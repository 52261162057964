import { Component, Input, OnInit } from "@angular/core";
import { Chapter, systemName } from "src/app/models/chapter";
import { svgTags } from "../../galaxy-map/galaxy-map.component";

@Component({
	selector: "app-etape5",
	templateUrl: "./etape5.component.html",
	styleUrls: ["./etape5.component.scss"]
})
export class Etape5Component implements OnInit {
	@Input() worldMaps;
	@Input() currentUserWorld: { index: number; systemName: systemName; currentChapter: Chapter[] };
	unlocked: boolean;
	currentChapter: Chapter;
	constructor() {}

	ngOnInit() {
		this.unlocked = this.worldMaps[this.currentUserWorld.systemName]?.some(chapter => {
			return chapter.unlocked;
		});

		this.currentChapter = this.currentUserWorld.currentChapter[0];
	}

	get systemName() {
		return systemName;
	}

	get svgTags() {
		return svgTags;
	}
}
