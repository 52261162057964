import { FicheEtapes } from "../components/fiche/fiche.component";
import { Details } from "../page/territoire/territoire.page";
import { CompletedExercises, JourneysStatus } from "./lrs/ose-stats-models";
import { OseActivity } from "./ose-activities";
import { OseCompetencies } from "./ose-competencies";

export enum OseExerciceType {
	fiche = "fiche",
	puzzle = "puzzles",
	coloriage = "coloriage",
	spotDifference = "7erreurs",
	memory = "memory",
	quizz = "quizz",
	wordSearch = "mots-caches",
	wasteSorting = "wastesort",
	visit = "visit"
}

export enum QuizzStatus {
	done,
	notDone,
	toDo
}

export class Ose2Journey {
	id: number;
	title: string;
	name: string;
	exercises: Array<OseActivity>;
	selectedCategory: Details;
	currentCategoryExercise: Array<OseActivity> = new Array();
	background: string;
	competencies: Array<OseCompetencies>;
	started = false;
	quizz: Array<{ id: number; completedQuizz: boolean; statusCompletion: QuizzStatus; isEvaluation:boolean, title?: string; icon?: string; typeNameFr?: string }>;
	feedback: string;
	isQuizzTypeCompleted: boolean; // true if lauched journey starts from (Je me teste) is quizz type
	isFiche = false;
	completed = false;
	resume: boolean;
	nbQuizCompleted: any;
	totalExercisesToDo: Array<OseActivity>;
	totalRemainingExercises: Array<OseActivity>;
	currentExerciseIndex = 0;
	fiche_peda?: string;

	workshopLastIndex;
	evaluationStartIndex;
	evaluations: Array<OseActivity> = new Array();
	learnings: Array<OseActivity> = new Array();
	workshops: Array<OseActivity> = new Array();
	icon: string;
	activitySelectedFromUser:boolean;
	skippedMode = false;

	constructor(element: any, oseCompetencies?: OseCompetencies[], oseActivities?: OseActivity[]) {
		this.id = element.id;
		this.title = element.title;
		this.feedback = element.feedback;
		this.background = element.background;
		this.fiche_peda = element.fiche_peda;

		if (oseActivities) {
			const deepCopiedExercises = JSON.stringify(this.getExerciseInfos(element.exerciseIds, oseActivities));
			this.exercises = JSON.parse(deepCopiedExercises);
			this.setExercisesTypeNameFr();
		}
		if (oseCompetencies) {
			this.competencies = this.getCompetencies(element.competencies, oseCompetencies);
		}
		this.quizz = this.getQuizzStatuses(element.quizz);
	}

	/**
	 * Create quizz progression status
	 * @param quizzIds quizz ids
	 * @returns Quizz
	 */
	private getQuizzStatuses(quizzIds) {
		if (quizzIds) {
			return quizzIds?.map(quizzId => {
				return {
					id: Number(quizzId),
					completed: false,
					statusCompletion: QuizzStatus.notDone,
					type: OseExerciceType.quizz
				};
			});
		} else {
			return quizzIds;
		}
	}

	setExercisesTypeNameFr() {
		this.exercises.forEach(ex => {
			if (ex) {
				if (ex.type === OseExerciceType.fiche) {
					ex.typeNameFr = "Cours";
				} else if (ex.type === OseExerciceType.puzzle) {
					ex.typeNameFr = "Puzzle";
				} else if (ex.type === OseExerciceType.memory) {
					ex.typeNameFr = "Memory";
				} else if (ex.type === OseExerciceType.spotDifference) {
					ex.typeNameFr = "Jeu des erreurs";
				} else if (ex.type === OseExerciceType.wordSearch) {
					ex.typeNameFr = "Mots mêlés";
				} else if (ex.type === OseExerciceType.quizz) {
					ex.typeNameFr = "Quiz";
				} else if (ex.type === OseExerciceType.coloriage) {
					ex.typeNameFr = "Coloriage";
				} else {
					ex.typeNameFr = ex.type;
				}
			}
		});
	}

	/**
	 * Set quizz boolean status based on user completed journey ids
	 * @param completedJourneyIds Array<number>
	 */
	setQuizzCompletionStatuses(completedJourneyIds: Array<JourneysStatus>) {
		if (this.quizz) {
			this.quizz.forEach((quizz, quizzIndex) => {
				const statusDone = completedJourneyIds?.some(journeyStatus => Number(journeyStatus.id) === Number(quizz.id));
				this.quizz[quizzIndex].completedQuizz = statusDone;
			});
			this.nbQuizCompleted = this.quizz.filter(q => q.completedQuizz).length;
		}
	}

	/**
	 * Verify if current journey status match which one pass in param
	 * @param completedJourneyIds Array<number>
	 */
	hasJourneyStatusMatch(journeyStatus: Array<JourneysStatus>) {
		return journeyStatus?.some(journey => {
			return Number(journey.id) === Number(this.id);
		});
	}

	/**
	 * Change exercise ids array to an object with status
	 */
	private getExerciseInfos(exerciseIds, oseActivities: OseActivity[]) {
		return exerciseIds.map(exerciseId => {
			return oseActivities.find(activity => Number(activity.id) === Number(exerciseId));
		});
	}

	/**
	 * Convert competency ids into an object of competencies
	 *
	 * @param competencyIds competencies ids from server
	 * @param oseCompetencies competencies width multiple properties
	 */
	private getCompetencies(competencyIds: Array<number>, oseCompetencies: Array<OseCompetencies>): Array<OseCompetencies> {
		competencyIds.forEach((exerciseId, index) => {
			oseCompetencies.forEach(currOseComp => {
				if (currOseComp.id === (exerciseId as any)) {
					(competencyIds[index] as any) = currOseComp;
					return;
				}
			});
		});
		return competencyIds as any;
	}

	/**
	 * Current exercise of journey that is playing
	 */
	public getCurrentExercise() {
		if (this.started) {
			this.currentExerciseIndex = -1;
			this.currentExerciseIndex = this.exercises.findIndex(ex => {
				return this.skippedMode ? !ex.skip : !ex.completed;
			});

			return this.exercises[this.currentExerciseIndex];
		} else {
			return null;
		}
	}
	/**
	 * Previous exercise of journey that is playing
	 */
	public getPrevExercise() {
		let exercice;
		let found = false;
		const currentExercise = this.getCurrentExercise();
		this.exercises.forEach(ex => {
			if (!found) {
				if (!ex.completed) {
					found = true;
				} else {
					exercice = ex;
				}
			}
		});
		return exercice;
	}
	setCurrentExerciseStatus(exercise, status: boolean) {
		exercise.completed = status;
	}

	/**
	 * change all exercises statuses
	 * @params completion status
	 */
	public setAllExercisesStatuses(completed: boolean) {
		this.exercises.forEach(ex => {
			ex.completed = completed;
			ex.skip = completed;
		});
	}

	/**
	 * Change exercise status to completed at the end of the activity
	 */
	public setExerciseCompleted(id: number) {
		let exercise;
		if(this.skippedMode){
			exercise = this.exercises.find(ex => ex.id === id && ex.skip === false);
			if(exercise){
				exercise.skip = true;
			}
		}
		if(!exercise){
			exercise = this.exercises.find(ex => ex.id === id && ex.completed === false);
		}
		

		exercise.completed = true;
	}

	/**
	 * get all exercises to do
	 * @returns OseActivity
	 */
	public getTotalExercisesToDo() {
		let exercisesTargetted;
		const categoryExercises = this.getSelectedCategory();
		exercisesTargetted = this.exercises;
		const exercisesToDo = exercisesTargetted.filter(ex => {
			return ex.type !== OseExerciceType.fiche || ex.hasvideo === "1";
		});

		const lastActivity = exercisesTargetted[exercisesTargetted.length - 1];
		if (lastActivity && lastActivity.type === OseExerciceType.fiche && lastActivity.hasvideo !== "1" && !lastActivity.isEtape) {
			// add additional cell for progression
			exercisesToDo.push(new OseActivity());
		}

		return exercisesToDo;
	}

	/**
	 * Verify if all exercises are completed
	 */
	public isCompleted(checkEndActivity:boolean) {
		let exercises;
		if(checkEndActivity){
			const currentExercise = this.getCurrentExercise();
			if(currentExercise){
				exercises = this.exercises.filter((ex) => ex.id !== currentExercise?.id)
			}
		}else{
			exercises = this.exercises;
		}
		return exercises.every(ex => {
			return this.skippedMode ? ex.skip : ex.completed;
		});
	}

	public initializeCategoryExercises(allQuizz) {
		const workshops = new Array();
		const learnings = new Array();
		const evaluations = new Array();

		this.workshopLastIndex = this.exercises
			.map((element, index) => (element.isworkshop === "1" ? index : ""))
			.filter(String)
			.pop() as Number;

		if (this.workshopLastIndex === null || this.workshopLastIndex === undefined) {
			this.workshopLastIndex = -1;
		}
		this.evaluationStartIndex = this.exercises.findIndex(element => element?.isevaluation === "1");
		this.exercises.forEach((ex, index) => {
			if (index <= this.workshopLastIndex) {
				workshops.push(ex);
			}
			if (this.evaluationStartIndex > -1) {
				// evaluation tag exist
				if (index > this.workshopLastIndex && index < this.evaluationStartIndex) {
					learnings.push(ex);
				}
				if (index >= this.evaluationStartIndex) {
					evaluations.push(ex);
				}
			} else {
				// evaluation tag not exist
				if (index > this.workshopLastIndex) {
					learnings.push(ex);
				}
			}
		});

		return { workshops, learnings, evaluations };
	}

	/**
	 * Create new etape in order to insert in exercise array
	 *
	 * @param title accepting two values (sequence/conclusion)
	 * @returns OseActivity
	 */
	createNewEtape(title: string): OseActivity {
		return {
			id: Math.floor(Math.random() * 900000) + 100000,
			title,
			type: OseExerciceType.fiche,
			completed: false,
			isEtape: true,
			skip: false
		};
	}

	/**
	 * Create a new sequence step and insert it in the appropriate position(end of category exercise)
	 */
	createDynamicNextSeqEtape() {
		let lastIndex;
		if(this.skippedMode){
			const selectedCategory = this.getSelectedCategory();
			lastIndex = this.exercises
			.map((ex, index) => !ex.skip && selectedCategory.some((selEx) => selEx.id === ex.id) ? index : "")
			.filter(String)
			.pop() as number;
		}else{
			lastIndex = this.exercises
			.map((ex, index) => (!ex.completed ? index : ""))
			.filter(String)
			.pop() as number;
		}
		if (this.exercises[lastIndex]?.title === FicheEtapes.titleConclusion && this.exercises[lastIndex]?.isEtape) {
			this.exercises[lastIndex].completed = true;
			this.exercises[lastIndex].skip = true;
		}
		if (this.selectedCategory !== Details.quizz) {
			let addNextSeq = true;
			if(this.selectedCategory === Details.learning){
				addNextSeq = this.evaluations?.length > 0;
			}
			if(addNextSeq){
				this.exercises.splice(lastIndex + 1, 0, {
					...this.createNewEtape(FicheEtapes.titleNextSeq)
				});
			}
		}
	}

	/**
	 * Return category array of ose activities depending on the value of selected category
	 * @returns OseActivity
	 */
	public getSelectedCategory(): OseActivity[] {
		if (this.selectedCategory) {
			if (this.selectedCategory === Details.learning) {
				return this.learnings;
			} else if (this.selectedCategory === Details.discovering) {
				return this.workshops;
			} else if (this.selectedCategory === Details.quizz) {
				return this.evaluations;
			}
		}
	}

	/**
	 * Verify if all exercises are completed
	 */
	public get isFirstExercise(): boolean {
		const currentExercise = this.getCurrentExercise();
		if (this.exercises[0]?.id === currentExercise?.id) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Verify if any of current journey has completed sequence
	 *
	 * @param completedJourneys CompletedJourneys
	 * @returns boolean
	 */
	public isSequenceCompleted(completedExercises: CompletedExercises[]) {
		return completedExercises.some(compJ => {
			return compJ.id === this.id && compJ.completedSequence;
		});
	}

	/**
	 * Redirect to the associated exercise page based on his type
	 * @param exerciseType the type of exercise
	 * @returns page of activity
	 */
	public getExerciseUrlByType(exerciseType) {
		let url: string = "";
		switch (exerciseType) {
			case OseExerciceType.fiche:
				url = "cours";
				break;
			case OseExerciceType.memory:
				url = "memory";
				break;
			case OseExerciceType.quizz:
				url = "quizz";
				break;
			case OseExerciceType.coloriage:
				url = "coloriage";
				break;
			case OseExerciceType.puzzle:
				url = "puzzle";
				break;
			case OseExerciceType.spotDifference:
				url = "jeu-des-erreurs";
				break;
			case OseExerciceType.wordSearch:
				url = "search-words";
				break;
			case OseExerciceType.visit:
				url = "photo-dome";
				break;
			case OseExerciceType.wasteSorting:
				url = "waste-sorting";
				break;
		}

		return url;
	}
}
