import { Directive } from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalService } from "src/app/services/global.service";

@Directive()

export class EventsBasePage {
	constructor(
		public globalService: GlobalService
	) {}
	// root class to subscribe to appIdleEvents & menuOpenEvents and launch associated method (setAppIdle() / onMenuToggle())
	// methods needs to be overloaded in ToolbarBasePage / GameBasePage / CabriBasePage or whatever page depending on use cases
	// (could be replaced by a service but needs refactoring)

	// menu events:
	public menuOpen = false;
	public appMenusSubscription: Subscription;
	public appIdleSubscription: Subscription;
	environment: {
		production: boolean;
		activityVersion: number;
		storyVersion: number;
		kidaia: boolean;
		bearerToken: string;
		ose: boolean;
		autolog: boolean;
		login: any;
		password: any;
	};
	public ionViewWillEnter() {
		this.initAppIdleSubscription();
		this.initAppMenusSubscription();
	}

	public ionViewWillLeave() {
		this.unsubscribeinitAppIdleSubscription();
		this.unsubscribeMenuEventsSubscription();
	}

	/**
	 * App Menu Open/Close Events Subscription
	 * Triggers onMenuToggle() which has to be overloaded in child
	 */
	initAppMenusSubscription() {
		if (this.appMenusSubscription) {
			this.appMenusSubscription.unsubscribe();
			this.appMenusSubscription = null;
		}
		this.appMenusSubscription = this.globalService.menuOpenEvent.subscribe({
			next: async v => {
				console.log("%c menuOpenEvent => " + "v.target = " + v.target, "v.open = " + v.open + "background: pink; color: black;");
				this.menuOpen = v.open;
                this.onMenuToggle(this.menuOpen);
			}
		});
	}
    unsubscribeMenuEventsSubscription() {
		if (this.appMenusSubscription) {
			this.appMenusSubscription.unsubscribe();
		}
	}
    /**
     * placeholder method to be overloaded in child
     */
	onMenuToggle(status: boolean) {
	}

	/**
	 * App Idle EventSubscription
	 * Triggers setAppIdle which has to be overloaded in child
	 */
	initAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			this.appIdleSubscription = null;
		}
		console.log("%cthis.initAppIdleSubscription", "background: yellow; color: black");
		this.appIdleSubscription = this.globalService.appIdleEvent.subscribe({
			next: v => {
				console.log("this.setAppIdle(" + v + ")");
				this.setAppIdle(v);
			}
		});
	}
    unsubscribeinitAppIdleSubscription() {
		if (this.appIdleSubscription) {
			this.appIdleSubscription.unsubscribe();
			console.log("%cappIdleSubscription unsubscribed", "background: yellow; color: black");
		}
	}
    /**
     * placeholder method to be overloaded in child
     */
	async setAppIdle(idle: boolean, loaderStop = true, afterResize?: boolean): Promise<void> {
		return new Promise(async (resolve, reject) => {
			resolve();
		});
	}
}
