import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AnyAaaaRecord } from "dns";

@Component({
  selector: "app-babylonjs-param",
  templateUrl: "./babylonjs-param.component.html",
  styleUrls: ["./babylonjs-param.component.scss"],
})
export class BabylonjsParamComponent implements OnInit {

  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() cd: ChangeDetectorRef;
  @Input() mode: string;
  @Input() label: string;
  @Input() model: AnyAaaaRecord;
	@Output() customChange: EventEmitter<{ value: any }> = new EventEmitter<{ value: any }>();

  public currentValue;

  constructor() { }

  ngOnInit() {
    this.currentValue = this.model;
  }

  updateValue() {
    this.currentValue = Math.round(this.currentValue * 10000) / 10000;
    this.customChange.emit({value: this.currentValue});
  }
}
