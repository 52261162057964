export class ExerciceChapterHistory {
	id: string;
	chapterId: string;
	position: number;
	idExercice: string;
	bilan : boolean;
	tempId:string;
	results: { shooting: number; star: number; moon: number }[];

	maxStarsWon(): { star: number; total: number; ratio: number } {
		const result = { star: 0, total: 0, ratio: 0 };
		if (this.results && this.results.length > 0) {
			this.results.forEach(item => {
				const total = item.star + item.shooting + item.moon;
				const star = item.star + item.shooting;
				const ratio = star / total;
				if (ratio > result.ratio) {
					result.star = star;
					result.total = total;
					result.ratio = ratio;
				}
			});
		}
		return result;
	}
}
