import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'myfilter',
    pure: false
})
export class FilterParams implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        let paramFunction = function (param) {
          return !param[3];
        }
        const itemFinal =  items.filter(paramFunction);
        return itemFinal;
    }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 