import { Pipe, PipeTransform } from "@angular/core";
import { AbstractMesh } from "@babylonjs/core";

@Pipe({
	name: "linkName"
})
export class linkNamePipe implements PipeTransform {
	transform(url: string): string {
        return url.substring(url.lastIndexOf('/')+1);
	}
}

