import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { userAlertActions } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";

import { GlobalService } from "src/app/services/global.service";
import { Student } from "src/app/models/student";
import { AudioService } from "src/app/services/audio.service";

@Component({
	selector: "app-custom-alert",
	templateUrl: "./custom-alert.component.html",
	styleUrls: ["./custom-alert.component.scss"]
})
export class CustomAlertComponent implements OnInit {
	@Input() alertTitle: string;
	@Input() alertAction: string;
	@Input() selectedStudent: Student;
	@Output() alertMessageAnswer = new EventEmitter<any>();

	constructor(
		public globalService: GlobalService,
		public audioService: AudioService,
		public router: Router,
		public cabriService: CabriDataService
	) {}

	ngOnInit() {
		console.error("alertTitle", this.alertTitle);
	}

	async dismiss($event = null) {
		if ($event) {
			await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		}
		this.alertMessageAnswer.emit(false);
		this.globalService.fullPageConfirmationAlert = false;
		this.globalService.closeStudentsMenu();
		this.audioService.playSelectSound();
	}

	async accept($event) {
		this.audioService.playSelectSound();
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		if (this.alertAction === userAlertActions.switchStudent) {
			// User change
			this.globalService.closeStudentsMenu();
			this.alertMessageAnswer.emit({ type: userAlertActions.switchStudent, student: this.selectedStudent });
		} else if (this.alertAction === userAlertActions.removeStudent) {
			// student remove
			this.alertMessageAnswer.emit({ type: userAlertActions.removeStudent });
		} else if (this.alertAction === userAlertActions.changeParam) {
			// student remove
			this.alertMessageAnswer.emit({ type: userAlertActions.changeParam });
		}
	}
}
