import { lensFlareVertexShader } from "@babylonjs/core/Shaders/lensFlare.vertex";
import { Competency, Video, VideoGenre } from "./video";

export class Videos {
	private videos: Video[];

	constructor(videos: Video[]) {
		this.videos = videos;
	}

	getAllVideos(): Video[] {
		return this.videos.slice();
	}

	getAllUnseenVideo() {
		return this.videos.slice().filter(video => !video.see);
	}

	getVideoByParam(unSeen?: boolean, age?: number, genre?: string | VideoGenre) {
		return this.videos.slice().filter(video => {
			let unSeenB: boolean, ageB: boolean, genreB: boolean;
			unSeenB = ageB = genreB = true;
			if (unSeen) {
				unSeenB = !video.see;
			}
			if (age) {
				ageB = video.age <= age;
			}
			if (genre) {
				genreB = video.genres?.includes(genre as VideoGenre);
			}
			return unSeenB && ageB && genreB;
		});
	}
	/**
	 * return filtered Array of video by competency
	 * @param competencies Competency or Competency[]
	 * @returns Video[]
	 */
	getVideoByCompetencies(competencies :  Competency | Competency[] ){
		if (Array.isArray(competencies)){
			return this.videos
				.slice()
				.filter(video => competencies.some(competency => video.competencies?.includes(competency)));
		} else {
			return this.videos
				.slice()
				.filter(video => video.competencies?.includes(competencies));
		}
	}

	/**
	 * return filtered Array of video by competency.id
	 * @param competencies id or id[]
	 * @returns Video[]
	 */
	getVideoByCompetenciesById(competencies :  string | string[] ){
		if (Array.isArray(competencies)){
			return this.videos
				.slice()
				.filter(video => competencies.some(competency => video.competencies?.some(competencyV => competencyV.id === competency)));
		} else {
			return this.videos
				.slice()
				.filter(video => video.competencies?.some(competencyV => competencyV.id === competencies));
		}
	}
}
