import { Component, OnInit } from "@angular/core";
import { svgTags } from "../../galaxy-map/galaxy-map.component";

@Component({
	selector: "app-galaxie9",
	templateUrl: "./galaxie9.component.html",
	styleUrls: ["./galaxie9.component.scss"]
})
export class Galaxie9Component implements OnInit {
	constructor() {}

	ngOnInit() {}

	get svgTags() {
		return svgTags;
	}
}
