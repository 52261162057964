
<div *ngIf="showSplash || (loaderStatus && !adobeLoader)" class="progressBarGlobalContainer backgroundStars">
  <div [ngClass]="{
  'logoContainer' : !environment.kidaia && !globalService.resizeEventRunning,
  'logoContainerPortrait' : !environment.kidaia && (globalService.isTablet || globalService.isMobile) && !globalService.isLandscape  && !globalService.resizeEventRunning,
  'logoContainerKidaia' : environment.kidaia  && !globalService.resizeEventRunning,
  'logoContainerKidaiaPortrait' : environment.kidaia && (globalService.isTablet || globalService.isMobile) && !globalService.isLandscape  && !globalService.resizeEventRunning,
  'logoContainerKidaiaSplash' : environment.kidaia && false && !globalService.resizeEventRunning,
  'marginR' : !environment.kidaia && globalService.isDesktop
}">
<!-- replace true/false par showsplash -->

    <!-- KIDAIA LOGO -->
    <div class="logoKidaiaWhiteText" *ngIf="environment.kidaia && !environment.ose"></div>

    <!-- OSE LOGO -->
    <div id="logo-ose" *ngIf="environment.kidaia && environment.ose" class="logoKidaiaWhiteText"></div>

    <!-- MATHIA LOGO -->
    <div *ngIf="!environment.kidaia" class="logoMathiaWhiteText"></div>
    <!-- <ion-img *ngIf="!environment.kidaia" [src]="'/assets/mathia/logo_fond_transparent_solo_white.png'" class="logotext"></ion-img> -->


    <!-- temp tts splashscreen wip -->
    <!-- <div class="mathiaContainer" *ngIf="environment.kidaia && showSplash">
      <div class="mascotteContainer">
        <div class="mathiaMascotteSplashKidaia"></div>
        <div class="bubble" (click)="playText(true); startingPageAnalytics('bouton help click')">
          <div class="gradientBubble">
            <div class="textContainer">
              <span class="textBubble">Choisis une des options</span>
              <ion-img [src]="'/assets/mathia/buttons/play.svg'"
                [ngClass]="[globalService.isMobile? 'playTextMobile':'playText']"></ion-img>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>

  <!-- loading progress bar: -->
  <!-- *ngIf="!globalService.lowPerformanceMode" ? remplacer par "Chargement" ? -->
  <div *ngIf="!globalService.resizeEventRunning" class="progressBarContainer" [ngClass]="{'infinite' : mode === 'infinite', 'progressBarContainerPortrait' : !globalService.isLandscape && !globalService.isDesktop, 'marginR' : !environment.kidaia && globalService.isDesktop}">
    <div class="progress-outer" [ngClass]="[environment.kidaia ? !globalService.isLandscape && !globalService.isDesktop ? 'progress-outerK outerKPortrait' : 'progress-outerK' : 'progress-outer']">
      <div *ngIf="mode !== 'infinite' || showSplash" class="progress-inner"
        [ngClass]="[environment.kidaia ? 'progress-innerK' : 'progress-inner']" [style.width]="progress + '%'">
      </div>
      
      <div #infiniteLoader *ngIf="mode === 'infinite'"
        [ngClass]="[environment.kidaia ? 'progress-innerK infinite-progress-innerK' : 'progress-inner infinite-progress-inner']" class="slideOutRight">
      </div>
    </div>
  </div>

  <ion-button *ngIf="!environment.production" class="closeBtn" (click)="stop()">Stop loading</ion-button>
</div>


<div *ngIf="(adobeAnimate && !globalService.lowPerformanceMode)" [hidden]="!adobeLoader || !loaderStatus">
  <div #animation_container id="animation_container" style="background-color:rgba(255, 255, 255, 1.00); width:550px; height:400px;z-index: 9998;">
		<canvas #canvas id="canvas" width="550" height="400" style="position: absolute; display: block; background-color:rgb(0, 0, 0);"></canvas>
		<div #dom_overlay_container id="dom_overlay_container" style="pointer-events:none; overflow:hidden; width:550px; height:400px; position: absolute; left: 0px; top: 0px; display: block;">
		</div>
	</div>
</div>