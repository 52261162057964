import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CabriActivity } from "src/app/models/cabri-activity";
import { JourneyDiagnosticAward } from "src/app/models/journey";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-progressbar-activity",
	templateUrl: "./progressbar-activity.component.html",
	styleUrls: ["./progressbar-activity.component.scss"]
})
export class ProgressbarActivityComponent implements OnChanges {
	@Input() currentUser: any;
	@Input() changeDetector: ChangeDetectorRef;
	@Input() mode: any;
	@Input() finalSummary: boolean;
	@Input() totalAward: number;
	@Input() barLength: number;
	@Input() currentActivity: CabriActivity;
	@Input() minQuestionVariable: number;
	cursorMode;

	numberOfQuestions = Array(0);
	environment: { production: boolean; activityVersion: number; kidaia: boolean; bearerToken: string };

	constructor(public cabriService: CabriDataService, public cd: ChangeDetectorRef, public globalService: GlobalService) {
		this.checkNumberOfQuestions();
		this.environment = environment;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			(changes.currentUser && !changes.currentUser.previousValue && changes.currentUser.currentValue) ||
			(changes.currentUser && changes.currentUser.previousValue && !changes.currentUser.currentValue) ||
			(changes.mode && changes.mode.currentValue !== changes.mode.previousValue) ||
			(changes.minQuestionVariable && changes.minQuestionVariable.previousValue !== changes.minQuestionVariable.currentValue) ||
			(changes.mode &&
				changes.mode.currentValue &&
				changes.mode.previousValue &&
				changes.mode.currentValue["v-cursor-mod"] &&
				changes.mode.previousValue["v-cursor-mod"] &&
				changes.mode.currentValue["v-cursor-mod"] !== changes.mode.previousValue["v-cursor-mod"]) ||
			(changes.totalAward && changes.totalAward.previousValue !== changes.totalAward.currentValue)
		) {
			this.checkNumberOfQuestions();
		}
	}

	get numberOfQuestionsArray() {
		return Array(this.numberOfQuestions);
	}

	get needRocketAnimation() {
		if (this.cabriService.progressionBarStatus.getValue() === false) {
			return false;
		}

		return true;
	}

	checkNumberOfQuestions() {
		if (this.barLength) {
			this.numberOfQuestions = Array(this.barLength);
		} else {
			let numberOfQuestions;
			if (this.mode) {
				this.cursorMode = +this.mode["v-cursor-mod"];
			}
			if (this.cursorMode === 1) {
				// Training mod
				if (this.currentUser && this.currentUser.awardsCurrent) {
					numberOfQuestions = this.defineDynamicNumberQuestions(15, 10);
				} else {
					numberOfQuestions = 10;
				}
			} else if (this.cursorMode === 0) {
				// Disovery mod
				numberOfQuestions = 25;
			} else if (this.cursorMode === 2) {
				// Challenge mod
				numberOfQuestions = 20;
			} else if (this.cursorMode === 3) {
				// minimum number of questions can be define in exercice variables
				let minQuestions = 0;
				if (this.minQuestionVariable) {
					minQuestions = this.minQuestionVariable;
				}
				if (this.currentUser && this.currentUser.awardsCurrent) {
					numberOfQuestions = this.defineDynamicNumberQuestions(
						Math.max(minQuestions, JourneyDiagnosticAward.maxTotalAwards),
						Math.max(minQuestions, JourneyDiagnosticAward.maxAward)
					);
				} else {
					numberOfQuestions = Math.max(minQuestions, JourneyDiagnosticAward.maxAward);
				}
			}
			this.numberOfQuestions = Array(numberOfQuestions);
		}
	}

	defineDynamicNumberQuestions(maxTotalAward, maxAward) {
		const sumAllAwards =
			this.currentUser.awardsCurrent.normal + this.currentUser.awardsCurrent.moons + this.currentUser.awardsCurrent.shooting;
		return Math.min(
			maxTotalAward,
			maxAward +
				sumAllAwards -
				Math.max(
					this.currentUser.awardsCurrent.moons,
					this.currentUser.awardsCurrent.normal,
					this.currentUser.awardsCurrent.shooting
				)
		);
	}
}
