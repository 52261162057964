<div class="studentsContainer scrollbar-primary"
  [ngClass]="!globalService.isBrowser && platform.isLandscape() ? 'scrollbarMobileLandscape' : 'scrollbar'">
  <div class="tabsContainer" *ngIf="accountService.user.codeClasse && (teamsCopy && teamsCopy.length > 0)">
    <div class="tab tabStudents" (click)="tabs('students',$event)">
      <span class="buttonText">
        <p i18n>Élèves</p>
      </span>
    </div>
    <div class="tab tabGroups" (click)="tabs('groups',$event)">
      <span class="buttonText">
        <p i18n>Groupes</p>
      </span>
    </div>
  </div>

  <ion-grid fixed class="ion-no-padding ion-no-margin" class="studentsList">
    <ion-row style="width: 100%;">

      <!-- STUDENTS -->
      <ng-container *ngIf="showStudents;else groups">
        <ng-container *ngFor="let student of students; let i = index">
          <ion-col *ngIf="!student.selected" size='6' size-sm="4" size-md="3" size-lg="2">
            <div class="bubbleStudent ion-no-padding ion-no-margin"
              [ngClass]="student.selectedTemp || student.selected ? 'selectedStudent' : 'notSelectedStudent'"
              (click)="addStudent(student)">
              <div class='gradientBubbleStudent'>
                <div class="studentInfo">
                  <ion-icon name="person"
                    [ngClass]="student.selectedTemp || student.selected ? 'child-picto-selected ion-no-padding' : 'child-picto-not-selected ion-no-padding'">
                  </ion-icon>
                  <p class="studentLabel">{{student.name}}</p>
                </div>
              </div>
            </div>
          </ion-col>
        </ng-container>
      </ng-container>
    </ion-row>

    <!-- GROUPS -->
    <ion-row style="width: 100%;">
      <ng-template #groups>
        <div class="gr_bubbles">
          <ng-container *ngFor="let team of teamsCopy; let i = index">
            <ion-col size='12' size-sm="12" size-md="6" size-lg="4">
              <div class='gr_gradientBubbles' (click)="selectCurrentTeam(team)">
                <div class="gr_Container">
                  <div class="gr_IconsContainer" [ngClass]="team.length > 3 ? 'twoRows' : ''">
                    <ng-container *ngFor="let student of team">
                      <ion-icon name="person"></ion-icon>
                    </ng-container>
                  </div>
                  <div class="gr_StudentNames">
                    <div class="gr_studentNamesContainer">
                      <ng-container *ngFor="let student of team;let last = last">
                        <p *ngIf="student">{{student.name}}</p>
                        <div *ngIf="!last" class="gr_Divider"></div>
                      </ng-container>
                    </div>
                  </div>

                </div>
              </div>
            </ion-col>
          </ng-container>
        </div>
      </ng-template>
    </ion-row>
  </ion-grid>
</div>

<ion-footer [ngClass]="!globalService.isBrowser && platform.isLandscape() ? 'footerMobileLandscape' : 'footerNormal'"
  class="gradientFooter">
  <button class="footerBtn btnCancel btnNoDecoration" (click)="dismiss($event)" i18n>Annuler</button>
</ion-footer>