import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { CabriDataService } from "src/app/services/cabri-data.service";

@Component({
	selector: "app-starboard-container",
	templateUrl: "./starboard-container.component.html",
	styleUrls: ["./starboard-container.component.scss"],
	animations: [
		trigger("slideInOut", [
			state(
				"in",
				style({
					transform: "translate3d(0, 0, 0)"
				})
			),
			state(
				"out",
				style({
					transform: "translate3d(100%, 0, 0)"
				})
			),
			transition("in => out", animate("400ms ease-in-out")),
			transition("out => in", animate("400ms ease-in-out"))
		]),
		trigger("slideInOutMirror", [
			state(
				"in",
				style({
					transform: "translate3d(0, 0, 0) rotateX(180deg)"
				})
			),
			state(
				"out",
				style({
					transform: "translate3d(100%, 0, 0) rotateX(180deg)"
				})
			),
			transition("in => out", animate("400ms ease-in-out")),
			transition("out => in", animate("400ms ease-in-out"))
		])
	]
})
export class StarboardContainerComponent implements OnInit, OnDestroy {
	@Input() shootingStarsNumber: number;
	@Input() normalStarsNumber: number;
	@Input() moonsNumber: number;
	@Input() changeDetector: ChangeDetectorRef;
	@Input() events: Observable<void>;
	@Input() light?: boolean;
	private _toggle: boolean;
	starboardClosingTimeout: NodeJS.Timeout;

	@Input("toggle") set toggle(value: boolean) {
		if (value && this.starboardState === "out") {
			this.starboardState = "in";
			this._toggle = value;
		}
		if (!value && this._toggle && this.starboardState === "in") {
			this.starboardState = "out";
			this._toggle = value;
		}
	}

	get toggle(): boolean {
		return this._toggle;
	}

	public starboardState = "out";
	eventsSubject: Subject<void> = new Subject<void>();
	private eventsSubscription: Subscription;
	constructor(public cabriService: CabriDataService) {}

	ngOnInit() {
		this.eventsSubscription = this.events.subscribe(() => {
			console.log("event STARS 1");
			this.eventsSubject.next();
		});
	}

	/**
	 * Toggle starboard visibility - auto close starboard after 5s
	 */
	toggleStarboard() {
		if (this.starboardState === "in") {
			if (this.starboardClosingTimeout) {
				clearTimeout(this.starboardClosingTimeout);
				this.starboardClosingTimeout = null;
			}
			this.starboardState = "out";
		} else {
			if (this.starboardClosingTimeout) {
				clearTimeout(this.starboardClosingTimeout);
				this.starboardClosingTimeout = null;
			}
			this.starboardState = "in";
			this.starboardClosingTimeout = setTimeout(() => {
				this.starboardState = "out";
				if (this.changeDetector) {
					this.changeDetector.detectChanges();
				}
				this.starboardClosingTimeout = null;
				console.error("end of timeout");
			}, 5000);
		}
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		}
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
		this.changeDetector = null;
		console.log("this.eventsSubscription.unsubscribed from starboard container component!");
	}
}
