<ng-container *ngIf="open">
  <div class="devToolsWrapper">
    <h2>BabylonJS Dev Tools</h2>
      <button (click)="mergeMeshes()">Merge</button>
      <button (click)="loadForest()">Forest</button>
      <button (click)="loadMathia2()">loadMathia2</button>
      <!-- <button (click)="loadMathia()">MathIA</button> -->
      <button (click)="loadSkybox()">Skybox</button>

      <h3>SCENE</h3>
      <div>
        <ul class="l1">
          <li *ngIf="scene">
            <label>Diffuse color :</label>
            <div class="colorPreview"
            [style]="'background:' + canvas.style.backgroundColor" (click)="openPicker(scene, 'backgroundColorPicker')"></div>
            <chrome-picker *ngIf="scene.backgroundColorPicker" [(color)]="scene.dC" [control]="scene.backgroundColorControl"></chrome-picker>
          </li>
        </ul>
      </div>

    <h3>CAMERAS</h3>
    
    <div>
      <ul class="l1">
          <li>
            <h4 (click)="cameraClicked(camera)">{{camera.id}} - {{camera.name}}</h4>
            <ul class="l2" *ngIf='camera.openDetails'>
              <li><app-babylonjs-param label="Radius" [model]="camera.radius" (customChange)="camera.radius = $event.value" 
                [min]="0" [max]="1000" step="1" [cd]="cd" mode="number"></app-babylonjs-param></li>
              <li><app-babylonjs-param label="Alpha" [model]="camera.alpha" (customChange)="camera.alpha = $event.value" 
                [min]="-6" [max]="6" step="0.05" [cd]="cd" mode="number"></app-babylonjs-param></li>
              <li><app-babylonjs-param label="Beta" [model]="camera.beta" (customChange)="camera.beta = $event.value" 
                [min]="-6" [max]="6" step="0.05" [cd]="cd" mode="number"></app-babylonjs-param></li>
              <li><h5>Position : (x:{{camera.position.x}}, y:{{camera.position.y}}, z:{{camera.position.z}})</h5>
                <ul class="inline-li l3">
                  <li><app-babylonjs-param label="x" [model]="camera.position.x" (customChange)="camera.setPosition({x: $event.value, y: camera.position.y, z: camera.position.z});cd.detectChanges()" 
                        [min]="-20" [max]="20" step="0.1" [cd]="cd" mode="number"></app-babylonjs-param></li>
                    <li><app-babylonjs-param label="Y" [model]="camera.position.y" (customChange)="camera.setPosition({x: camera.position.x, y: $event.value, z: camera.position.z});cd.detectChanges()" 
                      [min]="-10" [max]="10" step="0.1" [cd]="cd" mode="number"></app-babylonjs-param></li>
                     <li><app-babylonjs-param label="Z" [model]="camera.position.z" (customChange)="camera.setPosition({x: camera.position.x, y: camera.position.y, z: $event.value});cd.detectChanges()" 
                      [min]="-10" [max]="10" step="0.1" [cd]="cd" mode="number"></app-babylonjs-param></li>
                </ul>
              </li>              
              <li><button (click)="cd.detectChanges()">On / Off</button></li>            
            </ul>
          </li>
      </ul>
    </div>
    
    <h3>Meshes</h3>
    
    <input type="text" placeholder="Filter" (keyup)="filterMeshes($event)"/>
    <div>
      <ul class="l1">
        <ng-container *ngFor="let m of scene.meshes">
          <li *ngIf="m.showInDevMenu == undefined || m.showInDevMenu === true">
            <h4 (click)="meshClicked(m)"  (mouseenter)="highlight(m)" (mouseleave)="unhighlight(m)" >{{m.id}} - {{m.name}} {{m.object?'Object Type : '+m.object.type:''}}</h4>
            <ul class="l2" *ngIf='m.openDetails'>
              <li>
                  <button (click)="camera.setTarget(m.getBoundingInfo().boundingBox.centerWorld);cd.detectChanges()">Target</button>
                  <button (click)="m.showBoundingBox = !m.showBoundingBox;">Bounding Box</button>
                  <button (click)="m.dispose();cd.detectChanges()">Dispose</button></li>
              <li><h5>Position : (x:{{m.position.x}}, y:{{m.position.y}}, z:{{m.position.z}})</h5>
                <ul class="inline-li l3">
                  <li><app-babylonjs-param label="x" [model]="m.position.x" (customChange)="m.position.x = $event.value" 
                        [min]="-20" [max]="20" step="0.1" [cd]="cd" mode="number"></app-babylonjs-param></li>
                    <li><app-babylonjs-param label="Y" [model]="m.position.y" (customChange)="m.position.y = $event.value" 
                      [min]="-10" [max]="10" step="0.1" [cd]="cd" mode="number"></app-babylonjs-param></li>
                     <li><app-babylonjs-param label="Z" [model]="m.position.z" (customChange)="m.position.z = $event.value" 
                      [min]="-10" [max]="10" step="0.1" [cd]="cd" mode="number"></app-babylonjs-param></li>
                </ul>
              </li>
              <li><h5>Rotation : (x:{{m.rotation.x}}, y:{{m.rotation.y}}, z:{{m.rotation.z}})</h5>
                <ul class="inline-li l3">
                  <li><app-babylonjs-param label="x" [model]="m.rotation.x" (customChange)="m.rotation.x = $event.value" 
                        [min]="-4" [max]="4" step="0.01" [cd]="cd" mode="number"></app-babylonjs-param></li>
                    <li><app-babylonjs-param label="Y" [model]="m.rotation.y" (customChange)="m.rotation.y = $event.value" 
                      [min]="-4" [max]="4" step="0.01" [cd]="cd" mode="number"></app-babylonjs-param></li>
                     <li><app-babylonjs-param label="Z" [model]="m.rotation.z" (customChange)="m.rotation.z = $event.value" 
                      [min]="-4" [max]="4" step="0.01" [cd]="cd" mode="number"></app-babylonjs-param></li>
                </ul>
              </li>
              <li><h5>Scaling : (x:{{m.scaling.x}}, y:{{m.scaling.y}}, z:{{m.scaling.z}})</h5>
                <ul class="inline-li l3">
                  <li><app-babylonjs-param label="x" [model]="m.scaling.x" (customChange)="m.scaling.x = $event.value" 
                        [min]="0" [max]="4" step="0.001" [cd]="cd" mode="number"></app-babylonjs-param></li>
                    <li><app-babylonjs-param label="Y" [model]="m.scaling.y" (customChange)="m.scaling.y = $event.value" 
                      [min]="0" [max]="4" step="0.001" [cd]="cd" mode="number"></app-babylonjs-param></li>
                     <li><app-babylonjs-param label="Z" [model]="m.scaling.z" (customChange)="m.scaling.z = $event.value" 
                      [min]="0" [max]="4" step="0.001" [cd]="cd" mode="number"></app-babylonjs-param></li>
                </ul>
              </li>
              <li *ngIf="m.material && m.material.emissiveColor">
                <label>Emissive color :</label>
                <div class="colorPreview"
                    [style]="printRGBABackgroundColor(m.material.emissiveColor)" (click)="openPicker(m.material, 'emissiveColorPicker')"></div>
                <chrome-picker *ngIf="m.material.emissiveColorPicker" [(color)] = "m.material.eC" [control]="m.material.emissiveColorControl"></chrome-picker>
              </li>
              <li *ngIf="m.material && m.material.emissiveColor">
                <label>Diffuse color :</label>
                <div class="colorPreview"
                    [style]="printRGBABackgroundColor(m.material.diffuseColor)" (click)="openPicker(m.material, 'diffuseColorPicker')"></div>
                <chrome-picker *ngIf="m.material.diffuseColorPicker" [(color)] = "m.material.dC" [control]="m.material.diffuseColorControl"></chrome-picker>
              </li>
              <li><label>Opacity :</label>
                  <ion-range [(ngModel)]="m.visibility" min="0" max="1" step="0.01"></ion-range></li>

                <li *ngIf="m.subMeshes && m.subMeshes.length > 0">
                  <ng-container *ngFor="let sm of m.subMeshes">
                    <h4 (click)="meshClicked(sm)"  (mouseenter)="highlight(sm)" (mouseleave)="unhighlight(sm)" >{{sm.id}} - {{sm.name}} {{sm.object?'Object Type : '+sm.object.type:''}}</h4>
                    <ul class="l3">
                      <li>
                          <button (click)="camera.setTarget(sm.getBoundingInfo().boundingBox.centerWorld);cd.detectChanges()">Target</button>
                          <button (click)="sm.showBoundingBox = !sm.showBoundingBox;">Bounding Box</button>
                          <button (click)="sm.dispose();cd.detectChanges()">Dispose</button>
                        </li>
                      </ul>
                  </ng-container>
                </li>
                </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-container>
<ion-img id="logoBabylon" src="/assets/icon/babylonjs.png" (click)="openDevTools()">
