import { LrsUtils } from "./lrsUtils";
import { LrsVerbs, XapiVerbs } from "./xapiverbs";

export class XApiResult {
	success: boolean;
	duration: string;

	constructor(verb: XapiVerbs, duration: string) {
		if (
			verb === LrsVerbs.passed ||
			verb === LrsVerbs.passedOnRetry ||
			verb === LrsVerbs.passedWithHelp ||
			verb === LrsVerbs.completed
		) {
			this.success = true;
		} else {
			this.success = false;
		}
		this.duration = duration;
	}
}
