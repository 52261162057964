import { CabriDataService } from '../services/cabri-data.service';

export class Activity {
	public className: string = "Activity";
	public id: string;
	public name: string;
	public description: string;
	public thumbnail: string;
	public customThumbnail: string;
	public routingPath: string;
	public _params: any;
	public selected: boolean;
	public shownParamsLength: number;
    public hasExercice: any;
	public step: number | string;
	public order: number;
	get params() {
		return this._params;
	}
	set params(value) {
		this._params = value;
	}
}
