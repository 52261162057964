import { AudioService } from "./../../services/audio.service";
import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { Platform } from "@ionic/angular";
import { PlayTTSService } from "../../services/play-tts.service";
import { AccountService } from "src/app/services/account.service";
import { userAlertActions } from "src/app/services/account.service";
import { Router } from "@angular/router";
import { SttService } from "src/app/services/stt.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService, MenuEvents } from "src/app/services/global.service";
import { RemoteService } from "src/app/services/remote.service";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { versions } from "src/environments/versions";

import { Student } from "src/app/models/student";
import { Subscription } from "rxjs";
import { NetworkService } from "src/app/services/network.service";
import { AppLanguage } from "src/app/models/enums/enum-list";
import { OseJourneyService } from "src/app/services/ose-journeys.service";

declare var window: {
	document: any;
	location: any;
	open: any;
	innerHeight: any;
	innerWidth: any;
};

@Component({
	selector: "app-toolbar-with-buttons",
	templateUrl: "./toolbar-with-buttons.component.html",
	styleUrls: ["./toolbar-with-buttons.component.scss"]
})
// extends toolbar base for TTSEP everywhere?
// export class ToolbarWithButtonsComponent extends ToolbarBasePage implements OnInit, OnDestroy {
export class ToolbarWithButtonsComponent implements OnInit, OnDestroy {
	public displayVolume = false;
	public dicteeModeState: boolean;
	public dicteeModeToggleValue: boolean;
	private _appVolume: number;
	public changeD: ChangeDetectorRef;
	public environment: { production: boolean; activityVersion: number; kidaia: boolean };
	private checkToolbarStatusSubscription: Subscription;
	public alertTitle = $localize`Es-tu sûr de vouloir changer de joueur ?`;
	public versions;
	public appLanguage;
	@Input() remote: boolean;
	@Input() ghost = true;
	@Input() remoteClick: boolean;
	@Input() activityName: string;
	@Input() changeDetector: ChangeDetectorRef;
	@Input() disableFullScreen = false;
	@Input() studentSelectionPage = false;
	@Input() progressBar = false;
	@Input() currentUser: Student;
	@Input() finalSummary: any;
	@Input() studentsList: Student[];
	@Input() opacity = false;
	@Input() filteredParameterActivity = false;
	@Input() progressBarLength: number;
	@Output() studentSelection = new EventEmitter<boolean>();
	@Output() alertMessageAnswer = new EventEmitter<any>();
	public remoteHostActivate = false;
	public selectedStudent: Student;
	public preloadBackground: boolean;

	// story settings
	public storySettingChoiceMade = false;
	// texts:
	playersManagementText: string;
	public window: any;
	constructor(
		public ttsService: PlayTTSService,
		public accountImplService: AccountService,
		public platform: Platform,
		public router: Router,
		public sttService: SttService,
		public cabriService: CabriDataService,
		public globalService: GlobalService,
		public remoteService: RemoteService,
		public location: Location,
		public accountService: AccountService,
		public networkService: NetworkService,
		public audioService: AudioService,
		public oseJourneyService:OseJourneyService
	) {
		this.environment = environment;
		this.appLanguage = AppLanguage;
		this.window = window;
		this.versions = versions;
		// move in ngOnInit?
		this.checkToolbarStatus();
		this.subscribeToCheckToolbarStatusInputEvent();
		this.preloadSettingsBackground();
	}

	setChangeDetector(changeD: ChangeDetectorRef) {
		this.changeD = changeD;
	}



	ngOnInit() {
		this.playersManagementText = $localize`Gestion des joueurs`;
		// Manage players
		this.displayStoryParamIcon();
	}

	/**
	 * Check if need to display story activity icon on toolbar
	 */
	displayStoryParamIcon() {}

	preloadSettingsBackground() {
		this.preloadBackground = true;
		setTimeout(() => {
			this.preloadBackground = false;
		}, 1000);
	}

	/**
	 * Subscription to listen to external event to adapt toolbar size depending on progressbar and device
	 */
	subscribeToCheckToolbarStatusInputEvent() {
		if (!this.checkToolbarStatusSubscription) {
			this.checkToolbarStatusSubscription = this.globalService.runCheckToolbarStatus.subscribe({
				next: v => {
					this.checkToolbarStatus(v);
				}
			});
		}
	}
	unsubscribeTocheckToolbarStatusInputEvent() {
		if (this.checkToolbarStatusSubscription) {
			this.checkToolbarStatusSubscription.unsubscribe();
		}
	}

	get userAlertActions() {
		return userAlertActions;
	}

	modalState() {
		this.globalService.fullPageConfirmationAlert = false;
	}

	alertSwitchUser($event?) {
		if (!$event) {
			this.globalService.fullPageConfirmationAlert = false;
		} else {
			this.alertMessageAnswer.emit($event);
		}
		if (this.changeD) {
			this.changeD.detectChanges();
		} else if (this.changeDetector) {
			this.changeDetector.detectChanges();
		}
	}

	async alertKidaiaSwitchStudent(student: Student, $event) {
		$event.stopPropagation();
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		if (Number(student.id) !== Number(this.accountService.team[0].id)) {
			this.selectedStudent = student;
			this.globalService.fullPageConfirmationAlert = true;
		}
		this.globalService.closeStudentsMenu();
		if (this.changeD) {
			this.changeD.detectChanges();
		} else if (this.changeDetector) {
			this.changeDetector.detectChanges();
		}
	}

	/**
	 * Adapt toolbar size depending on progressbar and device type
	 */
	checkToolbarStatus(progressBarStatus = null) {
		if (this.globalService.onActivityPage && this.progressBar) {
			this.globalService.progressBarStatus = true;
		} else {
			this.globalService.progressBarStatus = false;
		}
		this.globalService.setDocumentHeightCssVar();
		if (this.globalService.progressBarStatus || progressBarStatus) {
			if (this.globalService.isMobile || this.globalService.smallHeightScreen) {
				document.documentElement.style.setProperty("--toolbarHeight", "40px");
				document.documentElement.style.setProperty("--progressBarHeight", "10px");
				this.globalService.toolbarHeight = 40;
				this.globalService.progressbarHeight = 10;
				document.documentElement.style.setProperty("--sttIconSize", "75%");
				document.documentElement.style.setProperty("--sttIconHeight", "40px");
			} else {
				// TODO => double toolbar width + Icons height on 4k desktop screens
				const multiplier = this.globalService.isDesktop && (window.innerHeight > 2000 || window.innerWidth > 3000) ? 1 : 1;
				if (this.globalService.isKidaia) {
					const toolbarHeight = 84 * multiplier;
					this.globalService.progressbarHeight = 15 * multiplier;
					document.documentElement.style.setProperty("--toolbarHeight", toolbarHeight + "px");
					document.documentElement.style.setProperty("--progressBarHeight", this.globalService.progressbarHeight + "px");
					this.globalService.toolbarHeight = toolbarHeight;
				} else {
					const toolbarHeight = 60 * multiplier;
					this.globalService.progressbarHeight = 10 * multiplier;
					document.documentElement.style.setProperty("--toolbarHeight", toolbarHeight + "px");
					document.documentElement.style.setProperty("--progressBarHeight", this.globalService.progressbarHeight + "px");
					this.globalService.toolbarHeight = toolbarHeight;
				}
			}
			return true;
		} else {
			this.globalService.progressbarHeight = 0;
			if (this.globalService.isMobile || this.globalService.smallHeightScreen) {
				document.documentElement.style.setProperty("--toolbarHeight", "40px");
				document.documentElement.style.setProperty("--progressBarHeight", "0px");
				this.globalService.toolbarHeight = 40;
			} else {
				// TODO => double toolbar width + Icons height on 4k desktop screens
				const multiplier = this.globalService.isDesktop && (window.innerHeight > 2000 || window.innerWidth > 3000) ? 1 : 1;
				if (this.globalService.isKidaia) {
					const toolbarHeight = 84 * multiplier;
					document.documentElement.style.setProperty("--toolbarHeight", toolbarHeight + "px");
					document.documentElement.style.setProperty("--progressBarHeight", "0px");
					this.globalService.toolbarHeight = toolbarHeight;
				} else {
					const toolbarHeight = 50 * multiplier;
					document.documentElement.style.setProperty("--toolbarHeight", toolbarHeight + "px");
					document.documentElement.style.setProperty("--progressBarHeight", "0px");
					this.globalService.toolbarHeight = toolbarHeight;
				}
			}
			return false;
		}
	}

	switchRemoteHost() {
		if (this.remoteService.remoteHostActivate) {
			this.remoteService.unRegisterRemoteHost();
		} else {
			this.remoteService.registerRemoteHost(this.accountImplService.user.codeClasse, this.cabriService.getHostActivityInfo());
		}
		if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	/**
	 * method called on back button to navigate back depending of context
	 */
	async back() {
		if (this.globalService.toolbarMenuOpened.status) {
			this.quitDiv();
		} else {
			if (this.globalService.onActivityPage) {
				this.globalService.setGlobalLoading(true);
			}
			await this.ttsService.killSpeech();
			if (this.studentSelectionPage) {
				this.studentSelection.emit(true);
			} else {
				const locationState = this.location.getState() as any;
				// IOS?
				if (
					this.globalService.onActivityPage &&
					(this.globalService.isIos || this.globalService.isSafari) &&
					locationState?.navigationId === 1 &&
					this.cabriService.holoMode !== "1" &&
					this.cabriService.holoMode !== "-1"
				) {
					window.open("/accueil", "_self");
				} else if (this.globalService.onActivityPage && !this.cabriService.currentActivity?.story) {
					// dans activité + entrainement + venant d'un reload:
					this.router.navigateByUrl("/gabarits", { replaceUrl: true });
				} else if (this.globalService.onActivityPage && this.cabriService.currentActivity?.story) {
					// dans activité + mode story / TODO check map story update?
					this.router.navigateByUrl("/map", { replaceUrl: true });
				} else if (this.router.url === "/activity-participants") {
					this.router.navigateByUrl("/home");
				} else if (this.router.url === "/gabarits" && !environment.kidaia) {
					if (this.accountService.isRealUserConnected && this.accountService.team.length === 1) {
						this.router.navigateByUrl("/accueil");
					} else {
						this.router.navigateByUrl("/activity-participants");
					}
				} else if (this.router.url === "/accueil" && !environment.kidaia) {
					this.router.navigateByUrl("/activity-participants");
				} else if (this.router.url === "/map" && !environment.kidaia) {
					this.router.navigateByUrl("/accueil");
				} else if (this.router.url === "/videos") {
					this.router.navigateByUrl("/accueil");
				} else if (locationState.navigationId > 1) {
					if (this.globalService.urlHistory.length > 1) {
						this.globalService.urlHistory.pop();
						this.router.navigateByUrl(this.globalService.urlHistory.pop());
					} else {
						this.router.navigateByUrl("/");
					}
				} else if (!this.accountService.isRealUserConnected) {
					this.router.navigateByUrl("/home");
				} else {
					this.router.navigateByUrl("/accueil");
				}
			}
		}
	}

	/**
	 * Home button redirection method for Mathia
	 */
	async home() {
		if (this.globalService.toolbarMenuOpened.status) {
			this.quitDiv();
		} else {
			if (this.changeD) {
				this.changeD.detectChanges();
			}
			await this.ttsService.killSpeech();
			// this.navCtrl.navigateForward("/starting");
			if (this.router.url.startsWith("/starting")) {
				this.router.navigateByUrl("/home");
			} else {
				if (this.accountImplService.isRealUserConnected) {
					this.router.navigateByUrl("/accueil");
				} else {
					this.router.navigateByUrl("/starting");
				}
			}
		}
	}

	/**
	 * Not used anymore -> TODO remove ?
	 */
	async manageStorySettings() {
		if (this.globalService.toolbarMenuOpened.status && this.globalService.toolbarMenuOpened.name !== MenuEvents.Holo) {
			this.quitDiv();
		} else {
			this.globalService.storySettingOpened = true;
			// on open :
			this.globalService.menuStorySettingOpenedEvent.next(true);
			if (this.globalService.menuOpenEvent) {
				this.globalService.menuOpenEvent.next({ open: true, target: MenuEvents.Holo });
			}
			if (!this.globalService.onActivityPage) {
				await this.ttsService.killSpeech();
			}
			if (this.changeD) {
				this.changeD.detectChanges();
			}
		}
	}

	/**
	 * Triggers left menu menuOpenEvent
	 */
	async displayLeftMenu() {
		if (this.globalService.toolbarMenuOpened.status && this.globalService.toolbarMenuOpened.name !== MenuEvents.Burger) {
			this.quitDiv();
		} else {
			if (!(this.accountImplService.team.length === 0 && environment.ose)) {
				this.globalService.leftMenuStatus = !this.globalService.leftMenuStatus;
				// on open :
				if (this.globalService.leftMenuStatus === true) {
					if (this.globalService.menuOpenEvent) {
						this.globalService.menuOpenEvent.next({ open: true, target: MenuEvents.Burger });
					}
					if (!this.globalService.onActivityPage) {
						await this.ttsService.killSpeech();
					}
				}
				// on Close :
				if (this.globalService.leftMenuStatus === false) {
					if (!this.globalService.onActivityPage) {
						await this.ttsService.killSpeech();
					}
					if (this.globalService.menuOpenEvent) {
						this.globalService.menuOpenEvent.next({ open: false, target: MenuEvents.Burger });
					}
				}
			}
		}
		if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	/**
	 * Toggle for the 3 cabri input methods buttons (type string --> 'stt' / 'numpad' / 'drawing')
	 */
	toggleCabriInputMethod(input: string) {
		if (
			!(input === "drawing" && (this.activityName === "jeudekim" || this.activityName === "heure")) &&
			input !== this.cabriService.inputMethod &&
			this.cabriService.allowInputMethodChange === true &&
			this.globalService.onActivityPage &&
			this.cabriService.toggleCabriInputMethodEvent
		) {
			this.cabriService.toggleCabriInputMethodEvent.next(input);
		}
	}

	acceptStudentChanges() {}

	dismissStudentChange() {}

	/**
	 * triggers toggleBrowserFullscreen() on fulscreen button click
	 */
	browserFullscreen() {
		if (this.globalService.toolbarMenuOpened.status) {
			this.quitDiv();
		} else {
			this.globalService.toggleBrowserFullscreen();
		}
	}

	isHomeRoute() {
		return this.router.url === "/starting";
	}

	async displaySettingsMenu() {
		if (this.globalService.toolbarMenuOpened.status && this.globalService.toolbarMenuOpened.name !== MenuEvents.Voice) {
			this.quitDiv();
		} else {
			this.globalService.displayVoiceSettingsStatus = true;
			this.globalService.lowPerformanceModePlayTTS =
				this.globalService.blueLightFilterPlayTTS =
				this.ttsService.dicteemodePlayTTS =
				this.ttsService.menusMutedPlayTTS =
				this.ttsService.rateRatioPlayTTS =
					this.globalService.displayVoiceSettingsStatus;
			// on open :
			if (this.globalService.menuOpenEvent) {
				this.globalService.menuOpenEvent.next({ open: true, target: MenuEvents.Voice });
				this.globalService.showSettingParams.next("settings_opened_clicked");
			}
			if (!this.globalService.onActivityPage) {
				await this.ttsService.killSpeech();
			}
		}
		if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	/**
	 * toggles old native global volume slider
	 * NOT USED ANYMORE -> TODO REMOVE ?
	 */
	async displayVolumeSettings() {
		if (this.globalService.toolbarMenuOpened.status && this.globalService.toolbarMenuOpened.name !== MenuEvents.Volume) {
			this.quitDiv();
		} else {
			this.displayVolume = !this.displayVolume;
			if (this.changeD) {
				this.changeD.detectChanges();
			}
			if (this.displayVolume === true) {
				// on open
				if (this.globalService.menuOpenEvent) {
					this.globalService.menuOpenEvent.next({ open: true, target: MenuEvents.Volume });
				}
				if (!this.globalService.onActivityPage) {
					await this.ttsService.killSpeech();
				}
				if (!localStorage.getItem("appVolume")) {
					this._appVolume = 0.8;
					localStorage.setItem("appVolume", String(this._appVolume));
				} else {
					this._appVolume = Number(localStorage.getItem("appVolume"));
				}
			} else if (this.displayVolume === false) {
				// on close
				if (!this.globalService.onActivityPage) {
					await this.ttsService.killSpeech();
				}
				if (this.globalService.menuOpenEvent) {
					this.globalService.menuOpenEvent.next({ open: false, target: MenuEvents.Volume });
				}
				localStorage.setItem("appVolume", String(this._appVolume));
			}
		}
	}

	// KIDAIA players dropdown menu:
	async displayUserSettings() {
		if (this.globalService.toolbarMenuOpened.status && this.globalService.toolbarMenuOpened.name !== MenuEvents.User) {
			this.quitDiv();
		} else {
			this.globalService.studentsMenuStatus = !this.globalService.studentsMenuStatus;
			if (this.changeD) {
				this.changeD.detectChanges();
			}
			if (this.globalService.studentsMenuStatus === true) {
				// on open
				if (this.globalService.menuOpenEvent) {
					this.globalService.menuOpenEvent.next({ open: true, target: MenuEvents.User });
				}
				if (!this.globalService.onActivityPage) {
					await this.ttsService.killSpeech();
				}
			} else if (this.globalService.studentsMenuStatus === false) {
				// on close
				if (!this.globalService.onActivityPage) {
					await this.ttsService.killSpeech();
				}
				if (this.globalService.menuOpenEvent) {
					this.globalService.menuOpenEvent.next({ open: false, target: MenuEvents.User });
				}
			}
		}
	}

	/**
	 * Quit div method to close menus if one is opened and user clicks outside of it to prevent actions when a menu is opened
	 */
	quitDiv() {
		if (this.changeD) {
			this.changeD.detectChanges();
		}
		if (this.globalService.studentsMenuStatus) {
			this.globalService.closeStudentsMenu();
		}
		if (this.globalService.displayVoiceSettingsStatus) {
			this.displaySettingsMenu();
		}
		if (this.displayVolume) {
			this.displayVolumeSettings();
		}
		if (this.globalService.leftMenuStatus) {
			this.displayLeftMenu();
		}
	}

	async refreshPage() {
		if (this.globalService.toolbarMenuOpened.status) {
			this.quitDiv();
		} else {
			if (this.globalService.isCordova && this.globalService.isAndroid) {
				this.globalService.reloadNavigateAndroid();
			} else {
				const page = window.document.querySelector("ion-router-outlet .ion-page:not(.ion-page-hidden)");
				if(page && (window as any)?.ng?.getComponent){
					const pageComponent = (window as any).ng.getComponent(page);
					if(pageComponent && pageComponent.canDeactivate){
						await pageComponent.canDeactivate();
					}
				}
				window.location.reload();
			}
		}
	}

	arrayOne(n: number): any[] {
		return Array(n);
	}

	dicteeHelp() {
		if (this.changeD) {
			this.changeD.detectChanges();
		}
		this.ttsService
			.playTTS(
				$localize`:tts:Le mode dictée accentue la séparation entre les mots sur la page d'enregistrement ${this.ttsService.replacer}:caractere: Par exemple ${this.ttsService.replacer}:caractere: 2 plus 2 egal 4 ${this.ttsService.replacer}:caractere: donnera.`,
				true,
				false
			)
			.then(() => this.ttsService.playTTS($localize`:tts:2 plus 2 egal 4 `, null, true));
	}

	goHome() {
		// redirect to kidaia web:
		(window as any).open("https://kidaia.com/app", "_self");
	}

	async goActivityParticipants($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget }, 150);
		this.router.navigateByUrl("/activity-participants");
	}

	/**
	 * method called to mute TTS voice on toolbar icon click in OSE environment
	 * @param bool 
	 */
	toggleMuteSounds(bool) {
		this.globalService.toggleMuteSounds(bool);
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		} else if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	ngOnDestroy() {
		if (this.remoteService.remoteHostActivate) {
			this.remoteService.unRegisterRemoteHost();
		}
		this.unsubscribeTocheckToolbarStatusInputEvent();
	}
}
