import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, lastValueFrom } from "rxjs";
import { ToastController, Platform, AlertController } from "@ionic/angular";
import { CabriDataService } from "./cabri-data.service";
import { Router } from "@angular/router";
import { LrsService } from "./lrs.service";
import { environment } from "src/environments/environment";
import { LmsService } from "./lms.service";
import { ConnectionStatus, NetworkService } from "./network.service";
import { AccountService } from "./account.service";
import { GlobalService } from "./global.service";
import { AnalyticService } from "./analytic.service";
import { LocalStorageService, StorageKey } from "./local-storage-service";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { StoryService } from "./story.service";
import { BadgeService } from "./badge.service";

@Injectable({
	providedIn: "root"
})
export class ConnectionStatusUpdaterService extends BaseService {
	// Autorization to store data
	static needStoreAllJourneys = true;
	static needStoreAssignations = true;
	public rgpdUpdated = false;
	private firstTime: boolean;
	public permissionWrite: BehaviorSubject<any> = new BehaviorSubject(false);
	environment: any;
	public connectedStatus: boolean;
	public toast:HTMLIonToastElement

	constructor(
		private router: Router,
		private plt: Platform,
		public alertController: AlertController,
		public networkService: NetworkService,
		private accountService: AccountService,
		public lrsService: LrsService,
		public lmsService: LmsService,
		public cabriDataService: CabriDataService,
		public globalService: GlobalService,
		public analyticService: AnalyticService,
		public localStorageService: LocalStorageService,
		public http: HttpClient,
		public storyService: StoryService,
		public toastController: ToastController,
		public badgeService:BadgeService
	) {
		super();
		this.environment = environment;
		this.plt.ready().then(() => {
			this.subscribeNetworkEvent();
		});
	}

	public subscribeNetworkEvent() {
		//WARN not subscribed for the first time
		this.networkService.status.subscribe((connectionStatus: ConnectionStatus) => {
			if (connectionStatus === ConnectionStatus.Offline) {
				this.actionsWithoutConnexion();
				this.globalService.loadOcrModel().subscribe(model => {
					console.log("Ocr model Loaded");
				});
			} else {
				this.actionsWithConnexion();
			}
			if (!this.firstTime) {
				this.firstTime = true;
			} else {
				this.accountService.recoverStudentsInfos();
			}
		});
	}

	async actionsWithConnexion() {
		if (this.networkService.lastConnectionStatus === ConnectionStatus.Offline) {
			this.lmsService.allActivitiesLoaded = new ReplaySubject(1);
			this.lmsService.modalStudentJourneys = false;
			this.cabriDataService.getAllActivities();
		}
		this.lmsService.storedJourneyStatementLoad = false;

		if (this.networkService.offlineCounter >= 1) {
			const analyitcs = {
				id: this.accountService.team[0]?.id || undefined,
				codeClasse: this.accountService.user.codeClasse || undefined,
				nombre_requete_lrs: this.lrsService.offlineStatementsStore.length,
				nombre_fois_hors_ligne: this.networkService.offlineCounter
			};
			this.analyticService.sendAnalytics("hors_ligne", analyitcs);
		}


		let lrsDataToSend = false;
		let usersIdsMadeActivity:Array<string>
		if (this.lrsService.offlineStatementsStore?.length > 0 && !this.globalService.isKidaia) {
			usersIdsMadeActivity = this.lrsService.getUniqueUsersMadeStatementOffline();
			lrsDataToSend = true;
		}
		// send form datas values stored in indexDb
		if(lrsDataToSend){
			this.presentToast($localize`Synchronisation de vos données en cours. Veuillez ne pas recharger ni vous déconnecter pendant le processus de synchronisation.`,20000);
		}
		await this.sendStoryFormData();
		await this.lrsService.sendOfflineStatements();

		if(lrsDataToSend){
			this.accountService.updateUserProfile(usersIdsMadeActivity);
			if(this.toast){
				await this.toast.dismiss();
			}
			this.presentToast($localize`La synchronisation de vos données est maintenant terminée.`,2000);
		}
	}

	async presentToast(message,duration?) {
		this.toast = await this.toastController.create({
			message,
			duration: duration || undefined,
			position: "bottom",
			cssClass: "custom-toast-internet"
		});
		await this.toast.present();
	}

	/**
	 * Send storys progression for all users
	 */
	public async sendStoryFormData():Promise<void> {
		return new Promise<void>(resolve => {
			Promise.all([
				this.localStorageService.get(StorageKey.saveKidaiaExercice),
				this.localStorageService.get(StorageKey.saveKidaiaExerciceResult),
				this.localStorageService.get(StorageKey.saveChapterUser)
			]).then(async values => {
				let needUpdateStory = false;
				const getFormData = formElement => {
					// create a new form and append data to it using the saved data
					if (formElement) {
						let formData = new FormData();
						for (const [key, value] of Object.entries(formElement) as any) {
							formData.append(key, value);
						}
						return formData;
					}
				};

				if (values[0]) {
					// If there are new exercise(s) made in story mode find the corresponding exercise that contains results
					for (const index in values[0]) {
						const formData = getFormData(values[0][index]);
						if (formData) {
							try {
								// new exercise of story made so store id
								const exerciseId = await lastValueFrom(this.http.post<string>(this.postUrl, formData));
								needUpdateStory = true;
								const correspondingSavedExIndex = values[1].findIndex(exResult => {
									const saveKidaiaExerciceResult = getFormData(exResult);
									const formDataExResultTempId = saveKidaiaExerciceResult.get("tempId");
									const exerciseCreatioonTempId = formData.get("tempId");
									if (formDataExResultTempId === exerciseCreatioonTempId) {
										return exResult;
									}
								});

								if (correspondingSavedExIndex > -1) {
									const saveKidaiaExerciceResult = getFormData(values[1][correspondingSavedExIndex]);
									// set id_chapter_exercice with stored http post id
									saveKidaiaExerciceResult.set("id_chapter_exercice", exerciseId);
									// result on exercice story that have never been played
									try {
										await lastValueFrom(this.http.post<string>(this.postUrl, saveKidaiaExerciceResult));
									} catch (err) {
										console.error("error saveKidaiaExerciceResult", saveKidaiaExerciceResult);
									}
									// Remove the exercise results stored items that have already been sent so as to send the exercises that have already been played at least once
									values[1].splice(correspondingSavedExIndex, 1);
								}
							} catch (err) {
								console.error("err while sending story form datas");
							}
						}
					}
				}

				if (values[1]) {
					// result on exercice story that have already been played at least once
					for (const element of values[1]) {
						const saveKidaiaExerciceResult = getFormData(element);
						if (saveKidaiaExerciceResult) {
							try {
								await lastValueFrom(this.http.post<string>(this.postUrl, saveKidaiaExerciceResult));
								needUpdateStory = true;
							} catch (err) {
								console.error("err while sending story form datas");
							}
						}
					}
				}

				if (values[2]) {
					// need unlock new maps
					for (const element of values[2]) {
						const saveChapterUser = getFormData(element);
						if (saveChapterUser) {
							try {
								await lastValueFrom(this.http.post<string>(this.postUrl, saveChapterUser));
							} catch (err) {
								console.error("err while sending story form datas");
							}
						}
					}
				}

				this.localStorageService.remove(StorageKey.saveChapterUser);
				this.localStorageService.remove(StorageKey.saveKidaiaExerciceResult);
				this.localStorageService.remove(StorageKey.saveKidaiaExercice);

				if (this.networkService.toast) {
					this.networkService.toast.dismiss();
				}
				this.globalService.setGlobalLoading(false);

				if (needUpdateStory && this.accountService.team[0]?.id) {
					this.storyService.getStorys(this.accountService.team[0].id);
				}

			}).finally(() => {
				resolve();
			})
		});
	}

	actionsWithoutConnexion() {
		this.lmsService.storedJourneyStatementLoad = false;
		this.cabriDataService.getAllActivities();
		if (this.router.url === "/lecture" || this.router.url.startsWith("/record")) {
			this.router.navigateByUrl("/gabarits");
		}
	}
}
