import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { HttpRequest, HttpHandler, HttpInterceptor } from "@angular/common/http";
import { BaseService } from "./base.service";
import { environment } from "src/environments/environment";
import { WpSidService } from "./wp-sid.service";
import { ClassService } from "./class.service";

@Injectable({
	providedIn: "root"
})
export class TokenInterceptorService extends BaseService implements HttpInterceptor {
	constructor(
		public classService: ClassService,
		public wpSIdService : WpSidService, 
		@Inject(LOCALE_ID) public locale: string) {
		super();
	}
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		let tokenReq: HttpRequest<any>;
		if (req.url.startsWith(this.postTralalereUrl) || req.url.startsWith(this.postPreprodTralalereUrl)) {
			if (!req.url.endsWith("login-token")) {
				if (this.classService.isLoggedIn()){
					const idToken = localStorage.getItem("id_token_trala");
					tokenReq = req.clone({
						headers: req.headers.set("access-token", idToken)
					});
				} else if (environment.kidaia) {
					(window as any).open("https://kidaia.com/","_self");
				} else {
					tokenReq = req;
				}
			} else {
				tokenReq = req;
			}
		} else {
			const connectSid = this.wpSIdService.connectSid;
			let customHeaders = req.headers.set("ConnectionSid", connectSid);
			customHeaders = customHeaders.set("Locale", this.locale);
			customHeaders = customHeaders.set("ajaxMathia", "1");
			if (req.url.startsWith(this.postUrl) && this.classService.source){
				customHeaders = customHeaders.set("source", this.classService.source);
			}
			if(this.classService.isBeneylu){
				customHeaders = customHeaders.set("source", "beneylu");
			}
			if(this.classService.isMathador){
				customHeaders = customHeaders.set("source", "mathador");
			}

			tokenReq = req.clone({
				headers: customHeaders
			});
		 } 
		return next.handle(tokenReq);
	}
}
