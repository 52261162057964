<!-- Starboard Flat -->
<div *ngIf="!light" >

    <div *ngIf="!cabriService.mirrorMode" (click)="toggleStarboard()" [@slideInOut]="starboardState" class="starBoardContainer">
        <app-starboard [events]="eventsSubject.asObservable()" [units]="this.shootingStarsNumber" type="shootingStar"></app-starboard>
        <app-starboard [events]="eventsSubject.asObservable()" [units]="this.normalStarsNumber" type="normalStar"></app-starboard>
        <app-starboard [events]="eventsSubject.asObservable()" [units]="this.moonsNumber" type="moon"></app-starboard>
    </div>
    
    <div *ngIf="cabriService.mirrorMode" (click)="toggleStarboard()" [@slideInOutMirror]="starboardState" class="starBoardContainer">
        <app-starboard [events]="eventsSubject.asObservable()" [units]="this.shootingStarsNumber" type="shootingStar"></app-starboard>
        <app-starboard [events]="eventsSubject.asObservable()" [units]="this.normalStarsNumber" type="normalStar"></app-starboard>
        <app-starboard [events]="eventsSubject.asObservable()" [units]="this.moonsNumber" type="moon"></app-starboard>
    </div>

    <div *ngIf="starboardState === 'out'"  (click)="toggleStarboard()" class="starBoardButton">
        <div class="starBoardButtonInside">
            <ion-icon class='starBoardIcon' slot="start" name="trophy"></ion-icon>
        </div>
    </div> 

</div>

<!-- Starboard Holo -->
<div *ngIf="light">
    <div class="starBoardContainerLight">
        <app-starboard [light]="light" [events]="eventsSubject.asObservable()" [units]="this.shootingStarsNumber" type="shootingStar" [changeDetector]="changeDetector"></app-starboard>
        <app-starboard [light]="light" [events]="eventsSubject.asObservable()" [units]="this.normalStarsNumber" type="normalStar" [changeDetector]="changeDetector"></app-starboard>
        <app-starboard [light]="light" [events]="eventsSubject.asObservable()" [units]="this.moonsNumber" type="moon" [changeDetector]="changeDetector"></app-starboard>   
    </div>
</div>
