import { Observable, Subscriber } from "rxjs";

declare var window:any;

export class ScreenOrientationIOS {

    public ORIENTATIONS = {
        PORTRAIT_PRIMARY: 'portrait-primary',
        PORTRAIT_SECONDARY: 'portrait-secondary',
        LANDSCAPE_PRIMARY: 'landscape-primary',
        LANDSCAPE_SECONDARY: 'landscape-secondary',
        PORTRAIT: 'portrait',
        LANDSCAPE: 'landscape',
        ANY: 'any'
    };
    public type;
    private onChangeObservable: Observable<void>;
    private onChangeObservableSubscriber: Subscriber<void>;
    private cordovaPlugin: any;

    constructor(){
        this.type = window.screen.orientation.type;
        let counter = 0;
        while(this.type === this.ORIENTATIONS.ANY && counter < 100){
            this.type = window.screen.orientation.type;
            counter++;
        }
        this.cordovaPlugin = window.cordova.plugins.screenorientation;
        this.onChangeObservable = new Observable<void>( (subscriber) => {
            this.onChangeObservableSubscriber = subscriber;
        });
        this.onChangeObservable.subscribe();
        window.screen.orientation.onchange = (e) => {
            this.type = window.screen.orientation.type;
            this.onChangeObservableSubscriber.next();
        }
    }
    lock(orientation){
        if(orientation === this.ORIENTATIONS.LANDSCAPE_PRIMARY || orientation === this.ORIENTATIONS.LANDSCAPE_SECONDARY){
            orientation = this.ORIENTATIONS.LANDSCAPE;
        }
        this.type = orientation;
        this.cordovaPlugin.setOrientation(orientation);
    }
    unlock(){
        this.type = 'any';
        this.cordovaPlugin.setOrientation('any');
    }
    onChange(): Observable<void>{
        return this.onChangeObservable;
    }
}
