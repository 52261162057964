<div class="containerMap" *ngIf="currentUserWorld" [hidden]="!displayContent">
    <div class="mapTitleContainer">
        <div class="backgroundImgParam mapTitle">
            <p class="mapTitleContent">
                {{currentUserWorld.title}}
            </p>
        </div>
    </div>

    <div class="backgroundImgParam body">
        <div class="backgroundImgParam bodyGrid">

            <div class="eachGalaxyMapContainer">
                <div [class]="'eachGalaxyMap ' + systemName.tellurique" [hidden]="currentUserWorld.index !== 0">
                    <app-tellurique class="eachComponent"></app-tellurique>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.cassiopee + ' ' + systemName.olympe"
                    *ngIf="currentUserWorld.index === 1 || currentUserWorld.index === 3">
                    <app-etape class="eachComponent" [currentUserWorld]="currentUserWorld" [worldMaps]="worldMaps">
                    </app-etape>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.enfant" [hidden]="currentUserWorld.index !== 2">
                    <app-enfants class="eachComponent"></app-enfants>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.aeriens" [hidden]="currentUserWorld.index !== 4">
                    <app-aeriens class="eachComponent"></app-aeriens>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.galaxie7" [hidden]="currentUserWorld.index !== 5">
                    <app-galaxie7 class="eachComponent"></app-galaxie7>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.etape3" [hidden]="currentUserWorld.index !== 6">
                    <app-etape3 class="eachComponent"></app-etape3>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.galaxie8" [hidden]="currentUserWorld.index !== 7">
                    <app-galaxie8 class="eachComponent"></app-galaxie8>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.etape4" [hidden]="currentUserWorld.index !== 8">
                    <app-etape4 class="eachComponent" [currentUserWorld]="currentUserWorld" [worldMaps]="worldMaps">
                    </app-etape4>
                </div>
                <div [class]="'eachGalaxyMap '+ systemName.galaxie9" [hidden]="currentUserWorld.index !== 9">
                    <app-galaxie9 class="eachComponent"></app-galaxie9>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.etape5" *ngIf="currentUserWorld.index === 10">
                    <app-etape5 class="eachComponent" [currentUserWorld]="currentUserWorld" [worldMaps]="worldMaps">
                    </app-etape5>
                </div>

                <div [class]="'eachGalaxyMap '+ systemName.galaxie10" [hidden]="currentUserWorld.index !== 11">
                    <app-galaxie10 class="eachComponent"></app-galaxie10>
                </div>

            </div>
        </div>
    </div>
    <img class="chevronImg goLeftSlide" src="/assets/img/galaxy-carte/slide_left.png" (click)="goLeft()" alt="goLeft">
    <img class="chevronImg goRightSlide" src="/assets/img/galaxy-carte/slide_right.png" (click)="goRight()"
        alt="goRight">
</div>