import { Param } from './param';

export class RangeParam extends Param{

    className:string='RangeParam';
    min:number;
    max:number;
    step:number;

    constructor(param){
        super(param);
        this.min = param.min;
        this.max = param.max;
        this.step = param.step;
    }
}
