import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { GlobalService } from "src/app/services/global.service";
import { ConnectionStatus, NetworkService } from "src/app/services/network.service";
import { Grade, NoCategoryStatus } from "src/app/models/exercices";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { LmsService } from "src/app/services/lms.service";
import { CabriActivity } from "src/app/models/cabri-activity";
import { customRocketModalAnimation } from "src/app/models/custom-angular-animations";
@Component({
	selector: "app-exercises-statistics-details",
	templateUrl: "./exercises-statistics-details.component.html",
	styleUrls: ["./exercises-statistics-details.component.scss"],
	animations: [customRocketModalAnimation]
})
export class ExercisesStatisticsDetailsComponent implements OnInit {
	@Input() exerciseByStatus;
	@Input() connectionStatus;
	@Input() currentActivity: CabriActivity;
	@Input() index: number;
	displayStatisticsData = false;

	public get Object() {
		return Object;
	}

	constructor(
		public accountService: AccountService,
		public lmsService: LmsService,
		public globalService: GlobalService,
		public cabriService: CabriDataService,
		public networkService: NetworkService
	) {}

	ngOnInit() {}

	public get Grade() {
		return Grade;
	}

	public get NetworkService() {
		return NetworkService;
	}

	public get ConnectionStatus() {
		return ConnectionStatus;
	}

	public get NoCategoryStatus() {
		return NoCategoryStatus;
	}

	get totalCategoryExercise() {
		return new Array(this.exerciseByStatus.value.items.length);
	}
}
