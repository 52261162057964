<div class="modalHeadercloseButtonContainer" (click)="dismiss()">
    <ion-icon class="modalHeadercloseButton" name="close-outline">
    </ion-icon>
</div>
<iframe *ngIf="textUrl[urlType]?.url" [src]="textUrl[urlType].url | safe" (load)="globalService.setSmallLoading(false);" [hidden]="globalService.smallLoaderStatus">
</iframe>
<div id="small-loader" *ngIf="globalService.smallLoaderStatus">
    <img id="loaderGIF" src="/assets/babylon/loading_low_ose.gif" alt="chargement">
</div>

