import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from "@angular/core";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

@Component({
	selector: "app-image-viewer",
	templateUrl: "./image-viewer.component.html",
	styleUrls: ["./image-viewer.component.scss"]
})
export class ImageViewerComponent implements OnChanges {
	@ViewChild("pinchZoom") pinchZoom: PinchZoomComponent;
	@ViewChild("mainContainer", { static: false }) mainContainer: ElementRef;
	@ViewChild("swiper", { static: false }) slideElement;
	@Input() open: boolean;
	@Input() imageURL: string | { src: string; srcSet: string };
	@Input() gallery: Array<any>;

	@Output() close = new EventEmitter();
	public isLandscape = true;
	public slides: Array<string> = [];
	public swiperOptions: SwiperOptions = {
		noSwiping: true,
		noSwipingClass: "swiper-slide",
		allowTouchMove: false,
		loop: true
	}
	constructor() {}
	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes);
		if (
			changes.imageURL?.currentValue &&
			(!changes.imageURL.previousValue || changes.imageURL.previousValue.src !== changes.imageURL.currentValue.src)
		) {
			const img = new Image();
			img.onload = () => {
				console.log("image loaded");
				this.isLandscape = img.width > img.height;
				this.slides = [this.imageURL, ...this.gallery.filter(i => img.src !== i.src)];
			};
			img.src = changes.imageURL.currentValue.src;
		}

		// const zBtn = this.mainContainer.nativeElement.querySelector(".pz-zoom-button");
		// zBtn.removeEventListener("click", this.pinchZoom.toggleZoom);
		// zBtn.removeEventListener("click", this.customToggleZoom);
		// zBtn.addEventListener("click", this.customToggleZoom);
	}
	customToggleZoom($event) {
		$event.preventDefault();
		$event.stopPropagation();
		this.pinchZoom.toggleZoom();
	}

	onSwiperChange($event){
		console.log("swiper change", $event);
		const currentImg = this.slideElement?.elementRef.nativeElement.querySelector(".swiper-slide-active img");
		if(currentImg){
			const img = new Image();
			img.onload = () => {
				console.log("image loaded");
				this.isLandscape = img.width > img.height;
			};
			img.src = currentImg.src;
		}
	}
	swiperInitliazed($event){
		console.log("swiper initialized", $event);
	}


	nextSlide() {
		this.slideElement.swiperRef.slideNext();
	}

	prevSlide() {
		this.slideElement.swiperRef.slidePrev();
	}
}
