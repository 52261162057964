<div *ngIf="total" [ngClass]="{'oseMascotteContainer' : globalService.isOse || globalService.isPageOse,'oseMascotteCompleted':total === number}" class="progressCard mascotteContainer progressedPercentGrid"
  [ngStyle]="{'gridTemplateColumns': 'repeat('+ total + ',1fr' + ')'}">
  <ng-container *ngFor="let count of totalAsArray;let i = index;let last = last">
    <div class="boxProgression" [ngClass]="{'boxSelected' : number  >= i + 1,'boxNoSelected':number === 0, 'start': i == 0, 'end':last}">
      <div id="mascotteWrapper" [ngClass]="{'hasNoProgressed':number === 0,'hasProgressed': number > 0, 'isLast':last}" *ngIf="mascotVisible && ((number === 0 && i === 0 ) || number !== 0)"  [@.disabled]="globalService.lowPerformanceMode || number === 0"
        [hidden]="(globalService.isMobile && (!globalService.isOse && !globalService.isPageOse)) || (number !== i + 1 && number !== 0)"  [@animateRocket]="{value: state, params: { startPosition: number * 100 }}">
        <div id="mascotte">
        </div>
      </div>
    </div>
  </ng-container>
  <div id="ruche">
  </div>
</div>