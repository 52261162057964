import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from "@angular/core";

@Component({
  selector: "app-clock",
  templateUrl: "./clock.component.html",
  styleUrls: ["./clock.component.scss"],
})
export class ClockComponent implements OnInit, OnChanges {
  // Display of the component
  @Input() showDigital = false;
  @Input() showAnalog = false;
  @Input() multiAnalog = false;
  @Input() showHoursHand = true;
  @Input() showMinutesHand = true;
  // Vars for setting the time of the clock
  @Input() dateInput;
  private previousDate: Date = new Date(0);
  public date: Date = new Date(0);

  // Analog clock
  // Display of the analog clock
  @Input() showHourNumbers = true;
  @Input() showMinuteNumbers = true;
  @Input() hidingLevel = 1;
  public hiddenHours;
  public hiddenMinutes;
  // Vars for the drawing of the clock
  public index: number;
  public minors: number[];
  public majors: number[];
  public hoursPositions: { x: number; y: number }[];
  public minutesPositions: { x: number; y: number }[];
  // Svg measures for the construction of the clock
  public fontFamily = "Helvetica";
  public radiusClock = 48;
  public radiusMinorCircle = 37;
  public lengthMinorLine = 3;
  public radiusMajorCircle: number = this.radiusMinorCircle + 1;
  public lengthMajorLine = 7;
  public radiusMinutesCircle = 43;
  public minutesFontSize = 6;
  public radiusHoursCircle = 26;
  public hoursFontSize = 8;

  // Vars for the animation of the analog clock
  private hourBeginRotation: number;
  private hourEndRotation: number;
  private minutesBeginRotation: number;
  private minutesEndRotation: number;
  public hourAnimationValues: string;
  public minutesAnimationValues: string;
  @ViewChild("hourAnimation", { static: false }) hourAnimation: ElementRef;
  @ViewChild("minutesAnimation", { static: false })
  minutesAnimation: ElementRef;

  // Digital clock
  public digitalFormat = "HH:mm";

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.minors = new Array(60);
    this.majors = new Array(12);
    this.minutesPositions = this.calculateNumberPositions(
      this.radiusMinutesCircle,
      0,
      this.minutesFontSize / 2 - 1
    );
    this.hoursPositions = this.calculateNumberPositions(
      this.radiusHoursCircle,
      0,
      this.hoursFontSize / 2 - 1.5
    );
    // "Nothing to display" is forbidden
    if (!this.showDigital && !this.showAnalog) {
      this.showDigital = true;
      this.showAnalog = true;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // If both clocks are not shown, show both
    if (
      changes.showDigital &&
      changes.showAnalog &&
      !changes.showDigital.currentValue &&
      !changes.showAnalog.currentValue
    ) {
      changes.showDigital.currentValue = true;
      changes.showAnalog.currentValue = true;
    }
    // If minutes hand is hidden, set the minutes to 0
    if (changes.showMinutesHand && !changes.showMinutesHand.currentValue) {
      this.date.setMinutes(0);
    }
    // Change the time for analog and digital clocks when date changes
    if (changes.dateInput) { this.changeTime(changes.dateInput.currentValue); }
    // Hide clock numbers when difficulty changes
    if (changes.hidingLevel) { this.hideClockNumbers(changes.hidingLevel.currentValue); }
    this.detectChanges();
  }

  /*
   * @param radius The clock radius
   * @param cx X coordinates of the clock's center
   * @param cy Y coordinates of the clock's center
   */
  private calculateNumberPositions(radius, cx, cy) {
    const numberPositions = [];
    for (let i = 1; i <= 12; i++) {
      const x = radius * Math.cos(Math.PI / -2 + (2 * i * Math.PI) / 12) + cx;
      const y = radius * Math.sin(Math.PI / -2 + (2 * i * Math.PI) / 12) + cy;
      numberPositions.push({ x, y });
    }
    this.detectChanges();
    return numberPositions;
  }

  // Return the angle to add to the hour hand due to the minutes hand
  private getMinutesDeg(date: Date): number {
    if (this.showMinutesHand) {
      return date.getMinutes() / 2;
    } else {
      return 0;
    }
  }

  private hideClockNumbers(hidingLevel: number) {
    if (hidingLevel === 5) {
      // Everything is hidden
      this.showHourNumbers = false;
      this.showMinuteNumbers = false;
      this.hiddenHours = [false, false, false, false, false, false, false, false, false, false, false, false];
      this.hiddenMinutes = [false, false, false, false, false, false, false, false, false, false, false, false];
    } else if (hidingLevel === 4) {
      // Show 3,6,9,12 hours
      this.showMinuteNumbers = false;
      this.showHourNumbers = true;
      this.hiddenHours = [true, true, false, true, true, false, true, true, false, true, true, false];
      this.hiddenMinutes = [false, false, false, false, false, false, false, false, false, false, false, false];
    } else if (hidingLevel === 3) {
      // Show all the hours
      this.showMinuteNumbers = false;
      this.showHourNumbers = true;
      this.hiddenHours = [false, false, false, false, false, false, false, false, false, false, false, false];
      this.hiddenMinutes = [false, false, false, false, false, false, false, false, false, false, false, false];
    } else if (hidingLevel === 2) {
      // Show 0,15,30,45 minutes
      this.showMinuteNumbers = true;
      this.showHourNumbers = true;
      this.hiddenHours = [false, false, false, false, false, false, false, false, false, false, false, false];
      this.hiddenMinutes = [true, true, false, true, true, false, true, true, false, true, true, false];
    }  else if (hidingLevel === 1 ) {
      // Show everything
      this.showMinuteNumbers = true;
      this.showHourNumbers = true;
      this.hiddenHours = [false, false, false, false, false, false, false, false, false, false, false, false];
      this.hiddenMinutes = [false, false, false, false, false, false, false, false, false, false, false, false];
    } else {
      // Show all the minutes, hidden hours
      this.showMinuteNumbers = true;
      this.showHourNumbers = false;
    }
    this.detectChanges();
  }

  // Change the time and animate the clock
  // Usage : changeTime("2020-07-02T05:22:45.135+02:00")
  public changeTime(date) {
    this.previousDate = this.date;
    this.date = new Date(date);
    // Analog clock
    if (this.showAnalog) {
      // Calculate the angle of the clock hands
      this.hourBeginRotation =
        30 * this.previousDate.getHours() +
        this.getMinutesDeg(this.previousDate);
      this.hourEndRotation =
        30 * this.date.getHours() + this.getMinutesDeg(this.date);
      this.minutesBeginRotation = 6 * this.previousDate.getMinutes();
      this.minutesEndRotation = 6 * this.date.getMinutes();
      // Generate the values for the rotation of the clock hands
      this.hourAnimationValues = this.generateRotationValues(
        this.hourBeginRotation,
        this.hourEndRotation
      ).join(";");
      this.minutesAnimationValues = this.generateRotationValues(
        this.minutesBeginRotation,
        this.minutesEndRotation
      ).join(";");
      // Launch the animation
      if (this.hourAnimation) { this.hourAnimation.nativeElement.beginElement(); }
      if (this.minutesAnimation) {
        this.minutesAnimation.nativeElement.beginElement();
      }
    }
    this.detectChanges();
  }
  // Calculate the percentage of advancement of the animation
  // x: between 0 and 1
  public easeOutBack(x: number): number {
    const c1 = 1.70158;
    const c3 = c1 + 1;
    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  }
  // Generate the rotation values (for the clock hands) according to the 'ease' function
  // ex with easeOutBack : generateRotVal(180,10) -> [180;110;60;26;5;-5;-7;-4;2;8;10]
  public generateRotationValues(beginDeg, endDeg) {
    const difference = endDeg - beginDeg;
    const values = [];
    for (let i = 0; i <= 1; i += 0.1) {
      values.push(Math.round(beginDeg + difference * this.easeOutBack(i)));
    }
    this.detectChanges();
    return values;
  }
  detectChanges() {
		if (this.cd) {
			this.cd.detectChanges();
		}
	}
}

