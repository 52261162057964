import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Console } from "console";
import { GlobalService } from "src/app/services/global.service";

@Component({
	selector: "app-custom-simple-progressbar",
	templateUrl: "./custom-simple-progressbar.component.html",
	styleUrls: ["./custom-simple-progressbar.component.scss"]
})
export class CustomSimpleProgressbarComponent implements AfterViewInit, OnInit, OnDestroy {
	@Input() percent: number;
	@Input() mobileLandscape: boolean;
	@ViewChild("progressBarPercent", { static: false }) progressBarPercent: ElementRef;
	percentBox: HTMLBaseElement;
	constructor(public globalService: GlobalService, public elementRef: ElementRef, public renderer: Renderer2) {}

	ngAfterViewInit(): void {
		if (this.percent) {
			this.percentBox = this.elementRef.nativeElement.querySelector(".boxProgressionPercent");

			if (this.percentBox && !this.globalService.lowPerformanceMode) {
				this.percentBox.addEventListener("animationend", this.pulseAnimation.bind(this));
			}
		}
	}

	ngOnInit(): void {
		if (!this.mobileLandscape) {
			this.mobileLandscape = this.globalService.isMobile;
		}
	}

	ngOnDestroy() {
		if (this.percentBox && !this.globalService.lowPerformanceMode) {
			this.percentBox.removeEventListener("animationend", this.pulseAnimation.bind(this));
		}
	}

	pulseAnimation() {
		if (this.percent === 100) {
			const percentResultBox = this.elementRef.nativeElement.querySelector(".percentResult");
			this.renderer.addClass(percentResultBox, "pulse");
		}
	}
}
