export class CastelCell {

    public value;
    public id;
    public state: string;
    private castelGrid;
    public hilightCell: false;
    public resultClass: string;
	public selected = false;

	public done = false;

    constructor(id, value, castelGrid) {
        this.id = id;
        this.value = value;
        this.castelGrid = castelGrid;
    }

    get row() {
        return Math.floor(this.id / this.castelGrid.nbCols);
    }
    get col() {
        return this.id % this.castelGrid.nbCols;
    }
}
