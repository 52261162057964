import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AccountService } from "./account.service";
import { Observable, Subject, BehaviorSubject, ReplaySubject, lastValueFrom } from "rxjs";
import { CabriDataService } from "./cabri-data.service";
import { GlobalLrs } from "../models/lrs/globalLrs";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { NetworkService } from "./network.service";
import { GlobalService } from "./global.service";
import { LrsStatement, StatementState, StatementObjectProgression } from "../models/lrs-statement";
import { LmsService } from "./lms.service";
import { Student } from "../models/student";
import { XapiContext, XapiExtensions } from "../models/lrs/xapicontext";
import { Platform } from "@ionic/angular";
import { Phrase } from "../models/phrase";
import { Status } from "../models/proposed-activity";
import { AppUtils } from "../app-utils";
import { Statement } from "../models/statement";
import { XapiObject, XObjectType } from "../models/lrs/xapiobject";
import { LrsVerbs } from "../models/lrs/xapiverbs";
import { OseJourneyService } from "./ose-journeys.service";
import { environment } from "src/environments/environment";
import { XApiResult } from "../models/lrs/xapiresult";
import { ClassService } from "./class.service";
import { NavigationEnd, Router } from "@angular/router";

declare var window: any;
@Injectable({
	providedIn: "root"
})
export class LrsService {
	private _BASE_URL = "https://lrs.mathia.education";

	private authorization = "N2FlODA5YTZlMGMwYWFmMGZmYzgwOWQ2MWY0MzQwYjM5Y2M2NjUzNDphOWRmMTEzMWY1MGRjZDAzNTcyZGZhZmJhMzZlMzFlMGUzNWU2MTU2";
	private authorizationOse =
		"NDdhZTg1NzQwMDE3ODYwYWNhYjQ1OGRiNmExYTQ3OTQ2NzU1YWVlNDo5OGY3M2I0NGMyN2Y0ZTEwNzQ1MjFmNTk5OTY2YWNiZTlkMTgyYzE4";
	private authorizationAren =
		"NmFkZThiZDRhMzNlZTNjMzZhZDA2ZmRkY2QxN2Y5Y2M4MTg5MTI3NDowMjM5ZmJiYjMzOGM0YjUyNTdlOTk0ZGZlMDBlMTQ4YjI1NTM4YTE4";

	private authorizationBeneylu =
		"NDM5NDM3M2E1Mjc5NGNiMTVmMWIyZGZkZDJmOTkyMjllNTNhYTJlMjpkZDljYjczNWYxNmU5OWUyNmM5MTE1OTNiOTY4MmIxMWEyMTIyYzEz";

	private authorizationMathador =
		"ZjQ2Nzc3ZGIwYzg0NTA0ZjMwNzBlMjFhOWI0MzkyMWZkNmZlMWMzYjoxYjQ3ODdkMmFkZjA2NjNiZTRhMjlhYzQ1NTdiODZiNDg3MmMwZDQ5";

	private mathiaKidaiaOrganisation = "5e416fa25dc57248ec03cbb8";
	private oseOrganisation = "63048cc2c9e32f71bb9ff82b";
	private arenOrganisation = "631f08ac60e2854640070b7b";
	private beneyluOrganisation = "6352bebc8f9d113dfe75f979";
	private mathadorOrganisation = "63ea6bb99ca66d3e1bb1c7ff";

	private experienceApiHeader: string;
	public offlineStatementsStore = new Array();
	public initActivity: GlobalLrs;
	// gamification: StoredLrsStatements;
	public canSendCompletedStatement: BehaviorSubject<boolean>;
	public durationStartActivity: number;

	// temp
	public userAutenticated = new BehaviorSubject<boolean>(false);
	public statement: LrsStatement;

	public startExercise: LrsStatement;
	public finishedExercise: LrsStatement;

	public finishedStatement: LrsStatement;
	public authenticated = new Subject<void>();
	public reponse: LrsStatement;

	// common params for all type of statements (start,esponse,completion)
	public commonLrsParams: XapiContext;
	public allLrsVerbs: any;
	environment;

	// newStatementSend = new Subject();
	newStatementSend = new ReplaySubject(1);

	constructor(
		private http: HttpClient,
		private accountService: AccountService,
		private lmsService: LmsService,
		public cabriService: CabriDataService,
		public networkService: NetworkService,
		public globalService: GlobalService,
		public platform: Platform,
		public oseJourneyService: OseJourneyService,
		public classService: ClassService,
		public router:Router
	) {
		// this.statement = new LrsStatement(this.requirements());
		this.environment = environment;
		
		// check URL params
		window.params = new URLSearchParams(window.location.search);
		const urlParams = window.params ? Array.from(window.params) : [];
		const urlParamsObj = AppUtils.objectify(urlParams);
		this.globalService.checkIframeIntegration(urlParamsObj, classService);

		this.checkAuthenticateUser();
		this.cabriService.setLrsService(this);
		this.platform.ready().then(async () => {
			LrsUtils.statementUrl = this.statementUrl;
			// to change extensions string key based on ose/mathia
			const extensions = new XapiExtensions();
			if (localStorage.getItem("statement")) {
				this.offlineStatementsStore = JSON.parse(localStorage.getItem("statement"));
			}
		});
		this.getXExpVersion().subscribe(data => {
			this.experienceApiHeader = data["version"][0];
		});

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				LrsUtils.statementUrl = this.statementUrl;
				const extensions = new XapiExtensions();
			}
		});
	}

	private get statementUrl() {
		return this.globalService.isPageOse || this.globalService.isOse ? Statement.URL_OSE : Statement.URL_MATHIA;
	}

	/**
	 * Header for LRS Requests (GET,POST)
	 *
	 * @param autorization autorization key (GET autorization is stored in Local storage otherwise(SEND) use key given by LRS)
	 */
	lrsHttpOptionsRequest(autorization: string) {
		return {
			headers: new HttpHeaders({
				Authorization: `Basic ${autorization}`,
				"Content-Type": "application/json",
				"X-Experience-API-Version": this.experienceApiHeader ? this.experienceApiHeader : "1.0.3"
			})
		};
	}

	/**
	 * Reading statement send at the start of the activity
	 */
	async createReadingParamOnStart() {
		const onStart = new LrsStatement(this.requirements(), false);
		let connectSid = localStorage.getItem("connect_sid");
		if (!connectSid) {
			connectSid = "user";
		}
		onStart.setActor(connectSid);
		await onStart.setVerb(null, "initialized");
		onStart.setObject(StatementObjectProgression.start);
		onStart.setReadingContext();
		return onStart;
	}

	/**
	 * Reading statement send during the activity and when completed
	 */
	async createReadingParamOnNormal(currentPhrase: Phrase, activityId: number, verb: string) {
		const onResponse = new LrsStatement(this.requirements(), false);
		let connectSid = localStorage.getItem("connect_sid");
		if (!connectSid) {
			connectSid = "user";
		}
		onResponse.setActor(connectSid);
		await onResponse.setVerb(null, verb);
		onResponse.setObjectReadingOnNormal(currentPhrase, String(activityId));
		onResponse.setReadingContext(activityId);
		return onResponse;
	}

	// /**
	//  * Statement send for exercise at the start of the activity
	//  */
	async getExerciseParams(): Promise<LrsStatement> {
		const exercise = new LrsStatement(this.requirements());
		exercise.setActor(this.accountService.team);
		await exercise.setVerb(StatementState.exercise);
		exercise.setObject(StatementObjectProgression.start, false);
		exercise.setContext();
		return exercise;
	}

	/**
	 * Statement send if the journey has been started or resumed at the start of the activity
	 */
	async getJourneyParams(currentUser: Student): Promise<LrsStatement> {
		const journey = new LrsStatement(this.requirements());
		if (journey.isJourneyHaveState) {
			journey.setActor(currentUser);
			await journey.setVerb(StatementState.journey);
			journey.setObject(StatementObjectProgression.start, true);
			journey.setContext();
			return journey;
		} else {
			return null;
		}
	}

	/**
	 * Statement send for the response during the activity
	 */
	async responseParams(currentUser: Student, verb: string): Promise<LrsStatement> {
		const response = new LrsStatement(this.requirements());
		response.setActor(currentUser);
		await response.setVerb(StatementState.question, verb);
		response.setResult(verb);
		response.setContext();
		response.setObject(StatementObjectProgression.response, false);
		return response;
	}

	/**
	 * Statement send for exercise at the start of the activity
	 */
	public manageNastyStartActivity(userId: any, timestamp, binome, isTypeJourney = false) {
		// const lrsUtils = new LrsStatement(this.requirements());
		const adaptative = LrsUtils.getExercisesAdaptativeInfos2(this.cabriService);

		let id, objTitle, objDescription, objType;

		if (this.lmsService.currentUserJourney) {
			LrsUtils.parcoursId = this.lmsService.currentUserJourney.id;
			// journey exercise step
			LrsUtils.jounreyExerciseStep = this.lmsService.currentUserJourney.exercises.find(ex => {
				return ex.status !== Status.done;
			})?.step;
		}
		if (isTypeJourney) {
			id = this.lmsService.currentUserJourney.id;
			objType = XObjectType.journey;
			objTitle = objDescription = this.lmsService.currentUserJourney.title;
		} else {
			id = this.cabriService.currentExercice.id;
			objType = XObjectType.exercise;
			objTitle = this.cabriService.currentExercice.name;
			objDescription = this.cabriService.currentExercice.description;
		}

		const objId = `https://xapi.mathia.education/${objType}/id/${id}`;

		const context = new XapiContext(this.accountService.team, {
			...this.globalActivityExtensionsCabri(binome)
		});
		const object = new XapiObject(objId, objTitle, objDescription, objType, {
			[XapiExtensions.competences]: adaptative.competences,
			[XapiExtensions.erreursType]: adaptative.erreursTypes,
			[XapiExtensions.prerequis]: adaptative.prerequis,
			[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
			[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
		});
		const statement = new Statement(userId, LrsVerbs.initialized, object, context, null, timestamp);
		return statement;
	}

	/**
	 * Statement send at the end of the activity
	 */
	completedExerciseParams(verb: string, challengeMod: boolean): Promise<Array<LrsStatement>> {
		let students: Array<Student>;
		let journeyEnd = false;
		return new Promise<Array<LrsStatement>>(async resolve => {
			if (this.lmsService.currentUserJourney) {
				if (!this.lmsService.currentUserJourney.nextActivityProposed) {
					// journey completed so send two statements for the same user
					students = new Array(2).fill(LrsUtils.currentUser);
					journeyEnd = true;
				} else {
					// journey mode but journey not completed yet
					students = new Array(LrsUtils.currentUser);
				}
			} else {
				// normal mode
				// for challenge mode send only statement fr the current user
				// @TODO if all students completed successfully without elimination
				students = !challengeMod ? this.accountService.team : new Array(LrsUtils.currentUser);
			}
			const statementCurrentStudent = new Array<LrsStatement>();
			for (let i = 0; i <= students.length - 1; i++) {
				const completedExercise = new LrsStatement(this.requirements());
				completedExercise.setActor(students[i]);
				await completedExercise.setVerb(StatementState.question, verb);
				completedExercise.setContext();
				if (i === 1 && journeyEnd) {
					completedExercise.setObject(StatementObjectProgression.completed, true);
				} else {
					completedExercise.setObject(StatementObjectProgression.completed, false);
				}
				completedExercise.setResult(verb);
				statementCurrentStudent.push(completedExercise);
			}
			resolve(statementCurrentStudent);
		});
	}

	// /**
	//  * Object executed on student response.From second statement until the before last statement
	//  */
	public manageNastyLrsOperation(verb: string, responseDuration, timeStamp, binome) {
		const lrsUtils = new LrsStatement(this.requirements());
		const { currentOperation, response } = this.generateOperationResponse2(verb);
		const getTotalDurationAsTimestamp = () => {
			// last timestamp in array and add some milliseconds to send last statement
			if (verb === LrsVerbs.finished) {
				const newDateFinished = new Date(timeStamp);
				// add 10 millisecond to send statement (passed,failed..) after finished
				const firstFinishedTimeStamp = newDateFinished.setMilliseconds(10);
				return LrsUtils.convertDateToISO8601(firstFinishedTimeStamp);
			} else {
				return LrsUtils.convertDateToISO8601(timeStamp);
			}
		};

		LrsUtils.currentOperation = currentOperation;
		const adaptative = LrsUtils.getExercisesAdaptativeInfos2(this.cabriService);
		const { definitionName, definitionDescription } = lrsUtils._setDefinitionParams();
		const objId = `https://xapi.mathia.education/question/id/${this.cabriService.currentExercice.id}`;
		const iso8601 = LrsUtils.duration8601(responseDuration);
		const result = new XApiResult(verb, iso8601);

		const context = new XapiContext(this.accountService.team, {
			...this.globalActivityExtensionsCabri(binome),
			[XapiExtensions.reponse]: response,
			[XapiExtensions.hid]: LrsUtils.currentOperation ? LrsUtils.generateUniqueId() : undefined
		});

		const object = new XapiObject(objId, definitionName, definitionDescription, XObjectType.question, {
			[XapiExtensions.competences]: adaptative.competences,
			[XapiExtensions.erreursType]: adaptative.erreursTypes,
			[XapiExtensions.prerequis]: adaptative.prerequis,
			[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
			[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
		});
		const statement = new Statement(LrsUtils.currentUser.id, verb, object, context, result, getTotalDurationAsTimestamp());
		return statement;
	}

	manageNastyEndOfActivity(allStudents, timestamps, isJourneyType) {
		const adaptative = LrsUtils.getExercisesAdaptativeInfos2(this.cabriService);
		const getTotalDurationAsTimestamp = () => {
			// last timestamp in array and add some milliseconds to send last statement
			const addMilliSec = this.lmsService.currentUserJourney ? 20 : 1000;
			const newDateCompleted = new Date(timestamps[timestamps.length - 1]);
			const completedTimeStamp = newDateCompleted.setMilliseconds(addMilliSec);

			return LrsUtils.convertDateToISO8601(completedTimeStamp);
		};

		const getPercentageResponse = () => {
			const allVerbs = allStudents.find(student => {
				return student.studentId === LrsUtils.currentUser.id;
			})?.verbs;

			if (!this.lmsService.currentUserJourney) {
				if (allVerbs?.length > 0) {
					const shooting = allVerbs.filter(verbElement => {
						return verbElement.verb === LrsVerbs.passed || verbElement.verb === LrsVerbs.passedWithHelp;
					});
					const moons = allVerbs.filter(verbElement => {
						return verbElement.verb === LrsVerbs.failed || verbElement.verb === LrsVerbs.failedOnFirstAttempt;
					});

					const perTotalGoodAnswer = (shooting.length / allVerbs.length) * 100;
					const perTotalWrongAnswer = (moons.length / allVerbs.length) * 100;
					if (perTotalGoodAnswer && perTotalWrongAnswer) {
						return { perTotalWrongAnswer, perTotalGoodAnswer };
					} else {
						// security
						return { perTotalWrongAnswer: 0, perTotalGoodAnswer: 0 };
					}
				} else {
					// security
					return { perTotalWrongAnswer: 0, perTotalGoodAnswer: 0 };
				}
			} else {
				let totalItems = 0,
					totalShootings = 0,
					totalMoonsNumber = 0;

				allVerbs.forEach(verbElements => {
					const currShootingNumber = verbElements.filter(eachVerbElement => {
						return eachVerbElement.verb === LrsVerbs.passed || eachVerbElement.verb === LrsVerbs.passedWithHelp;
					})?.length;

					const currMoonsNumber = verbElements.filter(eachVerbElement => {
						return eachVerbElement.verb === LrsVerbs.failed || eachVerbElement.verb === LrsVerbs.failedOnFirstAttempt;
					})?.length;

					totalItems += verbElements?.length || 0;
					totalShootings += currShootingNumber || 0;
					totalMoonsNumber += currMoonsNumber || 0;
				});

				const perTotalGoodAnswer = (totalShootings / totalItems) * 100;
				const perTotalWrongAnswer = (totalMoonsNumber / totalItems) * 100;
				if (perTotalGoodAnswer && perTotalWrongAnswer) {
					return { perTotalWrongAnswer, perTotalGoodAnswer };
				} else {
					return { perTotalWrongAnswer: 0, perTotalGoodAnswer: 0 };
				}
			}
		};

		const getTotalActivityDurationSecondsIso = durationJourney => {
			if (timestamps) {
				if (!durationJourney) {
					const startTimestamp = timestamps[0] - 5000;
					const lastTimestamp = timestamps[timestamps.length - 1] + 1000;
					const diffSeconds = (lastTimestamp - startTimestamp) / 1000;

					return LrsUtils.duration8601(diffSeconds);
				}
			}
		};

		let objId, objTitle, objDescription, objType;
		if (isJourneyType) {
			objId = this.lmsService.currentUserJourney.id;
			objType = XObjectType.journey;
			objTitle = objDescription = this.lmsService.currentUserJourney.title;
		} else {
			objId = this.cabriService.currentExercice.id;
			objType = XObjectType.exercise;
			objTitle = this.cabriService.currentExercice.name;
			objDescription = this.cabriService.currentExercice.description;
		}
		const result = new XApiResult(LrsVerbs.completed, getTotalActivityDurationSecondsIso(false));

		const context = new XapiContext(this.accountService.team, {
			...this.globalActivityExtensionsCabri(false),
			[XapiExtensions.pourcentageBonnesReponses]: getPercentageResponse().perTotalGoodAnswer,
			[XapiExtensions.pourcentageMauvaisesReponses]: getPercentageResponse().perTotalWrongAnswer,
			[XapiExtensions.dureeActivite]: getTotalActivityDurationSecondsIso(false)
		});
		const object = new XapiObject(`https://xapi.mathia.education/${objType}/id/${objId}`, objTitle, objDescription, objType, {
			[XapiExtensions.competences]: adaptative.competences,
			[XapiExtensions.erreursType]: adaptative.erreursTypes,
			[XapiExtensions.prerequis]: adaptative.prerequis,
			[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
			[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
		});
		const statement = new Statement(LrsUtils.currentUser.id, "completed", object, context, result, getTotalDurationAsTimestamp());
		return statement;
	}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	async timeOut(ms): Promise<void> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	}

	/**
	 * Définir les paramètres de l'activité
	 * @param currentActivity
	 */

	defineRandomActivityParams() {
		this.cabriService.currentActivity.params.forEach(param => {
			if (!param.hidden) {
				if (param.name === "remoteHost" || param.name === "v-display-mode" || param.name === "input-method") {
					const paramValue = this.statement.getRandomItem(param.selectionList);
					this.cabriService.currentActivity.setParamValue(param.name, paramValue[0]);
				}
			}
		});
	}

	get BASE_URL() {
		return this._BASE_URL;
	}

	getXExpVersion() {
		return this.http.get(`${this.BASE_URL}/data/xAPI/about`);
	}

	/**
	 * Check if need to display the tutorial for each activity based on students
	 */
	showTuto() {
		return new Promise<boolean>(async (resolve, reject) => {
			const allMembers = new Array();
			if (!this.cabriService.currentActivity || !this.cabriService.currentActivity.id) {
				reject();
				return;
			}
			if (
				!this.accountService.team ||
				(this.accountService.team && Array.isArray(this.accountService.team) && this.accountService.team.length === 0)
			) {
				reject();
				return;
			}
			if (this.globalService.isBeneylu) {
				reject();
				return;
			}

			this.accountService.team.forEach(member => {
				allMembers.push(member.id);
			});

			const statement = [
				{
					$match: {
						timestamp: {
							$gte: {
								$dte: "2020-01-01T19:30:00.000Z"
							}
						}
					}
				},
				{
					$match: {
						$and: [
							{
								"statement.verb.id": {
									$in: ["https://xapi.mathia.education/verbs/initialized"]
								}
							},
							{
								"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
									$nin: [null]
								}
							},
							{
								"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite": {
									$in: [this.cabriService.currentActivity.id]
								}
							},
							{
								"statement.actor.account.name": {
									$in: allMembers
								}
							}
						]
					}
				},
				{
					$project: {
						iduser: "$statement.actor.account.name",
						idsession: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session"
					}
				},
				{
					$group: {
						_id: "$iduser",
						nbsession: { $sum: 1 }
					}
				},
				{
					$sort: {
						timestamp: -1
					}
				}
			];
			const uri = AppUtils.encodeURI(statement);
			this.find(`${this.BASE_URL}/api/statements/aggregate?pipeline=${uri}`).subscribe({
				next: data => {
					let show = true;
					console.log("showTuto result",data)
					if (data.length === this.accountService.team.length) {
						// ne pas montrer le tuto, si au moins un élève a fait l'exercice plus de 3 fois.
						data.forEach(value => {
							if (value.nbsession > 40) {
								show = false;
							}
						});
					}
					resolve(show);
				},
				error: err => {
					reject();
				}
			});
		});
	}

	getAuthorization() {
		let authorization = this.authorization;
		if (this.globalService.isOse) {
			authorization = this.authorizationOse;
		}
		if (this.globalService.isAren) {
			authorization = this.authorizationAren;
		}
		if (this.globalService.isBeneylu) {
			authorization = this.authorizationBeneylu;
		}
		if(this.globalService.isMathador){
			authorization = this.authorizationMathador;
		}
		return authorization;
	}
	lrsAuthentication(body): Observable<any> {
		const httpOptions = this.lrsHttpOptionsRequest(this.getAuthorization());
		return this.http.post(`${this.BASE_URL}/api/v2/lrs`, body, httpOptions);
	}

	/**
	 * Send a request to LRS aggregate pipeline.
	 * @param request mongodb find request ($match, $project...)
	 * @returns json statement collection
	 */
	find(request): Observable<any> {
		const httpOptions = this.lrsHttpOptionsRequest(this.getAuthorization());
		return this.http.get(request, httpOptions);
	}

	/**
	 * Send a request as statement to LRS
	 * @param statement LRS statement model
	 */
	sendRequest(statement): Observable<any> {
		const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
		const httpOptions = this.lrsHttpOptionsRequest(encodedClientBasicAuth);
		return this.http.post(`${this.BASE_URL}/data/xAPI/statements`, statement, httpOptions);
	}

	createStore(code) {
		const postBody = {
			title: code,
			organisation: this.getOrganisationId()
		};
		return this.lrsAuthentication(postBody);
	}
	getOrganisationId() {
		let organisationId = this.mathiaKidaiaOrganisation;
		if (this.globalService.isOse) {
			organisationId = this.oseOrganisation;
		}
		if (this.globalService.isAren) {
			organisationId = this.arenOrganisation;
		}
		if (this.globalService.isBeneylu) {
			organisationId = this.beneyluOrganisation;
		}
		if (this.globalService.isMathador) {
			organisationId = this.mathadorOrganisation;
		}
		return organisationId;
	}
	createReadingStatementOnStart() {
		this.initActivity = new GlobalLrs(
			this,
			this.lmsService,
			this.cabriService,
			this.accountService,
			this.globalService,
			this.networkService
		);

		const currentStatement = this.initActivity.createReadingActivityStatementOnLoad() as any;
		this.sendRequest(currentStatement);
	}

	createStatementRecordOnEvent(phrase, activityId, verbsState) {
		const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");

		if (!encodedClientBasicAuth) {
			this.getAllStores(0).subscribe(data => {
				if (data.length === 0) {
					this.createAsyncStore(0).then(() => {
						this.createRecordStatement(phrase, activityId, verbsState);
					});
				} else {
					localStorage.setItem("lrs_id", data[0]["_id"]);
					this.getBasicAuthClient().then(() => {
						this.statementCreationBody();
					});
				}
			});
			return;
		}

		this.createRecordStatement(phrase, activityId, verbsState);
	}

	createRecordStatement(phrase, activityId, verbId) {
		if (!this.initActivity) {
			this.initActivity = new GlobalLrs(
				this,
				this.lmsService,
				this.cabriService,
				this.accountService,
				this.globalService,
				this.networkService
			);
		}

		const currentStatement = this.initActivity.createReadingStatementOnResponse(phrase, activityId, verbId) as Statement;
		this.sendRequest(currentStatement);
	}

	requirements() {
		return {
			accountService: this.accountService,
			lmsService: this.lmsService,
			globalService: this.globalService,
			cabriService: this.cabriService,
			lrsService: this,
			http: this.http,
			networkService: this.networkService
		};
	}

	/**
	 * Called on page init
	 */
	createCabriStatementOnStartActivity() {
		const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
		if (!encodedClientBasicAuth) {
			this.getAllStores(0).subscribe({
				/** IF USER NOT EXIST YET IN LRS SO CREATE HIM BEFORE OF SAVING THE FIRST STATEMENT(INITIALIZATION)  */
				next: data => {
					if (data.length === 0) {
						this.createAsyncStore(0).then(() => {
							this.statementCreationBody();
						});
					} else {
						localStorage.setItem("lrs_id", data[0]["_id"]);
						this.getBasicAuthClient().then(() => {
							this.statementCreationBody();
						});
					}
				},
				error: err => {
					this.statementCreationBody();
				}
			});
		} else {
			this.statementCreationBody();
		}
	}

	private statementCreationBody() {
		this.initActivity = new GlobalLrs(
			this,
			this.lmsService,
			this.cabriService,
			this.accountService,
			this.globalService,
			this.networkService
		);

		const currentStatement: any = this.initActivity.createCabriActivityStatementOnLoad();
		if (Array.isArray(currentStatement)) {
			currentStatement.forEach(statement => {
				this.sendRequest(statement).subscribe({
					next: request => {},
					error: err => {
						console.error("err statement", err);
						this.offlineStatementSave(statement, "initialized");
					}
				});
			});
		} else {
			this.sendRequest(currentStatement).subscribe({
				next: request => {
					if (this.globalService.fastBilanFinished) {
						this.createStatementActivity("completed", true);
					}
				},
				error: err => {
					console.log("errrr", err);
					const paramVerb = this.lmsService.currentUserJourney ? "resume" : "initialized";
					this.offlineStatementSave(currentStatement, paramVerb);
				}
			});
		}
	}

	async sendStatements(verbId, response, endActivity = false, endActivityOneUser = false){

		// send anser result statement
		await this.createStatementResponse(verbId, response);

		// send question finished statement
		await this.sendFinishedStatement("finished", response);

		if (endActivity) {
			// send end of activity statement
			this.createStatementActivity("completed", endActivityOneUser);
		}
	}

	createStatementResponse(verbId, response) {
		return new Promise<void>( (resolve) => {
			this.canSendCompletedStatement.next(false);
			this.initActivity.lastVerb = verbId;
			this.checkExistingBasicAuth().then(data => {
				const currentStatement = this.initActivity.createCabriStatementOnResponse(verbId, response) as any;
				this.sendRequest(currentStatement).subscribe({
					next: async request => {
						this.canSendCompletedStatement.next(true);
						resolve();
					},
					error: async err => {
						this.canSendCompletedStatement.next(true);
						this.offlineStatementSave(currentStatement, "response");
						resolve();
					}
				});
			});
		});
	}

	/**
	 * Finished statement
	 */

	async sendFinishedStatement(verbsModel, response) {
		return new Promise<void>((resolve, reject) => {
			const currentStatement = this.initActivity.createCabriStatementOnResponse(verbsModel, response) as any;
			this.sendRequest(currentStatement).subscribe({
				next: request => {
					this.initActivity.lastVerb = null;
					resolve();
				},
				error: err => {
					this.offlineStatementSave(currentStatement, "response");
					this.initActivity.lastVerb = null;
					resolve();
				}
			});
		});
	}

	async createStatementActivity(verbsModel, forCurrentUser = false): Promise<void> {
		return new Promise((resolve, reject) => {
			const currentStatement = this.initActivity.createCabriStatementActivity(verbsModel, forCurrentUser) as any;
			if (Array.isArray(currentStatement)) {
				currentStatement.forEach(statement => {
					this.sendRequest(statement).subscribe({
						next: () => {
							// console.error("last statement");
							resolve();
						},
						error: err => {
							console.log("statement end", statement);
							this.offlineStatementSave(statement, "completed");
							resolve();
						}
					});
				});
			} else {
				this.sendRequest(currentStatement).subscribe({
					next: data => {
						LrsUtils.jounreyExerciseStep = null;
						// console.error("last statement");
						resolve();
					},
					error: err => {
						console.log("statement end 2", currentStatement);
						this.offlineStatementSave(currentStatement, "completed");
						resolve();
					}
				});
			}
		});
	}

	request(request) {
		return new Promise((resolve, reject) => {
			const uri = AppUtils.encodeURI(request);
			this.find(`${this.BASE_URL}/api/statements/aggregate?pipeline=${uri}`).subscribe({
				next: async (result: any) => {
					resolve(result);
				},
				error: err => {
					reject(true);
				}
			});
		});
	}

	/**
	 * Send statements stored in local storage
	 */
	async sendOfflineStatements():Promise<void> {
		return new Promise<void>(async (resolve,reject) => {
			if (this.offlineStatementsStore && this.offlineStatementsStore.length === 0) {
				resolve();
			}else{
				const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
				if (!encodedClientBasicAuth) {
					this.checkExistingBasicAuth().then(async data => {
						setTimeout(async () => {
							resolve(await this.sendOfflineStatementsAsync());
						}, 10);
					});
				} else {
					// await this.sendOfflineStatementsAsync();
					setTimeout(async () => {
						resolve(await this.sendOfflineStatementsAsync());
					}, 10);
				}
			}
			
		})
	}

	/**
	 * Send statements stored in local storage recursively
	 */
	async sendOfflineStatementsAsync() {
		this.sendRequest(this.offlineStatementsStore[0].currentStatement).subscribe({
			next: async request => {
				this.offlineStatementsStore.shift();
				localStorage.setItem("statement", JSON.stringify(this.offlineStatementsStore));
				await this.sendOfflineStatements();
			},
			error:async err => {
				this.offlineStatementsStore.shift();
				localStorage.setItem("statement", JSON.stringify(this.offlineStatementsStore));
				await this.sendOfflineStatements();
			}
		});
	}

	/**
	 * Save offline statements in local storage
	 */
	offlineStatementSave(currentStatement, response) {
		const currentMoment = LrsUtils.getLrsTimestampFormat();
		if (response === "response") {
			currentStatement.timestamp = currentMoment;
			currentStatement.stored = currentMoment;
			this.offlineStatementsStore.push({ currentStatement, response });
		} else {
			if (!Array.isArray(currentStatement)) {
				currentStatement.timestamp = currentMoment;
				(currentStatement.stored = currentMoment), this.offlineStatementsStore.push({ currentStatement });
			} else {
				currentStatement.forEach(data => {
					data.timestamp = currentMoment;
					data.stored = currentMoment;
					this.offlineStatementsStore.push({ currentStatement: data });
				});
			}
		}

		console.log("this.offlineStatementsStore",this.offlineStatementsStore)
		localStorage.setItem("statement", JSON.stringify(this.offlineStatementsStore));
	}

	/**
	 * Get all ids user who made statement in offline
	 * @returns Array of actor names(ids)
	 */
	public getUniqueUsersMadeStatementOffline(){
		if(this.offlineStatementsStore?.length > 0){
			return this.offlineStatementsStore.reduce((acc, statement) => {
				const accountName = statement?.currentStatement?.actor?.account?.name;
				if (accountName && !acc.includes(accountName)) {
				  acc.push(accountName);
				}
				return acc;
			  }, []);
		}
	}

	/** MAKE USER PERMISSIONS TO MAKE STATEMENTS */

	/* UPDATE STATEMENT*/
	autenticateEnterCodeClass(dataInfo) {
		// this.authenticateUser = new Subject();
		this.getAllStores(dataInfo).subscribe(
			data => {
				if (data.length === 0) {
					this.createStore(dataInfo).subscribe(data => {
						localStorage.setItem("lrs_id", data["_id"]);
						this.getBasicAuthClient();
					});
				} else {
					localStorage.setItem("lrs_id", data[0]["_id"]);
					this.getBasicAuthClient();
				}

				if (!isNaN(dataInfo)) {
					localStorage.setItem("codeclasse", dataInfo);
				}

				if (this.userAutenticated.getValue() !== true) {
					this.userAutenticated.next(true);
				}
			},
			err => {
				console.log("err", err);
			}
		);
	}

	createAsyncStore(dataInfo): Promise<void> {
		return new Promise((resolve, reject) => {
			const postBody = {
				title: dataInfo,
				organisation: this.getOrganisationId()
			};

			this.lrsAuthentication(postBody).subscribe(lastData => {
				if (lastData) {
					localStorage.setItem("lrs_id", lastData["_id"]);
					this.find(`${this.BASE_URL}/api/v2/client?query={"lrs_id": "${lastData["_id"]}"}`).subscribe({
						next: data => {
							const basic_key = data[0].api.basic_key;
							const basic_secret = data[0].api.basic_secret;
							const encodeBasicAuth = btoa(`${basic_key}:${basic_secret}`);
							localStorage.setItem("clientBasicAuth", encodeBasicAuth);
							resolve();
						}
					});
				}
			});
		});
	}

	/**
	 * Autorize user to make LRS actions(create,search...)
	 */
	getBasicAuthClient(): Promise<void> {
		return new Promise((resolve, reject) => {
			const lrsId = localStorage.getItem("lrs_id");
			let encodeBasicAuth;
			this.find(`${this.BASE_URL}/api/v2/client?query={"lrs_id": "${lrsId}"}`).subscribe({
				next: data => {
					const basic_key = data[0].api.basic_key;
					const basic_secret = data[0].api.basic_secret;
					encodeBasicAuth = btoa(`${basic_key}:${basic_secret}`);
					localStorage.setItem("clientBasicAuth", encodeBasicAuth);
					resolve();
				}
			});
		});
	}

	getAllStores(codeclasse) {
		return this.find(`${this.BASE_URL}/api/v2/lrs?query={"title": ${codeclasse}}`);
	}

	/**
	 * Verify if user is authenticated before doing statement
	 */
	checkExistingBasicAuth() {
		return new Promise((resolve, reject) => {
			const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
			if (!encodedClientBasicAuth) {
				this.getAllStores(0).subscribe({
					next: data => {
						if (data.length === 0) {
							this.createAsyncStore(0).then(() => {
								resolve(true);
							});
						} else {
							localStorage.setItem("lrs_id", data[0]["_id"]);
							this.getBasicAuthClient().then(() => {
								resolve(true);
							});
						}
					},
					error: err => {
						resolve(true);
					}
				});
			} else {
				resolve(true);
			}
		});
	}

	/**
	 * Verify and autenticate user if not yet autenticated
	 */
	checkAuthenticateUser() {
		// this.authenticateUser = new Subject();
		// const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
		localStorage.removeItem("clientBasicAuth");
		const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
		if (!encodedClientBasicAuth) {
			this.getAllStores(132).subscribe(
				data => {
					if (data.length === 0) {
						this.createAsyncStore(132).then(() => {
							if (this.userAutenticated.getValue() !== true) {
								this.userAutenticated.next(true);
							}
						});
					} else {
						localStorage.setItem("lrs_id", data[0]["_id"]);
						this.getBasicAuthClient().then(() => {
							if (this.userAutenticated.getValue() !== true) {
								this.userAutenticated.next(true);
							}
						});
					}
				},
				err => {}
			);
		} else {
			if (this.userAutenticated.getValue() !== true) {
				this.userAutenticated.next(true);
			}
		}
	}

	/** FALSE TRACES */

	async sendNastyTraces(formDatas: any) {
		let { idEleves, activityType, cursorMod, perGoodAnswers, statementDateStartFrom, isMulti, responseDuration } = formDatas;
		// Number of questions depending on mod
		const allStudents = new Array();
		const timestamps = new Array();
		for (const [indexStudent, studentId] of idEleves.entries()) {
			let numberOfQuestions = 0;
			if (cursorMod === "1") {
				numberOfQuestions = 10;
			} else if (cursorMod === "0") {
				numberOfQuestions = 25;
			} else if (cursorMod === "2") {
				numberOfQuestions = 20;
			} else {
				cursorMod = "1";
				numberOfQuestions = 10;
			}

			LrsUtils.duration = new Array();
			let currentActivityType; // journey or activity
			if (activityType.journey) {
				// type journey search the targetted journey
				this.lmsService.currentUserJourney = activityType.exercise.find(data => {
					return data.id === activityType.id;
				});

				this.lmsService.currentUserJourney.start = true;
				// exercise search
				const exercises = this.lmsService.currentUserJourney.exercises.map(exercise => {
					return exercise;
				});

				currentActivityType = { exercise: exercises, journey: activityType.journey };
				// current activity
				this.cabriService.currentActivity = this.cabriService.activities.find(activity => {
					return Number(activity.id) === Number(this.lmsService.currentUserJourney.exercises[0].activityId);
				});
			} else {
				// type activity search the activity with params
				// search exercise object by id
				const exercise = this.cabriService.exercices.getExercise(activityType.id);
				currentActivityType = { exercise, journey: activityType.journey };
				this.cabriService.currentExercice = exercise;
				// cabri activity
				this.cabriService.currentActivity = this.cabriService.activities.find(activity => {
					return Number(activity.id) === Number(currentActivityType.exercise.gabarit);
				});

				this.cabriService.currentActivity.setParamValue("ex", exercise);
				this.cabriService.currentActivity.buildVariables();
				this.cabriService.currentActivity.updateVariables(this.cabriService.currentExercice);
			}
			this.cabriService.currentActivity.setParamValue("v-cursor-mod", cursorMod);
			const randomRangePerGoodAnswer = Math.floor(
				Math.random() * (perGoodAnswers.upper - perGoodAnswers.lower + 1) + perGoodAnswers.lower
			);

			// console.log("randomRangePerGoodAnswer", randomRangePerGoodAnswer);
			this.statement = new LrsStatement(this.requirements());

			// Taux de mauvaises réponses
			const goodAnswers = Math.round(numberOfQuestions * (randomRangePerGoodAnswer / 100));
			LrsUtils.nastyVerbs = new Array();
			if (currentActivityType.journey) {
				// Journey mod
				// console.log("currentUserJourney", this.lmsService.currentUserJourney);
				currentActivityType.exercise.forEach((item, indexExercise) => {
					LrsUtils.nastyVerbs.push(this.statement.verbBasedOnPercentage(goodAnswers, numberOfQuestions));
				});
				LrsUtils.nastyVerbs.forEach((verbElement, index, currElement) => {
					const result = this.dynamicQuestions(randomRangePerGoodAnswer, cursorMod, index);
					currElement[index] = result;
					numberOfQuestions = result.length;
				});
			} else {
				LrsUtils.nastyVerbs = this.statement.verbBasedOnPercentage(goodAnswers, numberOfQuestions, cursorMod);
				if (cursorMod === "1") {
					// Entraînement
					const result = this.dynamicQuestions(randomRangePerGoodAnswer, cursorMod);
					if (result) {
						LrsUtils.nastyVerbs = result;
						numberOfQuestions = result.length;
					}
				} else {
					numberOfQuestions = LrsUtils.nastyVerbs.length;
				}
			}
			// Response duration based on number of question and total duration of activity
			let numberOfGroups = numberOfQuestions;
			if (this.lmsService.currentUserJourney) {
				numberOfGroups = this.lmsService.currentUserJourney.exercises.length * numberOfQuestions;
			} else {
				numberOfGroups = numberOfQuestions;
			}

			// Interval based random response time and creation of all timestamps starting from form selection (accumulation)
			const minDelayResponse = responseDuration.lower;
			const maxDelayResponse = responseDuration.upper;
			const minDelay = 2;
			const maxDelay = 5;

			let timestamp;
			if (indexStudent === 0) {
				timestamp = new Date(statementDateStartFrom);
			} else {
				timestamp = new Date(timestamps[timestamps.length - 1]);
			}

			const defineResponseDuration = () => {
				// response duration random range
				const randomResponseDuration = Math.floor(Math.random() * (maxDelayResponse - minDelayResponse + 1) + minDelayResponse);
				// pass delay between each question random
				const randomDelayBtwnEachQuestion = Math.floor(Math.random() * (maxDelay - minDelay + 1) + minDelay);
				const timeStampWithDelay = timestamp.setSeconds(
					timestamp.getSeconds() + randomResponseDuration + randomDelayBtwnEachQuestion
				);
				timestamps.push(timeStampWithDelay);

				return randomResponseDuration;
			};

			// console.log("numberOfGroups", numberOfGroups);
			if (!currentActivityType.journey) {
				for (let i = 0; i < numberOfGroups; i++) {
					LrsUtils.nastyVerbs[i].responseDuration = defineResponseDuration();
				}
			} else {
				LrsUtils.nastyVerbs.forEach(verbsElements => {
					verbsElements.forEach(verbItem => {
						verbItem.responseDuration = defineResponseDuration();
					});
				});
			}

			allStudents.push({ studentId, verbs: LrsUtils.nastyVerbs });
		}

		// console.log("allStudents", allStudents);

		this.defineRandomActivityParams();
		if (this.lmsService.currentUserJourney) {
			this.journeyRequest(allStudents, timestamps);
		} else {
			if (isMulti) {
				// multiplayer multiple players in one session
				this.exerciseRequestMulti(allStudents, timestamps, cursorMod);
			} else {
				// different sessions (with or without binome)
				this.exerciseRequestSolo(allStudents, timestamps);
			}
		}
	}

	async exerciseRequestMulti(allStudents, timestamps, cursorMod) {
		let timestamp;

		let timeStampIndex = 0;
		let studentIndex = 0;
		const studentMaxLength = allStudents.length - 1;

		const studentElements = allStudents.map(studentItems => {
			return studentItems.verbs.map(verbElement => {
				return { ...verbElement, studentId: studentItems.studentId, sent: false };
			});
		});

		const binome = studentElements.length > 1;

		const getStudentElements = studentItems => {
			if (!studentItems) {
				return false;
			}

			// search next object which has not been yet send to lrs
			return studentItems.find(element => {
				return element.sent === false;
			});
		};

		// id session defined at the beginning of the activity
		LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);

		const newDate = new Date(timestamps[0]);
		// substract 5 seconds before the start of the activity
		const firstTimeStamp = newDate.setSeconds(newDate.getSeconds() - 5);
		timestamp = LrsUtils.convertDateToISO8601(firstTimeStamp);
		for (const student of allStudents) {
			// first statement send
			const statementOnStart = this.manageNastyStartActivity(student.studentId, timestamp, binome);
			console.log("statementOnStart", statementOnStart.getJson()?.context?.extensions);
			// await this.send(statementOnStart);
			this.send(statementOnStart);
		}
		let getNextVerb = true;
		console.log("studentElements", studentElements);
		while (getNextVerb) {
			let getNextQuestion = true;
			const currentStudentElement = studentElements[studentIndex].find(element => {
				return element.sent === false;
			});

			const responseVerb = currentStudentElement.verb;
			LrsUtils.currentUser = this.accountService.allStudents.find(teamStudent => {
				return Number(teamStudent.id) === Number(currentStudentElement.studentId);
			});

			if (responseVerb === LrsVerbs.failedOnFirstAttempt) {
				getNextQuestion = false;
			}
			if (getNextQuestion) {
				this.cabriService.currentActivity.updateVariables(this.cabriService.currentExercice);
			}

			// response statement
			const responseStatement = this.manageNastyLrsOperation(
				responseVerb,
				currentStudentElement.responseDuration,
				timestamps[timeStampIndex],
				binome
			);
			// console.log("responseStatement", responseStatement.getJson());
			// await this.send(responseStatement);
			this.send(responseStatement);
			// response statement "finished" verb
			const finishedStatement = this.manageNastyLrsOperation(
				LrsVerbs.finished,
				currentStudentElement.responseDuration,
				timestamps[timeStampIndex],
				binome
			);
			// await this.send(finishedStatement);
			this.send(finishedStatement);
			currentStudentElement.sent = true;

			if (cursorMod === "2") {
				const allSent = studentElements[studentIndex].every(element => {
					return element.sent;
				});
				if (responseVerb === LrsVerbs.failed || allSent) {
					// Défi eliminated or last question
					LrsUtils.currentUser = this.accountService.allStudents.find(teamStudent => {
						return Number(teamStudent.id) === Number(currentStudentElement.studentId);
					});
					const firstTimestamp = timestamps[0];
					const eliminatedTimestamp = timestamps[timeStampIndex];
					const startLastTimestampElimination = [firstTimestamp, eliminatedTimestamp];
					const completedStatement = this.manageNastyEndOfActivity(allStudents, startLastTimestampElimination, false);
					// await this.send(completedStatement);
					this.send(completedStatement);

					if (studentElements[studentIndex]) {
						studentElements.splice(studentIndex, 1);

						if (studentElements[studentIndex + 1]) {
							studentIndex++;
						} else if (studentElements.length === 1) {
							studentIndex = 0;
						} else if (studentElements.length === 0) {
							getNextVerb = false;
						}
					} else {
						getNextVerb = false;
					}
				} else {
					if (studentElements[studentIndex + 1]) {
						studentIndex++;
					} else {
						studentIndex = 0;
					}
				}
			} else if (cursorMod !== "2") {
				if (studentIndex < studentMaxLength && allStudents.length >= 2) {
					if (getNextQuestion) {
						studentIndex++;
					}
				} else {
					if (getNextQuestion) {
						studentIndex = 0;
					}
				}

				const item = getStudentElements(studentElements[studentIndex]);
				if (!item) {
					// console.log("cursorMod", cursorMod);
					for (const student of allStudents) {
						LrsUtils.currentUser = this.accountService.allStudents.find(teamStudent => {
							return Number(teamStudent.id) === Number(student.studentId);
						});
						// first statement send
						const completedStatement = this.manageNastyEndOfActivity(allStudents, timestamps, false);
						this.send(completedStatement);
					}
					getNextVerb = false;
				}
			}
			timeStampIndex++;
		}
	}

	async exerciseRequestSolo(allStudents, timestamps) {
		let countIndexVerbs = 0;
		let countIndexStudent = 0;
		let timeStampIndex = 0;
		let timestamp;

		// first statement send
		for (const student of allStudents) {
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			// countIndexStudent = 0;
			timeStampIndex = 0;
			countIndexVerbs = 0;
			const newDate = new Date(timestamps[0]);
			const firstTimeStamp = newDate.setSeconds(newDate.getSeconds() - 5);
			timestamp = LrsUtils.convertDateToISO8601(firstTimeStamp);
			const statementOnStart = this.manageNastyStartActivity(student.studentId, timestamp, false);
			this.send(statementOnStart);
			LrsUtils.currentUser = this.accountService.allStudents.find(teamStudent => {
				return Number(teamStudent.id) === Number(student.studentId);
			});
			if (countIndexStudent !== allStudents.length) {
				for (const verbElement of student.verbs) {
					// console.log("verbElement", verbElement);
					if (countIndexVerbs !== student.verbs.length) {
						const responseVerb = student.verbs[countIndexVerbs].verb;
						this.cabriService.currentActivity.updateVariables(this.cabriService.currentExercice);
						const responseStatement = this.manageNastyLrsOperation(
							responseVerb,
							student.verbs[countIndexVerbs].responseDuration,
							timestamps[timeStampIndex],
							false
						);

						// responseVerb, currentStudentElement.responseDuration, timestamps[timeStampIndex], binome;
						this.send(responseStatement);
						const finishedStatement = this.manageNastyLrsOperation(
							LrsVerbs.finished,
							student.verbs[countIndexVerbs].responseDuration,
							timestamps[timeStampIndex],
							false
						);
						this.send(finishedStatement);
						countIndexVerbs++;
						timeStampIndex++;
						// console.log("responseVerb", responseVerb);
					}
				}
				const firstTimestamp = timestamps[0];
				const lastTimestamp = timestamps[timeStampIndex] || timestamps[timeStampIndex - 1];
				const startLastTimestampElimination = [firstTimestamp, lastTimestamp];
				const completedStatement = this.manageNastyEndOfActivity(allStudents, startLastTimestampElimination, false);
				this.send(completedStatement);
				countIndexStudent++;
			}
		}
	}

	/**
	 * Per good answer generated false data
	 */
	dynamicQuestions(randomRangePerGoodAnswer, cursorMod, indexVerb?) {
		let minQuestions;
		let maxQuestions;

		let normal, moons, shootings;

		const getTotalAwards = verbElements => {
			// console.log("verbElements", verbElements);
			normal = verbElements.filter(data => {
				return data.verb === "passed-with-help";
			});
			moons = verbElements.filter(data => {
				return data.verb === "failed";
			});
			shootings = verbElements.filter(data => {
				return data.verb === "passed";
			});

			return { normal, moons, shootings };
		};

		minQuestions = 10;
		maxQuestions = 15;
		if (indexVerb || indexVerb === 0) {
			normal = getTotalAwards(LrsUtils.nastyVerbs[indexVerb]).normal;
			moons = getTotalAwards(LrsUtils.nastyVerbs[indexVerb]).moons;
			shootings = getTotalAwards(LrsUtils.nastyVerbs[indexVerb]).shootings;
		} else {
			normal = getTotalAwards(LrsUtils.nastyVerbs).normal;
			moons = getTotalAwards(LrsUtils.nastyVerbs).moons;
			shootings = getTotalAwards(LrsUtils.nastyVerbs).shootings;
		}

		const sumAllAwards = normal.length + moons.length + shootings.length;
		const resultFinal = Math.min(maxQuestions, minQuestions + sumAllAwards - Math.max(moons.length, normal.length, shootings.length));

		if (resultFinal && resultFinal > 0) {
			if (indexVerb || indexVerb === 0) {
			} else {
				LrsUtils.nastyVerbs = new Array();
			}
			const goodAnswers = Math.round(resultFinal * (randomRangePerGoodAnswer / 100));
			return this.statement.verbBasedOnPercentage(goodAnswers, resultFinal, cursorMod);
		}
	}

	async journeyRequest(allStudents, timestamps) {
		const defineJourneyExerciseActivity = currentJourneyEx => {
			this.cabriService.currentExercice = this.cabriService.exercices.getExercise(currentJourneyEx.exerciseId);
			// 	// cabri activity
			this.cabriService.currentActivity = this.cabriService.activities.find(activity => {
				return Number(activity.id) === Number(currentJourneyEx.activityId);
			});

			this.cabriService.currentActivity.setParamValue("ex", this.cabriService.currentExercice);
			this.cabriService.currentActivity.buildVariables();
			this.cabriService.currentActivity.updateVariables(this.cabriService.currentExercice);
		};
		let timestamp;
		let timeStampIndex = 0;

		// console.log("timestampstimestamps", timestamps);
		// console.log("allStudents", allStudents);

		for (const eachStudentElement of allStudents) {
			let journeyIndex = 0;
			timeStampIndex = 0;
			LrsUtils.currentUser = this.accountService.allStudents.find(teamStudent => {
				return Number(teamStudent.id) === Number(eachStudentElement.studentId);
			});

			this.lmsService.currentUserJourney.idSession = LrsUtils.generateUniqueSessionId(this.accountService, true);

			for (const [exerciseIndex, eachVerbItems] of eachStudentElement.verbs.entries()) {
				let newDate;

				if (journeyIndex === 0) {
					newDate = new Date(timestamps[timeStampIndex]);
					const firstTimeStamp = newDate.setSeconds(newDate.getSeconds() - 5);
					timestamp = LrsUtils.convertDateToISO8601(firstTimeStamp);
				} else {
					newDate = new Date(timestamps[timeStampIndex - 1]);
					const firstTimeStamp = newDate.setMilliseconds(30);
					timestamp = LrsUtils.convertDateToISO8601(firstTimeStamp);
				}

				const currentJourneyEx = this.lmsService.currentUserJourney.exercises.find(jEx => {
					return jEx.status === Status.notDone;
				});
				if (!currentJourneyEx) {
					// completed all exercises
					break;
				}
				defineJourneyExerciseActivity(currentJourneyEx);
				if (journeyIndex === 0) {
					// start of the first ex of the journey (2 statements send) type journey + type exercise
					LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
					for (const [indexEmpty, empty] of new Array(2).entries()) {
						const isTypeJourney = indexEmpty === 0;
						const statementOnStart = this.manageNastyStartActivity(
							eachStudentElement.studentId,
							timestamp,
							false,
							isTypeJourney
						);
						this.send(statementOnStart);
					}
				} else {
					const statementOnStart = this.manageNastyStartActivity(eachStudentElement.studentId, timestamp, false, false);
					this.send(statementOnStart);
				}

				journeyIndex++;
				for (const verbItem of eachVerbItems) {
					const responseVerb = verbItem.verb;
					// console.log("verbElement", verbElement);
					this.cabriService.currentActivity.updateVariables(this.cabriService.currentExercice);
					const responseStatement = this.manageNastyLrsOperation(
						responseVerb,
						verbItem.responseDuration,
						timestamps[timeStampIndex],
						false
					);

					this.send(responseStatement);
					const finishedStatement = this.manageNastyLrsOperation(
						LrsVerbs.finished,
						verbItem.responseDuration,
						timestamps[timeStampIndex],
						false
					);
					this.send(finishedStatement);
					timeStampIndex++;
				}

				this.lmsService.currentUserJourney.exercises[exerciseIndex].status = Status.done;
				let completedStatement;
				const allDone = this.lmsService.currentUserJourney.exercises.every(jEx => {
					return jEx.status === Status.done;
				});
				if (allDone) {
					for (let lastStatementIndex = 0; lastStatementIndex < 2; lastStatementIndex++) {
						const isTypeJourney = lastStatementIndex === 0;
						let startLastTimestamp, firstTimestamp, lastTimestamp;
						if (isTypeJourney) {
							firstTimestamp = timestamps[0];
							lastTimestamp = timestamps[timeStampIndex] || timestamps[timeStampIndex - 1];
						} else {
							firstTimestamp = timestamps[timeStampIndex - 1 - eachVerbItems.length + 1];
							lastTimestamp = timestamps[timeStampIndex - 1];
						}
						startLastTimestamp = [firstTimestamp, lastTimestamp];
						completedStatement = this.manageNastyEndOfActivity(allStudents, startLastTimestamp, isTypeJourney);
						// console.log("completedStatement", completedStatement?.getJson()?.context?.extensions);
						this.send(completedStatement);

						this.lmsService.currentUserJourney.exercises.forEach(jEx => {
							jEx.status = Status.notDone;
						});
					}
				} else {
					const firstExSessionTimestamp = timeStampIndex - 1 - eachVerbItems.length + 1;
					const lastExSessionTimestamp = timeStampIndex - 1;
					const exerciseSessionTimestamps = [timestamps[firstExSessionTimestamp], timestamps[lastExSessionTimestamp]];
					completedStatement = this.manageNastyEndOfActivity(allStudents, exerciseSessionTimestamps, false);
					this.send(completedStatement);
				}
			}
		}
	}

	generateOperationResponse2(verb: string) {
		const operationStatement = new LrsStatement(this.requirements());
		return operationStatement.generateRandomResponseOperation(verb);
	}

	/** OSE2 */
	/**
	 *
	 * @param statement
	 */

	public async send(statement: Statement | Statement[]) {
		return new Promise<Statement | void>(async (resolveStatement, reject) => {
			if (statement) {
				const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
				if (!encodedClientBasicAuth) {
					// autorisation
					const getAllStores = await lastValueFrom(this.getAllStores(0));
					if (getAllStores.length === 0) {
						await this.createAsyncStore(0);
					} else {
						localStorage.setItem("lrs_id", getAllStores[0]["_id"]);
						await this.getBasicAuthClient();
					}
				}
				this.userAutenticated.subscribe(autenticated => {
					if (autenticated) {
						const sendStatement = currStatement => {
							this.sendRequest(currStatement.getJson()).subscribe({
								next: request => {
									this.newStatementSend.next(currStatement);
									resolveStatement(currStatement);
								},
								error: err => {
									this.newStatementSend.next(null);
									reject(currStatement);
								}
							});
						};
						// send request if user is autenticated
						if (Array.isArray(statement)) {
							statement.forEach((currStatement: Statement) => {
								sendStatement(currStatement);
							});
						} else {
							sendStatement(statement);
						}
					} else {
						reject();
					}
				});
			} else {
				reject();
				console.error("lrs object not exist");
			}
		});
	}

	/**
	 * Statement object created only on the first exercise of journey.
	 * @returns Statement[]
	 */
	startJourneyStatement() {
		if (this.oseJourneyService.currentJourney){
				if(this.oseJourneyService.currentJourney.resume || this.oseJourneyService.currentJourney.activitySelectedFromUser || 
				(this.oseJourneyService.currentJourney.isFirstExercise && !this.oseJourneyService.currentJourney.isFiche)) {
				// Journey
				const object = new XapiObject(
					`${this.statementUrl}/journey/${String(this.oseJourneyService.currentJourney.id)}`,
					this.oseJourneyService.currentJourney.title,
					this.oseJourneyService.currentJourney.title,
					XObjectType.journey
				);
				const context = new XapiContext(this.accountService.team, {
					...this.globalActivityExtensions(),
					[XapiExtensions.parcoursSessionId]: LrsUtils.idJourneySession,
					[XapiExtensions.parcoursid]: this.oseJourneyService.currentJourney.id
				});
				const verb = this.oseJourneyService.currentJourney.resume ? LrsVerbs.resume : LrsVerbs.initialized;
				this.oseJourneyService.currentJourney.activitySelectedFromUser = false;
				this.oseJourneyService.currentJourney.resume = false;
				return new Statement(this.accountService.team[0]?.id, verb, object, context);
			}
		}
	}

	/**
	 * Statement object created only on the last journey exercise
	 * @returns Statement[]
	 */
	endJourneyStatement() {
		if (this.oseJourneyService.currentJourney?.isCompleted(true) && !this.oseJourneyService.currentJourney.isFiche) {
			const journeyDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.durationStartActivity));
			const result = new XApiResult(LrsVerbs.completed, journeyDuration);
			// Journey
			const object = new XapiObject(
				`${this.statementUrl}/journey/${String(this.oseJourneyService.currentJourney.id)}`,
				this.oseJourneyService.currentJourney.title,
				this.oseJourneyService.currentJourney.title,
				XObjectType.journey
			);
			const context = new XapiContext(this.accountService.team, {
				...this.globalActivityExtensions(),
				[XapiExtensions.parcoursSessionId]: LrsUtils.idJourneySession,
				[XapiExtensions.parcoursid]: this.oseJourneyService.currentJourney.id,
				[XapiExtensions.dureeActivite]: journeyDuration
			});
			return new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
		}
	}

	/**
	 * Extensions common for all statements (start,answer,end)
	 * @returns extensions
	 */
	globalActivityExtensions() {
		const oseDevMode = !this.environment.production ? true : false;
		const extension = {
			[XapiExtensions.codeclasse]: Number(this.accountService.team[0]?.classe?.id),
			[XapiExtensions.id]: this.cabriService.currentOseActivity?.id,
			[XapiExtensions.nomExercice]: this.cabriService.currentOseActivity?.title,
			[XapiExtensions.activite]: this.cabriService.currentOseActivity?.type,
			[XapiExtensions.nomActivite]: this.cabriService.currentOseActivity?.type,
			[XapiExtensions.idEquipe]: this.accountService.team[0]?.classe?.id,
			[XapiExtensions.ose]: true,
			[XapiExtensions.osedevmode]: oseDevMode,
			[XapiExtensions.binome]: false,
			[XapiExtensions.idSession]: LrsUtils.idsession,
			[XapiExtensions.parcoursSessionId]: LrsUtils.idJourneySession,
			[XapiExtensions.parcoursid]: this.oseJourneyService.currentJourney?.id,
			[XapiExtensions.horsLigne]:!this.networkService.isConnected
		};
		return extension;
	}

	/**
	 * Extensions common for all statements (start,answer,end) cabri
	 * @returns extensions
	 */
	globalActivityExtensionsCabri(binome: boolean) {
		this.initActivity = new GlobalLrs(
			this,
			this.lmsService,
			this.cabriService,
			this.accountService,
			this.globalService,
			this.networkService
		);
		const activityName = this.initActivity.detectCurrentActivityNameById(this.cabriService.currentActivity.id);
		const paramModeJeu = this.cabriService.currentActivity.getParam("v-cursor-mod")?.value;
		let modeJeu;
		if (Number(paramModeJeu) === 0) {
			modeJeu = "découverte";
		} else if (Number(paramModeJeu) === 1) {
			modeJeu = "entraînement";
		} else if (Number(paramModeJeu) === 2) {
			modeJeu = "défi";
		} else if (Number(paramModeJeu) === 3) {
			modeJeu = "bilan";
		}

		const extension = {
			[XapiExtensions.codeclasse]: Number(this.accountService.team[0]?.classe?.id),
			[XapiExtensions.id]: this.cabriService.currentExercice?.id,
			[XapiExtensions.nomExercice]: this.cabriService.currentExercice?.name,
			[XapiExtensions.activite]: activityName,
			[XapiExtensions.nomActivite]: this.cabriService.currentActivity?.name,
			[XapiExtensions.modeReponse]: "numpad",
			[XapiExtensions.kidaiadevmode]: true,
			[XapiExtensions.binome]: binome,
			[XapiExtensions.idSession]: LrsUtils.idsession,
			[XapiExtensions.modeJeu]: modeJeu,
			[XapiExtensions.parcoursEtape]: LrsUtils.jounreyExerciseStep || undefined,
			[XapiExtensions.parcoursid]: this.lmsService.currentUserJourney?.id || undefined,
			[XapiExtensions.parcoursSessionId]: this.lmsService.currentUserJourney?.idSession || undefined
		};
		return extension;
	}
}
