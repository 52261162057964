import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { Badges } from "../models/badges";
import { Badge, BadgeStepLocation, BadgesByUserId } from "../models/badge";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Chapter } from "../models/chapter";
import { User } from "../models/user";
import { lastValueFrom } from "rxjs";
import { LocalStorageService, StorageKey } from "./local-storage-service";

@Injectable({
	providedIn: "root"
})
export class BadgeService extends BaseService {
	public allBadges;
	public environment: { production: boolean; activityVersion: number; kidaia: boolean };
	modal: HTMLIonModalElement;

	constructor(private http: HttpClient,public localStorageService:LocalStorageService) {
		super();
		this.environment = environment;
	}

	static competencyToArray(competency: any): string[] {
		return Object.entries(competency).map(a => {
			return a[0];
		});
	}

	getBadges(idUser: string): Promise<Badges> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("action", "getBadges");
			formData.append("id_user", idUser);
			if (!this.environment.production) {
				formData.append("dev", "true");
			}
			const remapBadges: Badge[] = [];
			const badgesObs = this.http.post<Badge[]>(this.postUrl, formData).pipe(
				map((badges) => {
					badges.forEach((badge,index) => {
						badges[index] = this.setBadges(badge);
						remapBadges.push(badges[index]);
					});
					return remapBadges;
				})
			);
			lastValueFrom(badgesObs)
				.then(badges => {
					resolve(new Badges(badges));
				})
				.catch(error => reject(error));
		});
	}

	public getAllBadges(userIds:Array<string>): Promise<BadgesByUserId>{
			const formData = new FormData();
			formData.append("action", "getAllBadges");
			formData.append("id_users", JSON.stringify(userIds));
		
			let error = false;
			const badgesObs = this.http.post(this.postUrl, formData).pipe(
				catchError(async error => {
					error = true;
					const result =  await this.localStorageService.get(StorageKey.getAllBadges);
					return result;
				}),map((badges) => {
					if(!error){
						this.localStorageService.set(StorageKey.getAllBadges,badges)
					}
					this.allBadges = Object.assign(new BadgesByUserId(), badges)
						for(const userId in this.allBadges){
							this.allBadges[userId].forEach((badge,index) => {
								this.allBadges[userId][index] = this.setBadges(badge);
							});
							this.allBadges[userId] = new Badges(this.allBadges[userId]);
						}
						
						return this.allBadges
				})
			);
			return lastValueFrom(badgesObs)
	}

	private setBadges(badge:Badge):Badge{
		badge.unlocked = badge.unlocked ? true : false;
		badge.type = Number(badge.type);
		badge.chapterStep = Number(badge.chapterStep);
		badge.dateAcquired = new Date(badge.dateAcquired);
		if (badge.competencyRequirement) {
			badge.competencyRequirement = (badge.competencyRequirement as unknown as String).split(",");
		}
		return badge;
	}

	/**
	 * Return a map with all competency evaluated for a student id
	 * @param studentId
	 */
	public getUserCompetencies(studentId: string): Promise<Map<string, boolean>> {
		return new Promise((resolve, reject) => {
			const competenciesObs = this.http.get<any>(this.postApiMathia + "/studentProfile?uid=" + studentId + "&forceUpdate=true").pipe(
				map(competencies => {
					return new Map(Object.entries<boolean>(competencies));
				})
			);
			lastValueFrom(competenciesObs)
				.then(competencies => {
					resolve(competencies);
				})
				.catch(error => reject(error));
		});
	}

	public unlockBadge(badge: Badge, studentId: string) {
		badge.unlocked = true;
		badge.dateAcquired = new Date();
		this.saveBadge(badge, studentId).then(id => {
			console.log("Badge Unlock :" + badge.name + " id save :" + id);
		});
	}

	private saveBadge(badge: Badge, studentId: string): Promise<string> {
		const formData = new FormData();
		formData.append("action", "saveBadge");
		formData.append("id_user", studentId);
		formData.append("id_badge", badge.id);
		formData.append("unlocked", "1");
		return lastValueFrom(this.http.post<string>(this.postUrl, formData));
	}

	/**
	 * Check if step badges are unlocked
	 * @param chapter
	 * @return All unlocked Badges
	 */
	public checkStepBadges(step: BadgeStepLocation | BadgeStepLocation[], chapter: Chapter, badges: Badges, studentId: string): Badge[] {
		const unlockedBadges = [];
		if(badges){
			const stepBadges = badges.getAllBadgesByChapterAndSlotLocked(chapter, step);
			stepBadges.forEach(badge => {
				this.unlockBadge(badge, studentId);
				unlockedBadges.push(badge);
			});
		}
		return unlockedBadges;
	}

	/**
	 * Check if competency badges are unlocked
	 * @param chapter
	 * @return All unlocked Badges
	 */
	public async checkCompetencyBadges(competencies: string[] | any, badges: Badges, studentId: string): Promise<Badge[]> {
		const unlockedBadges = [];
		let lCompetencies: string[];
		if (Array.isArray(competencies)) {
			lCompetencies = competencies;
		} else {
			lCompetencies = BadgeService.competencyToArray(competencies);
		}
		const competencyBadges = badges.getAllBadgesByCompetencyLocked(lCompetencies);
		if (competencyBadges.length > 0) {
			let userCompetencies;
			try {
				userCompetencies = await this.getUserCompetencies(studentId);
				competencyBadges.forEach(badge => {
					// verify all competency acquired
					if (badge.competencyRequirement.every(competency => userCompetencies.get(competency))) {
						this.unlockBadge(badge, studentId);
						unlockedBadges.push(badge);
					}
				});
			} catch (err) {
				console.error("not existing user competencies", err);
			}
		}
		return unlockedBadges;
	}

	/**
	 * Check if rule badges acceuil are unlocked
	 * @return All unlocked Badges
	 */
	public checkRuleBadgesAccueil(user: User): Badge[] {
		const unlockedBadges = [];
		if(user.badges){
			const stepBadges = user.badges.getAllBadgesByCustomRules("acceuil");
			stepBadges.forEach(badge => {
				this.unlockBadge(badge, user.studentId);
				unlockedBadges.push(badge);
			});
		}
		return unlockedBadges;
	}

	giveBadgeForOldStep(user: User) {
		let indexChapter = user.currentStory.chapters.indexOf(user.storys[0].getCurrentChapter());
		if (indexChapter > 0) {
			indexChapter--;
			while (indexChapter > -1) {
				if (user.currentStory.chapters[indexChapter].unlocked) {
					const steps = [BadgeStepLocation.introduction, BadgeStepLocation.conclusion, BadgeStepLocation.chapter];
					if (!!user.currentStory.chapters[indexChapter].cinematic) {
						steps.push(BadgeStepLocation.cinematic);
					}
					this.checkStepBadges(steps, user.currentStory.chapters[indexChapter], user.badges, user.studentId);
				}
				indexChapter--;
			}
		}
	}
}
