import { Component, Input, OnInit } from "@angular/core";
import { Chapter, systemName } from "src/app/models/chapter";
import { svgTags } from "../../galaxy-map/galaxy-map.component";

@Component({
	selector: "app-etape4",
	templateUrl: "./etape4.component.html",
	styleUrls: ["./etape4.component.scss"]
})
export class Etape4Component implements OnInit {
	unlocked: boolean;
	@Input() worldMaps;
	@Input() currentUserWorld: { index: number; systemName: systemName; currentChapter: Chapter[] };
	constructor() {}
	ngOnInit() {
		this.unlocked = this.worldMaps[systemName.etape4].some(chapter => {
			return chapter.unlocked;
		});
	}
	get svgTags() {
		return svgTags;
	}
}
