import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ConfettiCanvas, ConfettiOptions } from "src/app/models/confetti-canvas";
import { GlobalService } from "src/app/services/global.service";

@Component({
	selector: "app-confetti-canvas",
	templateUrl: "./confetti-canvas.component.html",
	styleUrls: ["./confetti-canvas.component.scss"]
})
export class ConfettiCanvasComponent {
	@Input() options: ConfettiOptions;
	@ViewChild("canvasConfetti", { static: false }) canvas: ElementRef;

	constructor(globalService: GlobalService) {
		globalService.confettiCanvasComponent = this;
	}

	async launchConfetti(htmlElement?: HTMLElement, options?: ConfettiOptions) {
		let container;
		let currentOptions: ConfettiOptions;
		if (htmlElement) {
			container = htmlElement;
		} else {
			const containers = window.document.querySelectorAll(".ion-page:not(.ion-page-hidden) ion-content > .globalContainer");
			container = containers[containers.length - 1];
		}
		if (container && container instanceof HTMLElement) {
			const { right, width, height, top } = container.getBoundingClientRect();
			this.canvas.nativeElement.style.width = width + "px";
			this.canvas.nativeElement.style.height = height - 10 + "px";
			this.canvas.nativeElement.style.top = top + "px";
			this.canvas.nativeElement.style.left = right - width + "px";
			if (options) {
				currentOptions = options;
			} else {
				currentOptions = {
					spread: 110,
					particleCount: 150,
					startVelocity: 80,
					gravity: 2
				};
			}

			const confettiDom = new ConfettiCanvas(this.canvas.nativeElement, currentOptions);
			await confettiDom.launchConfetti();
		} else {
			console.error("globalContainer not exist for confetti");
		}
	}
}
