import { Injectable } from "@angular/core";
import { SocketRemoteOne } from "./web-socket.service";
import { Observable, Subject } from "rxjs";
import { AppUtils } from "../app-utils";

export enum RoleRemote {
	remoteHost = "remoteHost",
	remoteClient = "remoteClient"
}

export enum RemoteError {
	badAuth = "hostAuthIncorrect",
	notRegister = "SocketNotRegistered",
	idNotExist = "TargetIdNotExist"
}

export enum RemoteCommand {
	playerVisible = "playerVisible",
	playerName = "playerName",
	waiting = "waiting",
	stt = "stt",
	tts = "tts",
	result = "result",
	choiceResult = "choiceResult",
	numpad = "numpad",
	draw = "draw",
	numericInput = "numericInput",
	multiChoice = "multiChoice",
	kimInput = "kimInput",
	activityName = "activityName",
}

export enum RemoteCommandSolides {
	initSolides = "initSolides",
	facets = "facets",
	vertices = "vertices",
	edges = "edges",
	representation = "representation",
	patron = "patron",
	solides = "solides",
	zoom = "zoom",
	subMeshPicked = "subMeshPicked",
	rotate = "rotate",
	resetPosition = "resetPosition",
	camera = "camera",
	patronPositions = "patronPositions",
	patronPositionsParam = "patronPositionsParam",
	resetCube = "patronPositionsResetCube",
	backToExercises = "backToExercices",
	backFromExamples = "backFromExamples"
}

@Injectable({
	providedIn: "root"
})
export class RemoteService {
	uuid: string;
	code: number;
	connected = false;
	listRemoteHost: any[];
	listRemoteClient: any[];
	error: Observable<any>;
	receiveCommand: Observable<any>;
	remoteHost: Subject<boolean>;
	retry = 0;
	role: RoleRemote;
	remoteHostActivate = false;
	hostActivityInfo: any;
	private lastClasse: number;
	listRemoteClientObservable: Observable<unknown>;

	constructor(public socket: SocketRemoteOne) {
		this.uuid = AppUtils.createGuid();
		this.code = Math.round(Math.random() * (9999 - 1000) + 1000);
		this.listenSocketEvent();
		// TODO  DEBUG remove
		// tslint:disable-next-line: no-string-literal
		window["remoteService"] = this;
		this.remoteHost = new Subject<boolean>();
		this.role = RoleRemote.remoteClient;
	}

	listenSocketEvent() {
		this.socket.on("connectedTohost", data => {
			this.connected = data;
		});
		this.socket.on("listRemoteHost", data => {
			this.listRemoteHost = data;
		});
		this.socket.on("listRemoteClient", data => {
			this.listRemoteClient = data;
		});
		this.socket.on("connect", () => {
			this.updateRegister();
			if (localStorage.getItem("codeclasse")) {
				this.getListRemoteHost(Number(localStorage.getItem("codeclasse")));
			}
		});
		this.receiveCommand = this.socket.fromEvent("remoteCommand");
		this.listRemoteClientObservable = this.socket.fromEvent("listRemoteClient");
		this.error = this.socket.fromEvent("errorRemote");
	}

	updateRegister() {
		this.registerUser(
			localStorage.getItem("codeclasse") ? Number(localStorage.getItem("codeclasse")) : null,
			this.role,
			this.hostActivityInfo
		);
	}

	removeSocketEvent() {
		this.socket.removeAllListeners();
	}

	getListRemoteHost(classe: number) {
		this.socket.emit("getListRemoteHost", classe);
	}

	getListRemoteClient() {
		this.socket.emit("getListRemoteClient");
	}

	registerUser(classe: number, role: RoleRemote, hostActivityInfo?: any) {
		this.lastClasse = classe;
		this.socket.emit("register", this.uuid, this.code, classe, role, hostActivityInfo);
	}

	connectToHost(targetId: string, targetCode: number) {
		this.socket.emit("registerRemoteHost", targetId, targetCode, this.uuid);
	}

	disconnectFromHost(targetId: string) {
		this.socket.emit("unregisterRemoteHost", targetId);
	}

	registerRemoteHost(classe: number, hostActivityInfo: any) {
		this.role = RoleRemote.remoteHost;
		this.lastClasse = classe;
		this.hostActivityInfo = hostActivityInfo;
		this.registerUser(classe, this.role, hostActivityInfo);
		this.remoteHost.next(true);
		this.remoteHostActivate = true;
	}

	unRegisterRemoteHost() {
		this.role = RoleRemote.remoteClient;
		this.hostActivityInfo = null;
		this.changeRole(this.role);
		this.remoteHost.next(false);
		this.remoteHostActivate = false;
	}

	changeRole(role: RoleRemote) {
		this.socket.emit("changeRole", role);
	}

	sendCommand(targetId: string, codeHost: number, data: any) {
		this.socket.emit("sendCommand", targetId, codeHost, data);
	}

	sendBroadCastRemoteClient(data: any) {
		this.socket.emit("sendBroadCastRemoteClient", data);
	}
}
