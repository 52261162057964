import { Param } from "./param";

export class CheckParam extends Param {
	className = "CheckParam";

	constructor(param) {
		super(param);
		this.value = param.value == "1" ? true : false;
	}
}
