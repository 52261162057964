import { Component, OnInit } from "@angular/core";
import { RemoteService } from "src/app/services/remote.service";
import { ModalController } from "@ionic/angular";
import { GlobalService } from "src/app/services/global.service";
import { CabriDataService } from "src/app/services/cabri-data.service";

@Component({
	selector: "app-modal-remote-host",
	templateUrl: "./modal-remote-host.component.html",
	styleUrls: ["./modal-remote-host.component.scss"]
})
export class ModalRemoteHostComponent implements OnInit {
	constructor(
		public remoteService: RemoteService,
		public modalCtrl: ModalController,
		public globalService: GlobalService,
		public cabriDataService: CabriDataService
	) {}

	ngOnInit() {}

	selectHost(host) {
		this.modalCtrl.dismiss({
			host
		});
	}
}
