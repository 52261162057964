import { Pipe, PipeTransform } from "@angular/core";
import { AbstractMesh } from "@babylonjs/core";
import { PhotoDomeItem, PhotoDomeModel } from "../models/photodome";

@Pipe({
	name: "meshToPhotoDome"
})
export class MeshToPhotoDomePipe implements PipeTransform {
	transform(mesh: AbstractMesh, photoDomes: PhotoDomeItem[]): PhotoDomeItem {
		return mesh ? photoDomes.find(item => item.meshId === mesh.uniqueId):null;
	}
}

