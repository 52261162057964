import { Injectable } from "@angular/core";
import { AppUtils } from "../app-utils";
import { GlobalService } from "./global.service";

@Injectable({
	providedIn: "root"
})
export class AudioStoreService {
	success = [
		"assets/sounds/success/TunePocket-Big-Party-Cheer-Applause.mp3",
		"assets/sounds/success/TunePocket-Drum-Roll-Ta-Da.mp3",
		"assets/sounds/success/TunePocket-Fanfare-Trumpet-Announcement-Alert-2-24-48.mp3",
		"assets/sounds/success/TunePocket-Hurray-Cheer-Applause.mp3",
		"assets/sounds/success/TunePocket-Orchestra-Success-Achievement-1.mp3"
	];
  currentAudio: HTMLAudioElement;

	constructor(public globalService: GlobalService) {}


  loadSuccess(){
    this.loadAudio(this.success[AppUtils.getRandomIntInclusive(0,this.success.length-1)]);
  }

  loadAudio(url : string){
    this.currentAudio = new Audio();
    this.currentAudio.volume = 0.5;
    this.currentAudio.src = url;
    this.currentAudio.load();
  }

  playSound(): Promise<void>{
    if (this.currentAudio){
      this.currentAudio.volume = this.globalService.fxsVolume;
      return this.currentAudio.play();
    }
  }

  stopSound(){
    if (this.currentAudio){
      this.currentAudio.pause();
    }
  }
}
