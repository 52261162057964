import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';

@Component({
  selector: 'app-avatar-bot',
  templateUrl: './avatar-bot.component.html',
  styleUrls: ['./avatar-bot.component.scss'],
})
export class AvatarBotComponent implements OnInit {
  @Input('seed') seed: string;
  @ViewChild('svgcontainer', { static: true }) dataContainer: ElementRef<HTMLDivElement>;

  constructor() { }

  ngOnInit() {
    let options = {seed : this.seed};
    let avatars = createAvatar(style,options);
    this.dataContainer.nativeElement.innerHTML = avatars;
  }

}
