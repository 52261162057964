import { PlayTTSService } from "src/app/services/play-tts.service";
import { ScenarioOse } from "./scenario-ose";
import { ChangeDetectorRef } from "@angular/core";
import { AccountService } from "../services/account.service";
import { GlobalService } from "../services/global.service";
import { ScenarioPhrase } from "./scenario-phrase";
import { Student } from "./student";
import { Ose2Journey } from "./ose2-journey";
import { OseMap } from "./ose-maps";
import { OseJourneyService } from "../services/ose-journeys.service";

export class ScenarioNavigationOse extends ScenarioOse {
	private currentPlayerName: string;
	constructor(
		accountService: AccountService,
		globalService: GlobalService,
		oseJourneyService: OseJourneyService,
		page: any,
		cd: ChangeDetectorRef,
		public ttsService: PlayTTSService
	) {
		super(accountService, globalService, oseJourneyService, page, cd, ttsService);
		this.currentPlayerName = this.accountService.team[0]?.name;
	}

	async runMathiaSpeech(speechSequence) {
		// this.page.displayBubble(true);
		await super.runMathiaSpeech(speechSequence);
		// this.page.displayBubble(false);
	}

	async onPlayerSelected(student: Student) {
		const speechSequence: Array<any> = [new ScenarioPhrase([$localize`Bonjour ${student.name} !`])];
		await this.runMathiaSpeech(speechSequence);
	}

	killSpeech() {
		if (this.ttsService.currentTTSPCallback) {
			this.page.ttsService.killSpeech(this.ttsService.currentTTSPCallback);
		}
	}
}
