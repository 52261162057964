import { Pipe, PipeTransform } from '@angular/core';
import { Student } from '../models/student';

@Pipe({
	name: "filterStudent",
	pure: false
})
export class FilterStudent implements PipeTransform {
	transform(items: Student[]): Student[] {
		if (items) {
			return items.filter((item)=>{
				return !item.preview;
			});
		} else return null;
	}
}

@Pipe({
	name: "getStudentPreview",
	pure: false
})
export class FilterStudentPreview implements PipeTransform {
	transform(items: Student[]): Student {
		if (items) {
			return items.find((item)=>{
				return item.preview;
			});
		} else return null;
	}
}
