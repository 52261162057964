import { AppUtils } from "src/app/app-utils";
import { QuizzAnswer } from "./../../models/quizz/quizz-question";
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { GlobalService } from "src/app/services/global.service";
import { AppLanguage } from "src/app/models/enums/enum-list";

@Component({
	selector: "app-quizz-pad",
	templateUrl: "./quizz-pad.component.html",
	styleUrls: ["./quizz-pad.component.scss"]
})
export class QuizzPadComponent implements OnInit {
	@Input() dyslexicMode = true;
	@Output() change: EventEmitter<{ code: any; event: any }> = new EventEmitter<{ code: any; event: any }>();
	@Input() activity: string = null;
	@Input() displayedPadChoice: Array<QuizzAnswer>;
	@Input() cd: ChangeDetectorRef;
	@Input() padLocked = false;
	@ViewChild("validateLogo", { static: false, read: ElementRef }) validateLogo: ElementRef;

	answer: number[];
	validated: boolean;
	public showResults = false;
	enterClicked: boolean;
	atLeastOneChoiceSelected: boolean;
	get AppLanguage() {
		return AppLanguage;
	}
	constructor(public renderer: Renderer2, public globalService: GlobalService) {}

	ngOnInit() {
		this.answer = new Array();
	}

	// PRESS ENTER
	async sendAnswer(value) {
		console.log("numpad enter");
		this.enterClicked = true;
		await AppUtils.timeOut(200);
		this.enterClicked = false;
		this.validated = true;
		this.change.emit({ code: this.answer, event: value });
		this.clearQuizz();
	}

	clearBooleanAnswers(choice: QuizzAnswer) {
		// if selected choice is boolean unselect all other answers
		if (
			choice.text.toLowerCase() === "vrai" ||
			choice.text.toLowerCase() === "oui" ||
			choice.text.toLowerCase() === "faux" ||
			choice.text.toLowerCase() === "non" ||
			choice.text.toLowerCase() === "je ne sais pas" ||
			choice.text.toLowerCase() === "les deux"
		) {
			this.displayedPadChoice.forEach(pad => {
				pad.selected = false;
				this.updateQuizzPinArray(pad.selected, pad.id);
			});
		} else {
			// if answer(s) selected contains boolean choice, clear answer(s)
			this.displayedPadChoice.forEach(pad => {
				if (
					(pad.text.toLowerCase() === "vrai" ||
						pad.text.toLowerCase() === "oui" ||
						pad.text.toLowerCase() === "faux" ||
						pad.text.toLowerCase() === "non" ||
						pad.text.toLowerCase() === "je ne sais pas") &&
					pad.selected
				) {
					pad.selected = false;
					this.updateQuizzPinArray(pad.selected, pad.id);
				} else if (pad.text.toLowerCase() === "les deux") {
					this.displayedPadChoice.forEach(pad2 => {
						pad2.selected = false;
						this.updateQuizzPinArray(pad2.selected, pad2.id);
					});
				}
			});
		}
	}

	updateQuizz(choice: QuizzAnswer): Promise<void> {
		return new Promise(async resolve => {
			if (!this.padLocked) {
				this.clearBooleanAnswers(choice);
				choice.selected = !choice.selected;
				this.displayedPadChoice.forEach(pad => {
					pad.updateStatus();
				});
				this.updateQuizzPinArray(choice.selected, choice.id);
				this.checkIfAtLeastOneChoiceSelected();
				console.error("this.pinQuizz = ", this.answer);
			}
		});
	}

	checkIfAtLeastOneChoiceSelected() {
		this.atLeastOneChoiceSelected = this.displayedPadChoice.some(choice => choice.selected);
	}

	updateQuizzPinArray(btnSelected: boolean, id) {
		if (btnSelected) {
			this.answer.push(id);
		} else {
			const index = this.answer.indexOf(id);
			if (index !== -1) {
				this.answer.splice(index, 1);
			}
		}
	}

	/**
	 * Clear answer each new question time
	 */
	clearAnswer() {
		const selectedBtn = document.querySelectorAll(".btn-selected");
		selectedBtn?.forEach(btn => {
			btn.classList.remove("btn-selected");
		});

		this.displayedPadChoice?.forEach(response => {
			response.selected = false;
		});
	}

	clearQuizz() {
		this.answer = new Array();
	}

	public showPadResults(value: boolean = null) {
		if (value === null) {
			this.showResults = !this.showResults;
		} else {
			this.showResults = value;
		}
		this.displayedPadChoice.forEach(choice => {
			console.error(choice.id + " " + choice.status + " " + this.showResults);
		});
	}
}
