import { LrsUtils } from "./lrsUtils";

export enum XObjectType {
	video = "video",
	exercise = "exercise",
	question = "question",
	journey = "journey"
}
export class XapiObject {
	id: string;
	definition: { name: object; description: object; type: string; extensions?: object };
	objectType: string;

	constructor(id: string, definitionName: string, definitionDescription: string, type: XObjectType, definitionExtensions?: any) {
		this.id = id;
		this.definition = {
			name: { "fr-FR": definitionName },
			description: { "fr-FR": definitionDescription },
			type: LrsUtils.statementUrl + "/objectType/" + type,
			extensions: !definitionExtensions ? undefined : definitionExtensions
		};
	}
}
