import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject } from "rxjs";
import { environment } from "src/environments/environment";
import { FicheEtapes } from "../components/fiche/fiche.component";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { CompletedExercises, JourneysStatus } from "../models/lrs/ose-stats-models";
import { OseActivity } from "../models/ose-activities";
import { OseCompetencies } from "../models/ose-competencies";
import { OseMap } from "../models/ose-maps";
import { Ose2Journey, OseExerciceType } from "../models/ose2-journey";
import { POI } from "../models/poi";
import { AccountService } from "./account.service";
import { BaseService } from "./base.service";
import { CabriDataService } from "./cabri-data.service";
import { GlobalService } from "./global.service";
import { LmsService } from "./lms.service";
import { AudioService } from "./audio.service";
import { Details } from "../page/territoire/territoire.page";

@Injectable({
	providedIn: "root"
})
export class OseJourneyService extends BaseService {
	journeys: Ose2Journey[];
	public currentJourney: Ose2Journey;
	public currentMap: Ose2Journey | OseMap;
	oseActivities: OseActivity[];
	oseCompetencies: OseCompetencies[]; // @TODO another service
	public allJourneysLoaded: ReplaySubject<boolean> = new ReplaySubject(1);

	public launchExercise = new EventEmitter<OseActivity>();
	public exerciseEnd = new EventEmitter<void | boolean>();
	public exercisePrev = new EventEmitter<void | boolean>();
	public feedbackEnd = new EventEmitter<Event>();
	journeysStatus: Array<JourneysStatus>;
	sequencesTraces: Array<any>;
	exercisesStatus: Array<JourneysStatus>;
	currentCompletedJourneys: Array<JourneysStatus>;
	prevCompletedJourneys: Array<JourneysStatus>;
	onGoingJourneys: Array<JourneysStatus>;
	completedExercises: Array<CompletedExercises>;
	onGoingExercises: Array<CompletedExercises>;
	newCompletion: boolean;
	isPlaying: boolean;
	savedFeedback: any;
	initialized = false;
	tooltipsForWordSearch :Array<string> = [];
	constructor(
		public http: HttpClient,
		public router: Router,
		public accountService: AccountService,
		public cabriService: CabriDataService,
		public lmsService: LmsService,
		public globalService: GlobalService,
		public audioService:AudioService
	) {
		super();

		if (environment.ose || this.globalService.isPageOse) {
			this.init();
		}
	}

	init() {
		if (!this.initialized) {
			this.initialized = true;

			this.exerciseEnd.subscribe({
				next: data => {
					this.launchNextJourneyExercise(data);
				}
			});

			this.exercisePrev.subscribe({
				next: data => {
					this.launchPrevJourneyExercise();
				}
			});

			this.getAllJourneys();

			window.addEventListener("popstate", () => {
				if (this.currentJourney) {
					// enable to go back to the last journey exercise when browser return back is clicked
					const currExercise = this.currentJourney.getCurrentExercise();
					if (currExercise) {
						const currExerciseIndex = this.currentJourney.exercises.findIndex(ex => {
							return Number(ex.id) === Number(currExercise.id);
						});

						if (this.currentJourney.exercises[currExerciseIndex - 1]) {
							this.currentJourney.exercises[currExerciseIndex - 1].completed = false;
						}
					}
				}
			});
		}
	}

	getAllPOI(): Promise<POI[]> {
		return new Promise<POI[]>(async resolve => {
			const allMaps = await this.getMaps();
			await this.journeysLoaded();
			this.http.get(this.postApiKidaia + "?action=getPOI").subscribe({
				next: async (listePoi: POI[]) => {
					listePoi.forEach((poiElement, index) => {
						listePoi[index] = new POI(poiElement, allMaps, this.oseCompetencies);
					});
					resolve(listePoi);
				},
				error: error => {
					console.error("An error occured while recovering POI", error);
				}
			});
		});
	}

	private getMaps(): Promise<OseMap[]> {
		return new Promise<OseMap[]>(resolve => {
			this.http.get(this.postApiKidaia + "?action=getOseMaps").subscribe({
				next: (oseMaps: OseMap[]) => {
					resolve(oseMaps);
				},
				error: error => {
					console.error("An error occured while recovering ose maps", error);
				}
			});
		});
	}

	private async getAllJourneys() {
		await Promise.all([this.getOseCompetencies(), this.getAllActivities()]);
		this.http.get(this.postApiKidaia + `?action=getOseJourneys`).subscribe({
			next: async (oseJourneys: any[]) => {
				oseJourneys.forEach((j) => {
					j.exerciseIds = j.exerciseIds.concat(j.quizz)
				}) 
				oseJourneys.forEach((element, index, currItem) => {
					currItem[index] = new Ose2Journey(element, this.oseCompetencies, this.oseActivities);
					
					currItem[index].exercises.forEach((currEx, indEx) => {
						if(currItem[index] &&  currItem[index].quizz){
							const isEvaluation = currItem[index].quizz?.some((quizz,indQuizz) => {
								return Number(quizz.id) === Number(currEx.id);
							})
							if(isEvaluation){
								currEx.isevaluation = "1";
							}
						}
						if (!currEx) {
							currItem[index].exercises.splice(indEx, 1);
						}
					});
				});
				this.journeys = oseJourneys;
				// create of conclusion fiche element
				this.journeys.forEach(j => {
					let addedConclusionCount = 0;
					const getSeqExercisesIndexes = j.exercises
						.map((elm, idx) => {
							if ((elm.type !== OseExerciceType.fiche || elm.hasvideo === "1") && idx !== j.exercises?.length - 1) {
								return idx;
							} else {
								return "";
							}
						})
						.filter(String);
					getSeqExercisesIndexes.forEach((exIndex: any) => {
						const startExIndexInsert = exIndex + addedConclusionCount + 1;
						j.exercises.splice(startExIndexInsert, 0, {
							...j.createNewEtape( FicheEtapes.titleConclusion)
						});
						addedConclusionCount++;
					});
				});
				this.allJourneysLoaded.next(true);
			},
			error: error => {
				this.allJourneysLoaded.next(false);
				console.error("An error occured while recovering ose journeys", error);
			}
		});
	}

	public getOseCompetencies() {
		return new Promise<void>(resolve => {
			this.http.get(this.postApiKidaia + "?action=getOseCompetencies").subscribe({
				next: (oseCompetencies: OseCompetencies[]) => {
					this.oseCompetencies = oseCompetencies;
					resolve();
				},
				error: error => {
					console.error("An error occured while recovering ose competencies", error);
				}
			});
		});
	}

	// thematique
	public journeysLoaded(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (this.journeys) {
				resolve();
			} else {
				this.allJourneysLoaded.subscribe(loaded => {
					if (loaded) {
						resolve();
					}
				});
			}
		});
	}

	async getAllActivities() {
		return new Promise<void>(resolve => {
			this.http.get(this.postApiKidaia + "?action=getOseActivities").subscribe({
				next: (oseActivities: OseActivity[]) => {
					this.oseActivities = oseActivities;
					resolve();
				},
				error: error => {
					console.error("An error occured while recovering Activities", error);
				}
			});
		});
	}

	public getById(journeyId: number) {
		return this.journeys.find(journey => {
			return Number(journey.id) === journeyId;
		});
	}

	/**
	 * Initialize exercises statuses after completion of all of them
	 */
	public initializeStatuses(currJourney?: Ose2Journey) {
		if (currJourney) {
			currJourney.skippedMode = false;
			currJourney.started = false;
			currJourney.exercises.forEach(ex => {
				ex.completed = false;
				ex.skip = false
			});
		} else {
			this.journeys.forEach(journey => {
				journey.started = false;
				journey.skippedMode = false;
				journey.exercises.forEach(ex => {
					ex.completed = false;
					ex.skip = false
				});
			});
		}
	}

	public initializeJourney() {
		this.currentJourney = null;
		this.currentMap = null;
		if(this.journeys){
			this.journeys.forEach((j) => {
				j.exercises.forEach((jEx) => {
					jEx.alreadyCompleted = false;
				})
			})
		}
	
		this.initializeOseStats();
	}

	public initializeOseStats() {
		this.journeysStatus = null;
		this.exercisesStatus = null;
		this.completedExercises = null;
		this.currentCompletedJourneys = null;
		this.onGoingJourneys = null;
	}

	public getCurrentExerciseId() {
		if (this.currentJourney) {
			return this.currentJourney.getCurrentExercise().id;
		} else {
			return null;
		}
	}

	quitJourney() {
		this.tooltipsForWordSearch = [];
		this.audioService.playCancelSound();
		this.isPlaying = false;
		if(this.currentJourney){
			this.currentJourney.selectedCategory = null;
			this.currentJourney.currentCategoryExercise = new Array();
		}
		this.router.navigateByUrl("/territoire", {
			replaceUrl: true
		});
	}

	/**
	 * Verify if new journey is completed
	 */
	public isNewJourneyCompleted() {
		return (
			Array.isArray(this.journeysStatus) &&
			Array.isArray(this.prevCompletedJourneys) &&
			this.currentCompletedJourneys.length !== this.prevCompletedJourneys.length
		);
	}

	/**
	 * Launch next exercise of jouney when completed
	 * @return lrs statement not send at the end of the activity
	 */
	launchNextJourneyExercise(lrsError: boolean = false) {
		if (this.currentJourney) {
			const previousExercise = this.currentJourney.getCurrentExercise();
			if (previousExercise) {
				this.currentJourney.setExerciseCompleted(previousExercise.id);
			} else {
				LrsUtils.durationStartJourney = new Date().getTime();
				if (this.currentJourney.resume) {
					const resumesSeqSessionId = this.sequencesTraces.find(seqTraces => {
						return seqTraces.parcoursId === this.currentJourney.id;
					})?._id;
					if (resumesSeqSessionId) {
						LrsUtils.idJourneySession = resumesSeqSessionId;
					} else {
						LrsUtils.idJourneySession = LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
					}
				} else {
					LrsUtils.idJourneySession = LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
				}
			}
			if (this.currentJourney.isCompleted(false)) {
				if (!lrsError) {
					const areAllCompleted = this.currentJourney.exercises.filter(ex => !ex.isEtape)?.every((ex) => ex.completed);
					this.newCompletion = areAllCompleted
				}

				this.initializeStatuses();
				this.quitJourney();
			} else {
				this.newCompletion = false;
				this.currentJourney.started = true;
				const nextExercise = this.currentJourney.getCurrentExercise();
				if (previousExercise && previousExercise.type === nextExercise.type) {
					this.launchExercise.next(nextExercise);
				} else {
					const url = this.currentJourney.getExerciseUrlByType(nextExercise.type)
					this.router.navigateByUrl("/" + url).then(() => {
						this.isPlaying = true;
					});
				}
			}
		}
	}

	/**
	 * Launch prev exercise of jouney when completed
	 * @return lrs statement not send at the end of the activity
	 */
	launchPrevJourneyExercise() {
		if (this.currentJourney) {
			const currentExercise = this.currentJourney.getCurrentExercise();
			let previousExercise = this.currentJourney.getPrevExercise();
			previousExercise.completed = false;

			if(previousExercise.isEtape){
				previousExercise = this.currentJourney.getPrevExercise();
				previousExercise.completed = false;
			}

			if (currentExercise && currentExercise.type === previousExercise.type) {
				this.launchExercise.next(previousExercise);
			} else {
				const url = this.currentJourney.getExerciseUrlByType(previousExercise.type)
				this.router.navigateByUrl("/" + url).then(() => {
					this.isPlaying = true;
				});
			}
		}
	}

	addTooltipForWordSearch(title: string) {
		// if only one word in title and not already in list
		if(title.trim().split(" ").length === 1 && 
			this.tooltipsForWordSearch.indexOf(title) === -1){
			this.tooltipsForWordSearch.push(title);
		}
	}
}
