import { Component, Input, OnInit } from "@angular/core";
import { Chart, ChartDataset, ChartOptions, ChartType, LinearScale } from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import { SankeyController, Flow } from "chartjs-chart-sankey";

Chart.register(MatrixController, MatrixElement, LinearScale, SankeyController, Flow);
@Component({
	selector: "app-chartjs",
	templateUrl: "./chartjs.component.html",
	styleUrls: ["./chartjs.component.scss"]
})
export class ChartjsComponent implements OnInit {

  	@Input() chartData: ChartDataset[];
  	@Input() chartLabels: string[];
 	@Input() chartOptions: ChartOptions;
	@Input() chartType: ChartType;

	public canvasShape = "rectangle"; // mettre en rectangle les graphes pour mieux visualiser
	constructor() {}

	ngOnInit() {}
}
