<div class="goddessContainer">

  <div class="goddessTextContainer">
    <h1 class="goddessText">{{planetName}}</h1>
  </div>
  <div class="goddessWrapper">
    <div [class]="'goddess '+ className" [ngClass]="{'animateGodess': unlocked, 'disableGodess': !unlocked}"
      [attr.data-item]="svgTags.planet" [attr.data-planet]="currentChapter?.planet"
      [attr.data-narration]="currentChapter?.introduction">
    </div>
    <img src="/assets/img/hud/tableau-recompenses/cadenas.png" *ngIf="!unlocked" class="cadenasImg">
  </div>

  <div class="centeredMeteorContainer">
    <div class="centeredMeteor" [ngClass]="{'animateCenteredMeteor': unlocked}">
    </div>
  </div>

  <div class="bottomMeteorContainer">
    <div class="bottomMeteor" [ngClass]="{'animateBottomMeteor': unlocked}">
    </div>
  </div>
</div>