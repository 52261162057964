import { Badge, BadgeAchievementType, BadgeStepLocation, BadgeType } from "./badge";
import { Chapter } from "./chapter";

export class Badges {
	private badges: Badge[];
	constructor(badges: Badge[]) {
		this.badges = badges;
	}

	getAllBadges(): Badge[] {
		return this.badges;
	}

	getAllUnlockedBages(): Badge[] {
		return this.badges.slice().filter(badge => badge.unlocked);
	}

	getAllLockedBages(): Badge[] {
		return this.badges.slice().filter(badge => !badge.unlocked);
	}

	getAllBadgesByChapter(chapter: Chapter): Badge[] {
		return this.badges.slice().filter(badge => badge.chapterRequirement === chapter.id);
	}

	getAllBadgesByChapterAndSlot(chapter: Chapter, step: BadgeStepLocation | number): Badge[] {
		return this.badges.slice().filter(badge => badge.chapterRequirement === chapter.id && badge.chapterStep == step);
	}

	getAllBadgesByChapterAndSlotLocked(chapter: Chapter, step: BadgeStepLocation | number | BadgeStepLocation[]): Badge[] {
		const result = this.badges.slice().filter(badge => {
			if (Array.isArray(step)) {
				return badge.chapterRequirement === chapter.id && step.includes(badge.chapterStep) && !badge.unlocked;
			} else {
				return badge.chapterRequirement === chapter.id && badge.chapterStep == step && !badge.unlocked;
			}
		});
		return result;
	}

	getAllBadgesByCustomRules(rule: string | string[]): Badge[] {
		const result = this.badges.slice().filter(badge => {
			if (Array.isArray(rule)) {
				return badge.typeAchievement === BadgeAchievementType.rule && rule.includes(badge.ruleRequirement) && !badge.unlocked;
			} else {
				return badge.typeAchievement === BadgeAchievementType.rule && rule === badge.ruleRequirement && !badge.unlocked;
			}
		});
		return result;
	}

	getAllBadgesByCompetency(competency: string): Badge[] {
		return this.badges.slice().filter(badge => badge.competencyRequirement.includes(competency));
	}

	getAllBadgesByCompetencyLocked(competencies: string | string[]): Badge[] {
		if (Array.isArray(competencies)) {
			return this.badges
				.slice()
				.filter(badge => competencies.some(competency => badge.competencyRequirement?.includes(competency)) && !badge.unlocked);
		} else {
			return this.badges.slice().filter(badge => badge.competencyRequirement?.includes(competencies) && !badge.unlocked);
		}
	}

	getAllBadgesByType(type: BadgeType | BadgeType[]): Badge[] {
		return this.badges.slice().filter(badge => {
			if (Array.isArray(type)) {
				return type.includes(badge.type);
			} else {
				return badge.type === type;
			}
		});
	}

	getBadgeById(badgeId: string) {
		return this.badges.find(badge => badge.id === badgeId);
	}
}
