<div class="widget-clock" ng-class="themeClass" [ngClass]="{'multi' : multiAnalog}" ng-if="date">
  <div class="digital" *ngIf="showDigital">
    <span class="time">
      <span class="hours">
        <span class="firstDigit">{{ date.getHours() | number:'2.0-0' | slice:0:1 }}</span>
        <span class="secondDigit">{{ date.getHours() % 10 }}</span>
      </span><span> : </span>
      <span class="minutes">
        <span class="firstDigit">{{ date.getMinutes() | number:'2.0-0' | slice:0:1 }}</span>
        <span class="secondDigit">{{ date.getMinutes() % 10 }}</span>
      </span>
    </span>
  </div>
  <div class="analog" *ngIf="showAnalog" [ngClass]="{'multi' : multiAnalog}">
    <div class="square" [ngClass]="{'multiSquare' : multiAnalog}">
      <svg viewBox="0 0 100 100" *ngIf="hoursPositions">
        <g transform="translate(50,50)">
          <circle class="clock-face" [attr.r]="radiusClock" />
          <!--
            <line id="line1" [attr.x1]="140" [attr.x2.]="x2" [attr.y1]="10" [attr.y2]="10"
            [attr.transform]="'rotate()'" />
          -->
          <ng-container *ngIf="showHourNumbers">
            <svg:text *ngFor="let numberHour of majors; let i = index" [attr.x]="hoursPositions[i].x"
              [attr.class]="'hourNumber hourNumber-'+(i+1)" [attr.y]="hoursPositions[i].y" [attr.text-anchor]="'middle'"
              [attr.font-size]="hoursFontSize" [attr.font-family]="fontFamily" [attr.fill]="'#c92424'"
              [attr.opacity]="hiddenHours[i] ? 0 : 1">
              <tspan>{{i+1}}</tspan>
            </svg:text>
          </ng-container>
          <ng-container *ngIf="showMinuteNumbers">
            <svg:text *ngFor="let numberMinute of majors; let i = index" [attr.class]="'minuteNumber minuteNumber-'+((i+1)*5)"
              [attr.x]="minutesPositions[i].x" [attr.y]="minutesPositions[i].y" [attr.text-anchor]="'middle'"
              [attr.font-size]="minutesFontSize" [attr.font-family]="fontFamily" [attr.fill]="'#5182dd'"
              [attr.opacity]="hiddenMinutes[i] ? 0 : 1">
              <tspan>{{(i+1)*5}}</tspan>
            </svg:text>
          </ng-container>
          <line *ngFor="let minor of minors; let i = index" class="minor"
            [attr.y1]="radiusMinorCircle - lengthMinorLine" [attr.y2]="radiusMinorCircle"
            [attr.transform]="'rotate('+360 * i / minors.length+')'" />
          <line *ngFor="let major of majors; let i = index" class="major"
            [attr.y1]="radiusMajorCircle - lengthMajorLine" [attr.y2]="radiusMajorCircle"
            [attr.transform]="'rotate('+360 * i / majors.length+')'" />
          <line [style.visibility]="showMinutesHand ? 'visible' : 'hidden'" class="minute" [attr.y1]="2" [attr.y2]="-30">
            <animateTransform #minutesAnimation attributeType="XML" attributeName="transform" type="rotate"
              [attr.values]="minutesAnimationValues" dur="1s" fill="freeze" />
          </line>
          <line [style.visibility]="showHoursHand ? 'visible' : 'hidden'" class="hour" [attr.y1]="2" [attr.y2]="-20">
            <animateTransform #hourAnimation attributeType="XML" attributeName="transform" type="rotate"
              [attr.values]="hourAnimationValues" dur="1s" fill="freeze" />
          </line>
          <circle class="hour" [attr.r]="radiusClock / 25"/>
          <!--
            <g [attr.transform]="'rotate('+6 * date.getSeconds()+')'">
              <line class="second" [attr.y1]="10" [attr.y2]="-38" />
              <line class="second-counterweight" [attr.y1]="10" [attr.y2]="2" />
            </g>
          -->
        </g>
      </svg>
    </div>
  </div>
</div>