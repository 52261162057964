import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Badge, BadgeType } from "src/app/models/badge";
import { AccountService } from "src/app/services/account.service";
import { AnalyticService } from "src/app/services/analytic.service";
import { BadgeService } from "src/app/services/badge.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
	selector: "app-rewards-board",
	templateUrl: "./rewards-board.component.html",
	styleUrls: ["./rewards-board.component.scss"]
})
export class RewardsBoardComponent implements OnInit, AfterViewInit {
	cardsRewards = new Array(12).fill(null);
	currentArray = new Array();
	openMapFullScreen = false;
	skeletonsLoading = new Array(20);
	@ViewChild("tablitoBody", { static: false }) tablitoBody: ElementRef;
	@Input() getUserAllBadges: Badge[];
	@Output() goBackEvent = new EventEmitter<any>();
	// rewardType: string = "Competency";
	rewardType: any = {
		title: "Aventures" || "Compétences" || "IA404",
		badgeType: BadgeType.item,
		fullScreenCardDisplay: null,
		cssClass: "Aventures"
	};
	getItems: Badge[] = new Array();
	getAwards: Badge[] = new Array();
	getSkills: Badge[] = new Array();

	constructor(
		public globalService: GlobalService,
		public cabriDataService: CabriDataService,
		public accountService: AccountService,
		private elem: ElementRef,
		public cd: ChangeDetectorRef,
		public badgeService: BadgeService,
		public analyticService : AnalyticService
	) {
		this.analyticService.sendAnalytics("Navigation", {pageUrl : "LogBookStatisticComponent"});
	}

	get BadgeType() {
		return BadgeType;
	}

	ngAfterViewInit(): void {
		if (this.accountService.user?.badges) {
			this.getItems = this.accountService.user.badges.getAllBadgesByType([BadgeType.item, BadgeType.picture]);
			this.getAwards = this.accountService.user.badges.getAllBadgesByType(BadgeType.award);
			this.getSkills = this.accountService.user.badges.getAllBadgesByType(BadgeType.skill);

			this.getCurrentSelectedTypeCards();
		}
		this.cd.detectChanges();
	}

	getCurrentSelectedTypeCards() {
		if (this.rewardType.badgeType === BadgeType.item) {
			this.cardsRewards = new Array(this.getFillerSize(this.getItems)).fill(null);
			this.getItems.forEach((item, index) => {
				this.cardsRewards[index] = item;
			});
		} else if (this.rewardType.badgeType === BadgeType.award) {
			this.cardsRewards = new Array(this.getFillerSize(this.getAwards)).fill(null);
			this.getAwards.forEach((item, index) => {
				this.cardsRewards[index] = item;
			});
		} else {
			this.cardsRewards = new Array(this.getFillerSize(this.getSkills)).fill(null);
			this.getSkills.forEach((item, index) => {
				this.cardsRewards[index] = item;
			});
		}

		// sort asc unlocked first
		this.cardsRewards.sort((a, b) => b?.unlocked - a?.unlocked);
	}

	changeSource(cardReward){
		// offline load local img
		cardReward.picture = "assets/reward/cards/"+cardReward.id+".png";
	}

	private getFillerSize(badges: Badge[]): number {
		if (badges?.length > 12) {
			return badges.length
		} else {
			return 12;
		}
	}

	goBack() {
		this.goBackEvent.next(true);
	}

	async selectedMap($event: any, badgeType: BadgeType) {
		if (this.rewardType.badgeType !== badgeType) {
			this.rewardType.badgeType = badgeType;
			if ($event.currentTarget) {
				const className = this.globalService.isDesktop ? "selectedMenuItem" : "selectedMenuItem";
				this.elem.nativeElement.querySelectorAll(`.${className}`).forEach((elem: HTMLElement) => {
					elem.classList.remove(className);
				});
				$event.currentTarget.classList.add(className);
			}
			this.cardsRewards = new Array(12);
			if (this.rewardType.badgeType === BadgeType.item) {
				this.rewardType.cssClass = this.rewardType.title = "Aventures";
			} else if (this.rewardType.badgeType === BadgeType.skill) {
				this.rewardType.title = "Compétences";
				this.rewardType.cssClass = "Competences";
			} else {
				this.rewardType.cssClass = this.rewardType.title = "IA404";
			}

			this.getCurrentSelectedTypeCards();
		}
	}

	selectCard(currentCard: string) {
		this.openMapFullScreen = true;
		this.rewardType.fullScreenCardDisplay = currentCard;
	}

	dismiss() {}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	async timeOut(ms): Promise<void> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	}
	ngOnInit() {}
}
