import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss'],
})
export class IframeModalComponent implements OnInit {
  @Input() myUrl: string;
	constructor(public modalController: ModalController) {	}
	ngOnInit(): void {
		
	}
	dismiss() {
		this.modalController.dismiss({ dismissed: true });
	}
}
