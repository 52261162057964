import { Probleme } from "./probleme";

export class Exercise {
	categories: Array<any>;
	classe: string;
	competences: { [competenceId: string]: string };
	competencesValidees: { [competenceId: string]: string };
	description: string;
	displayMode: string;
	erreursType: Array<any>;
	erreursTypeValidees: Array<any>;
	gabarit: string;
	id: number;
	name: string;
	nameEn: string;
	numpadMode: string;
	operation: string;
	postId: string;
	prerequis: Array<any>;
	responseMode: string;
	resultMode: string;
	variables: any;
	version: string;
	probleme: Probleme;
	step: string | number;
	categoriesObject: any;
	displayResult = {
		collection: { value: null, image: "" },
		fingers: { value: null, image: "" },
		coins: { value: null, image: "" },
		dice: { value: null, image: "" },
		letters: { value: null, image: "" }
	};

	constructor(exercise) {
		this.categoriesObject = exercise.categories;
		this.categories = [];
		Object.entries(this.categoriesObject).forEach(([key, value]) => {
			this.categories.push(value);
		});
		this.classe = exercise.classe;

		this.competences = exercise.competences;
		this.competencesValidees = exercise.competences_validees;
		this.description = exercise.description;
		this.displayMode = exercise.display_mode;
		this.erreursType = exercise.erreurs_type;
		this.erreursTypeValidees = exercise.erreurs_type_validees;
		this.gabarit = exercise.gabarit;
		this.id = exercise.id;
		this.name = exercise.name;
		this.nameEn = exercise.name_en;
		this.numpadMode = exercise.numpad_mode;
		this.operation = exercise.operation;
		this.postId = exercise.post_id;
		this.prerequis = exercise.prerequis;
		this.responseMode = exercise.response_mode;
		this.resultMode = exercise.result_mode;
		this.variables = exercise.variables;
		this.version = exercise.version;
		this.probleme = exercise.probleme;
		this.step = exercise.step;
	}
}
