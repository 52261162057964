<div class="card" (click)="cardClicked.emit()" [@cardFlip]="data.state" [ngClass]="data.state">
  <div class="back">
  </div>
    <div class="front">
      <ng-container *ngIf="!data.displayText">
 
        <div class="img" [ngClass]="{'textWon': showText, 'textWonEnd':endFlipCards}" *ngIf="data.imageId">
          <div class="imgContainer">
            <img [src]="data.imageId">
          </div>
          <div class="textAudioContainer" [hidden]="!data.displayText && !endFlipCards">
            <div class="playTextContainer" (click)="readText(data.text)">
              <img  src="/assets/icon/play-ose.svg">
            </div>
              <div class="textContainer">
                <p>{{data.text}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.displayText">
        <div class="text">
          <p>{{data.text}}</p>
        </div>
      </ng-container>
    </div>
</div>