export class Result {
    success:boolean
    duration:string

    constructor(success : boolean,duration : string){
        this.success = success;
        this.duration = duration;
        this._getResult();
    }

    private _getResult(){
        return{
            success : this.success,
            duration : this.duration
        }
    }
}
