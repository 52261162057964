// export class Verbs {
// 	[name: string]: { id: string; display: { "en-US": string; "fr-FR": string } };
// }

import { LrsUtils } from "./lrsUtils";

export enum LrsVerbs {
	initialized = "initialized",
	resume = "resume",
	passed = "passed",
	passedWithHelp = "passed-with-help",
	passedOnRetry = "passed-on-retry",
	failedOnRetry = "failed-on-retry",
	failed = "failed",
	failedOnFirstAttempt = "failed-on-first-attempt",
	finished = "finished",
	completed = "completed",
	skipped = "skipped"
}

export class XapiVerbs {
	static verbs = {
		initialized: {
			id: `$url$/verbs/initialized`,
			display: {
				"en-US": "initialized",
				"fr-FR": "a commencé"
			}
		},
		resume: {
			id: `$url$/verbs/resume`,
			display: {
				"en-US": "resume",
				"fr-FR": "a repris"
			}
		},
		passed: {
			id: `$url$/verbs/passed`,
			display: {
				"en-US": "passed",
				"fr-FR": "a réussi"
			}
		},
		"passed-with-help": {
			id: `$url$/verbs/passed-with-help`,
			display: {
				"en-US": "passed with help",
				"fr-FR": "a réussi avec de l'aide"
			}
		},
		"passed-on-retry": {
			id: `$url$/verbs/passed-on-retry`,
			display: {
				"en-US": "passed on retry",
				"fr-FR": "a réussi lors d'une nouvelle tentative"
			}
		},
		"failed-on-retry": {
			id: `$url$/verbs/failed-on-retry`,
			display: {
				"en-US": "failed on retry",
				"fr-FR": "s'est trompé lors d'une nouvelle tentative"
			}
		},
		failed: {
			id: `$url$/verbs/failed`,
			display: {
				"en-US": "failed",
				"fr-FR": "s'est trompé"
			}
		},
		"failed-on-first-attempt": {
			id: `$url$/verbs/failed-on-first-attempt`,
			display: {
				"en-US": "failed-on-first-attempt",
				"fr-FR": "s'est trompé lors d'une première tentative"
			}
		},
		finished: {
			id: `$url$/verbs/finished`,
			display: {
				"en-US": "finished",
				"fr-FR": "a terminé"
			}
		},
		completed: {
			id: `$url$/verbs/completed`,
			display: {
				"en-US": "completed",
				"fr-FR": "a achevé"
			}
		},
		skipped: {
			id: `$url$/verbs/skipped`,
			display: {
				"en-US": "skipped",
				"fr-FR": "a passé"
			}
		}
	};

	static get(key) {
		try {
			this.verbs[key].id = this.verbs[key].id.replace(/(\$url\$|https:\/\/xapi\.ose|https:\/\/xapi\.mathia\.education)/g, LrsUtils.statementUrl);
			return this.verbs[key];
		} catch (e) {
			throw new Error("Verb " + key + "doesn't exists");
		}
	}

	static getText(id, lang = "fr-FR") {
		for (const verb in this.verbs) {
			if (this.verbs.hasOwnProperty(verb) && this.verbs[verb].id.replace(/(\$url\$|https:\/\/xapi\.ose|https:\/\/xapi\.mathia\.education)/g, LrsUtils.statementUrl) === id) {
				return this.verbs[verb].display[lang];
			}
		}
		throw new Error("Verb " + id + "doesn't exists");
	}
}
