import { Component, Input, OnInit } from "@angular/core";
import { svgTags } from "../../galaxy-map/galaxy-map.component";

@Component({
	selector: "app-tellurique",
	templateUrl: "./tellurique.component.html",
	styleUrls: ["./tellurique.component.scss"]
})
export class TelluriqueComponent implements OnInit {
	constructor() {}

	ngOnInit() {}

	get svgTags() {
		return svgTags;
	}
}
