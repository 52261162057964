import { AudioService } from "src/app/services/audio.service";
import { Component, Input, OnInit } from "@angular/core";
import { Engine } from "@babylonjs/core";
import { ModalController } from "@ionic/angular";
import { PlayTTSService } from "src/app/services/play-tts.service";

@Component({
  selector: "app-audio-engine-locked",
  templateUrl: "./audio-engine-locked.component.html",
  styleUrls: ["./audio-engine-locked.component.scss"],
})
export class AudioEngineLockedComponent implements OnInit {
  @Input() launchMusic: boolean;
  constructor(public modalCtrl: ModalController, public ttsService: PlayTTSService, public audioService: AudioService) { }

  ngOnInit() {}

  click() {
    if (this.launchMusic) {
      this.audioService.launchMusic(true);
    }
    Engine.audioEngine.unlock();
    this.ttsService.playTTS(" ");
    this.modalCtrl.dismiss();
  }
}
