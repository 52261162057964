import { OseCompetencies } from "./ose-competencies";
import { OseMap } from "./ose-maps";

export class POI {
	id: number;
	maps: Array<OseMap>; // first recovered map index from server before transforming to oseMaps
	title: string;

	constructor(poiElement, maps: Array<OseMap>, oseCompetencies: Array<OseCompetencies>) {
		this.id = poiElement.id;
		this.maps = this.getMapsObject(poiElement.maps, maps, oseCompetencies);
		this.title = poiElement.title;
	}

	/**
	 * Change map ids from server to an object
	 */
	private getMapsObject(poiMaps: Array<any>, allMaps: Array<OseMap>, oseCompetencies: Array<OseCompetencies>): Array<OseMap> {
		poiMaps.forEach((currMapId, index) => {
			// const currMap = allMaps.find(map => map.id == currMapId);
			const currMap = allMaps.find(map => Number(map.id) === Number(currMapId));
			poiMaps[index] = currMap;
			if(poiMaps[index]?.competencies){
				this.getCompetenciesObject((poiMaps[index] as any).competencies, oseCompetencies);
			}
		});
		return poiMaps;
	}

	/**
	 * Convert competency ids into an object of competencies
	 *
	 * @param competencies competencies ids from server
	 * @param oseCompetencies competencies width multiple properties
	 */
	private getCompetenciesObject(competencies: Array<any>, oseCompetencies: Array<OseCompetencies>) {
		competencies.forEach((competencyId, index) => {
			if (typeof competencyId === "number") {
				competencies[index] = oseCompetencies.find(oseCompetency => Number(competencyId) === Number(oseCompetency.id));
			}
		});
	}
}
