import { AudioService } from "src/app/services/audio.service";
import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Param } from "src/app/models/params/param";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService, MenuEvents } from "src/app/services/global.service";
import { userAlertActions } from "src/app/services/account.service";
import { PlayTTSService } from "src/app/services/play-tts.service";
import { LmsService } from "src/app/services/lms.service";
import { NetworkService } from "src/app/services/network.service";
import { ConnectionStatusUpdaterService } from "src/app/services/connection-status-updater.service";
import { CabriActivity } from "src/app/models/cabri-activity";
import { AppUtils } from "src/app/app-utils";
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
@Component({
	selector: "app-activity-settings",
	templateUrl: "./activity-settings.component.html",
	styleUrls: ["./activity-settings.component.scss"]
})
export class ActivitySettingsComponent implements OnInit {
	@Output() launchStoryActivityToolbar: EventEmitter<any> = new EventEmitter();
	@Output() alertMessageAnswer = new EventEmitter<any>();
	@Input() changeD: ChangeDetectorRef;
	@Input() getHelp: boolean;
	@Input() firstTimeParamSelection: boolean;
	@Output() closeSettingsFromActivitySettings : EventEmitter<any> = new EventEmitter();;
	@Output() switchSettingParam: EventEmitter<boolean> = new EventEmitter();
	@Output() onReady: ReplaySubject<boolean> = new ReplaySubject();
	public displayParams = false;
	public alertTitle = $localize`Es-tu sûr de vouloir changer le paramètre ?`;
	public generalParams: Param[] = new Array<Param>();
	@ViewChildren("paramContainer") paramContainer: QueryList<ElementRef>;

	freeModeParams = {
		holo: null,
		inputMethods: null,
		remoteHost: null
	};

	constructor(
		public globalService: GlobalService,
		public lmsService: LmsService,
		public cabriService: CabriDataService,
		public playTTSService: PlayTTSService,
		public networkService: NetworkService,
		public connectionStatusUpdater: ConnectionStatusUpdaterService,
		public audioService: AudioService,
		public router:Router
	) {}

	async ngOnInit() {
		this.globalService.menuStorySettingOpenedEvent.subscribe(async (event) => {
			if(event === "setInputMethod"){
				this.globalService.storySettingOpened = true;
			}
			if (this.globalService.storySettingOpened) {
				this.displayParams = false;
				await this.cabriService.getAllActivitiesAsync();
				if ((this.globalService.onActivityPage || this.isAdventureMode) && !this.globalService.isPageOse) {
					// when setting is opened during the activity
					this.cabriService.currentActivity.params.forEach(param => {
						if (param.name === "holo") {
							// holo keep not hidden params
							param.selectionList = param.selectionList.filter(selectedList => {
								return !selectedList[3];
							});
							// !!! important save as copy
							this.freeModeParams.holo = { ...param };
						} else if (param.name === "input-method") {
							// !!! important save as copy
							this.freeModeParams.inputMethods = { ...param };
						} else if (param.name === "remoteHost") {
							// !!! important save as copy
							this.freeModeParams.remoteHost = { ...param };
						}
					});

					const inputMethodExistInActivty = this.cabriService.currentActivity.params.some(param => {
						return param.name === "input-method";
					});
					if (!inputMethodExistInActivty) {
						// input-method not exist in the activity so choose random activity which has 3 values of input-method
						const inputMethodActivity = this.getActivityByCondition("input-method");
						if (inputMethodActivity) {
							const inputMethod = inputMethodActivity.getParam("input-method");
							// create copy to not change the original random activity param choosen
							this.freeModeParams.inputMethods = { ...inputMethod };
						}
					}

					if (this.freeModeParams.inputMethods && this.cabriService.inputMethod) {
						// bind inputMethod value to current activity value
						this.freeModeParams.inputMethods.value = this.cabriService.inputMethod;
					}
					this.filterInputMethods(this.freeModeParams.inputMethods);
					this.createFilteredParams();
					if(event === "setInputMethod"){
						this.changeInputMethodDuringActivity();
						this.globalService.storySettingOpened = false;
					}
				} else {
					// when setting is opened during the activity
					this.freeHoloSettingsChoice();
				}
			}
			this.displayParams = true;
			this.onReady.next(true);
			this.onReady.complete();
		});
	}

	public get isAdventureMode(): boolean {
		return (
			this.globalService.firstLaunchStoryActivity ||
			(this.cabriService.currentActivity?.story && Object.values(this.cabriService.currentActivity?.story).length > 0) ||
			this.lmsService.currentUserJourney?.remediation
		);
	}

	/**
	 * Check if user validated parameter is cabri zero writing activity
	 * @returns boolean
	 */
	public get isCabriEcritureExercise():boolean{
		if(this.cabriService.currentExercice &&
			this.cabriService.currentExercice.responseMode &&
			this.cabriService.currentExercice.responseMode === "not-voice"){
					return true
			}
			return false
	}

	/**
	 * Keep input method params if they are allowed
	 * @param inputMethod Param
	 */
	filterInputMethods(inputMethod: Param) {
		if (inputMethod?.selectionList) {
			if (this.cabriService.currentExercice.resultMode === "+zero" && this.freeModeParams?.holo?.value) {
				if (
					this.isCabriEcritureExercise
				) {
					// réponse possible uniquement au numpad et drawing
					this.cabriService.numpadAllowed = true;
					this.cabriService.drawingAllowed = true;
					this.cabriService.sttAllowed = false;

					if(this.cabriService.currentActivity && typeof this.cabriService.currentActivity.setParamValue === "function"){
						this.cabriService.currentActivity.setParamValue("input-method", "drawing");
					}
				} else {
					// réponse possible uniquement au micro
					this.cabriService.numpadAllowed = false;
					this.cabriService.drawingAllowed = false;
					this.cabriService.sttAllowed = true;
				}
			}

			if (!this.cabriService.numpadAllowed) {
				inputMethod.selectionList = inputMethod.selectionList.filter(selectedList => {
					return selectedList[1] !== "numpad";
				});
			}
			if (!this.cabriService.drawingAllowed) {
				inputMethod.selectionList = inputMethod.selectionList.filter(selectedList => {
					return selectedList[1] !== "drawing";
				});
			}
			if (!this.cabriService.sttAllowed) {
				inputMethod.selectionList = inputMethod.selectionList.filter(selectedList => {
					return selectedList[1] !== "stt";
				});
			}
		}
	}

	get userAlertActions() {
		return userAlertActions;
	}

	/**
	 * Select all holo params without activity defined
	 */
	async freeHoloSettingsChoice() {
		this.recoverFilteredParams();
		if (this.changeD) {
			this.changeD.detectChanges();
		}
	}

	public get activitiesParamsTargetted(): {
		holoActivity: CabriActivity;
		inputMethodActivity: CabriActivity;
		remoteHostActivity: CabriActivity;
	} {
		const holoActivity = this.getActivityByCondition("holo");
		const inputMethodActivity = this.getActivityByCondition("input-method");
		let remoteHostActivity;
		if (this.networkService.isConnected) {
			remoteHostActivity = this.getActivityByCondition("remoteHost");
		}

		return { holoActivity, inputMethodActivity, remoteHostActivity };
	}

	/**
	 * Recover and create new Array with stoored parameters
	 */
	recoverFilteredParams() {
		const { holo, inputMethod, remoteHost } = this.globalService.generalParamsValues;
		// get any activity parameter passed through the param
		const { holoActivity, inputMethodActivity, remoteHostActivity } = this.activitiesParamsTargetted;

		let cabriActivityHolo;
		let cabriActivityInputMethod;
		let cabriActivityRemoteHost;

		if (holoActivity) {
			// create new Cabri Activity in order to not erase it's original values (holo)
			cabriActivityHolo = new CabriActivity();
			cabriActivityHolo.restoreActivity(holoActivity);
			const holoActivityOfAct = holoActivity.getParam("input-method");
			if (holo?.value) {
				// LocalStorage value exist
				cabriActivityHolo.setParamValue("holo", holo.value);
			} else if (holoActivityOfAct?.value) {
				// LocalStorage value not exist
				holoActivity.setParamValue("input-method", holoActivityOfAct.value);
			}
		}

		if (inputMethodActivity) {
			// create new Cabri Activity in order to not erase it's original values (inputMethod)
			cabriActivityInputMethod = new CabriActivity();
			cabriActivityInputMethod.restoreActivity(inputMethodActivity);
			let inputMethodOfAct;
			if (remoteHostActivity) {
				inputMethodOfAct = remoteHostActivity.getParam("input-method");
			}
			if (inputMethod.value) {
				// LocalStorage value exist
				cabriActivityInputMethod.setParamValue("input-method", inputMethod.value);
			} else if (inputMethodOfAct?.value) {
				// LocalStorage value not exist
				cabriActivityInputMethod.setParamValue("input-method", inputMethodOfAct.value);
			}
		}

		if (remoteHostActivity) {
			// create new Cabri Activity in order to not erase it's original values (remoteHos)
			cabriActivityRemoteHost = new CabriActivity();
			cabriActivityRemoteHost.restoreActivity(remoteHostActivity);
			const remoteHostOfAct = remoteHostActivity.getParam("remoteHost");
			if (remoteHost.value) {
				// if LocalStorage value exist
				cabriActivityRemoteHost.setParamValue("remoteHost", remoteHost.value);
			} else if (remoteHostOfAct?.value) {
				// LocalStorage value not exist
				cabriActivityRemoteHost.setParamValue("remoteHost", remoteHostOfAct.value);
			}
		}
		this.generalParams = new Array();
		// Add of parameteres
		const paramHolo = cabriActivityHolo?.getParam("holo");
		const paramInputMethod = cabriActivityInputMethod?.getParam("input-method");

		if (paramInputMethod) {
			this.generalParams.push(paramInputMethod);
		}
		if (paramHolo) {
			this.generalParams.push(paramHolo);
		}
		if (!this.globalService.isKidaia) {
			const remoteHostParam = cabriActivityRemoteHost?.getParam("remoteHost");
			if (remoteHostParam) {
				this.generalParams.push(remoteHostParam);
			}
		}
	}

	/**
	 * Add of params in Array of params
	 */
	createFilteredParams() {
		const { inputMethods, remoteHost, holo } = this.freeModeParams;
		const activityHolo = this.cabriService.currentActivity?.getParam("holo");
		if (activityHolo) {
			// hide or show holo mode depending of network status
			const value = activityHolo.selectionList.find(selectionList => {
				return selectionList[1] == "-1";
			});
			if (value) {
				if (!this.networkService.isConnected) {
					// hide
					value[3] = true;
				} else {
					// show
					value[3] = false;
				}
			}
		}

		const remooteHostNeed = !this.isAdventureMode && !this.globalService.isKidaia && remoteHost;
		if (inputMethods || holo || remooteHostNeed) {
			this.generalParams = new Array();
		}

		if (inputMethods) {
			this.generalParams.push(inputMethods);
		}
		if (holo) {
			this.generalParams.push(holo);
		}
		// add of two additional params
		if (remooteHostNeed) {
			this.generalParams.push(remoteHost);
		}
	}

	/**
	 * Get any Cabri Activity which parameters selection list's length is the longest
	 *
	 * @param paramName the name of the parameter
	 * @returns Cabri Activity
	 */
	public getActivityByCondition(paramName: string): CabriActivity {
		let currentActivity;
		let lastActivityParamListLength = 0;
		this.cabriService.activities.forEach((activity, index) => {
			activity.params.forEach(currentParam => {
				if (currentParam.name === paramName && !currentParam.hidden) {
					let last;
					if (index - 1 < 0) {
						last = this.cabriService.activities[this.cabriService.activities.length - 1];
					} else {
						last = this.cabriService.activities[index - 1];
					}
					if (last) {
						lastActivityParamListLength = last.params
							.map(list => {
								if (list.name === paramName) {
									return list.selectionList.length;
								}
							})
							.filter(uNdefined => uNdefined !== undefined)?.[0];
					}

					const offlineFilteredActivity = () => {
						// exception offline no pyramid activity selection
						if (!this.networkService.isConnected) {
							return activity.params
								.map(param => {
									if (param.name === paramName && param.name === "holo") {
										if (param.selectionList?.length >= 2) {
											const find = param.selectionList?.find(currList => {
												return Number(currList[1]) === -1;
											});
											if (!find) {
												return activity;
											}
										}
									}
								})
								.filter(uNdefined => uNdefined !== undefined)?.[0];
						}
					};
					const filteredActivity = offlineFilteredActivity();
					if (filteredActivity) {
						currentActivity = filteredActivity;
					} else {
						if (!currentActivity || currentParam.selectionList.length >= lastActivityParamListLength) {
							// if loop current activity param selection list lenght is longer than previous activity's one
							currentActivity = activity;
						}
					}
				}
			});
		});
		return currentActivity;
	}


	/**
	 * No pyramid mod
	 */
	changeParamsDuringActivity() {
		if (this.cabriService.currentActivity) {
			let holoValue: string;
			let remoteHostValue: string;
			this.generalParams.forEach(param => {
				if (param.name === "holo") {
					holoValue = param.value;
				} else if (param.name === "remoteHost") {
					remoteHostValue = param.value;
				}
			});

			// display alert of warning
			// no pyramid
			if (holoValue === "2") {
				this.switchSettingParam.next(true);
			} else if (holoValue === "0") {
				this.switchSettingParam.next(false);
			}
			this.closeActivitySettings();
			AppUtils.timeOut(100).then(() => {
				if (this.changeD) {
					this.changeD.detectChanges();
				}
			});
		}
	}

	/**
	 * Restore changes after no confirmation from modal
	 */
	public restoreGeneralParams() {
		this.cabriService.currentActivity.params.forEach(curParam => {
			this.generalParams.forEach(genParam => {
				if (curParam.name === genParam.name) {
					genParam.value = curParam.value;
				}
			});
		});
		this.saveSettingsParam();
	}

	/**
	 * Verify if there is a need of changing and saving user selected param.
	 * @return true if there has been a new change in the selection of parameters
	 */
	public get newParamSelected(): boolean {
		if (this.globalService.onActivityPage && this.cabriService.currentActivity) {
			// filtering and keeping only needed params (input-method,remote host,holo)
			const currentActivityParams = this.cabriService.currentActivity.params.filter(curParam => {
				return this.generalParams.some(genParam => {
					return genParam.name === curParam.name;
				});
			});

			// verify if there is a change
			return currentActivityParams.some(param => {
				return this.generalParams.some(userSelectedParam => {
					if (param.value !== userSelectedParam.value) {
						return true;
					} else {
						return false;
					}
				});
			});
		} else {
			return true;
		}
	}

	/**
	 * Verify if need display alert if holo or remote host choosen and vice versa
	 * @return true so need display alert in order to confirm or not changes
	 */
	public get needWatchOutParamChanges(): boolean {
		const activityHoloValue = this.cabriService.currentActivity.getParam("holo")?.value;
		const activityRemostHostValue = this.cabriService.currentActivity.getParam("remoteHost")?.value;
		let holoValue: string;
		let remoteHostValue: string;
		this.generalParams.forEach(param => {
			if (param.name === "holo") {
				holoValue = param.value;
			} else if (param.name === "remoteHost") {
				remoteHostValue = param.value;
			}
		});

		if (activityHoloValue === "-1" || holoValue === "-1" || activityRemostHostValue === "1" || remoteHostValue === "1") {
			if (remoteHostValue) {
				if (remoteHostValue !== activityRemostHostValue) {
					// need to display alert
					return true;
				}
			}

			if (holoValue) {
				if (activityHoloValue !== holoValue) {
					// need to display alert
					return true;
				}
			}
		} else {
			return false;
		}
	}

	public getParamByName(paramName) {
		return this.generalParams?.find(param => {
			return param.name === paramName;
		});
	}
	/**
	 * Launch activity after validating all params
	 */
	validateChoice() {
		this.audioService.playSelectSound();
		this.saveSettingsParam();
		this.closeSettingsFromActivitySettings.next(true);
	}

	/**
	 * CHeck if need to force numpad for zero writing activity
	 * 
	 * @param paramValue string
	 * @returns boolean
	 */
	public checkCabriZeroNeedForceNumpad(paramValue:string):boolean{
		let forceNumpad = false;
		if (this.cabriService.currentActivity && this.cabriService.currentExercice?.resultMode === "+zero" && this.freeModeParams?.holo?.value) {
			if (
				this.isCabriEcritureExercise && paramValue === "numpad"
			) {
				forceNumpad = true;
			}
		}
		return forceNumpad
	}
	/**
	 * Store setting values when validate button is clicked
	 */
	saveSettingsParam() {
		this.generalParams.forEach(param => {
			if (param.name === "holo") {
				this.globalService.generalParamsValues.holo.name = param.name;
				this.globalService.generalParamsValues.holo.value = param.value;
			}

			if (param.name === "input-method") {
				this.globalService.generalParamsValues.inputMethod.name = param.name;
				this.globalService.generalParamsValues.inputMethod.value = param.value;
				this.cabriService.settingZeroModeNumpadAllowed = this.checkCabriZeroNeedForceNumpad(param.value);
			}

			if (param.name === "remoteHost") {
				this.globalService.generalParamsValues.remoteHost.name = param.name;
				this.globalService.generalParamsValues.remoteHost.value = param.value;
			}
		});

		sessionStorage.setItem("generalParamsValues", JSON.stringify(this.globalService.generalParamsValues));
	}

	changeInputMethodDuringActivity(){
		const curentInputMethod = this.generalParams.find(param => {
			return param.name === "input-method";
		})

		if(curentInputMethod){
			curentInputMethod.value = this.cabriService.inputMethod;
			this.cabriService.currentActivity.setParamValue(curentInputMethod.name, curentInputMethod.value);
			this.cabriService.currentActivity.buildVariables();
		}
		this.saveSettingsParam();
	}

	closeActivitySettings() {
		if (this.globalService.menuOpenEvent) {
			this.globalService.menuOpenEvent.next({ open: false, target: MenuEvents.Holo });
		}
	}
}
