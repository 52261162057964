import { Injectable } from "@angular/core";
import { ComponentsModule } from "../components/components.module";
import { LrsVerbs, XapiVerbs } from "../models/lrs/xapiverbs";
import { AccountService } from "./account.service";
import { GlobalService } from "./global.service";
import { LrsService } from "./lrs.service";
import { OseJourneyService } from "./ose-journeys.service";

@Injectable({
	providedIn: "root"
})
export class OseStatsService {
	constructor(
		public accountService: AccountService,
		public lrs: LrsService,
		public oseJourneys: OseJourneyService,
		public globalService: GlobalService
	) {}

	public completedJourneys() {
		return new Promise<void>((resolve, reject) => {
			if (this.oseJourneys.journeysStatus) {
				resolve();
			} else {
				if (!this.accountService.team[0]) {
					this.oseJourneys.journeysStatus = null;
					resolve();
				} else {
					const statement = [
						{
							$match: {
								timestamp: {
									$gte: {
										$dte: "2022-09-02T08:09:00.000Z"
									}
								}
							}
						},
						{
							$match: {
								$and: [
									{
										"statement.actor.account.name": {
											$in: [String(this.accountService.team[0].id)]
										}
									},
									{
										"statement.verb.id": {
											$in: ["https://xapi.ose/verbs/initialized", "https://xapi.ose/verbs/completed"]
										}
									}
								]
							}
						},
						{
							$project: {
								session_id: "$statement.object.definition.type",
								id: {
									$cond: [
										{
											$and: [
												{
													$eq: ["$statement.object.definition.type", "https://xapi.ose/objectType/journey"]
												}
											]
										},
										"$statement.context.extensions.https://xapi&46;ose/extensions/parcours_id",
										"$statement.context.extensions.https://xapi&46;ose/extensions/id"
									]
								},
								verb: "$statement.verb.id",
								isJourney: {
									$cond: [
										{
											$and: [
												{
													$eq: ["$statement.object.definition.type", "https://xapi.ose/objectType/journey"]
												}
											]
										},
										true,
										false
									]
								}
							}
						},

						{
							$group: {
								_id: {
									id: "$id",
									session_id: "$session_id",
									status: "$verb",
									isJourney: "$isJourney"
								}
							}
						},
						{
							$group: {
								_id: {
									id: "$_id.id",
									status: "$_id.status",
									isJourney: "$_id.isJourney"
								}
							}
						},
						{
							$sort: {
								timestamp: -1
							}
						}
					];

					this.lrs
						.request(statement)
						.then((completedItems: any) => {
							const journeyItems = new Array();
							const exerciseItems = new Array();
							completedItems.forEach(currJ => {
								if (currJ._id?.id) {
									currJ._id.id = Number(currJ._id.id);
									if (currJ._id.isJourney) {
										// filter by completed or initialized by journeys
										journeyItems.push(currJ._id);
									} else {
										// filter by completed or initialized by exercises
										exerciseItems.push(currJ._id);
									}
								}
							});

							// filter duplicates values journeys avoid mongoDB's processing time
							this.oseJourneys.journeysStatus = journeyItems.filter((value, index, self) => {
								return index === self.findIndex(t => t.id === value.id && t.status === value.status);
							});
							// filter duplicates values journeys avoid mongoDB's processing time
							this.oseJourneys.exercisesStatus = exerciseItems.filter((value, index, self) => {
								return index === self.findIndex(t => t.id === value.id && t.status === value.status);
							});

							this.getStatsBasedOnProgression();
							resolve();
						})
						.catch(err => {
							reject(true);
						});
				}
			}
		});
	}

	/**
	 * Segregate journeys and exercises  statuses(initialized/completed) statements in different arrays
	 */
	private getStatsBasedOnProgression() {
		/** JOURNEYS */
		this.oseJourneys.currentCompletedJourneys = this.oseJourneys.journeysStatus.filter(journey => {
			return journey.status === XapiVerbs.get(LrsVerbs.completed).id;
		});

		this.oseJourneys.onGoingJourneys = this.oseJourneys.journeysStatus.filter(alLStatuses => {
			return !this.oseJourneys.currentCompletedJourneys.some(completedJourney => {
				return alLStatuses.id === completedJourney.id;
			});
		});

		/** EXERCISES */
		this.oseJourneys.completedExercises = this.oseJourneys.exercisesStatus.filter(journey => {
			return journey.status === XapiVerbs.get(LrsVerbs.completed).id;
		});

		this.oseJourneys.onGoingExercises = this.oseJourneys.exercisesStatus.filter(alLStatuses => {
			return !this.oseJourneys.completedExercises.some(completedJourney => {
				return alLStatuses.id === completedJourney.id;
			});
		});
	}

	public completedExercises() {
		return new Promise<void>((resolve, reject) => {
			if (this.oseJourneys.completedExercises) {
				resolve();
			} else {
				if (!this.accountService.team[0]) {
					this.oseJourneys.completedExercises = null;
					resolve();
				} else {
					const statement = [
						{
							$match: {
								timestamp: {
									$gte: {
										$dte: "2022-09-02T08:09:00.000Z"
									}
								}
							}
						},
						{
							$match: {
								$and: [
									{
										"statement.actor.account.name": {
											$in: [String(this.accountService.team[0].id)]
										}
									},
									{
										"statement.verb.id": {
											$in: ["https://xapi.ose/verbs/completed"]
										}
									},
									{
										"statement.object.definition.type": {
											$in: ["https://xapi.ose/objectType/exercise"]
										}
									}
								]
							}
						},
						{
							$project: {
								id: "$statement.context.extensions.https://xapi&46;ose/extensions/id",
								completedSequence: "$statement.context.extensions.https://xapi&46;ose/extensions/sequence_termine"
							}
						},
						{
							$group: {
								_id: "$id",
								completedSequence: {
									$first: "$completedSequence"
								}
							}
						},
						{
							$sort: {
								timestamp: -1
							}
						}
					];

					this.lrs
						.request(statement)
						.then((completedJourneys: any) => {
							completedJourneys.forEach((currJ, index) => {
								completedJourneys[index].id = Number(completedJourneys[index]._id);
								currJ.completedSequence = !currJ.completedSequence ? false : true;
								delete completedJourneys[index]._id;
							});
							this.oseJourneys.completedExercises = completedJourneys;
							resolve();
						})
						.catch(err => {
							reject(true);
						});
				}
			}
		});
	}

	public sequencesProgression() {
		return new Promise<void>((resolve, reject) => {
			const statement = [
				{
					$match: {
						timestamp: {
							$gte: {
								$dte: "2022-09-02T08:09:00.000Z"
							}
						}
					}
				},
				{
					$match: {
						$and: [
							{
								"statement.actor.account.name": {
									$in: [String(this.accountService.team[0].id)]
								}
							},
							{
								"statement.context.extensions.https://xapi&46;ose/extensions/parcours_id": {
									$nin: [null]
								}
							},
							{
								"statement.verb.id": {
									$nin: ["https://xapi.ose/verbs/finished"]
								}
							}
						]
					}
				},
				{
					$project: {
						parcoursSessionId: "$statement.context.extensions.https://xapi&46;ose/extensions/parcours_session_id",
						parcoursId: "$statement.context.extensions.https://xapi&46;ose/extensions/parcours_id",
						exerciseSessionId: "$statement.context.extensions.https://xapi&46;ose/extensions/id_session",
						exId: "$statement.context.extensions.https://xapi&46;ose/extensions/id",
						timestamp:"$statement.timestamp",
						type: "$statement.context.extensions.https://xapi&46;ose/extensions/nom_activite",
						isCompleted: {
							$cond: [{ $eq: ["$statement.verb.id", "https://xapi.ose/verbs/completed"] }, 1, 0]
						}
					}
				},
				{
					$group: {
						_id: "$parcoursSessionId",
						parcoursId: { $first: "$parcoursId" },
						exercises_progressions: {
							$push: {
								$cond: {
									if: {
										$eq: ["$isCompleted", 1]
									},
									then: {id:"$exId", completed:true,type:"$type",timestamp:"$timestamp"},
									else: {id:"$exId", completed:false,type:"$type",timestamp:"$timestamp"}
								},
							}
						}
					}
				},
				{
					$sort: {
						_id: -1
					}
				}
			];

			this.lrs
				.request(statement)
				.then((allSequencies: any) => {
					// remove duplicate values. Values are already returned by storage date (newest to oldest)
					this.oseJourneys.sequencesTraces = allSequencies.filter((ex, index, self) => {
						return (
							index ===
							self.findIndex(t => {
								return t.parcoursId === ex.parcoursId;
							})
						);
					})

					this.oseJourneys.sequencesTraces.forEach((currSeq) => {
						currSeq.exercises_progressions = currSeq.exercises_progressions.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
					})
					resolve();
				})
				.catch(err => {
					console.error("errerr", err);
					reject(true);
				});
		});
	}
}
