import { Actor } from "./lrs/actores";
import { XapiObject } from "./lrs/xapiobject";
import { XapiVerbs } from "./lrs/xapiverbs";
import { XapiContext } from "./lrs/xapicontext";
import { User } from "./user";
import { XApiResult } from "./lrs/xapiresult";
import { LrsUtils } from "./lrs/lrsUtils";
import { Student } from "./student";

export enum ObjectType {
	video = 0
}

export class Statement {
	// public actor: Actor | Actor[];
	static URL_MATHIA = "https://xapi.mathia.education";
	static URL_OSE = "https://xapi.ose";
	public actor: Actor | Actor[];
	public userId: string;
	public object: XapiObject;
	public verb: XapiVerbs;
	public result: XApiResult;
	public context: XapiContext;
	public timestamp;
	constructor(userId: string, verb: XapiVerbs, object: XapiObject, context: XapiContext = null, result = null, timestamp = null) {
		this.userId = userId;
		this.verb = verb;
		this.object = object;
		this.result = result;
		this.timestamp = timestamp;
		if (context) {
			this.context = context;
		}
	}

	/**
	 * set object
	 */
	public setObject(object: XapiObject) {
		this.object = object;
	}

	/**
	 * set actor
	 */
	public setVerb(verb: XapiVerbs) {
		this.verb = verb;
	}
	getJson() {
		if (this.userId) {
			return {
				actor: {
					account: {
						homePage: LrsUtils.statementUrl,
						name: String(this.userId)
					}
				},
				timestamp: !this.timestamp ? undefined : this.timestamp,
				verb: XapiVerbs.get(this.verb),
				object: this.object,
				result: !this.result ? undefined : this.result,
				context: this.context
			};
		}
	}
}
