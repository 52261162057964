import { Component, Input, OnInit } from "@angular/core";
import { customRocketModalAnimation } from "src/app/models/custom-angular-animations";
import { GlobalService } from "src/app/services/global.service";

@Component({
	selector: "app-custom-progressbar",
	templateUrl: "./custom-progressbar.component.html",
	styleUrls: ["./custom-progressbar.component.scss"],
	animations: [customRocketModalAnimation]
})
export class CustomProgressbarComponent implements OnInit {
	@Input() total;
	@Input() number;
	@Input() mascotVisible = false;
	constructor(public globalService: GlobalService) {}

	get totalAsArray() {
		return new Array(this.total);
	}

	ngOnInit() {}
}
