import { Activity } from "./activity";
import { Journey } from "./journey";
import { Param } from "./params/param";
import { Chapter } from "./chapter";
import { ExerciceChapterHistory } from "./exercice-chapter-history";
import { cloneDeep } from "lodash";
import { Exercise } from "./exercise";
import { Fraction } from "../app-utils";
declare var window: { params: any };

export enum gameMode {
	discovery = "0",
	training = "1",
	challenge = "2",
	assessment = "3"
}

export class CabriActivity extends Activity {
	// public currentExercice: any;

	constructor() {
		super();
	}
	public className = "CabriActivity";
	public file: string;
	public journey?: Journey;
	public variables: any;
	public maxPlayer: number;
	public _params: Array<Param>;
	public slug;
	public shownParamsLength;
	public currentVariables;
	public hasPyramide = false;
	private tempExerciceVariable: [];
	public story: { exo?: ExerciceChapterHistory; chapter?: Chapter; position?: number; bilan?: boolean; journeyId?: string };
	public buildVariables() {
		this.variables = {};
		this._params.forEach(param => {
			if (param.destination === "CabriVariable") {
				this.variables[param.name] = param.value;
			}

			const play = this.getParam("play");

			if (localStorage.getItem("team")) {
				const studentsLength = JSON.parse(localStorage.getItem("team")).length;
				if (studentsLength <= 1) {
					this.maxPlayer = 1;
				} else {
					this.maxPlayer = studentsLength;
				}
			} else {
				this.maxPlayer = 1;
			}
		});

		// reset temp variable each time we build a new gabarit expect to work in all case
		this.tempExerciceVariable = [];
		this.saveActivity();
	}

	saveActivity() {
		let resoParams;
		const resoProblem = this.isResolutionProblem();
		if (resoProblem) {
			resoParams = cloneDeep(this.params);
			this.removeProblemFromParam();
		}
		localStorage.setItem("savedActivity", JSON.stringify(this));
		if (resoProblem) {
			this.restoreProblemFromParam(resoParams);
		}
	}

	/**
	 * Restore resolution probleme
	 */
	restoreProblemFromParam(resoParams) {
		if (resoParams) {
			const paramSelected = this.params.find(param => {
				return param.exercices && !param.value.probleme;
			});
			resoParams.forEach((resoParam: Param) => {
				if (resoParam.exercices) {
					if (paramSelected.value?.hasOwnProperty("probleme") && resoParam.value?.probleme) {
						paramSelected.value.probleme = resoParam.value.probleme;
					}

					if (paramSelected.defaultValue?.hasOwnProperty("probleme")) {
						paramSelected.defaultValue.probleme = resoParam.defaultValue.probleme;
					}
					resoParam.selectionList.forEach((eachResoParam, index) => {
						if (eachResoParam.probleme && paramSelected[index]) {
							paramSelected[index].probleme = eachResoParam.probleme;
						}
					});
				}
			});
		}
	}

	isResolutionProblem() {
		return this.params.some(param => {
			return (
				param.value?.probleme ||
				param.defaultValue?.probleme ||
				param.selectionList?.some(ex => {
					return ex.probleme;
				})
			);
		});
	}

	/**
	 * Remove problem from param before saving into localstorage
	 */
	removeProblemFromParam() {
		this.params.forEach((param: Param) => {
			if (param.exercices) {
				param.selectionList.forEach(ex => {
					if (ex.probleme) {
						ex.probleme = null;
					}
				});

				if (param.value?.probleme) {
					param.value.probleme = null;
				}
				if (param.defaultValue?.probleme) {
					param.defaultValue.probleme = null;
				}
			}
		});
	}

	getParam(name: string) {
		return this._params.find(param => param.name === name);
	}
	setParamValue(name: string, value: any) {
		let defaultValue;
		this._params.forEach(param => {
			if (param.name === name) {
				param.value = value;
				if (!param.exercices) {
					defaultValue = param.selectionList.find(selectionList => {
						return selectionList[1] === value;
					});

					if (defaultValue) {
						param.defaultValue = defaultValue;
					}
				} else {
					defaultValue = param.selectionList.find(ex => {
						return Number(ex.id) === Number(value.id);
					});
				}

				if (defaultValue) {
					param.defaultValue = defaultValue;
				}
			}
		});
	}

	paramHidden(name: string, items) {
		this._params.forEach(param => {
			if (param.selectionList && param.selectionList.length > 0) {
				if (param.name === name) {
					Object.entries(items).forEach((currentItem: any) => {
						//  [0] = param name to select ... [1] = hide or not
						if (currentItem[0]) {
							const value = param.selectionList.find(selectionList => {
								return selectionList[1] === currentItem[0];
							});

							if (value && typeof currentItem[1] === "boolean") {
								value[3] = currentItem[1];
							}
						}
					});
				}
			}
		});
	}

	resetExerciceVariable() {
		this.tempExerciceVariable = [];
	}

	/**
	 * Calculate variables for exercice
	 */
	// allowed letters for eval("operation")
	// tslint:disable: prefer-const (necessary for eval)
	updateVariables(currentExercice: Exercise) {
		let variables: any;
		variables = new Array();
		variables.operation = currentExercice.operation;
		variables.result_mode = currentExercice.resultMode;
		const activityVariables = JSON.parse(currentExercice.variables);

		// console.error(activityVariables);
		// allowed letters for eval("operation")
		// tslint:disable: prefer-const (necessary for eval)
		let k: number, i: number, j: number, m: number, x: number, y: number, z: number;
		let kk: number, ii: number, jj: number, mm: number, xx: number, yy: number, zz: number;
		let t: number, u: number, v: number, w: number;
		let tt: number, uu: number, vv: number, ww: number;

		// results
		let nb1: number, nb2: number, nb3: number, nb4: number, nb5: number, nb6: number, nb7: number, nb8: number, pas: number;
		let numerator: number, denominator: number;
		let maxQuestions: number;
		let activity: number,
			noRoadHelp: number,
			chooseExactNumber: number,
			difficulty: number,
			operation: number,
			nbMax: number,
			nbMin: number;

		function evalUnit(variable, value) {
			let result;
			if (value.indexOf("frac:") > -1) {
				const tmp = value.replace("frac:", "").split("|");
				return new Fraction(evalUnit("numerator", tmp[0]), evalUnit("denominator", tmp[1]));
			} else {
				if (value.indexOf("<") > 0) {
					const tmp = value.split("<");
					let min;
					let max;
					if (
						Number(currentExercice.gabarit) === 10 &&
						(variable === "nb1" || variable === "nb2") &&
						String(tmp[0]).trim() === "0"
					) {
						// nb1 and nb2 can't be equal to 0 in resolution de problèmes
						tmp[0] = "1";
					}
					eval("min = " + tmp[0]);
					eval("max = " + tmp[2]);
					eval(
						" " + variable + " = " + (Number(min) + 1) + " + Math.floor(Math.random()*" + (Number(max) - Number(min) - 1) + ")"
					);
				} else {
					eval(" " + variable + " = " + value);
				}
				// assign variable value

				eval("result = " + variable);
			}
			return result;
		}

		function evalArray(tempExerciceVariable: { array: any[]; frozen: string }[], variable, value) {
			if (!tempExerciceVariable[variable]) {
				tempExerciceVariable[variable] = { array: [], frozen: null };
			}
			let result = null;
			if (tempExerciceVariable[variable].array.length === 0) {
				tempExerciceVariable[variable].array = [...value];
			}
			result = evalUnit(variable, tempExerciceVariable[variable].array.shift());
			return result;
		}

		function evalFrozenVariable(tempExerciceVariable: { array: any[]; frozen: string }[], variable, value) {
			if (!tempExerciceVariable[variable]) {
				tempExerciceVariable[variable] = { array: [], frozen: null };
			}
			if (tempExerciceVariable[variable].frozen) {
				eval(" " + variable + " = " + tempExerciceVariable[variable].frozen);
			} else {
				tempExerciceVariable[variable].frozen = evalUnit(variable, value);
			}
			return tempExerciceVariable[variable].frozen;
		}

		for (const variable in activityVariables) {
			if (activityVariables.hasOwnProperty(variable)) {
				try {
					if (Array.isArray(activityVariables[variable])) {
						variables[variable] = evalArray(this.tempExerciceVariable, variable, activityVariables[variable]);
					} else if (activityVariables[variable].startsWith("$")) {
						variables[variable] = evalFrozenVariable(this.tempExerciceVariable, variable, activityVariables[variable].slice(1));
					} else {
						variables[variable] = evalUnit(variable, activityVariables[variable]);
					}
				} catch (e) {
					variables[variable] = activityVariables[variable];
				}
			}
		}
		this.variables = Object.assign(this.variables, variables);
		this.currentVariables = variables;
		return variables;
	}
	// tslint:enable: prefer-const (necessary for eval)

	restoreActivity(savedActivity) {
		if (!localStorage.getItem("currentUserJourney")) {
			const cursorMod = savedActivity._params.find(param => {
				return param.name === "v-cursor-mod";
			});
			// lors du raffraîchissement changer le mode bilan par entraînement
			if (cursorMod && cursorMod.value === "3") {
				cursorMod.value = "1";
			}
		}
		this._params = savedActivity._params;
		this.routingPath = savedActivity.routingPath;
		this.name = savedActivity.name;
		this.id = savedActivity.id;
		this.className = savedActivity.className;
		this.file = savedActivity.file;
		this.variables = savedActivity.variables;
		this.maxPlayer = savedActivity.maxPlayers;
		this.slug = savedActivity.slug;
		this.shownParamsLength = savedActivity.shownParamsLength;
		this.currentVariables = savedActivity.currentVariables;
		this.description = savedActivity.description;
		this.thumbnail = savedActivity.thumbnail;
		this.customThumbnail = savedActivity.customThumbnail;
		this.hasExercice = savedActivity.hasExercice;
		this.selected = savedActivity.selected;
	}

	get params() {
		return this._params;
	}

	set params(value) {
		this._params = value;

		this.shownParamsLength = 0;
		if (Array.isArray(this._params)) {
			this._params.forEach(param => {
				if (!param.hidden) {
					this.shownParamsLength++;
				}
			});
		}
	}

	public get exercicesParam() {
		return this.params.find(param => param.exercices === true);
	}
}
