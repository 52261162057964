import { EventEmitter, Inject, Injectable, LOCALE_ID, OnDestroy, Type } from "@angular/core";
import { PlayTTSService } from "./play-tts.service";
import { Platform, AlertController, AlertButton } from "@ionic/angular";
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { shareReplay, tap } from "rxjs/operators";
import { NavigationEnd, NavigationStart, Router, RouterEvent } from "@angular/router";
import { LayersModel, loadLayersModel } from "@tensorflow/tfjs-layers";
import * as tf from "@tensorflow/tfjs-core";
import { AppUtils } from "../app-utils";
import { NetworkService } from "./network.service";
import { SpeechBubbleComponent } from "../components/speech-bubble/speech-bubble.component";
import { ClassService } from "./class.service";
import { ConfettiCanvasComponent } from "../components/confetti-canvas/confetti-canvas.component";
import { Location } from "@angular/common";
import { AnalyticService } from "./analytic.service";
import { AppLanguage } from "../models/enums/enum-list";
import { ScreenOrientationIOS } from "../models/screen-orientation-ios";
import { SemVer } from "semver";
// declare var document: any;

declare var window: {
	innerHeight;
	innerWidth;
	outerHeight;
	outerWidth;
	document: any;
	addEventListener: any;
	removeEventListener: any;
	location: any;
};

export enum MenuEvents {
	Voice,
	Volume,
	Burger,
	User,
	Holo
}

export enum OperatingSystem {
	Windows = "Windows",
	MacOS = "MacOS",
	UNIX = "UNIX",
	Linux = "Linux"
}

@Injectable({
	providedIn: "root"
})
export class GlobalService extends BaseService implements OnDestroy {
	public onActivityPage = false;
	public clickClassName = "handlerButtonsClicked";
	public fastBilanFinished: boolean;

	// modal gabarits
	public exerciseStatisticsOpened = true;
	public launchChoiceStoryParamsSelection: boolean;

	// Kidaia alert
	public fullPageConfirmationAlert = false;

	// Settings menu
	private _blueLightFilter = false;
	public blueLightFilterPlayTTS = false;
	public _lowPerformanceMode = false;
	public lowPerformanceModePlayTTS = false;

	public vTimerCabri = 10;

	// TTS protection related event:
	public menuOpenEvent: Subject<{ open: boolean; target: MenuEvents; redirecting?: boolean }>;

	public windowDocumentHeight: any;
	public windowDocumentWidth: any;
	public canvasholderMarginTopBase: number;
	public toolbarHeight: number;

	// platforms:
	public isIos: boolean;
	public isMobile: boolean;
	public isAndroid: boolean;
	public isTablet: boolean;
	public isDesktop: boolean;
	public isCordova: boolean;
	// ( = is native app / not web)
	public isBrowser: boolean;
	public isSafari: boolean;
	public isKidaia: boolean;
	public isChrome: boolean;
	public isFirefox: boolean;

	// mathia iframe integration
	public inIframe = false;
	public isTrala = false;
	public isAren = false;
	public isBeneylu = false;
	public isMathador = false;

	// orientation:
	public screenOrientationSubscription: Subscription;
	public screenOrientationChangeEvent: Subject<boolean>;
	public isLandscape: boolean;
	public isMobileLandscape: boolean;
	public waitingLandscapeModeSubscription: Subscription;

	// resize:
	public windowResizedEvent: Subject<void>;
	public isFullscreen: boolean;
	public resizeTimer: NodeJS.Timer;
	public resizeEventRunning = false;
	public updateDrawCanvasSizeSubject: Subject<void>;

	public modeProd: boolean;
	public killTTSEP: boolean;

	// mode perf:
	public perfModeEvent: Subject<boolean>;

	// toolbar icons:
	public fakeMicOn: boolean;
	public micCheckRunning: boolean;
	public activateNumpadIcon: boolean;

	// toolbar status
	public runCheckToolbarStatus: Subject<boolean>;
	public devActivateHoloToolbar = false;

	// appIdle
	public appIdleEvent: Subject<boolean>;
	public appIdle: boolean;
	public progressBarStatus: boolean;

	// globalLoading
	public globalLoading = false;
	public globalLoadingEvent: Subject<{ status: boolean; adobeAnimParam?: boolean; initAdobe?: boolean }>;
	public unitsHelpCMValidation: boolean;
	config: any;
	remoteControl: any;
	public loaderSecurityTimer: NodeJS.Timeout;
	public runningAnimFuret: boolean;
	public activityRunningName: string;
	public environment: any;
	adobeAnimPreload: boolean;
	public toolbarMenuOpened: { status: boolean; name: MenuEvents };
	public globalAppMenusSubscription: Subscription;
	documentHeight: number;
	landscapeAlert: any;
	public setDocumentHeightOnFullscreenTimeOut: NodeJS.Timeout;
	public settingsHeight: any;
	public displayVoiceSettingsStatus: boolean;
	public displayVoiceSettingsAnimate = true;
	public leftMenuStatus: boolean;
	// Volume:
	public babylonVolumeBeforeMuteVolume: number;
	public volumeChangeEvent: Subject<{ music: number; sounds: number; fxs: number }>;
	musicVolumeBeforeMute: any;
	muteMusicVolume: boolean;
	public volume = { music: 1, sounds: 1, fxs: 0.5 };
	babylonMusicVolumeChangedTimeOut: NodeJS.Timeout;

	detailledParam;

	public waitingLandscapeMode: boolean;
	public orientationSwitched: boolean;
	public orientation: string;
	public orientationAtStart: string;
	soundsVolumeBeforeMute: number;
	soundsVolumeChangedTimeOut: NodeJS.Timeout;
	muteSounds: boolean;
	platformPauseSub: Subscription;
	platformResumeSub: any;
	appConfigSub: Subscription;
	_dyslexicMode: boolean;
	onActivityParticipantsPage: boolean;
	studentsMenuStatus: boolean;
	onAccueilPage: boolean;
	public babylonEngineScalingValue = 1;
	devicePixelRatioForEngineScaling: number;

	waitLandscapeModeNativeTimeOut: NodeJS.Timeout;

	// settings story
	public storySettingOpened = false;
	public storySettingChoiceMade = false;
	public menuStorySettingOpenedEvent = new ReplaySubject(1);
	public firstLaunchStoryActivity: boolean;

	allowedUrl: any;
	hSL: number;
	windowDocumentHeightOnMenuOpen: any;
	windowDocumentWidthOnMenuOpen: any;

	// OCR ardoise magique
	ocrOfflineUnavailable = false;
	private ocrRetryOnce = true;
	private modelOcrLoaded: ReplaySubject<LayersModel>;
	public bugOCR = false;
	runResizeOnMenuClose: boolean;

	launchStoryActivity = new EventEmitter();
	switchSettingParam = new EventEmitter();
	public showSettingParams: BehaviorSubject<any> = new BehaviorSubject(null);
	allowSettingsCompToDisplay: boolean;
	hideStorySettingCloseIcon: boolean;
	isOse: boolean;
	isPageOse: boolean;
	smallLoaderStatus: boolean;
	public mathiaSpeechRunning: boolean;
	appIdleTimeout: NodeJS.Timeout;
	appIdlePromise: Promise<void>;
	public speechBubbleComponent: SpeechBubbleComponent;
	public confettiCanvasComponent: ConfettiCanvasComponent;
	routerPageChangeSub: Subscription;
	smallHeightScreen: boolean;
	virtualKeyboardOpened: boolean;

	imageViewerURL: string;
	imageViewerOpen = false;
	imageViewerGallery = [];

	userOs: string;
	alertUnlockAudio: HTMLIonAlertElement;
	locationState: any;
	audioUnlocked: boolean;
	isKidaiaLandingPos: boolean;
	generalParamsValues = {
		holo: { name: null, value: null },
		inputMethod: { name: null, value: null },
		remoteHost: { name: null, value: null }
	};
	fxsVolumeChangedTimeOut: NodeJS.Timeout;
	fxsVolumeBeforeMute: number;
	muteFxs: boolean;

	public urlHistory: Array<string>;
	webGl1: boolean;
	progressbarHeight: number;
	isCodeMaisonDefault = false;
	public screenOrientation: ScreenOrientation | ScreenOrientationIOS;
	constructor(
		public playTTSService: PlayTTSService,
		private platform: Platform,
		screenOrientation: ScreenOrientation,
		public http: HttpClient,
		public alertController: AlertController,
		public router: Router,
		@Inject(LOCALE_ID) public locale: string,
		private location: Location,
		public analyticService: AnalyticService,
		public networkService: NetworkService
	) {
		super();

		this.screenOrientation = screenOrientation;

		if (!environment.production) {
			(window as any).globalService = this;
		}
		console.log("Locale ", locale);

		if (!sessionStorage.getItem("askConfigLaterTemp") && localStorage.getItem("askConfigLater")) {
			this.displayVoiceSettingsAnimate = false;
			this.displayVoiceSettingsStatus = true;
		}

		this.setHtmlEnvironmentClass();
		this.perfModeEvent = new Subject<boolean>();
		this.perfModeEvent.subscribe(on => {
			this.setHtmlClass("low-perf-mode", on);
		});

		if (this.dyslexicMode) {
			this.setHtmlClass("dyslexic-mode", true);
		}

		if (environment.kidaia && !environment.ose) {
			if (this.locale === AppLanguage.FR) {
				// on load, check current route
				const path = (window.location.pathname as string).substring(1);
				const currentRoute = this.router.config.find(r => r.path === path) as any;
				if (currentRoute?.isOse === true) {
					this.setPageOse(true);
				} else if (currentRoute?.isOse === false) {
					this.setPageOse(false);
				} else {
					const pageOse = localStorage.getItem("page-ose") !== "false";
					this.setPageOse(pageOse);
				}
			}
		}

		this.platform.ready().then(() => {
			if (this.platform.is("cordova") && this.platform.is("ios")) { // && this.iosMinVersion("16.4")
				this.screenOrientation = new ScreenOrientationIOS();
			}
			this.allowSettingsCompToDisplay = true;
			this.blueLightFilterGetStorageValue();
			this.lowPerformanceModeGetStorageValue();
			this.perfModeEvent.next(this.lowPerformanceMode);
			this.soundsVolumeGetStorageValue();
			this.babylonMusicVolumeGetStorageValue();
			this.fxsVolumeGetStorageValue();
			this.initPlatformAndOrientationVariables();
			this.initAppFontFamilyCssVar();
			this.appIdleListenerSubscription();
			this.preloadImages();

			this.setHtmlClass("wait-plt-ready", false);
			this.setHtmlClass("plt-is-ready", true);
			this.analyticService.sendAnalytics("Device", this.reportDeviceInformationAnalytic());
		});
		//(window as any).globalService = this;
		this.isFullscreen = false;
		this.recoverStoryParams();
		this.toolbarMenuOpened = { status: false, name: null };
		this.checkToolbarStatus();
		this.menuOpenEvent = new Subject<{ open: boolean; target: MenuEvents }>();
		this.initGlobalAppMenusSubscription();
		this.windowResizedEvent = new Subject<void>();
		this.volumeChangeEvent = new Subject<{ music: number; sounds: number; fxs: number }>();
		this.updateDrawCanvasSizeSubject = new Subject<void>();
		this.globalLoadingEvent = new Subject<{ status: boolean; adobeAnimParam?: boolean; initAdobe?: boolean }>();
		this.runCheckToolbarStatus = new Subject<boolean>();
		this.appIdleEvent = new Subject<boolean>();
		this.screenOrientationChangeEvent = new Subject<boolean>();
		window.addEventListener("resize", event => {
			this.sendResizeEventAfterTimeOut();
		});
		this.modeProd = environment.production;
		this.isKidaia = environment.kidaia;
		this.isOse = environment.ose;
		this.environment = environment;
		this.subscribeToAppConfig();
		this.initRouterChangeSub();
		this.allowedUrl = window.document.URL.startsWith("https://preprod.app.mathia.education/");

		this.urlHistory = new Array();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.urlHistory.push(event.urlAfterRedirects);
			}
		});
	}

	iosMinVersion(v: string) {
		let regex = /^(?:(?!chrome|android).)*(?:Version\/([\d|.]*)).*safari/i;
		let m: RegExpExecArray;
		m = regex.exec(navigator.userAgent);
		if(m === null){
			regex = /^(?:(?!chrome|android).)*(?:AppleWebKit\/([\d|.]*)).*/i;
			m = regex.exec(navigator.userAgent);
		}
		if (m !== null) {
			if (m[1]) {
				let version: SemVer = new SemVer(m[1]);
				return version.compare(v) >= 0;
			}
		}
	}
	/**
	 * to close ose global bubble if page change
	 */
	initRouterChangeSub() {
		this.unsubscribeRouterChangeSub();
		this.routerPageChangeSub = this.router.events.subscribe((event: RouterEvent) => {
			if (this.speechBubbleComponent?.displayBubble && event instanceof NavigationStart) {
				this.speechBubbleComponent.displayGlobalBubble(false);
			}
		});
	}
	unsubscribeRouterChangeSub() {
		try {
			this.routerPageChangeSub?.unsubscribe();
		} catch (err) {}
		this.routerPageChangeSub = null;
	}

	/**
	 * Add "env-kidaia", "env-mathia" or "env-kidaia-ose" css class to html tag element.
	 */
	setHtmlEnvironmentClass() {
		let envClass = "env-";
		envClass += environment.kidaia ? "kidaia" : "mathia";
		envClass += environment.ose ? "-ose" : "";
		window.document.querySelector("html").classList.add(envClass);
	}

	setHtmlClass(className: string, add: boolean) {
		if (add) {
			window.document.querySelector("html").classList.add(className);
		} else {
			window.document.querySelector("html").classList.remove(className);
		}
	}

	setPageOse(value = true) {
		if (this.locale === AppLanguage.FR) {
			this.isPageOse = value;
			this.setHtmlClass("page-ose", value);
			this.setHtmlClass("page-kidaia", !value);
			localStorage.setItem("page-ose", String(value));
		}
	}

	preloadImages() {
		const preloaderElement = window.document.querySelector("#image-preloader");
		const div = window.document.createElement("div");
		div.classList.add("backgroundSettings");
		const div2 = window.document.createElement("div");
		div.classList.add("backgroundSettings");
	}

	reloadNavigateAndroid(page?: string) {
		const urlWithParams = new URL("https://localhost/");
		urlWithParams.searchParams.append("navigate", page ? page : this.router.url);
		navigator["app"].loadUrl(urlWithParams.href);
	}

	recoverStoryParams() {
		const generalParamsValues = sessionStorage.getItem("generalParamsValues");
		if (generalParamsValues) {
			this.generalParamsValues = JSON.parse(sessionStorage.getItem("generalParamsValues"));
			this.storySettingChoiceMade = true;
		}
	}

	subscribeToAppConfig() {
		this.unsubscribeToAppConfig();
		this.appConfigSub = this.getAppConfig().subscribe(config => {
			console.log("App config", config);
		});
	}

	unsubscribeToAppConfig() {
		if (this.appConfigSub) {
			this.appConfigSub.unsubscribe();
			this.appConfigSub = null;
		}
	}

	/**
	 * Return app config from serveur
	 * this request have a sharedreplay and execute the request only one time
	 * @returns any
	 */
	getAppConfig(): Observable<any> {
		return this.http.get<any>((this.environment.kidaia ? this.postApiKidaia : this.postUrl) + `?action=app_mathia_get_config`).pipe(
			tap(config => (this.config = config)),
			shareReplay()
		);
	}

	/**
	 * Subscribe to opening/closing events of app menus (menu status = v.open, menu name = v.target)
	 */
	initGlobalAppMenusSubscription() {
		this.unsubscribeGlobalAppMenusSubscription();
		this.globalAppMenusSubscription = this.menuOpenEvent.subscribe({
			next: v => {
				this.toolbarMenuOpened.status = v.open;
				if (v.open) {
					this.windowDocumentHeightOnMenuOpen = window.innerHeight;
					this.windowDocumentWidthOnMenuOpen = window.innerWidth;
					this.toolbarMenuOpened.name = v.target;
				} else {
					this.toolbarMenuOpened.name = null;
				}
			}
		});
	}
	/**
	 * App Menu Open/Close Events Unsubscription
	 */
	unsubscribeGlobalAppMenusSubscription() {
		if (this.globalAppMenusSubscription) {
			this.globalAppMenusSubscription.unsubscribe();
		}
	}

	/**
	 * => lance un check pour afficher ou pas la progressbar dans la toolbar, et update les variables css en fonction
	 * => besoin de le faire manuellement dans certains cas pour le forcer (retour sur une page déjà naviguée et le check auto ne se fait pas)
	 */
	checkToolbarStatus(progressBar = null) {
		if (this.runCheckToolbarStatus) {
			this.runCheckToolbarStatus.next(progressBar);
		}
	}

	/**
	 * Colorize html element on click
	 * 3 modes:
	 * 	-> Timeout Mode = adds css colorization class and removes it at the end of the timeout
	 * 	-> Add Only Mode = only adds css colorization class
	 * 	-> Remove Only Mode = only removes css colorization class
	 */
	async waitButtonClick(nodeElements: any, timeout: any = 100) {
		return new Promise((resolve, reject) => {
			if(nodeElements?.buttonClicked){
				// Timeout Mode Add Class:
				if (timeout !== false) {
					Object.entries(nodeElements).forEach((currentNode: any) => {
						if (typeof currentNode[1] === "string") {
							const node = document.querySelector("." + currentNode[1]) as any;
							if (node) {
								node.classList.add(currentNode[0]);
								node.style.pointerEvents = "none";
							}
						} else if (currentNode[1] instanceof HTMLElement) {
							currentNode[1].classList.add(currentNode[0]);
							currentNode[1].style.pointerEvents = "none";
						}
					});
				}
				if (timeout === true) {
					// Add Only Mode:
					resolve(true);
				} else if (timeout === false) {
					// Remove Only Mode:
					Object.keys(nodeElements).forEach(currentNode => {
						const node = document.querySelector(`.${currentNode}`) as any;
						if (node) {
							node.classList.remove(currentNode);
							node.style.pointerEvents = "auto";
						}
						resolve(true);
					});
				} else {
					// Timeout Mode Remove Class :
					setTimeout(() => {
						Object.keys(nodeElements).forEach(currentNode => {
							const node = document.querySelector(`.${currentNode}`) as any;
							if (node) {
								node.classList.remove(currentNode);
								node.style.pointerEvents = "auto";
							}
							resolve(true);
						});
					}, timeout);
				}
			}else{
				resolve(true);
			}
		});
	}

	// detect orientation changes
	initObserveScreenOrientationSub() {
		this.screenOrientationSubscription = this.screenOrientation.onChange().subscribe(() => {
			let normalTimeout = 500;
			if (this.isIos) {
				normalTimeout = 900;
			}
			// TODO: remove timeout? -> do tests
			setTimeout(async () => {
				if (!this.isCordova && !(this.isTrala || this.isAren || this.isBeneylu)) {
					this.screenOrientationChangeEvent.next(
						(this.isLandscape ||
							this.screenOrientation.type === "landscape-primary" ||
							this.screenOrientation.type === "landscape-secondary" ||
							this.screenOrientation.type === "landscape") &&
							this.screenOrientation.type !== undefined
					);
				}
			}, normalTimeout);
		});
	}

	killObserveScreenOrientationSub() {
		if (this.screenOrientationSubscription) {
			this.screenOrientationSubscription.unsubscribe();
			this.screenOrientationSubscription = null;
		}
	}

	/**
	 * initialization/detection of important variables used in the app (device type & info, landscape mode) + setDocumentHeightCssVar()
	 */
	async initPlatformAndOrientationVariables() {
		this.detectUserOS();
		if ((this.platform.is("mobile") || this.platform.is("mobileweb")) && !this.platform.is("tablet")) {
			if (this.userOs === OperatingSystem.Windows) {
				// fix for Sophie's is-mobile flag on windows:
				this.isDesktop = true;
			} else {
				this.isMobile = true;
				window.document.querySelector("html").classList.add("is-mobile");
			}
		}
		if (this.platform.is("tablet") && !this.platform.is("desktop")) {
			if (this.userOs === OperatingSystem.Windows) {
				this.isDesktop = true;
			} else {
				this.isTablet = true;
				window.document.querySelector("html").classList.add("is-tablet");
			}
		}
		if (this.platform.is("desktop") || this.userOs === OperatingSystem.Windows) {
			this.isDesktop = true;
			window.document.querySelector("html").classList.add("is-desktop");
		}
		if (this.platform.is("cordova")) {
			this.isCordova = true;
			window.document.querySelector("html").classList.add("is-cordova");
		}
		if (this.platform.is("ios")) {
			this.isIos = true;
			window.document.querySelector("html").classList.add("is-ios");
		}
		if (environment.ose) {
			window.document.querySelector("html").classList.add("is-ose");
		}
		this.isBrowser = (this.platform.is("desktop") || this.platform.is("mobileweb")) && !this.isCordova;
		this.isAndroid = this.platform.is("android");
		this.isChrome = navigator.userAgent.indexOf("Chrome") > -1;
		this.isFirefox = navigator.userAgent.includes("Firefox");
		if (!environment.production) {
			console.log(`Platform = ${this.platform.platforms()}`);
			console.log("global--isTablet = " + this.isTablet);
			console.log("global--isMobile = " + this.isMobile);
			console.log("global--isDesktop = " + this.isDesktop);
			console.log("global--isIos? = " + this.isIos);
			console.log("global--isAndroid : " + this.isAndroid);
			console.log("global--isCordova? = " + this.isCordova);
			console.log("global--isKidaia? = " + this.isKidaia);
			console.log("global--isBrowser? = " + this.isBrowser);
			console.log("global--isSafari? = " + this.isSafari);
			console.log("global--isChrome = " + this.isChrome);
			console.log("global--isFirefox = " + this.isFirefox);
		}
		this.setDocumentHeightCssVar();
		this.checkPlatformLandscapeMode();
	}

	detectUserOS() {
		if (navigator.userAgent.indexOf("Win") != -1) this.userOs = OperatingSystem.Windows;
		if (navigator.userAgent.indexOf("Mac") != -1) this.userOs = OperatingSystem.MacOS;
		if (navigator.userAgent.indexOf("X11") != -1) this.userOs = OperatingSystem.UNIX;
		if (navigator.userAgent.indexOf("Linux") != -1) this.userOs = OperatingSystem.Linux;
		console.log("user Operating system = " + this.userOs);
		console.log("navigator.userAgent = " + navigator.userAgent);
	}

	checkPlatformLandscapeMode() {
		if (this.isCordova && (!this.isSafari || this.isSafari === undefined) && !this.isDesktop && this.screenOrientation) {
			// console.log("this.screenOrientation.type = " + this.screenOrientation.type);
			this.isLandscape =
				(this.screenOrientation.type === "landscape-primary" ||
					this.screenOrientation.type === "landscape-secondary" ||
					this.screenOrientation.type === "landscape") &&
				this.screenOrientation.type !== undefined;
			this.isMobileLandscape = this.isMobile && this.isLandscape;
			// console.log("checkPlatformLandscapeMode 1st cond -- isLandscape? = " + this.isLandscape);
			// console.log("checkPlatformLandscapeMode 1st cond -- isMobileLandscape? = " + this.isMobileLandscape);
		} else {
			if (window.innerHeight <= window.innerWidth) {
				this.isLandscape = true;
			} else {
				this.isLandscape = false;
			}
			this.isMobileLandscape = this.isMobile && this.isLandscape;
			// console.log("checkPlatformLandscapeMode 2nd cond -- isLandscape? = " + this.isLandscape);
			// console.log("checkPlatformLandscapeMode 2nd cond -- isMobileLandscape? = " + this.isMobileLandscape);
		}
		this.setHtmlClass("is-landscape", this.isLandscape);
		this.setHtmlClass("is-portrait", !this.isLandscape);
	}

	/**
	 * Triggers an Alert to choose between Direct Start of Activity
	 * or Start with tutorial (also permits to be sure to start the audio context thanks to user's action)
	 */
	async waitLandscapeMode(loading: boolean = null, force?: boolean): Promise<void> {
		try {
			if (!this.waitingLandscapeMode || force) {
				this.checkPlatformLandscapeMode();
				if (this.isCordova) {
					return new Promise(async (resolve, reject) => {
						await this.platform.ready().then(async () => {
							if (this.isCordova) {
								if (this.waitLandscapeModeNativeTimeOut) {
									clearTimeout(this.waitLandscapeModeNativeTimeOut);
								}
								await this.forceNativeLandscapeMode();
								this.waitLandscapeModeNativeTimeOut = setTimeout(async () => {
									clearTimeout(this.waitLandscapeModeNativeTimeOut);
									resolve();
								}, 500);
							}
						});
					});
				}
				if (!this.isLandscape && !this.isCordova && (this.isMobile || this.isTablet)) {
					// waitingLandscapeMode
					this.waitingLandscapeMode = true;
					if (this.landscapeAlert) {
						this.landscapeAlert.dismiss();
						this.landscapeAlert = null;
					}
					this.landscapeAlert = await this.alertController.create({
						cssClass: "waitLandscapeModeAlert",
						backdropDismiss: false,
						mode: "ios",
						message: $localize`Merci de passer en mode paysage !`
					});
					this.setGlobalLoading(false);
					await this.landscapeAlert.present();
					return new Promise((resolve, reject) => {
						if (this.waitingLandscapeModeSubscription) {
							this.waitingLandscapeModeSubscription.unsubscribe();
							this.waitingLandscapeModeSubscription = null;
						}
						this.waitingLandscapeModeSubscription = this.screenOrientationChangeEvent.subscribe({
							next: landscape => {
								try {
									if (landscape) {
										if (loading) {
											this.setGlobalLoading(true);
										}
										if (this.landscapeAlert) {
											this.landscapeAlert.dismiss();
											this.landscapeAlert = null;
										}
										this.waitingLandscapeModeSubscription.unsubscribe();
										this.waitingLandscapeMode = false;
										resolve();
									}
								} catch (error) {
									this.setGlobalLoading(false);
									this.waitingLandscapeMode = false;
									console.error("waitLandscapeMode error! ", error);
									throw error;
								}
							}
						});
						// }
					});
				}
			}
		} catch (error) {
			this.setGlobalLoading(false);
			this.waitingLandscapeMode = false;
			console.error("waitLandscapeMode error! ", error);
			throw error;
		}
	}

	forceNativeLandscapeMode(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (this.isCordova) {
				this.orientationSwitched = false;
				this.orientation = this.screenOrientation.type;
				if (this.orientation === "landscape-primary" || this.orientation === "landscape-secondary") {
					await this.screenOrientation.lock(this.orientation);
					this.orientationAtStart = this.orientation;
				} else if (this.orientation === "portrait-primary" || this.orientation === "portrait-secondary") {
					await this.screenOrientation.lock("landscape-primary");
					this.orientation = "landscape-primary";
					this.orientationAtStart = this.orientation;
				} else {
					await this.screenOrientation.lock("landscape-primary");
					this.orientation = "landscape-primary";
				}
				setTimeout(() => {
					this.isLandscape =
						this.screenOrientation.type === "landscape-primary" ||
						this.screenOrientation.type === "landscape-secondary" ||
						this.screenOrientation.type === "landscape";
					this.isMobileLandscape = this.isMobile && this.isLandscape;
					resolve();
				}, 200);
			}
		});
	}

	// Sends an event anywhere in the app after window has been resized (timeout to wait for new document dimensions)
	sendResizeEventAfterTimeOut() {
		this.resizeEventRunning = true;
		// if (!environment.production) {
		// 	console.error("sendResizeEventAfterTimeOut enter / this.resizeEventRunning = true");
		// }
		if (this.resizeTimer) {
			// if (!environment.production) {
			// 	console.error("clearTimeout(this.resizeTimer)");
			// }
			clearTimeout(this.resizeTimer);
		}
		if (
			this.isDesktop &&
			((!this.isOse && this.router.url === "/accueil") || this.router.url === "/map") &&
			!this.displayVoiceSettingsStatus &&
			!this.storySettingOpened
		) {
			this.setGlobalLoading(true);
		}
		// TODO adjust timeout to minimum:
		const duration = this.displayVoiceSettingsStatus || this.storySettingOpened ? 400 : 500;
		this.resizeTimer = setTimeout(async () => {
			console.log(this.appIdlePromise);
			if (this.appIdlePromise) {
				await this.appIdlePromise;
			}
			// if (!environment.production) {
			// 	console.error("sendResizeEventAfterTimeOut timeout 500 passed");
			// }
			// console.log("window resized event");
			this.checkPlatformLandscapeMode();
			this.runResizeOnMenuClose = (this.displayVoiceSettingsStatus || this.storySettingOpened) && this.onActivityPage;
			this.checkToolbarStatus();
			if (this.windowResizedEvent) {
				// if (!environment.production) {
				// 	console.error("sendResizeEventAfterTimeOut => this.windowResizedEvent.next()");
				// }
				this.windowResizedEvent.next();
			}
			this.resizeEventRunning = false;
			this.resizeTimer = null;
			// if (!environment.production) {
			// 	console.error("resizeTimer = resizeEventRunning = null");
			// }
		}, duration);
	}

	// to exp / not very reliable
	checkRam() {
		const memory = (navigator as any).deviceMemory;
		console.error("RAM = ", memory);
		// https://stackoverflow.com/questions/56372705/why-navigator-devicememory-is-not-accurate-for-devices-with-more-than-6gb-of-r
	}

	sendPerfModeSwitchedEvent(v: boolean) {
		if (this.perfModeEvent) {
			this.perfModeEvent.next(v);
		}
	}

	/**
	 * Set the height of the document as global TS + CSS variable to be used everywhere in app's TS & CSS files
	 * + detects if virtual keyboard is open (used on ose login + signup pages / can be used elsewhere in problematic forms on small devices)
	 * + detects if user has a smallHeightScreen on a non mobile device to reduce the height of the toolbar as in mobile use
	 */
	setDocumentHeightCssVar() {
		try {
			this.documentHeight = window.innerHeight.toString();
			document.documentElement.style.setProperty("--documentHeight", this.documentHeight + "px");
			// Virtual keyboard detection tentative but not very well supported yet on all systems (maybe later?):
			// if ("virtualKeyboard" in navigator) {
			// console.error("The VirtualKeyboard API is supported!");
			// navigator.virtualKeyboard.addEventListener("geometrychange", (event) => {
			// 	const { x, y, width, height } = event.target.boundingRect;
			// 	console.error("Virtual keyboard geometry changed:", x, y, width, height);
			//   });
			// }
			// Meanwhile we detect virtual keyboard opening when documentHeight is less than 200px:
			if (this.documentHeight < 200) {
				this.virtualKeyboardOpened = true;
				if (!environment.production) {
					console.error("this.virtualKeyboardOpened = true;");
				}
			}
			if (this.documentHeight < 648) {
				if (this.documentHeight > 200) {
					this.virtualKeyboardOpened = false;
				}
				this.smallHeightScreen = true;
				window.document.querySelector("html").classList.add("smallHeightScreen");
			} else {
				this.virtualKeyboardOpened = false;
				this.smallHeightScreen = false;
				window.document.querySelector("html").classList.remove("smallHeightScreen");
			}
		} catch (error) {
			console.error("setDocumentHeightCssVar error", error);
		}
	}

	/**
	 * Initialize font family CSS variable @ app start depending of dyslexicMode variable from LS if any
	 */
	initAppFontFamilyCssVar() {
		try {
			this.dyslexicModeGetStorageValue();
			this.changeAppFontCssVar(this._dyslexicMode);
		} catch (error) {
			console.error("initFontCssVar error", error);
		}
	}

	/**
	 * Set app font family CSS variable depending of dyslexicMode & environnement
	 */
	changeAppFontCssVar(dyslexic = false) {
		document.documentElement.style.setProperty("--appFontFamily", dyslexic ? "dyslexic-bold" : "Quicksand-Bold");
	}

	set dyslexicMode(value: boolean) {
		if (value === true) {
			this._dyslexicMode = true;
			if (this.displayVoiceSettingsStatus) {
				this.playTTSService.playTTS($localize`le mode dyslexique est activé`, null, false);
			}
			window.document.querySelector("html").classList.add("dyslexic-mode");
		} else {
			this._dyslexicMode = false;
			if (this.displayVoiceSettingsStatus) {
				this.playTTSService.playTTS($localize`le mode dyslexique est désactivé`, null, false);
			}
			window.document.querySelector("html").classList.remove("dyslexic-mode");
		}
		this.changeAppFontCssVar(this._dyslexicMode);
		this.dyslexicModeSetStorageValue(this._dyslexicMode);
	}

	get dyslexicMode(): boolean {
		return this._dyslexicMode;
	}

	dyslexicModeGetStorageValue() {
		if (localStorage.getItem("dyslexicMode")) {
			this._dyslexicMode = JSON.parse(localStorage.getItem("dyslexicMode"));
		} else if (!localStorage.getItem("dyslexicMode")) {
			this._dyslexicMode = false;
		}
	}

	dyslexicModeSetStorageValue(dyslexicMode: boolean) {
		localStorage.setItem("dyslexicMode", String(dyslexicMode));
	}

	/**
	 * Enable/Disable app's loading screen
	 * status 'true' = ON / 'false' = OFF
	 * mode '' = progress
	 * mode 'infinite' = loop loading
	 */
	setGlobalLoading(status: boolean, adobeAnimParam: boolean = null) {
		if (this.globalLoadingEvent) {
			this.globalLoading = status;
			this.globalLoadingEvent.next({ status, adobeAnimParam });
		}
	}
	get smallLoader() {
		return window.document.getElementById("small-loader");
	}
	setSmallLoading(status: boolean) {
		this.smallLoaderStatus = status;
		if (status) {
			this.smallLoader?.classList?.add("on");
		} else {
			this.smallLoader?.classList?.remove("on");
		}
	}

	/**
	 * Send event to update Drawing Component's canvas dimensions with its internal method
	 */
	updateDrawCompCanvasSize() {
		if (this.updateDrawCanvasSizeSubject) {
			this.updateDrawCanvasSizeSubject.next();
		}
	}

	/**
	 * BLUE LIGHT FILTER
	 */
	set blueLightFilter(value: boolean) {
		if (value === true) {
			this._blueLightFilter = true;
			if (this.blueLightFilterPlayTTS === true) {
				this.playTTSService.playTTS($localize`le filtre anti lumière bleue est activé`, null, false);
			}
		} else {
			this._blueLightFilter = false;
			if (this.blueLightFilterPlayTTS === true) {
				this.playTTSService.playTTS($localize`le filtre anti lumière bleue est désactivé`, null, false);
			}
		}
	}
	get blueLightFilter(): boolean {
		return this._blueLightFilter;
	}
	blueLightFilterGetStorageValue() {
		if (localStorage.getItem("blueLightFilterState")) {
			this._blueLightFilter = JSON.parse(localStorage.getItem("blueLightFilterState"));
		} else if (!localStorage.getItem("blueLightFilterState")) {
			this._blueLightFilter = false;
		}
	}
	blueLightFilterSetStorageValue() {
		localStorage.setItem("blueLightFilterState", String(this._blueLightFilter));
	}

	/**
	 * LOW PERFORMANCE MODE:
	 * destroy/reload Adobe Animate canvas in progress bar component accordingly
	 */
	set lowPerformanceMode(value: boolean) {
		if (value === true) {
			this._lowPerformanceMode = true;
			if (this.lowPerformanceModePlayTTS === true) {
				this.playTTSService.playTTS($localize`le mode performance est activé`, null, false);
			}
			this.sendPerfModeSwitchedEvent(true);
		} else {
			this._lowPerformanceMode = false;
			if (this.lowPerformanceModePlayTTS === true) {
				this.playTTSService.playTTS($localize`le mode performance est désactivé`, null, false);
			}
			this.sendPerfModeSwitchedEvent(false);
		}
		this.lowPerformanceModeSetStorageValue();
	}
	get lowPerformanceMode(): boolean {
		return this._lowPerformanceMode;
	}
	lowPerformanceModeGetStorageValue() {
		if (localStorage.getItem("lowPerformanceModeState")) {
			this._lowPerformanceMode = JSON.parse(localStorage.getItem("lowPerformanceModeState"));
		} else if (!localStorage.getItem("lowPerformanceModeState")) {
			this._lowPerformanceMode = false;
		}
	}
	lowPerformanceModeSetStorageValue() {
		localStorage.setItem("lowPerformanceModeState", String(this._lowPerformanceMode));
	}

	/**
	 * Checks if browser window / tab is active or idle for non-native devices to turn off render, stt, tts, sounds etc
	 */
	appIdleListenerSubscription() {
		if (!this.isCordova && this.isBrowser) {
			// WEB APP:
			document.addEventListener("visibilitychange", async () => {
				if (this.appIdleTimeout) {
					clearTimeout(this.appIdleTimeout);
					this.appIdleTimeout = null;
				}
				if (document.hidden) {
					this.appIdleEvent.next(true);
					this.appIdle = true;
				} else {
					if ((this.isMobile || this.isTablet) && !this.isCordova) {
						await AppUtils.timeOut(500);
					}
					this.checkIfBrowserIsFullscreen();
					this.appIdle = false;
					this.appIdleEvent.next(false);
					this.appIdleTimeout = null;
					if (!this.networkService.isConnected) {
						this.networkService.isNeedDisplayToaster();
					}
					// refresh fullscreen icon on resume:
					// const connected = NetworkService.status.getValue() === ConnectionStatus.Online;
					// console.error("connection status on idle = ", connected);
				}
			});
		} else {
			// NATIVE APP:
			this.subscribeToMinimizeResume();
		}
	}

	/**
	 * detects if the browser is fullscreen or not (multi-api)
	 */
	checkIfBrowserIsFullscreen() {
		if (document.fullscreenElement) {
			// console.log("fullscreenElement = ", document.fullscreenElement);
			this.isFullscreen = true;
		} else if (window.document.mozFullScreenElement) {
			// console.log("mozFullScreenElement = ", window.document.mozFullScreenElement);
			this.isFullscreen = true;
		} else if (window.document.msFullscreenElement) {
			// console.log("msFullscreenElement = ", window.document.msFullscreenElement);
			this.isFullscreen = true;
		} else if (window.document.webkitCurrentFullScreenElement) {
			// console.log("webkitCurrentFullScreenElement = ", window.document.webkitCurrentFullScreenElement);
			this.isFullscreen = true;
		} else if (window.document.webkitIsFullScreen) {
			// console.log("webkitIsFullScreen = ", window.document.webkitIsFullScreen);
			this.isFullscreen = true;
		} else {
			// console.log("fullscreen is off");
			this.isFullscreen = false;
		}
	}

	/**
	 * ask the browser to go or exit fullscreen depending of the isFullscreen variable (multi-api)
	 * TODO => find a way to go fullscreen on iOS
	 */
	toggleBrowserFullscreen() {
		this.checkIfBrowserIsFullscreen();
		if (this.setDocumentHeightOnFullscreenTimeOut) {
			clearTimeout(this.setDocumentHeightOnFullscreenTimeOut);
			this.setDocumentHeightOnFullscreenTimeOut = null;
		}
		this.setDocumentHeightOnFullscreenTimeOut = setTimeout(() => {
			this.setDocumentHeightCssVar();
			this.setDocumentHeightOnFullscreenTimeOut = null;
		}, 1500);
		if (!this.isFullscreen) {
			if (document.documentElement.requestFullscreen) {
				// BASE API
				console.log("BASE API FULLSCREEN");
				document.documentElement
					.requestFullscreen()
					.then(() => {
						console.log("requestFullscreen() OK");
						this.isFullscreen = true;
					})
					.catch(error => {
						console.error("requestFullscreen() error = ", error);
						// this.isFullscreen = false;
					});
			} else if (window.document.webkitEnterFullscreen) {
				console.log("FULLSCREEN - MacOs desktop / iPad NEW API VERSION - Chrome, Safari and Opera");
				window.document.webkitEnterFullscreen();
				this.isFullscreen = true;
				//( https://developer.apple.com/documentation/webkitjs/htmlvideoelement/1633500-webkitenterfullscreen )
			} else if (window.document.mozRequestFullScreen) {
				console.log("FULLSCREEN - Firefox legacy API");
				window.document.mozRequestFullScreen();
				this.isFullscreen = true;
			} else if (window.document.webkitRequestFullscreen) {
				console.log("FULLSCREEN - Chrome, Safari, Opera deprecated API");
				window.document.webkitRequestFullscreen();
				this.isFullscreen = true;
			} else if (window.document.msRequestFullscreen) {
				console.log("FULLSCREEN - old IE/Edge API");
				window.document.msRequestFullscreen();
				this.isFullscreen = true;
			} else if (window.document.body.webkitRequestFullScreen) {
				console.log("FULLSCREEN - Safari");
				window.document.body.webkitRequestFullScreen();
				this.isFullscreen = true;
			} else {
				console.error("FULLSCREEN IS NOT COMPATIBLE WITH THIS BROWSER");
			}
		} else {
			if (document.exitFullscreen) {
				console.log("EXIT FULLSCREEN - NEW API (new FIREFOX / new CHROME / new EDGE)");
				document
					.exitFullscreen()
					.then(() => {
						console.log("document.exitFullscreen() OK");
					})
					.catch(error => {
						console.error("document.exitFullscreen() error = ", error);
					});
			} else if (window.document.webkitExitFullscreen) {
				console.log("EXIT FULLSCREEN - MacOs desktop / iPad NEW API - Chrome, Safari and Opera");
				window.document.webkitExitFullscreen();
			} else if (window.document.mozCancelFullScreen) {
				console.log("EXIT FULLSCREEN - OLD FIREFOX API");
				window.document.mozCancelFullScreen();
			} else if (window.document.cancelFullScreen) {
				console.log("EXIT FULLSCREEN - OLD FIREFOX API 2");
				window.document.cancelFullScreen();
			} else if (window.document.msExitFullscreen) {
				console.log("EXIT FULLSCREEN - old IE/Edge API");
				window.document.msExitFullscreen();
			} else {
				console.error("No METHOD TO EXIT FULLSCREEN??");
			}
			this.isFullscreen = false;
		}
	}

	/**
	 * Subscription / unsubscription to detect app minimization (idle) on native devices
	 */
	subscribeToMinimizeResume() {
		if (this.isCordova && !this.isBrowser) {
			// Subscribe on pause i.e. background
			if (this.platformPauseSub) {
				this.platformPauseSub.unsubscribe();
				this.platformPauseSub = null;
			}
			this.platformPauseSub = this.platform.pause.subscribe(() => {
				this.appIdleEvent.next(true);
				this.appIdle = true;
			});
			if (this.platformResumeSub) {
				this.platformResumeSub.unsubscribe();
				this.platformResumeSub = null;
			}
			// Subscribe on resume i.e. foreground
			this.platformResumeSub = this.platform.resume.subscribe(() => {
				// const connected = NetworkService.status.getValue() === ConnectionStatus.Online;
				// console.error("connection status on idle = ", connected);
				this.appIdle = false;
				this.appIdleEvent.next(false);
			});
		}
	}
	unsubscribeToMinimizeResume() {
		if (this.platformPauseSub) {
			this.platformPauseSub.unsubscribe();
			this.platformPauseSub = null;
		}
		if (this.platformResumeSub) {
			this.platformResumeSub.unsubscribe();
			this.platformResumeSub = null;
		}
	}

	/**
	 * VOICES VOLUME (TTS / narration)
	 * TODO => changer le nom des variables de "sounds" à "voices" pour plus de clarté
	 * (je n'ose pas faire ça maintenant de peur de casser des trucs et de ne pas être là pour les résoudre)
	 */
	set soundsVolume(value: number) {
		if (this.soundsVolumeChangedTimeOut) {
			clearTimeout(this.soundsVolumeChangedTimeOut);
		}
		this.soundsVolumeChangedTimeOut = setTimeout(() => {
			if (value > 0.0001) {
				this.soundsVolumeBeforeMute = value;
				this.muteSounds = false;
				this.volume.sounds = this.playTTSService.volume = value;
				this.playTTSService.playTTS($localize`le volume de la voix a été changé`, null, false);
			} else if (value === 0.0001) {
				this.volume.sounds = this.playTTSService.volume = 0.0001;
				this.muteSounds = true;
			}
			if (this.volumeChangeEvent) {
				this.volumeChangeEvent.next(this.volume);
			}
		}, 100);
	}
	get soundsVolume(): number {
		return this.volume.sounds;
	}
	/**
	 * To mute the TTS voice (volume = 0.0001 if muted to leave the TTS bubbles displayed) & store status in LS
	 * @param muted
	 */
	toggleMuteSounds(muted) {
		if (muted) {
			this.soundsVolumeBeforeMute = this.soundsVolume;
			this.volume.sounds = this.playTTSService.volume = 0.0001;
			this.muteSounds = true;
			this.playTTSService.killSpeech();
		} else {
			this.volume.sounds =
				this.volume.sounds =
				this.playTTSService.volume =
					this.soundsVolumeBeforeMute && this.soundsVolumeBeforeMute !== 0.0001 ? this.soundsVolumeBeforeMute : 0.6;
			this.muteSounds = false;
		}
		if (this.volumeChangeEvent) {
			this.volumeChangeEvent.next(this.volume);
		}
		this.soundsVolumeSetStorageValue();
	}
	soundsVolumeGetStorageValue() {
		if (localStorage.getItem("soundsVolume")) {
			this.volume.sounds = this.playTTSService.volume = parseFloat(localStorage.getItem("soundsVolume"));
			if (this.volume.sounds === 0.0001) {
				this.muteSounds = true;
			}
		}
		if (!localStorage.getItem("soundsVolume")) {
			localStorage.setItem("soundsVolume", String(this.volume.sounds));
			this.playTTSService.volume = this.volume.sounds;
		}
	}
	soundsVolumeSetStorageValue() {
		localStorage.setItem("soundsVolume", String(this.volume.sounds));
	}

	/**
	 * Music volume (babylon for narration + global for the rest of the app)
	 * TODO => replace "babylonMusic" by "music" in variable names ?
	 */
	set musicVolumeSliderValue(value: number) {
		if (this.babylonMusicVolumeChangedTimeOut) {
			clearTimeout(this.babylonMusicVolumeChangedTimeOut);
		}
		this.babylonMusicVolumeChangedTimeOut = setTimeout(() => {
			value = Math.exp(value) / 2;
			if (value > 0.057) {
				this.musicVolumeBeforeMute = value;
				this.muteMusicVolume = false;
				this.volume.music = Number(value.toFixed(3));
			} else {
				this.volume.music = 0;
				this.muteMusicVolume = true;
			}
			this.babylonMusicVolumeSetStorageValue();
			if (this.volumeChangeEvent) {
				this.volumeChangeEvent.next(this.volume);
			}
		}, 100);
	}
	get musicVolumeSliderValue(): number {
		return Math.log(this.volume.music * 2);
	}
	/**
	 * To mute the music volume & store status in LS
	 * @param muted
	 */
	toggleMuteMusic(muted) {
		if (muted) {
			this.musicVolumeBeforeMute = this.volume.music;
			this.volume.music = 0;
			this.muteMusicVolume = true;
		} else {
			this.volume.music = this.musicVolumeBeforeMute ? this.musicVolumeBeforeMute : 0.3;
			this.muteMusicVolume = false;
		}
		if (this.volumeChangeEvent) {
			this.volumeChangeEvent.next(this.volume);
		}
		this.babylonMusicVolumeSetStorageValue();
	}
	babylonMusicVolumeGetStorageValue() {
		if (localStorage.getItem("babylonMusicVolume")) {
			this.volume.music = parseFloat(localStorage.getItem("babylonMusicVolume"));
			if (this.volume.music === 0) {
				this.muteMusicVolume = true;
			} else {
				this.muteMusicVolume = false;
			}
		} else {
			this.volume.music = 0.5;
			this.muteMusicVolume = false;
			this.babylonMusicVolumeSetStorageValue();
		}
	}
	babylonMusicVolumeSetStorageValue() {
		localStorage.setItem("babylonMusicVolume", String(this.volume.music));
	}

	/**
	 * SOUND FXS VOLUME (button clicks / awards sounds / misc fxs)
	 */
	set fxsVolume(value: number) {
		if (this.fxsVolumeChangedTimeOut) {
			clearTimeout(this.fxsVolumeChangedTimeOut);
		}
		this.fxsVolumeChangedTimeOut = setTimeout(() => {
			if (value > 0.0001) {
				this.fxsVolumeBeforeMute = value;
				this.muteFxs = false;
				this.volume.fxs = value;
				this.playTTSService.playTTS($localize`le volume des sons a été changé`, null, false);
			} else if (value === 0.0001) {
				this.volume.fxs = 0.0001;
				this.muteFxs = true;
			}
			if (this.volumeChangeEvent) {
				this.volumeChangeEvent.next(this.volume);
			}
		}, 100);
	}
	get fxsVolume(): number {
		return this.volume.fxs;
	}
	/**
	 * To mute the sounds effects (volume = 0.0001 if muted to leave the award bubbles displayed) & store status in LS
	 * @param muted
	 */
	toggleMuteFxs(bool) {
		if (bool) {
			this.fxsVolumeBeforeMute = this.fxsVolume;
			this.volume.fxs = 0.0001;
			this.muteFxs = true;
		} else {
			this.volume.fxs = this.volume.fxs =
				this.fxsVolumeBeforeMute && this.fxsVolumeBeforeMute !== 0.0001 ? this.fxsVolumeBeforeMute : 0.6;
			this.muteFxs = false;
		}
		if (this.volumeChangeEvent) {
			this.volumeChangeEvent.next(this.volume);
		}
		this.fxsVolumeSetStorageValue();
	}
	fxsVolumeGetStorageValue() {
		if (localStorage.getItem("fxsVolume")) {
			this.volume.fxs = parseFloat(localStorage.getItem("fxsVolume"));
			if (this.volume.fxs === 0.0001) {
				this.muteFxs = true;
			}
		}
		if (!localStorage.getItem("fxsVolume")) {
			localStorage.setItem("fxsVolume", String(this.volume.fxs));
		}
	}
	fxsVolumeSetStorageValue() {
		localStorage.setItem("fxsVolume", String(this.volume.fxs));
	}

	/**
	 * Simple ion-alert to use anywhere
	 * @param messageText message to display in the alert header
	 * @param buttonText string or [{text:string,value:return,role optionnal, css optionnal}]
	 * @param cssBody optionnal
	 * @param cssButton optionnal
	 * @returns void for one button or value return by button
	 */
	async simpleAlert<T>(
		messageText: string,
		buttonText: string | { text: string; value: T; role?: string; cssButton?: string }[],
		cssBody?: string,
		cssButton?: string
	): Promise<void | T> {
		return new Promise(async (resolve, reject) => {
			let buttons: AlertButton[] = [];
			if (typeof buttonText === "string") {
				buttons = [
					{
						text: buttonText,
						role: "confirm",
						cssClass: cssButton ? cssButton : "startButtonCabri",
						handler: () => {
							this.alertController.dismiss();
							resolve();
						}
					}
				];
			} else {
				buttonText.forEach(element => {
					buttons.push({
						text: element.text,
						role: element.role ? element.role : "confirm",
						cssClass: element.cssButton ? element.cssButton : cssButton ? cssButton : "startButtonCabri",
						handler: () => {
							this.alertController.dismiss();
							resolve(element.value);
						}
					});
				});
			}
			const alert = await this.alertController.create({
				cssClass: cssBody ? cssBody : "simpleAlert",
				backdropDismiss: true,
				mode: "ios",
				message: messageText,
				buttons: buttons
			});
			await alert.present();
			const { role } = await alert.onDidDismiss();
		});
	}

	closeStudentsMenu() {
		this.studentsMenuStatus = false;
		if (this.menuOpenEvent) {
			this.menuOpenEvent.next({ open: false, target: MenuEvents.User });
		}
	}

	loadOcrModel(networkService?: NetworkService): ReplaySubject<LayersModel> {
		if (!this.modelOcrLoaded) {
			this.modelOcrLoaded = new ReplaySubject<LayersModel>(1);
			console.time("ocrModel");
			loadLayersModel("/assets/tfjs/model.json")
				.then(async model => {
					console.log("Tensorflow Ocr model Loaded");
					console.timeEnd("ocrModel");
					try {
						model.predict(tf.zeros([1, 20, 20, 1]));
					} catch {
						if (tf.getBackend() !== "cpu") {
							console.log("Tensorflow Ocr set to cpu");
							tf.setBackend("cpu");
							await tf.ready();
							model.predict(tf.zeros([1, 20, 20, 1]));
						}
					}
					this.modelOcrLoaded.next(model);
					this.modelOcrLoaded.complete();
				})
				.catch(error => {
					if (this.ocrRetryOnce || !networkService.isConnected) {
						this.modelOcrLoaded = null;
						this.ocrRetryOnce = false;
					} else {
						this.ocrOfflineUnavailable = true;
					}
					this.bugOCR = true;
					console.error("Can't load tensorflow Ocr model ", error);
					console.timeEnd("ocrModel");
					// this.modelOcrLoaded.next(null);
					// this.modelOcrLoaded.complete();
				});
			return this.modelOcrLoaded;
		} else {
			return this.modelOcrLoaded;
		}
	}

	public checkIframeIntegration(params, classService: ClassService) {
		if (params.codeclasse && params.uid && (params.assignation_id || params.token)) {
			this.isTrala = true;
		}
		if (params.org && params.org === "aren") {
			this.isAren = true;
			classService.isAren = true;
			classService.source = "aren";
		}
		if (params.org && params.org === "beneylu") {
			this.isBeneylu = true;
			classService.isBeneylu = true;
		}
		if (params.org && params.org === "mathador") {
			this.isMathador = true;
			classService.isMathador = true;
			classService.source = "mathador";
		}
		if (this.isTrala || this.isAren || this.isBeneylu || this.isMathador) {
			this.inIframe = true;
		}
	}

	public displayImage(url) {
		this.imageViewerURL = url;
		this.imageViewerOpen = true;
	}

	/**
	 * Presents a modal to unlock Audio contexts -> to be awaited in pages will/didEnters after loader stops
	 * @param unlockGlobalMusic to unlock app music (default false)
	 * @param playText custom tts unlocking text (default 'OK')
	 */
	async unlockAudioAndTTSIfPageReloaded(unlockGlobalMusic?, playText = " ", fromBabylonPage = false): Promise<void> {
		return new Promise(async resolve => {
			if (this.checkIfFirstNavigatedPage() && this.router.url !== "/" && !this.audioUnlocked && !this.alertUnlockAudio) {
				this.alertUnlockAudio = await this.alertController.create({
					cssClass: "waitUserActionAlert",
					backdropDismiss: false,
					mode: "ios",
					message:
						$localize`Clique pour` +
						" " +
						(environment.ose || this.isPageOse
							? $localize`que Kidaia te parle`
							: environment.kidaia
							? $localize`que Kidaia te parle`
							: $localize`que Mathia te parle`) +
						"!",
					buttons: [
						{
							text: $localize`OK`,
							role: "confirm",
							cssClass: "startButtonCabri",
							handler: () => {
								this.audioUnlocked = true;
								// better text ?
								// const playText = environment.kidaia ? $localize`Bienvenue sur Kidaia` : $localize`Bienvenue sur Mathia`;
								if (unlockGlobalMusic) {
									if (!this.muteMusicVolume) {
										this.volumeChangeEvent.next(this.volume);
									}
								}
								this.playTTSService.playTTS(playText, null, false, false);
								this.alertUnlockAudio = null;
								resolve();
							}
						}
					]
				});
				await this.alertUnlockAudio.present();
			} else {
				resolve();
			}
		});
	}

	checkIfFirstNavigatedPage(): boolean {
		this.locationState = this.location.getState() as any;
		return this.locationState?.navigationId === 1;
	}

	reportDeviceInformationAnalytic() {
		const trace = {
			ios: this.isIos,
			android: this.isAndroid,
			mobile: this.isMobile,
			tablet: this.isTablet,
			desktop: this.isDesktop,
			cordova: this.isCordova,
			browser: this.isBrowser,
			safari: this.isSafari,
			height: window.outerHeight,
			width: window.outerWidth,
			lowPerformanceMode: this.lowPerformanceMode
		};
		return trace;
	}

	ngOnDestroy() {
		this.unsubscribeGlobalAppMenusSubscription();
		this.unsubscribeToMinimizeResume();
		this.unsubscribeToAppConfig();
		this.killObserveScreenOrientationSub();
		this.unsubscribeRouterChangeSub();
		window.removeEventListener("resize", () => this.sendResizeEventAfterTimeOut());
		this.screenOrientationSubscription.unsubscribe();
		this.globalAppMenusSubscription.unsubscribe();
	}
}
