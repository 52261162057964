<!-- Starboard v3 -->

    <div *ngIf="!light" [ngClass]="[type === 'shootingStar'? 'shootingStarContainer' : '', type === 'normalStar'? 'normalStarContainer' : '', type === 'moon'? 'moonContainer' : '']">
    
      <div class="shiftYF1" [ngClass]="[type === 'shootingStar'? 'shooting1Frame' : '', type === 'normalStar'? 'regular1Frame' : '', type === 'moon'? 'moon1Frame' : '']">
        <div class="Gradient1">
          <div class="UnitContainer1 shiftYU1">
            <div *ngFor="let item of arrayOne(singleStarsNumber); let i = index;" [ngClass]="[type === 'shootingStar'? 'shooting1Unit' : '', type === 'normalStar'? 'regular1Unit' : '', type === 'moon'? 'moon1Unit' : '']"></div>
          </div>
        </div> 
      </div>

      <div [ngClass]="[type === 'shootingStar'? 'shooting2Frame' : '', type === 'normalStar'? 'regular2Frame' : '', type === 'moon'? 'moon2Frame' : '']">
        <div class="Gradient2">
          <div class="UnitContainer2 shiftYU2">
            <div *ngFor="let item of arrayOne(fiveStarsNumber); let i = index;"  [ngClass]="[type === 'shootingStar'? 'shooting2Unit' : '', type === 'normalStar'? 'regular2Unit' : '', type === 'moon'? 'moon2Unit' : '']">
            </div>            
          </div> 
        </div>
      </div>

      <div class="shiftYF3" [ngClass]="[type === 'shootingStar'? 'shooting3Frame' : '', type === 'normalStar'? 'regular3Frame' : '', type === 'moon'? 'moon3Frame' : '']">
        <div class="Gradient2">
          <div *ngFor="let item of arrayOne(tenStarsNumber); let i = index;"  [ngClass]="[type === 'shootingStar'? 'shooting3Unit shiftYU3' : '', type === 'normalStar'? 'regular3Unit shiftYU3' : '', type === 'moon'? 'moon3Unit shiftYU3' : '']">
            <div [ngClass]="{'bigMoonsLabel' : type === 'moon', 'bigShootingLabel' : type === 'shootingStar', 'bigNormalLabel' : type === 'normalStar' }">{{this.units > 25 ? this.units - 15 : 10}}</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="light" class="lightFrame" [ngClass]="{'shootingLightFrame': type === 'shootingStar','regularLightFrame': type === 'normalStar', 'moonLightFrame': type === 'moon' }">
      <div class="innerScore">
        <div  [ngClass]="{'shooting1Unit': type === 'shootingStar','regular1Unit': type === 'normalStar', 'moon1Unit': type === 'moon' ,'minIconSize':true}"></div>
        <div class="unitText"> = {{units}}</div>
      </div>
    </div>