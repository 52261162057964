<div id="main-content">
  <!--<ng-container *ngIf="!environment.production"> 
    <div style="display: flex; justify-content: center;">
      <button (click)="showPourcentage()">Pourcentage</button>-->
  <!--<button (click)="showPerMonth()">Par mois</button>
      <button (click)="showPerTrimester()">Par trimestre</button>
      <button (click)="showExercicesCompleted()">Exercices Terminées</button>-->


  <!--<button (click)="showPerWeekUser()">Utilisateurs par semaines </button>
      <button (click)="showPerMonthUser()">Utilisateurs par mois </button>
      <button (click)="showPerTrimesterUser()">Utilisateurs par trimestre </button> 
      <button (click)="showNameParcours()">Name parcours </button>
      <button (click)="showParcoursTermines()">Parcours Terminées</button>
      <button (click)="showPerWeekParcours()">Parcours par semaine</button>
      <button (click)="showPerMonthParcours()">Parcours par mois</button>
      <button (click)="showPerTrimesterParcours()"> Parcours par trimestre </button> -->

  <!-- <ion-datetime hour-cycle="h24" locale="fr-FR"></ion-datetime> -->
  <ng-container *ngIf="statisticAllowed">
  <div>
    <ion-button id="open-modal-date"> Filtrer par date </ion-button>
  </div>
  <ion-modal trigger="open-modal-date">
    <ng-template>
      <ion-list>
        <ion-item id="open-calendar-start">
          <ion-icon icon="calendar-outline" slot="start" color="primary"></ion-icon>
          <ion-label>Date de début</ion-label>
          <ion-text slot="end">{{rangeDate.start}}</ion-text>
        </ion-item>

        <ion-popover trigger="open-calendar-start">
          <ng-template>
            <ion-datetime [min]="filterDataFrom" #dateTimeStart presentation="date" id="popover-datetime"
              (ionChange)="dateChoosen($event,true);dateTimeStart.confirm(true)" [value]="rangeDate.start">
            </ion-datetime>
          </ng-template>
        </ion-popover>

        <ion-item id="open-calendar-end">
          <ion-icon icon="calendar-outline" slot="start" color="primary"></ion-icon>
          <ion-label>Date de fin</ion-label>
          <ion-text slot="end">{{rangeDate.end}}</ion-text>
        </ion-item>
      </ion-list>
      <ion-popover trigger="open-calendar-end">
        <ng-template>
          <ion-datetime [min]="filterDataFrom" #dateTimeEnd presentation="date" id="popover-datetime"
            (ionChange)="dateChoosen($event,false);dateTimeEnd.confirm(true)" [value]="rangeDate.end"></ion-datetime>
        </ng-template>
      </ion-popover>

      <ion-button (click)="this.modalCtrl.dismiss()">Fermer</ion-button>
    </ng-template>
  </ion-modal>




  <ion-button color='light' fill='clear'>Progression de l'élève :</ion-button>


  <ion-button shape='round' id="togg1" (click)="getAveragePerGoodAnswer()">Moyenne</ion-button>

  <div id="option_date" *ngIf="buttonMoy">
    <ion-button shape='round' fill='clear' id="togg1" (click)="showPerWeek()">Par semaine</ion-button>
    <ion-button shape='round' fill='clear' d="togg1" (click)="showPerMonth()">Par mois</ion-button>
    <ion-button shape='round' fill='clear' id="togg1" (click)="showPerTrimester()">Par trimestre</ion-button>
  </div>

  <ion-button shape='round' id="togg2" (click)="showPourcentage()">Nombre d'activités achevées par niveau</ion-button>

  <ion-button shape='round' (click)="showExercicesCompleted()">Exercices Terminés</ion-button>

  <div id="exercices" *ngIf="buttonExercices">
    <ion-button shape='round' fill='clear' (click)="showPerWeekE()">Par semaine</ion-button>
    <ion-button shape='round' fill='clear' (click)="showPerMonthExercices()">Par mois</ion-button>
    <ion-button shape='round' fill='clear' (click)="showPerTrimesterExercices()">Par trimestre</ion-button>
  </div>


  <!-- <ion-button (click)="showExercicesCompletedClass()">Exercices Terminées Par Class</ion-button> -->

  <!-- <div>
    <button (click)="showPerWeekEx()">Exercices par semaine</button>
    <button (click)="showPerMonthEx()">Exercices par mois </button>
  </div> -->

  <ion-button shape='round' (click)="showParcoursTermines()">Parcours Terminés</ion-button>

  <div id="optiondate" *ngIf="buttonParcours">
    <ion-button shape='round' fill='clear' (click)="showPerWeekParcours()">Par semaine</ion-button>
    <ion-button shape='round' fill='clear' (click)="showPerMonthParcours()">Par mois</ion-button>
    <ion-button shape='round' fill='clear' (click)="showPerTrimesterParcours()"> Par trimestre </ion-button>
  </div>

  <ion-button shape='round' (click)="getAveragePerGoodAnswerClass()">Moyenne de la classe</ion-button>

  <ion-button shape='round' (click)="showAverageTime()">Durée moyenne des exercices par jour</ion-button>

  <ion-button shape='round' (click)="showExercicesInitializedCompleted()">Exercices commencés et terminés</ion-button>

  <ion-button shape='round' (click)="showExercicesCompletedPerGabarit()">Exercices terminés par gabarit</ion-button>

  <ion-button shape='round' (click)="showExPerGabaritDonut()">Exercices terminés par gabarit (Donut)</ion-button>



  <ion-button color='light' fill='clear'>Tableau de bord de l'enseignant :</ion-button>


  <ion-button shape='round' id="filtreComp" (click)="getCompetenceIdClass()">Moyenne par compétence</ion-button>

  <div id="optionComp" *ngIf="buttonComp">
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Additionner')">Additionner
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Soustraire')">Soustraire
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Multiplier')">Multiplier
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Diviser')">Diviser
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Représenter un nombre')">
      Représenter un nombre</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Comparer des nombres')">
      Comparer des nombres</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Décomposer un nombre')">
      Décomposer un nombre</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Ecrire un nombre')">Écrire
      un nombre</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Nommer un nombre')">Nommer
      un nombre</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Ranger des nombres')">
      Ranger des nombres</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Compter')">Compter
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Lire l heure')">Lire
      l'heure</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Solides')">Solides
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Aire')">Aire</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Durées')">Durées
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Volume et contenance')">
      Volume et contenance</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Angles')">Angles
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Proportionnalité')">
      Proportionnalité</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Se repérer')">Se repérer
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Se déplacer')">Se déplacer
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Figures géométriques')">
      Figures géométriques</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Relations géométriques')">
      Relations géométriques</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp"
      (click)="getSousCompetenceIdClass('Utiliser des instruments de construction/mesure')">Utiliser des instruments de
      construction/mesure</ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Masse')">Masse
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreComp" (click)="getSousCompetenceIdClass('Longueur et périmètre')">
      Longueur et périmètre</ion-button>
  </div>


  <ion-button shape='round' id="filtreErr" (click)="getErreurIdClass()">Moyenne par erreur type</ion-button>

  <div id="optionComp" *ngIf="buttonErr">
    <ion-button fill='clear' shape='round' id="filtreErr" (click)="getSousErreurIdClass('Automatismes')">Automatismes
    </ion-button>
    <ion-button fill='clear' shape='round' id="filtreErr" (click)="getSousErreurIdClass('Compréhension du nombre')">
      Compréhension du nombre</ion-button>
    <ion-button fill='clear' shape='round' id="filtreErr" (click)="getSousErreurIdClass('Comptine numérique')">Comptine
      numérique</ion-button>
    <ion-button fill='clear' shape='round' id="filtreErr"
      (click)="getSousErreurIdClass('Stratégie/procédure de calcul')">Stratégie/Procédure de calcul</ion-button>
  </div>

  <ion-button shape='round' (click)="showExercicesCompletedPerGabaritClass()">Exercices terminés par gabarit (classe)
  </ion-button>


  <ion-button shape='round' (click)="getCompetenceDayMoment()">Moyenne par moment de la journée 1 (élèves/heures)
  </ion-button>

  <ion-button shape='round' (click)="getCompetenceDayMomentTest()">Moyenne par moment de la journée 1 (test)
  </ion-button>

  <ion-button shape='round' (click)="getPerformanceClass()">Moyenne par jour de la semaine (5CC test)</ion-button>

  <ion-button shape='round' (click)="getPerformanceClass3CC()">Moyenne par jour de la semaine (3CC test)</ion-button>


  <!-- <ion-button (click)="getCompetenceDayMomentAll()">Moyenne par moment de la journée (par comp)</ion-button> -->

  <!-- <ion-button (click)="getCompetenceDayMomentAllEleves()">Moyenne par moment de la journée (par élève)</ion-button> -->



  <ion-button color='light' fill='clear'>Supervision :</ion-button>

  <ion-button shape='round' (click)="showExercicesInitializedCompletedMathia()">Exercices commencés et terminés (MATHIA)
  </ion-button>

  <ion-button shape='round' (click)="showExercicesInitializedCompletedKidaia()">Exercices commencés et terminés (KIDAIA)
  </ion-button>

  <ion-button shape='round' (click)="showJourneyInitializedCompletedMathia()">Parcours commencés et terminés (MATHIA)
  </ion-button>

  <ion-button shape='round' (click)="showJourneyInitializedCompletedKidaia()">Parcours commencés et terminés (KIDAIA)
  </ion-button>

  <ion-button shape='round' (click)="getPerformanceAll5CCMathia()">Performance par jour de la semaine (5CC MATHIA)
  </ion-button>

  <ion-button shape='round' (click)="getPerformanceAll5CCKidaia()">Performance par jour de la semaine (5CC KIDAIA)
  </ion-button>

  <ion-button shape='round' (click)="showFinishedQuestionsMathia()">Questions terminées (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showFinishedQuestionsKidaia()">Questions terminées (KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showExercisePerGoodAnswerMathia()">Nombre d'activités achevées par taux de réussite
    (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showExercisePerGoodAnswerKidaia()">Nombre d'activités achevées par taux de réussite
    (KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showVuesVideos()">Nombre de vues par vidéo (KIDAIA & MATHIA)</ion-button>

  <ion-button shape='round' (click)="showUserUniqueMathia()">Nombre de comptes actifs par jour (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showUserUniqueKidaia()">Nombre de comptes actifs par jour (KIDAIA)</ion-button>


  <!-- <div id="user" *ngIf="buttonUser">
  <ion-button (click)="showPerWeekUser()">Par semaine</ion-button>
  <ion-button (click)="showPerMonthUser()">Par mois</ion-button>
  <ion-button (click)="showPerTrimesterUser()"> Par trimestre </ion-button>
</div> -->

  <ion-button shape='round' (click)="showModesReponsesMathia()">Répartition des modes de réponses (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showModesReponsesKidaia()">Répartition des modes de réponses (KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showModeHoloMathia()">Utilisation du mode holo (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showModeHoloKidaia()">Utilisation du mode holo (KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showActCompletedPerGabaritAllMathia()">Activités achevées par gabarit (MATHIA)
  </ion-button>

  <ion-button shape='round' (click)="showActCompletedPerGabaritAllKidaia()">Activités achevées par gabarit (KIDAIA)
  </ion-button>

  <ion-button shape='round' (click)="showUserPerMomentMathia()">Activités achevées par jour de la semaine (MATHIA)
  </ion-button>

  <ion-button shape='round' (click)="showUserPerMomentKidaia()">Activités achevées par jour de la semaine (KIDAIA)
  </ion-button>

  <ion-button shape='round' (click)="showActAventureEntrainementMathia()">Activités achevées selon mode (MATHIA)
  </ion-button>

  <ion-button shape='round' (click)="showActAventureEntrainementKidaia()">Activités achevées selon mode (KIDAIA)
  </ion-button>

  <ion-button shape='round' (click)="showAverageTimePerGabaritMathia()">Taux & Durée moyenne des exercices par gabarit
    (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showAverageTimePerGabaritKidaia()">Taux & Durée moyenne des exercices par gabarit
    (KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showActPerGabaritAventureMathia()">Exercices commencés et achevés par gabarit
    (Aventure/MATHIA)</ion-button>

  <ion-button shape='round' (click)="showActPerGabaritEntrainementMathia()">Exercices commencés et achevés par gabarit
    (Entraînement/MATHIA)</ion-button>

  <ion-button shape='round' (click)="showActPerGabaritAventureKidaia()">Exercices commencés et achevés par gabarit
    (Aventure/KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showActPerGabaritEntrainementKidaia()">Exercices commencés et achevés par gabarit
    (Entraînement/KIDAIA)</ion-button>

  <ion-button shape='round' (click)="showScatterChartCompletedTimeMathia()">Exercices achevés & temps de travail par
    gabarit (MATHIA)</ion-button>

  <ion-button shape='round' (click)="showScatterChartCompletedTimeKidaia()">Exercices achevés & temps de travail par
    gabarit (KIDAIA)</ion-button>


  </ng-container>


  <div id="canvas-wrapper" [ngClass]="canvasShape">
    <app-chartjs
        [chartType]="barChartType"
        [chartData]="barChartData"
        [chartLabels]="barChartLabels"
        [chartOptions]="barChartOptions">
      </app-chartjs>
      <!-- <canvas baseChart [type]="barChartType" [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions">
      </canvas> -->
  </div>

</div>



<!-- <div id="customLoadingScreenDiv" [ngClass]="{'on': !barChartData}">
  <img id="loaderGIF" src="/assets/babylon/loading_low.gif" alt="chargement">
</div> -->