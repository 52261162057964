import { Injectable } from "@angular/core";
import { AccountService } from "./account.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Subscriber, Observable, ReplaySubject } from "rxjs";
import { GlobalService } from "./global.service";
import { Platform } from "@ionic/angular";
import { ClassService } from "./class.service";
declare var AdobeAn, createjs;
export interface AdobeComp {
	evt: any;
	comp: any;
}

@Injectable({
	providedIn: "root"
})
export class StartingService {
	adobeReadyObs: ReplaySubject<AdobeComp>;
	loader: any;

	constructor(public classService: ClassService, public accountService: AccountService, public globalService: GlobalService) {
		const urlParams = new URLSearchParams(window.location.search);
		if (environment.kidaia) {
			this.adobeReadyObs = new ReplaySubject<AdobeComp>(1);
			if (!this.globalService.lowPerformanceMode) {
				console.log("initAdobe() in starting service");
				this.initAdobe();
			}
			// if perfMode Desactivate we preload Adobe loader file if it's not already done
			this.globalService.perfModeEvent.subscribe(result => {
				if (!result && !this.loader) {
					this.initAdobe();
				}
			});
		}
	}

	initAdobe() {
		const comp = AdobeAn.getComposition("FCA87A29CE6F6B4090101038F2704463");
		const lib = comp.getLibrary();
		this.loader = new createjs.LoadQueue(false, "../assets/loadingAdobe/rocket/");
		this.loader.addEventListener("fileload", evt => {
			this.handleFileLoad(evt, comp);
		});

		this.loader.addEventListener("complete", evt => {
			this.adobeReadyObs.next({ evt, comp });
		});
		this.loader.loadManifest(lib.properties.manifest);
	}

	handleFileLoad(evt, comp) {
		let images = comp.getImages();
		if (evt && evt.item.type == "image") {
			images[evt.item.id] = evt.result;
		}
	}
}
