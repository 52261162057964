<!-- END RESULTS FLAT GLOBAL -->

<div class="pageContainerEndResults backgroundStars"
    *ngIf="results && !cabriService.unitsHelp && ((award === 'end_results_v2' && holoMode === '0') || award === 'end_results')"
    [ngClass]="{'mirrorMode pageContainerEndResultsMirrorMode' : cabriService.mirrorMode}">

    <div class="mathiaContainer">

        <div class="mascotteContainer">

            <div class="mascotteResults"></div>

            <div class="bubbleEndResults" (click)="playText()">

                <div class="gradientBubble">

                    <div class="textContainer">

                        <p class="textBubble" [ngClass]="{'dyslexicFontHeight': globalService.dyslexicMode}"
                            [innerHTML]="getResultsMessage()"></p>

                    </div>

                </div>
                <ion-img [src]="'/assets/mathia/buttons/play.svg'" class="playText"></ion-img>
            </div>

        </div>

    </div>

    <div class="endingWrapper gradientBorderGlobal">

        <div class="resultsWrapper">

            <!-- MOON -->
            <div [class]="'bubble-help ' + awardsDisplayedPosition" *ngIf="displayMoon">
                <div class="awardsCount">{{cabriService.teamMoonsCount}}</div>
                <div class="endAwardWrapper moon"></div>
            </div>
            <!-- REGULAR STAR -->
            <div [class]="'bubble-help ' + awardsDisplayedPosition" *ngIf="cabriService.teamNormalStarsCount > 0">
                <div class="awardsCount">{{cabriService.teamNormalStarsCount}}</div>
                <div class="endAwardWrapper star"></div>
            </div>
            <!-- SHOOTING STAR -->
            <div [class]="'bubble-help ' + awardsDisplayedPosition" *ngIf="cabriService.teamShootingStarsCount > 0">
                <div class="awardsCount">{{cabriService.teamShootingStarsCount}}</div>
                <div class="endAwardWrapper shooting-star"></div>
            </div>
        </div>
        <div class="buttonsContainer" [ngClass]="{'buttonsContainerEndResultsMirrorMode' : cabriService.mirrorMode}">
            <button *ngIf="cabriService.offerToRestartActivity" class="customButton btnNoDecoration"
                (click)="restartActivity()" i18n>Recommencer</button>
            <button class="customButton btnNoDecoration" (click)="home()" *ngIf="!globalService.inIframe">
                <ng-container
                    *ngIf="this.lmsService.currentUserJourney ||
                this.cabriService.currentActivity.story || cabriService.exercices?.nextExerciseProposed;else continuerJeu">
                    <span i18n>Quitter le jeu</span>
                </ng-container>

                <ng-template #continuerJeu>
                    <span i18n>Continuer</span>
                </ng-template>
            </button>
            <div *ngIf="this.lmsService.currentUserJourney?.nextActivityProposed || this.cabriService.currentActivity.story || cabriService.exercices?.nextExerciseProposed"
                class="customButton btnNoDecoration cardButton" (click)="nextActivity()">
                <span i18n>Je continue</span>
                <ion-img
                    *ngIf="cabriService.nextActivity && !this.lmsService.currentUserJourney && (cabriService.nextActivity.customThumbnail || cabriService.nextActivity.thumbnail)"
                    class="cardImages" [src]="cabriService.nextActivity.customThumbnail"
                    (ionError)="cabriService.nextActivity.customThumbnail = cabriService.nextActivity.thumbnail">
                </ion-img>
            </div>
        </div>
    </div>
</div>

<!-- END RESULTS HOLO GLOBAL -->
<div class="pageContainerEndResultsHolo"
    *ngIf="results && !cabriService.unitsHelp && award === 'end_results_v2' && (holoMode === '1' || holoMode === '-1')">

    <div class="buttonsContainerHolo">
        <button class="customButtonHolo"
            (click)="this.cabriService.currentActivity.story || this.lmsService.currentUserJourney?.nextActivityProposed ? nextActivity() : restartActivityHolo()"
            *ngIf="cabriService.displayHoloEndResultsButtonsCM" i18n>Oui</button>
        <button class="customButtonHolo" (click)="home()" *ngIf="cabriService.displayHoloEndResultsButtonsCM"
            i18n>Non</button>
    </div>

    <div class="endingWrapperHolo gradientBorderGlobal">

        <div class="resultsWrapperHolo">

            <div class="bubble-help" *ngIf="cabriService.teamMoonsCount > 0">
                <div class="endAwardWrapperHolo moon"></div>
                <div class="awardsCountHolo">{{cabriService.teamMoonsCount}}</div>
            </div>

            <div class="bubble-help" *ngIf="cabriService.teamNormalStarsCount > 0">
                <div class="endAwardWrapperHolo star"></div>
                <div class="awardsCountHolo">{{cabriService.teamNormalStarsCount}}</div>
            </div>

            <div class="bubble-help" *ngIf="cabriService.teamShootingStarsCount > 0">
                <div class="endAwardWrapperHolo shooting-star"></div>
                <div class="awardsCountHolo">{{cabriService.teamShootingStarsCount}}</div>
            </div>
        </div>

    </div>

</div>

<!-- CALCUL MENTAL -->

<div *ngIf="!results && (activity === 'calculmental' || activity === 'jeujustepoint')" class="pageContainerAwardsPopUp"
    [ngClass]="{'mirrorMode' : cabriService.mirrorMode}">

    <ng-container *ngIf="activity === 'jeujustepoint'">
        <div class="mascotteCM" *ngIf="!cabriService.hideMathia" [ngClass]="{
            'mascotteAnimationCM' : !globalService.lowPerformanceMode,
            'mascotteCMJeuJustePointHelp': help
        }"></div>
    </ng-container>

    <ng-container *ngIf="activity === 'calculmental'">
        <div class="mascotteCM" *ngIf="!cabriService.hideMathia" [ngClass]="{
        'mascotteAnimationCM' : !globalService.lowPerformanceMode,
        'mascotteBrowserNumpad':  (globalService.isDesktop && writeOperationOutEnabled) || (globalService.isDesktop && cabriService.cabriInputNumpadStatus && !help && !globalService.mathiaSpeechRunning && !cabriService.collectionModeInCM),
        'mascotteMobileNumpad': (globalService.isMobile && writeOperationOutEnabled) || (globalService.isMobile && cabriService.cabriInputNumpadStatus && !help),
        'mascotteTabletNumpad': (globalService.isTablet && writeOperationOutEnabled) || (globalService.isTablet && cabriService.cabriInputNumpadStatus && !help && !cabriService.collectionModeInCM),
        'mascotteTabletNumpadMirror': (globalService.isTablet && writeOperationOutEnabled) || (globalService.isTablet && cabriService.cabriInputNumpadStatus && !help && cabriService.mirrorMode),
        'mascotteHelp': help && !cabriService.cabriInputDrawStatus && !cabriService.cabriInputSttStatus,
        'mascotteHelpStt': help && cabriService.cabriInputSttStatus && !(cabriService.mirrorMode || (globalService.isCordova && this.accountService.team.length > 1)),
        'mascotteHelpSttMirror': help && cabriService.cabriInputSttStatus && (cabriService.mirrorMode || (globalService.isCordova && this.accountService.team.length > 1)),
        'mascotteDraw' : cabriService.cabriInputDrawStatus && !this.cabriService.collectionModeInCM && !cabriService.mirrorMode,
        'mascotteDrawCollection' : cabriService.cabriInputDrawStatus && this.cabriService.collectionModeInCM && !cabriService.mirrorMode,
        'mascotteDrawMirror' : cabriService.cabriInputDrawStatus && cabriService.mirrorMode,
        'mascotteCMUnitsHelp': cabriService.unitsHelp,
        'mascotteCollection' : cabriService.collectionModeInCM && cabriService.operationWrapperdisplayed && !cabriService.moonResultCollection &&
        (( !cabriService.cabriInputDrawStatus) || (cabriService.cabriInputSttStatus && globalService.isDesktop)),
        'mascotteCollectionMoonResult': cabriService.moonResultCollection,
        'mascotteCollectionStt': cabriService.collectionModeInCM && cabriService.cabriInputSttStatus && !globalService.isDesktop && !globalService.isMobile && !cabriService.mirrorMode && cabriService.operationWrapperdisplayed,
        'mascotteCollectionSttMirror' : cabriService.collectionModeInCM && cabriService.cabriInputSttStatus && !globalService.isDesktop && cabriService.mirrorMode,
        'mascotteCMMirror' : cabriService.mirrorMode && !cabriService.coinsModeInCM,
        'mascotteCMMirrorCoins' : cabriService.mirrorMode && cabriService.coinsModeInCM,
        'mascotteStatic' : help
    }"></div>
    </ng-container>

    <div class="award-main-wrapper" [ngClass]="{
    'award-main-wrapper-mobile': globalService.isMobile && !cabriService.unitsHelp, 
    'award-main-wrapperUnitsHelpDesktop': cabriService.unitsHelp && globalService.isDesktop,
    'award-main-wrapperUnitsHelpDesktopCalculMental' : cabriService.unitsHelp && globalService.isDesktop && activity === 'calculmental',
    'award-main-wrapperUnitsHelpDesktopJustePoint' : cabriService.unitsHelp && globalService.isDesktop && activity === 'jeujustepoint',
    'award-main-wrapperUnitsHelpTablet' : cabriService.unitsHelp && globalService.isTablet,
    'award-main-wrapperUnitsHelpTabletKid' : cabriService.unitsHelp && globalService.isTablet && globalService.isKidaia,
    'award-main-wrapperUnitsHelpMobile' : cabriService.unitsHelp && globalService.isMobile,
    'helpValidationMobile' : cabriService.unitsHelp && globalService.isMobile && globalService.unitsHelpCMValidation}"
        *ngIf="!cabriService.mirrorMode && !cabriService.moonResultCollection">

        <!-- Validation OCR -->
        <div class="bubble-help" [ngClass]="{'bubbleHelpMobile': globalService.isMobile}"
            *ngIf="(award === 'validation_ocr_filled') && !results">
            <div class="message-wrapper" [ngClass]="[globalService.isMobile? 'message-wrapperMobile':'']">
                <p [innerHTML]="getValidationOCRMessage()"></p>
            </div>
        </div>

        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerMobile':'validationButtonsContainer']"
            *ngIf="award === 'validation_ocr_filled' && !results">
            <div class="customButtonValidation" (click)="validateAnswer($event)" i18n>Oui</div>
            <div class="customButtonValidation" (click)="refuseAnswer($event)" i18n>Non</div>
        </div>

        <!-- Validation STT -->
        <div class="bubble-help" [ngClass]="{'bubbleHelpMobile': globalService.isMobile}"
            *ngIf="award === 'validation_stt' && !results">
            <div class="message-wrapper" [ngClass]="[globalService.isMobile? 'message-wrapperMobile':'']">
                <p [innerHTML]="getValidationSTTMessage()"></p>
            </div>
        </div>

        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerMobile':'validationButtonsContainer']"
            *ngIf="award === 'validation_stt' && !results">
            <div class="customButtonValidation" (click)="validateAnswer($event)" i18n>Oui</div>
            <div class="customButtonValidation" (click)="refuseAnswer($event)" i18n>Non</div>
        </div>

        <!-- RunMathiaSpeech Method -->
        <div [ngClass]="{'bigContainerCalculMobile': globalService.isMobile, 
        'bigContainerCalcul': globalService.isDesktop && !cabriService.unitsHelp, 
        'bigContainerCalculTablet': globalService.isTablet && !cabriService.unitsHelp,
        'bigContainerCalculTabletUnitsHelpTablet': cabriService.unitsHelp}"
            *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon' || award === 'units_help_validation') && !results && !(globalService.unitsHelpCMValidation && globalService.isMobile)">
            <div class="bubbleWithSkip" [ngClass]="[globalService.isMobile && !globalService.unitsHelpCMValidation? 'maxWidthCalculMobile':'maxWidthCalcul',
            globalService.unitsHelpCMValidation ? 'bubbleWithSkipUnitsHelpTablet':'']">
                <!-- message -->
                <div class="messageCalcul" *ngIf="!hideBubbleText">
                    <ng-container *ngIf="mathiaMessageSimple.indexOf('$$')>-1">
                        <p [ngClass]="{
                            'messageTextCalculMobile': globalService.isMobile, 
                            'messageTextCalculMobileHelp': globalService.isMobile && cabriService.unitsHelp,
                            'messageTextCalculDesktop': globalService.isDesktop, 'messageTextCalculTablet': globalService.isTablet,
                             'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                             'dyslexicFontHeight': globalService.dyslexicMode
                            }" [mathjax]="mathiaMessageSimple">
                        </p>

                    </ng-container>
                    <ng-container *ngIf="mathiaMessageSimple.indexOf('$$') === -1">
                        <p [ngClass]="{
                    'messageTextCalculMobile': globalService.isMobile, 
                    'messageTextCalculMobileHelp': globalService.isMobile && cabriService.unitsHelp,
                    'messageTextCalculDesktop': globalService.isDesktop, 'messageTextCalculTablet': globalService.isTablet,
                     'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                     'dyslexicFontHeight': globalService.dyslexicMode
                    }">
                            {{ mathiaMessageSimple }}</p>

                    </ng-container>
                </div>
                <!-- skip -->
                <div *ngIf="(award === 'simpleMessage' || (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon' && activity === 'jeujustepoint')) && !results"
                    [ngClass]="{'customButtonSkipMobile': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}"
                    class="clickable"
                    (click)="skip($event)" i18n>Passer</div>
                <!-- award -->
                <div class="awardContainerRunMathiaSpeech">
                    <div *ngIf="award === 'simpleMessageShootingStar'" class="awardWrapper shooting-star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageNormalStar'" class="awardWrapper star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageMoon'" class="awardWrapper moon" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                </div>
            </div>
        </div>

        <!-- units help validation: -->
        <div *ngIf="cabriService.unitsHelp" class="unitsHelpValidationButtonContainer"
            [ngClass]="{'unitsHelpValidationButtonContainerTablet' : globalService.isTablet}">
            <!-- text bubble -->
            <div *ngIf="globalService.unitsHelpCMValidation" [ngClass]="{'bubbleValidationUnitsHelpTablet': globalService.isTablet,
                'bubbleValidationUnitsHelpMobile': globalService.isMobile,
                 'bubbleValidationUnitsHelpDesktop': globalService.isDesktop}">
                <div class="gradientBubble">
                    <div class="textContainer">
                        <p class="textBubble" [ngClass]="{'dyslexicFontHeight': globalService.dyslexicMode}" i18n>Tu
                            comprends ?</p>
                    </div>
                </div>
            </div>
            <!-- buttons -->
            <div *ngIf="globalService.unitsHelpCMValidation" [ngClass]="{
            'validationButtonsContainerUnitsHelpTablet': globalService.isTablet,
            'validationButtonsContainerUnitsHelpMobile': globalService.isMobile,
            'validationButtonsContainerUnitsHelpDesktop': globalService.isDesktop}">
                <div class="customButtonValidationHelpUnitsHelp" (click)="validateAnswer($event)" i18n>Oui</div>
                <div class="customButtonValidationHelpUnitsHelp" (click)="refuseAnswer($event)" i18n>Non</div>
            </div>

        </div>

    </div>

</div>



<!-- REMOTE WAIT PARTICIPANTS -->

<div *ngIf="cabriService.remoteWaitingScreen" class="remoteComponentFrame">
    <!-- mascotte -->
    <div class="mascotteRemote" [ngClass]="{'mascotteAnimationKim' : !globalService.lowPerformanceMode}"
        *ngIf="!cabriService.hideMathia && !cabriService.mirrorMode"></div>
    <!-- mascotte mirror-->
    <div class="mascotteRemote" [ngClass]="{'mascotteAnimationKim' : !globalService.lowPerformanceMode}"
        *ngIf="!cabriService.hideMathia && cabriService.mirrorMode"></div>
    <!-- bubble -->
    <div class="remoteBubble" [ngClass]="{'remoteBubbleMobile' : globalService.isMobile}">
        <!-- RunMathiaSpeech Method KIM-->
        <div class="bigContainerRemote"
            *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') && !results">
            <div class="bubbleWithSkip">
                <!-- message -->
                <div class="messageKim">
                    <p [ngClass]="{'messageRemote': globalService.isMobile || globalService.isTablet,
                    'messageKimTextBrowser': globalService.isDesktop,
                    'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                    'dyslexicFontHeight': globalService.dyslexicMode}">
                        {{ mathiaMessageSimple }}</p>
                </div>
                <!-- skip -->
                <div *ngIf="award === 'simpleMessage' && !results"
                    [ngClass]="{'customButtonSkipMobile customButtonSkipMobileFuret': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}"
                    class="clickable"
                    (click)="skip($event)" i18n>Passer</div>
            </div>
        </div>

    </div>

</div>

<!-- FURET FLAT  -->
<div *ngIf="activity === 'jeudufuret' && holoMode === '0' && !cabriService.remoteWaitingScreen"
    class="pageContainerAwardsPopUpFuret" [ngClass]="{'mirrorMode' : cabriService.mirrorMode}">

    <div class="mascotteCMFuret" [ngClass]="{
    'mascotteAnimationKim' : !globalService.lowPerformanceMode, 
    'mascotteFuretMirrorMode' : cabriService.mirrorMode}" *ngIf="!cabriService.hideMathia"></div>

    <!-- furet substract mode classes ready if needed: -->
    <div class="awardFuret"
        [ngClass]="{'awardFuretTablet' : globalService.isTablet, 'awardFuretValidation' : (award === 'validation_ocr_filled' || award === 'validation_stt') && globalService.isDesktop, 'hiddenLocal' : cabriService.mirrorMode}"
        *ngIf="award !== 'none'">

        <!-- RunMathiaSpeech Method Furet-->
        <div [ngClass]="{'bigContainerMobileFuret': globalService.isMobile, 'bigContainerFuret': globalService.isDesktop, 'bigContainerFuretTablet': globalService.isTablet}"
            *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') && !results">
            <div class="bubbleWithSkip" [ngClass]="[globalService.isMobile ? 'maxWidthFuretMobile':'maxWidthCalcul']">
                <!-- message -->
                <div class="messageCalcul" *ngIf="!hideBubbleText">
                    <p [ngClass]="{
                        'messageTextCalculMobile': globalService.isMobile,
                        'messageTextCalculDesktop': globalService.isDesktop,
                        'messageTextCalculTablet': globalService.isTablet,
                        'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                        'dyslexicFontHeight': globalService.dyslexicMode
                    }">
                        {{ mathiaMessageSimple }}</p>
                </div>
                <!-- skip -->
                <div *ngIf="award === 'simpleMessage' && !results"
                    [ngClass]="{'customButtonSkipMobile customButtonSkipMobileFuret': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}" class="clickable"
                    (click)="skip($event)" i18n>Passer</div>
                <!-- award -->
                <div class="awardContainerRunMathiaSpeech">
                    <div *ngIf="award === 'simpleMessageShootingStar'" class="awardWrapper shooting-star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageNormalStar'" class="awardWrapper star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageMoon'" class="awardWrapper moon" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                </div>
            </div>
        </div>

        <!-- Validation OCR / STT-->
        <div [ngClass]="[globalService.isMobile? 'bubbleFuretValidationMobile':'bubbleFuretValidation']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt') && !results">
            <div [ngClass]="[globalService.isMobile? 'messageFuretMobile':'messageFuret']">
                <p [innerHTML]="getValidationOCRMessage()" *ngIf="award === 'validation_ocr_filled' && !results"></p>
                <p [innerHTML]="getValidationSTTMessage()" *ngIf="award === 'validation_stt' && !results"></p>
            </div>
        </div>

        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerFuretMobile':'validationButtonsContainerFuret']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt') && !results">
            <div [ngClass]="[globalService.isMobile? 'customButtonValidationMobileFuret':'customButtonValidationFuret']"
            class="clickable"
                (click)="validateAnswer($event)" i18n>Oui</div>
            <div [ngClass]="[globalService.isMobile? 'customButtonValidationMobileFuret':'customButtonValidationFuret']"
            class="clickable"
                (click)="refuseAnswer($event)" i18n>Non</div>
        </div>
    </div>
</div>

<!-- END FURET FLAT  -->

<!-- KIM FLAT  -->
<div *ngIf="activity === 'jeudekim' && holoMode === '0' && !cabriService.remoteWaitingScreen"
    [ngClass]="{'pageContainerAwardsPopUpKimMobile': globalService.isMobile, 'pageContainerAwardsPopUpFuret' : !globalService.isMobile, 'mirrorMode' : cabriService.mirrorMode}">

    <div class="mascotteCMKim"
        [ngClass]="{'mascotteAnimationKim' : !globalService.lowPerformanceMode, 'mascotteKimMirrorMode' : cabriService.mirrorMode}"
        *ngIf="!cabriService.hideMathia"></div>

    <div *ngIf="(award === 'validation_misc') && !results && cabriService.mirrorMode" class="mirrorModeReadyButtonKim clickable"
        (click)="ready($event)">{{mathiaSpeechButtonText}}</div>

    <div [ngClass]="{
        'awardKim': (award !== 'validation_stt' || award !== 'validation_misc'),
        'awardKimTablet': (award !== 'validation_stt' || award !== 'validation_misc') && globalService.isTablet,
        'awardKimMobile': globalService.isMobile && (award === 'validation_stt' || award === 'validation_misc'), 
        'awardKimMobileBubble': globalService.isMobile && (award !== 'validation_stt' || award !== 'validation_misc'), 
        'awardKimValidation': !globalService.isMobile && (award === 'validation_stt' || award === 'validation_misc'),
        'hiddenLocal' : cabriService.mirrorMode}" *ngIf="award !== 'none'">

        <!-- RunMathiaSpeech Method KIM-->
        <div [ngClass]="{'bigContainerMobile': globalService.isMobile, 'bigContainerKim': globalService.isDesktop, 'bigContainerKimTablet': globalService.isTablet}"
            *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') && !results">
            <div class="bubbleWithSkip"
                [ngClass]="[globalService.isMobile ? 'maxWidthCalculMobile':'maxWidthCalcul', !(award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') ? 'marginBottomKim' : '']">
                <!-- message -->
                <div class="messageKim">
                    <p [ngClass]="{
                            'messageKimText': globalService.isMobile || globalService.isTablet,
                            'messageKimTextBrowser': globalService.isDesktop,
                            'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                            'dyslexicFontHeight': globalService.dyslexicMode}">
                        {{ mathiaMessageSimple }}</p>
                </div>
                <!-- skip -->
                <div *ngIf="award === 'simpleMessage' && !results"
                    [ngClass]="{'customButtonSkipMobile customButtonSkipMobileFuret': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}" class="clickable"
                    (click)="skip($event)" i18n>Passer</div>
                <!-- award -->
                <div class="awardContainerRunMathiaSpeech">
                    <div *ngIf="award === 'simpleMessageShootingStar'" class="awardWrapper shooting-star"></div>
                    <div *ngIf="award === 'simpleMessageNormalStar'" class="awardWrapper star"></div>
                    <div *ngIf="award === 'simpleMessageMoon'" class="awardWrapper moon"></div>
                </div>
            </div>
        </div>

        <!-- Validation OCR / STT-->
        <div [ngClass]="[globalService.isMobile? 'bubbleKimValidationMobile':'bubbleKimValidation']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt' || award === 'validation_misc') && !results">
            <div [ngClass]="[globalService.isMobile? 'messageKimMobile':'messageKimSTT']">
                <p class="messageKimTextValidation" [innerHTML]="getValidationOCRMessage()"
                    *ngIf="award === 'validation_ocr_filled' && !results"></p>
                <p [ngClass]="{'messageKimTextValidationMobile': globalService.isMobile, 'messageKimTextValidationTablet': globalService.isTablet, 'messageKimTextValidation': !globalService.isTablet && !globalService.isMobile}"
                    [innerHTML]="getValidationSTTMessage()" *ngIf="award === 'validation_stt' && !results"></p>
                <p [ngClass]="{'messageKimTextValidationMobile': globalService.isMobile, 'messageKimTextValidation': !globalService.isMobile}"
                    *ngIf="award === 'validation_misc' && !results">{{ mathiaMessageSimple }}</p>
            </div>
        </div>

        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerKimMobile':'validationButtonsContainerKim']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt') && !results">
            <div [ngClass]="{'customButtonValidationSttMobile': globalService.isMobile, 'customButtonValidationSttTablet': globalService.isTablet, 'customButtonValidationStt': !globalService.isTablet && !globalService.isMobile}"
            class="clickable"
                (click)="validateAnswer($event)" i18n>Oui</div>
            <div [ngClass]="{'customButtonValidationSttMobile': globalService.isMobile, 'customButtonValidationSttTablet': globalService.isTablet, 'customButtonValidationStt': !globalService.isTablet && !globalService.isMobile}"
            class="clickable"
                (click)="refuseAnswer($event)" i18n>Non</div>
        </div>

        <div [ngClass]="{'validationButtonsContainerFuretMobile' :globalService.isMobile, 'validationButtonsContainerMisc' : globalService.isDesktop, 'validationButtonsContainerMiscKimTablet' : globalService.isTablet}"
            *ngIf="(award === 'validation_misc') && !results">
            <div [ngClass]="{'customButtonValidationMiscMobile': globalService.isMobile, 
            'customButtonValidationMiscTablet cbvHeightKimTablet': (globalService.isTablet && !globalService.isDesktop), 
            'customButtonValidationMisc cbvHeightKim': !globalService.isTablet && !globalService.isMobile && globalService.isDesktop}" class="clickable"
                (click)="ready($event)">{{mathiaSpeechButtonText}}</div>
        </div>

    </div>

</div>

<!-- END KIM -->

<!-- HEURE FLAT  -->

<div class="pageContainerAwardsPopUpFuret"
    *ngIf="!results && activity === 'lirelheure' && holoMode === '0' && !cabriService.remoteWaitingScreen"
    [ngClass]="{'mirrorMode' : cabriService.mirrorMode, 'pageContainerAwardsPopUpKimMobile' : globalService.isMobile}">

    <!-- mascotte -->
    <div *ngIf="!cabriService.hideMathia" class="mascotteCMHeure" [ngClass]="[
        !globalService.lowPerformanceMode ? 'mascotteAnimationHeure' : '',
        cabriService.clockLeft && !(globalService.isMobile && cabriService.clockType === 'digital') ? (globalService.isTablet? 'mascotteCMHeureCenterTablet' : 'mascotteCMHeureCenter') :'mascotteCMHeureRight',
        cabriService.cabriInputNumpadStatus && cabriService.clockType === 'digital' && !cabriService.mirrorMode ? 'mascotteCMHeureDigitalUp' : '',
        cabriService.cabriInputNumpadStatus && cabriService.clockType === 'digital' && cabriService.mirrorMode ? 'mascotteCMHeureDigitalUpMirrorMode' : '',
        cabriService.cabriInputNumpadStatus && cabriService.clockType === 'analog' && !cabriService.mirrorMode ? 'mascotteCMHeureAnalogUp' : ''
    ]"></div>

    <!-- bulle -->
    <div [ngClass]="{
    'awardHeureNew': (award !== 'validation_stt' || award !== 'validation_misc'),
    'awardKimValidation': !globalService.isMobile && (award === 'validation_stt' || award === 'validation_misc')}"
        *ngIf="award !== 'none' && !(globalService.isMobile && cabriService.clockType === 'digital') && !cabriService.mirrorMode">

        <!-- RunMathiaSpeech Method Heure (from Kim) -->
        <div [ngClass]="{'bigContainerMobile': globalService.isMobile, 'bigContainerKim bCH': globalService.isDesktop, 'bigContainerKimTablet': globalService.isTablet}"
            *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') && !results">
            <div class="bubbleWithSkip"
                [ngClass]="[globalService.isMobile ? 'maxWidthCalculMobile':'maxWidthCalcul', !(award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') ? 'marginBottomKim' : '']">
                <!-- message -->
                <div class="messageKim" *ngIf="!hideBubbleText">
                    <p [ngClass]="{
                            'messageKimText': globalService.isMobile || globalService.isTablet,
                            'messageKimTextBrowser': globalService.isDesktop,
                            'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                            'dyslexicFontHeight': globalService.dyslexicMode}">
                        {{ mathiaMessageSimple }}</p>
                </div>
                <!-- skip -->
                <div *ngIf="award === 'simpleMessage' && !results"
                    [ngClass]="{'customButtonSkipMobile customButtonSkipMobileHeure': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}" class="clickable"
                    (click)="skip($event)" i18n>Passer</div>
                <!-- award -->
                <div class="awardContainerRunMathiaSpeech">
                    <div *ngIf="award === 'simpleMessageShootingStar'" class="awardWrapper shooting-star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageNormalStar'" class="awardWrapper star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageMoon'" class="awardWrapper moon" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                </div>
            </div>
        </div>

        <!-- Validation OCR / STT-->
        <!-- bubble -->
        <div [ngClass]="[globalService.isMobile? 'bubbleHeureValidationMobile': globalService.isTablet ? 'bubbleHeureValidationTablet' : 'bubbleKimValidation']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt' || award === 'validation_misc' || award === 'validation_ampm') && !results">
            <div [ngClass]="[globalService.isMobile? 'messageKimMobile':'messageKimSTT']">
                <p class="messageKimTextValidation" [innerHTML]="getValidationOCRMessage()"
                    *ngIf="award === 'validation_ocr_filled' && !results"></p>
                <p [ngClass]="{'messageKimTextValidationMobile': globalService.isMobile, 'messageKimTextValidationTablet': globalService.isTablet, 'messageKimTextValidation': !globalService.isTablet && !globalService.isMobile}"
                    [innerHTML]="getValidationSTTMessage()" *ngIf="(award === 'validation_stt' || award === 'validation_ampm') && !results"></p>
                <p [ngClass]="{'messageKimTextValidationMobile': globalService.isMobile, 'messageKimTextValidation': !globalService.isMobile}"
                    *ngIf="award === 'validation_misc' && !results">{{ mathiaMessageSimple }}</p>
            </div>
        </div>

        <!-- buttons -->
        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerHeureMobile' : globalService.isTablet ? 'validationButtonsContainerHeureTablet' : 'validationButtonsContainerKim']"
        *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt' || award === 'validation_ampm') && !results">
            <div [ngClass]="{'customButtonValidationSttMobile': globalService.isMobile, 'customButtonValidationSttTabletHeure': globalService.isTablet, 'customButtonValidationStt': !globalService.isTablet && !globalService.isMobile}" class="clickable"
                (click)="validateAnswer($event)">
                <span *ngIf="award !== 'validation_ampm'" i18n>Oui</span>
                <span *ngIf="award === 'validation_ampm'">am</span>
            </div>
            <div [ngClass]="{'customButtonValidationSttMobile': globalService.isMobile, 'customButtonValidationSttTabletHeure': globalService.isTablet, 'customButtonValidationStt': !globalService.isTablet && !globalService.isMobile}" class="clickable"
                (click)="refuseAnswer($event)">
                <span *ngIf="award !== 'validation_ampm'" i18n>Non</span>
                <span *ngIf="award === 'validation_ampm'">pm</span>
            </div>
        </div>

        <div [ngClass]="{'validationButtonsContainerFuretMobile' :globalService.isMobile, 'validationButtonsContainerMisc' : globalService.isDesktop, 'validationButtonsContainerMiscKimTablet' : globalService.isTablet}"
            *ngIf="(award === 'validation_misc') && !results">
            <div [ngClass]="{'customButtonValidationMiscMobile': globalService.isMobile, 
            'customButtonValidationMiscTablet cbvHeightKimTablet': (globalService.isTablet && !globalService.isDesktop), 
            'customButtonValidationMisc cbvHeightKim': !globalService.isTablet && !globalService.isMobile && globalService.isDesktop}" class="clickable"
                (click)="ready()">{{mathiaSpeechButtonText}}</div>
        </div>

    </div>

</div>

<!-- GALAXIE FLAT  -->
<div *ngIf="activity === 'galaxiedescalculs' && holoMode === '0' && award !== 'end_results_v2' && award !== 'end_results'"
    class="galaxyComponentFrame"
    [ngClass]="{'galaxyComponentFrameTablet' : globalService.isTablet, 'galaxyComponentFrameMobile' : globalService.isMobile, 'mirrorMode galaxyComponentFrameMirrorMode' : cabriService.mirrorMode}">
    <!--  -->

    <!-- mascotte -->
    <div class="mascotteGalaxy" *ngIf="!cabriService.hideMathia && !cabriService.mirrorMode" [ngClass]="{
    'mascotteAnimationKim' : !globalService.lowPerformanceMode,
    'mascotteGalaxyIdle': !cabriService.castleLeft && globalService.isDesktop,
    'mascotteGalaxyDraw' : cabriService.cabriInputDrawStatus && globalService.isDesktop && !this.cabriService.inputMethodTutoRunning,
    'mascotteGalaxyTablet' : cabriService.castleLeft && globalService.isTablet && !cabriService.cabriInputNumpadStatus,
    'mascotteGalaxyIdleTablet' : !cabriService.castleLeft && globalService.isTablet, 
    'mascotteGalaxyIdleMobile' : !cabriService.castleLeft && globalService.isMobile,
    'mascotteGalaxyNumpad' : cabriService.cabriInputNumpadStatus,
    'mascotteGalaxyNumpadTablet' : globalService.isTablet && cabriService.cabriInputNumpadStatus && award !== 'validation_stt' && award !== 'validation_misc',
    'mascotteGalaxyIdleTabletDrawing' : globalService.isTablet && cabriService.cabriInputDrawStatus,
    'mascotteGalaxyNumpadTabletValidation' : cabriService.cabriInputNumpadStatus && award === 'validation_stt' && award === 'validation_misc',
    'mascotteGalaxyMobile' : cabriService.castleLeft && globalService.isMobile && !cabriService.cabriInputNumpadStatus,
    'mascotteGalaxyNumpadMobile' : cabriService.cabriInputNumpadStatus && globalService.isMobile}"></div>

    <!-- mascotte mirror-->
    <div class="mascotteGalaxy" *ngIf="!cabriService.hideMathia && cabriService.mirrorMode"
        [ngClass]="{
    'mascotteAnimationKim' : !globalService.lowPerformanceMode,
    'mascotteGalaxyTabletMirroMode' : cabriService.castleLeft && !cabriService.cabriInputNumpadStatus,
    'mascotteGalaxyIdleTabletMirroMode' : !cabriService.castleLeft, 
    'mascotteGalaxyNumpadTablet' : globalService.isTablet && cabriService.cabriInputNumpadStatus && award !== 'validation_stt' && award !== 'validation_misc',
    'mascotteGalaxyIdleTabletDrawing' : globalService.isTablet && cabriService.cabriInputDrawStatus,
    'mascotteGalaxyNumpadTabletValidation' : cabriService.cabriInputNumpadStatus && award === 'validation_stt' && award === 'validation_misc'}"></div>


    <div [ngClass]="{
        'galaxyBubble': (award !== 'validation_stt' || award !== 'validation_misc') && !globalService.isTablet,
        'galaxyBubbleTablet': (award !== 'validation_stt' || award !== 'validation_misc') && globalService.isTablet,
        'galaxyBubbleMobile' : (award !== 'validation_stt' || award !== 'validation_misc') && globalService.isMobile,
        'awardKimTablet': (award !== 'validation_stt' || award !== 'validation_misc') && globalService.isTablet,
        'awardKimMobile': globalService.isMobile && (award === 'validation_stt' || award === 'validation_misc'), 
        'awardKimMobileBubble': globalService.isMobile && (award !== 'validation_stt' || award !== 'validation_misc'), 
        'awardKimValidation': !globalService.isMobile && (award === 'validation_stt' || award === 'validation_misc')}"
        *ngIf="award !== 'none' && !cabriService.mirrorMode">

        <!-- RunMathiaSpeech Method KIM-->
        <div [ngClass]="{'bigContainerMobile': globalService.isMobile, 'bigContainerGalaxy': globalService.isDesktop, 'bigContainerGalaxyTablet': globalService.isTablet}"
            *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') && !results">
            <div class="bubbleWithSkip"
                [ngClass]="[globalService.isMobile ? 'maxWidthCalculMobile':'maxWidthCalcul', !(award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') ? 'marginBottomKim' : '']">
                <!-- message -->
                <div class="messageKim" *ngIf="!hideBubbleText">
                    <p [ngClass]="{
                            'messageKimText': globalService.isMobile || globalService.isTablet,
                            'messageKimTextBrowser': globalService.isDesktop,
                            'messageTextCalculMobileWithAward': globalService.isMobile && (award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon'),
                            'dyslexicFontHeight': globalService.dyslexicMode}">
                        {{ mathiaMessageSimple }}</p>
                </div>
                <!-- skip -->
                <div *ngIf="award === 'simpleMessage' && !results"
                    [ngClass]="{'customButtonSkipMobile customButtonSkipMobileFuret': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}" class="clickable"
                    (click)="skip($event)" i18n>Passer</div>
                <!-- award -->
                <div class="awardContainerRunMathiaSpeech">
                    <div *ngIf="award === 'simpleMessageShootingStar'" class="awardWrapper shooting-star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageNormalStar'" class="awardWrapper star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageMoon'" class="awardWrapper moon" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                </div>
            </div>
        </div>

        <!-- Validation OCR / STT-->
        <div [ngClass]="[globalService.isMobile? 'bubbleGalaxyValidationMobile':'bubbleKimValidation']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt' || award === 'validation_misc') && !results">
            <div [ngClass]="[globalService.isMobile? 'messageKimMobile':'messageKimSTT']">
                <p class="messageKimTextValidation" [ngClass]="{'messageGalaxyTextValidation': globalService.isMobile}"
                    [innerHTML]="getValidationOCRMessage()" *ngIf="award === 'validation_ocr_filled' && !results"></p>
                <p [ngClass]="{'messageKimTextValidationMobile': globalService.isMobile, 'messageKimTextValidationTablet': globalService.isTablet, 'messageKimTextValidation': !globalService.isTablet && !globalService.isMobile}"
                    [innerHTML]="getValidationSTTMessage()" *ngIf="award === 'validation_stt' && !results"></p>
                <p [ngClass]="{'messageGalaxyTextValidation': globalService.isMobile, 'messageKimTextValidation': !globalService.isMobile}"
                    *ngIf="award === 'validation_misc' && !results">{{ mathiaMessageSimple }}</p>
            </div>
        </div>

        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerGalaxyMobile': globalService.isTablet? 'validationButtonsContainerGalaxy' : 'validationButtonsContainerKim']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt') && !results">
            <div [ngClass]="{'customButtonValidationSttMobile': globalService.isMobile, 'customButtonValidationSttTablet': globalService.isTablet, 'customButtonValidationSttGalaxy': !globalService.isTablet && !globalService.isMobile}"
            class="clickable"
                (click)="validateAnswer($event)" i18n>Oui</div>
            <div [ngClass]="{'customButtonValidationSttMobile': globalService.isMobile, 'customButtonValidationSttTablet': globalService.isTablet, 'customButtonValidationSttGalaxy': !globalService.isTablet && !globalService.isMobile}"
            class="clickable"
                (click)="refuseAnswer($event)" i18n>Non</div>
        </div>

        <div [ngClass]="{'validationButtonsContainerFuretMobile' :globalService.isMobile, 'validationButtonsContainerMisc' : globalService.isDesktop, 'validationButtonsContainerMiscKimTablet' : globalService.isTablet}"
            *ngIf="(award === 'validation_misc') && !results">
            <div [ngClass]="{'customButtonValidationMiscMobile': globalService.isMobile, 
            'customButtonValidationMiscTablet cbvHeightKimTablet': (globalService.isTablet && !globalService.isDesktop), 
            'customButtonValidationMisc cbvHeightKim': !globalService.isTablet && !globalService.isMobile && globalService.isDesktop}" class="clickable"
                (click)="ready()">{{mathiaSpeechButtonText}}</div>
        </div>

    </div>

</div>

<!-- SOLIDES FLAT  -->
<div *ngIf="activity === 'solides' && holoMode === '0' && !cabriService.remoteWaitingScreen "
    class="pageContainerAwardsPopUpSolides">

    <div class="mascotteCMSolides"
        [ngClass]="{'mascotteAnimationKim' : !globalService.lowPerformanceMode, 'mascotteCMSolidesMobile' : globalService.isMobile}"
        *ngIf="!cabriService.hideMathia"></div>

    <div class="awardSolides"
        [ngClass]="{'awardSolidesTablet': globalService.isTablet, 'awardSolidesMobile': globalService.isMobile}"
        *ngIf="(award === 'simpleMessage' || award === 'simpleMessageNoSkip' || award === 'simpleMessageShootingStar' || award === 'simpleMessageNormalStar' || award === 'simpleMessageMoon') && !results">

        <!-- RunMathiaSpeech Method -->
        <div [ngClass]="{'bigContainerMobile': globalService.isMobile, 'bigContainerSolidTablet': globalService.isTablet, 'bigContainerFuret': !globalService.isMobile && !globalService.isTablet}"
            *ngIf="!cabriService.mirrorMode">
            <div class="bubbleWithSkipFuret" [ngClass]="{'bubbleWithSkipSolidTablet': globalService.isTablet}">
                <div class="messageKim" *ngIf="!hideBubbleText">
                    <p [ngClass]="[!globalService.isMobile && !globalService.isTablet? 'messageSolidesTextBrowser':'messageKimText',
                    globalService.dyslexicMode ? 'dyslexicFontHeight' : '']">
                        {{ mathiaMessageSimple }}</p>
                </div>
                <div [ngClass]="{'customButtonSkipMobile': globalService.isMobile, 'customButtonSkip': !globalService.isMobile}"
                    (click)="skip($event)" class="clickable"
                    *ngIf="(award === 'simpleMessage' || award !== 'simpleMessageNoSkip') && !results && globalService.mathiaSpeechRunning"
                    i18n>Passer
                </div>
                <!-- award -->
                <div class="awardContainerRunMathiaSpeech" *ngIf="award !== 'simpleMessage' && award !== 'simpleMessageNoSkip'">
                    <div *ngIf="award === 'simpleMessageShootingStar'" class="awardWrapper shooting-star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageNormalStar'" class="awardWrapper star" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                    <div *ngIf="award === 'simpleMessageMoon'" class="awardWrapper moon" [ngClass]="{'awardSoloMargin' : hideBubbleText}"></div>
                </div>
            </div>
        </div>

        <!-- Validation OCR / STT-->
        <div [ngClass]="[globalService.isMobile? 'bubbleFuretValidationMobile':'bubbleFuretValidation']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt') && !results">
            <div [ngClass]="[globalService.isMobile? 'messageFuretMobile':'messageFuret']">
                <p [innerHTML]="getValidationOCRMessage()" *ngIf="award === 'validation_ocr_filled' && !results"></p>
                <p [innerHTML]="getValidationSTTMessage()" *ngIf="award === 'validation_stt' && !results"></p>
            </div>
        </div>

        <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerFuretMobile':'validationButtonsContainerFuret']"
            *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt') && !results">
            <div [ngClass]="[globalService.isMobile? 'customButtonValidationMobileFuret':'customButtonValidation']"
            class="clickable"
                (click)="validateAnswer($event)" i18n>Oui</div>
            <div [ngClass]="[globalService.isMobile? 'customButtonValidationMobileFuret':'customButtonValidation']"
            class="clickable"
                (click)="refuseAnswer($event)" i18n>Non</div>
        </div>

    </div>

</div>

<!-- SKIP BUTTOjourney MIRROR MODE -->
<div *ngIf="award === 'simpleMessage' && !results && cabriService.mirrorMode"
    [ngClass]="[activity === 'solides' ? 'customButtonSkipMirrorModeSolides' : 'customButtonSkipMirrorMode']"
    class="clickable"
    (click)="skip($event)" i18n>Passer</div>

<!-- BOTTOM RIGHT HORIZONTAL -->
<div [ngClass]="[activity === 'solides' ? 'validationButtonsContainerMirrorModeSolides':'validationButtonsContainerMirrorMode']"
    *ngIf="(award === 'validation_ocr_filled' || award === 'validation_stt' || award === 'units_help_validation') && !results && cabriService.mirrorMode">
    <div #validateButtonMirror class="customButtonValidationMirrorModeSideRH clickable" (click)="validateAnswer($event)" i18n>Oui
    </div>
    <div #refuseButtonMirror class="customButtonValidationMirrorModeSideRH clickable" (click)="refuseAnswer($event)" i18n>Non
    </div>
</div>