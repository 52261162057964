<div class="perGoodAnswer" *ngIf="percent">
  <div class="statisticContainer progressionContainer"
    *ngIf="mobileLandscape || globalService.isDesktop || globalService.isTablet">
    <p class="percentResult">
      {{percent}} %
    </p>
    <div class="progressCard progressedPercentGrid">
      <div class="boxProgression">
        <div class="boxProgressionPercent" #progressBarPercent [ngClass]="{'boxProgressionNearlyCompleted' : percent >= 95, 'boxProgressionFarCompleted' : percent <= 94, 
           'level1' : percent >= 0 && percent <= 24,
           'level2': percent >= 25 && percent <= 48,
           'level3' : percent >= 50 && percent <= 99,
           'level4' : percent >= 100,
           'animate':!globalService.lowPerformanceMode}" [ngStyle]="{'transform' : 'scaleX('+ percent / 100  + ')'
            }">
        </div>
      </div>
    </div>
  </div>
</div>