<ng-container *ngIf="currentColoring">
  <div class="title-container">    
  </div>
  <div class="title-wrapper" *ngIf="!won">
    <p class="colorizedItems" >Le nombre d'éléments coloriés {{counterItemsToColor}}/{{totalItemsColored}}</p>
    <ion-button class="skip-button" (click)="nextDrawing()" *ngIf="!environment.production || accountService.team[0].preview">{{accountService.team[0].preview?"Passer":"Dessin suivant"}}</ion-button>
  </div>

  <pinch-zoom #pinch [properties]="pZProperties">
    <div id="svg-wrapper" #svgElement></div>
  </pinch-zoom>
</ng-container>

<div id="nav-wrapper" *ngIf="!currentColoring && cabriService.coloring">
  <nav>
    <ul>
      <ng-container *ngFor="let coloring of cabriService.coloring">
        <li (click)="loadSVG(coloring.id)">{{coloring.id}} - {{coloring.title | titlecase}}</li>
      </ng-container>
    </ul>
  </nav>
</div>