import { Component, OnInit } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { Student } from "src/app/models/student";
import { Classroom } from "src/app/models/classroom";
import { ModalController } from "@ionic/angular";
import { ModalActivityParticipantsComponent } from "src/app/components/modal-activity-participants/modal-activity-participants.component";
import { AccountService } from "src/app/services/account.service";
import { PlayTTSService } from "../../services/play-tts.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/services/global.service";
import { ConnectionStatus, NetworkService } from "src/app/services/network.service";
import { LrsService } from "src/app/services/lrs.service";
import { environment } from "src/environments/environment";
// tslint:disable-next-line: max-line-length
import { ModalActivityParticipantsKidaiaComponent } from "src/app/components/modal-activity-participants-kidaia/modal-activity-participants-kidaia.component";
import { LmsService } from "src/app/services/lms.service";
import { StoryService } from "src/app/services/story.service";
import { BadgeService } from "src/app/services/badge.service";
import { VideosService } from "src/app/services/videos.service";
import { lastValueFrom } from "rxjs";
import { ClassService } from "src/app/services/class.service";
import { AudioService } from "src/app/services/audio.service";

declare var window: {
	activityParticipant: any;
	dispatchEvent: any;
};

@Component({
	selector: "app-activity-participants",
	templateUrl: "./activity-participants.page.html",
	styleUrls: ["./activity-participants.page.scss"]
})
export class ActivityParticipantsPage implements OnInit {
	introText0Played: boolean;
	introText1Played: boolean;
	constructor(
		public platform: Platform,
		public modalController: ModalController,
		public accountService: AccountService,
		private playTTS: PlayTTSService,
		public cabriDataService: CabriDataService,
		private router: Router,
		public globalService: GlobalService,
		public toastController: ToastController,
		public lrsService: LrsService,
		public classService: ClassService,
		private lmsService: LmsService,
		public storyService: StoryService,
		public badgeService: BadgeService,
		public videosService: VideosService,
		public networkService: NetworkService,
		public audioService: AudioService
	) {
		window.activityParticipant = this;
	}

	debounce: boolean;
	allowStudentAdd = false;
	teamAlreadyFull = false;
	helpText: string;
	public maxPlayer: number;

	static buildMockClassroom(): any[] {
		const classeTest = new Classroom("Super classe", null, null);
		const studentArray = [
			new Student("1", classeTest, $localize`Astronaute 1`),
			new Student("2", classeTest, $localize`Astronaute 2`),
			new Student("3", classeTest, $localize`Astronaute 3`),
			new Student("4", classeTest, $localize`Astronaute 4`)
		];
		return studentArray;
	}

	ngOnInit(): void {}

	async ionViewWillEnter() {
		this.globalService.onActivityParticipantsPage = true;
		this.globalService.setGlobalLoading(true);
		this.introText1Played = false;
		this.introText0Played = false;
		try {
			this.globalService.checkToolbarStatus();
			this.debounce = false;
		} catch (error) {
			this.globalService.setGlobalLoading(false);
		}
	}

	async ionViewDidEnter() {
		// TODO remove security breach
		try {
			await this.timeOut(100);
			window.dispatchEvent(new Event("focus"));
			try{
				await this.classService.getSessionToken();
			}catch(err){}
			this.platform.ready().then(() => {
				if (this.globalService.isCordova) {
					console.log("screenO unlock gabarits");
					this.globalService.screenOrientation.unlock();
				}
			});
			await lastValueFrom(this.accountService.isUserLoaded);

			if(this.networkService.lastConnectionStatus === ConnectionStatus.Offline){
				// recover students when internet connexion is back
				this.accountService.studentsClassLoaded = false;
			}
			if (!this.accountService.studentsClassLoaded) {
				try {
					// new connexion so recover student infos
					await this.accountService.getStudentsAndJourneys();
					// update stories and badges for all students in the class
					if(this.accountService.isRealClassConnected){
						try{
							await this.lmsService.gamification.getExercisesProgression(this.globalService.locale);
						}catch(err){
							console.error("no statistics")
						}
						await this.accountService.getStudentsProfilesFromClassroom(this.accountService.user?.codeClasse);
					}
					let storys = this.storyService.getAllStorys(this.accountService.studentsIds);
					let badges = this.badgeService.getAllBadges(this.accountService.studentsIds);
					await Promise.all([storys,badges]);
				} catch (err) {
					console.error("erreur récupération parcours et élèves");
				}
			}
			this.maxPlayer = this.classService.loggedFromHome ? 1 : 4;
			this.globalService.setGlobalLoading(false);
			await this.globalService.unlockAudioAndTTSIfPageReloaded();
			this.audioService.launchMusic();
			this.recoverStudents();
		} finally {
			this.globalService.setGlobalLoading(false);
			if (this.accountService.team.length < this.maxPlayer) {
				this.allowStudentAdd = true;
			} else {
				this.allowStudentAdd = false;
			}
		}
	}

	recoverStudents() {
		this.sortStudentsByName();
		this.updateCurrentTeam();
		if (this.networkService.isConnected && this.accountService.checkUserConnected() && !this.accountService.isRealClassConnected) {
			this.presentToast($localize`Attention cette classe est vide.\nAppuyez sur "Changer de classe" pour choisir une autre classe.`);
		}
	}

	async presentToast(message) {
		const toast = await this.toastController.create({
			message,
			position: "top",
			duration: 5000
		});
		toast.present();
	}

	public sortStudentsByName() {
		return this.accountService.allStudents.sort((studentA, studentB) => {
			const studentAname = studentA.name
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toLowerCase();
			const studentBname = studentB.name
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.toLowerCase();
			if (studentAname < studentBname) {
				return -1;
			} else if (studentAname > studentBname) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	// storeStudents(storedRequests, classeGroupDetail) {
	// 	const studentsCopy = cloneDeep(classeGroupDetail.students);
	// 	studentsCopy.forEach(student => {
	// 		if (student.classe) {
	// 			student.classe = undefined;
	// 		}
	// 	});
	// 	storedRequests.students = studentsCopy;
	// 	storedRequests.codeclasse = this.accountService.user.codeClasse;
	// }

	playText(text: string, manual: boolean = false): Promise<void> {
		return new Promise(async (resolve, reject) => {
			this.helpText = text;
			if (!this.playTTS.menusMuted || manual) {
				this.playTTS.playTTS(text).then(() => {
					resolve();
				});
			} else {
				setTimeout(() => {
					resolve();
				}, 1000);
			}
		});
	}

	removeFromList(oneStudent: Student) {
		this.audioService.playCancelSound();
		this.accountService.allStudents.forEach(student => {
			if (student.id === oneStudent.id) {
				student.selectedTemp = false;
				student.selected = false;
				student.playerId = undefined;
			}
		});
		this.accountService.team = this.accountService.team.filter(item => item.id !== oneStudent.id);
		this.accountService.team.forEach((student, index) => {
			this.accountService.setStudentSlot(student, index);
		});

		this.teamAlreadyFull = false;
		this.allowStudentAdd = true;
		this.checkTeamIfReady();
	}

	async openListStudents() {
		// if (this.accountService.team.length < this.maxPlayer) {
		this.audioService.playSelectSound();
		this.accountService.teamsCopy = this.accountService.restoreGroups();
		const modal = await this.modalController.create({
			component: environment.kidaia ? ModalActivityParticipantsKidaiaComponent : ModalActivityParticipantsComponent,
			componentProps: {
				students: this.accountService.allStudents,
				team: this.accountService.team,
				teamsCopy: this.accountService.teamsCopy,
				maxPlayer: this.maxPlayer,
				teamAlreadyFull: this.teamAlreadyFull
			},
			cssClass: "student-list-modal gradientBubbleModal"
		});

		modal.onDidDismiss().then((data: any) => {
			if (data.data !== undefined) {
				if (typeof data.data.studentSelectedFromGroup !== "undefined") {
					this.accountService.team = data.data.studentSelectedFromGroup;
					if (this.checkIfFullMaxPlayer) {
						this.allowStudentAdd = false;
					}
				}
				if (data.data.student) {
					this.lmsService.userJourneyFromParams = null;
					this.lmsService.storedJourneyStatementLoad = false;
					if (this.lmsService.gamification) {
						this.lmsService.gamification.logBookStatistics = new Array();
					}
					this.accountService.loadGabaritStudentInfos = false;
					this.accountService.setStudentSlot(data.data.student);
					if (this.accountService.team.length > 1 && this.accountService.team.length !== this.maxPlayer) {
						if (!this.playTTS.menusMuted) {
							this.playText($localize`${data.data.student.name} sera le joueur numéro ${this.accountService.team.length} !`);
						}
					} else if (this.checkIfFullMaxPlayer) {
						this.allowStudentAdd = false;
						if (!this.playTTS.menusMuted) {
							if (!data.data.teamAlreadyFull) {
								this.playText(
									$localize`${data.data.student.name} sera le joueur numéro ${this.accountService.team.length} ! Quelle belle équipe. `
								);
							} else {
								this.playText($localize`L'équipe est au complet ! Vous ne pouvez pas ajouter d'autres joueurs !`);
							}
						}
					}
				}
				this.checkTeamIfReady();
			}
		});

		return await modal.present();
	}

	get checkIfFullMaxPlayer() {
		return this.accountService.team.length === this.maxPlayer;
	}

	async checkTeamIfReady(): Promise<any> {
		if (this.accountService.team.length === 0) {
			this.helpText = $localize`Appuie sur le bouton plus et sélectionne ton nom !`;
			if (!this.introText0Played) {
				this.playText(this.helpText);
				this.introText0Played = true;
			}
		} else if (this.accountService.team.length === 1 && !this.introText1Played) {
			// Appuie sur valider pour compléter ton equipe sinon ajoute tes camarades dans l'équipe
			this.introText1Played = true;
			
			if(this.classService.loggedFromHome){
				this.playText(
					$localize`Bienvenue à bord ${this.accountService.team[0].name} ! Appuie sur valider pour choisir ta destination.`
				);
			} else {
				this.playText(
					$localize`Bienvenue à bord ${this.accountService.team[0].name} ! Appuie sur valider pour choisir ta destination ou ajoute tes camarades pour jouer en équipe.`
				);
			}
			
		} else if (this.accountService.team.length > 1 && this.accountService.team.length < this.maxPlayer) {
			if (this.accountService.team.length === this.accountService.allStudents.length) {
				this.helpText = $localize`Vous pouvez maintenant appuyer sur le bouton Valider pour choisir une activité ! `;
			} else {
				this.helpText = $localize`Vous pouvez maintenant appuyer sur le bouton Valider pour choisir une activité ou ajouter plus de joueurs dans l'équipe !`;
			}
		} else if (this.accountService.team.length === this.maxPlayer) {
			this.helpText = $localize`Vous pouvez maintenant appuyer sur le bouton Valider pour choisir une activité !`;
		}
	}

	backCustomLocation($event) {
		this.playTTS.killSpeech();
		this.audioService.playSelectSound();
		if ($event && $event.currentTarget) {
			this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		}
		this.router.navigateByUrl("/starting");
	}

	/**
	 * Temp function
	 */
	updateCurrentTeam() {
		if (this.accountService.team.length > 0) {
			this.accountService.team = this.accountService.team.filter(student => {
				return this.accountService.allStudents.some(students => {
					return students.id === student.id;
				});
			});
		}

		this.accountService.restoreTeam(this.accountService.allStudents);
		this.accountService.team.forEach((student, index) => {
			student.selected = true;
			student.selectedTemp = true;
			const result = this.accountService.allStudents.find(students => students.id === student.id);
			if (result) {
				result.selected = true;
				this.accountService.setStudentSlot(student, index);
			}
		});
		this.checkTeamIfReady();
	}

	/***
	 * Save current group into storage only if it's not already exists
	 */
	public saveGroups(teamToSave) {
		let teams = JSON.parse(localStorage.getItem("teams" + this.accountService.user.codeClasse));
		if (teamToSave.length > 1) {
			teamToSave = teamToSave.sort((a, b) => {
				if (Number(a) < Number(b)) {
					return -1;
				}
				return Number(a) > Number(b) ? 1 : 0;
			});
			if (Array.isArray(teams)) {
				const teamsExist = teams.map(team => {
					if (JSON.stringify(team) === JSON.stringify(teamToSave)) {
						return true;
					}

					return false;
				});
				const result = teamsExist.some(find => {
					return find;
				});
				if (!result) {
					// Not store more than 20 groups
					if (teams.length > 20) {
						teams.shift();
					}
					teams.push(teamToSave);
				}
			} else {
				teams = new Array();
				teams.push(teamToSave);
			}
			this.accountService.teams = teams;
			localStorage.setItem("teams" + this.accountService.user.codeClasse, JSON.stringify(teams));
		}
	}

	async launchActivity($event) {
		if (this.accountService.team.length > 0 && !this.debounce) {
			this.debounce = true;
			try {
				this.audioService.playSelectSound();
				this.playTTS.killSpeech();
				this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
				this.globalService.setGlobalLoading(true);
				this.lmsService.allJourneysByInProgressStatus = new Array();
				const teamToSave = new Array();
				this.accountService.team.forEach((data, index) => {
					teamToSave.push(data.id);
				});

				localStorage.setItem("team", JSON.stringify(teamToSave));
				if (this.accountService.user.codeClasse) {
					this.saveGroups(teamToSave);
				}
				// this.lrsService.statement.setActor(this.accountService.team);
				await this.manageRedirections();
				this.playTTS.killSpeech();
			} catch (error) {
				console.error(error);
			} finally {
				this.debounce = false;
			}
		}
	}

	async manageRedirections() {
		if (this.accountService.team.length === 1) {
			try {
				await this.accountService.updateUserData(this.accountService.team[0].id);
			} catch (err) {
				console.error("err recupération des storys", err);
			}

			if (this.accountService.isRealUserConnected) {
				this.router.navigateByUrl("/accueil");
			} else {
				this.router.navigateByUrl("/gabarits");
			}
		} else {
			this.router.navigateByUrl("/gabarits");
		}
	}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	async timeOut(ms, callback: any = null) {
		return new Promise<void>(resolve =>
			setTimeout(() => {
				if (callback) {
					callback();
				}
				resolve();
			}, ms)
		);
	}

	ionViewWillLeave() {
		this.playTTS.killSpeech();
		this.globalService.onActivityParticipantsPage = false;
	}
}
