<div class="containerRewards">
  <div class="containerCompetencies">
    <div class="bodyHUDContainerRewards"
      [ngClass]="{'bodyHUDContainerRewardsDesktop': globalService.isDesktop, 'bdyHUDContainerRewardsMT' : !globalService.isDesktop}">
      <ion-col size="12" class="gridContainer bodyRewards">

        <div class="bodyContainerHeaderMT"
          [ngClass]="{'bodyContainerHeaderMobile': globalService.isMobile,'bodyContainerHeaderTablet': globalService.isTablet,'bodyContainerHeaderDesktop':globalService.isDesktop}">

          <ion-col size="3.83" class="headerSelectionCol">
            <div class="mapSelectionMT squareAventuresMapActive selectedMenuItem"
              (click)="selectedMap($event,BadgeType.item)">
              <div class="textAdventure"></div>
              <p class="textMap textMapMT" i18n>Proto-carte aventure</p>
            </div>
          </ion-col>

          <ion-col size="3.83" class="headerSelectionCol">
            <div class="mapSelectionMT squareCompetences squareCompetencesActive"
              (click)="selectedMap($event,BadgeType.skill)">
              <p class="textMap textMapMT" i18n>Proto-carte compétences</p>
            </div>
          </ion-col>

          <ion-col size="3.83" class="headerSelectionCol">
            <div class="mapSelectionMT squareIA404Map squareIA404MapActive"
              (click)="selectedMap($event,BadgeType.award)">
              <div class="text404"></div>
              <p class="textMap textMapMT" i18n>Proto-carte expérience</p>
            </div>
          </ion-col>

          <ion-col size="0.49">
            <div class="closeBtn" (click)="goBack()">
              <div class="modalHeadercloseButtonWrapper"
                [ngClass]="globalService.isMobile ? 'modalHeadercloseButtonWrapperMobile': 'modalHeadercloseButtonWrapperTD'">
                <div class="modalHeadercloseButtonContainer">
                  <ion-icon class="modalHeadercloseButton" name="close-outline">
                  </ion-icon>
                </div>
              </div>
            </div>
          </ion-col>
        </div>


        <ion-row
          [ngClass]="{'bodyContentRowMobile' :globalService.isMobile, 'bodyContentRowTablet':!globalService.isDesktop, 'bodyContentRowDesktop': globalService.isDesktop}">
          <ion-col size="12" class="mapsRewards"
            [ngClass]="{'mapsRewardsMobile' : globalService.isMobile, 'mapsRewardsTD':!globalService.isMobile}">
            <ion-row class="cardBody scrollbar-primary">
              <ng-container *ngFor="let cardReward of cardsRewards;let i = index;">
                <ion-col size="2" size-xl="2" size-sm="3" size-xs="4"
                  class="eachCard">
                  <ng-container *ngIf="cardReward && cardReward.unlocked">
                    <ion-img  class="card-img" [src]="cardReward.picture" (ionError)="changeSource(cardReward)" (click)="selectCard(cardReward.picture)"></ion-img>
                  </ng-container>

                  <ng-container *ngIf="cardReward && !cardReward.unlocked">
                    <div class="lockedCard">
                      <img src="/assets/img/hud/tableau-recompenses/cadenas.png" class="cadenasImg" alt="">
                      <ion-img  class="card-img-locked" [src]="cardReward.picture" (ionError)="changeSource(cardReward)"></ion-img>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!cardReward">
                    <img src="assets/img/hud/tableau-recompenses/map_1_locked.png" class="card-img" />
                  </ng-container>
                </ion-col>
              </ng-container>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </div>
  </div>
</div>





<div class="mapFullScreenWrapper" (click)="rewardType.fullScreenCardDisplay = null"
  *ngIf="rewardType.fullScreenCardDisplay">
  <div class="mapFullScreenContainer">
    <ion-row class="mapFullScreen mapCompetencyFullScreen">

      <img class="cardImgFullScreen" [src]="rewardType.fullScreenCardDisplay" alt="">
    </ion-row>
  </div>
</div>