import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";

@Component({
	selector: "app-starboard",
	templateUrl: "./starboard.component.html",
	styleUrls: ["./starboard.component.scss"]
})
// @Directive({
//   selector: '[units]'
// })
export class StarboardComponent implements OnInit, OnDestroy {
	constructor() {}
	@Input() units: number;
	@Input() type: string;
	@Input() events: Observable<void>;
	@Input() light?: boolean;
	@Input() changeDetector: ChangeDetectorRef;
	// types == shootingStar / normalStar / moon
	private eventsSubscription: Subscription;

	singleStarsNumber = 0;
	fiveStarsNumber = 0;
	tenStarsNumber = 0;

	ngOnInit() {
		// this.sortStars(this.units);
		this.eventsSubscription = this.events.subscribe(() => {
			//   console.log('event STARS 2 : '+ this.units + ' ' + this.type);
			if (!this.light) {
				this.sortStars(this.units);
			}
		});
	}

	sortStars(num) {
		// 0 -- 5
		if (num < 6) {
			this.tenStarsNumber = 0;
			this.fiveStarsNumber = 0;
			this.singleStarsNumber = num;
		} else if (num === 6 && num < 7) {
			this.FiveStarsObtainedAnimation();
			this.tenStarsNumber = 0;
			this.fiveStarsNumber = 1;
			this.singleStarsNumber = 1;
		} else if (num > 6 && num < 11) {
			this.tenStarsNumber = 0;
			this.fiveStarsNumber = 1;
			this.singleStarsNumber = num - 5;
		} else if (num === 11 && num < 12) {
			this.TenStarsObtainedAnimation();
			this.tenStarsNumber = 0;
			this.fiveStarsNumber = 2;
			this.singleStarsNumber = 1;
		} else if (num > 11 && num < 16) {
			this.tenStarsNumber = 0;
			this.fiveStarsNumber = 2;
			this.singleStarsNumber = num - 10;
		} else if (num === 16 && num < 17) {
			this.TenStarsObtainedAnimation();
			this.tenStarsNumber = 1;
			this.fiveStarsNumber = 1;
			this.singleStarsNumber = 1;
		} else if (num > 16 && num < 21) {
			this.tenStarsNumber = 1;
			this.fiveStarsNumber = 1;
			this.singleStarsNumber = num - 15;
		} else if (num === 21 && num < 22) {
			this.tenStarsNumber = 1;
			this.fiveStarsNumber = 2;
			this.singleStarsNumber = 1;
		} else if (num > 21 && num < 26) {
			this.tenStarsNumber = 1;
			this.fiveStarsNumber = 2;
			this.singleStarsNumber = num - 20;
		} else if (num > 25) {
			this.tenStarsNumber = 1;
			this.fiveStarsNumber = 2;
			this.singleStarsNumber = 5;
		}
		if (this.changeDetector) {
			this.changeDetector.detectChanges();
		}
	}

	FiveStarsObtainedAnimation() {
		// anim here
	}

	TenStarsObtainedAnimation() {
		// anim here
	}

	endAnimation() {
		// anim here
	}

	arrayOne(n: any): any[] {
		n = Number(n + "");
		return Array(n);
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
		this.changeDetector = null;
		console.log("this.eventsSubscription.unsubscribed from starboard component");
	}
}
