
<div class="globalContainer">

  <div class="mascotteCM mascotteAnimation" *ngIf="show"></div>
  
  <div class="award-main-wrapper" [ngClass]="[globalService.isMobile? 'award-main-wrapper-mobile':'']" >
    
    <!-- SPEECH -->
    <div class="bubble-help" *ngIf="message">
        <div class="message-wrapper">
            <p> {{ message }}</p>
        </div>
    </div>

    <div [ngClass]="[globalService.isMobile? 'validationButtonsContainerMobile':'validationButtonsContainer']" *ngIf="show && choice && choice.visible">
      <div *ngFor="let item of choice.items" class="customButtonValidation" (click)="answerResponse(item.value)">{{ item.label }}</div>
    </div>

  </div>

  
</div>