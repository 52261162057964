import { Component, OnInit, Input } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Student } from "src/app/models/student";
import { GlobalService } from "src/app/services/global.service";
import { AudioService } from "src/app/services/audio.service";

@Component({
	selector: "app-modal-activity-participants",
	templateUrl: "./modal-activity-participants.component.html",
	styleUrls: ["./modal-activity-participants.component.scss"]
})
export class ModalActivityParticipantsComponent implements OnInit {
	@Input() students: Array<any>;
	@Input() team: Array<any>;
	@Input() teamsCopy: Array<any>;
	@Input() teams: Array<any>;
	@Input() maxPlayer: number;
	@Input() keepTeam: boolean;
	@Input() teamAlreadyFull = false;
	public showStudents = true;
	studentParticipate = new Array();
	public student: Student;

	constructor(
		public modalCtrl: ModalController,
		public accountService: AccountService,
		public platform: Platform,
		public cabriDataService: CabriDataService,
		public globalService: GlobalService,
		public audioService: AudioService
	) {}

	ngOnInit() {}

	ionViewWillEnter() {}

	tabs(type, $event) {
		this.audioService.playSelectSound();
		this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.showStudents = type === "students";
	}

	/**
	 * Add a group containing multiple students
	 * @param teamSelected
	 */
	selectCurrentTeam(teamSelected) {
		const studentSelectedFromGroup = new Array();
		this.students.forEach(student => {
			teamSelected.forEach(currentTeam => {
				if (student.id === currentTeam.teamId) {
					student.selected = true;
					studentSelectedFromGroup.push(student);
				}
			});
		});

		this.modalCtrl.dismiss({
			dismissed: true,
			team: this.team,
			studentSelectedFromGroup
		});
	}

	addStudent(student: Student) {
		this.audioService.playSelectSound();
		if (this.team.length < this.maxPlayer) {
			if (!this.keepTeam) {
				student.selected = true;
			}
			this.student = student;
			this.team.push(student);
		} else {
			this.teamAlreadyFull = true;
		}

		this.modalCtrl.dismiss({
			dismissed: false,
			team: this.team,
			student,
			teamAlreadyFull: this.teamAlreadyFull
		});
	}

	dismiss($event) {
		this.audioService.playCancelSound();
		this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.modalCtrl.dismiss({
			dismissed: true,
			team: this.team,
			student: this.student
		});
	}
}
