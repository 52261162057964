import { Component, OnInit, Input } from '@angular/core';
import { CabriDataService } from "src/app/services/cabri-data.service";

@Component({
  selector: 'app-spash-screen-tuto',
  templateUrl: './spash-screen-tuto.component.html',
  styleUrls: ['./spash-screen-tuto.component.scss'],
})
export class SpashScreenTutoComponent implements OnInit {

  @Input() htmlContent: string;

  constructor(public cabriService: CabriDataService) { }

  ngOnInit() {}

}
