import { LmsService } from "../services/lms.service";
import { Classroom } from "./classroom";
import { Grade } from "./exercices";
import { journeyStatus } from "./journey";
export enum Role {
	student = "learner",
	teacher = "trainer"
}
export class Student {
	name: string;
	classe: Classroom;
	id: string;
	selected: boolean;
	selectedTemp: boolean;
	parentalConsent: boolean;
	guest: false;
	level: Grade = Grade.cp;
	coords;
	picture: string = null;
	awardsCurrent: { shooting: number; normal: number; moons: number; total: number };
	statisticsCurrent: { shooting: number };
	// idea of persistent awards to propose long time monitoring of their progression to students (+transforming awards to something?)
	// awardsPersistent: { shooting: number, normal: number, moons: number, total: number };
	playerId: number;
	uid: string;
	preview : boolean;


	// history inside current exercise
	answers: Array<{ award: string; answer: boolean }>;

	constructor(
		id: string,
		classe: Classroom,
		name: string,
		selected: boolean = false,
		selectedTemp = false,
		playerId?: number,
		uid: string = null
	) {
		this.id = id;
		this.classe = classe;
		this.name = name;
		this.selected = selected;
		this.selectedTemp = selectedTemp;
		this.awardsCurrent = { shooting: 0, normal: 0, moons: 0, total: 0 };
		// this.awardsPersistent = { shooting: 0, normal: 0, moons: 0, total: 0 };
		// // TODO this.awardsPersistent = from LS ?
		this.playerId = playerId;
		this.uid = uid;
		this.answers = new Array();
	}

}
