import {
	Component,
	OnInit,
	ViewChild,
	ElementRef,
	OnDestroy,
	Renderer2,
	Input,
	ViewChildren,
	QueryList,
	Output,
	EventEmitter
} from "@angular/core";

import { WordSearch } from "./model/WordSearch";
import { WordSearchSolver } from "./model/WordSearchSolver";
import { GridPosition } from "./model/GridPosition";
import { Grid } from "./model/Grid";
import { integerSequence } from "./model/helpers";
import { easeInOutCubic } from "./shared/easing";
import { AppUtils } from "src/app/app-utils";
import { GlobalService } from "src/app/services/global.service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { OseActivity } from "src/app/models/ose-activities";
import { OseExerciceType } from "src/app/models/ose2-journey";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Platform } from "@ionic/angular";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { Statement } from "src/app/models/statement";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { AccountService } from "src/app/services/account.service";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { LrsService } from "src/app/services/lrs.service";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import { Router } from "@angular/router";

interface CanvasPoint {
	x: number;
	y: number;
}

class CanvasLineAnimation {
	private beginTime: number;
	private delta: CanvasPoint;
	constructor(
		private context: CanvasRenderingContext2D,
		private from: CanvasPoint,
		private to: CanvasPoint,
		private durationMs: number = 2000
	) {
		this.delta = {
			x: to.x - from.x,
			y: to.y - from.y
		};
	}

	public begin() {
		this.beginTime = null;
		this.requestNextFrame();
	}

	private requestNextFrame() {
		window.requestAnimationFrame(t => this.step(t));
	}

	private step(presentTime: number) {
		if (!this.beginTime) {
			this.beginTime = presentTime;
		}
		const elapsedMs = presentTime - this.beginTime;
		const proportion = Math.min(1, elapsedMs / this.durationMs);
		const d = easeInOutCubic(proportion);
		const dc = this.context;
		dc.beginPath();
		dc.moveTo(this.from.x, this.from.y);
		dc.lineTo(this.from.x + d * this.delta.x, this.from.y + d * this.delta.y);
		dc.stroke();
		if (proportion < 1) {
			this.requestNextFrame();
		}
	}
}

@Component({
	selector: "app-word-search",
	templateUrl: "./word-search.component.html",
	styleUrls: ["./word-search.component.scss"]
})
export class WordSearchComponent implements OnInit, OnDestroy{
	@Input() scenarioBubble: ScenarioOseBubble;
	private wordSearch: WordSearch;
	private solver: WordSearchSolver;

	private _inputWords: string[];
	private _insertedWords: string[];
	private _pendingWords: string[];
	private _discoveredWords: string[];

	private selectedPosition: GridPosition;
	private hintPosition: GridPosition;
	private discoveryGrid: Grid<boolean>;
	@Output() dynamicConsigne: EventEmitter<string> = new EventEmitter();
	private _width: number;
	private _height: number;
	private _rows: number[];
	private _columns: number[];

	private _showPendingWords: boolean;
	private _revealedPendingWords: boolean;
	private _hintCount: number;

	@ViewChild("gridTable") gridTableRef: ElementRef<HTMLTableElement>;
	@ViewChild("gridCanvas") gridCanvasRef: ElementRef;
	@ViewChild("canvasStroke", { static: false }) canvasStrokeRef: ElementRef;
	@ViewChildren("letterBlock") letterBlock: QueryList<ElementRef<HTMLDivElement>>;
	private canvasStrokeColor: string;
	private canvasSize: number;
	private gridTileDisplaySize: { width: number; height: number };
	private drawContext: CanvasRenderingContext2D;
	resizeSubscription: Subscription;
	oldDisplaySize: { width: number; height: number };
	environment: {
		production: boolean;
		activityVersion: number;
		storyVersion: number;
		kidaia: boolean;
		bearerToken: string;
		ose: boolean;
		autolog: boolean;
		login: any;
		password: any;
	};
	currentWordSearch: any;
	difficulty = 2;
	observer: ResizeObserver;
	public solved = false;
	lineSize: number;

	constructor(
		private globalService: GlobalService,
		private oseJourneyService: OseJourneyService,
		public cabriService: CabriDataService,
		private platform: Platform,
		public renderer: Renderer2,
		public accountService: AccountService,
		public lrs: LrsService,
		public router: Router
	) {
		this.environment = environment;
		this.oseJourneyService.launchExercise.subscribe({
			next: (data: OseActivity) => {
				if (data.type === OseExerciceType.wordSearch) {
					this.startGame(data.id);
				}
			}
		});
		this.globalService.activityRunningName = OseExerciceType.wordSearch;

		this.selectedPosition = null;
		this.hintPosition = null;
		this._showPendingWords = true;
		this._hintCount = 0;
		this.observer = new ResizeObserver(entries => {
			this.updateCanvasSize(entries[0].contentRect);
		});


		this.setSize(9, 6);
		// (window as any).sw= this;
	}
	async loadCurrentWordSearch() {
		await this.oseJourneyService.journeysLoaded();
		await this.cabriService.getAllWordSearch();
		this.platform.ready().then(() => {
			this.startGame(this.oseJourneyService.getCurrentExerciseId());
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.observer.unobserve(this.table);
	}

	async startGame(idWordSearch: number) {
		this.solved = false;
		if (idWordSearch) {
			this.currentWordSearch = this.cabriService.wordSearchs.find(ws => Number(ws.id) === Number(idWordSearch));
			if(this.dynamicConsigne){
				this.dynamicConsigne.next(this.currentWordSearch.consigne)
			}
		} else {
			this.globalService.setGlobalLoading(false);
		}
		if (this.currentWordSearch) {
			this.cabriService.currentOseActivity = {
				id: this.currentWordSearch.id,
				title: this.currentWordSearch.title,
				type: OseExerciceType.wordSearch
			};

			if (!this.currentWordSearch) {
				this.currentWordSearch = { list: [] };
			}

			// false journey to debug
			await this.oseJourneyService.getAllActivities();
			await this.cabriService.getTooltipHelps();

			// search words in current journeys "fiches"
			if (this.oseJourneyService.currentJourney) {
				this.oseJourneyService.tooltipsForWordSearch.forEach(element => {
					if (this.currentWordSearch.list.every(help2 => !this.isSameWord(element, help2))) {
						this.currentWordSearch.list.push(element);
					}
				})
			}

			this.initCanvas();
			this.generate(this.currentWordSearch.list);
			await this.cabriService.cabriLoader.waitEndOfLoading();
			const statement = this.startActivityStatement();
			if (statement) {
				this.lrs.send(statement);
			}
			this.observer.disconnect();
			this.observer.observe(this.table);
			this.globalService.setGlobalLoading(false);
			await this.scenarioBubble.readCustomText(
				$localize`Clique sur la première lettre puis sur la dernière lettre d'un mot que tu as trouvé.`,
				true
			);
			await this.scenarioBubble.readCustomText(
				$localize`Tu peux aussi cliquer sur les mots dans la liste à droite pour avoir un indice.`,
				true
			);
			if (this.currentWordSearch.consigne) {
				await this.scenarioBubble.readCustomText(this.currentWordSearch.consigne, true);
			}
			await this.scenarioBubble.readCustomText($localize`C'est parti !`, true);
		}
	}
	private initCanvas(): void {
		const strokeRef = this.canvasStrokeRef.nativeElement as Element;
		this.canvasStrokeColor = window.getComputedStyle(strokeRef).color;
		this.drawContext = this.canvas.getContext("2d");
		this.drawContext.globalCompositeOperation = "xor";
		this.drawContext.globalAlpha = 0.2;
		if (!this.drawContext) {
			console.log("Could not get the canvas rending context");
			return;
		}
	}
	private updateLineSize() {
		this.lineSize = Math.min(
			parseInt(getComputedStyle(this.letterBlock.first.nativeElement).fontSize) * 1.5,
			this.table.querySelector(".grid-col").getBoundingClientRect().height - 4
		);
	}
	private updateCanvasSize(tableRect: DOMRectReadOnly) {
		if (tableRect.height !== 0 && tableRect.width !== 0) {
			this.updateLineSize();
			this.gridTileDisplaySize = {
				width: this.table.querySelector(".grid-col").getBoundingClientRect().width,
				height: this.table.querySelector(".grid-col").getBoundingClientRect().height
			};
			const gridDisplaySize = tableRect;
			this.canvasSize = gridDisplaySize.height;
			if (this.oldDisplaySize) {
				const canvasTemp = document.createElement("canvas");
				const ctxTemp = canvasTemp.getContext("2d");
				canvasTemp.height = this.canvas.height;
				canvasTemp.width = this.canvas.width;
				ctxTemp.drawImage(this.canvas, 0, 0);
				this.canvas.setAttribute("width", gridDisplaySize.width.toString());
				this.canvas.setAttribute("height", gridDisplaySize.height.toString());
				const factorX = gridDisplaySize.width / this.oldDisplaySize.width;
				const factorY = gridDisplaySize.height / this.oldDisplaySize.height;
				this.drawContext.scale(factorX, factorY);
				this.drawContext.drawImage(canvasTemp, 0, 0);
				this.drawContext.resetTransform();
			} else {
				this.canvas.setAttribute("width", gridDisplaySize.width.toString());
				this.canvas.setAttribute("height", this.canvasSize.toString());
			}
			this.oldDisplaySize = { width: gridDisplaySize.width, height: gridDisplaySize.height };
		}
	}
	private isSameWord = (w1, w2) => {
		return w1 === w2 || w1 + "s" === w2 || w1 === w2 + "s";
	};

	private get canvas(): HTMLCanvasElement {
		return this.gridCanvasRef.nativeElement;
	}

	private get table(): HTMLTableElement {
		return this.gridTableRef.nativeElement;
	}

	private toCanvasPoint(position: GridPosition): CanvasPoint {
		return {
			x: position.x * this.gridTileDisplaySize.width + (position.x - 1) + this.gridTileDisplaySize.width / 2,
			y: this.canvasSize - (position.y * this.gridTileDisplaySize.height + this.gridTileDisplaySize.height / 2)
		};
	}

	private drawLine(from: GridPosition, to: GridPosition) {
		this.updateLineSize();

		const dc = this.drawContext;
		dc.strokeStyle = this.canvasStrokeColor;
		dc.globalCompositeOperation = "xor";
		dc.globalAlpha = 0.2;
		dc.lineCap = "round";
		dc.lineWidth = this.lineSize;
		this.gridTileDisplaySize = {
			width: this.table.querySelector(".grid-col").getBoundingClientRect().width,
			height: this.table.querySelector(".grid-col").getBoundingClientRect().height
		};
		const animation = new CanvasLineAnimation(dc, this.toCanvasPoint(from), this.toCanvasPoint(to));
		animation.begin();
	}

	private setSize(width: number, height: number): void {
		this._width = width;
		this._height = height;

		this.discoveryGrid = new Grid<boolean>(this._width, this._height);
		this.discoveryGrid.setAll(() => false);

		this._columns = integerSequence(this._width);
		this._rows = integerSequence(this._height).reverse();
	}

	public clearCanvas() {
		this.drawContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
	}
	public generate(newWords?: string[]): void {
		this.clearCanvas();
		this.discoveryGrid.setAll(() => false);

		this.wordSearch = new WordSearch(this._width, this._height);
		console.log(this.wordSearch);
		this.solver = new WordSearchSolver(this.wordSearch);
		console.log(this.solver);

		if (newWords) {
			this._inputWords = [...newWords];
		}
		// shuffle array
		AppUtils.shuffleArray(this._inputWords);
		this._insertedWords = this.wordSearch.generate(this._inputWords);

		this._pendingWords = [...this._insertedWords];
		this._discoveredWords = [];

		this._revealedPendingWords = this.showPendingWords;
		this._hintCount = 0;
	}

	public createNewWordSearch(): void {
		console.error("createNewWordSearch");
		// const dialogRef = this.dialog.open(NewWordSearchDialogComponent, {
		//   data: {
		//     width: this._width,
		//     height: this._height,
		//     words: this._inputWords,
		//   },
		// });

		// dialogRef.afterClosed().filter(result => !!result)
		// .subscribe(result => {
		//   this.setSize(result.width, result.height);
		//   this.generate(result.words);
		// });
	}

	public get rows(): number[] {
		return this._rows;
	}

	public get columns(): number[] {
		return this._columns;
	}

	public get insertedWords(): string[] {
		return this._insertedWords;
	}

	public get pendingWords(): string[] {
		return this._pendingWords;
	}

	public get discoveredWords(): string[] {
		return this._discoveredWords;
	}

	public get showPendingWords(): boolean {
		return !!this._showPendingWords;
	}

	public set showPendingWords(show: boolean) {
		this._revealedPendingWords = true;
		this._showPendingWords = show;
	}

	public get revealedPendingWords(): boolean {
		return this._revealedPendingWords;
	}

	public get hintCount(): number {
		return this._hintCount;
	}

	public letter(row: number, column: number): string {
		if (this.wordSearch) {
			return this.wordSearch.letter(column, row);
		} else return "";
	}

	public discoverWord(word: string): boolean {
		const i = this.pendingWords.findIndex(s => s.toUpperCase() === word.toUpperCase());
		if (i < 0) {
			return false;
		}
		const [w] = this._pendingWords.splice(i, 1);
		this._discoveredWords.push(w);
		return true;
	}

	public async select(row: number, column: number) {
		const start = this.selectedPosition;
		if (start) {
			this.selectedPosition = null;
			const end = new GridPosition(column, row);
			const word = this.wordSearch.extract(start, end);
			console.log("selected:", word);
			if (word) {
				if (this.discoverWord(word)) {
					console.log("found:", word);
					this.markDiscovered(start, end);

					await this.scenarioBubble.readCustomText("Oui ! Bravo !", true);
					const help = this.cabriService.getTooltipHelp(word);
					if (help && help.feedback) {
						await this.scenarioBubble.readHTML(help.feedback, true);
					}
					if (this.isComplete()) {
						this.solved = true;
						const feedback = this.currentWordSearch?.feedback || $localize`Tu as réussi à trouver tous les mots`;
						await this.scenarioBubble.readText(feedback, false,true);
						await this.scenarioBubble.readCustomText(
							$localize`Appuie sur le bouton en bas à droite pour passer à la prochaine activité`,
							true
						);
						// this.showComplete();
					}
				} else {
					this.simpleSnackBar(`Sorry, but ${word} is not one of the words to find.`);
				}
			}
		} else {
			this.selectedPosition = new GridPosition(column, row);
		}
	}

	public isComplete(): boolean {
		return this._pendingWords.length === 0;
	}

	public markDiscovered(start: GridPosition, end: GridPosition): void {
		this.discoveryGrid.forEach(start, end, (_, pos) => {
			this.discoveryGrid.set(pos, true);
		});

		this.drawLine(start, end);
	}

	public revealHint(word: string): void {
		if (this.hintPosition) {
			return; // Another hint has yet to disappear.
		}
		const config = this.solver.find(word);
		if (config) {
			this._hintCount++;
			this.setHint(config.startingPosition);
			AppUtils.timeOut(3000).then(() => this.setHint(null));
		} else {
			this.simpleSnackBar(`Whoops!  I could not find ${word}.`);
		}
	}

	private setHint(position: GridPosition): void {
		this.hintPosition = position;
	}

	public blockClasses(row: number, column: number): object {
		return {
			selected: this.isSelected(row, column),
			discovered: this.isDiscovered(row, column),
			hinting: this.isHinting(row, column)
		};
	}

	private isSelected(row: number, column: number): boolean {
		return this.selectedPosition && this.selectedPosition.equalsXY(column, row);
	}

	private isDiscovered(row: number, column: number): boolean {
		return this.discoveryGrid.get(new GridPosition(column, row));
	}

	private isHinting(row: number, column: number): boolean {
		const p = this.hintPosition;
		return p && p.equalsXY(column, row);
	}

	private simpleSnackBar(message: string): void {
		console.error("snackbar", message);
		// this.snackBar.open(message, '', {
		//   duration: 5e3,
		// });
	}
	changeDifficulty(difficulty: number) {
		this.difficulty = difficulty;
		// console.log(Number(window.innerWidth / 50), Number(window.innerHeight / 50));
		switch (difficulty) {
			case 1:
				this.setSize(6, 6);
				break;
			case 2:
				this.setSize(9, 7);
				break;
			case 3:
				this.setSize(
					Math.max(12, Math.round((window.document.querySelector("#grid") as HTMLElement).offsetWidth / 75)),
					Math.max(9, Math.round((window.document.querySelector("#grid") as HTMLElement).offsetHeight / 40))
				);
				break;
		}
		this.generate();
	}
	async nextGame() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
		if (this.oseJourneyService.currentJourney) {
			this.oseJourneyService.tooltipsForWordSearch = [];
			const endStatement = this.endActivityStatement();
			this.lrs
				.send(endStatement)
				.then(() => {
					this.oseJourneyService.exerciseEnd.next();
				})
				.catch(error => {
					console.error("error last statement not send", error);
					this.oseJourneyService.exerciseEnd.next(true);
				});
		} else {
			this.prevNextPage(true);
		}
	}

	async prevPage() {
		if (!this.oseJourneyService.currentJourney) {
			if (this.currentWordSearch) {
					this.prevNextPage(false);
			}
		} else {
			await this.scenarioBubble?.skipMathiaSpeechSequence(true);
			this.oseJourneyService.exercisePrev.next();
		}
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.currentWordSearch.id)}`,
				this.currentWordSearch.title,
				this.currentWordSearch.theme || this.currentWordSearch.title,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */
	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.currentWordSearch.id)}`,
				this.currentWordSearch.title,
				this.currentWordSearch.theme || this.currentWordSearch.title,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});
			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	debug() {
		this.drawLine(new GridPosition(0, 0), new GridPosition(0, 5));
		this.drawLine(new GridPosition(1, 0), new GridPosition(1, 4));
		this.drawLine(new GridPosition(2, 0), new GridPosition(2, 3));
		this.drawLine(new GridPosition(3, 0), new GridPosition(3, 2));
		this.drawLine(new GridPosition(4, 0), new GridPosition(4, 1));
		this.drawLine(new GridPosition(5, 0), new GridPosition(5, 0));
		this.drawLine(new GridPosition(6, 0), new GridPosition(6, 5));
		this.drawLine(new GridPosition(7, 0), new GridPosition(7, 6));
		this.drawLine(new GridPosition(8, 0), new GridPosition(8, 7));
		this.drawLine(new GridPosition(9, 0), new GridPosition(9, 8));
		this.drawLine(new GridPosition(10, 0), new GridPosition(10, 9));
		this.drawLine(new GridPosition(11, 0), new GridPosition(11, 10));
		this.drawLine(new GridPosition(12, 0), new GridPosition(12, 11));
		this.drawLine(new GridPosition(13, 0), new GridPosition(13, 12));
		this.drawLine(new GridPosition(14, 0), new GridPosition(14, 13));
		this.drawLine(new GridPosition(15, 0), new GridPosition(15, 14));
		this.drawLine(new GridPosition(16, 0), new GridPosition(16, 15));
		this.drawLine(new GridPosition(17, 0), new GridPosition(17, 16));
	}
	debug2() {
		this.drawLine(new GridPosition(0, 0), new GridPosition(1, 0));
		this.drawLine(new GridPosition(0, 1), new GridPosition(2, 1));
		this.drawLine(new GridPosition(0, 2), new GridPosition(3, 2));
		this.drawLine(new GridPosition(0, 3), new GridPosition(4, 3));
		this.drawLine(new GridPosition(0, 4), new GridPosition(5, 4));
		this.drawLine(new GridPosition(0, 5), new GridPosition(6, 5));
		this.drawLine(new GridPosition(0, 6), new GridPosition(7, 6));
		this.drawLine(new GridPosition(0, 7), new GridPosition(8, 7));
		this.drawLine(new GridPosition(0, 8), new GridPosition(9, 8));
		this.drawLine(new GridPosition(0, 9), new GridPosition(10, 9));
		this.drawLine(new GridPosition(0, 10), new GridPosition(11, 10));
		this.drawLine(new GridPosition(0, 11), new GridPosition(12, 11));
		this.drawLine(new GridPosition(0, 12), new GridPosition(13, 12));
		this.drawLine(new GridPosition(0, 13), new GridPosition(14, 13));
		this.drawLine(new GridPosition(0, 14), new GridPosition(15, 14));
	}

	prevNextPage(next: boolean) {
		const currIndex = this.cabriService.wordSearchs.findIndex(wordSearch => wordSearch.id === this.currentWordSearch.id);
		let direction = next ? 1 : -1;
		if (currIndex > -1 && this.cabriService.wordSearchs[currIndex + direction]) {
			this.startGame(this.cabriService.wordSearchs[currIndex + direction].id);
		} else {
			let reStartIndex = next ? 0 : this.cabriService.wordSearchs.length - 1;
			this.startGame(this.cabriService.wordSearchs[reStartIndex].id);
		}
	}

	async findTooltipsInJourney(){
		const j = this.oseJourneyService.currentJourney;
		const tooltips = [];
		for (const f of j.exercises) {
			if (f.type === OseExerciceType.fiche && !f.isEtape) {
				const ficheHtml = await this.cabriService.getFiche(f.id);
				const ficheElement = this.renderer.createElement("div");
				ficheElement.innerHTML = ficheHtml;
				ficheElement.querySelectorAll("strong").forEach((element: HTMLElement) => {
					const help = this.cabriService.getTooltipHelp(element.innerHTML);
					if (help && tooltips.every(help2 => !this.isSameWord(help.title, help2))) {
						console.log(help);
						tooltips.push(help.title);
					}
				});
			}
		}
	}
}
