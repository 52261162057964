<div class="global" [ngClass]="{'isTablet': globalService.isTablet, 'isMobile': globalService.isMobile, 'dyslexicFont': dyslexicMode }">
    <!-- pads -->
    <div class="pads-row pointer-events-all" *ngIf="displayedPadChoice">
      <ng-container *ngFor="let choice of displayedPadChoice; let i = index;">
        <div class="col-1 {{choice.status}}">
          <!-- button -->
          <div id="{{'button' + choice.id}}" class="button-quizz"
              [ngClass]="{'btn-selected' : choice.selected, 'show-results' : showResults}"
              (click)="updateQuizz(choice)">
            <div class="answer-img-container" *ngIf="choice.image">
              <img [src]="choice.image" class="answer-img"/>
            </div>
            <div class="separation-line" *ngIf="choice.image"></div>
            <div class="answer-text-container">
              <p class="text" id="{{'text' + choice.id}}">{{choice.text}}</p>
            </div>
          </div>
          <div class="icon-top icon-error" *ngIf="choice.status === 'error' && showResults"></div>
          <div class="icon-top icon-valid" *ngIf="choice.status === 'valid' && showResults"></div>
          <div class="icon-top icon-missing" *ngIf="choice.status === 'missing' && showResults"></div>
        </div>
      </ng-container>
    </div>
    <!-- action buttons -->
    <div class="action-button-row pointer-events-all">
      <!-- <div class="colDelete">
        <div class="customButtonText" (click)="clearPads('clear')" id="buttonclear">
          <span class="textButtonFont" id="buttonclearp"
            i18n="erase numberResponse|erases the answer the user typed on the numpad@@eraseNumberResponse">
            Effacer
          </span>
        </div>
      </div> -->
      <div class="col-validate">
        <div class="btn-validate activateButton" [ngClass]="{'enter-clicked' : enterClicked, 'btn-validate__showing' : !validated && atLeastOneChoiceSelected}" (click)="sendAnswer('enter')" id="buttonenter">
          <!-- <span class="textButtonFont"
            i18n="validate numberResponse|validates the answer the user typed on the numpad@@validateNumberResponse">
            Valider
          </span> -->
          <div id="btn-validate-image" #validateLogo></div>
          <!-- <ion-img [src]="'../../../assets/quizz/quizz-pad/v-logo.png'" class="quizzImg" #validateLogo></ion-img> -->
        </div>
      </div>
    </div>
</div>
