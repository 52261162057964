import { ChangeDetectorRef, Directive, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Insomnia } from "@awesome-cordova-plugins/insomnia/ngx";
import { AlertController, Platform } from "@ionic/angular";
import { BehaviorSubject, lastValueFrom, Subject } from "rxjs";
import { Classroom } from "../../models/classroom";
import { LrsUtils } from "../../models/lrs/lrsUtils";
import { Student } from "../../models/student";
import { AccountService } from "../../services/account.service";
import { CabriDataService } from "../../services/cabri-data.service";
import { GlobalService } from "../../services/global.service";
import { LrsService } from "../../services/lrs.service";
import { PlayTTSService } from "../../services/play-tts.service";
import { ToolbarBasePage } from "../cabri/toolbar.base.page";
import { cloneDeep } from "lodash";
@Directive()
export class GameBasePageDirective extends ToolbarBasePage implements OnDestroy {
	public currentUser: Student;
	public devMenuV2Opened = false;
	public teamReady = false;

	// user Team variable
	public currentUserId: number;
	public player1: Student;
	public player2: Student;
	public player3: Student;
	public player4: Student;
	public currentEliminatedPlayerId: number;
	public currentTeam: Student[];
	currentUserPlayerId: number;
	public finalSummary: boolean;
	answerSubscription: Subject<any>;

	questionAskedDuringSession = new Array();
	public activityName: string;

	constructor(
		public globalService: GlobalService,
		public ttsService: PlayTTSService,
		public cd: ChangeDetectorRef,
		public router: Router,
		public accountService: AccountService,
		public platform: Platform,
		public insomnia: Insomnia,
		public alertController: AlertController,
		public lrs: LrsService,
		public cabriService: CabriDataService
	) {
		super(globalService, ttsService, cd, router);
		this.answerSubscription = new Subject<any>();
	}

	public ionViewWillEnter() {
		this.globalService.onActivityPage = true;
		super.ionViewWillEnter();
		this.initTeam();
		if (this.scenario) {
			this.scenario.lockCallback = false;
		}
	}

	async ionViewDidEnter() {
		super.ionViewDidEnter();
		this.platform.ready().then(() => {
			if (this.globalService.isCordova) {
				this.globalService.forceNativeLandscapeMode();
				this.toggleSleepOnNativeDevices(false);
			}
		});
		this.globalService.killTTSEP = false;
	}

	public async ionViewWillLeave() {
		this.globalService.activityRunningName = null;
		this.globalService.onActivityPage = false;
		if (this.scenario) {
			this.scenario.lockCallback = true;
		}
		this.globalService.killTTSEP = true;
		this.globalService.toolbarMenuOpened.status = false;
		await this.ttsService.killSpeech();
		await this.initTeam();
		this.globalService.killTTSEP = false;
		this.globalService.mathiaSpeechRunning = false;
		if (this.globalService.isCordova) {
			this.globalService.orientationSwitched = false;
		}
		this.toggleSleepOnNativeDevices(true);
		super.ionViewWillLeave();
	}

	ngOnDestroy(): void {
		// throw new Error("Method not implemented.");
	}

	/**
	 * Initialize a temporary activity team from the accountService one and reset team score
	 * clones the accountService team to be able to eliminate users if in challenge mode
	 * sets the currentUserPlayerId to be used in nextPlayer() methods
	 */
	initTeam(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			await lastValueFrom(this.accountService.isUserLoaded);
			if (!this.accountService.studentsClassLoaded) {
				try {
					await this.accountService.getStudentsAndJourneys();
				} catch (err) {
					console.error("erreur récupération parcours et élèves");
				}
			}
			const currentTeam = new Array();
			if (
				typeof this.accountService.team === "undefined" ||
				this.accountService.team == null ||
				this.accountService.team.length == null ||
				this.accountService.team.length === 0
			) {
				if (this.accountService.team.length === 0) {
					if (this.globalService.modeProd) {
						const e1 = new Student("1", new Classroom("2222", null, null), "petit astronaute", true, false, 1);
						currentTeam.push(e1);
					} else {
						const e1 = new Student("29", new Classroom("2222", null, null), "Sam", true, false, 1);
						const e2 = new Student("90", new Classroom("2222", null, null), "Jim", true, false, 2);
						const e3 = new Student("34", new Classroom("2222", null, null), "Chris", true, false, 3);
						const e4 = new Student("48", new Classroom("2222", null, null), "Arsen", true, false, 4);
						currentTeam.push(e1, e2, e3, e4);
					}
					this.accountService.team = currentTeam;
				}
			}
			this.resetPlayersAndTeamScore();
			this.currentTeam = cloneDeep(this.accountService.team);
			LrsUtils.currentTeam = this.currentTeam;
			this.cabriService.progressionBarStatus.next(false);
			this.player1 = this.currentTeam ? this.currentTeam.filter(student => student.playerId === 1)[0] : null;
			this.player2 = this.currentTeam ? this.currentTeam.filter(student => student.playerId === 2)[0] : null;
			this.player3 = this.currentTeam ? this.currentTeam.filter(student => student.playerId === 3)[0] : null;
			this.player4 = this.currentTeam ? this.currentTeam.filter(student => student.playerId === 4)[0] : null;

			// set currentUser:
			if (this.currentTeam.find(student => student.playerId === 1)) {
				this.currentUser = this.currentTeam.find(student => student.playerId === 1);
				this.currentUserId = 1;
				this.currentUserPlayerId = 1;
				// (currentUserPlayerId is used to to be incremented on each round to switch currentUser in nextPlayer())
				LrsUtils.currentUser = this.currentUser;
			}
			// this.logTeamInfo();
			this.teamReady = true;
			resolve();
		});
	}

	/**
	 * waits the temporary team to be ready (waiting for async getStudentsAndJourneys() in initTeam())
	 */
	async waitTeamReady(): Promise<void> {
		return new Promise(async resolve => {
			while (!this.teamReady) {
				await this.timeOutNormal(100);
			}
			resolve();
		});
	}

	/**
	 * To visualize accountService.team & current team info in debugger mode
	 */
	logTeamInfo() {
		console.log("currentTeam = ", this.currentTeam);
		console.log("accountService.team = ", this.accountService.team);
		console.log("player1 = ", this.player1);
		console.log("player2 = ", this.player2);
		console.log("player3 = ", this.player3);
		console.log("player4 = ", this.player4);
	}

	/**
	 * To visualize accountService.team & currentTeam awards in debugger mode
	 * accountService.team's awards are persistent
	 * currentTeam's ones are not
	 * TODO => move awards from cabriService to accountService to finish the migration if needed
	 */
	logAwards() {
		this.accountService.team.forEach(student => {
			console.log(student.name + " cabriService awardsCurrent = ", student.awardsCurrent);
		});
		this.currentTeam.forEach(student => {
			console.log(student.name + " currentTeam awardsCurrent = ", student.awardsCurrent);
		});
		console.log("currentUser awardsCurrent = ", this.currentUser.awardsCurrent);
		console.log("team shootings = " + this.cabriService.teamShootingStarsCount);
		console.log("team normals = " + this.cabriService.teamNormalStarsCount);
		console.log("team moons = " + this.cabriService.teamMoonsCount);
		console.log("team total = " + this.cabriService.teamTotalAwards);
	}

	/**
	 * resets currentTeam's & players' awards in the activity
	 */
	resetPlayersAndTeamScore() {
		this.cabriService.resetTeamStudentsScore();
		this.cabriService.resetTeamScore();
	}

	// Kidaia alert receiver switch user
	async alertKidaiaDemandSwitchUser($event) {
		if ($event && $event.student) {
			this.accountService.switchKidaiaCurrentUser($event.student);
		} else {
			// Sécurity do not must be applied (close modal)
			this.globalService.fullPageConfirmationAlert = false;
		}
	}

	/**
	 * allow/disallow sleep mode on native devices
	 * @param status on/off
	 */
	toggleSleepOnNativeDevices(status: boolean) {
		if (this.platform.is("cordova")) {
			if (status) {
				//  restore sleepmode:
				this.insomnia.allowSleepAgain().then(
					res => {
						console.log(`'@ allowSleepAgain --> success'`);
					},
					err => {
						console.log("@ allowSleepAgain --> ERROR");
					}
				);
			} else {
				// disable sleep on native device:
				this.insomnia.keepAwake().then(
					res => {
						console.log(`'@ keepAwake --> success'`);
					},
					err => {
						console.log("@ keepAwake --> ERROR");
					}
				);
			}
		}
	}

	/**
	 * expand/minimize devMenuV2 which is in the toolbar (used in ose's quizz only for now)
	 */
	toggleDevMenuV2() {
		this.devMenuV2Opened = !this.devMenuV2Opened;
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	/**
	 * Normal setTimeOut - unprotected from page leave
	 */
	async timeOutNormal(ms, callback: any = null): Promise<void> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (callback) {
					callback();
				}
				resolve();
			}, ms);
		});
	}

	/**
	 * Global activity initialization shared by all activities
	 * -> to be overloaded in child pages but needs a super.call to init LRS
	 */
	async initActivity() {
		try {
			this.lrs.canSendCompletedStatement = new BehaviorSubject<boolean>(false);
			this.lrs.durationStartActivity = new Date().getTime();
		} catch (error) {
			throw error;
		}
	}

	/**
	 * skip the scenario sequence on skip button click or manual call anywhere
	 * @param data
	 */
	async skipMathiaSpeechSequence(data: any) {
		const callback = this.ttsService.currentTTSPCallback;
		if (Array.isArray(data)) {
			this.scenario.skipSequence = data[0];
			await this.ttsService.killSpeech(callback);
			data[1]();
		} else {
			this.scenario.skipSequence = data;
			await this.ttsService.killSpeech(callback);
		}
		return true;
	}

	/**
	 * Rotate screen method for cabri activity participants switching in multiplayer mode
	 */
	rotateScreen() {
		this.globalService.orientationSwitched = !this.globalService.orientationSwitched;
		// console.log("this.orientation before = " + this.orientation);
		if (this.globalService.orientation === "landscape-primary") {
			this.globalService.orientation = "landscape-secondary";
		} else if (this.globalService.orientation === "landscape-secondary") {
			this.globalService.orientation = "landscape-primary";
		}
		// console.log("this.orientation after = " + this.orientation);
		this.globalService.screenOrientation.lock(this.globalService.orientation);
	}
}
