import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
	AbstractMesh,
	ArcRotateCamera,
	Engine,
	FreeCamera,
	HemisphericLight,
	Mesh,
	MeshBuilder,
	Scene,
	SceneLoader,
	Vector3
} from "@babylonjs/core";
import * as BABYLON from "@babylonjs/core";
import { FurMaterial, TerrainMaterial } from "@babylonjs/materials";
import { GlobalService } from "src/app/services/global.service";

@Component({
	selector: "app-bee",
	templateUrl: "./bee.component.html",
	styleUrls: ["./bee.component.scss"]
})
export class BeeComponent implements OnInit, AfterViewInit {
	@ViewChild("canvas", { static: false }) canvas: ElementRef;
	engine: Engine;
	scene: Scene;
	camera: ArcRotateCamera;
	light: any;
	bee: AbstractMesh;
	ground: BABYLON.GroundMesh;
	mainBee: AbstractMesh;
	constructor(public globalService: GlobalService) {
		(window as any).bee = this;
		(window as any).BABYLON = BABYLON;
	}
	ngOnInit() {}
	ngAfterViewInit(): void {
		this.engine = new BABYLON.Engine(this.canvas.nativeElement as HTMLCanvasElement, false);
		this.engine.setHardwareScalingLevel(1);

		this.scene = new BABYLON.Scene(this.engine);

		this.camera = new BABYLON.ArcRotateCamera("camera1", 0, 0, 50, new Vector3(0, 0, 0), this.scene);
		this.camera.rotation = new Vector3(1, 0, 0);
		this.camera.attachControl(this.canvas, true);

		this.light = new BABYLON.HemisphericLight("light1", new Vector3(0, 10, 0), this.scene);
    this.light = new BABYLON.HemisphericLight("light2", new Vector3(0, -10, 0), this.scene);

		// this.ground = BABYLON.MeshBuilder.CreateGround("ground", { width: 40, height: 40 }, this.scene);
		// this.ground.isPickable = true;
		this.scene.onPointerUp = (evt, pickResult) => {
			console.log(pickResult);
			if (pickResult.pickedPoint && this.bee) {
				console.log(evt, pickResult);
				const target = new Vector3(pickResult.pickedPoint.x, this.bee.position.y, pickResult.pickedPoint.z);

				this.bee.lookAt(target);

				const animMoveTo = new BABYLON.Animation(
					"moveto",
					"position",
					30,
					BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
					BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
				);
				const keys2 = [];
				keys2.push({ frame: 0, value: this.bee.position });
				keys2.push({ frame: 100, value: target });
				animMoveTo.setKeys(keys2);
				this.bee.animations = [];
				this.bee.animations.push(animMoveTo);
				this.scene.beginAnimation(this.bee, 0, 100, false, 1, () => {
					this.bee.lookAt(this.camera.position);
				});

				console.log("ok");
			}
		};
		this.engine.runRenderLoop(() => this.scene.render());
		this.globalService.setGlobalLoading(false);

		this.scene.onAfterRenderObservable.addOnce(() => {
			this.engine.setHardwareScalingLevel(1);
			//      this.test();
		});
	}

	test() {
		// this.scene.debugLayer.show();
		SceneLoader.ImportMeshAsync("", "./assets/", "BeeWsmoothing.babylon", this.scene).then(result => {
			console.log(result);
			this.bee = new BABYLON.Mesh("myBee", this.scene);
			this.mainBee = result.meshes[0];
			this.mainBee.parent = this.bee;
			this.bee.position = new Vector3(0, 3, 0);
			this.mainBee.scaling = new Vector3(100, 100, 100);
			this.mainBee.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD);
			// this.bee = result.meshes[0];
			// this.bee.position = new Vector3(0, 3, 0);

			this.camera.setTarget(this.bee.position);
		});
	}

	testTerrain() {
		var scene = this.scene;

		// Camera
		var camera = this.camera;
		camera.setTarget(BABYLON.Vector3.Zero());
		camera.attachControl(this.canvas, true);

		// Light
		// var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);

		// Create terrain material
		var terrainMaterial = new TerrainMaterial("terrainMaterial", scene);
		terrainMaterial.specularColor = new BABYLON.Color3(0.5, 0.5, 0.5);
		terrainMaterial.specularPower = 64;

		// Set the mix texture (represents the RGB values)
		terrainMaterial.mixTexture = new BABYLON.Texture("assets/babylon/furet/1.png", scene);

		// Diffuse textures following the RGB values of the mix map
		// diffuseTexture1: Red
		// diffuseTexture2: Green
		// diffuseTexture3: Blue
		terrainMaterial.diffuseTexture1 = new BABYLON.Texture("assets/babylon/furet/1.png", scene);
		terrainMaterial.diffuseTexture2 = new BABYLON.Texture("assets/babylon/furet/1.png", scene);
		terrainMaterial.diffuseTexture3 = new BABYLON.Texture("assets/babylon/furet/1.png", scene);

		// Bump textures according to the previously set diffuse textures
		terrainMaterial.bumpTexture1 = new BABYLON.Texture("assets/babylon/furet/2.png", scene);
		terrainMaterial.bumpTexture2 = new BABYLON.Texture("assets/babylon/furet/2.png", scene);
		terrainMaterial.bumpTexture3 = new BABYLON.Texture("assets/babylon/furet/2.png", scene);

		// Rescale textures according to the terrain
		terrainMaterial.diffuseTexture1.uScale = terrainMaterial.diffuseTexture1.vScale = 1;
		terrainMaterial.diffuseTexture2.uScale = terrainMaterial.diffuseTexture2.vScale = 1;
		terrainMaterial.diffuseTexture3.uScale = terrainMaterial.diffuseTexture3.vScale = 1;

		// Ground
		var ground = BABYLON.Mesh.CreateGroundFromHeightMap("ground", "assets/babylon/furet/1.png", 100, 100, 100, 0, 10, scene, false);
		// var ground = BABYLON.CreateSphere("ground", { diameter: 10 }, scene);

		ground.position.y = 2.05;
		ground.material = terrainMaterial;


		this.engine.setHardwareScalingLevel(1);
	}
}
