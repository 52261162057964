export class ScenarioPhrase {
	public phrase: Array<string>;
	public phraseTTS: Array<string>;
	public callback: any;
	public randomSpeechMode = false;
	public textBubble = true;
	public buttons: boolean;
	public buttonText: string;
	public disableSkip = false;
	public shootingStar = false;
	public normalStar = false;
	public moon = false;
	public onlySpeech = false;
	keepBubble: boolean;

	constructor(texte: any, callback = null, phraseTTS: any = null, buttonTexte: string = null) {
		if (Array.isArray(texte)) {
			this.phrase = texte;
		} else {
			this.phrase = [texte];
		}

		if (buttonTexte) {
			this.buttonText = buttonTexte;
		}

		if (phraseTTS) {
			if (Array.isArray(texte)) {
				this.phraseTTS = phraseTTS;
			} else {
				this.phraseTTS = [phraseTTS];
			}
		} else {
			this.phraseTTS = [];
			// remove space before ! and ? for intonations in sentence.
			this.phrase.forEach(element => {
				let elementTTS = element.replace(" ?", "?");
				elementTTS = elementTTS.replace(" !", "!");
				this.phraseTTS.push(elementTTS);
			});
		}

		if (callback) {
			this.callback = callback;
		} else {
			this.callback = () => {};
		}
	}

	setRandomMode() {
		this.randomSpeechMode = true;
		return this;
	}

	speechOnly() {
		this.onlySpeech = true;
		return this;
	}

	hideBubble() {
		this.textBubble = false;
		return this;
	}

	keepBubbleOn() {
		this.keepBubble = true;
		return this;
	}

	showButtons() {
		this.buttons = true;
		return this;
	}

	disableSkipButton() {
		this.disableSkip = true;
		return this;
	}

	/**
	 * shows award
	 * @param [award] with stt
	 * @returns nothing
	 */
	showAward(award: string) {
		if (award === "shooting") {
			this.shootingStar = true;
		} else if (award === "normal") {
			this.normalStar = true;
		} else if (award === "moon") {
			this.moon = true;
		}
		return this;
	}
}
