import { Component, Input, OnInit } from "@angular/core";
import { Chapter, systemName } from "src/app/models/chapter";
import { svgTags } from "../../galaxy-map/galaxy-map.component";

@Component({
	selector: "app-etape",
	templateUrl: "./etape.component.html",
	styleUrls: ["./etape.component.scss"]
})
export class EtapeComponent implements OnInit {
	@Input() worldMaps;
	@Input() currentUserWorld: { index: number; systemName: systemName; currentChapter: Chapter[] };
	unlocked: boolean;
	currentChapter: Chapter;
	className: string;
	planetName: string;
	constructor() {}

	ngOnInit() {
		this.className = this.currentUserWorld.systemName === systemName.cassiopee ? "Cassiopee" : this.currentUserWorld.systemName;
		this.planetName = this.currentUserWorld.systemName === systemName.cassiopee ? $localize`Déméter-ES1301` : "Tammari-FKS09";
		this.unlocked = this.worldMaps[this.currentUserWorld.systemName]?.some(chapter => {
			return chapter.unlocked;
		});

		this.currentChapter = this.currentUserWorld.currentChapter[0];
	}

	get systemName() {
		return systemName;
	}

	get svgTags() {
		return svgTags;
	}
}
