import { AppUtils } from "./../app-utils";
import { Renderer2 } from "@angular/core";

export enum ContentTagNames {
	IMG = "IMG",
	AUDIO = "AUDIO",
	VIDEO_WP = "VIDEO_WP",
	VIDEO_VIMEO = "VIDEO_VIMEO",
	H1 = "H1",
	H2 = "H2",
	H3 = "H3",
	P = "P"
}

export class TTSContent {
	tag: string;
	text: string;
}

export class Fiche {
	public pages: Array<any>;
	public styles: Array<any>;
	public pageTextContent: Array<TTSContent>;
	public ficheTextContent: Array<Array<TTSContent>>;
	constructor(private html: string, renderer: Renderer2, public id?, public title?, public feedback?: boolean) {
		// tmp html element
		const tmp = renderer.createElement("div");
		tmp.innerHTML = this.html;

		// extract fiche elements
		this.pages = tmp.querySelectorAll("#fiche-container > .wp-block-group, #fiche-container > .wp-block-columns");
		this.styles = tmp.querySelectorAll("style");
		this.ficheTextContent = new Array();
		if (!this.feedback) {
			this.scanFicheForTTSContent(this.pages);
		}
		this.nonBreakableSpace();
		AppUtils.debug("this.scenarios page = ", this.ficheTextContent);
	}

	nonBreakableSpace() {
		this.pages.forEach((pageElement: HTMLElement) => {
			const textElements = pageElement.querySelectorAll("p, h1, h2, h3, h4, li");
			textElements.forEach(e => {
				e.innerHTML = AppUtils.addNBSP(e.innerHTML);
			});
		});
	}
	scanFicheForTTSContent(pages) {
		pages.forEach((pageElement: HTMLElement) => {
			if (pageElement) {
				this.pageTextContent = new Array();
				const parent: HTMLElement = pageElement.querySelector(".wp-block-group__inner-container");
				this.scanParentElementForTTSContent(parent);
				this.ficheTextContent.push(this.pageTextContent);
			}
		});
	}

	scanParentElementForTTSContent(parent: any) {
		if (this.testIfTTSContent(parent)) {
			this.pushTTSContentInScenario(parent);
		} else {
			const children = parent.children;
			for (const childElement in children) {
				if (children.hasOwnProperty(childElement)) {
					this.scanParentElementForTTSContent(children[childElement]);
				}
			}
		}
	}

	testIfTTSContent(childElement) {
		// console.log(childElement);
		return (
			(!childElement.data &&
				(childElement.tagName === "H1" ||
					childElement.tagName === "H2" ||
					childElement.tagName === "H3" ||
					childElement.tagName === "P" ||
					childElement.tagName === "LI") &&
				childElement.innerText !== "") ||
			childElement.tagName === "IMG" ||
			childElement.tagName === "A" ||
			(childElement.tagName === "FIGURE" &&
				(childElement.classList?.contains("wp-block-video") || childElement.classList?.contains("wp-block-embed-vimeo")))
		);
	}

	pushTTSContentInScenario(childElement: HTMLElement) {
		let textContent: TTSContent;
		if (childElement.tagName === "IMG") {
			textContent = { tag: ContentTagNames.IMG, text: "image" };
		} else if (childElement.tagName === "A") {
			if ((childElement as HTMLAnchorElement).href && String((childElement as HTMLAnchorElement).href).endsWith(".mp3")) {
				textContent = { tag: ContentTagNames.AUDIO, text: "audio-link" };
				childElement.childNodes.forEach((child: HTMLElement) => {
					this.pageTextContent.push(textContent);
					if (child.tagName === "IMG") {
						textContent = { tag: ContentTagNames.IMG, text: "image" };
					}
				});
			}
		} else if (childElement.tagName === "FIGURE") {
			if (childElement.classList?.contains("wp-block-video")) {
				textContent = { tag: ContentTagNames.VIDEO_WP, text: "wp-video" };
			}
			if (childElement.classList?.contains("wp-block-embed-vimeo")) {
				textContent = { tag: ContentTagNames.VIDEO_VIMEO, text: "vimeo-video" };
			}
			if (childElement.classList?.contains("audio-link")) {
				textContent = { tag: ContentTagNames.AUDIO, text: "audio-link" };
			}
		} else if (childElement.tagName === "LI") {
			textContent = { tag: "P", text: childElement.innerText };
		} else if (childElement.tagName !== "STYLE") {
			textContent = { tag: childElement.tagName, text: childElement.innerText };
		}
		if (textContent) {
			this.pageTextContent.push(textContent);
		}
	}
}
