import { Exercise } from "./exercise";
import { Student } from "./student";

export enum AnswerStatus {
	VALID1ST = "valid1st",
	VALID2ND = "valid2nd",
	ERROR1ST = "error1st",
	ERROR2ND = "error2nd"
}
export enum AnswerNeedsHelp {
	YES = "yes",
	NO = "no",
	IGNORE = "ignore"
}
export enum AnswerSpeed {
	FAST = "fast",
	NORMAL = "normal",
	SLOW = "slow"
}
export enum AnswerAverage {
	ABOVE = "above",
	EQUAL = "equal",
	BELOW = "below",
	UNDEFINED = "undefined"
}
export enum AnswerExtreme {
	NO = "no",
	ALLBAD = "allBad",
	ALLGOOD = "allGood"
}
export enum AnswerAward {
	SHOOTING = "étoile filante",
	NORMAL = "étoile",
	MOON = "lune"
}

export const AnswerAwardTrans = {'étoile filante': $localize`étoile filante`,'étoile':$localize`étoile`, 'lune': $localize`lune`};

export class ActivityAnswer {
	public speed: AnswerSpeed;
	public progressionAverage: AnswerAverage;
	public progressionExtreme: AnswerExtreme;
	public award: AnswerAward;
	public success: boolean;
	public calculNamingMode: boolean;
	public calculProblemMode: boolean;
	constructor(
		public status: AnswerStatus,
		public needsHelp: AnswerNeedsHelp,
		public responseTimeInSeconds: number,
		private slow = 20,
		private fast = 7,
		private currentUser: Student,
		private minAnswersForAverage = 5,
		private minAnswersForExtreme = 5,
		public currentExercise: Exercise
	) {
		this.analyzeAnswerSpeed();
		this.analyzeUserProgressionFromAverage();
		this.analyzeIfExtremeContext();
		this.success = this.status === AnswerStatus.VALID1ST || this.status === AnswerStatus.VALID2ND;
		this.calculNamingMode = this.currentExercise.resultMode && this.currentExercise.resultMode === "+zero";
		this.calculProblemMode = currentExercise.probleme ? true : false;
	}

	public getContext() {
		const context = {
			status: this.status,
			speed: this.speed,
			progressionAverage: this.progressionAverage,
			progressionExtreme: this.progressionExtreme,
			success: this.status === AnswerStatus.VALID1ST || this.status === AnswerStatus.VALID2ND,
			needsHelp: this.needsHelp,
			calculNamingMode: this.calculNamingMode,
			calculProblemMode: this.calculProblemMode
		};
		// console.error("answer context = ", context);
		return context;
	}

	private analyzeAnswerSpeed() {
		if (this.responseTimeInSeconds < this.fast) {
			this.speed = AnswerSpeed.FAST;
		} else if (this.responseTimeInSeconds > this.slow) {
			this.speed = AnswerSpeed.SLOW;
		} else {
			this.speed = AnswerSpeed.NORMAL;
		}
	}

	/**
	 * set the context of user progression compared to the number of answered questions
	 */
	private analyzeUserProgressionFromAverage() {
		if (this.currentUser.awardsCurrent.total >= this.minAnswersForAverage) {
			if (
				this.currentUser.awardsCurrent.shooting + this.currentUser.awardsCurrent.normal >
				this.currentUser.awardsCurrent.total * 0.5
			) {
				// réponse en étant au dessus de la moyenne après 5 réponses :
				this.progressionAverage = AnswerAverage.ABOVE;
			} else if (
				this.currentUser.awardsCurrent.shooting + this.currentUser.awardsCurrent.normal <
				this.currentUser.awardsCurrent.total * 0.5
			) {
				// réponse en étant en dessous de la moyenne après 5 réponses :
				this.progressionAverage = AnswerAverage.BELOW;
			} else if (
				this.currentUser.awardsCurrent.shooting + this.currentUser.awardsCurrent.normal ===
				this.currentUser.awardsCurrent.total * 0.5
			) {
				// réponse en étant égal à la moyenne après 5 réponses :
				this.progressionAverage = AnswerAverage.EQUAL;
			}
		} else {
			this.progressionAverage = AnswerAverage.UNDEFINED;
		}
	}

	/**
	 * set the context of user extreme progression if any
	 */
	private analyzeIfExtremeContext() {
		if (this.currentUser.awardsCurrent.total >= this.minAnswersForExtreme) {
			if (this.currentUser.awardsCurrent.shooting === this.currentUser.awardsCurrent.total) {
				// bonne réponse après au moins X bonnes réponses consécutives:
				this.progressionExtreme = AnswerExtreme.ALLGOOD;
			} else if (this.currentUser.awardsCurrent.moons === this.currentUser.awardsCurrent.total) {
				// première bonne réponse après au moins X mauvaises réponses :
				this.progressionExtreme = AnswerExtreme.ALLBAD;
			} else {
				this.progressionExtreme = AnswerExtreme.NO;
			}
		} else {
			this.progressionExtreme = AnswerExtreme.NO;
		}
	}

	setAnswerAward(awardName: string) {
		switch (awardName) {
			case "shooting":
				return (this.award = AnswerAward.SHOOTING);
			case "normal":
				return (this.award = AnswerAward.NORMAL);
			case "moon":
				return (this.award = AnswerAward.MOON);
		}
	}
}
