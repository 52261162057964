import { NetworkService } from "src/app/services/network.service";
import { Param } from "./param";

export class SelectParam extends Param {
	selectionList: Array<{ label: string; value: string }>;
	defaultValue: { label: string; value: string };
	className: string = "SelectParam";

	constructor(param) {
		super(param);

		this.selectionList = param.selectionList;
		this.defaultValue = param.selectionList[0];
		this.value = param.selectionList[0][1];
	}
}
