import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "../../services/global.service";

@Component({
	selector: "app-choice",
	templateUrl: "./app-choice.component.html",
	styleUrls: ["./app-choice.component.scss"]
})
export class AppChoiceComponent implements OnInit {
	@Input() show: boolean;
  @Input() message: string;
  @Output() callback = new EventEmitter<boolean>();
	@Input() choice?: { visible: boolean; items: Array<{ label: string; value: any }>};

	constructor(public cabriService: CabriDataService, public globalService: GlobalService) {}

	ngOnInit() {}

  answerResponse(value: any) {
    if (this.choice){
      this.choice.visible = false;
      if (this.callback) {
        this.callback.emit(value);
      } else {
        throw new Error("Component app-mathia-speech callback for choice is require");
      }
    }
  }
}
