<!-- Alert button -->
<div class="alertContainer" (click)="dismiss()">
    <div class="alertBoxWrapperKidaia">
        <div class="alertBoxKidaia"
            [ngClass]="{'alertBoxKidaiaMobLandscape' : globalService.isMobile && globalService.isLandscape, 'alertBoxKidaiaDesktop' : globalService.isDesktop, 'alertBoxKidaiaMT' :  !globalService.isDesktop}"
            (click)="$event.stopPropagation()">

            <ion-grid class="customGrid">
                <ion-row class="alertTitleContainer">
                    <ion-col size="12">
                        {{alertTitle}}
                    </ion-col>
                </ion-row>

                <ion-row class="alertContainerButtons">
                    <ion-col size="4" class="refuseChanges" (click)="dismiss($event)">
                        <button class="alertBoxfooterBtn" i18n>
                            Non
                        </button>
                    </ion-col>
                    <ion-col size="4" class="acceptChanges" (click)="accept($event)">
                        <button class="alertBoxfooterBtn" i18n>
                            Oui
                        </button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
</div>