import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Classroom } from "../models/classroom";
import { Student } from "../models/student";
import { KidaiaSso } from "../models/user";
import { BaseService } from "./base.service";

export interface GradeClasse {
	classroomId: string | number;
	grade: string;
	label: string;
}

export interface BearerToken {
	access_token: string;
	expires_in: string;
}

@Injectable({
	providedIn: "root"
})
export abstract class ClassService extends BaseService {
	authBearer: string;
	classes: GradeClasse[];
	source: string;
	isAren = false;
	isBeneylu = false;
	isMathador: boolean;

	loggedFromHome = false;
	homeStudent: any;
	/**
	 * request a rest transaction token for tralalere
	 * if only login specified the loggin is the authBearer token
	 */
	abstract login(login?: string, password?: string): Observable<BearerToken>;

	public abstract setSession(authResult: BearerToken);
	/**
	 *  return tralalere token for auth
	 *  @returns string
	 */
	public abstract getSessionToken(): Promise<string>;
	public abstract logout();

	public abstract isLoggedIn(): boolean;
	public abstract isLoggedInStudent(): boolean;
	
	public abstract isLoggedOut();

	public abstract getExpirationTralalere(): Date;
	public abstract getExpirationStudent(): Date;
	/**
	 * Get a classe from tralalere with an array of Student
	 * @return Classroom
	 */
	public abstract getClasseFromGroupDetail(codeClass: string): Observable<Classroom>;

	public abstract getAssignationList(idProf: string): Observable<any>;

	/**
	 * Get teacher/Parent account information raw data from tralalere
	 */
	public abstract getTeacherInformation(): Promise<any>;

	public abstract getListClasseTeacher(): Promise<GradeClasse[] | null>;

	/**
	 * Create Student tralalere use classe from student if classe exist or first classe from parent account
	 * @param student use name and parental consent and picture classe not ready actually
	 */
	public abstract createStudent(student: Student): Promise<void>;

	/**
	 * Delete student tralalere use id of The Student
	 */
	public abstract deleteStudent(student: Student): Promise<void>;

	/**
	 * Update Student information update name and parental consent
	 */
	public abstract updateStudent(student: Student): Promise<void>;
	/**
	 * Get Kidaia sso information
	 */
	public abstract getKidaiaSso(): Promise<KidaiaSso>;
	/**
	 * verify password for deletion
	 * @param formData
	 * @returns
	 */
	public abstract verifPassword(formData: FormData): Observable<any>;

	public abstract redirectionError();

	public abstract registerTeacherAccount(email: string, password: string, label: string, firstName: string): Observable<any>;

	public abstract disconnectUser(): Observable<any>;

	public abstract loginCodeMaison(code: string): Promise<string>;
}
