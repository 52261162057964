import { Component, OnInit } from "@angular/core";
import { RemoteService } from "src/app/services/remote.service";

@Component({
	selector: "app-remote-status",
	templateUrl: "./remote-status.component.html",
	styleUrls: ["./remote-status.component.scss"]
})
export class RemoteStatusComponent implements OnInit {
	constructor(public remoteService: RemoteService) {}

	ngOnInit() {}
}
