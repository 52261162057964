<!-- transform="translate(-33.8,-34)" -->
<ng-container *ngIf="color">
  <!-- <svg  viewBox="0 0 35 41" width="65" height="70"> -->
  <svg viewBox="0 0 35 41" [attr.width]="width" [attr.height]="height">
    <defs>
      <pattern [id]="'imgpattern' + random" x="0" y="0" width="1" height="1">
      </pattern>
    </defs>
    <path transform="translate(0,4)" [attr.fill]="'url(#imgpattern'+ random + ')'" [attr.stroke]="color"
      stroke-width="2" [attr.stroke-dasharray]="percent + ', 100'" d="M18 2.0845
    a 15.9155 15.9155 0 0 1 0 31.831
    a 15.9155 15.9155 0 0 1 0 -31.831" />
    <text x="52%" y="55%" font-weight="bold" [attr.fill]="svgTextColor" text-anchor="middle" font-size="9px"
      font-family="Quicksand-Bold" dy=".3em">{{percent}} %</text>
  </svg>
</ng-container>