<div class="globalContainer" *ngIf="studentsList">
  <!-- <ng-container *ngIf="!login"> -->

  <div *ngIf="modalAlertDisplay">
    <app-custom-alert (alertMessageAnswer)="alertDemandRemoveStudent($event)"
      [alertAction]="userAlertActions.removeStudent" [alertTitle]="alertTitle"></app-custom-alert>
  </div>

  <div class="studentsContainerKidaia">
    <!-- close button -->
    <img *ngIf="notModal === false" src="/assets/mathia/buttons/validation/refuse_orange_centered.png" alt="close"
      class="modalHeadercloseButton" (click)="dismiss(null)">
    <ion-button *ngIf="studentsList | getStudentPreview as teacher" class="teacherMode" (click)="chooseStudent(teacher, $event)" i18n><ion-icon slot="start" name="school-sharp"></ion-icon>Mode enseignant</ion-button> 

    <div class="chooseTextWrapper">

      <p class="chooseText"
        [ngClass]="{'chooseTextMobile' : globalService.isMobile && !globalService.isLandscape, 'chooseTextPortrait' : !globalService.isDesktop && !globalService.isLandscape}"
        *ngIf="!studentsManagementMode && !editionStudent && !studentCreationMode && !spinner.status && !login" i18n>
        Choisis un joueur
      </p>
      <p class="chooseText"
        [ngClass]="{'chooseTextMobile' : globalService.isMobile && !globalService.isLandscape, 'chooseTextPortrait' : !globalService.isDesktop && !globalService.isLandscape}"
        *ngIf="(studentsManagementMode && !editionStudent && !spinner.status && !login) || modalAlertDisplay" i18n>
        Gérer les joueurs
      </p>
      <p class="chooseText"
        [ngClass]="{'chooseTextMobile' : globalService.isMobile && !globalService.isLandscape, 'chooseTextPortrait' : !globalService.isDesktop && !globalService.isLandscape}"
        *ngIf="editionStudent && !spinner.status && !studentCreationMode && !modalAlertDisplay && !login" i18n>
        Modifie ton joueur
      </p>
      <p class="chooseText"
        [ngClass]="{'chooseTextMobile' : globalService.isMobile && !globalService.isLandscape, 'chooseTextPortrait' : !globalService.isDesktop && !globalService.isLandscape}"
        *ngIf="!studentsManagementMode && studentCreationMode && !spinner.status && !login" i18n>
        Ajoute un joueur
      </p>
      <p class="chooseText"
        [ngClass]="{'chooseTextMobile' : globalService.isMobile && !globalService.isLandscape, 'chooseTextPortrait' : !globalService.isDesktop && !globalService.isLandscape}"
        *ngIf="spinner.status && spinner.text">
        {{spinner.text}}
      </p>
      <p class="chooseText"
        [ngClass]="{'chooseTextMobile' : globalService.isMobile && !globalService.isLandscape, 'chooseTextPortrait' : !globalService.isDesktop && !globalService.isLandscape}"
        *ngIf="!spinner.status && login" i18n>
        S'authentifier
      </p>

    </div>

    <ng-container *ngIf="(studentsList && !editionStudent) || modalAlertDisplay">

      <div class="playersGridWrapper scrollbar-primary">

        <div class="gridVerticalAlign">

          <ion-grid fixed class="ion-no-padding ion-no-margin kidaiaPlayersGrid"
            [ngClass]="{'kidaiaPlayersGridMobile' : globalService.isMobile}">

            <!-- No login -->
            <ion-row class="kidaiaPlayersRow ion-no-padding ion-no-margin"
              [ngClass]="{'scrollbar' : studentsList && studentsList.length > 1 && !spinner.status, 'kidaiaPlayersRowPortrait' : !globalService.isDesktop && !platform.isLandscape()}">
              <!-- *ngIf="!login" -->
              <!-- ADD STUDENT ICON -->
              <ion-col
                sizeXs="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '4' : '5'}}"
                sizeMd="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '4' : '5'}}"
                sizeLg="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '3' : '4'}}"
                sizeXl="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '2' : '4'}}"
                class="addCard"
                [ngClass]="{'notEditionMode' : !editionStudent,'kidaiaPlayersManagedCol' : studentsManagementMode,'kidaiaPlayersCol' : globalService.isLandscape || globalService.isDesktop, 'kidaiaPlayersColPortrait' : !globalService.isLandscape && !globalService.isDesktop, 'kidaiaPlayersColMobileLandscape' : globalService.isMobile && globalService.isLandscape, 'kidaiaPlayersColTablet' : globalService.isTablet}"
                *ngIf="globalService.isKidaia && !studentCreationMode && (!editionStudent || modalAlertDisplay) && studentsList && (studentsList.length < maxKidaiaAccount || maxKidaiaAccount===-1)&& !spinner.status">
                <div class="bubbleStudent ion-no-padding ion-no-margin"
                  *ngIf="!accountService.tralaError || environment.ose">
                  <div class='gradientBubbleStudent'>
                    <div class=" studentInfo" (click)="enterStudentCreation()">
                      <div class="studentStatistics" [ngClass]="{'studentStatisticsMobile' : globalService.isMobile}"></div>
                      <div class="startGameClickable">
                        <div class="iconWrapper">
                          <img 
                          [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']"
                          src="/assets/kidaia/icones/add_profile_untouched.svg"
                          alt="close" />
                        </div>
                        <!-- <div class="addPlayerIcon" (click)="enterStudentCreation()"></div> -->
                        <div class="labelWrapper">
                          <div class="classRoomWrapper">
                            <button class="startButtonInCard btnNoDecoration" i18n>Ajouter un joueur</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </ion-col>
              
              <!-- STUDENTS -->
              <ng-container *ngIf="!spinner.status">
                <ion-col *ngFor="let student of studentsList | filterStudent; let i = index" class="colKid"
                  sizeXs="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '4' : '5'}}"
                  sizeMd="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '4' : '5'}}"
                  sizeLg="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '3' : '4'}}"
                  sizeXl="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '2' : '4'}}"
                  [ngClass]="{'kidaiaPlayersNotManagedCol' : !studentsManagementMode && environment.kidaia,
                  'osePlayersNotManagedCol' : !studentsManagementMode && environment.kidaia,'kidaiaPlayersManagedCol' : studentsManagementMode,
                  'kidaiaPlayersCol' : globalService.isLandscape || globalService.isDesktop, 'kidaiaPlayersColPortrait' : !globalService.isLandscape && !globalService.isDesktop, 'kidaiaPlayersColMobileLandscape' : globalService.isMobile && globalService.isLandscape, 'kidaiaPlayersColTablet' : globalService.isTablet, 'teacherMode' : student.preview}">
                  <!-- size-xl="2" -->

                  <div class="bubbleStudent ion-no-padding ion-no-margin"
                    [ngClass]="student.selectedTemp || student.selected ? 'editionStudent' : 'notSelectedStudent'">
                    <div class='gradientBubbleStudent' [ngClass]="{'gradientBubbleStudentMobile' : globalService.isMobile}"
                      (click)="studentsManagementMode? $event.stopPropagation() : chooseStudent(student, $event)">

                      <div class="studentStatistics" [ngClass]="{'brighterColor' : (i + 1) % 2 === 0, 'studentStatisticsMobile' : globalService.isMobile}"
                        *ngIf="student.statisticsCurrent">
                        <div class="statisticContainer starsItems"
                          [hidden]="!currentStudentStatistics[student.id] || currentStudentStatistics[student.id] === 0">
                          <p class="statisticResponse" #statisticsResult>
                            {{currentStudentStatistics[student.id]}}
                          </p>
                          <img src="/assets/starboard/results/shooting.png" class="awards" alt="shooting">
                        </div>
                      </div>

                      <div class="studentInfo" [ngClass]="{'studentInfoMobile' : globalService.isMobile}">

                        <!-- Name -->
                        <div class="nameWrapper">
                          <!-- Static -->
                          <p class="studentLabel"
                            [ngClass]="{'studentLabelMobile' : globalService.isMobile, 'brighterColor' : (i + 1) % 2 === 0}">
                            {{student.name}}</p>
                        </div>

                        <div class="iconWrapper"
                          [ngClass]="[editionStudent && editionStudent.id === student.id ? 'iconWrapperPlayerEdition' : 'iconWrapper']">
                          <!-- player icon -->
                          <img *ngIf="environment.ose"
                            [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']"
                            src="https://dev-lms.kidaia.com/classroom/assets/media/alphabet/{{student.name[0].toUpperCase()}}.png"
                            alt="close" />
                          <img *ngIf="!environment.ose"
                            [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']"
                            src="/assets/kidaia/astrocoolrecrop.png" alt="close" />
                        </div>

                        <!-- Player info -->
                        <div class="labelWrapper">


                          <!-- Level -->
                          <div class="classRoomWrapper">
                            <button class="startButtonInCard btnNoDecoration"
                              [ngClass]="{'brighterBackground' : (i + 1) % 2 === 0, 'startButtonInCardMobilePortrait' : globalService.isMobile && !globalService.isLandscape}"
                              *ngIf="!studentsManagementMode" i18n>C'est
                              parti !</button>
                            <div class="iconWrapperManagePlayer" *ngIf="studentsManagementMode">
                              <div *ngIf="!student.preview" #editStudentIcon class="managePlayerIcon" (click)="editStudent(student, i, $event)"
                                [ngClass]="studentsManagementMode ? 'pointersEvents' : ''" dis></div>
                              <div *ngIf="!student.preview" #removeStudentIcon class="removePlayerIcon"
                                (click)="confirmStudentRemoval(student, i, $event)"
                                [ngClass]="studentsManagementMode ? 'pointersEvents' : ''"></div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </ion-col>
              </ng-container>

              <!-- GUEST -->
              <!-- <ion-col size="{{globalService.isLandscape && !globalService.isDesktop? '3' :  globalService.isLandscape && globalService.isDesktop ? '2' : '4'}}" 
                    [ngClass]="{'kidaiaPlayersCol' : globalService.isLandscape || globalService.isDesktop, 'kidaiaPlayersColPortrait' : !globalService.isLandscape && !globalService.isDesktop" 
                    *ngIf="globalService.isKidaia && !editionStudent">
                      <div class="bubbleStudent ion-no-padding ion-no-margin notSelectedStudent">
                        <div class='gradientBubbleStudent' (click)="addStudent(guest, $event)">
                          <div class="studentInfo">
                            <div class="iconWrapper">
                              <img [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']" src="/assets/kidaia/astrocoolrecrop.png" alt="close"/>
                            </div>
                            <div class="labelWrapper">
                              <p class="studentLabel" [ngClass]="{'studentLabelMobile' : globalService.isMobile}">Invité</p>
                              <p class="studentLabel" [ngClass]="{'studentLabelMobile' : globalService.isMobile}">Classe</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ion-col> -->



              <div class="spinnerWrapper" *ngIf="spinner.status">
                <ion-spinner name="crescent"></ion-spinner>
              </div>
            </ion-row>

          </ion-grid>

        </div>


      </div>

      <div class="footerButtonsWrapper"
        [ngClass]="{'footerButtonsWrapperPortrait' : !globalService.isLandscape && (globalService.isTablet || globalService.isMobile)}">

        <ng-container *ngIf="!login && !spinner.status">
          <!-- studentsManagementMode switch button -->

          <button *ngIf="environment.ose" id="disonnect-ose" class="footerBtn btnNoDecoration disconnect" (click)="disconnect($event)">
            <span i18n>Se déconnecter</span>
          </button>
          <button class="footerBtn btnNoDecoration" *ngIf="!studentsManagementMode && !accountService.tralaError"
            [ngClass]="[studentsManagementMode ? 'btnStart' : 'btnCancel']"
            (click)="studentsManagementMode ? exitStudentsManagementMode($event) : enterStudentsManagementMode($event)">
            <span *ngIf="studentsManagementMode" i18n>
              Valider les changements
            </span>

            <span *ngIf="!studentsManagementMode" i18n>
              Gérer les joueurs
            </span>
          </button>

          <ng-container *ngIf="studentsManagementMode">
            <button class="footerBtn btnStart btnNoDecoration" (click)="exitStudentsManagementMode($event)"
              i18n>Terminer</button>
          </ng-container>

        </ng-container>


      </div>

    </ng-container>

    <!-- STUDENT CREATION / EDITION -->
    <ng-container *ngIf="(editionStudent || studentCreationMode) && !modalAlertDisplay">

      <div class="playersGridWrapper scrollbar-primary" [ngClass]="{'loginPage': login}">

        <div class="gridVerticalAlign">

          <ion-grid fixed class="ion-no-padding ion-no-margin kidaiaPlayersGrid"
            [ngClass]="{'kidaiaPlayersGridMobile' : globalService.isMobile}">

            <!-- No login -->
            <ion-row class="kidaiaPlayersRow ion-no-padding ion-no-margin"
              [ngClass]="{'kidaiaPlayersRowPortrait' : !globalService.isDesktop && !platform.isLandscape()}"
              [hidden]="login">


              <ion-col
                 class="editionMode"
                sizeXs="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '4' : '5'}}"
                sizeMd="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '4' : '5'}}"
                sizeLg="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '3' : '4'}}"
                sizeXl="{{globalService.isLandscape && !globalService.isDesktop? '3' : globalService.isLandscape && globalService.isDesktop ? '2' : '4'}}"
                [ngClass]="{'kidaiaPlayersCol' : globalService.isLandscape || globalService.isDesktop, 'kidaiaPlayersColPortrait' : !globalService.isLandscape && !globalService.isDesktop, 'kidaiaPlayersColMobileLandscape' : globalService.isMobile && globalService.isLandscape, 'kidaiaPlayersColTablet' : globalService.isTablet}">

                <div class="bubbleStudent ion-no-padding ion-no-margin notSelectedStudent" *ngIf="!spinner.status">
                  <div class='gradientBubbleStudent'>
                    <div class="studentInfo">
                      <div class="iconWrapperPlayerEdition">
                        <!-- player icon -->
                        <img [ngClass]="[!globalService.isLandscape ? 'playerIconPortrait' : 'playerIcon']"
                          src="/assets/kidaia/astrocoolrecrop.png" alt="close" />
                      </div>

                      <!-- Player info -->
                      <div class="labelWrapperEdition">
                        <div class="nameWrapper">
                          <!-- Edition -->
                          <input type="text" id="name" [(ngModel)]="editionStudent.name" [placeholder]="placeholderName"
                            onfocus="placeholder = ''" autocomplete="off" name="name" required minlength="2"
                            maxlength="30" class="studentNameInputField" autocapitalize="words">
                        </div>

                        <div class="classRoomWrapper" [hidden]="hideClassSelection">

                          <ion-select class="studentClassSelect" [(ngModel)]="editionStudent.classe.id"
                            [interface]="globalService.isDesktop ? 'popover' : 'alert'" [placeholder]="placeholderLevel"
                            [interfaceOptions]="{cssClass:'kidaia-popover'}" cancelText="Quitter" okText="Valider">
                            <ion-select-option *ngFor="let classroom of classes" [value]="classroom.classroomId">
                              {{classroom.label}}
                            </ion-select-option>
                          </ion-select>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="spinnerWrapper" *ngIf="spinner.status">
                  <ion-spinner name="crescent"></ion-spinner>
                </div>

              </ion-col>

            </ion-row>

            <!-- LOGIN FOR PLAYER REMOVAL -->
            <ng-container *ngIf="login">
              <ion-row class="formLoginContainer">
                <ion-col class="formLoginCol" sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="5" sizeXl="4">
                  <form *ngIf="!spinner.status" (ngSubmit)="submitRemoveStudentForm()" class="formLogin"
                    [ngStyle]="formLog.error?.length > 0 ?  {'height': '90%'} : {'height': '60%'}">
                    <input class="fieldRemoveStudent" type="email" [placeholder]="placeholderEmail"
                      [(ngModel)]="formLog.email" name="email" />
                    <input class="fieldRemoveStudent" type="password" [placeholder]="placeholderPassword"
                      [(ngModel)]="formLog.password" name="password">
                    <ng-container *ngIf="formLog.error && formLog.error.length > 0">
                      <div class="formError">
                        <p>{{formLog.error}}</p>
                      </div>
                    </ng-container>
                  </form>
                  <div class="spinnerWrapper" *ngIf="spinner.status">
                    <ion-spinner name="crescent"></ion-spinner>
                  </div>
                </ion-col>
              </ion-row>
            </ng-container>

          </ion-grid>

        </div>

      </div>

      <div class="footerButtonsWrapper">

        <ng-container *ngIf="!login && !spinner.status">

          <!-- validate student edition -->
          <ng-container *ngIf="!studentCreationMode">
            <button class="footerBtn btnFormQuit btnNoDecoration" (click)="cancelStudentEdition($event)"
              i18n>Annuler</button>
            <button class="footerBtn btnStart btnNoDecoration" (click)="confirmStudentEdition(editionStudent, $event)"
              i18n>Valider</button>
          </ng-container>
          <!-- validate student creation -->


          <!--Creation buttons -->
          <ng-container *ngIf="studentCreationMode && !spinner.status">
            <button *ngIf="!firstTimePlayerCreation" class="footerBtn btnFormQuit btnNoDecoration"
              (click)="cancelStudentCreation($event)" i18n>Quitter</button>
            <button class="footerBtn btnStart btnNoDecoration" (click)="validateStudentCreation($event)"
              i18n>Valider</button>
          </ng-container>

        </ng-container>

        <!-- login buttons -->
        <ng-container *ngIf="login && !spinner.status">
          <button class="footerBtn btnFormQuit btnNoDecoration" (click)="dismissLogin($event)" i18n>Quitter</button>
          <button class="footerBtn btnStart btnNoDecoration" (click)="submitRemoveStudent($event)" i18n>Valider</button>
        </ng-container>

        <!-- 
      <button #leftArrowStudentCreation *ngIf="studentCreationMode && !firstTimePlayerCreation && !spinner.status" [ngClass]="[!globalService.isLandscape && (globalService.isTablet || globalService.isMobile) ? 'leftArrowPortrait' : 'leftArrow']"
      (click)="cancelStudentCreation()"></button> -->

      </div>

    </ng-container>

    <!-- <button #leftArrowStudentsManagement *ngIf="studentsManagementMode && !spinner.status" [ngClass]="[!globalService.isLandscape && (globalService.isTablet || globalService.isMobile) ? 'leftArrowPortrait' : 'leftArrow']"
  (click)="studentsManagementMode && !editionStudent? exitStudentsManagementMode() : cancelStudentEdition()"></button> -->

  </div>