<div class="global"
  [ngClass]="{'isTablet' : globalService.isTablet, 'isMobile' : globalService.isMobile, 'isMirror' : cabriService.mirrorMode}">

  <!-- <div class="emptiness" [ngClass]="{'emptinessMirrorMode' : cabriService.mirrorMode}"></div> -->

  <div class="numpadContainer" [ngClass]="{
    'numPadContainerKim' : activity === 'kim' && !remote && !cabriService.mirrorMode && !globalService.isMobile,
    'numPadContainerKimMobile' : activity === 'kim' && !remote && !cabriService.mirrorMode && globalService.isMobile,
    'numPadContainerKimRemote' : activity === 'kim' && remote && !cabriService.mirrorMode, 
    'numPadContainerKimMirrorMode': activity === 'kim' && !remote && cabriService.mirrorMode,
    'numPadContainer' : activity !== 'kim' && activity !== 'heure' && !cabriService.mirrorMode,
    'numPadContainerGalaxy' : activity === 'galaxiedescalculs' && !cabriService.mirrorMode,
    'numPadContainerMirrorMode' : activity !== 'kim' && activity !== 'heure' && cabriService.mirrorMode,
    'numPadContainerMirrorModeGalaxy' : activity === 'galaxiedescalculs' && cabriService.mirrorMode,
    'numPadContainerHeureA' : activity === 'heure' && !showDigital && !cabriService.mirrorMode,
    'numPadContainerHeureAMirrorMode' : activity === 'heure' && !showDigital && cabriService.mirrorMode,
    'numPadContainerHeureAMobile' : activity === 'heure' && !showDigital && globalService.isMobile, 
    'numPadContainerHeureD' : activity === 'heure' && showDigital && !cabriService.mirrorMode,
    'numPadContainerHeureDMirrorMode' : activity === 'heure' && showDigital && cabriService.mirrorMode}">

    <!-- solid qcm -->
    <div class="row1HSolides pEAll" *ngIf="activity === 'solides' && displayedPadChoice">
      <div class="col1" *ngFor="let answer of displayedPadChoice; let i = index"
        [ngClass]="{'yesNo' : answer.text === 'OUI' || answer.text === 'NON', 'numeric' : answer.text.toString().length < 3}">
        <div class="customButton textButtonFont"
          (click)="handleInput(answer.value?answer.value:answer.text, i.toString());emitEvent('enter')"
          id="{{ 'button' + i }}">
          <p [ngClass]="[dyslexicMode? 'dyslexicFont' : '']" id="{{ 'button' + i + 'p'}}">
            {{answer.text}}
          </p>
        </div>
      </div>
    </div>

    <!-- heure analog -->
    <div class="row1HAnalog pEAll" *ngIf="activity === 'heure' && !showDigital">
      <div class="col1" *ngFor="let answer of displayedPadChoice; let i = index">
        <div class="customButton textButtonFont" (click)="handleInput(answer);emitEvent('enter')"
          id="{{ 'button' + answer }}">
          <p [ngClass]="{'numButtonFontTablet' : globalService.isTablet, 'dyslexicFont' : dyslexicMode, 'hoursEnLetterSpacing' : globalService.locale === AppLanguage.EN}"
            id="{{ 'button' + answer + 'p'}}">
            {{answer}}
          </p>
          <!-- TODO TRANSLATION => adapt answer format in heure.ts to am/pm -->
        </div>
      </div>
    </div>

    <!-- heure digital -->
    <div class="row1 largeSize pEAll" *ngIf="activity === 'heure' && showDigital">
      <div class="col1" *ngFor="let answer of choiceAnswerAnalog; let i = index">
        <div class="customButton textButtonFont" (click)="handleInput(answer.value);emitEvent('enter')"
          id="{{ 'button' + answer.value }}">
          <app-clock class="clockChoice" [multiAnalog]="true" [showAnalog]="true" [showDigital]="false"
            [(dateInput)]="answer.dateInput" [showHoursHand]="true" [showMinutesHand]="true"
            [hidingLevel]="answer.hidingLevel">
          </app-clock>
        </div>
      </div>
    </div>

    <!-- normal -->
    <div class="row1 pEAll" *ngIf="activity !== 'heure' && activity !== 'kim' 
          && !(activity == 'solides' && displayedPadChoice && displayedPadChoice.length > 0)">
      <div class="col1" *ngFor="let number of displayedPadNumbers;let i = index">
        <div class="customButton ecriture-cursive" [ngClass]="{'customButtonCastleNumbers': activity === 'galaxiedescalculs'}" (click)="handleInput(number)" id="{{ 'button' + number }}">
          <p [ngClass]="[number == 0 ? 'numButtonFont0' : 'numButtonFont', dyslexicMode? 'dyslexicFont' : '']"
            id="{{ 'button' + number + 'p'}}">
            {{number}}
          </p>
        </div>
      </div>

      <div *ngIf="activity === 'heure'" class="customButton" (click)="handleInput('h')" id="buttonHour">
        <p [ngClass]="{'dyslexicFont' : dyslexicMode}">h</p>
      </div>
      <!-- TODO TRANSLATION => am/pm version -->
      <!-- <div *ngIf="activity === 'heure'" class="customButton" (click)="handleInput('h')" id="buttonHour">
        <p [ngClass]="{'dyslexicFont' : dyslexicMode}">TODO TRANSLATION => am/pm</p>
      </div> -->
    </div>

    <!-- kim -->
    <div class="row1Kim pEAll" *ngIf="activity === 'kim'"
      [ngClass]="{'pointerEventsDisabled' : pointerEvents === false}">
      <div class="col1Kim" *ngFor="let choice of displayedPadChoice; let i = index">
        <div class="customButton textButtonFont columnFlex" (click)="handleInput(choice.value);emitEvent('enter')"
          id="{{ 'button' + choice.value }}">
          <div class="kimImgContainer">
            <ion-img [src]="choice.src" class="solidImg"></ion-img>
          </div>
          <div class="kimSolidNameContainer">
            <p class="solidName">{{choice.value}}</p>
          </div>
          <!-- TODO TRANSLATION => check choice.value language in kim.ts (already done -> to verify) -->
        </div>
      </div>
    </div>

    <!-- action buttons -->
    <div class="row2 pEAll" *ngIf="activity !== 'heure' && activity !== 'kim'
        && !(activity == 'solides' && displayedPadChoice && displayedPadChoice.length > 0)">

      <div class="colDelete">
        <div class="customButtonText" (click)="handleInput('clear')" id="buttonclear">
          <span class="textButtonFont" id="buttonclearp"
            i18n="erase numberResponse|erases the answer the user typed on the numpad@@eraseNumberResponse">
            Effacer
          </span>
        </div>
      </div>

      <div class="colValidate">
        <!-- <div class="customButtonRandom" (click)="handleInput('random')">
            <span class="textButtonFont">
              R
            </span>
          </div>   -->
        <div class="customButtonValidate activatedButton" (click)="emitEvent('enter')">
          <span class="textButtonFont"
            i18n="validate numberResponse|validates the answer the user typed on the numpad@@validateNumberResponse">
            Valider
          </span>
        </div>
      </div>
    </div>

  </div>

</div>