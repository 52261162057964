<div *ngIf="currentUser" class="progressBarWrapper"
   [ngClass]="{'progressBarWrapperKid' : environment.kidaia, 'isOse' : environment.kidaia && environment.ose}"
   [style.grid-template-columns]="'repeat('+numberOfQuestions.length+', 1fr)'">
   <ng-container *ngFor="let question of numberOfQuestions;let i = index;let last = last;">
      <div class="box" [ngClass]="{'animate': !globalService.lowPerformanceMode,
                                 'shooting': (currentUser.answers[i] != undefined &&
                                  currentUser.answers[i].award === 'shooting') || 
                                  ( 
                                  currentUser.answers[i] != undefined && 
                                  currentUser.answers[i].award === 'normal' &&
                                  cursorMode === 0),
                                  'normal': (currentUser.answers[i] != undefined &&
                                  currentUser.answers[i].award === 'normal') && cursorMode !== 0,
                                  'moon': currentUser.answers[i] != undefined &&
                                  currentUser.answers[i].award === 'moon',
                                  'selectedBox':
                                   currentUser.answers.length === i,
                                  'boxKid': environment.kidaia && barLength < 100,
                                   'shootingKid': environment.kidaia && (currentUser.answers[i] != undefined &&
                                  currentUser.answers[i].award === 'shooting') || 
                                  ( 
                                  currentUser.answers[i] != undefined && 
                                  currentUser.answers[i].award === 'normal' &&
                                  cursorMode === 0),
                                  'normalKid': environment.kidaia && (currentUser.answers[i] != undefined &&
                                  currentUser.answers[i].award === 'normal') && cursorMode !== 0,
                                  'moonKid': environment.kidaia && currentUser.answers[i] != undefined &&
                                  currentUser.answers[i].award === 'moon'
                                 }">

         <ng-container *ngIf="currentUser.answers.length === i;else keepGridVisible">
            <p>{{i + 1}}/{{numberOfQuestions.length}}</p>
         </ng-container>

         <!-- Keep grid box visible even if it's the end -->
         <ng-template #keepGridVisible>
            <p style="text-indent:-9999px;" *ngIf="last">end</p>
         </ng-template>

         <ng-container *ngIf="!environment.kidaia && currentUser.answers[i] != undefined &&
         (currentUser.answers[i].award === 'normal' || 
         currentUser.answers[i].award === 'shooting' || 
         currentUser.answers[i].award === 'moon') && currentUser.answers.length - 1 === i && !finalSummary">
            <div id="rocketWrapper" [hidden]="globalService.isMobile && (cursorMode === 0 || cursorMode === 2)"
               [ngClass]="needRocketAnimation && !globalService.lowPerformanceMode  ? 'rocketAnimation' : 'rocketRight'">
               <div id="rocketImg"></div>
            </div>
         </ng-container>
      </div>
   </ng-container>
</div>