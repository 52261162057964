import { Component, Input, ViewChild, AfterViewInit, ChangeDetectorRef, OnInit, ViewChildren, OnDestroy } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { NetworkService } from "src/app/services/network.service";
import { GlobalService } from "src/app/services/global.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { LrsService } from "src/app/services/lrs.service";
import { ActivatedRoute } from "@angular/router";
import { Grade, NoCategoryStatus } from "src/app/models/exercices";
import { CabriActivity } from "src/app/models/cabri-activity";
import { ExerciseProgressionLength } from "src/app/models/lrs/studentExerciseStatistics";
import { SwiperOptions } from "swiper/types";
import Swiper from "swiper";
import { AppUtils } from "src/app/app-utils";
import { AppLanguage } from "src/app/models/enums/enum-list";
import { ParamTutorial } from "src/app/models/paramTutorial";
import { AudioService } from "src/app/services/audio.service";
@Component({
	selector: "app-param",
	templateUrl: "./param.component.html",
	styleUrls: ["./param.component.scss"]
})
export class ParamComponent implements OnInit, AfterViewInit {
	public currentValue;
	@Input("param") myParam;
	@Input() paramDetails;
	@Input() assignation: boolean;
	@Input() displayNombreAutrementChoices: boolean;
	@Input() mode;
	@Input() tabsExercises;
	@Input() studentParam;
	@Input() quickstartPage;
	@Input() currentActivity: CabriActivity;

	scenarioParam: ParamTutorial;

	toogle = false;
	selectedExerciseCategory;
	cursorModeChoosen;

	@ViewChildren("paramSelection") paramSelection;
	@ViewChild("ionSlides", { static: false }) slideElement;
	screenOrientationSubscription: any;
	filteredSelectionList: any[];
	slidesSwiper: Swiper;
	sliderOptions: SwiperOptions;
	selectedClass: string;
	completedExerciseLength = new Array(ExerciseProgressionLength.number);

	// Vaiable set from settings. Define if each param(Input method,holo,cursor mode) shoul be displyed in one row or two(title + param)
	displayParamToRow = false;

	constructor(
		public cd: ChangeDetectorRef,
		public activatedRoute: ActivatedRoute,
		public accountService: AccountService,
		public lrsService: LrsService,
		public networkService: NetworkService,
		public globalService: GlobalService,
		public cabriService: CabriDataService,
		public audioService: AudioService
	) {
		this.filteredSelectionList = new Array();
		this.sliderOptions = {
			autoHeight: true,
			loop: true
		};
	}

	ngOnInit() {
		if (this.myParam) {
			if (!this.myParam.exercices) {
				// this.filteredSelectionList = this.myParam.selectionList.filter(
				// 	element =>
				// 		// filter bilan mode
				// 		!element[3] &&
				// 		// filter pyramide mode in kidaia
				// 		!(this.myParam.name === "holo" && element[1] === "-1" && environment.kidaia)
				// );
				this.filteredSelectionList = this.myParam.selectionList;
				this.scenarioParam = new ParamTutorial();
			} else {
				this.filteredSelectionList = [this.myParam.value];
			}
		}
		if (this.tabsExercises) {
			this.tabsExercises.subscribe(item => {
				if (Array.isArray(item.selectionList) || item.defaultValue) {
					this.filteredSelectionList = item.selectionList;
				}

				this.selectedClass = this.displayedClassLevel;
			});
		}
	}

	public get displayedClassLevel() {
		if (this.globalService.locale === AppLanguage.EN) {
			if (this.cabriService.exercices.selected === Grade.cp) {
				return "5";
			}
			if (this.cabriService.exercices.selected === Grade.ce1) {
				return "6";
			}
			if (this.cabriService.exercices.selected === Grade.ce2) {
				return "7";
			}
			if (this.cabriService.exercices.selected === Grade.cm1) {
				return "8";
			}

			if (this.cabriService.exercices.selected === Grade.cm2) {
				return "9";
			}
		} else {
			return this.cabriService.exercices.selected;
		}
	}

	public get NetworkService() {
		return NetworkService;
	}

	/**
	 * Getter accessible from template.Let's know if activity settings should be displayed in one column or two depending on help request
	 * @return boolean
	 */
	public get displayFullSpaceGrid() {
		if (this.globalService.isMobile) {
			if (this.globalService.isMobileLandscape) {
				return false;
			} else {
				return true;
			}
		} else {
			if (this.displayParamToRow && !this.globalService.isLandscape) {
				return false;
			} else if (this.displayParamToRow || !this.globalService.isDesktop) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Scroll to selected exercise category
	 */
	openExerciseCategory(exerciseByCategory) {
		this.audioService.playSelectSound();
		exerciseByCategory.opened = !exerciseByCategory.opened;
		this.cabriService.exercices.selectedCategory = exerciseByCategory;
		this.globalService.exerciseStatisticsOpened = false;
		this.cabriService.changeCategoryExerciseParamValue(exerciseByCategory.catName, exerciseByCategory, this.currentActivity.id);
	}

	public get NoCategoryStatus() {
		return NoCategoryStatus;
	}

	get Array() {
		return Array;
	}

	get Grade() {
		return Grade;
	}

	async ngAfterViewInit() {
		// timeout for avoiding visual glitch
		await AppUtils.timeOut(200);
		if (this.slideElement?.elementRef?.nativeElement) {
			this.slidesSwiper = new Swiper(this.slideElement?.elementRef?.nativeElement, {
				loopedSlides: this.filteredSelectionList.length,
				...this.sliderOptions,
				on: {
					init: ($event: Swiper) => {
						this.afterInitSwiper($event);
					},
					slideChange: $event => {
						this.onSwiperChange($event);
					}
				}
			});
		}

		if (this.slidesSwiper) {
			this.screenOrientationSubscription = this.globalService.screenOrientation.onChange().subscribe(() => {
				if (this.slidesSwiper) {
					// init because component is destroyed
					this.slidesSwiper = new Swiper(this.slideElement?.elementRef?.nativeElement, {
						loopedSlides: this.filteredSelectionList.length,
						...this.sliderOptions
					});
					this.slidesSwiper.updateAutoHeight(500);
					this.slidesSwiper.update();
				}
			});
		}

		if (this.myParam.defaultValue && this.filteredSelectionList) {
			const v = this.filteredSelectionList.indexOf(this.myParam.defaultValue);
			if (v > -1) {
				this.currentValue = v;
			}
		}

		this.cd.detectChanges();
	}

	afterInitSwiper(swiper: Swiper) {
		if (this.myParam.className === "SelectParam") {
			if (this.myParam.value) {
				for (const index in this.filteredSelectionList) {
					if (this.filteredSelectionList[index][1] === this.myParam.value) {
						swiper.slideTo(Number(index), 0);
					}
				}
			}
		}
	}

	onSwiperChange(swiper: Swiper) {
		this.updateParamValue(swiper.realIndex, true);
	}

	nextSlide() {
		this.slidesSwiper.slideNext();
	}

	prevSlide() {
		this.slidesSwiper.slidePrev();
	}

	/**
	 * Update param value
	 */
	updateParamValue(value, slide = false) {
		if (value === this.filteredSelectionList.length) {
			// security if modal is opened quickly
			value = 0;
		}
		this.currentValue = value;
		this.myParam.value = this.filteredSelectionList[value][1];
		this.myParam.defaultValue = this.filteredSelectionList[value];
		if (!slide && this.slidesSwiper) {
			this.slidesSwiper.slideTo(Number(value), 0);
		}

		this.cd.detectChanges();
	}

	/**
	 * Update param exercise value
	 */
	updateExerciseValue($event, exercise) {
		this.audioService.playSelectSound();
		this.myParam.value = this.myParam.defaultValue = exercise;
		if ($event && $event.currentTarget && !$event.currentTarget.classList.contains("selectedItem")) {
			document.querySelectorAll(".elementList").forEach(data => {
				data.classList.remove("selectedItem");
			});
			$event.currentTarget.classList.add("selectedItem");
		}
		this.cd.detectChanges();
	}
}
