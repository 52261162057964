<ng-container>
  <!-- <ion-grid> -->
  <ion-row *ngIf="logBook.timestamp.day !== lmsService.gamification.logBookStatistics[index - 1]?.timestamp.day || 
  logBook.timestamp.year !== lmsService.gamification.logBookStatistics[index - 1]?.timestamp.year"
    class="loogBookDate">
    <ion-col size="12" class="ion-float-left">
      <p class="logBookText">
        {{logBook.timestamp.dayOfWeek}}
        {{logBook.timestamp.day}}
        {{logBook.timestamp.month}}
        {{logBook.timestamp.year}}
      </p>

      <div class="divider"></div>
    </ion-col>
  </ion-row>
  <ion-row>
    <div class="logBookContainer">

      <div class="textContainer">
        <ion-col size="1">
          <p class="logBookText ion-text-center">
            <i>{{logBook.timestamp.time}}</i>
          </p>
        </ion-col>
        <ion-col size="11">
          <p class="logBookText">
            <span i18n="Logbook sentence start completed exercise">J'ai terminé l'exercice </span>
            <span class="title"> {{logBook.exerciseName}} </span>
            <span i18n="Logbook sentence of the activity">de l'activité </span>
            <span class="title"> {{logBook.activityName}} </span>
            <ng-container *ngIf="logBook.journeyName"> <span i18n="Logbook sentence of the journey">du parcours </span>
              <span class="title"> {{logBook.journeyName}} </span>
            </ng-container>
            <span i18n="Logbook sentence with">avec</span>&nbsp;

            <ng-container *ngIf="logBook.perGoodAnswers > 0;else failed">
              <span class="starNumber"> {{logBook.perGoodAnswers}}% </span>
              <span i18n="Logbook sentence per of good answer">de bonnes réponses</span>&nbsp;
            </ng-container>

            <ng-template #failed>
              <span *ngIf="logBook.perGoodAnswers == 0" i18n="Logbook sentence number stars">aucune étoile</span>&nbsp;
            </ng-template>

            <ng-container *ngIf="logBook.duration">
              <span i18n="Logbook sentence end duration in seconds">en </span> {{logBook.duration}}
            </ng-container>
          </p>
        </ion-col>
      </div>
    </div>
  </ion-row>
  <!-- </ion-grid> -->
</ng-container>