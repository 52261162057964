import { AuthGuard, CanActivateFirstCall, PremiumOseConnectionGuard } from "../auth-guard.guard";
import { environment } from "src/environments/environment";
import { RgpdGuard } from "../services/rgpd.guard";

export class RoutingRules {
	static get activityParticipants() {
		let route;
		if (environment.kidaia) {
			route = {
				path: "activity-participants",
				loadChildren: () =>
					import("../page/activity-participants-kidaia/activity-participants-kidaia.module").then(
						m => m.ActivityParticipantsKidaiaPageModule
					),
					isOse:true
			};
		} else {
			route = {
				path: "activity-participants",
				loadChildren: () =>
					import("../page/activity-participants/activity-participants.module").then(m => m.ActivityParticipantsPageModule),
				canActivate: [RgpdGuard]
			};
		}
		return route;
	}

	static get landing() {
		let route;
		if (environment.ose) {
			route = {
				path: "landing",
				loadChildren: () =>
				import("../page/activity-participants-kidaia/activity-participants-kidaia.module").then(
					m => m.ActivityParticipantsKidaiaPageModule
				),
			};
		} else {
			route = {
				path: "landing",
				loadChildren: () => import("../pages/landing/landing.module").then(m => m.LandingPageModule),
				canActivate: [AuthGuard],
			};
		}
		return route;
	}
}
