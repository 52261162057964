import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalController, PopoverController, RadioValueAccessor } from "@ionic/angular";
import { Chart, ChartOptions, ChartType, ChartDataset, ScatterDataPoint, BubbleDataPoint, DoughnutController, Tooltip } from "chart.js";
import { ChartConfiguration, ChartEvent } from "chart.js";
import * as helpers from "chart.js/helpers";
import { color } from "chart.js/helpers";
import { format, parseISO, addMonths, getMonth, getWeek, endOfDay, getDay } from "date-fns";
import { lastValueFrom } from "rxjs";
import { AppUtils } from "src/app/app-utils";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { LmsService } from "src/app/services/lms.service";
import { LrsService } from "src/app/services/lrs.service";
import { environment } from "src/environments/environment";
import { MatrixController, MatrixElement, MatrixDataPoint } from "chartjs-chart-matrix";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BaseChartDirective } from "ng2-charts";
import { AnalyticService } from "src/app/services/analytic.service";

declare var window;
Chart.register(MatrixController, MatrixElement);

export enum FilteredStatistic {
	moyenne = 0,
	pourcentage = 1,
	exerciceTermines = 2,
	exerciceTerminesClass = 3,
	parcoursTermines = 4,
	utilisateursMathia = 5,
	moyenneClasse = 6,
	duration = 7,
	competence = 8,
	exTerminesGabarit = 9,
	sousCompetence = 10,
	erreur = 11,
	sousErreur = 12,
	exTerminesGabaritClass = 13,
	exTerminesGabaritDonut = 14,
	competenceDayMoment = 15,
	competenceDayMomentAllMathia = 16,
	competenceDayMomentAllEleves = 17,
	performanceClass = 18,
	performanceClass3CC = 19,
	modesReponsesMathia = 20,
	modeHoloMathia = 21,
	vuesVideos = 22,
	exCommencesTermines = 23,
	competenceDayMomentTest = 24,
	exTerminesGabaritAllMathia = 25,
	userPerMomentMathia = 26,
	userPerMomentKidaia = 27,
	exTerminesGabaritAllKidaia = 28,
	modeHoloKidaia = 29,
	modesReponsesKidaia = 30,
	utilisateursKidaia = 31,
	competenceDayMomentAllKidaia = 32,
	completedAventureEntrainementMathia = 33,
	completedAventureEntrainementKidaia = 34,
	averageTimePerGabaritMathia = 35,
	actPerGabaritAventureMathia = 37,
	actPerGabaritEntrainementMathia = 38,
	actPerGabaritAventureKidaia = 39,
	actPerGabaritEntrainementKidaia = 40,
	averageTimePerGabaritKidaia = 41,
	finishedQuestionsMathia = 42,
	finishedQuestionsKidaia = 43,
	completedPerGoodAnswerMathia = 44,
	completedPerGoodAnswerKidaia = 45,
	scatterChartMathia = 46,
	scatterChartKidaia = 47,
	exCommencesTerminesMathia = 48,
	exCommencesTerminesKidaia = 49,
	parcoursCommencesTerminesMathia = 50,
	parcoursCommencesTerminesKidaia = 51
}

@Component({
	selector: "app-log-book-statistics-content",
	templateUrl: "./log-book-statistics-content.component.html",
	styleUrls: ["./log-book-statistics-content.component.scss"]
})
export class LogBookStatisticsContentComponent implements OnInit {
	environment: { production: boolean; activityVersion: number; storyVersion: number; kidaia: boolean; bearerToken: string };
	currentData: any;
	currentDataClass: any;
	chartExerciceCompleted: any;
	chartExerciceInitialized: any;
	chartExercicePerGabarit: any;
	chartExercicePerGabaritInit: any;
	chartParcoursFinished: any;
	chartVuesVideosCompleted: any;
	chartVuesVideosInitialized: any;
	chartFinishedQuestions: any;
	dataPerformanceUser: any;
	currentDataTime: any;
	dataCompetence: any;
	dataCompetenceUser: any;
	dataErreurUser: any;
	chartAventure: any;
	chartEntrainement: any;
	dateFnsTz: any;
	dateIsoString: any;
	buttonMoy: boolean;
	buttonExercices: boolean;
	buttonParcours: boolean;
	buttonComp: boolean;
	buttonErr: boolean;
	//date de départ initiale ne peut pas aller avant cette date
	filterDataFrom = "2021-01-01T10:46:20Z";
	// c: IonDatetime;

	rangeDate = {
		start: null,
		end: null
	};

	@ViewChild("canvasChartJS", { static: false }) chart: any;

	constructor(
		public lmsService: LmsService,
		public http: HttpClient,
		public cabriService: CabriDataService,
		public accountService: AccountService,
		public lrsService: LrsService,
		public popoverController: PopoverController,
		public modalCtrl: ModalController,
		public analyticService : AnalyticService
	) {
		this.analyticService.sendAnalytics("Navigation", {pageUrl : "LogBookStatisticComponent"});
		this.environment = environment;
		this.allowStatistics();
		this.getAveragePerGoodAnswer();
		// this.getCategory();
		// this.getCompetenceId();
		// this.getCompetenceIdClass();
		// this.showExercicesCompletedPerGabarit();
		// this.getSousCompetenceIdClass();
		// this.showExercicesCompletedPerGabaritClass();
		// this.showExPerGabaritDonut();
		// this.getCompetenceDayMoment();
		// this.getCompetenceDayMomentAll();
		// this.getCompetenceDayMomentAllEleves();
		// this.getCompetenceDayMomentTest();
		// this.showVuesVideos();
		// this.getPerformanceClass();
		// this.showModesReponses();
		// this.showModeHolo();
		// this.showHeatMapEx();
		// this.getSousCompetenceIdClassTest('Multiplier');
		// this.showExercicesCompletedPerGabaritAll();
		// this.showUserPerMoment();
		// this.showActAventureEntrainementMathia();
		// this.showActCompletedPerGabaritAventureMathia();
		// this.showFinishedQuestions();
		// this.showExercisePerGoodAnswerMathia();
		// this.showNameExercisesCompleted();
		// this.showScatterChartCompletedTime();
		// this.showExercicesInitializedCompletedMathia();
		// this.showJourneyInitializedCompletedMathia();
	}

	public barChartOptions: ChartOptions = {
		responsive: true
	};

	chartData: ChartDataset[] = [
		{
			// ⤵️ Add these
			label: "$ in millions",
			data: [1551, 1688, 1800, 1895, 2124, 2124]
		}
	];
	chartLabels: string[] = [];
	chartOptions: ChartOptions = {};

	// public barChartLabels = ["2006", "2007", "2008", "2009", "2010", "2011", "2012"];
	public barChartLabels = [];
	public barChartType: ChartType = "line";
	public barChartLegend = true;
	public barChartPlugins = [];
	public chartColors = [];
	public canvasShape = "rectangle"; //mettre en rectangle les graphes pour mieux visualiser
	// public barChartData: ChartDataset[] = [
	//  { data: [65, 59, 80, 81, 56, 55, 40], label: "Par semaine" },
	//  { data: [28, 48, 40, 19, 86, 27, 90], label: "Par mois" }
	// ];
	public barChartData: ChartDataset[];
	public customHeight = "500px";

	/////////////////////////////////////////////////:
	//CODE FILTRES PAR DATE
	currentStatisticChoosen: FilteredStatistic;

	dateChoosen($event, startedDate: boolean) {
		const formatString = format(parseISO($event.detail.value), "yyyy-MM-dd");
		if (startedDate) {
			this.rangeDate.start = formatString;
		} else {
			this.rangeDate.end = formatString;
		}

		console.log("this.currentStatisticChoosen", this.currentStatisticChoosen);
		// this.getAveragePerGoodAnswer();
		if (this.currentStatisticChoosen === FilteredStatistic.exerciceTermines) {
			this.showExercicesCompleted();
		} else if (this.currentStatisticChoosen === FilteredStatistic.moyenne) {
			this.getAveragePerGoodAnswer();
		} else if (this.currentStatisticChoosen === FilteredStatistic.pourcentage) {
			this.showPourcentage();
		} else if (this.currentStatisticChoosen === FilteredStatistic.parcoursTermines) {
			this.showParcoursTermines();
		} else if (this.currentStatisticChoosen === FilteredStatistic.moyenneClasse) {
			this.getAveragePerGoodAnswerClass();
		} else if (this.currentStatisticChoosen === FilteredStatistic.duration) {
			this.showAverageTime();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exCommencesTermines) {
			this.showExercicesInitializedCompleted();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exTerminesGabarit) {
			this.showExercicesCompletedPerGabarit();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exTerminesGabaritDonut) {
			this.showExPerGabaritDonut();
		} else if (this.currentStatisticChoosen === FilteredStatistic.competence) {
			this.getCompetenceIdClass();
		} else if (this.currentStatisticChoosen === FilteredStatistic.erreur) {
			this.getErreurIdClass();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exTerminesGabaritClass) {
			this.showExercicesCompletedPerGabaritClass();
		} else if (this.currentStatisticChoosen === FilteredStatistic.competenceDayMoment) {
			this.getCompetenceDayMoment();
		} else if (this.currentStatisticChoosen === FilteredStatistic.competenceDayMomentTest) {
			this.getCompetenceDayMomentTest();
		} else if (this.currentStatisticChoosen === FilteredStatistic.performanceClass) {
			this.getPerformanceClass();
		} else if (this.currentStatisticChoosen === FilteredStatistic.performanceClass3CC) {
			this.getPerformanceClass3CC();
		} else if (this.currentStatisticChoosen === FilteredStatistic.competenceDayMomentAllMathia) {
			this.getPerformanceAll5CCMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.competenceDayMomentAllKidaia) {
			this.getPerformanceAll5CCKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.vuesVideos) {
			// ose tag
			this.showVuesVideos();
		} else if (this.currentStatisticChoosen === FilteredStatistic.utilisateursMathia) {
			this.showUserUniqueMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.utilisateursKidaia) {
			// ose tag
			this.showUserUniqueKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.modesReponsesMathia) {
			this.showModesReponsesMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.modesReponsesKidaia) {
			this.showModesReponsesKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.modeHoloMathia) {
			this.showModeHoloMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.modeHoloKidaia) {
			this.showModeHoloKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exTerminesGabaritAllMathia) {
			this.showActCompletedPerGabaritAllMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.userPerMomentMathia) {
			this.showUserPerMomentMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.userPerMomentKidaia) {
			this.showUserPerMomentKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exTerminesGabaritAllKidaia) {
			this.showActCompletedPerGabaritAllKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.completedAventureEntrainementMathia) {
			this.showActAventureEntrainementMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.completedAventureEntrainementKidaia) {
			this.showActAventureEntrainementKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.averageTimePerGabaritMathia) {
			this.showAverageTimePerGabaritMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.averageTimePerGabaritKidaia) {
			this.showAverageTimePerGabaritKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.actPerGabaritAventureMathia) {
			this.showActPerGabaritAventureMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.actPerGabaritEntrainementMathia) {
			this.showActPerGabaritEntrainementMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.actPerGabaritAventureKidaia) {
			this.showActPerGabaritAventureKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.actPerGabaritEntrainementKidaia) {
			this.showActPerGabaritEntrainementKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.finishedQuestionsMathia) {
			this.showFinishedQuestionsMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.finishedQuestionsKidaia) {
			this.showFinishedQuestionsKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.completedPerGoodAnswerMathia) {
			this.showExercisePerGoodAnswerMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.completedPerGoodAnswerKidaia) {
			this.showExercisePerGoodAnswerKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.scatterChartMathia) {
			this.showScatterChartCompletedTimeMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.scatterChartKidaia) {
			this.showScatterChartCompletedTimeKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exCommencesTerminesMathia) {
			this.showExercicesInitializedCompletedMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.exCommencesTerminesKidaia) {
			this.showExercicesInitializedCompletedKidaia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.parcoursCommencesTerminesMathia) {
			this.showJourneyInitializedCompletedMathia();
		} else if (this.currentStatisticChoosen === FilteredStatistic.parcoursCommencesTerminesKidaia) {
			// ose tag
			this.showJourneyInitializedCompletedKidaia();
		}
	}

	dropDownChoice($event) {
		console.log($event);
	}
	//////////////////////////////////
	//PAR DEFAUT
	async ngOnInit() {
		(window as any).myc = this.chart;
		// console.error(Number((window.document.querySelector("#main-content") as any).offsetHeight) - 100 + "px");
		// (window.document.querySelector("#canvas-wrapper") as any).style.height =
		//     Number((window.document.querySelector("#main-content") as any).offsetHeight) - 100 + "px";
		await lastValueFrom(this.accountService.isUserLoaded);
		//le premier graphique montrée par defaut à l'ouverture de l'onglet statistique

		// this.barChartType = 'matrix';
		// this.barChartData = [{
		// 	label: 'Basic matrix',
		// 	data: [{ x: 1, y: 1 }, { x: 2, y: 1 }, { x: 1, y: 2 }, { x: 2, y: 2 }],
		// 	borderWidth: 1,
		// 	borderColor: 'rgba(0,0,0,0.5)',
		// 	backgroundColor: 'rgba(200,200,0,0.3)',
		// 	width: ({ chart }) => (chart.chartArea || {}).width / 2 - 1,
		// 	height: ({ chart }) => (chart.chartArea || {}).height / 2 - 1,
		// }];
		// this.barChartOptions = {
		// 	scales: {
		// 		x: {
		// 			display: false,
		// 			min: 0.5,
		// 			max: 2.5,
		// 			offset: false
		// 		},
		// 		y: {
		// 			display: false,
		// 			min: 0.5,
		// 			max: 2.5
		// 		}
		// 	}
		// };

		// this.getAveragePerGoodAnswer();
		// this.showExercicesCompleted();
		// this.lmsService.gamification.nbU();
		// this.lmsService.gamification.exercicescompleted();
		// this.lmsService.gamification.parcourscompleted(this.cabriService, this.lmsService._globalJourneys);
		// this.lmsService.gamification.nombredeclasse();
	}
	/////////////////////////
	// CODE EXTRAIRE LES DATA ET LABELS

	extractLabel(chartData) {
		const labels = [];
		for (const key in chartData) {
			if (key) {
				labels.push(key);
			}
		}
		return labels;
	}
	extractGabaritsLabel(chartData) {
		const labels = [];
		for (const key in chartData) {
			if (key) {
				const name = this.cabriService.activities.find(a => a.id === key).name;
				labels.push(name);
			}
		}
		return labels;
	}

	extractData(chartData, userId = null) {
		const data = [];
		for (const key in chartData) {
			if (key) {
				if (userId) {
					const userData = chartData[key].find(item => {
						return Number(item.userId) === Number(userId);
					});
					data.push(userData ? userData.value : null);
				} else {
					chartData[key].forEach(item => {
						data.push(item.value);
					});
				}
			}
		}
		return data;
	}

	// CODE : CALCUL DE LA MOYENNE DES DONNÉES

	extractAverageData(chartData, userId) {
		const data = [];
		for (const key in chartData) {
			if (key) {
				const userData = chartData[key].filter(item => {
					return Number(item.userId) === Number(userId);
				});
				if (userData && userData.length > 0) {
					let total = 0;
					userData.forEach(data => {
						total += data.value;
					});
					const average = Math.round(total / userData.length);
					data.push(average);
				} else {
					data.push(null);
				}
			}
		}
		return data;
	}

	// requêtes pour récupérer données et faire graphe moyenne de bonnes réponses
	async averageStatisticsPerGoodAnswer(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					nomEx: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/nom_exercice",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							tauxBonnesReponses: "$pourcentage",
							nomEx: "$nomEx",
							mydate: "$mydate",
							session: "$session",
							idAct: "$idActivite"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", nomEx: "$_id.nomEx", idAct: "$_id.idAct" },
						taux: {
							// calcule la moyenne de taux des bonnes reponses
							$avg: "$_id.tauxBonnesReponses"
						},
						session: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						nomEx: "$_id.nomEx",
						session: "$session",
						idAct: "$_id.idAct",
						value: { $ceil: "$taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);

			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value) {
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async getAveragePerGoodAnswer() {
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;

		this.buttonMoy = !this.buttonMoy; //afficher et masquer les boutons des options par mois/semaine/trimestres
		this.currentStatisticChoosen = FilteredStatistic.moyenne;
		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		this.currentData = await this.averageStatisticsPerGoodAnswer(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);
		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartLabels = this.extractLabel(this.currentData);
		this.barChartType = "line";
		this.barChartData = [
			{
				data: this.extractAverageData(this.currentData, this.accountService.team[0].id),
				label: "Moyenne par jour",
				backgroundColor: "rgba(10, 30, 54, 1)",
				fill: true,
				borderColor: "#E4931E",
				radius: 2
			}
		];
		console.log("barchartdata", this.barChartLabels);
		console.log("currentData", this.currentData);
		console.log("this.accountService.team[0].id", this.accountService.team[0].id);

		const nbEx = [];
		const exTravailles = [];
		const act = [];
		for (const date in this.currentData) {
			let sum = 0;
			if (!exTravailles[date]) {
				exTravailles[date] = [];
			}
			if (!nbEx[date]) {
				nbEx[date] = [];
			}
			if (!act[date]) {
				act[date] = [];
			}
			for (const idx in this.currentData[date]) {
				exTravailles[date].push(this.currentData[date][idx].nomEx);
				sum += this.currentData[date][idx].session;
				act[date].push(this.currentData[date][idx].idAct);
			}
			nbEx[date].push(sum);
		}

		const day = this.barChartLabels;

		for (const date in act) {
			act[date] = [...new Set(act[date])];
		}

		const labelAct = [];
		for (const date in act) {
			if (!labelAct[date]) {
				labelAct[date] = [];
			}
			act[date].forEach(id => {
				const name = this.cabriService.activities.find(a => a.id === id).name;
				labelAct[date].push(name);
			});
		}

		console.log("act", labelAct);
		console.log("nbEx", nbEx);
		console.log("exTravailles", exTravailles);

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							let tab = [
								"Taux de réussite moyen : " + v + "%",
								"Nombre d" + "'" + "exercices réalisés : " + nbEx[day[context.dataIndex]],
								"Exercice(s) travaillé(s) : "
							];
							for (const idx in exTravailles[day[context.dataIndex]]) {
								tab = tab.concat("• " + exTravailles[day[context.dataIndex]][idx]);
							}
							tab.push("Activité(s) : ");
							for (const idx in labelAct[day[context.dataIndex]]) {
								tab = tab.concat("• " + labelAct[day[context.dataIndex]][idx]);
							}
							return tab;
						}
					}
				}
			},
			scales: {
				yAxis: {
					suggestedMin: 0,
					suggestedMax: 100
				}
			}
		};
		// this.chart?.update();
	}

	// CODE : CAMEMBERT, NOTE EN CATÉGORIE
	showPourcentage() {
		// destroy previous chart
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.canvasShape = "square";
		this.currentStatisticChoosen = FilteredStatistic.pourcentage;

		// build data
		const results = {
			"Non acquis": 0,
			"En cours d'acquisition": 0,
			"À renforcer": 0,
			Maîtrisé: 0
		};

		console.log("this.currentData", this.currentData);
		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (stat.value < 25) {
						results["Non acquis"]++;
					} else if (stat.value < 50) {
						results["En cours d'acquisition"]++;
					} else if (stat.value < 75) {
						results["À renforcer"]++;
					} else {
						results.Maîtrisé++;
					}
				});
			}
		}
		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["Non acquis", "En cours d'acquisition", "À renforcer", "Maîtrisé"];
		this.barChartType = "pie";
		this.barChartData = [
			{
				data: [results["Non acquis"], results["En cours d'acquisition"], results["À renforcer"], results["Maîtrisé"]],
				label: "Pourcentage de réussite",
				backgroundColor: ["#C0E3FF", "#024D6C", "#0A1E36", "#E4931E"]
			}
		];
	}

	//////////////////////////////////
	// MOYENNE PAR SEMAINE
	showPerWeek() {
		// destroy previous chart
		this.barChartOptions.indexAxis = null;
		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					const date = new Date(key);
					const week = "S" + getWeek(date);

					if (!results[week]) {
						results[week] = [];
					}
					results[week].push(stat);
				});
			}
		}

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartLabels = this.extractLabel(results);
		this.barChartType = "line";

		this.barChartData = [
			{
				data: this.extractAverageData(results, this.accountService.team[0].id),
				label: "Moyenne par semaine",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "rgba(10, 30, 54, 1)"
			}
		];
		console.log("results", results);

		const nbEx = [];
		const exTravailles = [];
		const act = [];

		for (const date in results) {
			let sum = 0;
			if (!exTravailles[date]) {
				exTravailles[date] = [];
			}
			if (!nbEx[date]) {
				nbEx[date] = [];
			}
			if (!act[date]) {
				act[date] = [];
			}
			for (const idx in results[date]) {
				exTravailles[date].push(results[date][idx].nomEx);
				sum += results[date][idx].session;
				act[date].push(results[date][idx].idAct);
			}
			nbEx[date].push(sum);
		}

		for (const date in act) {
			act[date] = [...new Set(act[date])];
		}

		const labelAct = [];
		for (const date in act) {
			if (!labelAct[date]) {
				labelAct[date] = [];
			}
			act[date].forEach(id => {
				const name = this.cabriService.activities.find(a => a.id === id).name;
				labelAct[date].push(name);
			});
		}

		console.log("nbEx", nbEx);
		console.log("exTravailles", exTravailles);

		const week = this.barChartLabels;

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							let tab = [
								"Moyenne : " + v,
								"Nombre d" + "'" + "exercices réalisés : " + nbEx[week[context.dataIndex]],
								"Exercice(s) travaillé(s) : "
							];
							tab = tab.concat(exTravailles[week[context.dataIndex]]);
							tab.push("Activité(s) : ");
							tab = tab.concat(labelAct[week[context.dataIndex]]);
							return tab;
						}
					}
				}
			},
			scales: {
				yAxis: {
					suggestedMin: 0,
					suggestedMax: 100
				}
			}
		};
	}

	// MOYENNE PAR MOIS
	showPerMonth() {
		// destroy previous chart
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.canvasShape = "rectangle";
		console.log("currentData", this.currentData);
		const results = {};
		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					const date = new Date(key);
					const month = getMonth(date);
					const mois = AppUtils.months[month];
					if (!results[mois]) {
						results[mois] = [];
					}
					results[mois].push(stat);
				});
			}
		}

		console.log("results", results);

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartLabels = this.extractLabel(results);
		this.barChartType = "line";

		const nbEx = [];
		const exTravailles = [];
		const act = [];

		for (const date in results) {
			let sum = 0;
			if (!exTravailles[date]) {
				exTravailles[date] = [];
			}
			if (!nbEx[date]) {
				nbEx[date] = [];
			}
			if (!act[date]) {
				act[date] = [];
			}
			for (const idx in results[date]) {
				exTravailles[date].push(results[date][idx].nomEx);
				sum += results[date][idx].session;
				act[date].push(results[date][idx].idAct);
			}
			nbEx[date].push(sum);
		}

		console.log("nbEx", nbEx);
		console.log("exTravailles", exTravailles);

		const month = this.barChartLabels;

		for (const date in act) {
			act[date] = [...new Set(act[date])];
		}

		const labelAct = [];
		for (const date in act) {
			if (!labelAct[date]) {
				labelAct[date] = [];
			}
			act[date].forEach(id => {
				const name = this.cabriService.activities.find(a => a.id === id).name;
				labelAct[date].push(name);
			});
		}

		this.barChartData = [
			{
				data: this.extractAverageData(results, this.accountService.team[0].id),
				label: "Moyenne par mois",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "rgba(10, 30, 54, 1)"
			}
		];

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							let tab = [
								"Moyenne : " + v,
								"Nombre d" + "'" + "exercices réalisés : " + nbEx[month[context.dataIndex]],
								"Exercice(s) travaillé(s) : "
							];
							tab = tab.concat(exTravailles[month[context.dataIndex]]);
							tab.push("Activité(s) : ");
							tab = tab.concat(labelAct[month[context.dataIndex]]);
							return tab;
						}
					}
				}
			},
			scales: {
				yAxis: {
					suggestedMin: 0,
					suggestedMax: 100
				}
			}
		};
	}

	// MOYENNE PAR TRIMESTRE
	showPerTrimester() {
		// on destroy le chart d'avant
		this.barChartOptions.indexAxis = null;
		this.barChartData = null;
		this.barChartOptions.plugins = null;

		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.currentData) {
			if (key) {
				const taDate = this.currentData[key];
				taDate.forEach(stat => {
					const date = new Date(key);
					const month = date.getMonth();

					let trimestre;
					if (month >= 9 && month < 12) {
						trimestre = "T1";
					}
					if (month === 12 || month < 3) {
						trimestre = "T2";
					}
					if (month >= 3 && month < 6) {
						trimestre = "T3";
					}
					if (month >= 6 && month < 9) {
						trimestre = "T4";
					}
					if (!results[trimestre]) {
						results[trimestre] = [];
					}
					results[trimestre].push(stat);
				});
			}
		}

		const nbEx = [];
		const exTravailles = [];
		const act = [];

		for (const date in results) {
			let sum = 0;
			if (!exTravailles[date]) {
				exTravailles[date] = [];
			}
			if (!nbEx[date]) {
				nbEx[date] = [];
			}
			if (!act[date]) {
				act[date] = [];
			}
			for (const idx in results[date]) {
				exTravailles[date].push(results[date][idx].nomEx);
				sum += results[date][idx].session;
				act[date].push(results[date][idx].idAct);
			}
			nbEx[date].push(sum);
		}

		console.log("nbEx", nbEx);
		console.log("exTravailles", exTravailles);

		this.barChartType = "bar";
		this.barChartLabels = this.extractLabel(results);
		this.barChartData = [
			{
				data: this.extractAverageData(results, this.accountService.team[0].id),
				label: "Moyenne par trimestre",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "#E4931E"
			}
		];

		const trim = this.barChartLabels;

		for (const date in act) {
			act[date] = [...new Set(act[date])];
		}

		const labelAct = [];
		for (const date in act) {
			if (!labelAct[date]) {
				labelAct[date] = [];
			}
			act[date].forEach(id => {
				const name = this.cabriService.activities.find(a => a.id === id).name;
				labelAct[date].push(name);
			});
		}

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							let tab = [
								"Moyenne : " + v,
								"Nombre d" + "'" + "exercices réalisés : " + nbEx[trim[context.dataIndex]],
								"Exercice(s) travaillé(s) : "
							];
							tab = tab.concat(exTravailles[trim[context.dataIndex]]);
							tab.push("Activité(s) : ");
							tab = tab.concat(labelAct[trim[context.dataIndex]]);
							return tab;
						}
					}
				}
			},
			scales: {
				yAxis: {
					suggestedMin: 0,
					suggestedMax: 100
				}
			}
		};
	}

	//////////////////////// EXERCICES TERMINES ///////////////////////////////////

	async getExercisesCompleted(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	// OPTIONS //
	// EXERCICES TERMINES PAR JOUR
	async showExercicesCompleted() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.buttonExercices = !this.buttonExercices;
		this.currentStatisticChoosen = FilteredStatistic.exerciceTermines;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		console.log("this.rangeDate.start", this.rangeDate.start);
		this.canvasShape = "rectangle";
		// build data
		this.chartExerciceCompleted = await this.getExercisesCompleted(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);

		// define axis options
		this.barChartOptions.scales = {};

		this.barChartLabels = this.extractLabel(this.chartExerciceCompleted);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartExerciceCompleted),
				label: "Exercices terminés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			}
		];
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	// EXERCICES TERMINES PAR SEMAINE
	showPerWeekE() {
		// destroy previous chart
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.chartExerciceCompleted) {
			if (key) {
				const tabDate = this.chartExerciceCompleted[key];
				tabDate.forEach(stat => {
					const date = new Date(key);
					const week = "S" + getWeek(date);
					if (!results[week]) {
						results[week] = [stat];
					} else {
						results[week][0].value += stat.value;
					}
				});
			}
		}

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartLabels = this.extractLabel(results);
		this.barChartType = "bar";

		this.barChartData = [
			{
				data: this.extractData(results),
				label: "Exercices terminés par semaine",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "#E4931E"
			}
		];
		console.log("results", results);
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	// EXERCICES TERMINES PAR MOIS
	showPerMonthExercices() {
		// destroy previous chart
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;
		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.chartExerciceCompleted) {
			if (key) {
				const tabDate = this.chartExerciceCompleted[key];
				tabDate.forEach(stat => {
					const date = new Date(key);
					const month = getMonth(date);
					const mois = AppUtils.months[month];
					if (!results[mois]) {
						results[mois] = [stat];
					} else {
						results[mois][0].value += stat.value;
					}
				});
			}
		}

		console.log("results", results);

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 400
			}
		};
		this.barChartLabels = this.extractLabel(results);
		this.barChartType = "bar";

		this.barChartData = [
			{
				data: this.extractData(results),
				label: "Exercices terminés par mois",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "#E4931E"
			}
		];
	}

	// EXERCICES TERMINES PAR TRIMESTRES
	showPerTrimesterExercices() {
		// on destroy le chart d'avant
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;
		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.chartExerciceCompleted) {
			if (key) {
				const taDate = this.chartExerciceCompleted[key];
				taDate.forEach(stat => {
					const date = new Date(key);
					const month = date.getMonth();

					let trimestre;
					if (month >= 9 && month < 12) {
						trimestre = "T1";
					}
					if (month === 12 || month < 3) {
						trimestre = "T2";
					}
					if (month >= 3 && month < 6) {
						trimestre = "T3";
					}
					if (month >= 6 && month < 9) {
						trimestre = "T4";
					}
					if (!results[trimestre]) {
						results[trimestre] = [stat];
					} else {
						results[trimestre][0].value += stat.value;
					}
				});
			}
		}
		console.log("results", results);

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartType = "bar";
		this.barChartLabels = this.extractLabel(results);
		this.barChartData = [
			{
				data: this.extractData(results),
				label: "Moyenne par trimestre",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "#E4931E"
			}
		];
	}

	/////////////////////// PARCOURS TERMINES //////////////////////

	async getParcoursCompleted(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const date = new Date();
			const startdate = addMonths(date, -12);
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/journey"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/parcours_session_id": {
						$nin: [null]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}

			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/parcours_session_id",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre de parcours finis
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showParcoursTermines() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.buttonParcours = !this.buttonParcours;
		this.currentStatisticChoosen = FilteredStatistic.parcoursTermines;
		let date;
		let startDate;
		// destroy previous chart
		this.barChartData = null;
		this.canvasShape = "rectangle";
		// build data

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}
		this.chartParcoursFinished = await this.getParcoursCompleted(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);

		// define axis options
		this.barChartOptions.scales = {};

		this.barChartLabels = this.extractLabel(this.chartParcoursFinished);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartParcoursFinished),
				label: "Parcours achevés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			}
		];
	}
	/// PARCOURS PAR SEMAINE

	showPerWeekParcours() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		// destroy previous chart
		this.barChartData = null;
		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.chartParcoursFinished) {
			if (key) {
				const tabDate = this.chartParcoursFinished[key];
				tabDate.forEach(stat => {
					const date = new Date(key);
					const week = "S" + getWeek(date);

					if (!results[week]) {
						results[week] = [stat];
					} else {
						results[week][0].value += stat.value;
					}
				});
			}
		}

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartLabels = this.extractLabel(results);
		this.barChartType = "bar";

		this.barChartData = [
			{
				data: this.extractData(results),
				label: "Parcours par semaine",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "#E4931E"
			}
		];
		console.log("results", results);

		console.log("this.barChartData", this.barChartData);
	}

	// PARCOURS PAR MOIS
	showPerMonthParcours() {
		// destroy previous chart
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartData = null;
		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.chartParcoursFinished) {
			if (key) {
				const tabDate = this.chartParcoursFinished[key];
				tabDate.forEach(stat => {
					const date = new Date(key);
					const month = getMonth(date);
					const mois = AppUtils.months[month];

					if (!results[mois]) {
						results[mois] = [stat];
					} else {
						results[mois][0].value += stat.value;
					}
				});
			}
		}
		console.log("results", results);

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartLabels = this.extractLabel(results);
		this.barChartType = "line";

		this.barChartData = [
			{
				data: this.extractData(results),
				label: "Parcours par mois",
				borderColor: "#E4931E",
				fill: true,
				backgroundColor: "rgba(10, 30, 54, 1)"
			}
		];
	}

	// PARCOURS PAR TRIMESTRE

	showPerTrimesterParcours() {
		// on destroy le chart d'avant
		this.barChartOptions.indexAxis = null;
		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.canvasShape = "rectangle";
		const results = {};
		for (const key in this.chartParcoursFinished) {
			if (key) {
				const taDate = this.chartParcoursFinished[key];
				taDate.forEach(stat => {
					const date = new Date(key);
					const month = date.getMonth();

					let trimestre;
					if (month >= 9 && month < 12) {
						trimestre = "T1";
					}
					if (month === 12 || month < 3) {
						trimestre = "T2";
					}
					if (month >= 3 && month < 6) {
						trimestre = "T3";
					}
					if (month >= 6 && month < 9) {
						trimestre = "T4";
					}
					if (!results[trimestre]) {
						results[trimestre] = [stat];
					} else {
						results[trimestre][0].value += stat.value;
					}
				});
			}
		}

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};
		this.barChartType = "bar";
		this.barChartLabels = this.extractLabel(results);
		this.barChartData = [
			{
				data: this.extractData(results),
				label: "Parcours par trimestre",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			}
		];
	}

	///////////////////// NOMBRE DE CONNEXION / UTILISATEURS PAR JOUR MATHIA /////////////////////

	async getUserUniqueMathia($startDate = null, $endDate = null) {
		// this.buttonUser = !this.buttonUser;
		return new Promise((_resolve, reject) => {
			const date = new Date();
			const startdate = addMonths(date, -12);

			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				// {
				// 	"statement.object.definition.type": {
				// 		$in: ["https://xapi.mathia.education/objectType/exercise"]
				// 	}
				// },
				{
					"statement.actor.account.name": {
						$nin: [
							[
								"1",
								"2",
								"3",
								"4",
								"29",
								"34",
								"48",
								"90",
								"14582",
								"22226",
								"22076",
								"20902",
								"6473",
								"21968",
								"19612",
								"21545",
								"21894",
								"21915",
								"15831",
								"2172",
								"1904",
								"1413",
								"1412"
							]
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});
			const $project = {
				$project: {
					value: "$statement.actor.account.name",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: {
							value: "$value",
							mydate: "$mydate"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				_resolve(stats);
			});
		});
	}
	async showUserUniqueMathia() {
		this.currentStatisticChoosen = FilteredStatistic.utilisateursMathia;

		// destroy previous chart
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "rectangle";
		// build data
		this.currentData = await this.getUserUniqueMathia(this.rangeDate.start, this.rangeDate.end);

		// define axis options

		this.barChartLabels = this.extractLabel(this.currentData);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.currentData),
				label: "Nombre de comptes actifs par jour",
				backgroundColor: "rgba(10, 30, 54, 1)",
				borderColor: "#E4931E",
				radius: 1,
				borderWidth: 2,
				fill: true
			}
		];

		console.log("this.currentData", this.currentData);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	///////////////////// NOMBRE DE CONNEXION / UTILISATEURS PAR JOUR KIDAIA /////////////////////

	async getUserUniqueKidaia($startDate = null, $endDate = null) {
		// this.buttonUser = !this.buttonUser;
		return new Promise((_resolve, reject) => {
			const date = new Date();
			const startdate = addMonths(date, -12);

			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized", "https://xapi.ose/verbs/initialized"]
					}
				},
				// {
				// 	"statement.object.definition.type": {
				// 		$in: ["https://xapi.mathia.education/objectType/exercise"]
				// 	}
				// },
				{
					"statement.actor.account.name": {
						$nin: [
								"1",
								"2",
								"3",
								"4",
								"29",
								"34",
								"48",
								"90",
								"14582",
								"22226",
								"22076",
								"20902",
								"6473",
								"21968",
								"19612",
								"21545",
								"21894",
								"21915",
								"15831",
								"2172",
								"1904",
								"1413",
								"1412"
						]
					}
				},
				{
					$or: [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
								$nin: LrsUtils.codeClassBlacklist
							}
						},
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/codeclasse": {
							 	$nin: LrsUtils.codeClassBlacklist
							}
						}
					]
				},
				{
					$or : [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
								$in: [true]
							}
						}, 
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/ose": {
								$in: [true]
							}
						}
					]
				}
			];
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});
			const $project = {
				$project: {
					value: "$statement.actor.account.name",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: {
							value: "$value",
							mydate: "$mydate"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				_resolve(stats);
			});
		});
	}
	async showUserUniqueKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.utilisateursKidaia;

		// destroy previous chart
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "rectangle";
		// build data
		this.currentData = await this.getUserUniqueKidaia(this.rangeDate.start, this.rangeDate.end);

		// define axis options

		this.barChartLabels = this.extractLabel(this.currentData);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.currentData),
				label: "Nombre de comptes actifs par jour",
				backgroundColor: "rgba(10, 30, 54, 1)",
				borderColor: "#E4931E",
				radius: 1,
				borderWidth: 2,
				fill: true
			}
		];

		console.log("this.currentData", this.currentData);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	// CONNEXION PAR SEMAINE
	// showPerWeekUser() {
	// 	// destroy previous chart
	// 	this.barChartData = null;

	// 	const results = {};
	// 	for (const key in this.currentData) {
	// 		if (key) {
	// 			const tabDate = this.currentData[key];
	// 			tabDate.forEach(stat => {
	// 				const date = new Date(key);
	// 				const week = "S" + getWeek(date);

	// 				if (!results[week]) {
	// 					results[week] = [stat];
	// 				} else {
	// 					results[week][0].value += stat.value;
	// 				}
	// 			});
	// 		}
	// 	}

	// 	this.barChartOptions.scales = {
	// 		yAxis: {
	// 			suggestedMin: 0,
	// 			suggestedMax: 150
	// 		}
	// 	};
	// 	this.barChartLabels = this.extractLabel(results);
	// 	this.barChartType = "bar";

	// 	this.barChartData = [
	// 		{
	// 			data: this.extractData(results),
	// 			label: "Utilisateurs par semaine",
	// 			backgroundColor: "#E4931E",
	// 			fill: true,
	// 			borderColor: "#E4931E"
	// 		}
	// 	];
	// }

	// // CONNEXION PAR MOIS

	// showPerMonthUser() {
	// 	// destroy previous chart
	// 	this.barChartData = null;
	// 	this.canvasShape = "rectangle";
	// 	const results = {};
	// 	for (const key in this.currentData) {
	// 		if (key) {
	// 			const tabDate = this.currentData[key];
	// 			tabDate.forEach(stat => {
	// 				const date = new Date(key);
	// 				const month = getMonth(date);

	// 				const mois = AppUtils.months[month];

	// 				if (!results[mois]) {
	// 					results[mois] = [];
	// 				}
	// 				results[mois].push(stat);
	// 			});
	// 		}
	// 	}

	// 	this.barChartOptions.scales = {
	// 		yAxis: {
	// 			suggestedMin: 0,
	// 			suggestedMax: 100
	// 		}
	// 	};
	// 	this.barChartLabels = this.extractLabel(results);
	// 	this.barChartType = "bar";

	// 	this.barChartData = [
	// 		{
	// 			data: this.extractData(results, this.accountService.team[0].id),
	// 			label: "Utilisateurs par mois",
	// 			backgroundColor: "#E4931E",
	// 			fill: true,
	// 			borderColor: "#E4931E"
	// 		}
	// 	];
	// }

	// // CONNEXION PAR TRIMESTRE

	// showPerTrimesterUser() {
	// 	// on destroy le chart d'avant
	// 	this.barChartData = null;
	// 	this.canvasShape = "rectangle";
	// 	const results = {};
	// 	for (const key in this.currentData) {
	// 		if (key) {
	// 			const taDate = this.currentData[key];
	// 			taDate.forEach(stat => {
	// 				const date = new Date(key);
	// 				const month = date.getMonth();

	// 				let trimestre;
	// 				if (month >= 9 && month < 12) {
	// 					trimestre = "T1";
	// 				}
	// 				if (month === 12 || month < 3) {
	// 					trimestre = "T2";
	// 				}
	// 				if (month >= 3 && month < 6) {
	// 					trimestre = "T3";
	// 				}
	// 				if (month >= 6 && month < 9) {
	// 					trimestre = "T4";
	// 				}
	// 				if (!results[trimestre]) {
	// 					results[trimestre] = [];
	// 				}
	// 				results[trimestre].push(stat);
	// 			});
	// 		}
	// 	}

	// 	this.barChartOptions.scales = {
	// 		yAxis: {
	// 			suggestedMin: 0,
	// 			suggestedMax: 100
	// 		}
	// 	};
	// 	this.barChartType = "bar";
	// 	this.barChartLabels = this.extractLabel(results);
	// 	this.barChartData = [
	// 		{
	// 			data: this.extractData(results, this.accountService.team[0].id),
	// 			label: "Utilisateur par trimestre",
	// 			backgroundColor: "#E4931E",
	// 			fill: true,
	// 			borderColor: "#E4931E"
	// 		}
	// 	];
	// }

	// async getNombreActivité() {
	//      return new Promise((resolve, reject) => {
	//          const date = new Date();
	//          const startdate = addMonths(date, -12);

	//          const request = [];
	//          const match: Array<any> = [
	//              {
	//                  "statement.verb.id": {
	//                      $in: ["https://xapi.mathia.education/verbs/finished"]
	//                  }
	//              },
	//                          {
	//                              "statement.context.extensions": {
	//                                  $in: ["https://xapi&46;mathia&46;education/extensions/id_activite"]
	//                              }
	//                          },
	//                          {
	//                              "statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
	//                                  $nin: LrsUtils.codeClassBlacklist
	//                              }
	//                          },
	//                          {
	//                              timestamp: {
	//                                  $gte: {
	//                                      $dte: LrsUtils.convertDateToISO8601(startdate)
	//                                  }
	//                              }
	//                          }

	//                      ];
	//                      request.push({
	//                          $match:{
	//                              $and: match
	//                          }
	//                      });

	//                  const $project = {
	//                      $project: {
	//                      value: "$statement.context.extensions.https://xapi.mathia.education/extensions/id_activite",
	//                      mydate: {
	//                          $dateToString: {
	//                              format: "%d-%m-%Y",
	//                              date: {
	//                                  $toDate: "statement.timestamp"
	//                              }
	//                          }
	//                      }
	//                  }
	//              };
	//              request.push($project);
	//              request.push(
	//              {
	//                  $group: {
	//                      _id: { date: "$mydate"},
	//                      value: {
	//                          $sum: 1
	//                      }
	//                  }
	//              },
	//              {
	//                  $project:{
	//                      _id: 0,
	//                      date: "$_id.date",
	//                      value: 1
	//                  }
	//              },
	//              {
	//                  $sort:{
	//                      date: 1
	//                  }
	//              }
	//              );
	//              this.lrsService.request(request).then(async (results: any) => {
	//                  const stats = {};
	//                  //console.log(results)
	//                  results.forEach(item => {
	//                      //compare s'il y a une valeur (une moyenne) à une date
	//                      if (!item.date) {
	//                          return;
	//                      }
	//                      if (!stats[item.date]) {
	//                          stats[item.date] = [];
	//                      }
	//                      stats[item.date].push(item);
	//                  });
	//                  resolve(stats);
	//              });
	//          });
	// }
	// async showNameParcours() {
	//     // destroy previous chart
	//     this.barChartData = null;
	//     this.canvasShape = "rectangle";
	//     // build data
	//    this.currentData = await this.getNombreActivité();

	//     // define axis options
	//     this.barChartOptions.scales = {};

	//     this.barChartLabels = this.extractLabel(this.currentData);
	//     this.barChartType = "bar";
	//     this.barChartData = [
	//         {
	//             data: this.extractData(this.currentData),
	//             label: "Parcours par jour",
	//             backgroundColor: "#E4931E",
	//             fill: true,
	//             borderColor: "#E4931E"
	//         }
	//     ];
	//     console.log("this.barChartLabels", this.barChartLabels);
	//     console.log("this.barChartData", this.barChartData);
	// }

	//////PARTIE PROF//////

	// //////////////////////// EXERCICES TERMINÉES PAR CLASSE ///////////
	// async getExercisesCompletedClasse(userId, classId, $startDate = null, $endDate = null) {
	// 	return new Promise((resolve, reject) => {
	// 		const request = [];
	// 		const match: Array<any> = [
	// 			{
	// 				"statement.verb.id": {
	// 					$in: ["https://xapi.mathia.education/verbs/completed"]
	// 				}
	// 			},
	// 			{
	// 				"statement.object.definition.type": {
	// 					$in: ["https://xapi.mathia.education/objectType/exercise"]
	// 				}
	// 			},
	// 			{
	// 				"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
	// 					$in: [classId]
	// 				}
	// 			}
	// 		];
	// 		if ($startDate || $endDate) {
	// 			if ($startDate) {
	// 				match.push({
	// 					timestamp: {
	// 						$gte: {
	// 							$dte: $startDate
	// 						}
	// 					}
	// 				});
	// 			}
	// 			if ($endDate) {
	// 				match.push({
	// 					timestamp: {
	// 						$lte: {
	// 							$dte: $endDate
	// 						}
	// 					}
	// 				});
	// 			}
	// 		} else {
	// 			match.push({
	// 				timestamp: {
	// 					$gte: {
	// 						$dte: "2021-01-01T08:09:00.000Z"
	// 					}
	// 				}
	// 			});
	// 		}
	// 		request.push({
	// 			$match: {
	// 				$and: match
	// 			}
	// 		});

	// 		const $project = {
	// 			$project: {
	// 				value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
	// 				mydate: {
	// 					$dateToString: {
	// 						format: "%Y-%m-%d",
	// 						date: {
	// 							$toDate: "$statement.timestamp"
	// 						}
	// 					}
	// 				}
	// 			}
	// 		};
	// 		request.push($project);
	// 		request.push(
	// 			{
	// 				$group: {
	// 					_id: { date: "$mydate" },
	// 					value: {
	// 						//compte le nombre d'exercices terminées
	// 						$sum: 1
	// 					}
	// 				}
	// 			},
	// 			{
	// 				$project: {
	// 					_id: 0,
	// 					date: "$_id.date",
	// 					value: 1
	// 				}
	// 			},

	// 			{
	// 				$sort: {
	// 					date: 1
	// 				}
	// 			}
	// 		);
	// 		this.lrsService.request(request).then(async (results: any) => {
	// 			const stats = {};
	// 			console.log(results);
	// 			results.forEach(item => {
	// 				//compare s'il y a une valeur (une moyenne) à une date
	// 				if (!item.date) {
	// 					return;
	// 				}
	// 				if (!stats[item.date]) {
	// 					stats[item.date] = [];
	// 				}
	// 				stats[item.date].push(item);
	// 			});
	// 			resolve(stats);
	// 		});
	// 	});
	// }

	// async showExercicesCompletedClass() {
	// 	// destroy previous chart
	// 	this.barChartData = null;
	// 	this.barChartOptions.indexAxis = null;
	// 	this.currentStatisticChoosen = FilteredStatistic.exerciceTerminesClass;
	// 	let date;
	// 	let startDate;

	// 	if (!this.rangeDate.start) {
	// 		date = new Date(this.filterDataFrom);
	// 		startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
	// 		this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
	// 	}

	// 	if (!this.rangeDate.end) {
	// 		this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
	// 	}

	// 	console.log("this.rangeDate.start", this.rangeDate.start);
	// 	this.barChartData = null;
	// 	this.canvasShape = "rectangle";
	// 	// build data
	// 	const chartExerciceCompletedClass: any = await this.getExercisesCompletedClasse(
	// 		this.accountService.team[0].id,
	// 		this.accountService.classroom.id,
	// 		this.rangeDate.start,
	// 		this.rangeDate.end
	// 	);

	// 	// define axis options
	// 	this.barChartOptions.scales = {};

	// 	this.barChartLabels = this.extractLabel(chartExerciceCompletedClass);
	// 	this.barChartType = "bar";
	// 	this.barChartData = [
	// 		{
	// 			data: this.extractData(chartExerciceCompletedClass),
	// 			label: "Exercices terminés par jour",
	// 			backgroundColor: "#E4931E",
	// 			fill: true,
	// 			borderColor: "#E4931E"
	// 		}
	// 	];
	// 	console.log("classlabels", this.barChartLabels);
	// 	console.log("classData", this.barChartData);
	// }

	/// MOYENNE DE LA CLASSE ///

	async averageStatisticsPerGoodAnswerClass(classId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$in: [Number(classId)]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							classId: "$classId",
							tauxBonnesReponses: "$pourcentage",
							mydate: "$mydate"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", classId: "$_id.classId" },
						taux: {
							// calcule la moyenne de taux des bonnes reponses
							$avg: "$_id.tauxBonnesReponses"
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						classId: "$_id.classId",
						value: { $ceil: "$taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value && item.classId) {
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						if (!stats[item.date][item.classId]) {
							stats[item.date][item.classId] = [];
						}
						stats[item.date][item.classId].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async getAveragePerGoodAnswerClass() {
		this.barChartOptions.indexAxis = null;
		this.barChartData = null;
		this.barChartOptions.plugins = null;

		this.currentStatisticChoosen = FilteredStatistic.moyenneClasse;
		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		this.currentDataClass = await this.averageStatisticsPerGoodAnswerClass(
			this.accountService.classroom.id,
			this.rangeDate.start,
			this.rangeDate.end
		);

		this.currentData = await this.averageStatisticsPerGoodAnswer(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);

		console.log("date", this.rangeDate.start);
		console.log("datefin", this.rangeDate.end);

		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 100
			}
		};

		var seuil = new Array(this.extractDataClass(this.currentDataClass).length).fill(Number(50));
		this.barChartLabels = this.extractLabel(this.currentData);
		this.barChartType = "line";
		this.barChartData = [
			{
				data: this.extractDataClass(this.currentDataClass),
				label: "Moyenne de la classe",
				backgroundColor: "rgba(10, 30, 54, 1)",
				fill: false,
				borderColor: "#E4931E",
				radius: 2
			},
			{
				data: this.extractData(this.currentData, this.accountService.team[0].id),
				label: "Moyenne de l'élève",
				backgroundColor: "rgba(10, 30, 54, 1)",
				fill: false,
				borderColor: "#FFCC99",
				radius: 2
			},
			{
				data: seuil,
				label: "Seuil",
				borderColor: "#A2F2B1",
				backgroundColor: "rgba(10, 30, 54, 1)",
				radius: 0,
				borderDash: [10, 10],
				borderWidth: 0.5
			}
		];
		console.log("barchartdata", this.barChartData);
		console.log("currentdataclass", this.currentDataClass);
		console.log("barchartlabels", this.barChartLabels);
	}

	extractDataClass(chartData, userId = null) {
		const data = [];
		for (const key in chartData) {
			for (const key2 in chartData[key]) {
				if (key2) {
					if (userId) {
						const userData = chartData[key][key2].find(item => {
							return Number(item.userId) === Number(userId);
						});
						data.push(userData ? userData.value : null);
					} else {
						if (chartData[key][key2].length === 1) {
							chartData[key][key2].forEach(item => {
								data.push(item.value);
							});
						} else {
							let total = 0;
							chartData[key][key2].forEach(data => {
								total += data.value;
							});
							const average = Math.round(total / chartData[key][key2].length);
							data.push(average);
						}
					}
				}
			}
		}
		return data;
	}

	////GRAPHE DUREE MOYENNE PAR EXERCICE////

	async getAverageTime(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					timeDuration: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					userId: "$statement.actor.account.name",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							time: "$timeDuration",
							mydate: "$mydate"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId" },
						duration: {
							// calcule la moyenne de taux des bonnes reponses
							$avg: "$_id.time"
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						value: { $ceil: "$duration" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value) {
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async showAverageTime() {
		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;

		this.currentStatisticChoosen = FilteredStatistic.duration;
		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		this.currentDataTime = await this.getAverageTime(this.accountService.team[0].id, this.rangeDate.start, this.rangeDate.end);
		this.barChartOptions.scales = {
			yAxis: {
				suggestedMin: 0,
				suggestedMax: 350
			}
		};
		this.barChartOptions.indexAxis = "y";
		this.barChartLabels = this.extractLabel(this.currentDataTime);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.currentDataTime, this.accountService.team[0].id),
				label: "Durée moyenne par exercice (en seconde)",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			}
		];
		console.log("barchartlabel", this.barChartLabels);
		console.log("barchartdata", this.barChartData);
		console.log("currentDataTime", this.currentDataTime);
		console.log("this.accountService.team[0].id", this.accountService.team[0].id);
	}

	///GRAPHE EXERCICES COMMENCES ET TERMINES///

	///RECUPERATION DU NOMBRE D'EXERCICES COMMENCÉS///

	async getExercisesInitialized(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showExercicesInitializedCompleted() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.currentStatisticChoosen = FilteredStatistic.exCommencesTermines;
		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		console.log("this.rangeDate.start", this.rangeDate.start);
		this.canvasShape = "rectangle";
		// build data
		this.chartExerciceInitialized = await this.getExercisesInitialized(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);
		this.chartExerciceCompleted = await this.getExercisesCompleted(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);

		// define axis options
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
		this.barChartLabels = this.extractLabel(this.chartExerciceInitialized);

		//Compléter les exs terminés

		for (const label in this.chartExerciceInitialized) {
			if (!this.chartExerciceCompleted[label]) {
				this.chartExerciceCompleted[label] = [];
				this.chartExerciceCompleted[label].push({ value: 0, date: label });
			}
		}

		//Récupérer données dans le même ordre que chartExInit

		const newChartExCompleted = [];
		for (const label in this.barChartLabels) {
			newChartExCompleted.push(this.chartExerciceCompleted[this.barChartLabels[label]][0].value);
		}

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartExerciceInitialized),
				label: "Exercices commencés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			},
			{
				data: newChartExCompleted,
				label: "Exercices terminés par jour",
				backgroundColor: "#F22E4F",
				fill: true,
				borderColor: "#F22E4F"
			}
		];
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	///EXERCICES TERMINES PAR GABARITS///

	async getExercisesCompletedGabarits(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							//compte le nombre d'exercices terminés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showExercicesCompletedPerGabarit() {
		this.currentStatisticChoosen = FilteredStatistic.exTerminesGabarit;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getExercisesCompletedGabarits(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);
		console.log("data", this.chartExercicePerGabarit);
		console.error(this.cabriService.activities.find(a => a.id === "1").name);
		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);
		this.barChartType = "bar";
		(this.barChartData = [
			{
				data: this.extractData(this.chartExercicePerGabarit),
				label: "Exercices terminés par gabarit",
				backgroundColor: [
					"rgba(255, 99, 132, 0.2)",
					"rgba(255, 159, 64, 0.2)",
					"rgba(255, 205, 86, 0.2)",
					"rgba(75, 192, 192, 0.2)",
					"rgba(54, 162, 235, 0.2)",
					"rgba(153, 102, 255, 0.2)",
					"rgba(201, 203, 207, 0.2)"
				], //Rajouter couleurs
				fill: true,
				borderColor: [
					"rgb(255, 99, 132)",
					"rgb(255, 159, 64)",
					"rgb(255, 205, 86)",
					"rgb(75, 192, 192)",
					"rgb(54, 162, 235)",
					"rgb(153, 102, 255)",
					"rgb(201, 203, 207)"
				],
				borderWidth: 1
			}
		]),
			(this.barChartOptions.scales = {
				y: {
					beginAtZero: true
				}
			});
	}

	/////FILTRES COMPETENCES//////

	//Reconstruire arbre des competences et des erreurs
	getCategoryParent(competences, competenceId) {
		let found;
		competences.forEach(competence => {
			if (competence.id == competenceId) {
				if (competence.parent == "0") {
					found = competence;
				} else {
					found = this.getCategoryParent(competences, competence.parent);
				}
			}
		});
		return found;
	}

	// PREMIER GRAPHE ENTRAINEMENT

	// async averagePerCompetences(userId, $startDate = null, $endDate = null) {
	// 	return new Promise((resolve, reject) => {
	// 		const request = [];

	// 		const match: Array<any> = [
	// 			{
	// 				"statement.verb.id": {
	// 					$in: ["https://xapi.mathia.education/verbs/completed"]
	// 				}
	// 			},
	// 			{
	// 				"statement.object.definition.type": {
	// 					$in: ["https://xapi.mathia.education/objectType/exercise"]
	// 				}
	// 			},

	// 			{
	// 				"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
	// 					$nin: [null]
	// 				}
	// 			},
	// 			{
	// 				"statement.actor.account.name": {
	// 					$in: [userId]
	// 				}
	// 			}

	// 		];
	// 		if ($startDate || $endDate) {
	// 			if ($startDate) {
	// 				match.push({
	// 					timestamp: {
	// 						$gte: {
	// 							$dte: $startDate
	// 						}
	// 					}
	// 				});
	// 			}
	// 			if ($endDate) {
	// 				match.push({
	// 					timestamp: {
	// 						$lte: {
	// 							$dte: $endDate
	// 						}
	// 					}
	// 				});
	// 			}
	// 		} else {
	// 			match.push({
	// 				timestamp: {
	// 					$gte: {
	// 						$dte: "2021-01-01T08:09:00.000Z"
	// 					}
	// 				}
	// 			});
	// 		}
	// 		request.push({
	// 			$match: {
	// 				$and: match
	// 			}
	// 		});

	// 		const $project = {
	// 			$project: {
	// 				pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
	// 				competence: "$statement.object.definition.extensions.https://xapi&46;mathia&46;education/extensions/competences",
	// 				userId: "$statement.actor.account.name",
	// 				session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
	// 				mydate: {
	// 					$dateToString: {
	// 						format: "%Y-%m-%d",
	// 						date: {
	// 							$toDate: "$statement.timestamp"
	// 						}
	// 					}
	// 				}
	// 			}
	// 		};
	// 		request.push($project);

	// 		request.push(
	// 			{
	// 				$group: {
	// 					_id: {
	// 						userId: "$userId",
	// 						tauxBonnesReponses: "$pourcentage",
	// 						comp: "$competence",
	// 						mydate: "$mydate",
	// 						session: "$session"
	// 					}
	// 				}
	// 			},
	// 			{
	// 				$group: {
	// 					_id: {
	// 						date: "$_id.mydate", userId: "$_id.userId", competence: "$_id.comp", tauxBonnesReponses: "$_id.tauxBonnesReponses", session: "$_id.session"
	// 					}
	// 				}
	// 			},
	// 			{
	// 				$project: {
	// 					_id: 0,
	// 					date: "$_id.date",
	// 					userId: "$_id.userId",
	// 					competence: "$_id.competence",
	// 					value: { $ceil: "$_id.tauxBonnesReponses" }
	// 				}
	// 			},
	// 			{
	// 				$sort: {
	// 					date: 1
	// 				}
	// 			}
	// 		);
	// 		console.log('request', request);

	// 		this.lrsService.request(request).then(async (results: any) => {
	// 			const stats = {};
	// 			console.log("results", results);
	// 			results.forEach(item => {
	// 				//compare s'il y a une valeur (une moyenne) à une date
	// 				if (item.date && item.value) {
	// 					if (!stats[item.date]) {
	// 						stats[item.date] = [];
	// 					}
	// 					stats[item.date].push(item);
	// 				}
	// 			});

	// 			resolve(stats);
	// 			console.log("statssss", stats);
	// 		});
	// 	});
	// }

	// async getCompetenceId() {
	// 	this.dataCompetence = await this.averagePerCompetences(
	// 		this.accountService.team[0].id,
	// 		this.rangeDate.start,
	// 		this.rangeDate.end);
	// 	console.log("df", this.dataCompetence);

	// 	this.http.get('https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_skills_tree').subscribe(data => {
	// 		//Recuperer liste des id competences par date
	// 		const competences = data;

	// 		const results = [];
	// 		const listIdCompetence = {};
	// 		for (const date in this.dataCompetence) {
	// 			var idCompetences = [];
	// 			var value = [];
	// 			for (const idx in this.dataCompetence[date]) {

	// 				const exo = this.dataCompetence[date][idx];

	// 				const idCompetences = Object.keys(JSON.parse(exo.competence));
	// 				//Ne pas prendre en compte les exercices sans extension competences
	// 				if (idCompetences.length != 0) {
	// 					categoryParent = [];
	// 					idCompetences.forEach((idCompetence) => {
	// 						const parent = this.getCategoryCompetences(competences, idCompetence);
	// 						if (categoryParent.indexOf(parent) === -1) {
	// 							categoryParent.push(parent);
	// 						}
	// 					});

	// 					categoryParent.forEach(category => {
	// 						if (!results[category.name]) {
	// 							results[category.name] = {
	// 								notes: [],
	// 								moyenne: []
	// 							}
	// 						}
	// 						results[category.name].notes.push(exo.value);

	// 					});
	// 					for (const parent in results) {
	// 						let sum = 0;
	// 						for (let i = 0; i < results[parent].notes.length; i++) {
	// 							sum += results[parent].notes[i];
	// 						}
	// 						results[parent].moyenne = Math.round(sum / results[parent].notes.length);
	// 					}

	// 				}
	// 			}
	// 		}
	// 		var categoryParent = [];
	// 		console.log("resFinal", results);

	// 		//GRAPHIQUE
	// 		this.barChartData = null;
	// 		this.barChartOptions.indexAxis = null;
	// 		// this.buttonExercices = !this.buttonExercices;
	// 		this.currentStatisticChoosen = FilteredStatistic.competence;
	// 		this.canvasShape = "rectangle";

	// 		// define axis options
	// 		this.barChartOptions.scales = {};
	// 		this.barChartLabels = Object.keys(results);
	// 		this.barChartType = "bar";
	// 		this.barChartOptions.indexAxis = 'y';
	// 		// build data
	// 		let dataComp = [];
	// 		for (const parent in results) {
	// 			dataComp.push(results[parent].moyenne);
	// 		}
	// 		this.barChartData = [
	// 			{
	// 				data: dataComp,
	// 				label: "Moyenne par compétence",
	// 				backgroundColor: "#E4931E",
	// 				fill: true,
	// 				borderColor: "#E4931E"
	// 			}
	// 		];
	// 	}
	// 	);
	// }

	///HEATMAP DES COMPETENCES PARENTS///

	async getHeatMap(classId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$in: [Number(classId)]
					}
				}
			];
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					competence: "$statement.object.definition.extensions.https://xapi&46;mathia&46;education/extensions/competences",
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					nomEx: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/nom_exercice",
					duration: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							tauxBonnesReponses: "$pourcentage",
							comp: "$competence",
							mydate: "$mydate",
							session: "$session",
							duration: "$duration",
							nomEx: "$nomEx"
						}
					}
				},
				{
					$group: {
						_id: {
							date: "$_id.mydate",
							userId: "$_id.userId",
							competence: "$_id.comp",
							taux: "$_id.tauxBonnesReponses",
							session: "$_id.session",
							nomEx: "$_id.nomEx",
							duration: "$_id.duration"
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						competence: "$_id.competence",
						nomEx: "$_id.nomEx",
						duration: "$_id.duration",
						value: { $ceil: "$_id.taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value && item.userId) {
						if (!stats[item.userId]) {
							stats[item.userId] = [];
						}

						stats[item.userId].push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async getCompetenceIdClass() {
		this.currentStatisticChoosen = FilteredStatistic.competence;
		this.buttonComp = !this.buttonComp;

		this.dataCompetenceUser = await this.getHeatMap(this.accountService.classroom.id, this.rangeDate.start, this.rangeDate.end);

		this.http.get("https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_skills_tree").subscribe(data => {
			//Recuperer liste des id competences par date
			const competences = data;
			const user = Object.keys(this.dataCompetenceUser);

			const results = [];
			const listIdCompetence = {};
			for (const user in this.dataCompetenceUser) {
				// this.accountService.team[0].id === user
				if (true) {
					var idCompetences = [];
					var value = [];
					for (const idx in this.dataCompetenceUser[user]) {
						const exo = this.dataCompetenceUser[user][idx];

						const idCompetences = Object.keys(JSON.parse(exo.competence));
						//Ne pas prendre en compte les exercices sans extension competences
						if (idCompetences.length != 0) {
							categoryParent = [];
							idCompetences.forEach(idCompetence => {
								const parent = this.getCategoryParent(competences, idCompetence);
								if (categoryParent.indexOf(parent) === -1) {
									categoryParent.push(parent);
								}
							});

							if (!results[user]) {
								results[user] = [];
							}

							categoryParent.forEach(category => {
								if (!results[user][category.name]) {
									results[user][category.name] = {
										notes: [],
										moyenne: [],
										nbEssai: []
									};
								}
								results[user][category.name].notes.push(exo.value);
							});
						}
					}
				}
			}
			for (const user in results) {
				for (const comp in results[user]) {
					let sum = 0;
					results[user][comp].notes.forEach(value => {
						sum += value;
					});
					results[user][comp].moyenne = Math.round(sum / results[user][comp].notes.length);
					results[user][comp].nbEssai = results[user][comp].notes.length;
				}
			}
			var categoryParent = [];
			console.log("resFinalll", results);

			const competenceParent = [];
			for (const idx in data) {
				if (data[idx].parent === "0") {
					competenceParent.push(data[idx].name);
				}
			}
			competenceParent.splice(26, 36);

			///HEATMAP DES COMPETENCES PARENTS///

			//destroy
			this.barChartData = null;
			this.barChartOptions.indexAxis = null;

			//build data
			const dataHeatMap = [];
			let labelUser = [];
			for (const user in results) {
				for (const comp in results[user]) {
					const student = this.accountService.classroom.students.find(s => s.id === user);
					if (student) {
						labelUser.push(student.name);
						dataHeatMap.push({ x: comp, y: student.name, v: results[user][comp].moyenne, nb: results[user][comp].nbEssai });
					}
				}
			}

			labelUser = [...new Set(labelUser)];

			//Remplir le vide
			for (const user in results) {
				for (const parent in competenceParent) {
					const student = this.accountService.classroom.students.find(s => s.id === user);
					if (student) {
						if (!results[user][competenceParent[parent]]) {
							dataHeatMap.push({ x: competenceParent[parent], y: student.name, v: "Non noté", nb: 0 });
						}
					}
				}
			}

			//Légende
			const res = {
				"Non acquis": [],
				"En cours d'acquisition": [],
				"À renforcer": [],
				Maîtrisé: [],
				"Non travaillé": []
			};
			dataHeatMap.forEach(stat => {
				if (stat.v < 25 && stat.v != "Non noté") {
					res["Non acquis"].push(stat);
				} else if (stat.v < 50 && stat.v != "Non noté") {
					res["En cours d'acquisition"].push(stat);
				} else if (stat.v < 75 && stat.v != "Non noté") {
					res["À renforcer"].push(stat);
				} else if (stat.v === "Non noté") {
					res["Non travaillé"].push(stat);
				} else {
					res["Maîtrisé"].push(stat);
				}
			});

			console.log("res", res);
			this.barChartType = "matrix";
			this.barChartData = [
				{
					label: "Maitrîsée",
					data: res["Maîtrisé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#A2F5AA",
					width: ({ chart }) => (chart.chartArea || {}).width / competenceParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "À renforcer",
					data: res["À renforcer"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EFF5A4",
					width: ({ chart }) => (chart.chartArea || {}).width / competenceParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "En cours d'acquisition",
					data: res["En cours d'acquisition"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EDA864",
					width: ({ chart }) => (chart.chartArea || {}).width / competenceParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non acquis",
					data: res["Non acquis"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ED928A",
					width: ({ chart }) => (chart.chartArea || {}).width / competenceParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non travaillé",
					data: res["Non travaillé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ABBBF7",
					width: ({ chart }) => (chart.chartArea || {}).width / competenceParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				}
			];
			this.barChartOptions = {
				plugins: {
					legend: {
						display: true,
						position: "top"
					},
					tooltip: {
						callbacks: {
							label(context) {
								const v = context.dataset.data[context.dataIndex] as any;
								return [
									"Compétence : " + v.x,
									"Élève : " + v.y,
									"Moyenne : " + v.v,
									"Nombre d" + "'" + "exercices réalisés : " + v.nb
								];
							}
						}
					}
				},
				scales: {
					x: {
						type: "category",
						labels: competenceParent,
						display: true,
						offset: true
					},
					y: {
						type: "category",
						labels: labelUser,
						display: true,
						offset: true
					}
				},
				responsive: true,
				maintainAspectRatio: false
			};
		});
	}

	///HEATMAP DES SOUS COMPETENCES///

	getSousCategory(competences, idCompetenceParent) {
		let found = [];
		competences.forEach(competence => {
			if (competence.parent == idCompetenceParent) {
				found.push(competence);
			}
		});
		return found;
	}

	//Trouver les sous (x1) compétences donc pas les parents et ceux avec 0 enfant
	getObject(competences, idCompetence) {
		let found = [];
		competences.forEach(competence => {
			if (competence.id == idCompetence && competence.parent != "0") {
				found = competence.name;
			}
		});
		return found;
	}

	async getSousCompetenceIdClass(competenceP) {
		this.currentStatisticChoosen = FilteredStatistic.sousCompetence;

		if (competenceP === "Lire l heure") {
			competenceP = "Lire l" + "'" + "heure";
		}

		this.dataCompetenceUser = await this.getHeatMap(this.accountService.classroom.id, this.rangeDate.start, this.rangeDate.end);
		console.log("datacomppp", this.dataCompetenceUser);

		this.http.get("https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_skills_tree").subscribe(data => {
			const competences = data;

			//Récupérer toutes les compétences parents
			const competenceParent = [];
			for (const idx in data) {
				if (data[idx].parent === "0") {
					competenceParent.push(data[idx]);
				}
			}
			competenceParent.splice(26, 36);

			const results = [];
			competenceParent.forEach(parent => {
				if (!results[parent.name]) {
					results[parent.name] = [];
				}
				const sousComp = this.getSousCategory(competences, parent.id);
				results[parent.name].push(sousComp);
			});

			const user = Object.keys(this.dataCompetenceUser);

			const listIdCompetence = {};
			const dataUser = [];

			for (const user in this.dataCompetenceUser) {
				const student = this.accountService.classroom.students.find(s => s.id === user);
				// this.accountService.team[0].id === user
				if (true) {
					var idCompetences = [];
					var value = [];
					for (const idx in this.dataCompetenceUser[user]) {
						const exo = this.dataCompetenceUser[user][idx];
						const idCompetences = Object.keys(JSON.parse(exo.competence));
						const nameCompetences = Object.values(JSON.parse(exo.competence));

						//Ne pas prendre en compte les exercices sans extension competences
						if (idCompetences.length != 0 && student) {
							if (!dataUser[user]) {
								dataUser[user] = [];
							}
							competenceParent.forEach(parent => {
								if (!dataUser[user][parent.name]) {
									dataUser[user][parent.name] = [];
								}
							});

							for (const parent in results) {
								for (const comp in results[parent][0]) {
									if (!dataUser[user][parent][results[parent][0][comp].name]) {
										dataUser[user][parent][results[parent][0][comp].name] = {
											notes: [],
											moyenne: [],
											exTravailles: [],
											temps: []
										};
									}
								}
							}

							idCompetences.forEach(idCompetence => {
								const parent = this.getCategoryParent(competences, idCompetence);
								const comp = this.getObject(competences, idCompetence);
								const sousComp = this.getSousCategory(competences, parent.id);

								for (const idx in sousComp) {
									if (comp.length != 0 && String(comp) === sousComp[idx].name) {
										dataUser[user][parent.name][comp].notes.push(exo.value);
										dataUser[user][parent.name][comp].exTravailles.push(exo.nomEx);
										dataUser[user][parent.name][comp].temps.push(exo.duration);
									}
								}
							});
						}
					}
				}
			}

			//Calcul moyenne et nb essais

			for (const user in dataUser) {
				for (const compParent in dataUser[user]) {
					for (const sousComp in dataUser[user][compParent]) {
						let sum = 0;
						dataUser[user][compParent][sousComp].notes.forEach(value => {
							sum += value;
						});
						dataUser[user][compParent][sousComp].moyenne = Math.round(sum / dataUser[user][compParent][sousComp].notes.length);
						dataUser[user][compParent][sousComp].nbEssai = dataUser[user][compParent][sousComp].notes.length;
					}
				}
			}

			///GRAPHIQUES///

			//destroy
			this.barChartData = null;
			this.barChartOptions.indexAxis = null;

			//build data
			const dataHeatMap = [];
			let labelUser = [];
			for (const user in dataUser) {
				const student = this.accountService.classroom.students.find(s => s.id === user);
				labelUser.push(student.name);
				for (const compParent in dataUser[user]) {
					if (!dataHeatMap[compParent]) {
						dataHeatMap[compParent] = [];
					}
					for (const sousComp in dataUser[user][compParent]) {
						dataHeatMap[compParent].push({
							x: sousComp,
							y: student.name,
							v: dataUser[user][compParent][sousComp].moyenne,
							nb: dataUser[user][compParent][sousComp].nbEssai,
							ex: dataUser[user][compParent][sousComp].exTravailles,
							notes: dataUser[user][compParent][sousComp].notes,
							tps: dataUser[user][compParent][sousComp].temps
						});
					}
				}
			}

			console.log("dataheatmap", dataHeatMap);

			//Remplacer NaN par du texte
			for (const parent in dataHeatMap) {
				for (const idx in dataHeatMap[parent]) {
					if (isNaN(dataHeatMap[parent][idx].v)) {
						dataHeatMap[parent][idx].v = "Non noté";
					}
				}
			}

			//Légende
			const res = {
				"Non acquis": [],
				"En cours d'acquisition": [],
				"À renforcer": [],
				Maîtrisé: [],
				"Non travaillé": []
			};

			dataHeatMap[competenceP].forEach(stat => {
				if (stat.v < 25 && stat.v != "Non noté") {
					res["Non acquis"].push(stat);
				} else if (stat.v < 50 && stat.v != "Non noté") {
					res["En cours d'acquisition"].push(stat);
				} else if (stat.v < 75 && stat.v != "Non noté") {
					res["À renforcer"].push(stat);
				} else if (stat.v === "Non noté") {
					res["Non travaillé"].push(stat);
				} else {
					res["Maîtrisé"].push(stat);
				}
			});

			console.log("res", res);

			//Nom sous compétence concernée
			const name = [];
			for (const idx in results[competenceP][0]) {
				name.push(results[competenceP][0][idx].name);
			}
			console.log("name", name);

			this.barChartType = "matrix";
			this.barChartData = [
				{
					label: "Maitrîsée",
					data: res["Maîtrisé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#A2F5AA",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "À renforcer",
					data: res["À renforcer"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EFF5A4",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "En cours d'acquisition",
					data: res["En cours d'acquisition"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EDA864",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non acquis",
					data: res["Non acquis"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ED928A",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non travaillé",
					data: res["Non travaillé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ABBBF7",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				}
			];
			this.barChartOptions = {
				plugins: {
					legend: {
						display: true,
						position: "top"
					},
					tooltip: {
						callbacks: {
							label(context) {
								const v = context.dataset.data[context.dataIndex] as any;
								let tab = ["Élève : " + v.y, "Taux de réussite moyen : " + v.v, "Exercices travaillés : "];
								let sum = 0;
								for (const idx in v.ex) {
									sum += v.tps[idx];
									tab = tab.concat("• " + v.ex[idx]);
									tab = tab.concat("avec un taux de réussite de " + v.notes[idx] + "%");
								}
								tab = tab.concat("Temps de travail total : " + Math.round((sum / 60) * 100) / 100 + "'");
								return tab;
							}
						}
					}
				},
				scales: {
					x: {
						type: "category",
						labels: name,
						display: true,
						offset: true
					},
					y: {
						type: "category",
						labels: labelUser,
						display: true,
						offset: true
					}
				},
				responsive: true,
				maintainAspectRatio: false
			};

			console.log("dataUser", dataUser);
		});
	}

	async getSousCompetenceIdClassTest(competenceP) {
		this.currentStatisticChoosen = FilteredStatistic.sousCompetence;

		this.dataCompetenceUser = await this.getHeatMap("70227", this.rangeDate.start, this.rangeDate.end);
		console.log("datacomppp", this.dataCompetenceUser);

		this.http.get("https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_skills_tree").subscribe(data => {
			const competences = data;

			//Récupérer toutes les compétences parents
			const competenceParent = [];
			for (const idx in data) {
				if (data[idx].parent === "0") {
					competenceParent.push(data[idx]);
				}
			}
			competenceParent.splice(26, 36);

			const results = [];
			competenceParent.forEach(parent => {
				if (!results[parent.name]) {
					results[parent.name] = [];
				}
				const sousComp = this.getSousCategory(competences, parent.id);
				results[parent.name].push(sousComp);
			});

			const user = Object.keys(this.dataCompetenceUser);

			const listIdCompetence = {};
			const dataUser = [];

			for (const user in this.dataCompetenceUser) {
				// const student = this.accountService.classroom.students.find(s => s.id === user);
				// this.accountService.team[0].id === user
				if (true) {
					var idCompetences = [];
					var value = [];
					for (const idx in this.dataCompetenceUser[user]) {
						const exo = this.dataCompetenceUser[user][idx];
						const idCompetences = Object.keys(JSON.parse(exo.competence));
						const nameCompetences = Object.values(JSON.parse(exo.competence));

						//Ne pas prendre en compte les exercices sans extension competences
						if (idCompetences.length != 0) {
							if (!dataUser[user]) {
								dataUser[user] = [];
							}
							competenceParent.forEach(parent => {
								if (!dataUser[user][parent.name]) {
									dataUser[user][parent.name] = [];
								}
							});

							for (const parent in results) {
								for (const comp in results[parent][0]) {
									if (!dataUser[user][parent][results[parent][0][comp].name]) {
										dataUser[user][parent][results[parent][0][comp].name] = {
											notes: [],
											moyenne: [],
											exTravailles: [],
											temps: []
										};
									}
								}
							}

							idCompetences.forEach(idCompetence => {
								const parent = this.getCategoryParent(competences, idCompetence);
								const comp = this.getObject(competences, idCompetence);
								const sousComp = this.getSousCategory(competences, parent.id);

								for (const idx in sousComp) {
									if (comp.length != 0 && String(comp) === sousComp[idx].name) {
										dataUser[user][parent.name][comp].notes.push(exo.value);
										dataUser[user][parent.name][comp].exTravailles.push(exo.nomEx);
										dataUser[user][parent.name][comp].temps.push(exo.duration);
									}
								}
							});
						}
					}
				}
			}
			console.log("datauser", dataUser);

			//Calcul moyenne et nb essais

			for (const user in dataUser) {
				for (const compParent in dataUser[user]) {
					for (const sousComp in dataUser[user][compParent]) {
						let sum = 0;
						dataUser[user][compParent][sousComp].notes.forEach(value => {
							sum += value;
						});
						dataUser[user][compParent][sousComp].moyenne = Math.round(sum / dataUser[user][compParent][sousComp].notes.length);
						dataUser[user][compParent][sousComp].nbEssai = dataUser[user][compParent][sousComp].notes.length;
					}
				}
			}

			///GRAPHIQUES///

			//destroy
			this.barChartData = null;
			this.barChartOptions.indexAxis = null;

			//build data
			const dataHeatMap = [];
			let labelUser = [];
			for (const user in dataUser) {
				labelUser.push(user);
				for (const compParent in dataUser[user]) {
					if (!dataHeatMap[compParent]) {
						dataHeatMap[compParent] = [];
					}
					for (const sousComp in dataUser[user][compParent]) {
						dataHeatMap[compParent].push({
							x: sousComp,
							y: user,
							v: dataUser[user][compParent][sousComp].moyenne,
							nb: dataUser[user][compParent][sousComp].nbEssai,
							ex: dataUser[user][compParent][sousComp].exTravailles,
							notes: dataUser[user][compParent][sousComp].notes,
							tps: dataUser[user][compParent][sousComp].temps
						});
					}
				}
			}

			console.log("dataheatmap", dataHeatMap);

			//Remplacer NaN par du texte
			for (const parent in dataHeatMap) {
				for (const idx in dataHeatMap[parent]) {
					if (isNaN(dataHeatMap[parent][idx].v)) {
						dataHeatMap[parent][idx].v = "Non noté";
					}
				}
			}

			//Légende
			const res = {
				"Non acquis": [],
				"En cours d'acquisition": [],
				"À renforcer": [],
				Maîtrisé: [],
				"Non travaillé": []
			};

			dataHeatMap[competenceP].forEach(stat => {
				if (stat.v < 25 && stat.v != "Non noté") {
					res["Non acquis"].push(stat);
				} else if (stat.v < 50 && stat.v != "Non noté") {
					res["En cours d'acquisition"].push(stat);
				} else if (stat.v < 75 && stat.v != "Non noté") {
					res["À renforcer"].push(stat);
				} else if (stat.v === "Non noté") {
					res["Non travaillé"].push(stat);
				} else {
					res["Maîtrisé"].push(stat);
				}
			});

			console.log("res", res);

			//Nom sous compétence concernée
			const name = [];
			for (const idx in results[competenceP][0]) {
				name.push(results[competenceP][0][idx].name);
			}
			console.log("name", name);

			this.barChartType = "matrix";
			this.barChartData = [
				{
					label: "Maitrîsée",
					data: res["Maîtrisé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#A2F5AA",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "À renforcer",
					data: res["À renforcer"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EFF5A4",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "En cours d'acquisition",
					data: res["En cours d'acquisition"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EDA864",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non acquis",
					data: res["Non acquis"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ED928A",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non travaillé",
					data: res["Non travaillé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ABBBF7",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				}
			];
			this.barChartOptions = {
				plugins: {
					legend: {
						display: true,
						position: "top"
					},
					tooltip: {
						callbacks: {
							label(context) {
								const v = context.dataset.data[context.dataIndex] as any;
								let tab = ["Élève : " + v.y, "Taux de réussite moyen : " + v.v + "%", "Exercices travaillés : "];
								let sum = 0;
								for (const idx in v.ex) {
									sum += v.tps[idx];
									tab = tab.concat("• " + v.ex[idx]);
									tab = tab.concat("avec un taux de réussite de " + v.notes[idx] + "%");
								}
								tab = tab.concat("Temps de travail total : " + Math.round(sum / 60) + "'");

								return tab;
							}
						}
					}
				},
				scales: {
					x: {
						type: "category",
						labels: name,
						display: true,
						offset: true
					},
					y: {
						type: "category",
						labels: labelUser,
						display: true,
						offset: true
					}
				},
				responsive: true,
				maintainAspectRatio: false
			};

			console.log("dataUser", dataUser);
		});
	}

	///// HEATMAP DES ERREURS TYPE /////

	async getHeatMapErreur(classId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$in: [Number(classId)]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					erreur: "$statement.object.definition.extensions.https://xapi&46;mathia&46;education/extensions/erreurs_type",
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							tauxBonnesReponses: "$pourcentage",
							err: "$erreur",
							mydate: "$mydate",
							session: "$session"
						}
					}
				},
				{
					$group: {
						_id: {
							date: "$_id.mydate",
							userId: "$_id.userId",
							erreur: "$_id.err",
							taux: "$_id.tauxBonnesReponses",
							session: "$_id.session"
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						erreur: "$_id.erreur",
						value: { $ceil: "$_id.taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value && item.userId) {
						if (!stats[item.userId]) {
							stats[item.userId] = [];
						}

						stats[item.userId].push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async getErreurIdClass() {
		this.currentStatisticChoosen = FilteredStatistic.erreur;
		this.buttonErr = !this.buttonErr;

		this.dataErreurUser = await this.getHeatMapErreur(this.accountService.classroom.id, this.rangeDate.start, this.rangeDate.end);

		//PB: pas de clé 'erreur' pour cette utilisateur et l'exercice indice 475
		delete this.dataErreurUser["14601"][475];
		console.log("dataErreur", this.dataErreurUser);

		this.http.get("https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_commonerrors_tree").subscribe(data => {
			//Recuperer liste des id erreurs par date
			const erreurs = data;
			const user = Object.keys(this.dataErreurUser);

			const results = [];
			const listIdErreur = {};
			for (const user in this.dataErreurUser) {
				// this.accountService.team[0].id === user
				if (true) {
					var idErreurs = [];
					var value = [];
					for (const idx in this.dataErreurUser[user]) {
						const exo = this.dataErreurUser[user][idx];
						const idErreurs = Object.keys(JSON.parse(exo.erreur));

						//Ne pas prendre en compte les exercices sans extension erreurs
						if (idErreurs.length != 0) {
							categoryParent = [];
							idErreurs.forEach(idErreur => {
								const parent = this.getCategoryParent(erreurs, idErreur);
								if (categoryParent.indexOf(parent) === -1) {
									categoryParent.push(parent);
								}
							});

							if (!results[user]) {
								results[user] = [];
							}

							categoryParent.forEach(category => {
								if (!results[user][category.name]) {
									results[user][category.name] = {
										notes: [],
										moyenne: [],
										nbEssai: []
									};
								}
								results[user][category.name].notes.push(exo.value);
							});
						}
					}
				}
			}

			for (const user in results) {
				for (const comp in results[user]) {
					let sum = 0;
					results[user][comp].notes.forEach(value => {
						sum += value;
					});
					results[user][comp].moyenne = Math.round(sum / results[user][comp].notes.length);
					results[user][comp].nbEssai = results[user][comp].notes.length;
				}
			}
			var categoryParent = [];
			console.log("resFinalll", results);

			const erreurParent = [];
			for (const idx in data) {
				if (data[idx].parent === "0") {
					erreurParent.push(data[idx].name);
				}
			}
			console.log("errParent", erreurParent);

			///HEATMAP DES ERREURS PARENTS///

			//destroy
			this.barChartData = null;
			this.barChartOptions.indexAxis = null;

			//build data
			const dataHeatMap = [];
			let labelUser = [];
			for (const user in results) {
				for (const err in results[user]) {
					const student = this.accountService.classroom.students.find(s => s.id === user);
					if (student) {
						labelUser.push(student.name);
						dataHeatMap.push({ x: err, y: student.name, v: results[user][err].moyenne, nb: results[user][err].nbEssai });
					}
				}
			}

			labelUser = [...new Set(labelUser)];

			console.log(labelUser);

			//Remplir le vide
			for (const user in results) {
				for (const parent in erreurParent) {
					const student = this.accountService.classroom.students.find(s => s.id === user);
					if (student) {
						if (!results[user][erreurParent[parent]]) {
							dataHeatMap.push({ x: erreurParent[parent], y: student.name, v: "Non noté", nb: 0 });
						}
					}
				}
			}

			console.log("dataHeatMapErr", dataHeatMap);

			//Légende
			const res = {
				"Non acquis": [],
				"En cours d'acquisition": [],
				"À renforcer": [],
				Maîtrisé: [],
				"Non travaillé": []
			};
			dataHeatMap.forEach(stat => {
				if (stat.v < 25 && stat.v != "Non noté") {
					res["Non acquis"].push(stat);
				} else if (stat.v < 50 && stat.v != "Non noté") {
					res["En cours d'acquisition"].push(stat);
				} else if (stat.v < 75 && stat.v != "Non noté") {
					res["À renforcer"].push(stat);
				} else if (stat.v === "Non noté") {
					res["Non travaillé"].push(stat);
				} else {
					res["Maîtrisé"].push(stat);
				}
			});

			this.barChartType = "matrix";
			this.barChartData = [
				{
					label: "Maitrîsée",
					data: res["Maîtrisé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#A2F5AA",
					width: ({ chart }) => (chart.chartArea || {}).width / erreurParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "À renforcer",
					data: res["À renforcer"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EFF5A4",
					width: ({ chart }) => (chart.chartArea || {}).width / erreurParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "En cours d'acquisition",
					data: res["En cours d'acquisition"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EDA864",
					width: ({ chart }) => (chart.chartArea || {}).width / erreurParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non acquis",
					data: res["Non acquis"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ED928A",
					width: ({ chart }) => (chart.chartArea || {}).width / erreurParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non travaillé",
					data: res["Non travaillé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ABBBF7",
					width: ({ chart }) => (chart.chartArea || {}).width / erreurParent.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				}
			];
			this.barChartOptions = {
				plugins: {
					legend: {
						display: true,
						position: "top"
					},
					tooltip: {
						callbacks: {
							label(context) {
								const v = context.dataset.data[context.dataIndex] as any;
								return ["Élève : " + v.y, "Moyenne : " + v.v, "Nombre d" + "'" + "exercices réalisés : " + v.nb];
							}
						}
					}
				},
				scales: {
					x: {
						type: "category",
						labels: erreurParent,
						display: true,
						offset: true
					},
					y: {
						type: "category",
						labels: labelUser,
						display: true,
						offset: true
					}
				},
				responsive: true,
				maintainAspectRatio: false
			};
		});
	}

	///HEATMAP DES SOUS ERREURS TYPE///

	async getSousErreurIdClass(erreurP) {
		this.currentStatisticChoosen = FilteredStatistic.sousErreur;

		this.dataErreurUser = await this.getHeatMapErreur(this.accountService.classroom.id, this.rangeDate.start, this.rangeDate.end);

		//PB: pas de clé 'erreur' pour cette utilisateur et l'exercice indice 475
		delete this.dataErreurUser["14601"][475];
		console.log("dataErr", this.dataErreurUser);

		this.http.get("https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_commonerrors_tree").subscribe(data => {
			const erreurs = data;

			//Récupérer toutes les erreurs parents
			const erreurParent = [];
			for (const idx in data) {
				if (data[idx].parent === "0") {
					erreurParent.push(data[idx]);
				}
			}
			console.log("errParent", erreurParent);

			const results = [];
			erreurParent.forEach(parent => {
				if (!results[parent.name]) {
					results[parent.name] = [];
				}
				const sousErr = this.getSousCategory(erreurs, parent.id);
				results[parent.name].push(sousErr);
			});
			console.log("sousErr", results);

			const dataUser = [];

			for (const user in this.dataErreurUser) {
				const student = this.accountService.classroom.students.find(s => s.id === user);
				// this.accountService.team[0].id === user
				if (true) {
					var idCompetences = [];
					var value = [];
					for (const idx in this.dataErreurUser[user]) {
						const exo = this.dataErreurUser[user][idx];
						const idErreurs = Object.keys(JSON.parse(exo.erreur));
						const nameErreurs = Object.values(JSON.parse(exo.erreur));

						//Ne pas prendre en compte les exercices sans extension competences
						if (idErreurs.length != 0 && student) {
							if (!dataUser[user]) {
								dataUser[user] = [];
							}
							erreurParent.forEach(parent => {
								if (!dataUser[user][parent.name]) {
									dataUser[user][parent.name] = [];
								}
							});

							for (const parent in results) {
								for (const err in results[parent][0]) {
									if (!dataUser[user][parent][results[parent][0][err].name]) {
										dataUser[user][parent][results[parent][0][err].name] = {
											notes: [],
											moyenne: [],
											nbEssai: []
										};
									}
								}
							}

							idErreurs.forEach(idErreur => {
								const parent = this.getCategoryParent(erreurs, idErreur);
								const err = this.getObject(erreurs, idErreur);
								const sousErr = this.getSousCategory(erreurs, parent.id);

								for (const idx in sousErr) {
									if (err.length != 0 && String(err) === sousErr[idx].name) {
										dataUser[user][parent.name][err].notes.push(exo.value);
									}
								}
							});
						}
					}
				}
			}

			//Calcul moyenne et nb essais

			for (const user in dataUser) {
				for (const errParent in dataUser[user]) {
					for (const sousErr in dataUser[user][errParent]) {
						let sum = 0;
						dataUser[user][errParent][sousErr].notes.forEach(value => {
							sum += value;
						});
						dataUser[user][errParent][sousErr].moyenne = Math.round(sum / dataUser[user][errParent][sousErr].notes.length);
						dataUser[user][errParent][sousErr].nbEssai = dataUser[user][errParent][sousErr].notes.length;
					}
				}
			}

			console.log("dataUser", dataUser);

			///GRAPHIQUES///

			//destroy
			this.barChartData = null;
			this.barChartOptions.indexAxis = null;

			//build data
			const dataHeatMap = [];
			let labelUser = [];
			for (const user in dataUser) {
				const student = this.accountService.classroom.students.find(s => s.id === user);
				labelUser.push(student.name);
				for (const errParent in dataUser[user]) {
					if (!dataHeatMap[errParent]) {
						dataHeatMap[errParent] = [];
					}
					for (const sousErr in dataUser[user][errParent]) {
						dataHeatMap[errParent].push({
							x: sousErr,
							y: student.name,
							v: dataUser[user][errParent][sousErr].moyenne,
							nb: dataUser[user][errParent][sousErr].nbEssai
						});
					}
				}
			}

			//Remplacer NaN par du texte
			for (const parent in dataHeatMap) {
				for (const idx in dataHeatMap[parent]) {
					if (isNaN(dataHeatMap[parent][idx].v)) {
						dataHeatMap[parent][idx].v = "Non noté";
					}
				}
			}

			console.log("dataheatmap", dataHeatMap);

			//Légende
			const res = {
				"Non acquis": [],
				"En cours d'acquisition": [],
				"À renforcer": [],
				Maîtrisé: [],
				"Non travaillé": []
			};

			dataHeatMap[erreurP].forEach(stat => {
				console.log("stat", stat);
				if (stat.v < 25 && stat.v != "Non noté") {
					res["Non acquis"].push(stat);
				} else if (stat.v < 50 && stat.v != "Non noté") {
					res["En cours d'acquisition"].push(stat);
				} else if (stat.v < 75 && stat.v != "Non noté") {
					res["À renforcer"].push(stat);
				} else if (stat.v === "Non noté") {
					res["Non travaillé"].push(stat);
				} else {
					res["Maîtrisé"].push(stat);
				}
			});

			//Nom sous compétence concernée
			const name = [];
			for (const idx in results[erreurP][0]) {
				name.push(results[erreurP][0][idx].name);
			}
			console.log("name", name);

			this.barChartType = "matrix";
			this.barChartData = [
				{
					label: "Maitrîsée",
					data: res["Maîtrisé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#A2F5AA",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "À renforcer",
					data: res["À renforcer"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EFF5A4",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "En cours d'acquisition",
					data: res["En cours d'acquisition"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#EDA864",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non acquis",
					data: res["Non acquis"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ED928A",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				},
				{
					label: "Non travaillé",
					data: res["Non travaillé"],
					borderWidth: 1,
					hoverBackgroundColor: "yellow",
					borderColor: "white",
					backgroundColor: "#ABBBF7",
					width: ({ chart }) => (chart.chartArea || {}).width / name.length, //diviser par le nb de colonne
					height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
				}
			];
			this.barChartOptions = {
				plugins: {
					legend: {
						display: true,
						position: "top"
					},
					tooltip: {
						callbacks: {
							label(context) {
								const v = context.dataset.data[context.dataIndex] as any;
								return ["Élève : " + v.y, "Moyenne : " + v.v, "Nombre d" + "'" + "exercices réalisés : " + v.nb];
							}
						}
					}
				},
				scales: {
					x: {
						type: "category",
						labels: name,
						display: true,
						offset: true
					},
					y: {
						type: "category",
						labels: labelUser,
						display: true,
						offset: true
					}
				},
				responsive: true,
				maintainAspectRatio: false
			};
		});
	}

	///EXERCICES TERMINES PAR GABARITS POUR TOUS LES ÉLÈVES DE LA CLASSE///

	async getExercisesCompletedGabaritsClass(classId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$in: [Number(classId)]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							idAct: "$idActivite"
						},
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						userId: "$_id.userId",
						idAct: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.userId) {
						return;
					}
					if (!stats[item.userId]) {
						stats[item.userId] = [];
					}
					if (!stats[item.userId][item.idAct]) {
						stats[item.userId][item.idAct] = [];
					}
					stats[item.userId][item.idAct].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showExercicesCompletedPerGabaritClass() {
		this.currentStatisticChoosen = FilteredStatistic.exTerminesGabaritClass;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getExercisesCompletedGabaritsClass(
			this.accountService.classroom.id,
			this.rangeDate.start,
			this.rangeDate.end
		);
		console.log("data", this.chartExercicePerGabarit);

		//Prendre que les utilisateurs existants actuellement dans la classe et construire le data
		const dataExPerGabarit = [];

		for (const user in this.chartExercicePerGabarit) {
			const student = this.accountService.classroom.students.find(s => s.id === user);
			if (student) {
				if (!dataExPerGabarit[user]) {
					dataExPerGabarit[user] = [];
				}
				for (const act in this.chartExercicePerGabarit[user]) {
					if (!dataExPerGabarit[user][act]) {
						dataExPerGabarit[user][act] = {};
					}
					dataExPerGabarit[user][act] = this.chartExercicePerGabarit[user][act][0];
				}
			}
		}

		//Rajouter les activités jamais faites
		const idAct = [];
		this.cabriService.activities.forEach(a => idAct.push(a.id));
		console.log("id", idAct);

		const nameAct = [];
		for (const elt in idAct) {
			const name = this.cabriService.activities.find(a => a.id === idAct[elt]).name;
			nameAct.push(name);
		}
		console.log("name", nameAct);

		let labelUser = [];
		for (const id in idAct) {
			for (const user in dataExPerGabarit) {
				const student = this.accountService.classroom.students.find(s => s.id === user);
				labelUser.push(student.name);
				if (!dataExPerGabarit[user][idAct[id]]) {
					dataExPerGabarit[user][idAct[id]] = {};
					dataExPerGabarit[user][idAct[id]] = { value: 0, userId: user, idAct: idAct[id] };
				}
			}
		}
		labelUser = [...new Set(labelUser)];

		console.log("labelUser", labelUser);
		console.log("dt", dataExPerGabarit);

		//Build data
		const data = [];
		for (const user in dataExPerGabarit) {
			for (const idAct in dataExPerGabarit[user]) {
				if (!data[idAct]) {
					data[idAct] = [];
				}
				data[idAct].push(dataExPerGabarit[user][idAct]);
			}
		}

		console.log("dataa", data);
		this.barChartLabels = labelUser;
		this.barChartType = "bar";

		const dataChart = [];
		for (const idAct in data) {
			if (!dataChart[idAct]) {
				dataChart[idAct] = [];
			}
			for (const act in data[idAct]) {
				dataChart[idAct].push(data[idAct][act].value);
			}
		}
		console.log("dtt", dataChart);

		(this.barChartData = [
			{
				data: dataChart["1"],
				label: "Calcul mental",
				backgroundColor: "#ABDEE6",
				fill: true,
				borderColor: "white",
				borderWidth: 1
			},
			{
				data: dataChart["7"],
				label: "Les nombres autrement",
				backgroundColor: "#CBAACB",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},
			{
				data: dataChart["3"],
				label: "Jeu du Furet",
				backgroundColor: "#FFFFB5",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},
			{
				data: dataChart["4"],
				label: "Jeu de Kim",
				backgroundColor: "#FFCCB6",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},

			{
				data: dataChart["5"],
				label: "Jeu de l" + "'" + "heure",
				backgroundColor: "#F3B0C3",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},
			{
				data: dataChart["6"],
				label: "Galaxie des calculs",
				backgroundColor: "#B6CFB6",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},
			{
				data: dataChart["8"],
				label: "Découverte des solides",
				backgroundColor: "#ECEAE4",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},
			{
				data: dataChart["10"],
				label: "Résolution de problèmes",
				backgroundColor: "#E3C9B5",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			},
			{
				data: dataChart["11"],
				label: "Jeu du juste point",
				backgroundColor: "#55CBCD",
				borderColor: "white",
				fill: true,
				borderWidth: 1
			}
		]),
			(this.barChartOptions.scales = {
				x: {
					stacked: true
				},
				y: {
					stacked: true
				}
			});
	}

	///EXERCICES TERMINES PAR GABARITS POUR L'ÉLÈVE CONNECTÉ (DONUT)///

	async getExercisesCompletedGabaritsDonut(userId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.actor.account.name": {
						$in: [userId]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							idAct: "$idActivite"
						},
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						},
						taux: {
							$avg: "$pourcentage"
						}
					}
				},
				{
					$project: {
						_id: 0,
						userId: "$_id.userId",
						idAct: "$_id.idAct",
						value: 1,
						moyenne: { $ceil: "$taux" }
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!stats[item.idAct]) {
						stats[item.idAct] = [];
					}
					stats[item.idAct].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showExPerGabaritDonut() {
		this.currentStatisticChoosen = FilteredStatistic.exTerminesGabaritDonut;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getExercisesCompletedGabaritsDonut(
			this.accountService.team[0].id,
			this.rangeDate.start,
			this.rangeDate.end
		);
		console.log("data1", this.chartExercicePerGabarit);

		const data = this.extractData(this.chartExercicePerGabarit);
		console.log("data", data);

		const labelAct = this.extractGabaritsLabel(this.chartExercicePerGabarit);
		console.log("lbel", labelAct);

		const moy = [];
		const nbEx = [];
		for (const idAct in this.chartExercicePerGabarit) {
			moy.push(this.chartExercicePerGabarit[idAct][0].moyenne);
			nbEx.push(this.chartExercicePerGabarit[idAct][0].value);
		}

		this.barChartLabels = labelAct;
		this.barChartType = "doughnut";
		this.barChartData = [
			{
				data: data,
				backgroundColor: ["#ABDEE6", "#CBAACB", "#FFFFB5", "#FFCCB6", "#F3B0C3", "#B6CFB6", "#ECEAE4", "#E3C9B5", "#55CBCD"]
			}
		];
		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Activité : " + labelAct[context.dataIndex],
								"Moyenne : " + moy[context.dataIndex],
								"Nombre d" + "'" + "exercices achevés : " + nbEx[context.dataIndex]
							];
						}
					}
				}
			}
		};
	}

	///HEATMAP DES PERFORMANCES EN FONCTION DES MOMENTS DE LA JOURNEE///

	async getHeatMapPerformance(classId, $startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$in: [Number(classId)]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							tauxBonnesReponses: "$pourcentage",
							mydate: "$mydate",
							session: "$session"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", taux: "$_id.tauxBonnesReponses", session: "$_id.session" }
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						value: { $ceil: "$_id.taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = [];
				console.log("results", results);
				results.forEach(item => {
					if (item.value && item.userId) {
						stats.push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async getCompetenceDayMoment() {
		this.currentStatisticChoosen = FilteredStatistic.competenceDayMoment;

		this.dataCompetenceUser = await this.getHeatMapPerformance(
			this.accountService.classroom.id,
			this.rangeDate.start,
			this.rangeDate.end
		);

		//Convertir date avec la bonne heure
		for (const exo in this.dataCompetenceUser) {
			this.dataCompetenceUser[exo].date = new Date(this.dataCompetenceUser[exo].date);
		}
		console.log("dataday", this.dataCompetenceUser);
		const newData = [];
		for (const exo in this.dataCompetenceUser) {
			const user = this.dataCompetenceUser[exo].userId;
			const student = this.accountService.classroom.students.find(s => s.id === user);
			if (student) {
				newData.push(this.dataCompetenceUser[exo]);
			}
		}

		console.log("newdata", newData);

		const data = [];
		data["8h-10h"] = [];
		data["10h-12h"] = [];
		data["12h-14h"] = [];
		data["14h-16h"] = [];
		data["16h-18h"] = [];
		data["18h-20h"] = [];
		data["20h-22h"] = [];

		let labelUser = [];

		for (const date in data) {
			for (const exo in newData) {
				const user = newData[exo].userId;
				const student = this.accountService.classroom.students.find(s => s.id === user);
				if (student) {
					labelUser.push(student.name);
					if (!data[date][newData[exo].userId]) {
						data[date][newData[exo].userId] = {
							notes: [],
							moyenne: [],
							nbEssai: []
						};
					}
				}
			}
		}

		labelUser = [...new Set(labelUser)];
		console.log("label", labelUser);

		for (const exo in newData) {
			var hours = [];
			hours = newData[exo].date.getHours();
			if ((hours as unknown) === 8 || (hours as unknown) === 9) {
				data["8h-10h"][newData[exo].userId].notes.push(newData[exo].value);
			} else if ((hours as unknown) === 10 || (hours as unknown) === 11) {
				data["10h-12h"][newData[exo].userId].notes.push(newData[exo].value);
			} else if ((hours as unknown) === 12 || (hours as unknown) === 13) {
				data["12h-14h"][newData[exo].userId].notes.push(newData[exo].value);
			} else if ((hours as unknown) === 14 || (hours as unknown) === 15) {
				data["14h-16h"][newData[exo].userId].notes.push(newData[exo].value);
			} else if ((hours as unknown) === 16 || (hours as unknown) === 17) {
				data["16h-18h"][newData[exo].userId].notes.push(newData[exo].value);
			} else if ((hours as unknown) === 18 || (hours as unknown) === 19) {
				data["18h-20h"][newData[exo].userId].notes.push(newData[exo].value);
			} else if ((hours as unknown) === 20 || (hours as unknown) === 21) {
				data["20h-22h"][newData[exo].userId].notes.push(newData[exo].value);
			}
		}

		for (const date in data) {
			for (const user in data[date]) {
				let sum = 0;
				data[date][user].notes.forEach(value => {
					sum += value;
				});
				data[date][user].moyenne = Math.round(sum / data[date][user].notes.length);
				data[date][user].nbEssai = data[date][user].notes.length;
			}
		}

		//Remplacer NaN par du texte
		for (const date in data) {
			for (const user in data[date]) {
				if (isNaN(data[date][user].moyenne)) {
					data[date][user].moyenne = "Non noté";
				}
			}
		}

		const dateLabel = ["8h-10h", "10h-12h", "12h-14h", "14h-16h", "16h-18h", "18h-20h", "20h-22h"];

		console.log("dt", data);

		//build data
		const dataHeatMap = [];
		for (const date in data) {
			for (const user in data[date]) {
				const student = this.accountService.classroom.students.find(s => s.id === user);
				dataHeatMap.push({ x: date, y: student.name, v: data[date][user].moyenne, nb: data[date][user].nbEssai });
			}
		}

		console.log("dataF", dataHeatMap);

		//Légende
		const res = {
			"Très favorable": [],
			Favorable: [],
			"Peu favorable": [],
			"Non favorable": [],
			"Non noté": []
		};

		dataHeatMap.forEach(stat => {
			if (stat.v < 25 && stat.v != "Non noté") {
				res["Non favorable"].push(stat);
			} else if (stat.v < 50 && stat.v != "Non noté") {
				res["Peu favorable"].push(stat);
			} else if (stat.v < 75 && stat.v != "Non noté") {
				res["Favorable"].push(stat);
			} else if (stat.v === "Non noté") {
				res["Non noté"].push(stat);
			} else {
				res["Très favorable"].push(stat);
			}
		});

		console.log("res", res);

		//destroy
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;

		this.barChartType = "matrix";
		this.barChartData = [
			{
				label: "Très favorable",
				data: res["Très favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#A2F5AA",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Favorable",
				data: res["Favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EFF5A4",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Peu favorable",
				data: res["Peu favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EDA864",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Non favorable",
				data: res["Non favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ED928A",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Non noté",
				data: res["Non noté"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ABBBF7",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			}
		];

		this.barChartOptions = {
			plugins: {
				legend: {
					display: true,
					position: "top"
				},
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Moment de la journée : " + v.x,
								"Élève : " + v.y,
								"Moyenne : " + v.v,
								"Nombre d" + "'" + "exercices réalisés : " + v.nb
							];
						}
					}
				}
			},
			scales: {
				x: {
					type: "category",
					labels: dateLabel,
					display: true,
					offset: true
				},
				y: {
					type: "category",
					labels: labelUser,
					display: true,
					offset: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		};
	}

	////TEST SUR LA CLASSE 70227////

	async getCompetenceDayMomentTest() {
		this.currentStatisticChoosen = FilteredStatistic.competenceDayMomentTest;

		this.dataCompetenceUser = await this.getHeatMapPerformance("70227", this.rangeDate.start, this.rangeDate.end);

		//Convertir date avec la bonne heure
		for (const exo in this.dataCompetenceUser) {
			this.dataCompetenceUser[exo].date = new Date(this.dataCompetenceUser[exo].date);
		}
		console.log("dataday", this.dataCompetenceUser);

		const data = [];
		data["8h-10h"] = [];
		data["10h-12h"] = [];
		data["12h-14h"] = [];
		data["14h-16h"] = [];
		data["16h-18h"] = [];
		data["18h-20h"] = [];
		data["20h-22h"] = [];

		let labelUser = [];

		for (const date in data) {
			for (const exo in this.dataCompetenceUser) {
				const user = this.dataCompetenceUser[exo].userId;
				labelUser.push(user);
				if (!data[date][this.dataCompetenceUser[exo].userId]) {
					data[date][this.dataCompetenceUser[exo].userId] = {
						notes: [],
						moyenne: [],
						nbEssai: []
					};
				}
			}
		}

		labelUser = [...new Set(labelUser)];
		console.log("label", labelUser);
		console.log("datatest", data);

		for (const exo in this.dataCompetenceUser) {
			var hours = [];
			hours = this.dataCompetenceUser[exo].date.getHours();
			if ((hours as unknown) === 8 || (hours as unknown) === 9) {
				data["8h-10h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			} else if ((hours as unknown) === 10 || (hours as unknown) === 11) {
				data["10h-12h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			} else if ((hours as unknown) === 12 || (hours as unknown) === 13) {
				data["12h-14h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			} else if ((hours as unknown) === 14 || (hours as unknown) === 15) {
				data["14h-16h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			} else if ((hours as unknown) === 16 || (hours as unknown) === 17) {
				data["16h-18h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			} else if ((hours as unknown) === 18 || (hours as unknown) === 19) {
				data["18h-20h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			} else if ((hours as unknown) === 20 || (hours as unknown) === 21) {
				data["20h-22h"][this.dataCompetenceUser[exo].userId].notes.push(this.dataCompetenceUser[exo].value);
			}
		}

		for (const date in data) {
			for (const user in data[date]) {
				let sum = 0;
				data[date][user].notes.forEach(value => {
					sum += value;
				});
				data[date][user].moyenne = Math.round(sum / data[date][user].notes.length);
				data[date][user].nbEssai = data[date][user].notes.length;
			}
		}

		//Remplacer NaN par du texte
		for (const date in data) {
			for (const user in data[date]) {
				if (isNaN(data[date][user].moyenne)) {
					data[date][user].moyenne = "Non noté";
				}
			}
		}

		const dateLabel = ["8h-10h", "10h-12h", "12h-14h", "14h-16h", "16h-18h", "18h-20h", "20h-22h"];

		//build data
		const dataHeatMap = [];
		for (const date in data) {
			for (const user in data[date]) {
				dataHeatMap.push({ x: date, y: user, v: data[date][user].moyenne, nb: data[date][user].nbEssai });
			}
		}

		console.log("dataF", dataHeatMap);

		//Légende
		const res = {
			"Très favorable": [],
			Favorable: [],
			"Peu favorable": [],
			"Non favorable": [],
			"Non noté": []
		};

		dataHeatMap.forEach(stat => {
			if (stat.v < 25 && stat.v != "Non noté") {
				res["Non favorable"].push(stat);
			} else if (stat.v < 50 && stat.v != "Non noté") {
				res["Peu favorable"].push(stat);
			} else if (stat.v < 75 && stat.v != "Non noté") {
				res["Favorable"].push(stat);
			} else if (stat.v === "Non noté") {
				res["Non noté"].push(stat);
			} else {
				res["Très favorable"].push(stat);
			}
		});

		console.log("res", res);

		//destroy
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;

		this.barChartType = "matrix";
		this.barChartData = [
			{
				label: "Très favorable",
				data: res["Très favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#A2F5AA",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Favorable",
				data: res["Favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EFF5A4",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Peu favorable",
				data: res["Peu favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EDA864",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Non favorable",
				data: res["Non favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ED928A",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			},

			{
				label: "Non noté",
				data: res["Non noté"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ABBBF7",
				width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / labelUser.length //par le nb de ligne
			}
		];

		this.barChartOptions = {
			plugins: {
				legend: {
					display: true,
					position: "top"
				},
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Moment de la journée : " + v.x,
								"Élève : " + v.y,
								"Moyenne : " + v.v,
								"Nombre d" + "'" + "exercices réalisés : " + v.nb
							];
						}
					}
				}
			},
			scales: {
				x: {
					type: "category",
					labels: dateLabel,
					display: true,
					offset: true
				},
				y: {
					type: "category",
					labels: labelUser,
					display: true,
					offset: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		};
	}

	// async getCompetenceDayMomentAll() {
	// 	this.currentStatisticChoosen = FilteredStatistic.competenceDayMomentAll;

	// 	this.dataCompetenceUser = await this.getHeatMapPerfAll(
	// 		this.rangeDate.start,
	// 		this.rangeDate.end);

	// 	this.http.get('https://mathia.education/wp-admin/admin-ajax.php?action=app_mathia_get_skills_tree').subscribe(data => {
	// 		//Recuperer liste des id competences par date
	// 		const competences = data;

	// 		//Convertir date avec la bonne heure
	// 		for (const exo in this.dataCompetenceUser) {
	// 			this.dataCompetenceUser[exo].date = new Date(this.dataCompetenceUser[exo].date);
	// 		}

	// 		console.log("data", this.dataCompetenceUser);

	// 		const categoryParent = [];
	// 		for (const idx in this.dataCompetenceUser) {
	// 			const exo = this.dataCompetenceUser[idx];
	// 			const idCompetences = Object.keys(JSON.parse(exo.competence));
	// 			if (idCompetences.length != 0) {
	// 				idCompetences.forEach((idCompetence) => {
	// 					const parent = this.getCategoryParent(competences, idCompetence);
	// 					if (categoryParent.indexOf(parent) === -1) {
	// 						categoryParent.push(parent);
	// 					}
	// 				});
	// 			}
	// 		}
	// 		console.log("catParent", categoryParent);

	// 		const results = [];
	// 		categoryParent.forEach(category => {
	// 			if (!results[category.name]) {
	// 				results[category.name] = [];
	// 			}
	// 		});

	// 		for (const comp in results) {
	// 			if (!results[comp]['8h-10h']) {
	// 				results[comp]['8h-10h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 			if (!results[comp]['10h-12h']) {
	// 				results[comp]['10h-12h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 			if (!results[comp]['12h-14h']) {
	// 				results[comp]['12h-14h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 			if (!results[comp]['14h-16h']) {
	// 				results[comp]['14h-16h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 			if (!results[comp]['16h-18h']) {
	// 				results[comp]['16h-18h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 			if (!results[comp]['18h-20h']) {
	// 				results[comp]['18h-20h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 			if (!results[comp]['20h-22h']) {
	// 				results[comp]['20h-22h'] = {
	// 					notes: [],
	// 					moyenne: [],
	// 					nbEx: []
	// 				};
	// 			}
	// 		}

	// 		for (const idx in this.dataCompetenceUser) {
	// 			const exo = this.dataCompetenceUser[idx];
	// 			const idCompetences = Object.keys(JSON.parse(exo.competence));
	// 			if (idCompetences.length != 0) {
	// 				idCompetences.forEach((idCompetence) => {
	// 					const parent = this.getCategoryParent(competences, idCompetence);
	// 					var hours = [];
	// 					hours = this.dataCompetenceUser[idx].date.getHours();
	// 					if ((hours as unknown) === 8 || (hours as unknown) === 9) {
	// 						results[parent.name]['8h-10h'].notes.push(exo.value);
	// 					} else if ((hours as unknown) === 10 || (hours as unknown === 11)) {
	// 						results[parent.name]['10h-12h'].notes.push(exo.value);
	// 					} else if ((hours as unknown) === 12 || (hours as unknown) === 13) {
	// 						results[parent.name]['12h-14h'].notes.push(exo.value);
	// 					} else if ((hours as unknown) === 14 || (hours as unknown) === 15) {
	// 						results[parent.name]['14h-16h'].notes.push(exo.value);
	// 					} else if ((hours as unknown) === 16 || (hours as unknown) === 17) {
	// 						results[parent.name]['16h-18h'].notes.push(exo.value);
	// 					} else if ((hours as unknown) === 18 || (hours as unknown) === 19) {
	// 						results[parent.name]['18h-20h'].notes.push(exo.value);
	// 					} else if ((hours as unknown) === 20 || (hours as unknown) === 21) {
	// 						results[parent.name]['20h-22h'].notes.push(exo.value);
	// 					}

	// 				});
	// 			}

	// 		}

	// 		for (const comp in results) {
	// 			for (const moment in results[comp]) {
	// 				let sum = 0;
	// 				results[comp][moment].notes.forEach(value => {
	// 					sum += value;
	// 				});
	// 				results[comp][moment].moyenne = Math.round(sum / results[comp][moment].notes.length);
	// 				results[comp][moment].nbEx = results[comp][moment].notes.length;
	// 			}
	// 		}

	// 		console.log("res", results);

	// 		const dateLabel = ['8h-10h', '10h-12h', '12h-14h', '14h-16h', '16h-18h', '18h-20h', '20h-22h'];

	// 		const parentLabel = [];
	// 		for (const comp in results) {
	// 			parentLabel.push(comp)
	// 		}
	// 		console.log("parentlab", parentLabel);

	// 		//build data
	// 		const dataHeatMap = [];
	// 		for (const comp in results) {
	// 			for (const date in results[comp]) {
	// 				dataHeatMap.push({ x: date, y: comp, v: results[comp][date].moyenne, nbEx: results[comp][date].nbEx });
	// 			}
	// 		}

	// 		console.log("dataF", dataHeatMap);

	// 		//destroy
	// 		this.barChartData = null;
	// 		this.barChartOptions.indexAxis = null;
	// 		this.barChartOptions.plugins = null;

	// 		this.barChartType = 'matrix';
	// 		this.barChartData = [{
	// 			label: "Moyenne par moment de la journée",
	// 			data: dataHeatMap,
	// 			borderWidth: 1,
	// 			hoverBackgroundColor: 'yellow',
	// 			borderColor: "white",
	// 			backgroundColor(context) {
	// 				const value = context.dataset.data[context.dataIndex].v;
	// 				const alpha = value / 100;
	// 				return helpers.color("#ACE8D4").alpha(alpha).rgbString();
	// 			},
	// 			width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
	// 			height: ({ chart }) => (chart.chartArea || {}).height / categoryParent.length //par le nb de ligne

	// 		}];

	// 		this.barChartOptions = {
	// 			plugins: {
	// 				legend: {
	// 					display: true,
	// 					position: 'top',
	// 				},
	// 				tooltip: {
	// 					callbacks: {
	// 						label(context) {
	// 							const v = context.dataset.data[context.dataIndex] as any;
	// 							return ['Moment de la journée : ' + v.x, 'Compétence : ' + v.y, 'Moyenne : ' + v.v, 'Nombre d' + "'" + 'exercices réalisés : ' + v.nbEx];
	// 						}
	// 					}
	// 				}
	// 			},
	// 			scales: {
	// 				x: {
	// 					type: 'category',
	// 					labels: dateLabel,
	// 					display: true,
	// 					offset: true
	// 				},
	// 				y: {
	// 					type: 'category',
	// 					labels: parentLabel,
	// 					display: true,
	// 					offset: true,

	// 				}
	// 			},
	// 			responsive: true,
	// 			maintainAspectRatio: false

	// 		};

	// 	});

	// }

	///HEATMAP DES PERFORMANCES EN FONCTION DES MOMENTS DE LA JOURNEE TOUTES CLASSES CONFONDUES avec en y les élèves///

	// async getCompetenceDayMomentAllEleves() {
	// 	this.currentStatisticChoosen = FilteredStatistic.competenceDayMomentAllEleves;

	// 	this.dataCompetenceUser = await this.getHeatMapPerfAll(
	// 		this.rangeDate.start,
	// 		this.rangeDate.end);

	// 	//Convertir date avec la bonne heure
	// 	for (const exo in this.dataCompetenceUser) {
	// 		this.dataCompetenceUser[exo].date = new Date(this.dataCompetenceUser[exo].date);
	// 	}

	// 	console.log("data", this.dataCompetenceUser);

	// 	const results = [];

	// 	for (const idx in this.dataCompetenceUser) {
	// 		const exo = this.dataCompetenceUser[idx];
	// 		if (!results[this.dataCompetenceUser[idx].userId]) {
	// 			results[this.dataCompetenceUser[idx].userId] = [];
	// 		}
	// 	}
	// 	console.log("res", results);

	// 	for (const user in results) {
	// 		if (!results[user]['8h-10h']) {
	// 			results[user]['8h-10h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 		if (!results[user]['10h-12h']) {
	// 			results[user]['10h-12h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 		if (!results[user]['12h-14h']) {
	// 			results[user]['12h-14h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 		if (!results[user]['14h-16h']) {
	// 			results[user]['14h-16h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 		if (!results[user]['16h-18h']) {
	// 			results[user]['16h-18h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 		if (!results[user]['18h-20h']) {
	// 			results[user]['18h-20h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 		if (!results[user]['20h-22h']) {
	// 			results[user]['20h-22h'] = {
	// 				notes: [],
	// 				moyenne: [],
	// 				nbEx: []
	// 			};
	// 		}
	// 	}

	// 	for (const idx in this.dataCompetenceUser) {
	// 		const exo = this.dataCompetenceUser[idx];
	// 		var hours = [];
	// 		hours = this.dataCompetenceUser[idx].date.getHours();
	// 		if ((hours as unknown) === 8 || (hours as unknown) === 9) {
	// 			results[this.dataCompetenceUser[idx].userId]['8h-10h'].notes.push(exo.value);
	// 		} else if ((hours as unknown) === 10 || (hours as unknown === 11)) {
	// 			results[this.dataCompetenceUser[idx].userId]['10h-12h'].notes.push(exo.value);
	// 		} else if ((hours as unknown) === 12 || (hours as unknown) === 13) {
	// 			results[this.dataCompetenceUser[idx].userId]['12h-14h'].notes.push(exo.value);
	// 		} else if ((hours as unknown) === 14 || (hours as unknown) === 15) {
	// 			results[this.dataCompetenceUser[idx].userId]['14h-16h'].notes.push(exo.value);
	// 		} else if ((hours as unknown) === 16 || (hours as unknown) === 17) {
	// 			results[this.dataCompetenceUser[idx].userId]['16h-18h'].notes.push(exo.value);
	// 		} else if ((hours as unknown) === 18 || (hours as unknown) === 19) {
	// 			results[this.dataCompetenceUser[idx].userId]['18h-20h'].notes.push(exo.value);
	// 		} else if ((hours as unknown) === 20 || (hours as unknown) === 21) {
	// 			results[this.dataCompetenceUser[idx].userId]['20h-22h'].notes.push(exo.value);
	// 		}

	// 	};

	// 	for (const user in results) {
	// 		for (const moment in results[user]) {
	// 			let sum = 0;
	// 			results[user][moment].notes.forEach(value => {
	// 				sum += value;
	// 			});
	// 			results[user][moment].moyenne = Math.round(sum / results[user][moment].notes.length);
	// 			results[user][moment].nbEx = results[user][moment].notes.length;
	// 		}
	// 	}

	// 	const dateLabel = ['8h-10h', '10h-12h', '12h-14h', '14h-16h', '16h-18h', '18h-20h', '20h-22h'];

	// 	const userLabel = [];
	// 	for (const user in results) {
	// 		userLabel.push(user)
	// 	}

	// 	console.log("userlabel", userLabel);

	// 	//Remplacer NaN par du texte
	// 	for (const user in results) {
	// 		for (const date in results[user]) {
	// 			if (isNaN(results[user][date].moyenne)) {
	// 				results[user][date].moyenne = "Non noté";
	// 			}
	// 		}
	// 	}

	// 	//build data
	// 	const dataHeatMap = [];
	// 	for (const user in results) {
	// 		for (const date in results[user]) {
	// 			dataHeatMap.push({ x: date, y: user, v: results[user][date].moyenne, nb: results[user][date].nbEx });
	// 		}
	// 	}

	// 	console.log("dataF", dataHeatMap);

	// 	//destroy
	// 	this.barChartData = null;
	// 	this.barChartOptions.indexAxis = null;
	// 	this.barChartOptions.plugins = null;

	// 	this.barChartType = 'matrix';
	// 	this.barChartData = [{
	// 		label: "Moyenne par moment de la journée",
	// 		data: dataHeatMap,
	// 		borderWidth: 1,
	// 		hoverBackgroundColor: 'yellow',
	// 		borderColor: "white",
	// 		backgroundColor(context) {
	// 			const value = context.dataset.data[context.dataIndex].v;
	// 			const alpha = value / 100;
	// 			return helpers.color("#ACE8D4").alpha(alpha).rgbString();
	// 		},
	// 		width: ({ chart }) => (chart.chartArea || {}).width / dateLabel.length, //diviser par le nb de colonne
	// 		height: ({ chart }) => (chart.chartArea || {}).height / userLabel.length //par le nb de ligne

	// 	}];

	// 	this.barChartOptions = {
	// 		plugins: {
	// 			legend: {
	// 				display: true,
	// 				position: 'top',
	// 			},
	// 			tooltip: {
	// 				callbacks: {
	// 					label(context) {
	// 						const v = context.dataset.data[context.dataIndex] as any;
	// 						return ['Moment de la journée : ' + v.x, 'Élève : ' + v.y, 'Moyenne : ' + v.v, 'Nombre d' + "'" + 'exercices réalisés : ' + v.nb];
	// 					}
	// 				}
	// 			}
	// 		},
	// 		scales: {
	// 			x: {
	// 				type: 'category',
	// 				labels: dateLabel,
	// 				display: true,
	// 				offset: true
	// 			},
	// 			y: {
	// 				type: 'category',
	// 				labels: userLabel,
	// 				display: true,
	// 				offset: true,

	// 			}
	// 		},
	// 		responsive: true,
	// 		maintainAspectRatio: false

	// 	};

	// }

	///NOMBRE DE VUES VIDEOS///

	async getVuesVideos($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed", "https://xapi.ose/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/video","https://xapi.ose/objectType/video" ]
					}
				},
				{
					$or: [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
								$nin: LrsUtils.codeClassBlacklist
							}
						},
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/codeclasse": {
							 	$nin: LrsUtils.codeClassBlacklist
							}
						}
					]
				},
				{
					"statement.actor.account.name": {
						$nin: [
								"1",
								"2",
								"3",
								"4",
								"29",
								"34",
								"48",
								"90",
								"14582",
								"22226",
								"22076",
								"20902",
								"6473",
								"21968",
								"19612",
								"21545",
								"21894",
								"21915",
								"15831",
								"2172",
								"1413",
								"1412",
								"1904"
						]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.hash",
					name: "$statement.object.definition.name.fr-FR",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { name: "$name" },
						value: {
							//compte le nombre de video
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						name: "$_id.name",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.name) {
						return;
					}
					if (!stats[item.name]) {
						stats[item.name] = [];
					}
					stats[item.name].push(item);
				});
				resolve(stats);
			});
		});
	}

	async getVuesVideosInitialized($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized","https://xapi.ose/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/video","https://xapi.ose/objectType/video"]
					}
				},
				{
					$or: [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
								$nin: LrsUtils.codeClassBlacklist
							}
						},
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/codeclasse": {
							 	$nin: LrsUtils.codeClassBlacklist
							}
						}
					]
				},
				{
					"statement.actor.account.name": {
						$nin: [
								"1",
								"2",
								"3",
								"4",
								"29",
								"34",
								"48",
								"90",
								"14582",
								"22226",
								"22076",
								"20902",
								"6473",
								"21968",
								"19612",
								"21545",
								"21894",
								"21915",
								"15831",
								"2172",
								"1904",
								"1413",
								"1412"
						]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.hash",
					name: "$statement.object.definition.name.fr-FR",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { name: "$name" },
						value: {
							//compte le nombre de video
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						name: "$_id.name",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.name) {
						return;
					}
					if (!stats[item.name]) {
						stats[item.name] = [];
					}
					stats[item.name].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showVuesVideos() {
		this.currentStatisticChoosen = FilteredStatistic.vuesVideos;

		this.chartVuesVideosCompleted = await this.getVuesVideos(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.chartVuesVideosCompleted);

		this.chartVuesVideosInitialized = await this.getVuesVideosInitialized(this.rangeDate.start, this.rangeDate.end);

		console.log("init", this.chartVuesVideosInitialized);
		console.log("completed", this.chartVuesVideosCompleted);

		//Compléter les vidéos terminées

		for (const label in this.chartVuesVideosInitialized) {
			if (!this.chartVuesVideosCompleted[label]) {
				this.chartVuesVideosCompleted[label] = [];
				this.chartVuesVideosCompleted[label].push({ value: 0, name: label });
			}
		}

		//destroy

		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartType = null;

		//build data

		const dataInitialized = [];
		for (const video in this.chartVuesVideosInitialized) {
			console.log(video);
			dataInitialized.push(this.chartVuesVideosInitialized[video][0].value);
		}

		console.log("dtInit", dataInitialized);

		this.barChartLabels = this.extractLabel(this.chartVuesVideosInitialized);

		console.log(this.barChartLabels);

		//Récupérer données dans le même ordre que dataInitialized

		const dataCompleted = [];
		for (const label in this.barChartLabels) {
			dataCompleted.push(this.chartVuesVideosCompleted[this.barChartLabels[label]][0].value);
		}

		console.log("dtCompleted", dataCompleted);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataInitialized,
				label: "Nombre de vidéos commencées",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			},
			{
				data: dataCompleted,
				label: "Nombre de vidéos terminées",
				backgroundColor: "red",
				fill: true,
				borderColor: "red"
			}
		];
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	///HEATMAP DES PERFORMANCES EN FONCTION DES JOURS DE LA SEMAINE (5CC test)///

	async getPerformanceClass() {
		this.currentStatisticChoosen = FilteredStatistic.performanceClass;

		this.dataPerformanceUser = await this.getHeatMapPerformance("70227", this.rangeDate.start, this.rangeDate.end);

		for (const exo in this.dataPerformanceUser) {
			this.dataPerformanceUser[exo].date = new Date(this.dataPerformanceUser[exo].date);
		}

		const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
		const momentDay = ["Matin", "Après-midi"];

		const data = [];
		for (const exo in this.dataPerformanceUser) {
			let d = getDay(this.dataPerformanceUser[exo].date); //= dayOfWeek[d-1]
			let hours = this.dataPerformanceUser[exo].date.getHours();
			if (d === 0) {
				if (!data[dayOfWeek[6]]) {
					data[dayOfWeek[6]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[6]]["Matin"]) {
						data[dayOfWeek[6]]["Matin"] = [];
					}
					data[dayOfWeek[6]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[6]]["Après-midi"]) {
						data[dayOfWeek[6]]["Après-midi"] = [];
					}
					data[dayOfWeek[6]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			} else {
				if (!data[dayOfWeek[d - 1]]) {
					data[dayOfWeek[d - 1]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[d - 1]]["Matin"]) {
						data[dayOfWeek[d - 1]]["Matin"] = [];
					}
					data[dayOfWeek[d - 1]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[d - 1]]["Après-midi"]) {
						data[dayOfWeek[d - 1]]["Après-midi"] = [];
					}
					data[dayOfWeek[d - 1]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			}
		}

		console.log("this.dataPerf", this.dataPerformanceUser);

		//Remplir le vide
		for (const day in dayOfWeek) {
			if (!data[dayOfWeek[day]]) {
				data[dayOfWeek[day]] = [];
			}
			for (const moment in momentDay) {
				if (!data[dayOfWeek[day]][momentDay[moment]]) {
					data[dayOfWeek[day]][momentDay[moment]] = [];
					data[dayOfWeek[day]][momentDay[moment]].push({ value: "Non noté" });
				}
			}
		}

		console.log("data", data);

		const moyennePerDay = [];
		for (const day in data) {
			if (!moyennePerDay[day]) {
				moyennePerDay[day] = [];
			}
			for (const moment in data[day]) {
				if (!moyennePerDay[day][moment]) {
					moyennePerDay[day][moment] = [];
				}
				let sum = 0;
				if (data[day][moment][0].value === "Non noté") {
					moyennePerDay[day][moment].push("Non noté");
				} else {
					for (const exo in data[day][moment]) {
						sum += data[day][moment][exo].value;
					}
					moyennePerDay[day][moment].push(Math.round(sum / data[day][moment].length));
				}
			}
		}

		console.log("moyenne", moyennePerDay);

		//build dataHeatMap
		const dataHeatMap = [];

		for (const day in data) {
			for (const moment in data[day]) {
				dataHeatMap.push({ x: moment, y: day, v: moyennePerDay[day][moment][0], nbEx: data[day][moment].length });
			}
		}

		console.log("dataHeatMap", dataHeatMap);

		//Légende
		const res = {
			"Non favorable": [],
			"Peu favorable": [],
			Favorable: [],
			"Très favorable": [],
			"Non noté": []
		};
		dataHeatMap.forEach(stat => {
			if (stat.v < 25 && stat.v != "Non noté") {
				res["Non favorable"].push(stat);
			} else if (stat.v < 50 && stat.v != "Non noté") {
				res["Peu favorable"].push(stat);
			} else if (stat.v < 75 && stat.v != "Non noté") {
				res["Favorable"].push(stat);
			} else if (stat.v === "Non noté") {
				res["Non noté"].push(stat);
			} else {
				res["Très favorable"].push(stat);
			}
		});

		console.log("res", res);

		//destroy
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;

		this.barChartType = "matrix";
		this.barChartData = [
			{
				label: "Très favorable",
				data: res["Très favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#A2F5AA",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Favorable",
				data: res["Favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EFF5A4",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Peu favorable",
				data: res["Peu favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EDA864",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non favorable",
				data: res["Non favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ED928A",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non noté",
				data: res["Non noté"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ABBBF7",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			}
		];

		this.barChartOptions = {
			plugins: {
				legend: {
					display: true,
					position: "top"
				},
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Moment de la journée : " + v.x,
								"Jour de la semaine : " + v.y,
								"Moyenne de la classe : " + v.v,
								"Nombre d" + "'" + "exercices réalisés : " + v.nbEx
							];
						}
					}
				}
			},
			scales: {
				x: {
					type: "category",
					labels: ["Matin", "Après-midi"],
					display: true,
					offset: true
				},
				y: {
					type: "category",
					labels: ["Dimanche", "Samedi", "Vendredi", "Jeudi", "Mercredi", "Mardi", "Lundi"],
					display: true,
					offset: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		};
	}

	///Même graphe (3CC test)///

	async getPerformanceClass3CC() {
		this.currentStatisticChoosen = FilteredStatistic.performanceClass3CC;

		this.dataPerformanceUser = await this.getHeatMapPerformance("70227", this.rangeDate.start, this.rangeDate.end);

		for (const exo in this.dataPerformanceUser) {
			this.dataPerformanceUser[exo].date = new Date(this.dataPerformanceUser[exo].date);
		}

		const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];
		const momentDay = ["Matin", "Après-midi"];

		const data = [];
		for (const exo in this.dataPerformanceUser) {
			let d = getDay(this.dataPerformanceUser[exo].date); //= dayOfWeek[d-1]
			let hours = this.dataPerformanceUser[exo].date.getHours();
			if (d != 0 && d != 6) {
				if (!data[dayOfWeek[d - 1]]) {
					data[dayOfWeek[d - 1]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[d - 1]]["Matin"]) {
						data[dayOfWeek[d - 1]]["Matin"] = [];
					}
					data[dayOfWeek[d - 1]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[d - 1]]["Après-midi"]) {
						data[dayOfWeek[d - 1]]["Après-midi"] = [];
					}
					data[dayOfWeek[d - 1]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			}
		}

		console.log("this.dataPerf", this.dataPerformanceUser);

		//Remplir le vide
		for (const day in dayOfWeek) {
			if (!data[dayOfWeek[day]]) {
				data[dayOfWeek[day]] = [];
			}
			for (const moment in momentDay) {
				if (!data[dayOfWeek[day]][momentDay[moment]]) {
					data[dayOfWeek[day]][momentDay[moment]] = [];
					data[dayOfWeek[day]][momentDay[moment]].push({ value: "Non noté", nbEx: 0 });
				}
			}
		}

		console.log("data", data);

		const moyennePerDay = [];
		for (const day in data) {
			if (!moyennePerDay[day]) {
				moyennePerDay[day] = [];
			}
			for (const moment in data[day]) {
				if (!moyennePerDay[day][moment]) {
					moyennePerDay[day][moment] = [];
				}
				let sum = 0;
				if (data[day][moment][0].value === "Non noté") {
					moyennePerDay[day][moment].push("Non noté");
				} else {
					for (const exo in data[day][moment]) {
						sum += data[day][moment][exo].value;
					}
					moyennePerDay[day][moment].push(Math.round(sum / data[day][moment].length));
				}
			}
		}

		console.log("moyenne", moyennePerDay);

		//Légende
		const res = {
			"Plus favorable": [],
			"Moins favorable": [],
			"Non noté": []
		};

		for (const day in moyennePerDay) {
			if (moyennePerDay[day]["Matin"][0] != "Non noté" && moyennePerDay[day]["Après-midi"][0] != "Non noté") {
				if (
					moyennePerDay[day]["Matin"][0] < moyennePerDay[day]["Après-midi"][0] &&
					moyennePerDay[day]["Matin"][0] != moyennePerDay[day]["Après-midi"][0]
				) {
					res["Plus favorable"].push({
						x: "Après-midi",
						y: day,
						v: moyennePerDay[day]["Après-midi"][0],
						nbEx: data[day]["Après-midi"].length
					});
					res["Moins favorable"].push({ x: "Matin", y: day, v: moyennePerDay[day]["Matin"][0], nbEx: data[day]["Matin"].length });
				} else if (
					moyennePerDay[day]["Matin"][0] > moyennePerDay[day]["Après-midi"][0] &&
					moyennePerDay[day]["Matin"][0] != moyennePerDay[day]["Après-midi"][0]
				) {
					res["Plus favorable"].push({ x: "Matin", y: day, v: moyennePerDay[day]["Matin"][0], nbEx: data[day]["Matin"].length });
					res["Moins favorable"].push({
						x: "Après-midi",
						y: day,
						v: moyennePerDay[day]["Après-midi"][0],
						nbEx: data[day]["Après-midi"].length
					});
				} else if (moyennePerDay[day]["Matin"][0] === moyennePerDay[day]["Après-midi"][0]) {
					res["Plus favorable"].push({ x: "Matin", y: day, v: moyennePerDay[day]["Matin"][0], nbEx: data[day]["Matin"].length });
					res["Plus favorable"].push({
						x: "Après-midi",
						y: day,
						v: moyennePerDay[day]["Après-midi"][0],
						nbEx: data[day]["Après-midi"].length
					});
				}
			} else if (moyennePerDay[day]["Matin"][0] === "Non noté" && moyennePerDay[day]["Après-midi"][0] === "Non noté") {
				res["Non noté"].push({ x: "Matin", y: day, v: moyennePerDay[day]["Matin"][0], nbEx: 0 });
				res["Non noté"].push({ x: "Après-midi", y: day, v: moyennePerDay[day]["Après-midi"][0], nbEx: 0 });
			} else if (moyennePerDay[day]["Matin"][0] === "Non noté" || moyennePerDay[day]["Après-midi"][0] === "Non noté") {
				if (
					moyennePerDay[day]["Matin"][0] === "Non noté" &&
					moyennePerDay[day]["Matin"][0] != moyennePerDay[day]["Après-midi"][0]
				) {
					res["Non noté"].push({ x: "Matin", y: day, v: moyennePerDay[day]["Matin"][0], nbEx: 0 });
					res["Plus favorable"].push({
						x: "Après-midi",
						y: day,
						v: moyennePerDay[day]["Après-midi"][0],
						nbEx: data[day]["Après-midi"].length
					});
				} else if (
					moyennePerDay[day]["Après-midi"][0] === "Non noté" &&
					moyennePerDay[day]["Matin"][0] != moyennePerDay[day]["Après-midi"][0]
				) {
					res["Plus favorable"].push({ x: "Matin", y: day, v: moyennePerDay[day]["Matin"][0], nbEx: data[day]["Matin"].length });
					res["Non noté"].push({ x: "Après-midi", y: day, v: moyennePerDay[day]["Après-midi"][0], nbEx: 0 });
				}
			}
		}

		console.log("res", res);

		//destroy
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;

		this.barChartType = "matrix";
		this.barChartData = [
			{
				label: "Plus favorable",
				data: res["Plus favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#A2F5AA",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Moins favorable",
				data: res["Moins favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EDA864",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non noté",
				data: res["Non noté"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ABBBF7",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			}
		];

		this.barChartOptions = {
			plugins: {
				legend: {
					display: true,
					position: "top"
				},
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Moment de la journée : " + v.x,
								"Jour de la semaine : " + v.y,
								"Moyenne de la classe : " + v.v,
								"Nombre d" + "'" + "exercices réalisés : " + v.nbEx
							];
						}
					}
				}
			},
			scales: {
				x: {
					type: "category",
					labels: ["Matin", "Après-midi"],
					display: true,
					offset: true
				},
				y: {
					type: "category",
					labels: ["Vendredi", "Jeudi", "Mercredi", "Mardi", "Lundi"],
					display: true,
					offset: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		};
	}

	///HEATMAP DES FERFORMANCES EN FONCTION DES JOURS DE LA SEMAINE PAR DEMI JOURNEE ET POUR TOUTES CLASSES CONFONDUES MATHIA///

	async getHeatMapPerfAllMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							tauxBonnesReponses: "$pourcentage",
							mydate: "$mydate",
							session: "$session"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", taux: "$_id.tauxBonnesReponses", session: "$_id.session" }
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						value: { $ceil: "$_id.taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = [];
				console.log("results", results);
				results.forEach(item => {
					if (item.value && item.userId) {
						stats.push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async getPerformanceAll5CCMathia() {
		this.currentStatisticChoosen = FilteredStatistic.competenceDayMomentAllMathia;

		this.dataPerformanceUser = await this.getHeatMapPerfAllMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.dataPerformanceUser);

		for (const exo in this.dataPerformanceUser) {
			this.dataPerformanceUser[exo].date = new Date(this.dataPerformanceUser[exo].date);
		}

		const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
		const momentDay = ["Matin", "Après-midi"];

		const data = [];
		for (const exo in this.dataPerformanceUser) {
			let d = getDay(this.dataPerformanceUser[exo].date); //= dayOfWeek[d-1]
			let hours = this.dataPerformanceUser[exo].date.getHours();
			if (d === 0) {
				if (!data[dayOfWeek[6]]) {
					data[dayOfWeek[6]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[6]]["Matin"]) {
						data[dayOfWeek[6]]["Matin"] = [];
					}
					data[dayOfWeek[6]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[6]]["Après-midi"]) {
						data[dayOfWeek[6]]["Après-midi"] = [];
					}
					data[dayOfWeek[6]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			} else {
				if (!data[dayOfWeek[d - 1]]) {
					data[dayOfWeek[d - 1]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[d - 1]]["Matin"]) {
						data[dayOfWeek[d - 1]]["Matin"] = [];
					}
					data[dayOfWeek[d - 1]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[d - 1]]["Après-midi"]) {
						data[dayOfWeek[d - 1]]["Après-midi"] = [];
					}
					data[dayOfWeek[d - 1]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			}
		}

		console.log("this.dataPerf", this.dataPerformanceUser);

		//Remplir le vide
		for (const day in dayOfWeek) {
			if (!data[dayOfWeek[day]]) {
				data[dayOfWeek[day]] = [];
			}
			for (const moment in momentDay) {
				if (!data[dayOfWeek[day]][momentDay[moment]]) {
					data[dayOfWeek[day]][momentDay[moment]] = [];
					data[dayOfWeek[day]][momentDay[moment]].push({ value: "Non noté" });
				}
			}
		}

		console.log("data", data);

		const moyennePerDay = [];
		for (const day in data) {
			if (!moyennePerDay[day]) {
				moyennePerDay[day] = [];
			}
			for (const moment in data[day]) {
				if (!moyennePerDay[day][moment]) {
					moyennePerDay[day][moment] = [];
				}
				let sum = 0;
				if (data[day][moment][0].value === "Non noté") {
					moyennePerDay[day][moment].push("Non noté");
				} else {
					for (const exo in data[day][moment]) {
						sum += data[day][moment][exo].value;
					}
					moyennePerDay[day][moment].push(Math.round(sum / data[day][moment].length));
				}
			}
		}

		console.log("moyenne", moyennePerDay);

		//build dataHeatMap
		const dataHeatMap = [];

		for (const day in data) {
			for (const moment in data[day]) {
				dataHeatMap.push({ x: moment, y: day, v: moyennePerDay[day][moment][0], nbEx: data[day][moment].length });
			}
		}

		console.log("dataHeatMap", dataHeatMap);

		//Légende
		const res = {
			"Non favorable": [],
			"Peu favorable": [],
			Favorable: [],
			"Très favorable": [],
			"Non noté": []
		};
		dataHeatMap.forEach(stat => {
			if (stat.v < 25 && stat.v != "Non noté") {
				res["Non favorable"].push(stat);
			} else if (stat.v < 50 && stat.v != "Non noté") {
				res["Peu favorable"].push(stat);
			} else if (stat.v < 75 && stat.v != "Non noté") {
				res["Favorable"].push(stat);
			} else if (stat.v === "Non noté") {
				res["Non noté"].push(stat);
			} else {
				res["Très favorable"].push(stat);
			}
		});

		console.log("res", res);

		//destroy
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.barChartType = "matrix";
		this.barChartData = [
			{
				label: "Très favorable",
				data: res["Très favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#A2F5AA",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Favorable",
				data: res["Favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EFF5A4",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Peu favorable",
				data: res["Peu favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EDA864",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non favorable",
				data: res["Non favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ED928A",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non noté",
				data: res["Non noté"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ABBBF7",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			}
		];

		this.barChartOptions = {
			plugins: {
				legend: {
					display: true,
					position: "top"
				},
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Moment de la journée : " + v.x,
								"Jour de la semaine : " + v.y,
								"Taux de réussite moyen : " + v.v + "%",
								"Nombre d" + "'" + "exercices réalisés : " + v.nbEx
							];
						}
					}
				}
			},
			scales: {
				x: {
					type: "category",
					labels: ["Matin", "Après-midi"],
					display: true,
					offset: true
				},
				y: {
					type: "category",
					labels: ["Dimanche", "Samedi", "Vendredi", "Jeudi", "Mercredi", "Mardi", "Lundi"],
					display: true,
					offset: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		};
	}

	///HEATMAP DES FERFORMANCES EN FONCTION DES JOURS DE LA SEMAINE PAR DEMI JOURNEE ET POUR TOUTES CLASSES CONFONDUES KIDAIA///

	async getHeatMapPerfAllKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							tauxBonnesReponses: "$pourcentage",
							mydate: "$mydate",
							session: "$session"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", taux: "$_id.tauxBonnesReponses", session: "$_id.session" }
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId",
						value: { $ceil: "$_id.taux" }
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = [];
				console.log("results", results);
				results.forEach(item => {
					if (item.value && item.userId) {
						stats.push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async getPerformanceAll5CCKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.competenceDayMomentAllKidaia;

		this.dataPerformanceUser = await this.getHeatMapPerfAllKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.dataPerformanceUser);

		for (const exo in this.dataPerformanceUser) {
			this.dataPerformanceUser[exo].date = new Date(this.dataPerformanceUser[exo].date);
		}

		const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
		const momentDay = ["Matin", "Après-midi"];

		const data = [];
		for (const exo in this.dataPerformanceUser) {
			let d = getDay(this.dataPerformanceUser[exo].date); //= dayOfWeek[d-1]
			let hours = this.dataPerformanceUser[exo].date.getHours();
			if (d === 0) {
				if (!data[dayOfWeek[6]]) {
					data[dayOfWeek[6]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[6]]["Matin"]) {
						data[dayOfWeek[6]]["Matin"] = [];
					}
					data[dayOfWeek[6]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[6]]["Après-midi"]) {
						data[dayOfWeek[6]]["Après-midi"] = [];
					}
					data[dayOfWeek[6]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			} else {
				if (!data[dayOfWeek[d - 1]]) {
					data[dayOfWeek[d - 1]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[d - 1]]["Matin"]) {
						data[dayOfWeek[d - 1]]["Matin"] = [];
					}
					data[dayOfWeek[d - 1]]["Matin"].push(this.dataPerformanceUser[exo]);
				} else {
					if (!data[dayOfWeek[d - 1]]["Après-midi"]) {
						data[dayOfWeek[d - 1]]["Après-midi"] = [];
					}
					data[dayOfWeek[d - 1]]["Après-midi"].push(this.dataPerformanceUser[exo]);
				}
			}
		}

		console.log("this.dataPerf", this.dataPerformanceUser);

		//Remplir le vide
		for (const day in dayOfWeek) {
			if (!data[dayOfWeek[day]]) {
				data[dayOfWeek[day]] = [];
			}
			for (const moment in momentDay) {
				if (!data[dayOfWeek[day]][momentDay[moment]]) {
					data[dayOfWeek[day]][momentDay[moment]] = [];
					data[dayOfWeek[day]][momentDay[moment]].push({ value: "Non noté" });
				}
			}
		}

		console.log("data", data);

		const moyennePerDay = [];
		for (const day in data) {
			if (!moyennePerDay[day]) {
				moyennePerDay[day] = [];
			}
			for (const moment in data[day]) {
				if (!moyennePerDay[day][moment]) {
					moyennePerDay[day][moment] = [];
				}
				let sum = 0;
				if (data[day][moment][0].value === "Non noté") {
					moyennePerDay[day][moment].push("Non noté");
				} else {
					for (const exo in data[day][moment]) {
						sum += data[day][moment][exo].value;
					}
					moyennePerDay[day][moment].push(Math.round(sum / data[day][moment].length));
				}
			}
		}

		console.log("moyenne", moyennePerDay);

		//build dataHeatMap
		const dataHeatMap = [];

		for (const day in data) {
			for (const moment in data[day]) {
				dataHeatMap.push({ x: moment, y: day, v: moyennePerDay[day][moment][0], nbEx: data[day][moment].length });
			}
		}

		console.log("dataHeatMap", dataHeatMap);

		//Légende
		const res = {
			"Non favorable": [],
			"Peu favorable": [],
			Favorable: [],
			"Très favorable": [],
			"Non noté": []
		};
		dataHeatMap.forEach(stat => {
			if (stat.v < 25 && stat.v != "Non noté") {
				res["Non favorable"].push(stat);
			} else if (stat.v < 50 && stat.v != "Non noté") {
				res["Peu favorable"].push(stat);
			} else if (stat.v < 75 && stat.v != "Non noté") {
				res["Favorable"].push(stat);
			} else if (stat.v === "Non noté") {
				res["Non noté"].push(stat);
			} else {
				res["Très favorable"].push(stat);
			}
		});

		console.log("res", res);

		//destroy
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.barChartType = "matrix";
		this.barChartData = [
			{
				label: "Très favorable",
				data: res["Très favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#A2F5AA",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Favorable",
				data: res["Favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EFF5A4",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Peu favorable",
				data: res["Peu favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#EDA864",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non favorable",
				data: res["Non favorable"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ED928A",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			},

			{
				label: "Non noté",
				data: res["Non noté"],
				borderWidth: 1,
				hoverBackgroundColor: "yellow",
				borderColor: "white",
				backgroundColor: "#ABBBF7",
				width: ({ chart }) => (chart.chartArea || {}).width / 2, //diviser par le nb de colonne
				height: ({ chart }) => (chart.chartArea || {}).height / dayOfWeek.length //par le nb de ligne
			}
		];

		this.barChartOptions = {
			plugins: {
				legend: {
					display: true,
					position: "top"
				},
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Moment de la journée : " + v.x,
								"Jour de la semaine : " + v.y,
								"Taux de réussite moyen : " + v.v + "%",
								"Nombre d" + "'" + "exercices réalisés : " + v.nbEx
							];
						}
					}
				}
			},
			scales: {
				x: {
					type: "category",
					labels: ["Matin", "Après-midi"],
					display: true,
					offset: true
				},
				y: {
					type: "category",
					labels: ["Dimanche", "Samedi", "Vendredi", "Jeudi", "Mercredi", "Mardi", "Lundi"],
					display: true,
					offset: true
				}
			},
			responsive: true,
			maintainAspectRatio: false
		};
	}

	///UTILISATION DES MODES DE RÉPONSES MATHIA///

	async getModesReponsesMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/finished"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/question"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/mode_reponse": {
						$nin: ["Astro Ardoise", "Cosmo Clavier", "Supra Vocal"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/mode_reponse",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate", value: "$value" },
						nb: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: "$_id.value",
						nb: "$nb"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (item.value && item.date && item.nb) {
						if (!item.date) {
							return;
						}
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});
				resolve(stats);
			});
		});
	}

	async showModesReponsesMathia() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.canvasShape = null;
		this.barChartData = null;

		this.canvasShape = "square";
		this.currentStatisticChoosen = FilteredStatistic.modesReponsesMathia;

		this.currentData = await this.getModesReponsesMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.currentData);

		const results = {
			Stt: 0,
			Numpad: 0,
			Drawing: 0
		};

		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (stat.value === "stt") {
						results["Stt"] += stat.nb;
					} else if (stat.value === "numpad") {
						results["Numpad"] += stat.nb;
					} else if (stat.value === "drawing") {
						results["Drawing"] += stat.nb;
					}
				});
			}
		}

		console.log("results", results);

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["Stt", "Numpad", "Drawing"];
		this.barChartType = "doughnut";
		this.barChartData = [
			{
				data: [results["Stt"], results["Numpad"], results["Drawing"]],
				backgroundColor: ["#BADBB8", "#C0E3FF", "#E4931E"]
			}
		];
	}

	///UTILISATION DES MODES DE RÉPONSES KIDAIA///

	async getModesReponsesKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/finished"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/question"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/mode_reponse": {
						$nin: ["Astro Ardoise", "Cosmo Clavier", "Supra Vocal"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/mode_reponse",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate", value: "$value" },
						nb: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: "$_id.value",
						nb: "$nb"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (item.value && item.date && item.nb) {
						if (!item.date) {
							return;
						}
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});
				resolve(stats);
			});
		});
	}

	async showModesReponsesKidaia() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "square";
		this.currentStatisticChoosen = FilteredStatistic.modesReponsesKidaia;

		this.currentData = await this.getModesReponsesKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.currentData);

		const results = {
			Stt: 0,
			Numpad: 0,
			Drawing: 0
		};

		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (stat.value === "stt") {
						results["Stt"] += stat.nb;
					} else if (stat.value === "numpad") {
						results["Numpad"] += stat.nb;
					} else if (stat.value === "drawing") {
						results["Drawing"] += stat.nb;
					}
				});
			}
		}

		console.log("results", results);

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["Stt", "Numpad", "Drawing"];
		this.barChartType = "doughnut";
		this.barChartData = [
			{
				data: [results["Stt"], results["Numpad"], results["Drawing"]],
				backgroundColor: ["#BADBB8", "#C0E3FF", "#E4931E"]
			}
		];
	}

	///UTILISATION DU MODE HOLO MATHIA///

	async getModeHoloMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/finished"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/question"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/holo",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate", value: "$value" },
						nb: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: "$_id.value",
						nb: "$nb"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (item.value && item.date && item.nb) {
						if (!item.date) {
							return;
						}
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});
				resolve(stats);
			});
		});
	}

	async showModeHoloMathia() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "square";
		this.currentStatisticChoosen = FilteredStatistic.modeHoloMathia;

		this.currentData = await this.getModeHoloMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.currentData);

		const results = {
			Sans: 0,
			Pyramide: 0,
			"Une face": 0
		};

		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (stat.value === "Sans" || stat.value === "No") {
						results["Sans"] += stat.nb;
					} else if (stat.value === "Pyramide") {
						results["Pyramide"] += stat.nb;
					} else if (stat.value === "Une face" || stat.value === "One face") {
						results["Une face"] += stat.nb;
					}
				});
			}
		}

		console.log("results", results);

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["Sans", "Pyramide", "Une face"];
		this.barChartType = "pie";
		this.barChartData = [
			{
				data: [results["Sans"], results["Pyramide"], results["Une face"]],
				backgroundColor: ["#BADBB8", "#C0E3FF", "#E4931E"]
			}
		];
	}

	///UTILISATION DU MODE HOLO KIDAIA///

	async getModeHoloKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/finished"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/question"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/holo",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate", value: "$value" },
						nb: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: "$_id.value",
						nb: "$nb"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (item.value && item.date && item.nb) {
						if (!item.date) {
							return;
						}
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});
				resolve(stats);
			});
		});
	}

	async showModeHoloKidaia() {
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "square";
		this.currentStatisticChoosen = FilteredStatistic.modeHoloKidaia;

		this.currentData = await this.getModeHoloKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.currentData);

		const results = {
			Sans: 0,
			Pyramide: 0,
			"Une face": 0
		};

		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (stat.value === "Sans" || stat.value === "No") {
						results["Sans"] += stat.nb;
					} else if (stat.value === "Pyramide") {
						results["Pyramide"] += stat.nb;
					} else if (stat.value === "Une face" || stat.value === "One face") {
						results["Une face"] += stat.nb;
					}
				});
			}
		}

		console.log("results", results);

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["Sans", "Pyramide", "Une face"];
		this.barChartType = "pie";
		this.barChartData = [
			{
				data: [results["Sans"], results["Pyramide"], results["Une face"]],
				backgroundColor: ["#BADBB8", "#C0E3FF", "#E4931E"]
			}
		];
	}

	////NOMBRE D'ACTIVITES ACHEVEES PAR GABARIT MATHIA SUPERVISION///

	async getActCompletedGabaritsAllMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				// {
				// 	"statement.object.definition.type": {
				// 		$in: ["https://xapi.mathia.education/objectType/exercise"]
				// 	}
				// },
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							//compte le nombre d'exercices terminés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showActCompletedPerGabaritAllMathia() {
		this.currentStatisticChoosen = FilteredStatistic.exTerminesGabaritAllMathia;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getActCompletedGabaritsAllMathia(this.rangeDate.start, this.rangeDate.end);

		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);
		this.barChartType = "bar";
		(this.barChartData = [
			{
				data: this.extractData(this.chartExercicePerGabarit),
				label: "Activités achevées par gabarit",
				backgroundColor: [
					"rgba(255, 99, 132, 0.5)",
					"rgba(255, 159, 64, 0.5)",
					"rgba(255, 205, 86, 0.5)",
					"rgba(75, 192, 192, 0.5)",
					"rgba(54, 162, 235, 0.5)",
					"rgba(153, 102, 255, 0.5)",
					"rgba(201, 203, 207, 0.5)",
					"rgba(252, 192, 244, 0.5)",
					"rgba(168, 250, 165, 0.5)"
				],
				fill: true,
				borderColor: [
					"rgb(255, 99, 132)",
					"rgb(255, 159, 64)",
					"rgb(255, 205, 86)",
					"rgb(75, 192, 192)",
					"rgb(54, 162, 235)",
					"rgb(153, 102, 255)",
					"rgb(201, 203, 207)",
					"rgb(252, 192, 244)",
					"rgb(168, 250, 165)"
				],
				borderWidth: 1
			}
		]),
			(this.barChartOptions.scales = {
				y: {
					beginAtZero: true
				}
			});
	}

	////NOMBRE D'ACTIVITES ACHEVEES PAR GABARIT KIDAIA SUPERVISION///

	async getActCompletedGabaritsAllKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				// {
				// 	"statement.object.definition.type": {
				// 		$in: ["https://xapi.mathia.education/objectType/question"]
				// 	}
				// },
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showActCompletedPerGabaritAllKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.exTerminesGabaritAllKidaia;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getActCompletedGabaritsAllKidaia(this.rangeDate.start, this.rangeDate.end);

		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);
		this.barChartType = "bar";
		(this.barChartData = [
			{
				data: this.extractData(this.chartExercicePerGabarit),
				label: "Activités achevées par gabarit",
				backgroundColor: [
					"rgba(255, 99, 132, 0.5)",
					"rgba(255, 159, 64, 0.5)",
					"rgba(255, 205, 86, 0.5)",
					"rgba(75, 192, 192, 0.5)",
					"rgba(54, 162, 235, 0.5)",
					"rgba(153, 102, 255, 0.5)",
					"rgba(201, 203, 207, 0.5)",
					"rgba(252, 192, 244, 0.5)",
					"rgba(168, 250, 165, 0.5)"
				], //Rajouter couleurs
				fill: true,
				borderColor: [
					"rgb(255, 99, 132)",
					"rgb(255, 159, 64)",
					"rgb(255, 205, 86)",
					"rgb(75, 192, 192)",
					"rgb(54, 162, 235)",
					"rgb(153, 102, 255)",
					"rgb(201, 203, 207)",
					"rgb(252, 192, 244)",
					"rgba(168, 250, 165)"
				],
				borderWidth: 1
			}
		]),
			(this.barChartOptions.scales = {
				y: {
					beginAtZero: true
				}
			});
	}

	///NOMBRE D'ACTIVITES DE TYPE ACHEVEES PAR JOUR DE LA SEMAINE ET MOMENT DE LA JOURNEE POUR LA PLATEFORME MATHIA///

	async getUserPerMomentMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							mydate: "$mydate",
							session: "$session"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", session: "$_id.session" }
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = [];
				console.log("results", results);
				results.forEach(item => {
					if (item.date && item.userId) {
						stats.push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async showUserPerMomentMathia() {
		this.currentStatisticChoosen = FilteredStatistic.userPerMomentMathia;

		this.currentData = await this.getUserPerMomentMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.currentData);

		for (const exo in this.currentData) {
			this.currentData[exo].date = new Date(this.currentData[exo].date);
		}

		const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
		const momentDay = ["Matin", "Après-midi"];

		const data = [];
		for (const exo in this.currentData) {
			let d = getDay(this.currentData[exo].date); //= dayOfWeek[d-1]
			let hours = this.currentData[exo].date.getHours();
			if (d === 0) {
				if (!data[dayOfWeek[6]]) {
					data[dayOfWeek[6]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[6]]["Matin"]) {
						data[dayOfWeek[6]]["Matin"] = [];
					}
					data[dayOfWeek[6]]["Matin"].push(this.currentData[exo]);
				} else {
					if (!data[dayOfWeek[6]]["Après-midi"]) {
						data[dayOfWeek[6]]["Après-midi"] = [];
					}
					data[dayOfWeek[6]]["Après-midi"].push(this.currentData[exo]);
				}
			} else {
				if (!data[dayOfWeek[d - 1]]) {
					data[dayOfWeek[d - 1]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[d - 1]]["Matin"]) {
						data[dayOfWeek[d - 1]]["Matin"] = [];
					}
					data[dayOfWeek[d - 1]]["Matin"].push(this.currentData[exo]);
				} else {
					if (!data[dayOfWeek[d - 1]]["Après-midi"]) {
						data[dayOfWeek[d - 1]]["Après-midi"] = [];
					}
					data[dayOfWeek[d - 1]]["Après-midi"].push(this.currentData[exo]);
				}
			}
		}

		console.log("res", data);

		//build datas

		const dataMatin = [];
		const dataAprem = [];

		for (const day in dayOfWeek) {
			for (const moment in data[dayOfWeek[day]]) {
				if (moment === "Matin") {
					dataMatin.push(data[dayOfWeek[day]][moment].length);
				} else {
					dataAprem.push(data[dayOfWeek[day]][moment].length);
				}
			}
		}

		console.log("matin", dataMatin);
		console.log("aprem", dataAprem);

		//destroy
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.barChartLabels = dayOfWeek;
		this.barChartType = "bar";
		this.barChartData = [
			{
				label: "Matin",
				data: dataMatin,
				borderWidth: 1,
				borderColor: "white",
				backgroundColor: "#CAF1FA"
			},
			{
				label: "Après-midi",
				data: dataAprem,
				borderWidth: 1,
				borderColor: "white",
				backgroundColor: "#5089FA"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	///NOMBRE D'ACTIVITES DE TYPE ACHEVEES PAR JOUR DE LA SEMAINE ET MOMENT DE LA JOURNEE POUR LA PLATEFORME KIDAIA///

	async getUserPerMomentKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							mydate: "$mydate",
							session: "$session"
						}
					}
				},
				{
					$group: {
						_id: { date: "$_id.mydate", userId: "$_id.userId", session: "$_id.session" }
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						userId: "$_id.userId"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = [];
				console.log("results", results);
				results.forEach(item => {
					if (item.date && item.userId) {
						stats.push(item);
					}
				});

				resolve(stats);
			});
		});
	}

	async showUserPerMomentKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.userPerMomentKidaia;

		this.currentData = await this.getUserPerMomentKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.currentData);

		for (const exo in this.currentData) {
			this.currentData[exo].date = new Date(this.currentData[exo].date);
		}

		const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
		const momentDay = ["Matin", "Après-midi"];

		const data = [];
		for (const exo in this.currentData) {
			let d = getDay(this.currentData[exo].date); //= dayOfWeek[d-1]
			let hours = this.currentData[exo].date.getHours();
			if (d === 0) {
				if (!data[dayOfWeek[6]]) {
					data[dayOfWeek[6]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[6]]["Matin"]) {
						data[dayOfWeek[6]]["Matin"] = [];
					}
					data[dayOfWeek[6]]["Matin"].push(this.currentData[exo]);
				} else {
					if (!data[dayOfWeek[6]]["Après-midi"]) {
						data[dayOfWeek[6]]["Après-midi"] = [];
					}
					data[dayOfWeek[6]]["Après-midi"].push(this.currentData[exo]);
				}
			} else {
				if (!data[dayOfWeek[d - 1]]) {
					data[dayOfWeek[d - 1]] = [];
				}
				if (hours < 13) {
					if (!data[dayOfWeek[d - 1]]["Matin"]) {
						data[dayOfWeek[d - 1]]["Matin"] = [];
					}
					data[dayOfWeek[d - 1]]["Matin"].push(this.currentData[exo]);
				} else {
					if (!data[dayOfWeek[d - 1]]["Après-midi"]) {
						data[dayOfWeek[d - 1]]["Après-midi"] = [];
					}
					data[dayOfWeek[d - 1]]["Après-midi"].push(this.currentData[exo]);
				}
			}
		}

		console.log("res", data);

		//build datas

		const dataMatin = [];
		const dataAprem = [];

		for (const day in dayOfWeek) {
			for (const moment in data[dayOfWeek[day]]) {
				if (moment === "Matin") {
					dataMatin.push(data[dayOfWeek[day]][moment].length);
				} else {
					dataAprem.push(data[dayOfWeek[day]][moment].length);
				}
			}
		}

		console.log("matin", dataMatin);
		console.log("aprem", dataAprem);

		//destroy
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.barChartLabels = dayOfWeek;
		this.barChartType = "bar";
		this.barChartData = [
			{
				label: "Matin",
				data: dataMatin,
				borderWidth: 1,
				borderColor: "white",
				backgroundColor: "#CAF1FA"
			},
			{
				label: "Après-midi",
				data: dataAprem,
				borderWidth: 1,
				borderColor: "white",
				backgroundColor: "#5089FA"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	//// GRAPHIQUE DES ACTIVITES ACHEVEES EN MODE AVENTURE ET EN MODE ENTRAINEMENT MATHIA///

	async getCompletedAventureMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [true]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'act achevées en mode aventure
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async getCompletedEntrainementMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [false]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'act achevées en mode aventure
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showActAventureEntrainementMathia() {
		this.currentStatisticChoosen = FilteredStatistic.completedAventureEntrainementMathia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.chartAventure = await this.getCompletedAventureMathia(this.rangeDate.start, this.rangeDate.end);
		this.chartEntrainement = await this.getCompletedEntrainementMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("aventure", this.chartAventure);
		console.log("entrainement", this.chartEntrainement);

		this.barChartLabels = this.extractLabel(this.chartAventure);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartEntrainement),
				label: "Activités achevées en mode entraînement",
				backgroundColor: "#B3FCB6",
				fill: true,
				borderColor: "#B3FCB6"
			},
			{
				data: this.extractData(this.chartAventure),
				label: "Activités achevées en mode aventure",
				backgroundColor: "#4BAD4F",
				fill: true,
				borderColor: "#4BAD4F"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	//// GRAPHIQUE DES ACTIVITES ACHEVEES EN MODE AVENTURE ET EN MODE ENTRAINEMENT KIDAIA///

	async getCompletedAventureKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [true]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'act achevées en mode aventure
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async getCompletedEntrainementKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [false]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					userId: "$statement.actor.account.name",
					classId: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'act achevées en mode aventure
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showActAventureEntrainementKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.completedAventureEntrainementKidaia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.chartAventure = await this.getCompletedAventureKidaia(this.rangeDate.start, this.rangeDate.end);
		this.chartEntrainement = await this.getCompletedEntrainementKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("aventure", this.chartAventure);
		console.log("entrainement", this.chartEntrainement);

		this.barChartLabels = this.extractLabel(this.chartAventure);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartEntrainement),
				label: "Activités achevées en mode entraînement",
				backgroundColor: "#B3FCB6",
				fill: true,
				borderColor: "#B3FCB6"
			},
			{
				data: this.extractData(this.chartAventure),
				label: "Activités achevées en mode aventure",
				backgroundColor: "#4BAD4F",
				fill: true,
				borderColor: "#4BAD4F"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	////GRAPHE DUREE MOYENNE PAR EXERCICE////

	async getAverageTimePerGabaritMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					timeDuration: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					userId: "$statement.actor.account.name",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							time: "$timeDuration",
							tauxBonnesReponses: "$pourcentage",
							idActivite: "$idActivite",
							mydate: "$mydate",
							value: "$value"
						}
					}
				},
				{
					$group: {
						_id: { idActivite: "$_id.idActivite" },
						duration: {
							$avg: "$_id.time"
						},
						taux: {
							$avg: "$_id.tauxBonnesReponses"
						},
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idActivite",
						time: { $ceil: "$duration" },
						taux: { $ceil: "$taux" },
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					if (item.idActivite && item.value) {
						if (!stats[item.idActivite]) {
							stats[item.idActivite] = [];
						}
						stats[item.idActivite].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async showAverageTimePerGabaritMathia() {
		this.currentStatisticChoosen = FilteredStatistic.averageTimePerGabaritMathia;

		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		this.currentDataTime = await this.getAverageTimePerGabaritMathia(this.rangeDate.start, this.rangeDate.end);

		//build data

		const dataTimePerGabarit = [];
		const nbEx = [];
		const taux = [];
		for (const idx in this.currentDataTime) {
			dataTimePerGabarit.push(this.currentDataTime[idx][0].time);
			nbEx.push(this.currentDataTime[idx][0].value);
			taux.push(this.currentDataTime[idx][0].taux);
		}

		this.barChartOptions.indexAxis = "y";
		this.barChartLabels = this.extractGabaritsLabel(this.currentDataTime);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataTimePerGabarit,
				label: "Durée moyenne passée sur les exercices par gabarit (sec)",
				backgroundColor: "#D3BCE6",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			}
		];
		console.log("barchartlabel", this.barChartLabels);
		console.log("barchartdata", this.barChartData);
		console.log("currentDataTime", this.currentDataTime);
		console.log("this.accountService.team[0].id", this.accountService.team[0].id);

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = nbEx as any;
							return [
								"Durée moyenne passée sur les exercices : " + dataTimePerGabarit[context.dataIndex] + " secondes",
								"Nombre d" + "'" + "exercices réalisés : " + nbEx[context.dataIndex],
								"Taux de réussite moyen : " + taux[context.dataIndex] + "%"
							];
						}
					}
				}
			},
			indexAxis: "y"
		};
	}

	////GRAPHE DUREE MOYENNE PAR EXERCICE KIDAIA////

	async getAverageTimePerGabaritKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];
			console.log("match", match);
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					timeDuration: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					userId: "$statement.actor.account.name",
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							time: "$timeDuration",
							idActivite: "$idActivite",
							tauxBonnesReponses: "$pourcentage",
							mydate: "$mydate"
						}
					}
				},
				{
					$group: {
						_id: { idActivite: "$_id.idActivite" },
						duration: {
							$avg: "$_id.time"
						},
						taux: {
							$avg: "$_id.tauxBonnesReponses"
						},
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idActivite",
						time: { $ceil: "$duration" },
						taux: { $ceil: "$taux" },
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			console.log("request", request);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					if (item.idActivite && item.value) {
						if (!stats[item.idActivite]) {
							stats[item.idActivite] = [];
						}
						stats[item.idActivite].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async showAverageTimePerGabaritKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.averageTimePerGabaritKidaia;

		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		this.currentDataTime = await this.getAverageTimePerGabaritKidaia(this.rangeDate.start, this.rangeDate.end);

		//build data

		const dataTimePerGabarit = [];
		const nbEx = [];
		const taux = [];
		for (const idx in this.currentDataTime) {
			dataTimePerGabarit.push(this.currentDataTime[idx][0].time);
			nbEx.push(this.currentDataTime[idx][0].value);
			taux.push(this.currentDataTime[idx][0].taux);
		}

		this.barChartOptions.indexAxis = "y";
		this.barChartLabels = this.extractGabaritsLabel(this.currentDataTime);
		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataTimePerGabarit,
				label: "Durée moyenne passée sur les exercices par gabarit (sec)",
				backgroundColor: "#D3BCE6",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			}
		];

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = nbEx as any;
							return [
								"Durée moyenne passée sur les exercices : " + dataTimePerGabarit[context.dataIndex] + " secondes",
								"Nombre d" + "'" + "exercices réalisés : " + nbEx[context.dataIndex],
								"Taux de réussite moyen : " + taux[context.dataIndex] + "%"
							];
						}
					}
				}
			},
			indexAxis: "y"
		};
		console.log("barchartlabel", this.barChartLabels);
		console.log("barchartdata", this.barChartData);
		console.log("currentDataTime", this.currentDataTime);
		console.log("this.accountService.team[0].id", this.accountService.team[0].id);
	}

	////NOMBRE D'EX ACHEVEES ET COMMENCEES PAR GABARIT EN MODE AVENTURE MATHIA SUPERVISION///

	async getActCompletedGabaritsAventureMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getActInitGabaritsAventureMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showActPerGabaritAventureMathia() {
		this.currentStatisticChoosen = FilteredStatistic.actPerGabaritAventureMathia;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getActCompletedGabaritsAventureMathia(this.rangeDate.start, this.rangeDate.end);

		this.chartExercicePerGabaritInit = await this.getActInitGabaritsAventureMathia(this.rangeDate.start, this.rangeDate.end);

		const idAct = ["1", "3", "4", "5", "6", "7", "8", "10", "11"];
		for (const id in idAct) {
			if (!this.chartExercicePerGabarit[idAct[id]]) {
				this.chartExercicePerGabarit[idAct[id]] = [];
				this.chartExercicePerGabarit[idAct[id]].push({ value: 0, idActivite: idAct[id] });
			}
			if (!this.chartExercicePerGabaritInit[idAct[id]]) {
				this.chartExercicePerGabaritInit[idAct[id]] = [];
				this.chartExercicePerGabaritInit[idAct[id]].push({ value: 0, idActivite: idAct[id] });
			}
		}

		console.log("dt", this.chartExercicePerGabarit);

		const dataAventureCompleted = [];
		for (const idAct in this.chartExercicePerGabarit) {
			dataAventureCompleted.push(this.chartExercicePerGabarit[idAct][0].value);
		}

		const dataAventureInit = [];
		for (const idAct in this.chartExercicePerGabaritInit) {
			dataAventureInit.push(this.chartExercicePerGabaritInit[idAct][0].value);
		}

		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataAventureInit,
				label: "Exercices commencés par gabarit en mode aventure",
				backgroundColor: "#FABD55",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			},

			{
				data: dataAventureCompleted,
				label: "Exercices achevés par gabarit en mode aventure",
				backgroundColor: "#FADCAA",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			}
		];
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	////NOMBRE D'EX ACHEVEES ET COMMENCÉES PAR GABARIT EN MODE ENTRAINEMENT MATHIA SUPERVISION///

	async getActGabaritsEntrainementMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getActGabaritsEntrainementInitMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showActPerGabaritEntrainementMathia() {
		this.currentStatisticChoosen = FilteredStatistic.actPerGabaritEntrainementMathia;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getActGabaritsEntrainementMathia(this.rangeDate.start, this.rangeDate.end);

		this.chartExercicePerGabaritInit = await this.getActGabaritsEntrainementInitMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.chartExercicePerGabarit);

		const dataAventureCompleted = [];
		for (const idAct in this.chartExercicePerGabarit) {
			dataAventureCompleted.push(this.chartExercicePerGabarit[idAct][0].value);
		}

		const dataAventureInit = [];
		for (const idAct in this.chartExercicePerGabaritInit) {
			dataAventureInit.push(this.chartExercicePerGabaritInit[idAct][0].value);
		}

		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataAventureInit,
				label: "Exercices commencés par gabarit en mode entraînement",
				backgroundColor: "#FABD55",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			},

			{
				data: dataAventureCompleted,
				label: "Exercices achevés par gabarit en mode entraînement",
				backgroundColor: "#FADCAA",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	////NOMBRE D'EX ACHEVEES PAR GABARIT EN MODE AVENTURE KIDAIA SUPERVISION///

	async getActCompletedGabaritsAventureKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getActInitGabaritsAventureKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showActPerGabaritAventureKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.actPerGabaritAventureKidaia;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getActCompletedGabaritsAventureKidaia(this.rangeDate.start, this.rangeDate.end);

		this.chartExercicePerGabaritInit = await this.getActInitGabaritsAventureKidaia(this.rangeDate.start, this.rangeDate.end);

		const idAct = ["1", "3", "4", "5", "6", "7", "8", "10", "11"];
		for (const id in idAct) {
			if (!this.chartExercicePerGabarit[idAct[id]]) {
				this.chartExercicePerGabarit[idAct[id]] = [];
				this.chartExercicePerGabarit[idAct[id]].push({ value: 0, idActivite: idAct[id] });
			}
			if (!this.chartExercicePerGabaritInit[idAct[id]]) {
				this.chartExercicePerGabaritInit[idAct[id]] = [];
				this.chartExercicePerGabaritInit[idAct[id]].push({ value: 0, idActivite: idAct[id] });
			}
		}

		console.log("dt", this.chartExercicePerGabarit);
		console.log("dt2", this.chartExercicePerGabaritInit);

		const dataAventureCompleted = [];
		for (const idAct in this.chartExercicePerGabarit) {
			dataAventureCompleted.push(this.chartExercicePerGabarit[idAct][0].value);
		}

		const dataAventureInit = [];
		for (const idAct in this.chartExercicePerGabaritInit) {
			dataAventureInit.push(this.chartExercicePerGabaritInit[idAct][0].value);
		}

		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataAventureInit,
				label: "Exercices commencés par gabarit en mode aventure",
				backgroundColor: "#FABD55",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			},

			{
				data: dataAventureCompleted,
				label: "Exercices achevés par gabarit en mode aventure",
				backgroundColor: "#FADCAA",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	////NOMBRE D'EX ACHEVEES PAR GABARIT EN MODE ENTRAINEMENT MATHIA SUPERVISION///

	async getActGabaritsEntrainementKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getActGabaritsEntrainementInitKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/story": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showActPerGabaritEntrainementKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.actPerGabaritEntrainementKidaia;

		this.barChartOptions.plugins = null;
		this.barChartData = null;
		this.barChartOptions.indexAxis = null;

		this.chartExercicePerGabarit = await this.getActGabaritsEntrainementKidaia(this.rangeDate.start, this.rangeDate.end);

		this.chartExercicePerGabaritInit = await this.getActGabaritsEntrainementInitKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("dt", this.chartExercicePerGabarit);

		const dataAventureCompleted = [];
		for (const idAct in this.chartExercicePerGabarit) {
			dataAventureCompleted.push(this.chartExercicePerGabarit[idAct][0].value);
		}

		const dataAventureInit = [];
		for (const idAct in this.chartExercicePerGabaritInit) {
			dataAventureInit.push(this.chartExercicePerGabaritInit[idAct][0].value);
		}

		this.barChartLabels = this.extractGabaritsLabel(this.chartExercicePerGabarit);

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: dataAventureInit,
				label: "Exercices commencés par gabarit en mode entraînement",
				backgroundColor: "#FABD55",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			},

			{
				data: dataAventureCompleted,
				label: "Exercices achevés par gabarit en mode entraînement",
				backgroundColor: "#FADCAA",
				fill: true,
				borderWidth: 2,
				borderColor: "white"
			}
		];

		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
	}

	async getQuestionsFinishedMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$nin: ["https://xapi.mathia.education/verbs/finished"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/question"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.verb.display.fr-FR",
					finished: "$statement.verb.display.fr-FR",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { finished: "$finished" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						finished: "$_id.finished",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.finished) {
						return;
					}
					if (!stats[item.finished]) {
						stats[item.finished] = [];
					}
					stats[item.finished].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showFinishedQuestionsMathia() {
		this.currentStatisticChoosen = FilteredStatistic.finishedQuestionsMathia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "square";

		this.chartFinishedQuestions = await this.getQuestionsFinishedMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.chartFinishedQuestions);

		const labels = [];
		for (const type in this.chartFinishedQuestions) {
			labels.push(type);
		}

		console.log("labels", labels);

		const data = [];
		for (const label in labels) {
			data.push(this.chartFinishedQuestions[labels[label]][0].value);
		}
		console.log("dt", data);

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = labels;
		this.barChartType = "doughnut";
		this.barChartData = [
			{
				data: [data[0], data[1], data[2], data[3], data[4]],
				backgroundColor: ["#BADBB8", "#C0E3FF", "#E4931E", "#DCB1F5", "#F5EAC1"]
			}
		];
	}

	async getQuestionsFinishedKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$nin: ["https://xapi.mathia.education/verbs/finished"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/question"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.verb.display.fr-FR",
					finished: "$statement.verb.display.fr-FR",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { finished: "$finished" },
						value: {
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						finished: "$_id.finished",
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.finished) {
						return;
					}
					if (!stats[item.finished]) {
						stats[item.finished] = [];
					}
					stats[item.finished].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showFinishedQuestionsKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.finishedQuestionsKidaia;
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		this.canvasShape = "square";

		this.chartFinishedQuestions = await this.getQuestionsFinishedKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.chartFinishedQuestions);

		const labels = [];
		for (const type in this.chartFinishedQuestions) {
			labels.push(type);
		}

		console.log("labels", labels);

		const data = [];
		for (const label in labels) {
			data.push(this.chartFinishedQuestions[labels[label]][0].value);
		}

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = labels;
		this.barChartType = "doughnut";
		this.barChartData = [
			{
				data: [data[0], data[1], data[2], data[3], data[4]],
				backgroundColor: ["#BADBB8", "#C0E3FF", "#E4931E", "#DCB1F5", "#F5EAC1"]
			}
		];
	}

	/// NOMBRE D'EXERCICES TERMINES EN FONCTION DU TAUX DE BONNES REPONSES MATHIA ///

	async getExercisePerGoodAnswerMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				// {
				// 	"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
				// 		$nin: [null]
				// 	}
				// },
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					time: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							date: "$mydate",
							time: "$time",
							tauxBonnesReponses: "$pourcentage",
							idAct: "$idActivite"
						}
					}
				},
				{
					$group: {
						_id: {
							date: "$_id.date",
							taux: "$_id.tauxBonnesReponses",
							userId: "$_id.userId",
							time: "$_id.time",
							idAct: "$_id.idAct"
						}
					}
				},
				{
					$project: {
						_id: 0,
						time: "$_id.time",
						userId: "$_id.userId",
						date: "$_id.date",
						value: "$_id.taux",
						idAct: "$_id.idAct"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);

			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value) {
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async showExercisePerGoodAnswerMathia() {
		this.currentStatisticChoosen = FilteredStatistic.completedPerGoodAnswerMathia;

		this.currentData = await this.getExercisePerGoodAnswerMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.currentData);

		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;
		this.barChartOptions.scales = null;

		// build data
		const results = {
			"90-100%": 0,
			"75-90%": 0,
			"50-75%": 0,
			"25-50%": 0,
			"0-25%": 0
		};

		const time = {
			"90-100%": [],
			"75-90%": [],
			"50-75%": [],
			"25-50%": [],
			"0-25%": []
		};

		const act = {
			"90-100%": [],
			"75-90%": [],
			"50-75%": [],
			"25-50%": [],
			"0-25%": []
		};

		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (0 < stat.value && stat.value <= 25) {
						results["0-25%"]++;
						time["0-25%"].push(stat.time);
						act["0-25%"].push(stat.idAct);
					} else if (25 < stat.value && stat.value <= 50) {
						results["25-50%"]++;
						time["25-50%"].push(stat.time);
						act["25-50%"].push(stat.idAct);
					} else if (50 < stat.value && stat.value <= 75) {
						results["50-75%"]++;
						time["50-75%"].push(stat.time);
						act["50-75%"].push(stat.idAct);
					} else if (75 < stat.value && stat.value <= 90) {
						results["75-90%"]++;
						time["75-90%"].push(stat.time);
						act["75-90%"].push(stat.idAct);
					} else if (90 < stat.value && stat.value <= 100) {
						results["90-100%"]++;
						time["90-100%"].push(stat.time);
						act["90-100%"].push(stat.idAct);
					}
				});
			}
		}

		console.log("res", results);

		const dataAverage = [];
		for (const taux in time) {
			var sum = 0;
			time[taux].forEach(stat => {
				sum += stat;
			});
			if (!dataAverage[taux]) {
				dataAverage[taux] = [];
			}
			dataAverage[taux].push(Math.round(sum / time[taux].length));
		}

		for (const taux in act) {
			act[taux] = [...new Set(act[taux])];
		}

		const labelAct = [];
		for (const taux in act) {
			if (!labelAct[taux]) {
				labelAct[taux] = [];
			}
			act[taux].forEach(id => {
				const name = this.cabriService.activities.find(a => a.id === id).name;
				labelAct[taux].push(name);
			});
		}

		console.log("act", labelAct);

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["0-25%", "25-50%", "50-75%", "75-90%", "90-100%"];
		this.barChartType = "pie";
		this.barChartData = [
			{
				data: [results["0-25%"], results["25-50%"], results["50-75%"], results["75-90%"], results["90-100%"]],
				backgroundColor: ["#EB918F", "#F7BF9C", "#F7F7BE", "#C8F2AC", "#51C451"]
			}
		];

		const labels = this.barChartLabels;

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							let tab = [
								"Taux de réussite : " + labels[context.dataIndex],
								"Nombre d" + "'" + "exercices réalisés : " + results[labels[context.dataIndex]],
								"Durée moyenne passée sur les exercices : " +
									Math.round(dataAverage[labels[context.dataIndex]] / 60) +
									" min",
								"Activité(s) concernée(s) : "
							];
							for (const idx in labelAct[labels[context.dataIndex]]) {
								tab = tab.concat("• " + labelAct[labels[context.dataIndex]][idx]);
							}
							return tab;
						}
					}
				}
			}
		};
	}

	/// NOMBRE D'EXERCICES TERMINES EN FONCTION DU TAUX DE BONNES REPONSES KIDAIA ///

	async getExercisePerGoodAnswerKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];

			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				// {
				// 	"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
				// 		$nin: [null]
				// 	}
				// },
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];
			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					pourcentage: "$statement.context.extensions.https://xapi&46;mathia&46;education/pourcentage_bonnes_reponses",
					userId: "$statement.actor.account.name",
					session: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					time: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					mydate: {
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);

			request.push(
				{
					$group: {
						_id: {
							userId: "$userId",
							date: "$mydate",
							time: "$time",
							tauxBonnesReponses: "$pourcentage",
							idAct: "$idActivite"
						}
					}
				},
				{
					$group: {
						_id: {
							date: "$_id.date",
							taux: "$_id.tauxBonnesReponses",
							userId: "$_id.userId",
							time: "$_id.time",
							idAct: "$_id.idAct"
						}
					}
				},
				{
					$project: {
						_id: 0,
						time: "$_id.time",
						userId: "$_id.userId",
						date: "$_id.date",
						value: "$_id.taux",
						idAct: "$_id.idAct"
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);

			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				console.log("results", results);
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (item.date && item.value) {
						if (!stats[item.date]) {
							stats[item.date] = [];
						}
						stats[item.date].push(item);
					}
				});

				resolve(stats);
				console.log("stats", stats);
			});
		});
	}

	async showExercisePerGoodAnswerKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.completedPerGoodAnswerKidaia;

		this.currentData = await this.getExercisePerGoodAnswerKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("data", this.currentData);

		this.barChartData = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.plugins = null;

		// build data
		const results = {
			"90-100%": 0,
			"75-90%": 0,
			"50-75%": 0,
			"25-50%": 0,
			"0-25%": 0
		};

		const time = {
			"90-100%": [],
			"75-90%": [],
			"50-75%": [],
			"25-50%": [],
			"0-25%": []
		};

		const act = {
			"90-100%": [],
			"75-90%": [],
			"50-75%": [],
			"25-50%": [],
			"0-25%": []
		};

		for (const key in this.currentData) {
			if (key) {
				const tabDate = this.currentData[key];
				tabDate.forEach(stat => {
					if (0 < stat.value && stat.value <= 25) {
						results["0-25%"]++;
						time["0-25%"].push(stat.time);
						act["0-25%"].push(stat.idAct);
					} else if (25 < stat.value && stat.value <= 50) {
						results["25-50%"]++;
						time["25-50%"].push(stat.time);
						act["25-50%"].push(stat.idAct);
					} else if (50 < stat.value && stat.value <= 75) {
						results["50-75%"]++;
						time["50-75%"].push(stat.time);
						act["50-75%"].push(stat.idAct);
					} else if (75 < stat.value && stat.value <= 90) {
						results["75-90%"]++;
						time["75-90%"].push(stat.time);
						act["75-90%"].push(stat.idAct);
					} else if (90 < stat.value && stat.value <= 100) {
						results["90-100%"]++;
						time["90-100%"].push(stat.time);
						act["90-100%"].push(stat.idAct);
					}
				});
			}
		}

		console.log("res", results);
		console.log("time", time);

		const dataAverage = [];
		for (const taux in time) {
			var sum = 0;
			time[taux].forEach(stat => {
				sum += stat;
			});
			if (!dataAverage[taux]) {
				dataAverage[taux] = [];
			}
			dataAverage[taux].push(Math.round(sum / time[taux].length));
		}

		console.log("timeAverage", dataAverage);

		for (const taux in act) {
			act[taux] = [...new Set(act[taux])];
		}

		const labelAct = [];
		for (const taux in act) {
			if (!labelAct[taux]) {
				labelAct[taux] = [];
			}
			act[taux].forEach(id => {
				const name = this.cabriService.activities.find(a => a.id === id).name;
				labelAct[taux].push(name);
			});
		}

		// define axis options
		this.barChartOptions.scales = {};
		this.barChartLabels = ["0-25%", "25-50%", "50-75%", "75-90%", "90-100%"];
		this.barChartType = "pie";
		this.barChartData = [
			{
				data: [results["0-25%"], results["25-50%"], results["50-75%"], results["75-90%"], results["90-100%"]],
				backgroundColor: ["#EB918F", "#F7BF9C", "#F7F7BE", "#C8F2AC", "#51C451"]
			}
		];

		const labels = this.barChartLabels;

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							let tab = [
								"Taux de réussite : " + labels[context.dataIndex],
								"Nombre d" + "'" + "exercices réalisés : " + results[labels[context.dataIndex]],
								"Durée moyenne passée sur les exercices : " +
									Math.round(dataAverage[labels[context.dataIndex]] / 60) +
									" min",
								"Activité(s) concernée(s) : "
							];
							for (const idx in labelAct[labels[context.dataIndex]]) {
								tab = tab.concat("• " + labelAct[labels[context.dataIndex]][idx]);
							}
							return tab;
						}
					}
				}
			}
		};
	}

	//////////////////////// EXERCICES TERMINES PAR NOM ///////////////////////////////////

	// async getNameExercisesCompleted($startDate = null, $endDate = null) {
	// 	return new Promise((resolve, reject) => {
	// 		const request = [];
	// 		const match: Array<any> = [
	// 			{
	// 				"statement.verb.id": {
	// 					$in: ["https://xapi.mathia.education/verbs/completed"]
	// 				}
	// 			},
	// 			{
	// 				"statement.object.definition.type": {
	// 					$in: ["https://xapi.mathia.education/objectType/exercise"]
	// 				}
	// 			},
	// 			{
	// 				"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
	// 					$nin: LrsUtils.codeClassBlacklist
	// 				}
	// 			},
	// 			{
	// 				"statement.actor.account.name": {
	// 					$nin: ["1", "2", "3", "4", "29", "34", "48", "90", "14582", "22226", "22076", "20902", "6473", "21968", "19612", "21545", "21894", "21915", "15831", "2172", "1413", "1412", "1904"]
	// 				}
	// 			}
	// 		];

	// 		if ($startDate || $endDate) {
	// 			if ($startDate) {
	// 				match.push({
	// 					timestamp: {
	// 						$gte: {
	// 							$dte: $startDate
	// 						}
	// 					}
	// 				});
	// 			}
	// 			if ($endDate) {
	// 				match.push({
	// 					timestamp: {
	// 						$lte: {
	// 							$dte: $endDate
	// 						}
	// 					}
	// 				});
	// 			}
	// 		} else {
	// 			match.push({
	// 				timestamp: {
	// 					$gte: {
	// 						$dte: "2021-01-01T08:09:00.000Z"
	// 					}
	// 				}
	// 			});
	// 		}
	// 		request.push({
	// 			$match: {
	// 				$and: match
	// 			}
	// 		});

	// 		const $project = {
	// 			$project: {
	// 				value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/nom_exercice",
	// 				mydate: {
	// 					// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
	// 					$dateToString: {
	// 						format: "%Y-%m-%d",
	// 						date: {
	// 							$toDate: "$statement.timestamp"
	// 						}
	// 					}
	// 				}
	// 			}
	// 		};
	// 		request.push($project);
	// 		request.push(
	// 			{
	// 				$group: {
	// 					_id: { name: "$value" },
	// 					value: {
	// 						//compte le nombre d'exercices terminées
	// 						$sum: 1
	// 					}
	// 				}
	// 			},
	// 			{
	// 				$project: {
	// 					_id: 0,
	// 					name: "$_id.name",
	// 					value: 1
	// 				}
	// 			},
	// 			{
	// 				$sort: {
	// 					date: 1
	// 				}
	// 			}
	// 		);
	// 		this.lrsService.request(request).then(async (results: any) => {
	// 			const stats = {};
	// 			//console.log(results)
	// 			results.forEach(item => {
	// 				if (!item.name) {
	// 					return;
	// 				}
	// 				if (!stats[item.name]) {
	// 					stats[item.name] = [];
	// 				}
	// 				stats[item.name].push(item);
	// 			});
	// 			resolve(stats);
	// 			console.log("statsex", stats);
	// 		});
	// 	});
	// }

	// async showNameExercisesCompleted() {
	// 	this.currentData = await this.getNameExercisesCompleted(
	// 		this.rangeDate.start,
	// 		this.rangeDate.end
	// 	);

	// 	console.log("data", this.currentData);
	// }

	/// BUBBLE CHART POUR COMPARER NB ACTIVITES ACHEVEES PAR GABARIT ET DUREE MOYENNE MATHIA ///

	async getExCompletedTimeGabaritsMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					timeDuration: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							//compte le nombre d'exercices terminés
							$sum: 1
						},
						time: {
							$avg: "$timeDuration"
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						time: 1,
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showScatterChartCompletedTimeMathia() {
		this.currentStatisticChoosen = FilteredStatistic.scatterChartMathia;

		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;

		this.currentData = await this.getExCompletedTimeGabaritsMathia(this.rangeDate.start, this.rangeDate.end);

		console.log("currentData", this.currentData);

		const dataScatter = [];
		for (const idAct in this.currentData) {
			const name = this.cabriService.activities.find(a => a.id === idAct).name;
			for (const idx in this.currentData[idAct]) {
				dataScatter.push({ x: Math.round(this.currentData[idAct][idx].time), y: this.currentData[idAct][idx].value });
			}
		}

		console.log("dtBubble", dataScatter);

		const labels = this.extractGabaritsLabel(this.currentData);

		this.barChartType = "scatter";
		this.barChartData = [
			{
				data: dataScatter,
				label: "Exercices achevés en fonction du temps de travail",
				backgroundColor: "#AA57FA",

				borderColor: "#D8BAF5",
				radius: 5
			}
		];

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Nombre d" + "'" + "exercices achevés : " + v.y,
								"Temps de travail moyen : " + v.x + " secondes",
								"Activitée concernée : " + labels[context.dataIndex]
							];
						}
					}
				}
			}
		};
	}

	async getExCompletedTimeGabaritsKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session": {
						$nin: [null]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					idActivite: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_activite",
					timeDuration: "$metadata.https://learninglocker&46;net/result-duration.seconds",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { idAct: "$idActivite" },
						value: {
							//compte le nombre d'exercices terminés
							$sum: 1
						},
						time: {
							$avg: "$timeDuration"
						}
					}
				},
				{
					$project: {
						_id: 0,
						idActivite: "$_id.idAct",
						time: 1,
						value: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.idActivite) {
						return;
					}
					if (!stats[item.idActivite]) {
						stats[item.idActivite] = [];
					}
					stats[item.idActivite].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async showScatterChartCompletedTimeKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.scatterChartKidaia;

		this.barChartData = null;
		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;

		this.currentData = await this.getExCompletedTimeGabaritsKidaia(this.rangeDate.start, this.rangeDate.end);

		console.log("currentData", this.currentData);

		const dataScatter = [];
		for (const idAct in this.currentData) {
			const name = this.cabriService.activities.find(a => a.id === idAct).name;
			for (const idx in this.currentData[idAct]) {
				dataScatter.push({ x: Math.round(this.currentData[idAct][idx].time), y: this.currentData[idAct][idx].value });
			}
		}

		console.log("dtBubble", dataScatter);

		const labels = this.extractGabaritsLabel(this.currentData);

		this.barChartType = "scatter";
		this.barChartData = [
			{
				data: dataScatter,
				label: "Exercices achevés en fonction du temps de travail",
				backgroundColor: "#AA57FA",

				borderColor: "#D8BAF5",
				radius: 5
			}
		];

		this.barChartOptions = {
			plugins: {
				tooltip: {
					callbacks: {
						label(context) {
							const v = context.dataset.data[context.dataIndex] as any;
							return [
								"Nombre d" + "'" + "exercices achevés : " + v.y,
								"Temps de travail moyen : " + v.x + " secondes",
								"Activitée concernée : " + labels[context.dataIndex]
							];
						}
					}
				}
			}
		};
	}

	///GRAPHE EXERCICES COMMENCES ET TERMINES MATHIA///

	async getExercisesCompletedMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getExercisesInitializedMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showExercicesInitializedCompletedMathia() {
		this.currentStatisticChoosen = FilteredStatistic.exCommencesTerminesMathia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		console.log("this.rangeDate.start", this.rangeDate.start);
		this.canvasShape = "rectangle";
		// build data
		this.chartExerciceInitialized = await this.getExercisesInitializedMathia(this.rangeDate.start, this.rangeDate.end);
		this.chartExerciceCompleted = await this.getExercisesCompletedMathia(this.rangeDate.start, this.rangeDate.end);

		// define axis options
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
		this.barChartLabels = this.extractLabel(this.chartExerciceInitialized);

		//Compléter les exs terminés

		for (const label in this.chartExerciceInitialized) {
			if (!this.chartExerciceCompleted[label]) {
				this.chartExerciceCompleted[label] = [];
				this.chartExerciceCompleted[label].push({ value: 0, date: label });
			}
		}

		//Récupérer données dans le même ordre que chartExInit

		const newChartExCompleted = [];
		for (const label in this.barChartLabels) {
			newChartExCompleted.push(this.chartExerciceCompleted[this.barChartLabels[label]][0].value);
		}

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartExerciceInitialized),
				label: "Exercices commencés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			},
			{
				data: newChartExCompleted,
				label: "Exercices terminés par jour",
				backgroundColor: "#F22E4F",
				fill: true,
				borderColor: "#F22E4F"
			}
		];
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	///GRAPHE EXERCICES COMMENCES ET TERMINES KIDAIA///

	async getExercisesCompletedKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getExercisesInitializedKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/exercise"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [true]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showExercicesInitializedCompletedKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.exCommencesTerminesKidaia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		console.log("this.rangeDate.start", this.rangeDate.start);
		this.canvasShape = "rectangle";
		// build data
		this.chartExerciceInitialized = await this.getExercisesInitializedKidaia(this.rangeDate.start, this.rangeDate.end);
		this.chartExerciceCompleted = await this.getExercisesCompletedKidaia(this.rangeDate.start, this.rangeDate.end);

		// define axis options
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
		this.barChartLabels = this.extractLabel(this.chartExerciceInitialized);

		//Compléter les exs terminés

		for (const label in this.chartExerciceInitialized) {
			if (!this.chartExerciceCompleted[label]) {
				this.chartExerciceCompleted[label] = [];
				this.chartExerciceCompleted[label].push({ value: 0, date: label });
			}
		}

		//Récupérer données dans le même ordre que chartExInit

		const newChartExCompleted = [];
		for (const label in this.barChartLabels) {
			newChartExCompleted.push(this.chartExerciceCompleted[this.barChartLabels[label]][0].value);
		}

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartExerciceInitialized),
				label: "Exercices commencés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			},
			{
				data: newChartExCompleted,
				label: "Exercices terminés par jour",
				backgroundColor: "#F22E4F",
				fill: true,
				borderColor: "#F22E4F"
			}
		];
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	///GRAPHE PARCOURS COMMENCES ET TERMINES MATHIA///

	async getJourneyCompletedMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/journey"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getJourneyInitializedMathia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/journey"]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
						$nin: LrsUtils.codeClassBlacklist
					}
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
						$in: [false]
					}
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showJourneyInitializedCompletedMathia() {
		this.currentStatisticChoosen = FilteredStatistic.parcoursCommencesTerminesMathia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		console.log("this.rangeDate.start", this.rangeDate.start);
		this.canvasShape = "rectangle";
		// build data
		this.chartExerciceInitialized = await this.getJourneyInitializedMathia(this.rangeDate.start, this.rangeDate.end);
		this.chartExerciceCompleted = await this.getJourneyCompletedMathia(this.rangeDate.start, this.rangeDate.end);

		// define axis options
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
		this.barChartLabels = this.extractLabel(this.chartExerciceInitialized);

		//Compléter les exs terminés

		for (const label in this.chartExerciceInitialized) {
			if (!this.chartExerciceCompleted[label]) {
				this.chartExerciceCompleted[label] = [];
				this.chartExerciceCompleted[label].push({ value: 0, date: label });
			}
		}

		//Récupérer données dans le même ordre que chartExInit

		const newChartExCompleted = [];
		for (const label in this.barChartLabels) {
			newChartExCompleted.push(this.chartExerciceCompleted[this.barChartLabels[label]][0].value);
		}

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartExerciceInitialized),
				label: "Parcours commencés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			},
			{
				data: newChartExCompleted,
				label: "Parcours terminés par jour",
				backgroundColor: "#F22E4F",
				fill: true,
				borderColor: "#F22E4F"
			}
		];
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}

	///GRAPHE PARCOURS COMMENCES ET TERMINES KIDAIA///

	async getJourneyCompletedKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/completed","https://xapi.ose/verbs/completed"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/journey","https://xapi.ose/objectType/journey"]
					}
				},
				{
					$or: [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
								$nin: LrsUtils.codeClassBlacklist
							}
						},
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/codeclasse": {
							 	$nin: LrsUtils.codeClassBlacklist
							}
						}
					]
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					$or : [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
								$in: [true]
							}
						}, 
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/ose": {
								$in: [true]
							}
						}
					]
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices terminées
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					//compare s'il y a une valeur (une moyenne) à une date
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
				console.log("statsex", stats);
			});
		});
	}

	async getJourneyInitializedKidaia($startDate = null, $endDate = null) {
		return new Promise((resolve, reject) => {
			const request = [];
			const match: Array<any> = [
				{
					"statement.verb.id": {
						$in: ["https://xapi.mathia.education/verbs/initialized", "https://xapi.ose/verbs/initialized"]
					}
				},
				{
					"statement.object.definition.type": {
						$in: ["https://xapi.mathia.education/objectType/journey", "https://xapi.ose/objectType/journey"]
					}
				},
				{
					$or: [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/codeclasse": {
								$nin: LrsUtils.codeClassBlacklist
							}
						},
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/codeclasse": {
							 	$nin: LrsUtils.codeClassBlacklist
							}
						}
					]
				},
				{
					"statement.actor.account.name": {
						$nin: [
							"1",
							"2",
							"3",
							"4",
							"29",
							"34",
							"48",
							"90",
							"14582",
							"22226",
							"22076",
							"20902",
							"6473",
							"21968",
							"19612",
							"21545",
							"21894",
							"21915",
							"15831",
							"2172",
							"1413",
							"1412",
							"1904"
						]
					}
				},
				{
					$or : [
						{
							"statement.context.extensions.https://xapi&46;mathia&46;education/extensions/kidaia": {
								$in: [true]
							}
						}, 
						{
							"statement.context.extensions.https://xapi&46;ose/extensions/ose": {
								$in: [true]
							}
						}
					]
				}
			];

			if ($startDate || $endDate) {
				if ($startDate) {
					match.push({
						timestamp: {
							$gte: {
								$dte: $startDate
							}
						}
					});
				}
				if ($endDate) {
					match.push({
						timestamp: {
							$lte: {
								$dte: $endDate
							}
						}
					});
				}
			} else {
				match.push({
					timestamp: {
						$gte: {
							$dte: "2021-01-01T08:09:00.000Z"
						}
					}
				});
			}
			request.push({
				$match: {
					$and: match
				}
			});

			const $project = {
				$project: {
					value: "$statement.context.extensions.https://xapi&46;mathia&46;education/extensions/id_session",
					mydate: {
						// ça prend toutes les dates de tous les utilisateurs qui ont finit un exercice
						$dateToString: {
							format: "%Y-%m-%d",
							date: {
								$toDate: "$statement.timestamp"
							}
						}
					}
				}
			};
			request.push($project);
			request.push(
				{
					$group: {
						_id: { date: "$mydate" },
						value: {
							//compte le nombre d'exercices commencés
							$sum: 1
						}
					}
				},
				{
					$project: {
						_id: 0,
						date: "$_id.date",
						value: 1
					}
				},
				{
					$sort: {
						date: 1
					}
				}
			);
			this.lrsService.request(request).then(async (results: any) => {
				const stats = {};
				//console.log(results)
				results.forEach(item => {
					if (!item.date) {
						return;
					}
					if (!stats[item.date]) {
						stats[item.date] = [];
					}
					stats[item.date].push(item);
				});
				resolve(stats);
			});
		});
	}

	async showJourneyInitializedCompletedKidaia() {
		this.currentStatisticChoosen = FilteredStatistic.parcoursCommencesTerminesKidaia;

		this.barChartOptions.plugins = null;
		this.barChartOptions.indexAxis = null;
		this.barChartOptions.scales = null;
		this.barChartData = null;

		let date;
		let startDate;

		if (!this.rangeDate.start) {
			date = new Date(this.filterDataFrom);
			startDate = LrsUtils.convertDateToISO8601(addMonths(date, -12));
			this.rangeDate.start = format(parseISO(this.filterDataFrom), "yyyy-MM-dd");
		}

		if (!this.rangeDate.end) {
			this.rangeDate.end = format(endOfDay(new Date()), "yyyy-MM-dd");
		}

		console.log("this.rangeDate.start", this.rangeDate.start);
		this.canvasShape = "rectangle";
		// build data
		this.chartExerciceInitialized = await this.getJourneyInitializedKidaia(this.rangeDate.start, this.rangeDate.end);
		this.chartExerciceCompleted = await this.getJourneyCompletedKidaia(this.rangeDate.start, this.rangeDate.end);

		// define axis options
		this.barChartOptions.scales = {
			x: {
				stacked: true
			},
			y: {
				stacked: true
			}
		};
		this.barChartLabels = this.extractLabel(this.chartExerciceInitialized);

		//Compléter les exs terminés

		for (const label in this.chartExerciceInitialized) {
			if (!this.chartExerciceCompleted[label]) {
				this.chartExerciceCompleted[label] = [];
				this.chartExerciceCompleted[label].push({ value: 0, date: label });
			}
		}

		//Récupérer données dans le même ordre que chartExInit

		const newChartExCompleted = [];
		for (const label in this.barChartLabels) {
			newChartExCompleted.push(this.chartExerciceCompleted[this.barChartLabels[label]][0].value);
		}

		this.barChartType = "bar";
		this.barChartData = [
			{
				data: this.extractData(this.chartExerciceInitialized),
				label: "Parcours commencés par jour",
				backgroundColor: "#E4931E",
				fill: true,
				borderColor: "#E4931E"
			},
			{
				data: newChartExCompleted,
				label: "Parcours terminés par jour",
				backgroundColor: "#F22E4F",
				fill: true,
				borderColor: "#F22E4F"
			}
		];
		console.log("this.chartExerciceCompleted", this.chartExerciceCompleted);
		console.log("this.barChartLabels", this.barChartLabels);
		console.log("this.barChartData", this.barChartData);
	}
	public statisticAllowed = false;
	public allowStatistics(){
		this.statisticAllowed = this.accountService.allowSupervision();
		// this.statisticAllowed = true;
	}
}