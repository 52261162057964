<div class="containerWrapper">
  <div class="titleContainer">
    <h2 class="title" i18n>
      Attention ! Nous allons vous demander la permission de suivre votre activité dans Mathia. Si vous refusez, certaines fonctionnalités seront désactivées.
    </h2>
  </div>
 

  <div class="startButtonContainer">
				
    <div class="customButton gradient"
    (click)="dismiss()"> 
    <span class="buttonText">
      OK
    </span>

    </div>
  </div>
</div>

