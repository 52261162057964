import { Injectable, NgZone } from "@angular/core";
import { BaseService } from "./base.service";
import { Story, StoryByUserId } from "../models/story";
import { HttpClient } from "@angular/common/http";
import { Student } from "../models/student";
import { Chapter, exoState } from "../models/chapter";
import { ExerciceChapterHistory } from "../models/exercice-chapter-history";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { lastValueFrom } from "rxjs";
import { LocalStorageService, StorageKey } from "./local-storage-service";
import { AppUtils } from "../app-utils";

@Injectable({
	providedIn: "root"
})
export class StoryService extends BaseService {
	public environment: { production: boolean; activityVersion: number; storyVersion: number; kidaia: boolean };
	allStories: StoryByUserId | null;

	constructor(private http: HttpClient, public router: Router, private ngzone: NgZone, public localStorageService: LocalStorageService) {
		super();
		this.environment = environment;
	}

	/**
	 * Get all storys with chapters and exercices result for one user
	 * @param idUser trallalere id user
	 */
	getStorys(idUser: string): Promise<Story[]> {
		const formData = new FormData();
		formData.append("action", "getStorys");
		formData.append("id_user", idUser);
		if (!this.environment.production) {
			formData.append("dev", "true");
		}
		formData.append("version", this.environment.storyVersion.toString());
		let remapStorys: Story[] = [];
		const storysObs = this.http.post<Story[]>(this.postUrl, formData).pipe(
			map(storys => {
				storys.forEach((story, index) => {
					storys[index] = this.setStory(story);
					remapStorys.push(Object.assign(new Story(), storys[index]));
				});
				return remapStorys;
			})
		);
		return lastValueFrom(storysObs);
	}

	public getAllStorys(idUsers: Array<string>): Promise<StoryByUserId> {
		if (this.allStories) {
			return new Promise<StoryByUserId>(resolve => {
				resolve(this.allStories);
			});
		} else {
			const formData = new FormData();
			formData.append("action", "getAllStorys");
			formData.append("id_users", JSON.stringify(idUsers));
			formData.append("version", this.environment.storyVersion.toString());
			let errorOccured = false;
			const storysObs = this.http.post<StoryByUserId[]>(this.postUrl, formData).pipe(
				catchError(async () => {
					errorOccured = true;
					const allStorys = await this.localStorageService.get(StorageKey.getAllStorys);
					return allStorys;
				}),
				map(storys => {
					if(storys){
						this.allStories = Object.assign(new StoryByUserId(), storys);
						for (const userId in this.allStories) {
							this.allStories[userId].forEach((eachStory, index) => {
								this.allStories[userId][index] = Object.assign(new Story(), this.setStory(eachStory));
							});
						}
						if (!errorOccured) {
							this.localStorageService.set(StorageKey.getAllStorys, this.allStories);
						}
					}
					return this.allStories;
				})
			);

			return lastValueFrom(storysObs);
		}
	}

	private setStory(story: Story): Story {
		const remapchapters: Chapter[] = [];
		story.chapters.forEach(chapter => {
			const remapExercicesChapterHistory: ExerciceChapterHistory[] = [];
			chapter.exercicesChapterHistory.forEach(exercicesChapterHistory => {
				exercicesChapterHistory.position = Number(exercicesChapterHistory.position);
				exercicesChapterHistory.bilan =
					(exercicesChapterHistory.bilan as unknown) === "1" || exercicesChapterHistory.bilan === true;
				exercicesChapterHistory.results.forEach(result => {
					result.star = Number(result.star);
					result.shooting = Number(result.shooting);
					result.moon = Number(result.moon);
				});
				remapExercicesChapterHistory.push(Object.assign(new ExerciceChapterHistory(), exercicesChapterHistory));
			});
			chapter.exercicesChapterHistory = remapExercicesChapterHistory;
			chapter.minimumToWin = Number(chapter.minimumToWin);
			chapter.maxExercices = Number(chapter.maxExercices);
			chapter.unlocked = (chapter.unlocked as unknown) === "1" || chapter.unlocked === true;
			chapter.hasExercices = (chapter.hasExercices as unknown) === "1" || chapter.hasExercices === true;
			// chapter.systemName =
			remapchapters.push(Object.assign(new Chapter(), chapter));
		});
		story.chapters = remapchapters;
		story.order = Number(story.order);
		return story;
	}

	public saveStoriesByUser(studentId, userStories) {
		if(!environment.kidaia && !environment.ose){
			if (this.allStories[studentId] && userStories) {
				this.allStories[studentId] = userStories;
				this.localStorageService.set(StorageKey.getAllStorys, this.allStories);
			}
		}
	}

	private async saveExercice(exercice: ExerciceChapterHistory, student: Student): Promise<string> {
		const formData = new FormData();
		formData.append("action", "saveKidaiaExercice");
		formData.append("id_user", student.id);
		formData.append("id_chapter", exercice.chapterId);
		formData.append("id_exercice", exercice.idExercice);
		formData.append("bilan", exercice.bilan ? "1" : "0");
		formData.append("position", exercice.position.toString());
		formData.append("tempId", exercice.tempId);
		let saveExercicePromise;
		try {
			saveExercicePromise = await lastValueFrom(this.http.post<string>(this.postUrl, formData));
		} catch (err) {
			saveExercicePromise = new Promise((resolve, reject) => {
				resolve(true);
			});
			this.saveFormData(formData, StorageKey.saveKidaiaExercice);
		}

		return saveExercicePromise;
	}

	/**
	 * Lauch activity with the exercice in position
	 */
	launchExercice(chapter: Chapter, position: number, bilan = false, journeyId = ""): Promise<void> {
		return this.ngzone.run(() => {
			return new Promise<void>((resolve, reject) => {
				// console.log("Angular zone :"+ NgZone.isInAngularZone());
				if (position <= chapter.maxExercices && position > 0) {
					const exercice = chapter.exercicesChapterHistory.find(element => element.position === position);
					if (exercice) {
						this.router.navigateByUrl("/gabarits", {
							state: {
								launchExercice: { exo: exercice, chapter, position }
							}
						});
						resolve();
					} else {
						this.router.navigateByUrl("/gabarits", {
							state: {
								launchExercice: { chapter, position, bilan, journeyId }
							}
						});
						resolve();
					}
				} else {
					this.router.navigateByUrl("/gabarits", {
						state: {
							launchExercice: { chapter, position: chapter.exercicesChapterHistory.length + 1, bilan, journeyId }
						}
					});
					resolve();
				}
			});
		});
	}
	/**
	 * check chapter unlock 0 no, 1 yes, 2 already unlock
	 */
	checkUnlockNextChapter(chapter: Chapter, story: Story, student: Student): number {
		// all exercice have been done
		// check if all exercice have status validated (done with enough star won)
		if (chapter.maxExercices <= chapter.exercicesChapterHistory.length) {
			const nextChapter = story.getNextChapter(chapter);
			if (
				!nextChapter.unlocked &&
				chapter.exercicesChapterHistory.every(exo => {
					return exo.position > chapter.maxExercices || chapter.getExoStatus(exo.position) === exoState.validated;
				})
			) {
				nextChapter.unlocked = true;
				this.saveChapterUnlock(nextChapter, student);
				return 1;
			} else {
				if (nextChapter.unlocked) {
					return 2;
				} else {
					return 0;
				}
			}
		} else {
			return 0;
		}
	}

	/**
	 * choose next page after an activity is done
	 */
	goToNextAfterActivity(chapter: Chapter, position: number, haveUnlock: number = 0) {
		// all exercice have been done
		if (chapter.maxExercices === chapter.exercicesChapterHistory.length && haveUnlock !== 2) {
			// check if all exercice have status validated (done with enough star won)
			if (haveUnlock === 1) {
				// we can go on conclusion
				if (chapter.conclusionWin) {
					this.router.navigateByUrl("/narration", {
						state: {
							sceneToPlay: chapter.conclusionWin,
							position: 0
						}
					});
				} else {
					this.router.navigateByUrl("/map");
				}
			} else {
				if (chapter.conclusionFail) {
					this.router.navigateByUrl("/narration", {
						state: {
							sceneToPlay: chapter.conclusionFail,
							position
						}
					});
				} else {
					this.router.navigateByUrl("/map");
				}
			}
		} else {
			if (position === chapter.maxExercices) {
				if (chapter.conclusionWin) {
					this.router.navigateByUrl("/narration", {
						state: {
							sceneToPlay: chapter.conclusionWin,
							position: 0
						}
					});
				} else {
					this.router.navigateByUrl("/map");
				}
			} else if (chapter.interlude && position < chapter.maxExercices) {
				this.router.navigateByUrl("/map", {
					state: {
						sceneToPlay: chapter.interlude,
						position
					}
				});
			} else {
				this.router.navigateByUrl("/map");
			}
		}
	}

	/**
	 * Create a new exercice for a position save on db and return her id who is a prerequisite to add result
	 * @param position the free position of the exercice
	 * @param idExerciceChapter the id of the exercice choose by ai
	 */
	async createExercice(
		chapter: Chapter,
		position: number,
		student: Student,
		idExerciceChapter: string,
		bilan = false
	): Promise<ExerciceChapterHistory> {
		const exercice = new ExerciceChapterHistory();
		exercice.chapterId = chapter.id;
		exercice.position = position;
		exercice.results = new Array();
		exercice.idExercice = idExerciceChapter;
		exercice.bilan = bilan;
		exercice.tempId = AppUtils.createGuid();
		try {
			exercice.id = await this.saveExercice(exercice, student);
		} catch (err) {}
		chapter.exercicesChapterHistory.push(exercice);

		return exercice;
	}

	/**
	 * save and exercice result on db
	 */
	private async saveExerciceResult(exercice: ExerciceChapterHistory, shooting: number = 0, star: number = 0, moon: number = 0) {
		const formData = new FormData();
		formData.append("action", "saveKidaiaExerciceResult");
		formData.append("id_chapter_exercice", exercice.id);
		formData.append("shooting", shooting.toString());
		formData.append("star", star.toString());
		formData.append("moon", moon.toString());
		if (exercice.tempId) {
			formData.append("tempId", exercice.tempId);
		}
		let saveExercicePromise;
		try {
			saveExercicePromise = await lastValueFrom(this.http.post<string>(this.postUrl, formData));
		} catch (err) {
			saveExercicePromise = new Promise((resolve, reject) => {
				resolve(true);
			});
			this.saveFormData(formData, StorageKey.saveKidaiaExerciceResult);
		}
		return saveExercicePromise;
	}

	/**
	 * add exercice result and return promise when fullfill
	 */
	addExerciceResult(exercice: ExerciceChapterHistory, shooting: number = 0, star: number = 0, moon: number = 0): Promise<void> {
		return new Promise((resolve, reject) => {
			this.saveExerciceResult(exercice, shooting, star, moon).finally(() => {
				exercice.results.push({ shooting, star, moon });
				resolve();
			});
			// .catch(error => reject(error.message));
		});
	}

	/**
	 * Save the unlocked status for and user/chapter
	 * @return the chapter_user id
	 */
	async saveChapterUnlock(chapter: Chapter, student: Student): Promise<string> {
		const formData = new FormData();
		formData.append("action", "saveChapterUser");
		formData.append("id_user", student.id);
		formData.append("id_chapter", chapter.id.toString());
		formData.append("unlocked", chapter.unlocked ? "1" : "0");
		let saveChapter;
		try {
			saveChapter = await lastValueFrom(this.http.post<string>(this.postUrl, formData));
		} catch (err) {
			saveChapter = new Promise((resolve, reject) => {
				resolve(true);
			});
			this.saveFormData(formData, StorageKey.saveChapterUser);
		}

		return saveChapter;
	}

	public async saveFormData(formData, key: StorageKey) {
		let getData;
		try {
			getData = await this.localStorageService.get(key);
		} catch (err) {}
		if (!getData) {
			getData = [];
		}
		const formDataEntries = Array.from((formData as any).entries());
		let data = {};
		formDataEntries.forEach(entry => {
			data[entry[0]] = entry[1];
		});
		getData.push(data);
		this.localStorageService.set(key, getData);
	}
}
