<ng-container *ngIf="journeyByStatus">
  <ng-container *ngFor="let journey of journeyByStatus | keyvalue">
    <ng-container *ngIf="journey.value.items.length > 0">
      <div class="pageContainerAssignation">
        <div class="customLabelContainer"
          [ngClass]="{'assignationCustomLabelContainerMobile' : globalService.isMobile }"
          (click)="openJourneyCategory($event,journey)">

          <p class="customLabel">
            <ng-container *ngIf="journey.key == 1" i18n> Les parcours en cours </ng-container>
            <ng-container *ngIf="journey.key == 2" i18n> Mes parcours à faire </ng-container>
            <ng-container *ngIf="journey.key == 3" i18n> Les parcours terminés </ng-container>
            <ng-container *ngIf="journey.key == 4" i18n> Mes parcours </ng-container>
            <ng-container *ngIf="journey.key == 5" i18n> Tous les parcours </ng-container>
            ({{journey.value.items.length}})
          </p>
          <ion-icon class="dropdownDetails journeyCategoryDropdown"
            [ngClass]="journey.value.opened ? 'dropdownOpened' : 'dropdownClosed'" name="arrow-forward-circle-outline">
          </ion-icon>
        </div>

        <ng-container *ngIf="journey.value.opened">
          <ng-container *ngFor="let journeyValue of journey.value.items; let jIndex = index">
            <div class="elementListBorder elementListNormal"
              [ngClass]="{'elementListNormalMobile' : globalService.isMobile}">
              <div class="positionNormal elementList" (click)="updateValue($event, journeyValue)"
                [ngClass]="{'selectedItem' : journey.key == 1 && lmsService.userJourneyFromParams?.id == journeyValue.id || (!containAtLeastJourneyInProgress && lmsService.userJourneyFromParams?.id == journeyValue.id)}">
                <div [class]="'modalContentWrapper currentStudentJourney' + jIndex"
                  [ngClass]="{'modalContentWrapperTablet' : globalService.isTable,'modalContentWrapperDesktop' : globalService.isDesktop, 'modalContentWrapperMobilePortrait' : globalService.isMobile && !globalService.isLandscape}">
                  <div class="exoTitle">
                    <p class="ion-text-left"> {{lmsService.extractor[journeyValue.id]?.title}}</p>
                  </div>

                  <div class="exoStars">
                    <app-custom-simple-progressbar *ngIf="lmsService.journeyStatistics"
                      [percent]="lmsService.journeyStatistics[journeyValue.id]?.perCorrectAnswers">
                    </app-custom-simple-progressbar>
                  </div>

                  <div class="exoId">
                    <div class="rightModalPositionContainer" [ngClass]="[globalService.isDesktop ? 'rightModalPositionContainerDesktop' : 'rightModalPositionContainerTM',
                      globalService.isMobile && !globalService.isLandscape ? '' : '']">
                      <div class="idContainer">
                        <p>{{lmsService.extractor[journeyValue.id]?.id}}</p>
                      </div>
                      <div class="dropDownContainer">
                        <ion-icon class="dropdownDetails"
                          [ngClass]="journeyValue.selected ? 'dropdownOpened' : 'dropdownClosed'"
                          (click)="openJourneyDetails(journeyValue, $event,jIndex)" class="journeyArrowDown"
                          name="arrow-forward-circle-outline"></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>


                <ion-row style="width:100%;">
                  <ng-container *ngIf="journeyValue.selected">
                    <div class="studentDropdownProgression">
                      <div class="userDashboard"
                        [ngClass]="[globalService.isMobile ? 'spaceMobileDashboard' : 'spaceNormalDashboard']">
                        <div class="stepProgression">
                          <p class="studentDropdownTitle">Étapes du parcours</p>
                          <div class="eachStudentExerciseContainer">
                            <ng-container
                              *ngFor="let journeyExercises of journeyValue.exercises; let exIndex = index;let last = last">
                              <ion-row class="eachStudentExercise"
                                [ngClass]="{'eachStudentExerciseMobile': globalService.isMobile, 'eachStudentExerciseTD': globalService.isDesktop, 'eachStudentExerciseStart':exIndex === 0, 'eachStudentExerciseLast':last}">
                                <div class="stepsContainer" [ngClass]="{ 'disabledExercise' : journeyExercises.status !== Status.done && journey.key != 3,
                                'enableExercise' : journeyExercises.status === Status.done || journey.key == 3}">
                                  <ion-col size="1" size-xl="1" [sizeSm]="globalService.isMobile ? '4' : '1'"
                                    size-xs="4" class="stepsLeftPositionExerciseIndex">
                                    <p>{{exIndex + 1}}</p>
                                  </ion-col>

                                  <ion-col *ngIf="!globalService.isMobile" size="3" size-xl="3" size-sm="3"
                                    class="stepsLeftPositionTitle">

                                    <ion-img
                                      [src]="'/assets/gabarits/thumbnails/assignation/thumbnail_'+journeyExercises.activityId +'.png'"
                                      class="journeyThumbnail"></ion-img>
                                    <ion-img>
                                    </ion-img>
                                  </ion-col>

                                  <ion-col size="4" size-xl="4" size-sm="4" size-xs="4" class="exerciseTitleContainer">
                                    <p>
                                      {{cabriService.exercices.extractor[journeyExercises.exerciseId]?.title}}</p>
                                  </ion-col>

                                  <ion-col size="4" size-sm="4" size-md="4" size-xs="4" class="exerciseIdContainer">
                                    <p>
                                      {{cabriService.exercices.extractor[journeyExercises.exerciseId]?.id}}</p>
                                  </ion-col>
                                </div>
                                <div>
                                </div>
                              </ion-row>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ion-row>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>