import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ColorPickerControl } from "@iplab/ngx-color-picker";

declare var BABYLON;
declare var window: any;
@Component({
	selector: "app-babylonjs",
	templateUrl: "./babylonjs.component.html",
	styleUrls: ["./babylonjs.component.scss"]
})
export class BabylonjsComponent implements OnInit {

	constructor() {
		this.blacklistMeshes = [""];
	}
	savebackground: any;
	mycolor: any;
	currentMesh: any;
	currentColor: any;
	centerRotation: any;
	public scene;
	public camera;
	public canvas: any;
	public open = false;
	public blacklistMeshes;
	public currentModel;
	@Input() cd: ChangeDetectorRef;

	public allModels;

	ngOnInit() {}

	openDevTools() {
		console.log("open babylon js");
		this.open = !this.open;

		this.scene = window.store.getters.cabri.Scene;
		this.canvas = window.document.getElementById("renderCanvas1");
		// --------------------------
		// backGround:
		this.scene.backgroundColorControl = new ColorPickerControl();
		this.scene.backgroundColorControl.showAlphaChannel();
		this.scene.backgroundColorControl.valueChanges.subscribe(color => {
			console.log("color = " , color);
			this.canvas.style.backgroundColor = this.convertColorCSS(color);
			this.cd.detectChanges();
		});
		// --------------------------

		// remove default cabri events
		// this.scene.actionManagers.forEach(am => am.dispose());
		// this.scene.CABRI.onPointerDown = () => {};
		// this.scene.CABRI.onPointerUp = () => {};
		// this.scene.CABRI.onPointerMove = () => {};
		this.camera = this.scene.CABRI.Camera2D3D;
		this.cd.detectChanges();

		this.scene.meshes.forEach(m => {
			if (m.material) {
				m.material.emissiveColorControl = new ColorPickerControl();
				m.material.emissiveColorControl.showAlphaChannel();
				m.material.emissiveColorControl.valueChanges.subscribe(color => {
					m.material.emissiveColor = this.convertColor(color);
					this.cd.detectChanges();
				});
				m.material.diffuseColorControl = new ColorPickerControl();
				m.material.diffuseColorControl.showAlphaChannel();
				m.material.diffuseColorControl.valueChanges.subscribe(color => {
					m.material.diffuseColor = this.convertColor(color);
					this.cd.detectChanges();
				});

			}
		});

		// this.calculateCenterOfRoration();
	}
	mergeMeshes() {
		const mergedMeshes = [];

		this.allModels = [];
		this.scene.meshes.forEach(m => {
			if (m && m.name) {
				if (m.object && m.object.type && m.object.type === "model") {
					console.log(m);
					m.computeWorldMatrix();
					m.getWorldMatrix(true);
					m.setPivotMatrix(BABYLON.Matrix.Translation(m.position.x, m.position.y, m.position.z));
					const ids = m.id.split(".");
					if (!this.allModels[ids[0]]) {
						this.allModels[ids[0]] = [];
					}
					this.allModels[ids[0]].push(m);
				}
			}
		});
		setInterval(() => {
			this.allModels.forEach(m => {
				m.forEach(submesh => {
					submesh.rotation.z += 0.01;
				});
			});
		}, 1000 / 60);
		return true;
		this.scene.meshes.forEach(m => {
			if (m && m.name) {
				if (m.object && m.object.type && m.object.type === "facet") {
					console.log(m);
					mergedMeshes.push(m);
				}
			}
		});
		if (mergedMeshes.length > 0) {
			const newMesh = BABYLON.Mesh.MergeMeshes(mergedMeshes, true, false, false, true, true);
			newMesh.layerMask = 0x10000000;
			const info = newMesh.getBoundingInfo().boundingBox.centerWorld;
			newMesh.setPivotMatrix(BABYLON.Matrix.Translation(-info.x, -info.y, -info.z));
			this.camera.setTarget(info);
			newMesh.renderingGroupId = 1;
			newMesh.id = "mycube";
			console.log("mesh id" + (this.scene.meshes.length - 1), newMesh);

			newMesh.actionManager = new BABYLON.ActionManager(this.scene);
			newMesh.actionManager.registerAction(
				new BABYLON.InterpolateValueAction(
					BABYLON.ActionManager.OnPickTrigger,
					newMesh.material,
					"diffuse",
					new BABYLON.Color3(1, 0, 0),
					1000
				)
			);

			let i = 0;
			newMesh.subMeshes.forEach(submesh => {
				console.log(submesh);
				submesh.id = "mycube-" + i;
				i++;
				submesh.actionManager = new BABYLON.ActionManager(this.scene);
				submesh.actionManager.registerAction(
					new BABYLON.InterpolateValueAction(
						BABYLON.ActionManager.OnPickTrigger,
						submesh.currentMaterial,
						"diffuseColor",
						new BABYLON.Color3(1, 0, 0),
						1000
					)
				);
			});
		}

		this.cd.detectChanges();
	}

	highlight(m) {
		const b = window.dispatchEvent(new Event("blur"));
		if (m.material && m.material.diffuseColor) {
			m.savedColor = new BABYLON.Color3(m.material.diffuseColor.r, m.material.diffuseColor.g, m.material.diffuseColor.b);
			m.material.diffuseColor = new BABYLON.Color3(1, 0, 0);
		}
	}
	unhighlight(m) {
		if (m.material && m.material.diffuseColor) {
			m.material.diffuseColor = new BABYLON.Color3(m.savedColor.r, m.savedColor.g, m.savedColor.b);
		}
	}
	meshClicked(m) {
		this.currentMesh = m;
		m.openDetails = !m.openDetails;
		// m.convertToFlatShadedMesh();
		// m.enableEdgesRendering(0.95, true);
		// m.edgesWidth = 4.0;
		// m.edgesColor = new BABYLON.Color4(1, 1, 1, 1);
		console.log(m);
		this.cd.detectChanges();
	}
	cameraClicked(c) {
		console.log(c);
		c.openDetails = !c.openDetails;

		this.scene.CABRI.unlockOrientation();
		this.camera.lowerAlphaLimit = null;
		this.camera.upperAlphaLimit = null;
		this.camera.lowerBetaLimit = null;
		this.camera.upperBetaLimit = null;
		this.cd.detectChanges();

		// this.camera.setTarget(this.centerRotation);
	}

	filterMeshes(e) {
		const search = e.target.value;
		console.log(e);
		this.scene.meshes.forEach(m => {
			if (search === "") {
				m.showInDevMenu = true;
			} else {
				if (
					("" + m.id).indexOf(search) > -1 ||
					("" + m.name).indexOf(search) > -1 ||
					(m.object && ("" + m.object.type).indexOf(search) > -1)
				) {
					m.showInDevMenu = true;
				} else {
					m.showInDevMenu = false;
				}
			}
		});
		this.cd.detectChanges();
	}

	openPicker(material, key) {
		material[key] = !material[key];
		this.cd.detectChanges();
	}
	printRGBABackgroundColor(color) {
		return "background-color:rgba(" + color.r * 255 + "," + color.g * 255 + "," + color.b * 255 + "," + color.a * 255 + ");";
	}

	convertColor(color) {
		return new BABYLON.Color4(
			color.getRgba().red / 255,
			color.getRgba().green / 255,
			color.getRgba().blue / 255,
			color.getRgba().alpha
		);
	}
	convertColorCSS(color) {
		return "rgba(" + color.getRgba().red + "," + color.getRgba().green + "," + color.getRgba().blue + "," + color.getRgba().alpha + ")";
	}
	saveValue(param, event) {
		console.log(param, event);
		param = event.value;
	}

	calculateCenterOfRoration() {
		let distance = -1;
		let minPoint = null;
		for (const m in window.store.getters.cabri.Scene.meshes) {
			if (window.store.getters.cabri.Scene.meshes.hasOwnProperty(m)) {
				const mesh = window.store.getters.cabri.Scene.meshes[m];
				if (mesh.object && mesh.object.type === "facet") {
					if (("" + mesh.name).indexOf("_") === -1) {
						console.log(m, mesh.name, mesh.object, mesh.object.params.pts3D);
						if (distance < 0) {
							distance = this.distance(mesh.object.params.pts3D[0], mesh.object.params.pts3D[1]);
						}
						if (!minPoint) {
							minPoint = JSON.parse(JSON.stringify(mesh.position));
						}

						if (mesh.position.x < minPoint.x && mesh.position.y < minPoint.y && mesh.position.z < minPoint.z) {
							minPoint = mesh.position;
						}
					}
				} else {
					// console.error(mesh.name, m);
				}
			}
		}
		console.log(minPoint);
		console.log(distance);

		const center = {
			x: minPoint.x + distance / 2,
			y: minPoint.y + distance / 2,
			z: minPoint.z + distance / 2
		};
		console.log(center);

		this.centerRotation = new BABYLON.Vector3(0, 0, 0);
	}
	distance(pointA, pointB) {
		const dx = pointB.x - pointA.x;
		const dy = pointB.y - pointA.y;
		const dz = pointB.z - pointA.z;

		const dist = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2) + Math.pow(dz, 2));

		return dist;
	}

	loadForest() {
		BABYLON.SceneLoader.ImportMesh(
			null,
			"/assets/models/",
			"forestsphere.babylon",
			this.scene,
			(meshes, particleSystems, skeletons) => {
				// do something with the meshes and skeletons
				// particleSystems are always null for glTF assets
				console.log(meshes);
				const newMesh = meshes[0];
				newMesh.layerMask = 0x10000000;
				newMesh.renderingGroupId = 1;
				newMesh.id = "forest";
				newMesh.scaling = new BABYLON.Vector3(1000, 1000, 1000);
				this.cd.detectChanges();
			}
		);
	}

	loadMathia2() {
		BABYLON.SceneLoader.ImportMesh(
			null,
			"/assets/babylon/",
			"mathia_neutral_flame.babylon",
			this.scene,
			(meshes, particleSystems, skeletons) => {
				// do something with the meshes and skeletons
				// particleSystems are always null for glTF assets
				console.log(meshes);
				const newMesh = meshes[0];
				newMesh.layerMask = 0x10000000;
				newMesh.renderingGroupId = 1;
				newMesh.id = "mathia";
				newMesh.scaling = new BABYLON.Vector3(100, 100, 100);
				const info = newMesh.getBoundingInfo().boundingBox.centerWorld;

				newMesh.rotationQuaternion.toEulerAnglesToRef(newMesh.rotation);
				newMesh.rotationQuaternion = null;
				newMesh.position.x = -2;
				newMesh.setPivotMatrix(BABYLON.Matrix.Translation(0, -info.y, 0));

				this.cd.detectChanges();
			}
		);
	}

	loadSkybox() {
		// Skybox
		const skybox = BABYLON.MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, this.scene);
		const skyboxMaterial = new BABYLON.StandardMaterial("skyBox", this.scene);
		skyboxMaterial.backFaceCulling = false;
		skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("/assets/babylon/cubemaps/milky/jpg/milky", this.scene);
		skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
		skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
		skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
		skybox.material = skyboxMaterial;
		skybox.layerMask = 0x10000000;
  console.log("ok");
        // load .env lighting test:
		const hdrTexture = new BABYLON.CubeTexture.CreateFromPrefilteredData("/assets/babylon/cubemaps/milky/environment.env", this.scene);
		this.scene.environmentTexture = hdrTexture;
		this.cd.detectChanges();
	}

	loadMathia() {
		BABYLON.SceneLoader.ImportMesh(
			null,
			"/assets/babylon/",
			"mathia_neutral_flame.babylon",
			this.scene,
			(meshes, particleSystems, skeletons) => {
				// do something with the meshes and skeletons
				// particleSystems are always null for glTF assets
				meshes.forEach(newMesh => {
					newMesh.layerMask = 0x10000000;
					newMesh.renderingGroupId = 1;
					newMesh.id = "MathiaEngaging";
				});
				const hdrTexture = new BABYLON.CubeTexture.CreateFromPrefilteredData(
					"/assets/babylon/cubemaps/milky/environment.env",
					this.scene
				);
				this.scene.environmentTexture = hdrTexture;
				this.cd.detectChanges();
			}
		);
	}

	getQuaternion(axe, value) {
		let axis;
		if (axe === "x") {
			axis = new BABYLON.Vector3(1, 0, 0);
		}
		if (axe === "y") {
			axis = new BABYLON.Vector3(0, 1, 0);
		}
		if (axe === "z") {
			axis = new BABYLON.Vector3(0, 0, 1);
		}
		return new BABYLON.Quaternion.RotationAxis(axis, value);
	}
}
