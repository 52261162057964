import { ScenarioQuizz } from "src/app/models/quizz/scenario-quizz";
import { Router } from "@angular/router";
import { PlayTTSService } from "./../../services/play-tts.service";
import { ScenarioOse } from "./../../models/scenario-ose";
import { Subscription } from "rxjs";
import { AudioService } from "./../../services/audio.service";
import { HttpClient } from "@angular/common/http";
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from "@angular/core";
import { Fiche } from "src/app/models/fiche";
import { GlobalService } from "src/app/services/global.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import Swiper, { SwiperOptions } from "swiper";
import { OseExerciceType } from "src/app/models/ose2-journey";
import { OseActivity } from "src/app/models/ose-activities";
import { Statement } from "src/app/models/statement";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { LrsService } from "src/app/services/lrs.service";
import { AccountService } from "src/app/services/account.service";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import * as Player from "@vimeo/player/dist/player.js";
import { AppUtils } from "src/app/app-utils";
import { ScenarioNavigationOse } from "src/app/models/scenario-navigation-ose";
import { environment } from "src/environments/environment";
import { SwiperEvents } from "swiper/types";
import { Details } from "src/app/page/territoire/territoire.page";

export enum FicheEtapes {
	titleConclusion = "Conclusion",
	titleNextSeq = "nextSeq"
}
@Component({
	selector: "app-fiche",
	templateUrl: "./fiche.component.html",
	styleUrls: ["./fiche.component.scss"]
})
export class FicheComponent implements OnInit, AfterViewInit {
	// FICHE
	public displayMascotte = false;
	public fiche: Fiche;
	public fiches: Array<any>;
	event: SwiperEvents;
	@ViewChild("customStyle", { static: false }) cStyle: ElementRef;
	// SWIPER
	@ViewChild("ionSlides", { static: false }) slideElement;
	// swiper: Swiper;
	public get swiper(): Swiper {
		return this.slideElement?.swiperRef;
	}
	public isSwiperEnd: boolean;
	sliderOptions: SwiperOptions;
	@Input("idFiche") idFiche;
	@Input("hideMascotte") hideMascotte = false;
	@Input("feedback") feedback;
	public readingFeedback: boolean;
	@Input() scenario: ScenarioOse | ScenarioQuizz | ScenarioNavigationOse;
	@Output() end? = new EventEmitter<boolean>();
	@Input() standAlone?: boolean;
	vimeoPlayers: Array<any>;
	volumeChangeSub: Subscription;
	fichePageIndex: number;
	vimeoFirstVideoLoaded: Promise<unknown>;
	vimeoFirstVideoLoadedResolve: (value: void | PromiseLike<void>) => void;

	swiperInitEnd: Promise<void>;

	imagesReadyPromise: Promise<void>;
	public nextButtonClicked: boolean;
	resolveCreatedFichePromise: (value: void | PromiseLike<void>) => void;
	vimeoPlayerPlayingId: any;
	launchExerciseSubscription: Subscription;
	public environment;
	animateNextButton = false;
	vimeoIsPlaying: boolean;

	public totalExToDo: string;
	public totalSeqStep: string;
	public totalExRemaining: string;
	public currentFiche: OseActivity;
	public FicheEtapes;
	public Details;
	public tooltipIsReading = false;
	constructor(
		private http: HttpClient,
		private renderer: Renderer2,
		private cabriService: CabriDataService,
		public globalService: GlobalService,
		public oseJourneyService: OseJourneyService,
		public accountService: AccountService,
		public lrs: LrsService,
		public cd: ChangeDetectorRef,
		private audioService: AudioService,
		public ttsService: PlayTTSService,
		public router: Router
	) {
		this.environment = environment;
		this.launchExerciseSubscription = this.oseJourneyService.launchExercise.subscribe({
			next: (exo: OseActivity) => {
				if (exo.type === OseExerciceType.fiche) {
					if (!exo.isEtape) {
						this.loadFiche(exo.id);
					} else {
						this.initalizeFiche();
					}
				}
			}
		});
		this.volumeChangeSub = this.globalService.volumeChangeEvent.subscribe(volume => {
			this.vimeoPlayers?.forEach(player => {
				player.setVolume(volume.sounds);
			});
		});
		this.sliderOptions = {
			resizeObserver: true
		};
	}

	async ngOnInit() {
		await this.initalizeFiche();
	}

	async initalizeFiche() {
		await this.cabriService.getTooltipHelps();
		if (this.feedback && this.feedback !== "") {
			let htmlFeedback;
			if (this.feedback.indexOf('"wp-block-group__inner-container"') > -1) {
				htmlFeedback = this.feedback;
			} else {
				htmlFeedback = this.feedback.replace(
					'<div id="fiche-container">',
					"<div id='fiche-container'><div class='wp-block-group'><div class='wp-block-group__inner-container'>"
				);
				htmlFeedback += "</div></div>";
			}
			this.createFiche(htmlFeedback, null);
		} else {
			this.setSmallLoader(true);
			await this.oseJourneyService.journeysLoaded();
			// console.error("create fiche on init")
			this.currentFiche = null;
			let idFiche;
			if (this.idFiche) {
				idFiche = this.idFiche;
			} else if (this.oseJourneyService.currentJourney) {
				this.currentFiche = this.oseJourneyService.currentJourney.getCurrentExercise();
				idFiche = this.currentFiche.id;
			}
			if (idFiche) {
				if (this.currentFiche?.isEtape) {
					// conclusion
					this.FicheEtapes = FicheEtapes;
					this.Details = Details;
					this.idFiche = idFiche;
					this.globalService.confettiCanvasComponent.launchConfetti();
					if (this.currentFiche.title === FicheEtapes.titleConclusion) {
						const exToDo = this.oseJourneyService.currentJourney.getTotalExercisesToDo();
						if (exToDo?.length > 0) {
							const exRemaining = exToDo.filter(allEx => {
								return this.oseJourneyService.currentJourney.skippedMode ? !allEx.skip : !allEx.completed;
							});
							if (exRemaining?.length > 0) {
								const seqStep = exToDo?.length - exRemaining?.length;

								if (seqStep > 0) {
									this.totalSeqStep = seqStep === 1 ? `${seqStep}ère` + "" : `${seqStep}ème`;
								}
								this.totalExToDo = AppUtils.numberToLetter(exToDo?.length, this.globalService.locale);
								this.totalExRemaining = AppUtils.numberToLetter(exRemaining?.length, this.globalService.locale);
								if (this.totalExRemaining === "un") {
									this.totalExRemaining = "une";
								}
							}
						}
					}
					this.setSmallLoader(false);
				} else {
					this.loadFiche(idFiche);
				}
			} else if (this.idFiche) {
				this.loadFiche(idFiche);
			} else {
				if (this.environment.production) {
					this.router.navigateByUrl("/territoire");
				} else {
					const testFiche = 0; // change id for testing
					if (testFiche) {
						this.loadFiche(testFiche);
					} else {
						this.fiches = await this.cabriService.getAllFiches();
					}

					// this.setSmallLoader(false);
					this.globalService.setGlobalLoading(false);
				}
			}
		}
	}

	/**
	 * Slide changed on touch event without click the button / update slides data
	 * @param $event Swiper
	 */
	async slideTouchTransitionEnd($event: Array<Swiper>) {
		if ($event && Array.isArray($event)) {
			const swpEvent = $event[0];
			// update swiper instance with params one. Get exact swiper position with index
			this.swiper.activeIndex = swpEvent.activeIndex;
			this.swiper.previousIndex = swpEvent.previousIndex;
			if (swpEvent.previousIndex > swpEvent.activeIndex) {
				await this.previousPage(false);
			} else {
				await this.nextPage(swpEvent, false);
			}
			// this.onSwiperChange();
		}
	}

	setSmallLoader(value: boolean) {
		// TODO handle loading in feedback mode if media content ? (temp disabled)
		if (!this.feedback) {
			this.globalService.setSmallLoading(value);
		}
	}

	async loadFiche(idFiche: number) {
		return new Promise<void>(async resolve => {
			this.setSmallLoader(true);
			this.cd.detectChanges();
			this.cabriService.getFiche(idFiche).then(async ficheData => {
				await this.createFiche(ficheData, idFiche);
				if (this.fiche) {
					this.slideElement.initialize = true;
					this.cabriService.currentOseActivity = {
						id: Number(this.fiche.id),
						title: this.fiche.title,
						type: OseExerciceType.fiche
					};
					const statement = this.startActivityStatement();
					if (statement) {
						this.lrs.send(statement);
					}
					// wait here if TTS disabled instead of in scenario.readSpeechSequenceFromFicheContent() tts callback?
					// await this.vimeoFirstVideoLoaded;
					this.setSmallLoader(false);
					// await this.globalService.speechBubbleComponent.displayGlobalBubble(false);
					this.scenario.readSpeechSequenceFromFicheContent(this.fiche.ficheTextContent[this.swiper.activeIndex]);
				} else {
					this.setSmallLoader(false);
				}
				resolve();
			});
		});
	}

	/**
	 * creates a Promise to be awaited and resolved after fiche is created & fichePageIndex is set
	 */
	async ficheCreatedPromise(): Promise<void> {
		return new Promise(resolve => {
			this.resolveCreatedFichePromise = resolve;
		});
	}

	async createFiche(ficheHTML: string, idFiche: number) {
		this.animateNextButton = false;
		if (!this.hideMascotte) {
			this.displayMascotte = true;
		}
		this.isSwiperEnd = false;
		const ficheTitle = idFiche ? await this.cabriService.getFicheTitle(idFiche) : "";

		// destroy swiper
		this.fiche = null;
		this.cd.detectChanges();

		// create new fiche
		this.fiche = new Fiche(ficheHTML, this.renderer, idFiche, ficheTitle);

		this.fichePageIndex = 0;

		// append custom styles to page
		this.fiche.styles.forEach(style => {
			this.renderer.appendChild(this.cStyle.nativeElement, style);
		});
		if (this.fiche.pages.length === 1) {
			this.isSwiperEnd = true;
		}

		// security
		this.cd.detectChanges();
		await this.updateFiguresHeight();
		await this.searchForVimeo();
	}

	async swiperInitliazed($event) {
		this.setSmallLoader(true);
		await this.afterInitSwiper($event);
	}

	async swiperResizeEvent() {
		this.swiper.wrapperEl.style.height = this.swiper.el.offsetHeight + "px";
		await this.updateFiguresHeight(true);
		this.setSmallLoader(false);
	}

	async ngAfterViewInit() {}

	allImagesLoaded() {
		this.imagesReadyPromise = new Promise<void>(async resolve => {
			this.cd.detectChanges();
			const promises = [];
			this.slideElement.elementRef.nativeElement.querySelectorAll("img").forEach(image => {
				image.loading = "eager";
				if (!image.complete) {
					const promise = new Promise<void>(resolve => {
						image.onload = () => {
							resolve();
						};
						image.onerror = () => {
							resolve();
						};
					});
					promises.push(promise);
				}
			});
			if (promises.length > 0) {
				await Promise.all(promises);
			}
			resolve();
		});
	}
	async updateFiguresHeight(resize = false, launchSecurity = true) {
		if (!resize) {
			this.allImagesLoaded();
		}
		await this.imagesReadyPromise;
		const mascotteHeight = Number(window.innerHeight * 0.14);

		// add tooltip on strong tags
		this.addTooltip();

		// set height on ose numbered list (height auto doesn't allow calculation)
		const numberedLists = this.slideElement.elementRef.nativeElement.querySelectorAll(".ose-numbered-list");
		numberedLists.forEach(numberedList => {
			numberedList.style.height = numberedList.offsetHeight + "px";
		});

		// hack empty p
		const deleteEmptyPTag = this.slideElement.elementRef.nativeElement.querySelectorAll("p");
		deleteEmptyPTag.forEach(element => {
			if (element.innerHTML === "") {
				element.remove();
			}
		});

		const circleWrappers = this.slideElement.elementRef.nativeElement.querySelectorAll(".green-circle-wrapper");
		circleWrappers.forEach(circleWrapper => {
			// add class to ose green circle container
			const ficheContainer = this.findParent(circleWrapper, "page-container--fiche");
			ficheContainer.classList.add("ose-circle");

			// adujst height to number of elements
			if (circleWrapper.parentElement.classList.contains("is-vertical")) {
				circleWrapper.style.height = 90 / circleWrapper.parentElement.children.length + "%";
			}

			// force circle size
			const divCircle = circleWrapper.querySelector(".circle > div");
			divCircle.style.width = divCircle.offsetHeight + "px";
		});

		// calculate height of all figures
		this.calculateHeights(mascotteHeight);

		// hide floating div if not needed
		this.hideFloatingDiv(mascotteHeight);

		// click on audio link to play sound
		this.addAudioLinks();

		this.imageViewer();

		if (launchSecurity) {
			this.figureHeightSecurity();
		}
		// this.setSmallLoader(false);
	}

	imageViewer() {
		const imgClickEvent = $event => {
			$event.stopPropagation();

			this.globalService.displayImage({ src: $event.target.src, srcSet: $event.target.srcset });

			this.globalService.imageViewerGallery = Array.from(this.slideElement.elementRef.nativeElement
				.querySelectorAll(".swiper-slide-active img:not(.conclusion-end-seq img, .tooltip)"))
				.filter((element:HTMLElement) => {
					return !(element.parentElement.tagName === "A" && element.parentElement.parentElement.classList.contains("audio-link"))
				})
				.map((i:any) => {
					return { src: i.src, srcSet: i.srcSet };
				});
			console.log(this.globalService.imageViewerGallery);
		};
		// fullscreen image on click
		const imgTags = this.slideElement.elementRef.nativeElement.querySelectorAll("img:not(.conclusion-end-seq img, .tooltip)");
		imgTags.forEach((element: HTMLElement) => {
			// not on audio-links
			if (!(element.parentElement.tagName === "A" && element.parentElement.parentElement.classList.contains("audio-link"))) {
				// remove old clic events
				element.eventListeners("click").forEach(eventListener => {
					element.removeEventListener("click", eventListener);
				});
				// add clic event
				element.addEventListener("click", imgClickEvent);
			}
		});
	}
	figureHeightSecurity(counter = 0) {
		setTimeout(() => {
			this.updateFiguresHeight(true, false);
		}, 150);
	}
	async searchForVimeo() {
		this.vimeoPlayers = new Array();
		const vimeoIframes = this.slideElement.elementRef.nativeElement.querySelectorAll(".is-provider-vimeo");
		for (const [i, element] of vimeoIframes.entries()) {
			await this.initVimeoPlayer(element, i + 1);
			console.error("this.vimeoPlayers updated = ", this.vimeoPlayers);
		}
	}

	hideFloatingDiv(mascotteHeight) {
		const ficheContainers = this.slideElement.elementRef.nativeElement.querySelectorAll(".wp-block-group__inner-container");
		ficheContainers.forEach(ficheContainer => {
			if (
				this.hideMascotte ||
				ficheContainer.offsetHeight < this.slideElement.elementRef.nativeElement.offsetHeight - mascotteHeight * 2
			) {
				// hide floating element
				ficheContainer.classList.add("hideFloatingElement");
			} else {
				ficheContainer.classList.remove("hideFloatingElement");
			}
		});
	}
	addAudioLinks() {
		const aTags = this.slideElement.elementRef.nativeElement.querySelectorAll("a");
		aTags.forEach(element => {
			if (element.href && String(element.href).endsWith(".mp3")) {
				element.parentElement.classList.add("audio-link");
				element.addEventListener("click", $event => {
					$event.preventDefault();
					$event.stopPropagation();
					let target = $event.target as any;
					if (target.tagName === "IMG") {
						target = target.parentElement;
					}
					const audioElement = new Audio(target.href);
					audioElement.addEventListener("loadeddata", () => {
						audioElement.play();
					});
				});
			}
		});
	}

	calculateHeights(mascotteHeight) {
		const figureElements = this.slideElement.elementRef.nativeElement.querySelectorAll("swiper figure:not(.wp-block-audio)");
		figureElements.forEach(figureElement => {
			if (figureElement) {
				let headerHeight = 0;
				let totalHeight = 0;

				let parent = figureElement.parentElement;
				if (parent.classList?.contains("wp-block-image")) {
					parent = parent.parentElement;
				}
				if (parent.classList?.contains("wp-block-column")) {
					if (parent.childNodes.length === 3) {
						// up to wp-block-columns only if figure is the only child of the column (childNodes.length === 3)
						parent = parent.parentElement;
						parent = parent.parentElement;
					} else {
						// else calculate height of the others elements
						const childrenColumn = parent.childNodes;
						childrenColumn.forEach(element => {
							if (!element.data && element.tagName !== "FIGURE") {
								const elementHeight =
									element.offsetHeight +
									parseInt(window.getComputedStyle(element).getPropertyValue("margin-top"), 10) +
									parseInt(window.getComputedStyle(element).getPropertyValue("margin-bottom"), 10);

								// calculate totalHeight
								totalHeight += elementHeight;
							}
						});
						parent = parent.parentElement;
						parent = parent.parentElement;
					}
				}
				if (parent.classList?.contains("wp-block-group__inner-container")) {
					let beforeFigure = true;
					const children = parent.childNodes;
					children.forEach(element => {
						if (
							!element.data &&
							(element.tagName === "FIGURE" ||
								element.classList?.contains("wp-block-image") ||
								element.classList?.contains("wp-block-video") ||
								element.classList?.contains("wp-block-columns"))
						) {
							beforeFigure = false;
						}
						if (
							!element.data &&
							element.tagName !== "FIGURE" &&
							!element.classList?.contains("wp-block-image") &&
							!element.classList?.contains("wp-block-video") &&
							!element.classList?.contains("wp-block-columns")
						) {
							// don't replace parseInt by Number because of "px" in string
							const elementHeight =
								element.offsetHeight +
								parseInt(window.getComputedStyle(element).getPropertyValue("margin-top"), 10) +
								parseInt(window.getComputedStyle(element).getPropertyValue("margin-bottom"), 10);

							// calculate header height
							if (beforeFigure) {
								if (headerHeight + elementHeight < mascotteHeight) {
									headerHeight += elementHeight;
								} else if (elementHeight && headerHeight + elementHeight > mascotteHeight) {
									headerHeight = mascotteHeight;
								}
							}

							// calculate totalHeight
							totalHeight += elementHeight;
						}
					});
					// add empty space between header and mascotte
					totalHeight += Number(mascotteHeight - headerHeight);
					// set calculated heights
					figureElement.style.height = this.slideElement.elementRef.nativeElement.offsetHeight - totalHeight + "px";
				}
			}
		});
	}

	findParent(element, className) {
		if (element.parentElement.classList.contains(className) || element.parentElement.tagName === "HTML") {
			return element.parentElement;
		} else {
			return this.findParent(element.parentElement, className);
		}
	}

	sendJourneyEndLrs() {
		return new Promise<void>(resolve => {
			const endStatement = this.endActivityStatement();
			this.lrs.send(endStatement).finally(() => {
				resolve();
			});
		});
	}

	continueActivity() {
		if (this.currentFiche.title === FicheEtapes.titleNextSeq) {
			const ex = this.oseJourneyService.currentJourney.getCurrentExercise();
			if (this.oseJourneyService.currentJourney.selectedCategory === Details.discovering) {
				this.oseJourneyService.currentJourney.selectedCategory = Details.learning;
			} else if (this.oseJourneyService.currentJourney.selectedCategory === Details.learning) {
				this.oseJourneyService.currentJourney.selectedCategory = Details.quizz;
			}
			this.oseJourneyService.currentJourney.currentCategoryExercise = this.oseJourneyService.currentJourney.getSelectedCategory();
			this.oseJourneyService.currentJourney.exercises.forEach((exercise, i, currArr) => {
				const isCurrentEx = this.oseJourneyService.currentJourney.currentCategoryExercise.some(currEx => {
					return currEx.id === exercise.id;
				});

				if (isCurrentEx || ex.id === exercise.id) {
					currArr[i].completed = false;
				} else {
					currArr[i].completed = true;
				}
			});
			this.oseJourneyService.currentJourney.createDynamicNextSeqEtape();
		}

		this.nextButtonClicked = true;
		this.idFiche = null;
		this.fichePageIndex = undefined;
		this.displayMascotte = false;
		this.fiche = null;
		this.currentFiche = null;
		this.animateNextButton = false;
		this.oseJourneyService.exerciseEnd.next();
		this.setSmallLoader(false);
		// this.destroyPage();
	}

	async nextPage(event?, changeSlide = true) {
		this.animateNextButton = false;
		this.toggleVimeoPlayback(false);
		if (this.isSwiperEnd || this.feedback) {
			if (event) {
				await this.globalService.waitButtonClick({ buttonClicked: event.currentTarget });
			}
			this.nextButtonClicked = true;
			await this.scenario.skipMathiaSpeechSequence(true);
			this.fichePageIndex = undefined;
			await this.killVimeoPlayerIfAny();
			if (this.feedback) {
				this.oseJourneyService.feedbackEnd.next(event);
			} else {
				if (this.fiche) {
					this.sendJourneyEndLrs().finally(() => {
						if (!this.standAlone) {
							this.oseJourneyService.exerciseEnd.next();
						}
						this.end?.emit(true);
					});
				}
				this.displayMascotte = false;
				this.fiche = null;
				this.currentFiche = null;
				this.tooltipIsReading = false;
				this.swiper.slideTo(0, 20, false);
				this.setSmallLoader(false);
			}
		} else {
			this.fichePageIndex++;
			await this.scenario.skipMathiaSpeechSequence(true);
			if (changeSlide) {
				this.swiper.slideNext(300, false);
			}
			this.swiper.updateSize();
			this.swiper.updateAutoHeight();
			this.setSmallLoader(false);
			this.scenario.readSpeechSequenceFromFicheContent(this.fiche.ficheTextContent[this.swiper.activeIndex]);
		}

		if (this.swiper.isEnd) {
			this.isSwiperEnd = true;
		}
	}

	async prevPage() {
		if (this.swiper?.activeIndex > 0 && this.fiche) {
			this.previousPage();
		} else {
			this.fiche = null;
			this.currentFiche = null;
			await this.scenario?.skipMathiaSpeechSequence(true);
			await this.killVimeoPlayerIfAny();
			this.oseJourneyService.exercisePrev.next();
		}
	}
	async previousPage(changeSlide = true) {
		this.isSwiperEnd = false;
		this.animateNextButton = false;
		this.fichePageIndex--;
		this.tooltipIsReading = false;
		await this.scenario.skipMathiaSpeechSequence(true);
		this.toggleVimeoPlayback(false);
		if (changeSlide) {
			this.swiper.slidePrev();
		}
		this.setSmallLoader(false);
		this.scenario.readSpeechSequenceFromFicheContent(this.fiche.ficheTextContent[this.swiper.activeIndex]);
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.fiche.id)}`,
				this.fiche.title,
				this.fiche.title,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */

	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();

			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.fiche.id)}`,
				this.fiche.title,
				this.fiche.title,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}

			statements.push(exerciseStatement);
			return statements;
		}
	}

	async afterInitSwiper(swiper: Swiper) {
		await this.updateFiguresHeight();
		await this.searchForVimeo();
		// this.setSmallLoader(false);
		this.cd.detectChanges();
		this.globalService.setGlobalLoading(false);
	}

	onSwiperChange() {
		// console.error("onSwiperChange");
		this.animateNextButton = false;
		this.swiper.updateSize();
		this.swiper.updateAutoHeight();
		this.updateFiguresHeight(true);
	}

	async nextFiche() {
		await this.scenario.skipMathiaSpeechSequence(true);
		if (this.isSwiperEnd) {
			await this.killVimeoPlayerIfAny();
			this.fiches.every((fiche, index) => {
				if (fiche.id === this.fiche.id) {
					if (index === this.fiches.length - 1) {
						this.loadFiche(this.fiches[0].id);
					} else {
						this.loadFiche(this.fiches[index + 1].id);
					}
					return false;
				} else {
					return true;
				}
			});
		} else {
			this.nextPage();
		}

		if (this.swiper.isEnd) {
			this.isSwiperEnd = true;
		}
	}

	async prevFiche() {
		if (this.isSwiperEnd) {
			await this.killVimeoPlayerIfAny();
			this.fiches.every((fiche, index) => {
				if (fiche.id === this.fiche.id) {
					if (index === 0) {
						this.loadFiche(this.fiches[this.fiches.length - 1].id);
					} else {
						this.loadFiche(this.fiches[index - 1].id);
					}
					return false;
				} else {
					return true;
				}
			});
		} else {
			this.nextPage();
		}

		if (this.swiper.isEnd) {
			this.isSwiperEnd = true;
		}
	}
	addTooltip() {
		this.slideElement.elementRef.nativeElement.querySelectorAll("strong").forEach((element: HTMLElement) => {
			const help = this.cabriService.getTooltipHelp(element.innerHTML);
			if (element && help) {
				this.oseJourneyService.addTooltipForWordSearch(help.title);
				element.classList.add("has-tooltip");
				const spanHelp = this.renderer.createElement("span");
				spanHelp.innerHTML = "<img class='tooltip' src='/assets/icon/information-circle-outline.svg'/>";
				this.renderer.appendChild(element, spanHelp);
				element.addEventListener("mouseenter", event => {
					this.createTooltip(help, event.target as HTMLElement);
				});
				element.addEventListener("click", event => {
					event.stopPropagation();
					let target = event.target as HTMLElement;
					if (target.tagName === "IMG") {
						target = target.parentElement;
					}
					if (target.tagName === "SPAN") {
						target = target.parentElement;
					}
					this.createTooltip(help, target);
				});
				element.addEventListener("mouseleave", event => {
					this.deleteTooltip();
				});
				this.slideElement.elementRef.nativeElement.addEventListener("click", event => {
					this.deleteTooltip();
				});
			}
		});
	}
	createTooltip(help, target: HTMLElement) {
		if (!target.querySelector(".customtooltip") && !target.classList.contains("customtooltip")) {
			const tooltipHelp = this.renderer.createElement("div");
			tooltipHelp.innerHTML = "<div>" + help.content + "</div>";
			tooltipHelp.classList.add("customtooltip");
			if (target.offsetWidth > window.document.getElementById("fiche-container").offsetWidth / 2) {
				tooltipHelp.style.width = window.document.getElementById("fiche-container").offsetWidth - target.offsetLeft + 100 + "px";
				tooltipHelp.style.marginLeft = "-200px";
			} else {
				tooltipHelp.style.width = target.offsetWidth + "px";
				if(target.offsetLeft < 200){
					tooltipHelp.style.marginLeft = (200 - target.offsetLeft) + "px";
				}
			}
			tooltipHelp.addEventListener("click", event => {
				event.stopPropagation();
			});
			this.renderer.appendChild(target, tooltipHelp);
			if(!this.ttsService.isplaying){
				this.scenario.readText(tooltipHelp.textContent)
				this.tooltipIsReading = true;
			}
			this.imageViewer();
		} else {
			this.deleteTooltip();
		}
	}
	deleteTooltip() {
		window.document.querySelectorAll(".customtooltip").forEach(element => element.remove());
		if(this.tooltipIsReading){
			this.scenario?.skipMathiaSpeechSequence(true);
			this.tooltipIsReading = false;
		}
	}

	initVimeoPlayer(element: any, id: number): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const iframeVimeo = element.querySelector("iframe");
				iframeVimeo.loading = "eager";
				const vimeoPlayer = new Player(element.querySelector("iframe"));
				vimeoPlayer.id = id;
				let statement: Statement;
				const volume = this.globalService.muteSounds
					? 1
					: this.globalService.volume.sounds + 0.3 > 1
					? 1
					: this.globalService.volume.sounds + 0.3;
				vimeoPlayer.setVolume(volume);
				if (this.vimeoPlayers.length === 0) {
					this.vimeoFirstVideoLoaded = new Promise<void>(resolve2 => {
						// resolve onLoad to await elsewhere
						this.vimeoFirstVideoLoadedResolve = resolve2;
					});
				}
				if(this.globalService.isFullscreen){
					this.createFullscreenButton(iframeVimeo, vimeoPlayer);
				} else {
					this.globalService.windowResizedEvent.subscribe(()=>{
						// wait 1s to be sure globalService.isFullscreen is up-to-date
						AppUtils.timeOut(1000).then(()=> {
							if(this.globalService.isFullscreen){
								this.createFullscreenButton(iframeVimeo, vimeoPlayer);
							}
						});
					});
				}
				let sendInitStatement = true;
				vimeoPlayer.on("play", $event => {
					// if other player is playing, pause it
					if (this.vimeoPlayerPlayingId) {
						this.vimeoPlayers?.forEach(player => {
							if (this.vimeoPlayerPlayingId === player.id && this.vimeoPlayerPlayingId !== vimeoPlayer.id) {
								this.vimeoPlayerPlayingId = null;
								player.pause();
							}
						});
					}
					if (!this.vimeoPlayerPlayingId) {
						this.vimeoPlayerPlayingId = vimeoPlayer.id;
					}
					this.vimeoIsPlaying = true;
					this.audioService.toggleMusicPlayback(false);
					if (sendInitStatement) {
						sendInitStatement = false;
						vimeoPlayer
							.getVideoTitle()
							.then(title => {
								const vimeoVideo: { title: string; url: string } = { title, url: (iframeVimeo as any).src };
								statement = this.createVideoStatement(vimeoVideo);
								statement.verb = LrsVerbs.initialized;
								this.lrs.send(statement);
							})
							.catch(error => {
								console.error("vimeoTitle error = ", error);
							});
					}
				});

				vimeoPlayer.on("ended", () => {
					// TODO => tts ou next
					if (this.vimeoPlayerPlayingId === vimeoPlayer.id) {
						this.vimeoPlayerPlayingId = null;
					}
					this.vimeoIsPlaying = false;
					if (statement) {
						statement.verb = LrsVerbs.completed;
						this.lrs.send(statement);
					}
					this.audioService.toggleMusicPlayback(true);
				});

				vimeoPlayer.on("loaded", $event => {
					this.vimeoFirstVideoLoadedResolve();
					// TODO => play if no TTS mode ?
					// this.vimeoPlayer.play();
				});

				vimeoPlayer.on("pause", () => {
					this.vimeoIsPlaying = false;
					AppUtils.debug("pause !");
					this.audioService.toggleMusicPlayback(true);
				});

				vimeoPlayer.on("error", () => {
					this.vimeoIsPlaying = false;
					AppUtils.debug("error !");
					this.vimeoFirstVideoLoadedResolve();
				});
				this.vimeoPlayers.push(vimeoPlayer);
				resolve();
			} catch (error) {
				console.error("error = ", error);
				reject();
				this.vimeoFirstVideoLoadedResolve();
			}
			// await this.vimeoFirstVideoLoadedResolve is in scenario.readSpeechSequenceFromFicheContent()
		});
	}
	createFullscreenButton(iframeVimeo: any, vimeoPlayer: any) {

		// check if button is already created
		const fullscreenButtonExists = iframeVimeo.parentElement.querySelector(".vimeo-fullscreen-button");
		if(!fullscreenButtonExists){
			// create element
			const btnFullscreen = this.renderer.createElement('ion-button');
			btnFullscreen.innerHTML = "Passer la vidéo en plein écran";
			btnFullscreen.classList.add("vimeo-fullscreen-button");
			
			// manage click
			btnFullscreen.addEventListener("click", ($event) => {
				vimeoPlayer.requestFullscreen();
				// $event.target.remove();
				
				// event when leaving fullscreen (not working on all devices)
				const manageFullscreenEvent = data => {
					if(!data.fullscreen && this.globalService.isFullscreen){
						vimeoPlayer.off("fullscreenchange", manageFullscreenEvent);
						this.createFullscreenButton(iframeVimeo, vimeoPlayer);
					}
				}
				// vimeoPlayer.on("fullscreenchange", manageFullscreenEvent);
			});
			iframeVimeo.parentElement.append(btnFullscreen);

		}
	}

	toggleVimeoPlayback(value) {
		if (value) {
			this.vimeoPlayers?.forEach(player => {
				if (this.vimeoPlayerPlayingId === player.id) {
					player.play();
				}
			});
		} else {
			this.vimeoPlayers?.forEach(player => {
				player.pause();
			});
		}
	}

	createVideoStatement(vimeoVideo) {
		const object = new XapiObject(vimeoVideo.url, vimeoVideo.title, vimeoVideo.title, XObjectType.video);
		const context = new XapiContext(this.accountService.team, {
			[XapiExtensions.codeclasse]: this.accountService.team[0]?.classe?.id,
			[XapiExtensions.ose]: true
		});

		return new Statement(this.accountService.team[0]?.id, null, object, context);
	}

	killVimeoPlayerIfAny(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.vimeoPlayers?.length > 0) {
				this.vimeoPlayers.forEach(player => {
					player
						.destroy()
						.then(() => {
							player = null;
							AppUtils.debug("VIMEO PLAYER DESTROYED SUCCESSFULLY");
							this.audioService.toggleMusicPlayback(true);
							resolve();
						})
						.catch(error => {
							player = null;
							AppUtils.debug("VIMEO PLAYER DESTROYED ERROR = ", error);
							reject();
						});
				});
				this.vimeoPlayers = null;
			} else {
				resolve();
			}
		});
	}

	ngOnDestroy() {
		this.scenario?.skipMathiaSpeechSequence(true);
		this.volumeChangeSub?.unsubscribe();
		this.launchExerciseSubscription?.unsubscribe();
		this.totalSeqStep = this.totalExRemaining = this.totalExToDo = null;
	}
}
