import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, SimpleChanges, OnChanges } from "@angular/core";
import { format } from "date-fns";
import { AppLanguage } from "src/app/models/enums/enum-list";
import { GlobalService } from "src/app/services/global.service";
@Component({
	selector: "app-clock-digital",
	templateUrl: "./clock-digital.component.html",
	styleUrls: ["./clock-digital.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class ClockDigitalComponent implements OnInit, OnChanges {
	private WEEKDAYNAMES = "LUN MAR MER JEU VEN SAM DIM";
	private WEEKDAYNAMESEN = "MON TUE WED THU FRI SAT SUN";

	// This object will hold the digit elements
	private digits: any = {};
	// Map digits to their names (this will be an array)
	private digitToName = "zero one two three four five six seven eight nine".split(" ");
	// Positions for the hours, minutes, and seconds
	private positions;

	@ViewChild("clock", { static: false }) clock: ElementRef;
	private ampm;
	private weekdays;

	// Interval to run the refresh every second
	private intervalClockDigital;

	@Input() darkThemeEnabled = false;
	@Input() showWeekdays = true;
	@Input() showAmpm = true;
	@Input() showSeconds = true;
	@Input() stopTime = false;
	@Input() dateInput;
	@Input() holo = false;
	globalService: GlobalService;

	constructor(globalService: GlobalService) {
		this.globalService = globalService;
	}

	ngOnInit() {
		// Stop time if dateInput is provided
		this.stopTime = this.dateInput ? true : false;
	}

	ngOnChanges(changes: SimpleChanges) {
		// Do nothing for the first call (before ngOnInit)
		if (!changes.showSeconds || !changes.showSeconds.isFirstChange()) {
			// Update the template if necessary
			const clockAndTimemustBeReloaded = changes.showSeconds || changes.showWeekdays || changes.showAmpm;
			if (clockAndTimemustBeReloaded) {
				this.initTemplate();
				this.update_time(this.dateInput);
			}
			// Remove the time refresh if necessary
			if (changes.stopTime && changes.stopTime.currentValue) {
				clearInterval(this.intervalClockDigital);
			} else if (changes.stopTime && !changes.stopTime.currentValue) {
				// Schedule this function to be run every 1 sec
				this.intervalClockDigital = setInterval(() => {
					this.update_time();
				}, 1000);
			}
			// Change the time & stop it
			if (changes.dateInput) {
				this.setDateInput(changes.dateInput.currentValue);
			}
		}
	}

	public setDateInput(date) {
		this.stopTime = true;
		clearInterval(this.intervalClockDigital);
		this.update_time(date);
	}

	initTemplate() {
		// Generate the digits with the needed markup,
		// and add them to the clock
		const digitHolder = this.clock.nativeElement.querySelector(".digits");
		while (digitHolder.hasChildNodes()) {
			digitHolder.removeChild(digitHolder.childNodes[0]);
		}

		// AM / PM option
		if (this.showAmpm) {
			this.ampm = this.clock.nativeElement.querySelector(".ampm");
		}
		// Digits positions
		if (this.showSeconds) {
			this.positions = ["h1", "h2", ":", "m1", "m2", ":", "s1", "s2"];
		} else {
			this.positions = ["h1", "h2", ":", "m1", "m2"];
		}

		this.positions.forEach(position => {
			if (position === ":") {
				const dots = document.createElement("div");
				dots.className = "dots";
				digitHolder.append(dots);
			} else {
				const pos = document.createElement("div");
				pos.id = position;
				for (let i = 1; i < 8; i++) {
					const digitBar = document.createElement("span");
					digitBar.className = "d" + i;
					pos.append(digitBar);
				}

				// Set the digits as key:value pairs in the digits object
				this.digits[position] = pos;

				// Add the digit elements to the page
				digitHolder.append(pos);
			}
		});

		if (this.showWeekdays) {
			// Add the weekday names
			const weekdayNames = this.globalService.locale === AppLanguage.FR ? this.WEEKDAYNAMES.split(" ") : this.WEEKDAYNAMESEN.split(" ");
			const weekdayHolder = this.clock.nativeElement.querySelector(".weekdays");
			while (weekdayHolder.hasChildNodes()) {
				weekdayHolder.removeChild(weekdayHolder.childNodes[0]);
			}

			weekdayNames.forEach(weekdayName => {
				const weekday = document.createElement("span");
				weekday.innerHTML = weekdayName;
				weekdayHolder.append(weekday);
			});
			this.weekdays = this.clock.nativeElement.querySelectorAll(".weekdays span");
		}
	}

	ngAfterViewInit() {
		this.initTemplate();

		if (!this.stopTime) {
			// Schedule this function to be run every 1 sec
			this.intervalClockDigital = setInterval(() => {
				this.update_time();
			}, 1000);
		} else {
			this.update_time(this.dateInput);
		}
	}

	// Run a timer every second and update the clock
	public update_time(time?) {
		const formatStr = this.showAmpm ? "hhmmssia" : "HHmmssia";
		// const formatStr = this.showAmpm ? "hhmmssia" : "HHmmssia";
		// test : const formatStr = "hhmmssia";
		const date = format(time, formatStr);
		this.digits.h1.className = this.digitToName[date[0]];
		this.digits.h2.className = this.digitToName[date[1]];
		this.digits.m1.className = this.digitToName[date[2]];
		this.digits.m2.className = this.digitToName[date[3]];
		if (this.showSeconds) {
			this.digits.s1.className = this.digitToName[date[4]];
			this.digits.s2.className = this.digitToName[date[5]];
		}

		if (this.showWeekdays) {
			// day of week
			const dow = Number(date[6]) - 1;
			// Mark the active day of the week
			this.weekdays.forEach(spanWeekday => {
				if (spanWeekday.className === "active") {
					spanWeekday.className = "";
				}
			});
			this.weekdays[dow].className = "active";
		}

		// Set the am/pm text:
		if (this.showAmpm) {
			this.ampm.textContent = String(date[7] + date[8]).toUpperCase();
		}
	}

	public hintColorBlue(): void {
		const min1 = document.querySelectorAll("#m1 > span");
		const min2 = document.querySelectorAll("#m2 > span");

		for (let i = 0; i < min1.length; i++) {
			const m1 = min1[i];
			const m2 = min2[i];
			m1.classList.add("tutoBlue");
			m2.classList.add("tutoBlue");
		}
	}

	public hintColorRed(type: number): void {
		if (type === 1) {
			const heures1 = document.querySelectorAll("#h1 > span");
			const heures2 = document.querySelectorAll("#h2 > span");

			for (let i = 0; i < heures1.length; i++) {
				const h1 = heures1[i];
				const h2 = heures2[i];
				h1.classList.add("tuto");
				h2.classList.add("tuto");
			}
		}
		if (type === 2) {
			const min1 = document.querySelectorAll("#m1 > span");
			const min2 = document.querySelectorAll("#m2 > span");

			for (let i = 0; i < min1.length; i++) {
				const m1 = min1[i];
				const m2 = min2[i];
				m1.classList.add("tuto");
				m2.classList.add("tuto");
			}
		}
	}
	public hintColorBlack(type: number): void {
		if (type === 1) {
			const heures1 = document.querySelectorAll("#h1 > span");
			const heures2 = document.querySelectorAll("#h2 > span");

			for (let i = 0; i < heures1.length; i++) {
				const h1 = heures1[i];
				const h2 = heures2[i];
				h1.classList.remove("tuto");
				h2.classList.remove("tuto");
			}
		}
		if (type === 2) {
			const min1 = document.querySelectorAll("#m1 > span");
			const min2 = document.querySelectorAll("#m2 > span");

			for (let i = 0; i < min1.length; i++) {
				const m1 = min1[i];
				const m2 = min2[i];
				m1.classList.remove("tuto");
				m2.classList.remove("tuto");
			}
		}
		if (type === 3) {
			const min1 = document.querySelectorAll("#m1 > span");
			const min2 = document.querySelectorAll("#m2 > span");

			for (let i = 0; i < min1.length; i++) {
				const m1 = min1[i];
				const m2 = min2[i];
				m1.classList.remove("tutoBlue");
				m2.classList.remove("tutoBlue");
			}
		}
	}
	ngOnDestroy() {
		clearInterval(this.intervalClockDigital);
	}
}
