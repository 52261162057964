import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
	OnDestroy,
	AfterViewInit,
	Output,
	SimpleChanges,
	OnChanges
} from "@angular/core";
import { ReplaySubject, Subject, Subscription, take, merge, Observable } from "rxjs";
import { GlobalService } from "../../services/global.service";
import { environment } from "../../../environments/environment";
import { AdobeComp } from "src/app/services/starting.service";
declare var AdobeAn, createjs;
@Component({
	selector: "app-progress-bar",
	templateUrl: "./progress-bar.component.html",
	styleUrls: ["./progress-bar.component.scss"]
})
export class ProgressBarComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() showSplash: boolean;
	@Input() progress;
	@Input() mode;
	@Input() adobeAnimate = false;
	@Input() adobeReadyObs: ReplaySubject<AdobeComp>;
	@Input() progressBarObs: Subject<any>;
	@Input() loaderStatus: boolean = false;
	@Input() cd: ChangeDetectorRef;
	@ViewChild("infiniteLoader") infiniteLoader: ElementRef;
	@ViewChild("canvas") canvasElement: ElementRef;
	@ViewChild("animation_container") animation_containerElement: ElementRef;
	@ViewChild("dom_overlay_container") dom_overlay_containerElement: ElementRef;

	private eventStartStopSubscription: Subscription;
	public infiniteProgress = -25;
	environment: any;
	canvas: any;
	relaunchAnim: boolean;
	anim_container: any;
	dom_overlay_container: any;
	stage: any;
	exportRoot: any;
	adobeReadyToLaunch: boolean;
	lib: any;
	adobeReadySubscribe: Subscription;
	loaderSecurityTimer: NodeJS.Timeout;
	adobeLoader = false;
	ticker: any;

	constructor(public globalService: GlobalService) {
		// subscribe to globalService loading screen triggering events:
		// console.error("progress bar constructor");
		this.environment = environment;
		this.loaderStatus = false;
		this.mode = environment.ose ? "infinite" : null;
	}

	ngOnInit() {}

	ngAfterViewInit() {
		if (!environment.ose) {
			this.initAdobeLoaderCanvas();
		}
		if (this.progressBarObs) {
			// console.error("subscribing to global loader");
			if (this.eventStartStopSubscription) {
				this.eventStartStopSubscription.unsubscribe();
			}
			this.eventStartStopSubscription = this.progressBarObs.subscribe({
				next: ({ status, adobeAnimParam, timeout }) => {
					if (status) {
						this.mode = "infinite";
						this.start(adobeAnimParam);
					} else {
						this.stop();
					}
					if (timeout) {
						console.log("%cSECURITY TIMEOUT --> STOP LOADER !!!", "background: red; color: yellow");
					}
				}
			});
			// if perf mode changed or resized notify in order to init adobe loader canvas
			merge(this.globalService.perfModeEvent, this.globalService.windowResizedEvent)
				.pipe(take(1))
				.subscribe(() => {
					this.relaunchAnim = true;
				});
		}
	}

	initAdobeLoaderCanvas() {
		if (this.adobeAnimate && !this.globalService.lowPerformanceMode) {
			this.canvas = this.canvasElement.nativeElement;
			this.anim_container = this.animation_containerElement.nativeElement;
			this.dom_overlay_container = this.dom_overlay_containerElement.nativeElement;
			if (this.adobeReadyObs) {
				this.adobeReadySubscribe = this.adobeReadyObs.subscribe(comp => {
					if (comp) {
						this.adobeReadyToLaunch = true;
						this.handleComplete(comp.evt, comp.comp);
					}
				});
			}
		}
	}

	/**
	 * Starts the Loading screen
	 */
	start(adobeAnimParam: boolean) {
		if (this.relaunchAnim) {
			this.initAdobeLoaderCanvas();
		}
		this.loaderStatus = true;
		this.globalService.globalLoading = true;
		if (this.adobeAnimate && adobeAnimParam && !this.globalService.lowPerformanceMode) {
			this.adobeLoader = true;
			if (this.exportRoot) {
				this.ticker.reset();
				this.ticker.addEventListener("tick", this.stage);
				this.exportRoot.paused = false;
			}
		} else {
			this.infiniteProgress = -25;
			this.adobeLoader = false;
			/*if (this.infiniteLoader) {
				this.infiniteLoader.nativeElement.classList.add("slideOutRight");
			}*/
		}
		clearTimeout(this.loaderSecurityTimer);
		this.setLoaderSecurityTimer();
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	/**
	 * Stops the loading screen
	 */
	stop() {
		// console.error("stop()");
		/*if (this.infiniteLoader) {
			this.infiniteLoader.nativeElement.classList.remove("slideOutRight");
		}*/
		if (this.adobeAnimate && this.exportRoot) {
			this.ticker.reset();
		}
		this.infiniteProgress = -25;
		this.adobeLoader = false;
		this.loaderStatus = false;
		console.log("STOP LOADER");
		clearTimeout(this.loaderSecurityTimer);
		this.mode = null;
		this.globalService.globalLoading = false;
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	/**
	 * Stops the Loading screen after a certain amount of time if still running
	 */
	setLoaderSecurityTimer() {
		if (this.loaderStatus) {
			console.log("START LOADER");
			this.loaderSecurityTimer = setTimeout(() => {
				//stop by using the Subject progress bar and signiify it's a timeout
				this.progressBarObs.next({ status: false, timeout: true });
				//this.stop();
				// set to 1mn:
			}, 60000);
		}
	}

	fnStartAnimation() {
		this.stage.addChild(this.exportRoot);
		this.ticker = createjs.Ticker;

		this.ticker.framerate = this.lib.properties.fps;
		// (window as any).myLoadarTicker = this.ticker;
		this.exportRoot.paused = false;
	}

	handleComplete(evt, comp) {
		//This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
		const ss = comp.getSpriteSheet();
		const queue = evt.target;
		this.lib = comp.getLibrary();
		const ssMetadata = this.lib.ssMetadata;
		for (let i = 0; i < ssMetadata.length; i++) {
			ss[ssMetadata[i].name] = new createjs.SpriteSheet({
				images: [queue.getResult(ssMetadata[i].name)],
				frames: ssMetadata[i].frames
			});
		}
		this.exportRoot = new this.lib.fuséfinal();
		this.stage = new this.lib.Stage(this.canvas);
		//Registers the "tick" event listener.

		//Code to support hidpi screens and responsive scaling.
		this.makeResponsive(true, "both", true, 2, [this.canvas, this.anim_container, this.dom_overlay_container]);
		AdobeAn.compositionLoaded(this.lib.properties.id);
		AdobeAn.bootstrapCallback(this.fnStartAnimation.bind(this));
	}

	makeResponsive = function (isResp, respDim, isScale, scaleType, domContainers) {
		this.lastS = 1;
		window.addEventListener("resize", this.resizeCanvas.bind(this));
		this.resizeCanvas(isResp, respDim, isScale, scaleType, domContainers);
	};

	resizeCanvas = function (isResp, respDim, isScale, scaleType, domContainers) {
		const w = this.lib.properties.width,
			h = this.lib.properties.height;
		const iw = window.innerWidth,
			ih = window.innerHeight;
		const pRatio = window.devicePixelRatio || 1;
		const xRatio = iw / w;
		const yRatio = ih / h;
		let sRatio = 1;
		if (isResp) {
			if ((respDim === "width" && this.lastW === iw) || (respDim === "height" && this.lastH === ih)) {
				sRatio = this.lastS;
			} else if (!isScale) {
				if (iw < w || ih < h) {
					sRatio = Math.min(xRatio, yRatio);
				}
			} else if (scaleType === 1) {
				sRatio = Math.min(xRatio, yRatio);
			} else if (scaleType === 2) {
				sRatio = Math.max(xRatio, yRatio);
			}
		}
		if (domContainers && domContainers.length > 0) {
			domContainers[0].width = w * pRatio * sRatio;
			domContainers[0].height = h * pRatio * sRatio;
			domContainers.forEach(function (container) {
				container.style.width = w * sRatio + "px";
				container.style.height = h * sRatio + "px";
			});
			this.stage.scaleX = pRatio * sRatio;
			this.stage.scaleY = pRatio * sRatio;
			this.lastW = iw;
			this.lastH = ih;
			this.lastS = sRatio;
			this.stage.tickOnUpdate = false;
			this.stage.update();
			this.stage.tickOnUpdate = true;
		}
	};

	ngOnDestroy() {
		if (this.eventStartStopSubscription) {
			this.eventStartStopSubscription.unsubscribe();
		}
		if (this.adobeReadySubscribe) {
			this.adobeReadySubscribe.unsubscribe();
		}
		clearTimeout(this.loaderSecurityTimer);
	}
}
