import { ChangeDetectorRef, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Platform, AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { BabylonIntegration } from 'src/app/models/babylon-integration';
import { AccountService } from 'src/app/services/account.service';
import { AnalyticService } from 'src/app/services/analytic.service';
import { AudioService } from 'src/app/services/audio.service';
import { CabriDataService } from 'src/app/services/cabri-data.service';
import { ClassService } from 'src/app/services/class.service';
import { GlobalService } from 'src/app/services/global.service';
import { LmsService } from 'src/app/services/lms.service';
import { LocalStorageService } from 'src/app/services/local-storage-service';
import { LrsService } from 'src/app/services/lrs.service';
import { NetworkService } from 'src/app/services/network.service';
import { PlayTTSService } from 'src/app/services/play-tts.service';
import { RemoteService } from 'src/app/services/remote.service';
import { SttService } from 'src/app/services/stt.service';
import { GameBasePageDirective } from './gamebasepage.page';

@Directive({
  selector: '[appBabylonGameBasePage]'
})
export class BabylonGameBasePageDirective extends GameBasePageDirective {
  integration : BabylonIntegration;

  constructor(public cabriService: CabriDataService,
		public lrs: LrsService,
		public globalService: GlobalService,
		public ttsService: PlayTTSService,
		public cd: ChangeDetectorRef,
		public sttService: SttService,
		public accountService: AccountService,
		public platform: Platform,
		public insomnia: Insomnia,
		public alertController: AlertController,
		public analyticService: AnalyticService,
		public router: Router,
		public networkService: NetworkService,
		public remoteService: RemoteService,
		public classService: ClassService,
		public lmsService: LmsService,
		public localStorageService: LocalStorageService,
		public audioService: AudioService) {
      super(globalService, ttsService, cd, router, accountService, platform, insomnia, alertController, lrs, cabriService);
   }


   canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			this.globalService.setGlobalLoading(true);
			this.unsubscribeMenuEventsSubscription();
			try {
				if (this.integration) {
					//test
					this.integration.showFPS(false);
					this.integration.stopRender();
					await this.integration.cleanEngine();
					this.cabriService.engineBabylon.dispose();
					this.cabriService.engineBabylon = null;
				}
			} catch (e) {
				console.log("catch error clean cabri", e);
			} finally {
				resolve(true);
			}
		});
	}
}
