import { Component, Input, OnInit } from "@angular/core";
import { CabriActivity } from "src/app/models/cabri-activity";
import { LogBookStatistics } from "src/app/models/gamification-lrs";
import { AnalyticService } from "src/app/services/analytic.service";
import { GlobalService } from "src/app/services/global.service";
import { LmsService } from "src/app/services/lms.service";

@Component({
	selector: "app-log-book-statistics-text",
	templateUrl: "./log-book-statistics-text.component.html",
	styleUrls: ["./log-book-statistics-text.component.scss"]
})
export class LogBookStatisticsTextComponent implements OnInit {
	@Input() logBook: LogBookStatistics;
	@Input() index: number;
	constructor(public globalService: GlobalService, public lmsService: LmsService, public analyticService: AnalyticService) {}

	ngOnInit() {
	}
}
