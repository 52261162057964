import { Injectable, ChangeDetectorRef, NgZone, Renderer2 } from "@angular/core";
import { CabriActivity } from "../models/cabri-activity";
import { Student } from "../models/student";
import { Activity } from "../models/activity";
import { Param } from "../models/params/param";
import { SelectParam } from "../models/params/select-param";
import { CheckParam } from "../models/params/check-param";
import { RangeParam } from "../models/params/range-param";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "../services/global.service";
import { AccountService } from "./account.service";
import { environment } from "../../environments/environment";
import { CategorizeStatus, Exercices, Grade } from "../models/exercices";
import { BaseService } from "./base.service";
import { BehaviorSubject, Subject, lastValueFrom, catchError,map } from "rxjs";
import { LrsService } from "./lrs.service";
import { ConnectionStatus, NetworkService } from "./network.service";
import { Router } from "@angular/router";
import { StartingService } from "./starting.service";
import { CabriLoader } from "../models/cabri-loader";
import { Engine } from "@babylonjs/core";
import { StoryService } from "./story.service";
import { LmsService } from "./lms.service";
import { SafariService } from "./safari.service";
import { BadgeService } from "./badge.service";
import { VideosService } from "./videos.service";
import { LocalStorageService, StorageKey, StorageKeyUnused } from "./local-storage-service";
import { Exercise } from "../models/exercise";
import { AppUtils } from "../app-utils";
import { AppLanguage } from "../models/enums/enum-list";
import { ClassService } from "./class.service";
import { OseActivity } from "../models/ose-activities";
import { Coloring } from "../components/coloring/coloring.component";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { Memory } from "../pages/memory/memory.page";
export enum journeyType {
	byAssignation = "assignation",
	byJourney = "journey",
	both = "both"
}
@Injectable({
	providedIn: "root"
})
export class CabriDataService extends BaseService {
	fps: any;
	mirrorMode: boolean;
	remoteWaitingScreen: boolean;
	inputMethodTutoRunning: boolean;
	/** All journeys with no filter */
	cabriLoader: CabriLoader;
	engineBabylon: Engine;
	resoproData: any;
	forceBackgroundTexture: any;
	offerToRestartActivity = true;
	public categories: CategorizeStatus = {
		[Grade.cp]: {},
		[Grade.ce1]: {},
		[Grade.ce2]: {},
		[Grade.cm1]: {},
		[Grade.cm2]: {}
	};
	fiches: Array<{ id: any; title: string; lastmodified: string;titre_sommaire:string }>;
	quizz: any;
	questions: any;
	currentOseActivity: OseActivity;
	coloring: Array<Coloring>;
	spotDifference: Array<Coloring>;
	tooltipHelps: any;
	memorys: Array<Memory>;
	puzzles
	wordSearchs: any;
	territoires: any;
	constructor(
		public http: HttpClient,
		private globalService: GlobalService,
		public accountService: AccountService,
		public classService: ClassService,
		public ngZone: NgZone,
		public router: Router,
		public startingService: StartingService,
		public storyService: StoryService,
		public lmsService: LmsService,
		public safariService: SafariService,
		public badgeService: BadgeService,
		public videosService: VideosService,
		public networkService: NetworkService,
		public localStorageService: LocalStorageService
	) {
		super();
		this.activities = new Array();
		if (!environment.ose) {
			this.getAllActivities();
		} else {
			// this.lmsService._globalJourneys = [];
			this.lmsService.allActivitiesLoaded.next(true);
			this.lmsService.allJourneyLoaded = true;
		}
		this.cd = null;
		this.toolbarHeight = this.globalService.toolbarHeight;
		this.isMobile = this.globalService.isMobile;
		this.toggleCabriInputMethodEvent = new Subject<string>();
		this.waitingUserActionEvent = new Subject<boolean>();
		this.waitTutoChoiceEvent = new Subject<boolean>();
		this.allowInputMethodChange = false;
		this.accountService.setCabriService(this);
	}
	public activityStart = false;
	public currentActivity: CabriActivity;
	public nextActivity: CabriActivity;

	// Journey :

	// Modal student modal selected
	// redefine student journeys when network status change after getting all activities

	public currentSavedDom: string;
	public currentSavedActivityId: string;
	public userIdChosenForEx: number;
	progressBarCurrentUser: Student;
	// public allStudents: Student[] = new Array();
	// public loadGabaritStudentInfos = false;
	public lrs: LrsService;

	public activities: CabriActivity[];
	public activitiesCopy: CabriActivity[];
	public allParams: any;
	public hideMathia: boolean;
	public holoMode: string;
	public savedDomHoloMode: string;
	public savedDomMirrorMode: boolean;
	// inputMethods:
	public inputMethod: any;
	// cabri input method:
	public toggleCabriInputMethodEvent: Subject<string>;
	public cabriInputNumpadStatus: boolean;
	public cabriInputDrawStatus: boolean;
	public cabriInputSttStatus: boolean;
	public allowInputMethodChange: boolean;
	// waitingUserAction on firstTime:
	// public waitingUserActionEvent: Subject<any>;
	public waitingUserActionEvent: Subject<string | boolean>;

	public toCabri = false;
	public waitTutoChoiceEvent: Subject<boolean>;

	public exempleArray: { name: string; value: string }[];
	// public team: Student[];
	private cd: ChangeDetectorRef;
	public participantAnswer: any;

	// awards:
	public teamShootingStarsCount: number;
	public teamNormalStarsCount: number;
	public teamMoonsCount: number;
	public teamTotalAwards: number;

	public toolbarHeight: number;
	public isMobile: boolean;
	public smallOperationHelp = false;
	public exercices: Exercices;
	public exerciceId: number;
	public unitsHelp: boolean;
	public currentExercice: Exercise;
	public nb1U;
	public nb1D;
	public nb1C;
	public nb1M;
	public nb2U;
	public nb2D;
	public nb2C;
	public nb2M;
	public resU;
	public resD;
	public resC;
	public resM;
	public nb1Units;
	public nb1Tens;
	public nb1Hundreds;
	public nb1Thousands;
	public nb2Units;
	public nb2Tens;
	public nb2Hundreds;
	public nb2Thousands;
	public resUnits;
	public resTens;
	public resHundreds;
	public resThousands;
	public nb1;
	public nb2;
	public result;
	/* tslint:disable */
	public nb1U_TTS;
	public nb1D_TTS;
	public nb1C_TTS;
	public nb1M_TTS;
	public nb2U_TTS;
	public nb2D_TTS;
	public nb2C_TTS;
	public nb2M_TTS;
	public resU_TTS;
	public resD_TTS;
	public resC_TTS;
	public resM_TTS;
	progressionBarStatus: BehaviorSubject<any> = new BehaviorSubject(false);

	// furet Holo:
	public displayHoloEndResultsButtonsCM: boolean;

	public allActivitiesLoaded = false;

	public castleLeft: boolean;
	// jeu de l'heure:
	public clockLeft = false;
	public clockType: string;
	// calcul mental / denombrement variables for ngConditions:
	public operationWrapperdisplayed: boolean;
	public moonResultCollection: boolean;
	public collectionModeInCM: boolean;
	public diceModeInCM: boolean;
	public fingersModeInCM: boolean;
	public lettersModeInCM: boolean;
	public coinsModeInCM: boolean;

	public settingZeroModeNumpadAllowed = false;
	public numpadAllowed = true;
	public drawingAllowed = true;
	public sttAllowed = true;
	public categoryNamesOrdered = {};

	setSelectedActivity(activity) {
		this.currentActivity = activity;
		this.currentActivity.buildVariables();
	}

	defineExercice(selectedActivity) {
		selectedActivity.params.find(item => {
			if (item.exercices) {
				if (Array.isArray(item.defaultValue)) {
					this.currentExercice = item.defaultValue[0];
				} else {
					this.currentExercice = item.defaultValue;
				}
				this.exerciceId = this.currentExercice.id;
				localStorage.setItem("exerciceId", String(this.exerciceId));
			}
		});
	}

	getActivityById(id): Activity {
		return this.activities.find(activity => activity.id === id);
	}

	public detectChanges() {
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	public setChangeDetector(cd: ChangeDetectorRef) {
		this.cd = cd;
	}

	async getAllActivities() {
		this.http
			.get<Activity[]>(this.postUrl + "?action=app_mathia_get_gabarits2&version=" + environment.activityVersion)
			.pipe(
				catchError(async err => {
					console.error("activities not loaded", err);
					this.networkService.errorOccured = true;
					if (this.networkService.isConnected) {
						this.networkService.updateNetworkStatus(ConnectionStatus.Offline,true);
					}
					// Recover saved activities no matter if server or connection lost problem
					const getActivities = await this.localStorageService.get(StorageKey.activitiesList);
					// activities
					return getActivities
						? getActivities
						: ((await lastValueFrom(this.http.get("../assets/json/activities.json"))) as CabriActivity[]);
				})
			)
			.subscribe({
				next: async (activitiesList: Activity[]) => {
					this.activities = new Array();
					for (const activityId in activitiesList) {
						if (activitiesList.hasOwnProperty(activityId)) {
							const activity = activitiesList[activityId];
							if (this.safariService.onSafari("Safari")) {
								// disable lecture activity on safari and mobileweb
								if (Number(activity.id) === 2) {
									continue;
								}
							}
							let currentActivity;
							if (activity.routingPath && activity.routingPath !== "/activities") {
								currentActivity = new CabriActivity();
							} else {
								currentActivity = new Activity();
							}
							if(this.networkService.isConnected){
								const fileExist = await AppUtils.doesFileExist(activity.customThumbnail);
								if (!fileExist) {
									// change gabarit image if not yet existing
									activity.customThumbnail = activity.thumbnail;
								}
							}
							currentActivity = Object.assign(currentActivity, activity);
							currentActivity.selected = false;
							const currentParams: Param[] = new Array();
							for (const paramId in currentActivity.params) {
								if (currentActivity.params.hasOwnProperty(paramId)) {
									let param = currentActivity.params[paramId];
									if (paramId === "holo" && this.globalService.isKidaia && this.globalService.locale === AppLanguage.EN) {
										// hide holo param from modal in Kidaia english
										currentActivity._params.holo.hidden = true;
										currentActivity._params.holo.value = "0";
									}
									if (param.name != "play") {
										const typeParam = this.typeParam(param);
										currentParams.push(typeParam);
									}
								}
							}
							currentActivity.params = currentParams;
							this.activities.push(currentActivity);
						}
					}
					this.activities = this.activities.sort((a, b): number => {
						return a.order - b.order;
					});
					await this.getAllExercices();
					// récupérer token trala + get all journeys
					if (this.accountService.logReadyPromise) {
						try {
							await this.accountService.logReadyPromise;
						} catch (err) {
							console.log("error login promise");
						}
					} else {
						try {
							await this.classService.getSessionToken();
						} catch (err) {
							console.error("err trala login");
						}
					}
					await this.lmsService.getAllJourneys(this);
					this.lmsService.allActivitiesLoaded.next(true);
					if (!this.accountService.isRealUserConnected) {
						this.lmsService.exerciseStatisticsLoaded.next(true);
					}
					if (this.networkService.errorOccured) {
						this.filterActivityOffline();
					} else {
						this.localStorageService.set(StorageKey.activitiesList, activitiesList);
					}
					// Remove unused activities
					this.localStorageService.remove(StorageKeyUnused.activities);
					this.networkService.errorOccured = false;
				},
				complete: () => {}
			});
	}

	/**
	 * Détermine le type de paramètre
	 * @param param
	 */
	typeParam(param) {
		if (param.type && param.type === "selection") {
			param = new SelectParam(param);
		} else if (param.type && param.type === "checkbox") {
			param = new CheckParam(param);
		} else if (param.type && param.type === "range") {
			param = new RangeParam(param);
		} else {
			param = new Param(param);
		}

		return param;
	}

	/**
	 * Permet de ne pas afficher certaines valeurs d'un paramètre
	 * @param {string} paramName Le nom du paramètre à filtrer
	 * @param {string | Array} itemToKeep Les valeurs à filtrer
	 */

	public paramsToKeep(paramName, itemToKeep) {
		if (!this.activities) {
			return;
		}

		this.activities.forEach(activity => {
			activity.params.forEach(param => {
				if (param.name === paramName) {
					/** Filtrer s'il y a plusieurs valeurs(Array) */
					if (Array.isArray(itemToKeep)) {
						let selectionListIndexes = param.selectionList
							.map((list, idx) => {
								let listToKeep = list.some(currentList => {
									let currentResult = itemToKeep.some(item => {
										return currentList === item;
									});
									return currentResult;
								});
								if (listToKeep) {
									return idx;
								}
							})
							.filter(index => {
								return index != undefined;
							});
						let currentArray = new Array();
						if (selectionListIndexes.length > 0) {
							selectionListIndexes.forEach(arrayIndexes => {
								let result = param.selectionList.filter((list, index) => {
									return index === arrayIndexes;
								});
								currentArray.push(result[0]);
							});
						} else {
							throw `Le paramètre ${paramName} n'a pas de valeur associée`;
						}
						if (currentArray.length > 0) {
							currentArray[0][3] = false;
							param.selectionList = currentArray;
							param.defaultValue = param.selectionList[0];
							param.value = param.selectionList[0][1];
						}
					} else {
						/** Filtrer quand il y a qu'une seule valeur(string) */
						const pos = param.selectionList.findIndex(list => {
							let pos = list.some(currentList => currentList === itemToKeep);
							return pos;
						});

						if (pos > -1 && param.selectionList.length > 1) {
							const temp = param.selectionList[pos];
							temp[3] = false;
							param.selectionList = new Array();
							param.selectionList.push(temp);

							param.defaultValue = temp;
							if (param.selectionList[0]) {
								param.value = param.selectionList[0][1];
							}
						}
					}
				}
			});
		});
	}

	/**
	 * Supprimer une activité existante
	 * @param {string} activityName Le nom de l'activité
	 */

	public filterActivity(activityName) {
		return new Promise((resolve, reject) => {
			if (!this.activities) {
				return;
			}
			this.activities = this.activities.filter(activity => {
				return activity.name.toLowerCase() !== activityName.toLowerCase();
			});
			resolve(true);
		});
	}

	/**
	 * Leave activity page
	 */
	leaveActivityPage() {
		this.lmsService.currentUserJourney = null;
		if (LrsUtils.assignationId) {
			LrsUtils.assignationId = undefined;
		}
		this.router.navigateByUrl("gabarits", { replaceUrl: true });
	}

	setLrsService(lrsService: LrsService) {
		this.lrs = lrsService;
	}

	public filterActivityOffline() {
		this.filterActivity("lecture");
		this.paramsToKeep("input-method", ["numpad", "drawing"]);
		this.paramsToKeep("remoteHost", "0");
		this.paramsToKeep("holo", ["0", "2"]);
	}

	filterOfflineExerciseCategoryByTermId(term_id: number) {
		if (!this.networkService.isConnected) {
			this.exercices.allCategories = this.exercices.allCategories.filter(ex => {
				return Number(ex.term_id) !== Number(term_id);
			});
		}
	}

	/**
	 * Modify exercise value if it's not defined or it's defined throught param and change selection list pf exercise according to the grade
	 */
	defineExercices(defaultValue = true) {
		this.activities.forEach(item => {
			item.params.find(param => {
				if (param.exercices) {
					const listExercices = this.exercices.filter(item.id);
					if (defaultValue || typeof param.defaultValue === "undefined") {
						param.defaultValue = listExercices[0];
					}
					param.selectionList = listExercices;
				}
			});
		});
	}

	changeCategoryExerciseParamValue(categoryKeyName: string, exerciseByCategory, activityId: number | string) {
		if (exerciseByCategory?.items) {
			const exerciseToDo = this.selectFirstCategoryNotDoneExercise(exerciseByCategory.items);
			if (exerciseToDo) {
				const currentActivity = this.activities.find(act => {
					return Number(act.id) === Number(activityId);
				});
				currentActivity.setParamValue("ex", exerciseToDo);

				const selectedCategoryExercise = this.categories[this.exercices.selected][categoryKeyName].items.find(ex => {
					return exerciseToDo.id === ex.exercise.id;
				});
				selectedCategoryExercise.selected = true;
			}
		}
	}

	/**
	 * Select first not done exercise of category selected
	 */
	selectFirstCategoryNotDoneExercise(categoryExercises: any[]) {
		let defaultNotDoneExerciseValue;
		// exclude percent of good answer of 0 value
		for (let progression in this.accountService.exerciseStatistics?.exercisePerGoodAnswer) {
			if (progression && this.accountService.exerciseStatistics.exercisePerGoodAnswer[progression]?.result === 0) {
				delete this.accountService.exerciseStatistics.exercisePerGoodAnswer[progression];
			}
		}

		// keep the first exercise not done from list of exercises
		for (let item of categoryExercises) {
			if (!this.accountService.exerciseStatistics.exercisePerGoodAnswer.hasOwnProperty(item.exercise.id)) {
				defaultNotDoneExerciseValue = item.exercise;
				break;
			}
		}

		if (!defaultNotDoneExerciseValue && categoryExercises?.length > 0) {
			// all exercises of this category had been done so select the first one
			defaultNotDoneExerciseValue = categoryExercises[0].exercise;
		}

		return defaultNotDoneExerciseValue;
	}

	getAllExercices(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			return this.http
				.get<any>(this.postUrl + "?action=app_mathia_get_exercices2&version=" + environment.activityVersion)
				.pipe(
					catchError(async err => {
						const getExercises = await this.localStorageService.get(StorageKey.exercises);
						return getExercises ? getExercises : await lastValueFrom(this.http.get("../assets/json/exercises.json"));
					})
				)
				.subscribe({
					next: async (exercices: Exercise[]) => {
						this.exercices = new Exercices([...exercices], this.globalService, this);
						this.exercices.extractExerciseIdFromTitle();

						this.exercices.allCategories = await lastValueFrom(
							this.http.get("https://mathia.education?action=app_mathia_get_ordered_categories").pipe(
								catchError(async err => {
									const categoriesStored = await this.localStorageService.get(StorageKey.allCategories);
									return categoriesStored && categoriesStored !== "null"
										? categoriesStored
										: await lastValueFrom(this.http.get("/assets/json/categories.json"));
								})
							)
						);
						this.activities.find(item => {
							const exObject = this.createExerciceObject(item);
							if (exObject && exObject.selectionList && exObject.selectionList.length > 0) {
								const pos = item.params.findIndex(param => {
									return param.exercices;
								});
								// Avoid to push same exercices in params
								if (pos === -1) {
									item.params.push(this.createExerciceObject(item));
									item.hasExercice = true;
								}
							}
						});
						this.allActivitiesLoaded = true;
						if (!this.networkService.errorOccured) {
							this.localStorageService.set(StorageKey.exercises, exercices);
							this.localStorageService.set(StorageKey.allCategories, this.exercices.allCategories);
						}

						resolve();
					},
					error: err => {
						console.error("error catch", err);
					}
				});
		});
	}

	getCurrentExercice() {
		this.currentExercice = this.exercices.getExercise(this.exerciceId);
		return this.currentExercice;
	}

	createExerciceObject(item) {
		const exercices = this.exercices.filter(item.id);
		return {
			className: "SelectParam",
			defaultValue: [exercices[0]],
			finished: false,
			destination: "CabriParam",
			hidden: false,
			dynamic: false,
			label: $localize`Exercices`,
			name: "ex",
			selectionList: exercices,
			tooltip: "",
			value: "0",
			exercices: true,
			config: false,
			onChange: Function
		};
	}

	async setActivityId(aid: any) {
		return new Promise<boolean>(async (resolve, reject) => {
			// set exercice ID
			this.exerciceId = Number(aid);
			localStorage.setItem("exerciceId", String(this.exerciceId));

			// wait activities ready
			await this.getAllActivitiesAsync();

			// search and set activity
			let activityFound = false;
			this.activities.every(item => {
				this.exercices.getAll().every(exo => {
					if (Number(exo.id) === this.exerciceId && exo.gabarit == item.id) {
						this.currentExercice = this.exercices.getExercise(this.exerciceId);
						item.exercicesParam.defaultValue = this.currentExercice;
						if (!this.currentActivity || this.currentActivity === undefined || this.currentActivity.id !== item.id) {
							this.setSelectedActivity(item);
						}
						this.currentActivity.setParamValue("ex", this.currentExercice);
						activityFound = true;
					}
					return !activityFound;
				});
				return !activityFound;
			});
			if (activityFound) {
				resolve(true);
			} else {
				reject("Activity not found. Id = " + aid);
			}
		});
	}

	async getSavedActivity() {
		return new Promise(async resolve => {
			if (!this.allActivitiesLoaded) {
				while (!this.allActivitiesLoaded) {
					await AppUtils.timeOut(100);
				}
			}
			const savedActivity = JSON.parse(localStorage.getItem("savedActivity"));
			const exerciceId = localStorage.getItem("exerciceId");
			if (exerciceId && exerciceId != "") {
				this.setActivityId(exerciceId);
			}

			if (savedActivity) {
				try {
					const activity = new CabriActivity();
					activity.restoreActivity(savedActivity);
					activity.buildVariables();
					this.setSelectedActivity(activity);
					this.defineExercice(activity);
					if (exerciceId && exerciceId != "") {
						this.exerciceId = Number(exerciceId);
						this.currentExercice = this.getCurrentExercice();
					}
				} catch (e) {
					this.currentActivity = null;
					if (exerciceId && exerciceId != "") {
						this.exerciceId = Number(exerciceId);
					}
					console.log(e);
				}
			} else {
				this.currentActivity = null;
			}

			resolve(savedActivity);
		});
	}

	getAllActivitiesAsync(): Promise<any> {
		return new Promise(async resolve => {
			if (this.allActivitiesLoaded) {
				resolve(true);
			} else {
				this.lmsService.allActivitiesLoaded.subscribe({
					next: () => {
						resolve(true);
					},
					error: err => {
						console.error("error while loading activities", err);
					}
				});
			}
		});
	}

	/**
	 * force numpad mode for exercices if needed.
	 */
	checkExerciceResponseMode() {
		if (this.currentExercice.responseMode && this.currentExercice.responseMode === "numpad" && this.holoMode === "0") {
			this.currentActivity.setParamValue("input-method", "numpad");
		}
	}

	resetTeamStudentsScore() {
		this.accountService.team.forEach(student => {
			student.awardsCurrent = { shooting: 0, normal: 0, moons: 0, total: 0 };
		});
		console.log("%c STUDENTS SCORE RESET", "background: black; color: red");
	}

	resetTeamScore() {
		this.teamShootingStarsCount = 0;
		this.teamNormalStarsCount = 0;
		this.teamMoonsCount = 0;
		this.teamTotalAwards = 0;
		console.log("%cTEAM SCORE RESET", "background: black; color: red");
	}

	getHostActivityInfo(): { activity: string; participants: string[] } {
		const participants = new Array<string>();
		this.accountService.team.forEach(student => {
			participants.push(student.name);
		});
		return { activity: this.currentActivity.id, participants };
	}

	// set variables used to calculate mascotte & operationWrapper positions for "les nombres autrement"
	initNombresAutrementVariables(value = null) {
		if (value) {
			if (value === "collection") {
				this.collectionModeInCM = true;
				this.diceModeInCM = false;
				this.fingersModeInCM = false;
				this.lettersModeInCM = false;
				this.coinsModeInCM = false;
			} else if (value === "dice") {
				this.collectionModeInCM = false;
				this.diceModeInCM = true;
				this.fingersModeInCM = false;
				this.lettersModeInCM = false;
				this.coinsModeInCM = false;
			} else if (value === "fingers") {
				this.collectionModeInCM = false;
				this.diceModeInCM = false;
				this.fingersModeInCM = true;
				this.lettersModeInCM = false;
				this.coinsModeInCM = false;
			} else if (value === "letters") {
				this.collectionModeInCM = false;
				this.diceModeInCM = false;
				this.fingersModeInCM = false;
				this.lettersModeInCM = true;
				this.coinsModeInCM = false;
			} else if (value === "coins") {
				this.collectionModeInCM = false;
				this.diceModeInCM = false;
				this.fingersModeInCM = false;
				this.lettersModeInCM = false;
				this.coinsModeInCM = true;
			}
		}
	}

	getResoProData() {
		return new Promise<void>((resolve, reject) => {
			this.http
				.get(this.postUrl + "?action=app_mathia_resopro")
				.pipe(
					catchError(async err => {
						return await lastValueFrom(this.http.get("../assets/json/gabarits/resoproData.json"));
					})
				)
				.subscribe({
					next: async (probleme: any) => {
						probleme.listes.forEach(element => {
							if (element.list_values) {
								element.list_values = element.list_values.split("\r\n");
							}
						});
						this.resoproData = probleme;
						resolve();
					}
				});
		});
	}

	getAllQuizz() {
		return new Promise<any>((resolve, reject) => {
			if (this.quizz) {
				resolve(this.quizz);
			} else {
				this.http
					.get<any>(this.postApiKidaia + "?action=getQuizz")
					.pipe(
						catchError(async err => {
							console.error(err);
						})
					)
					.subscribe({
						next: async data => {
							this.quizz = data;
							this.quizz.forEach((quizz, index) => {
								this.quizz[index].id = Number(this.quizz[index].id);
							});
							resolve(data);
						}
					});
			}
		});
	}

	getColoring(): Promise<Coloring[]> {
		return new Promise<Coloring[]>((resolve, reject) => {
			if (this.coloring) {
				resolve(this.coloring);
			} else {
				this.http
					.get<any>(this.postApiKidaia + "?action=getColoring")
					.pipe(
						catchError(async err => {
							console.error(err);
						})
					)
					.subscribe({
						next: async data => {
							data.forEach((coloring, index) => {
								data[index].id = Number(data[index].id);
							});
							this.coloring = data;
							resolve(this.coloring);
						}
					});
			}
		});
	}


	/**
	 * Seven Errors data
	 */
	getSpotDifference(): Promise<Coloring[]> {
		return new Promise<Coloring[]>((resolve, reject) => {
			if (this.spotDifference) {
				resolve(this.spotDifference);
			} else {
				this.http
					.get<any>(this.postApiKidaia + "?action=get7errors")
					.pipe(
						catchError(async err => {
							console.error(err);
						})
					)
					.subscribe({
						next: async data => {
							data.forEach((spotDifference, index) => {
								data[index].id = Number(data[index].id);
							});
							this.spotDifference = data;
							resolve(this.spotDifference);
						}
					});
			}
		});
	}

	getAllQuestions() {
		return new Promise<any>((resolve, reject) => {
			if (this.questions) {
				resolve(this.questions);
			} else {
				this.http
					.get<any>(this.postApiKidaia + "?action=getQuestions")
					.pipe(
						catchError(async err => {
							console.error(err);
						})
					)
					.subscribe({
						next: async data => {
							this.questions = data;
							resolve(data);
						}
					});
			}
		});
	}
	getFiche(idFiche, updateCache = false) {
		return new Promise<string>(async (resolve, reject) => {
			try {
				const ficheDate = await this.getFicheDate(idFiche);
				const fiche = await this.localStorageService.get('fiche-html-'+idFiche+'-'+ficheDate);
				if(!fiche || fiche === ''){
					this.localStorageService.deleteAllStartingWith('fiche-html-'+idFiche);
					
				}
				// console.log(fiche);
				if (fiche && !updateCache){
					resolve(fiche);
				} else {
					this.http.get(this.postApiKidaia + "?action=getFiche&fiche_id=" + idFiche, { responseType: "text" }).subscribe({
						next: (data: string) => {
							this.localStorageService.set('fiche-html-'+idFiche+'-'+ ficheDate, data);
							resolve(data);
						},
						error: e => {
							reject(e);
						}
					});
				}
			} catch(e) {
				console.error(e);
				throw new Error ("error getting fiche");
			} 
			
		});
	}
	getAllFiches() {
		return new Promise<any>((resolve, reject) => {
			if (this.fiches) {
				resolve(this.fiches);
			} else {
				this.http.get(this.postApiKidaia + "?action=getFiches").subscribe({
					next: data => {
						this.fiches = data as any;
						resolve(data);
					},
					error: e => {
						reject(e);
					}
				});
			}
		});
	}


	getAllTerritoires() {
		return new Promise<any>((resolve, reject) => {
			if (this.territoires) {
				resolve(this.territoires);
			} else {
				this.http.get(this.postApiKidaia + "?action=getTerritoires").subscribe({
					next: data => {
						this.territoires = data as any;
						resolve(data);
					},
					error: e => {
						reject(e);
					}
				});
			}
		});
	}
	getAllWordSearch() {
		return new Promise<any>((resolve, reject) => {
			if (this.wordSearchs) {
				resolve(this.wordSearchs);
			} else {
				this.http.get(this.postApiKidaia + "?action=getWordSearch").subscribe({
					next: (data: Array<any>) => {

						this.wordSearchs = data.map( e => {
							const wordRegEx = new RegExp(/<p>([^<]*)<\/p>/, 'g');
							const list = [];
							for(const m of (e.list as string).matchAll(wordRegEx)){
								list.push(m[1]);
							}
							e.list = list;
							return e;
						}) as any;

						console.log(this.wordSearchs);
						resolve(this.wordSearchs);
					},
					error: e => {
						reject(e);
					}
				});
			}
		});
	}
	getMemory() {
		return new Promise<any>((resolve, reject) => {
			if(this.memorys){
				resolve(this.memorys);
			}else{
			this.http.get(this.postApiKidaia + "?action=getMemory").subscribe({
				next: (data: any) => {
					data.forEach((item, index) => {
						data[index].id = Number(data[index].id);
					});
					this.memorys = data
					resolve(this.memorys);
				},
				error: e => {
					reject(e);
				}
			});
			}
		});
	}
	getPuzzles() {
		return new Promise<any>((resolve, reject) => {
			if(this.puzzles){
				resolve(this.puzzles)
			}else{
				this.http.get(this.postApiKidaia + "?action=getPuzzle").pipe(map((puzzles:any) => {
					puzzles.forEach((puzzle) => {
						if(puzzle){
							puzzle.id = Number(puzzle.id);
						}
					});
					this.puzzles = puzzles;
					resolve(this.puzzles);
				})).subscribe({
					next: (data) => {
						resolve(data);
					},
					error: e => {
						reject(e);
					}
				});
			}

		});
	}

	async getFicheTitle(idFiche: Number) {
		await this.getAllFiches();
		const fiche = this.fiches.find(f => Number(f.id) === Number(idFiche));
		return fiche.title;
	}
	async getFicheDate(idFiche: Number) {
		await this.getAllFiches();
		const fiche = this.fiches.find(f => Number(f.id) === Number(idFiche));
		return fiche.lastmodified;
	}

	getTooltipHelps() {
		return new Promise<any>((resolve, reject) => {
			if (this.tooltipHelps) {
				resolve(this.tooltipHelps);
			} else {
				this.http.get(this.postApiKidaia + "?action=getTooltipHelp").subscribe({
					next: data => {
						this.tooltipHelps = data ? data : [];
						resolve(data);
					},
					error: e => {
						this.tooltipHelps = [];
						resolve([]);
					}
				});
			}
		});
	}

	getTooltipHelp(title): {id: string, title: string, content: string, feedback: string } {
		return this.tooltipHelps.find(tooltip => tooltip.title.toLowerCase().trim() === title.toLowerCase().trim());
	}
}
