import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

export enum HttpErrorStatus {
	InternalServerError = 500
}
@Injectable({
	providedIn: "root"
})
export class BaseService {
	constructor() {}
	tralaError: boolean;
	postUrl = "https://mathia.education";
	postApiMathia = "https://api.mathia.education";
	postTralalereUrl = "https://lms-mathia.api.tralalere.com/api";
	postPreprodTralalereUrl = "https://preprod.lms-mathia.api.tralalere.com/api";
	postApiKidaia = "https://api.kidaia.com/wp-admin/admin-ajax.php";
	postApiKidaiaLoggedIn = "https://api.kidaia.com/wp-admin/admin-ajax.php";
	postKidaia = "https://kidaia.com/wp-admin/admin-ajax.php";
	postOpenSteam = "https://dev-lms.kidaia.com/routing/Routing.php";
	siteKidaia = "https://kidaia.com";

	handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError(() => new Error("Something bad happened; please try again later."));
	}
}
