import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit, ElementRef, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Student } from "src/app/models/student";
import { Platform } from "@ionic/angular";
import { PlayTTSService } from "../../services/play-tts.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { LrsService } from "src/app/services/lrs.service";
import { GlobalService } from "../../services/global.service";
import { Router } from "@angular/router";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { environment } from "src/environments/environment";
import { LmsService } from "src/app/services/lms.service";
import { AccountService } from "src/app/services/account.service";
import { ProposedActivity, Status } from "src/app/models/proposed-activity";

@Component({
	selector: "app-mathia-calcul-mental",
	templateUrl: "./mathia-calcul-mental.component.html",
	styleUrls: ["./mathia-calcul-mental.component.scss"]
})
export class MathiaCalculMentalComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input("award") award: string;
	@Input("activity") activity: string;
	@Input("summary") results: boolean;
	@Input("currentUser") currentUser: Student;
	@Input("eventMessage") eventMessage: Observable<void>;
	@Input("help") help: boolean;
	@Input("holoMode") holoMode: string;
	@Input("mathiaSpeechButtonText") mathiaSpeechButtonText = $localize`C'est parti !`;
	@Input("hideBubbleText") hideBubbleText: boolean;
	@Input("writeOperationOutEnabled") writeOperationOutEnabled: boolean;
	@Output() mathiaMessage: EventEmitter<string> = new EventEmitter<string>();
	@Output() validation = new EventEmitter<boolean>();
	@Output() skipSequence = new EventEmitter<any>();
	@Output() restart = new EventEmitter();
	@Output() nextActivityEvent = new EventEmitter();

	public mathiaMessageSimple: string;
	private environment: { production: boolean; activityVersion: number; kidaia: boolean; bearerToken: string };

	constructor(
		public platform: Platform,
		private playTTSService: PlayTTSService,
		public cabriService: CabriDataService,
		public lrs: LrsService,
		public globalService: GlobalService,
		private router: Router,
		public lmsService: LmsService,
		public accountService: AccountService
	) {
		this.environment = environment;
	}
	// TODO: animate color on click:
	@ViewChild("validateButtonMirror") validateButtonMirror: ElementRef;
	@ViewChild("refuseButtonMirror") refuseButtonMirror: ElementRef;
	private eventMessageSubscription: Subscription;

	public messagesValidationOCR;
	public indexValidationOCR;
	public messagesValidationSTT;
	public indexValidationSTT;
	public unitsHelpMessage;
	public indexunitsHelpMessage;
	public unitsHelpMessageTTS;
	public indexunitsHelpMessageTTS;
	public validationUnitsHelpMessage;
	public indexValidationUnitsHelpMessage;
	public unitsHelpMessageConfirmed;
	public indexUnitsHelpMessageConfirmed;
	public inputMethodTutorialMessagesNoMic;
	public indexInputMethodTutorialMessagesNoMic;
	public inputMethodTutorialMessages;
	public indexinputMethodTutorialMessages;
	public messagesNoMic;
	public indexMessagesNoMic;

	public resultsMessage;
	public indexResultsMessage;
	public help2NumberSeparation;

	public displayValidationButtons: boolean;
	public answerValidated: boolean;
	awardsDisplayedPosition = "";
	ngOnInit() {
		this.resultsMessage = [];
		this.messagesValidationOCR = [
			$localize`As-tu dessiné le nombre ${this.cabriService.participantAnswer}:number: ?`,
			$localize`tu as bien dessiné le nombre ${this.cabriService.participantAnswer}:number: ?`
		];
		this.messagesValidationSTT = [];
		this.unitsHelpMessage = [];
		this.unitsHelpMessageTTS = [];
		this.validationUnitsHelpMessage = [];
		this.unitsHelpMessageConfirmed = [];
	}

	ngAfterViewInit() {
		this.subscribeEvent();
	}

	subscribeEvent() {
		this.eventMessageSubscription = this.eventMessage.subscribe((value: any = null) => {
			if (!value) {
				this.updateIndex();
				this.eventMessageSubscription.unsubscribe();
				this.subscribeEvent();
				this.allAwardsObtained() ? (this.awardsDisplayedPosition = "column") : (this.awardsDisplayedPosition = "row");
			} else {
				this.mathiaMessageSimple = value;
			}
		});
	}

	updateIndex() {
		let mathiaMessage = "";
		if (this.award === "validation_ocr_filled") {
			this.messagesValidationOCR = [
				$localize`As-tu dessiné le nombre ${this.cabriService.participantAnswer}:number: ?`,
				$localize`tu as bien dessiné le nombre ${this.cabriService.participantAnswer}:number: ?`
			];
			this.indexValidationOCR = Math.floor(Math.random() * this.messagesValidationOCR.length);
			mathiaMessage = this.messagesValidationOCR[this.indexValidationOCR];
		} else if (this.award === "validation_stt") {
			this.messagesValidationSTT = [
				$localize`As-tu bien prononcé ${this.cabriService.participantAnswer}:number: ?`,
				$localize`tu as bien dit ${this.cabriService.participantAnswer}:number: ?`,
				$localize`Ai-je bien entendu ${this.cabriService.participantAnswer}:number: ?`,
				$localize`As-tu bien dit ${this.cabriService.participantAnswer}:number: ?`
			];
			this.indexValidationSTT = Math.floor(Math.random() * this.messagesValidationSTT.length);
			mathiaMessage = this.messagesValidationSTT[this.indexValidationSTT];
		} else if (this.award === "validation_ampm") {
			this.messagesValidationSTT = [$localize`AM or PM ?`];
			this.indexValidationSTT = 0;
			mathiaMessage = this.messagesValidationSTT[this.indexValidationSTT];
		} else if (this.award === "units_help_validation") {
			this.validationUnitsHelpMessage = [$localize`Tu comprends ?`];
			this.indexValidationUnitsHelpMessage = Math.floor(Math.random() * this.validationUnitsHelpMessage.length);
			mathiaMessage = this.validationUnitsHelpMessage[this.indexValidationUnitsHelpMessage];
		} else if (this.award === "end_results") {
			if (this.accountService.team.length === 1) {
				this.resultsMessage = [
					// "Félicitations ! tu as fini l'activité ! Voici tout ce que tu as gagné ! Bravo petit astronaute des mathématiques !",
					// "Quel voyage ! Bravo ! Voici tout ce que tu as gagné ! Tu peux être fier de toi !"
					$localize`Tu as terminé ce niveau ! Voici tout ce que tu as gagné ! On continue ?`,
					$localize`Tu as terminé ce niveau ! Voici tout ce que tu as gagné ! Tu peux être fier de toi ! On continue ?`
				];
			} else if (this.accountService.team.length > 1) {
				this.resultsMessage = [
					// "Vous avez fini l'activité ! Voici tout ce que vous avez gagné ! Bravo petits astronautes des mathématiques !",
					// "Voici tout ce que vous avez gagné ! Vous pouvez être fiers de vous !"
					$localize`Voici tout ce que vous avez gagné ! Bravo petits astronautes des mathématiques !`,
					$localize`Voici tout ce que vous avez gagné ! Vous pouvez être fiers de vous ! On continue ?`
				];
			}
			this.indexResultsMessage = Math.floor(Math.random() * this.resultsMessage.length);
			mathiaMessage = this.resultsMessage[this.indexResultsMessage];
		} else if (this.award === "end_results_v2") {
			if (this.accountService.team.length === 1) {
				if (this.cabriService.currentActivity && this.cabriService.currentActivity.story) {
					// TODO: condition on succes / fail
					this.resultsMessage = [" "];
				} else if (this.lmsService.currentUserJourney?.nextActivityProposed) {
					this.resultsMessage = [$localize`Tu as terminé cette étape de ton parcours ! On passe à la suivante ?`];
				} else {
					this.resultsMessage = [$localize`Mission terminée ! Voici tout ce que tu as remporté !`];
				}
			} else if (this.accountService.team.length > 1) {
				this.resultsMessage = [$localize`Mission terminée ! Voici tout ce que vous avez remporté ! On continue ?`];
			}
			this.indexResultsMessage = Math.floor(Math.random() * this.resultsMessage.length);
			mathiaMessage = this.resultsMessage[this.indexResultsMessage];
		}
		this.mathiaMessage.emit(mathiaMessage);
	}

	// Check if all awards obtained
	allAwardsObtained() {
		return (
			this.cabriService.teamShootingStarsCount > 0 &&
			this.cabriService.teamNormalStarsCount > 0 &&
			this.cabriService.teamMoonsCount > 0
		);
	}

	get displayMoon() {
		return (
			this.cabriService.teamMoonsCount > 0 ||
			(this.cabriService.teamNormalStarsCount === 0 && this.cabriService.teamShootingStarsCount === 0)
		);
	}

	getValidationOCRMessage() {
		return this.messagesValidationOCR[this.indexValidationOCR];
	}

	getValidationSTTMessage() {
		return this.messagesValidationSTT[this.indexValidationSTT];
	}

	getResultsMessage() {
		return this.resultsMessage[this.indexResultsMessage];
	}

	async validateAnswer($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.displayValidationButtons = false;
		this.answerValidated = true;
		// console.log("validated");
		this.validation.emit(this.answerValidated);
	}

	async refuseAnswer($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.displayValidationButtons = false;
		this.answerValidated = false;
		// console.log("refused");
		this.validation.emit(this.answerValidated);
	}

	async ready($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.displayValidationButtons = false;
		this.answerValidated = true;
		const waitSkipSequence = new Promise(resolve => {
			this.skipSequence.emit([true, resolve]);
		});
		await waitSkipSequence;
		this.validation.emit(this.answerValidated);
	}

	async skip($event) {
		await this.globalService.waitButtonClick({ buttonClicked: $event.currentTarget });
		this.skipSequence.emit(true);
	}

	playText() {
		this.playTTSService.playTTS(this.resultsMessage);
	}

	home() {
		this.cabriService.leaveActivityPage();
	}

	async restartActivityHolo() {
		if (this.lmsService.currentUserJourney && !this.lmsService.currentUserJourney.nextActivityProposed) {
			this.playTTSService.killSpeech();
			const journeyTargetted = this.lmsService._globalJourneys.find(j => {
				return j.id === this.lmsService.currentUserJourney.id;
			});
			this.lmsService.currentUserJourney.exercises = journeyTargetted.exercises;
			this.lmsService.currentUserJourney.exercises.forEach((ex: ProposedActivity) => {
				ex.status = Status.notDone;
			});
			this.lmsService.currentUserJourney.resume = false;
			this.lmsService.currentUserJourney.start = true;
			await this.cabriService.setActivityId(this.lmsService.currentUserJourney.exercises[0].exerciseId);
			this.cabriService.currentActivity.buildVariables();
		}
		this.restartActivity();
	}

	async restartActivity() {
		await this.playTTSService.killSpeech();
		if (this.lmsService.currentUserJourney) {
			this.lmsService.currentUserJourney.allAskedQuestions = new Array();
		}
		this.restart.emit(true);
	}

	nextActivity() {
		this.globalService.setGlobalLoading(true);
		this.playTTSService.killSpeech();
		this.nextActivityEvent.emit();
	}

	exit() {
		// navigator[‘app’].exitApp()
	}

	ngOnDestroy() {
		if (this.eventMessageSubscription) {
			this.eventMessageSubscription.unsubscribe();
		}
	}
}
