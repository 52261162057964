<div class="imagePuzzle" *ngIf="puzzle">

	<div id="headerContainer" #headerContainer>
		<div class="header-title">
			<ng-container *ngIf="!solved">
				<div class="title-wrapper">
					<h1 class="sectionName">{{consigne}}</h1>
					<ion-button  class="skip-button"(click)="nextGame()" *ngIf="!environment.production || accountService.team[0].preview" i18n>Passer</ion-button>
				</div>
			</ng-container>
			<div class="helpBtnWrapper" *ngIf="!solved" (click)="displayHelp = !displayHelp">
				<p *ngIf="!displayHelp" i18n>Voir l'image</p>
				<p *ngIf="displayHelp" i18n>Continuer à jouer</p>
			</div>
		</div>
		
	</div>
	<div #puzzleWrapper class="puzzle-wrapper" [ngClass]="solved ? 'completed':'unsolvedPuzzle'">
		<img #imageSrc [src]="imageUrl" [style.opacity]="displayHelp ? '1' : '0'" />
		<ul class="puzzle" [hidden]="displayHelp" [ngClass]="{'div-disabled': ticksinsecond>=300 && ticksinsecond<=600}"
			cdkDropListGroup>
			<li *ngFor="let img of imagesArray" class="box" [id]="img.index" cdkDropListSortingDisabled cdkDropList
				(cdkDropListDropped)="onDrop($event)" [style.width]="boxFixSizeX+'%'" [style.height]="boxFixSizeY+'%'">
				<div cdkDrag [cdkDragData]="img" [cdkDragDisabled]="solved" [style.width]="'100%'"
					[style.height]="'100%'" [ngStyle]="{
					'background-image':'url(' + imageUrl + ') ',
					'background-repeat':'no-repeat', 
					'background-position': '' + img.xPos + ' '  + img.yPos + '',
					'background-size':''+ (gridsizeX * 100) +'% '+ (gridsizeY * 100) +'%'
				}">

				</div>
			</li>
		</ul>
	</div>
</div>
<ng-container *ngIf="!puzzle && this.cabriService.puzzles">
	<nav id="nav-wrapper">
		<ul>
			<ng-container *ngFor="let puzzle of this.cabriService.puzzles">
				<li (click)="loadPuzzle(puzzle.id)">
					<div>{{puzzle.id}} - {{puzzle.title}}</div>
					<div><img src="{{puzzle.img}}" /></div>
				</li>
			</ng-container>
		</ul>
	</nav>
</ng-container>