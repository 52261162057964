import { AudioService } from "src/app/services/audio.service";
import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	ViewChildren,
	QueryList,
	SimpleChanges,
	OnChanges,
	OnDestroy
} from "@angular/core";
import { GlobalService } from "src/app/services/global.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { ClockComponent } from "../clock/clock.component";
import { AppLanguage } from "src/app/models/enums/enum-list";

@Component({
	selector: "app-cabri-numpad",
	templateUrl: "./cabri-numpad.component.html",
	styleUrls: ["./cabri-numpad.component.scss"]
})
export class CabriNumpadComponent implements OnInit, OnChanges, OnDestroy {
	@Input() cd: ChangeDetectorRef;
	@Input() pagetitle = "Enter Pin";
	@Output() change: EventEmitter<{ code: any; event: any }> = new EventEmitter<{ code: any; event: any }>();
	@Input() activity: string = null;
	@Input() pointerEvents = true;
	@Input() displayedPadChoice: [];
	@Input() choiceAnswerAnalog: Array<{ dateInput: any; hidingLevel: any; value: any }>;
	@Input() showDigital = false;
	@Input() remote = false;
	@Input() randomModeInput = false;
	@Input() dyslexicMode = true;
	@Input() pinInitialize = false;
	@Input() writeOpOut = false;
	randomMode = false;
	@ViewChildren(ClockComponent) clock: QueryList<ClockComponent>;
	constructor(public globalService: GlobalService, public cabriService: CabriDataService, public audioService: AudioService) {}
	pin: any = null;
	codeValidated = false;
	pinText = true;
	normalpadNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	displayedPadNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
	randomPadNumbers: [];

	ngOnChanges(changes: SimpleChanges) {
		if (changes.randomModeInput && changes.randomModeInput.currentValue !== changes.randomModeInput.previousValue) {
			if (this.randomModeInput) {
				this.randomMode = true;
				this.randomFunk(this.normalpadNumbers);
			} else if (this.randomMode && !this.randomModeInput) {
				this.displayedPadNumbers = this.normalpadNumbers;
				this.randomMode = false;
				this.cd.detectChanges();
			}
		}

		if (changes.pinInitialize && changes.pinInitialize.currentValue !== changes.pinInitialize.previousValue) {
			if (this.pin) {
				this.pin = null;
			}
		}
	}

	get AppLanguage() {
		return AppLanguage;
	}

	keyboardEvent($event) {
		// console.log($event);
		const n = Number($event.key);
		if (n > -1 && n < 10) {
			this.handleInput(n);
		}
		if ($event.keyCode === 13) {
			this.emitEvent("enter");
		}
		if ($event.keyCode === 8) {
			this.handleInput("clear");
		}
	}
	ngOnInit() {
		document.addEventListener("keyup", this.keyboardEvent.bind(this));
	}
	ngOnDestroy() {
		document.removeEventListener("keyup", this.keyboardEvent);
	}

	// PRESS ENTER
	async emitEvent(value) {
		if (this.pin !== null && this.pin !== "" && !this.globalService.mathiaSpeechRunning) {
			await this.globalService.waitButtonClick({ buttonValidateClicked: "customButtonValidate" }, 200);
			this.audioService.playValidateSound();
			this.change.emit({ code: this.pin, event: value });
			this.pin = null;
		}
	}

	btnColorAnim(pin: any, buttonIndex?: string): Promise<void> {
		return new Promise(async resolve => {
			let buttonClicked = document.getElementById("button" + pin);
			let buttonClickedText = document.getElementById("button" + pin + "p");
			if (buttonIndex) {
				buttonClicked = document.getElementById("button" + buttonIndex);
				buttonClickedText = document.getElementById("button" + buttonIndex + "p");
			}
			if (buttonClicked) {
				buttonClicked.classList.add("buttonClicked");
				if (buttonClickedText) {
					buttonClickedText.classList.add("buttonClickedText");
				}
				if (this.cd) {
					this.cd.detectChanges();
				}
				setTimeout(() => {
					buttonClicked.classList.remove("buttonClicked");
					if (buttonClickedText) {
						buttonClickedText.classList.remove("buttonClickedText");
					}
					if (this.cd) {
						this.cd.detectChanges();
					}
					resolve();
				}, 200);
			} else {
				resolve();
			}
		});
	}

	async handleInput(pin: any, buttonIndex?: string) {
		this.btnColorAnim(pin, buttonIndex);
		if (pin === "random") {
			this.pin = null;
			this.randomMode = !this.randomMode;
			if (this.randomMode === true) {
				this.randomFunk(this.normalpadNumbers);
			} else {
				this.displayedPadNumbers = this.normalpadNumbers;
			}
			if (this.cd) {
				this.cd.detectChanges();
			}
			return;
		}

		if (pin === "clear") {
			if (this.pin?.toString()?.length > 0) {
				if(this.writeOpOut){
					this.pin = this.pin.toString().substring(1);
				} else {
					this.pin = this.pin.toString().substring(0, this.pin.toString().length - 1);
				}
			}
			this.audioService.playCancelSound();
			if (this.pin.length === 0) {
				this.change.emit({ code: this.pin, event: "cleared" });
			} else {
				this.change.emit({ code: this.pin, event: null });
			}
			return;
		}

		if (this.pin === null) {
			// first digit
			if (this.activity !== "heure" && this.activity !== "solides" && this.activity !== "kim") {
				this.audioService.playSelectSound();
			}
			this.pin = pin;
			this.change.emit({ code: this.pin, event: null });
			return;
		}

		if (this.pin.toString().length >= 1 && this.activity !== "heure") {
			// add to first digit
			this.audioService.playSelectSound();
			if(this.writeOpOut){
				this.pin = pin.toString() + this.pin.toString();
			} else {
				this.pin = this.pin.toString() + pin.toString();
			}
			this.change.emit({ code: this.pin, event: null });
		} else {
			this.pin = pin.toString();
			this.change.emit({ code: this.pin, event: null });
		}
	}

	randomFunk(array) {
		// let numberArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
		const randomArray = [];
		for (let i = 0; i < 10; i++) {
			//pick a random number in numberArray
			const randomIndex = Math.floor(Math.random() * array.length);
			const randomNumber = array[randomIndex];
			randomArray.push(randomNumber);

			//remove the selected number from array
			array = array.filter(item => item != randomNumber);
		}
		this.displayedPadNumbers = randomArray;
	}

	//other example of shuffle
	shuffle(array) {
		let counter = array.length;
		// While there are elements in the array
		while (counter > 0) {
			// Pick a random index
			const index = Math.floor(Math.random() * counter);

			// Decrease counter by 1
			counter--;

			// And swap the last element with it
			const temp = array[counter];
			array[counter] = array[index];
			array[index] = temp;
		}

		return array;
	}
}
