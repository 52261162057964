import { Injectable, ErrorHandler } from "@angular/core";
import { AnalyticService } from "../services/analytic.service";
import { throwError } from "rxjs";
import { GlobalService } from "../services/global.service";
import { AppUtils } from "../app-utils";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	public environment: { production: boolean; activityVersion: number; kidaia: boolean; };
	constructor(public analytic: AnalyticService, public globalService: GlobalService, public router: Router) {
    this.environment = environment;
  }

	handleError(error: Error) {
		if (this.environment.production) {
			try {
				const trace = {
					message: error.message,
          			stackTrace : error.stack,
					url: this.router.url,
					ios: this.globalService.isIos,
					android: this.globalService.isAndroid,
					tablet: this.globalService.isTablet,
					desktop: this.globalService.isDesktop,
					cordova: this.globalService.isCordova,
					browser: this.globalService.isBrowser,
					safari: this.globalService.isSafari,
					height: window.innerHeight,
					width: window.innerWidth
				};
				this.analytic.sendAnalytics("Error", trace);
			} catch (errorAnalytic) {}
		}
		throw error;
	}
}
