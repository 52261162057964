enum Destination {
	PARAM = "CabriParam",
	VARIABLE = "CabriVariable"
}

export enum NombreAutrementDisplayMode {
	collection = "collection",
	letters = "letters",
	fingers = "fingers",
	coins = "coins",
	dice = "dice"
}
export class Param {
	public className = "Param";
	public exercices = false;
	public defaultValue: any;
	public name: string;
	public label: string;
	public value: any;
	public callBack?: () => void;
	public hidden = false;
	public dynamic = false;
	public destination: string;
	public tooltip: string;
	public selectionList: any;
	public config: boolean;

	constructor(param) {
		this.name = param.name;
		this.defaultValue = param.defaultValue;
		this.exercices = param.exercices;
		this.label = param.label;
		this.value = param.value;
		this.hidden = param.hidden;
		this.dynamic = param.dynamic;
		this.destination = param.destination;
		this.tooltip = param.tooltip;
		this.selectionList = param.selectionList;
		this.config = param.config;
	}

	onChange() {
		if (this.callBack) {
			this.callBack();
		}
	}
}
