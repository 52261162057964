import { Param } from "./params/param";

export enum ParamType {
	inputMode = "input-method",
	holoMode = "holo",
	remoteMode = "remoteHost"
}

/**
 * Scénarios pour le tutriel des Gabarits
 */
export class ParamTutorial {
	currentParam: Param;
	constructor() {}
	/**
	 * Introduction activité "Les nombres autrement"
	 */
	showTutorialText(currentParam: Param) {
		let textToDisplay = "";
		this.currentParam = currentParam;

		// console.log("this.page.tutorialParamTargetted", this.page.tutorialParamTargetted);
		// // Mode de réponses
		if (this.currentParam.name === ParamType.inputMode) {
			if (this.currentParam.selectionList.length === 1) {
				// Uniquement un seul input
				if (this.currentParam.selectionList[0][1] === "stt") {
					textToDisplay = $localize`Seul le mode Supra Vocal est disponible pour cette activité, vous devez répondre en parlant.`;
				} else if (this.currentParam.selectionList[0][1] === "numpad") {
					textToDisplay = $localize`Seul le mode Cosmo Clavir est disponible pour cette activieté, vous devez répondre avec le clavier numérique.`;
				} else if (this.currentParam.selectionList[0][1] === "drawing") {
					textToDisplay = $localize`Seul le mode Astro Ardoise est disponible pour cette activité, vous devez répondre en dessinant.`;
				}
			} else if (this.currentParam.selectionList.length === 2) {
				// Paramètre pour input-method contenant stt et numpad
				const sttNumpad = this.currentParam.selectionList.every(data => {
					return data[1] === "stt" || data[1] === "numpad";
				});
				// Paramètre pour input-method contenant stt et drawing
				const sttDrawing = this.currentParam.selectionList.every(data => {
					return data[1] === "stt" || data[1] === "drawing";
				});
				// Paramètre pour input-method contenant numpad et drawing
				const drawingNumpad = this.currentParam.selectionList.every(data => {
					return data[1] === "numpad" || data[1] === "drawing";
				});
				if (sttNumpad) {
					textToDisplay = $localize`Les différents modes proposés sont Supra Vocal et Cosmo Clavier.
					Supra Vocal pour répondre directement en parlant et
					Cosmo Clavier pour répondre à l'aide du clavier numérique.`;
				} else if (sttDrawing) {
					textToDisplay = $localize`Les différents modes proposés sont Supra Vocal et Astro Ardoise.
					Supra Vocal pour répondre directement en parlant et
					Astro Ardoise pour dessiner le résultat sur l'écran.`;
				} else if (drawingNumpad) {
					textToDisplay = $localize`Les différents modes proposés sont Astro Ardoise et Cosmo Clavier.
					Astro Ardoise pour dessiner le résultat sur l'écran et
					Cosmo Clavier pour répondre à l'aide du clavier numérique.`;
				}
			} else if (this.currentParam.selectionList.length === 3) {
				textToDisplay = $localize`
					Les différents modes proposés sont Supra Vocal, Astro Ardoise et Cosmo Clavier.
					Supra Vocal pour répondre directement,
					Astro Ardoise pour dessiner le résultat sur l'écran et
					Cosmo Clavier pour répondre avec le clavier numérique.`;
			}
		} else if (this.currentParam.name === ParamType.holoMode) {
			// Holo
			textToDisplay = $localize`
		    Le mode holographique permet de projeter un hologramme sur une pyramide ou une vitre si vous disposez du matériel nécessaire.`;
		} else if (this.currentParam.name === ParamType.remoteMode) {
			// Remote
			textToDisplay = $localize`
			Le contrôle à distance permet de répondre aux questions de l'exercice depuis un autre appareil.`;
		}
		return textToDisplay;
	}
}
