import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WpSidService {

  connectSid: string;  

  constructor() {
    this.connectSid = localStorage.getItem("connect_sid"); 
    if (!this.connectSid || this.connectSid === "") {
			this.connectSid = this.returnHash();
			localStorage.setItem("connect_sid", this.connectSid);
		}
  }

  returnHash() {
		const abc = "abcdefghijklmnopqrstuvwxyz1234567890".split("");
		let token = "";
		for (let i = 0; i < 32; i++) {
			token += abc[Math.floor(Math.random() * abc.length)];
		}
		return token; // Will return a 32 bit "hash"
	}
}
