<ion-content id="territoire" scrollY="false">

  <app-toolbar-with-buttons [opacity]="globalService.isKidaia" [studentsList]="accountService.allStudents">
  </app-toolbar-with-buttons>

  <div id="small-loader">
    <img id="loaderGIF" src="/assets/babylon/loading_low_ose.gif" alt="chargement">
  </div>

  <!-- top wizard system -->
  <ng-template #reUseCatDetails let-ex="ex" let-i="i">
    <div class="exerciseDetails__icon_container">
      <img class="exerciseDetails__icon" *ngIf="!subpartInProgress || subpartInProgress?.id !==ex.id;else inProgress" [src]="ex.icon" />
      <ng-template #inProgress>
          <div class="exInProgress"></div>
      </ng-template>
    </div>
    <div class="exerciseDetails__name_container"
      [ngClass]="{'sizeSolo': !ex.hasSubpart, 'sizeWithSubparts': ex.hasSubpart}">
      <p class="exerciseDetails__name" (click)="startSelectedActivityFromIndex(ex,i)">
        {{ex.titre_sommaire || ex.title}}
      </p>
    </div>
    <div class="exSubParts" *ngIf="ex.hasSubpart">
      <img *ngIf="ex.subpart?.hasGame" src="assets/icon/game.svg">
      <img *ngIf="ex.subpart?.hasQuiz" src="assets/icon/quiz.svg">
      <img *ngIf="ex.subpart?.hasVideo" src="assets/icon/play.svg">
    </div>
    <div class="exerciseDetails__choices">
      <ion-toggle class="toggleCustomElem"
        *ngIf="accountService.team?.length > 0 && accountService.team[0].preview;else visibleForStudent" slot="start"
        [(ngModel)]="ex.selected"></ion-toggle>
      <ng-template #visibleForStudent>
        <img [src]="ex?.alreadyCompleted ? 'assets/icon/etoile_full.png' : 'assets/icon/etoile_empty.png'"
          class="isCompleted" [ngClass]="{'awarded':ex?.alreadyCompleted, 'notAwarded':!ex?.alreadyCompleted}" >
      </ng-template>
    </div>
  </ng-template>
  <div class="globalContainer" [style.pointer-events]="oseJourneyService.newCompletion ? 'none' : 'auto'" [ngClass]="{
      'globalContainerTerritoire': displayTerritoire, 
      'globalContainerMap':!displayTerritoire,
      'fiche-displayed': selectedActivities && selectedActivities[0]?.isFiche,
      'journey-displayed': isJourneyDisplayed,
      'detailsOpened': detailsOpened,
      'all-quiz-completed': allQuizCompleted }" #content>
    <div class="page-container">
      <div class="main">
        <swiper #ionSlides [zoom]="true" [config]="sliderOptions" (slideChange)="onSwiperChange($event)"
          (slideChangeTransitionEnd)="onSwiperChange($event)">
          <ng-template swiperSlide>
            <!-- SVG -->
            <pinch-zoom #pinch>
              <div #svgWrapper id="svg-wrapper"></div>
            </pinch-zoom>
            <div class="btnBackToTerritoire" (click)="goBackTerritoire()" *ngIf="!displayTerritoire">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <!-- MODAL -->
            <div class="card-wrapper card-reduce" #cardActivity
              [@modalAnim]="{value: modalState, params: {goToRightPosition: goToRightPosition}}"
              (@modalAnim.start)="onAnimationStart($event)" (@modalAnim.done)="onAnimationEnd($event)"
              [hidden]="!selectedActivities || selectedActivities?.length === 0">
              <div class="close-btn" (click)="closeModal()">
                <div class="close-icon-global__img"></div>
              </div>
              <div class="card-wrapper-content" [ngClass]="{'cursor-pointer' : !isJourneyDisplayed}">
                <ng-container *ngIf="selectedActivities && selectedActivities[0]">
                  <div class="card-container" *ngFor="let activity of selectedActivities"
                    (click)="!isJourneyDisplayed ? goNextActivity(activity,false) : null">
                    <ng-container *ngIf="selectedActivities[0]?.isFiche;else poiJourneyModal">
                      <app-fiche #ficheComponent [idFiche]="selectedActivities[0].id" class="card-container is-fiche"
                        [hideMascotte]="true" [scenario]="scenarioNav"></app-fiche>
                    </ng-container>
                    <ng-template #poiJourneyModal>
                      <div class="card-container-title" title="{{activity.id}}">
                        <p>{{activity.title}}</p>
                      </div>

                      <div class="card-container-body" [hidden]="detailsOpened">
                        <img [src]="activity.background">
                      </div>
                      <div class="card-container-footer">
                        <p>{{activity.city}}</p>

                        <div class="card-container-footer-item journeyContainer"
                          *ngIf="activity && isJourneyDisplayed && totalProgressionExercisesToDo">
                          <div id="fiche_peda_container" *ngIf="oseJourneyService.currentJourney?.fiche_peda && accountService.team?.[0].preview">
                            <p class="ficha_peda_text" i18n> Fiche pédagogique </p>
                            <a [href]="oseJourneyService.currentJourney?.fiche_peda" target="_blank">{{
                              oseJourneyService.currentJourney?.fiche_peda | linkName}}</a>
                          </div>

                          <div id="btn-sequence" class="cat-sequence card-container-adventure cursor-pointer"
                            (click)="startResumeJourney(activity, canJourneyResume)">
                            <ng-container *ngIf="canJourneyResume">
                              <h2 i18n>Continuer</h2>
                            </ng-container>
                            <ng-container *ngIf="!canJourneyResume">
                              <h2 *ngIf="!alreadyCompletedJourney" i18n>Commencer</h2>
                              <h2 *ngIf="alreadyCompletedJourney" i18n>Recommencer</h2>
                            </ng-container>
                          </div>

                          <div id="btn-sequence" class="cat-dropdown card-container-adventure cursor-pointer"
                            (click)="openCategories()"
                            [ngClass]="{'opened':isCategoryListOpen}">
                            <h2>Exploration</h2>
                            <ion-icon class="detailStatus" name="caret-forward-outline"></ion-icon>
                          </div>

                          <div class="categoriesListContainer" *ngIf="isCategoryListOpen">
                            <ng-container *ngIf="oseJourneyService.currentJourney?.workshops?.length > 0">
                              <ion-list lines="none" class="categoryItem" [ngClass]="{'opened':oseJourneyService.currentJourney?.selectedCategory === Details.discovering}">
                                <ion-item (click)="openDetails(Details.discovering)" class="cursor-pointer">
                                  <ion-label class="categoryTitle">Je découvre</ion-label>
                                  <ion-icon class="detailStatus" name="caret-forward-outline" ></ion-icon>
                                </ion-item>
                              </ion-list>
                              <div id="containerDiscover"
                                [hidden]="oseJourneyService.currentJourney?.selectedCategory !== Details.discovering">
                                <ng-container
                                  *ngFor="let ex of oseJourneyService.currentJourney.workshops;let i = index">
                                  <div class="exerciseDetails" *ngIf="ex.displayInModal" title="{{ex.id}}">
                                    <ng-container [ngTemplateOutlet]="reUseCatDetails"
                                      [ngTemplateOutletContext]="{ ex: ex,i:i}">
                                    </ng-container>
                                  </div>
                                </ng-container>

                                <div class="journeyActionBtnContainer">
                                  <div class="validationBtn" (click)="startJourneyWithSelectedExercises()">
                                    <p class="validationBtn" i18n>Commencer</p>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="oseJourneyService.currentJourney?.learnings?.length > 0">
                              <ion-list lines="none" class="categoryItem" [ngClass]="{'opened':oseJourneyService.currentJourney?.selectedCategory === Details.learning}">
                                <ion-item (click)="openDetails(Details.learning)" class="cursor-pointer">
                                  <ion-label class="categoryTitle">J'apprends</ion-label>
                                  <ion-icon class="detailStatus" name="caret-forward-outline" ></ion-icon>
                                </ion-item>
                              </ion-list>
                              <div id="containerLearning"
                                [hidden]="oseJourneyService.currentJourney?.selectedCategory !== Details.learning"
                                *ngIf="oseJourneyService?.currentJourney?.exercises">
                                <ng-container
                                  *ngFor="let ex of oseJourneyService.currentJourney.learnings;let i = index">
                                  <div class="exerciseDetails" *ngIf="ex.displayInModal" title="{{ex.id}}">
                                    <ng-container [ngTemplateOutlet]="reUseCatDetails"
                                      [ngTemplateOutletContext]="{ ex: ex,i:i}">
                                    </ng-container>
                                  </div>
                                </ng-container>
                                <div class="journeyActionBtnContainer"
                                  [ngClass]="{'disabled':disableValidateSelectionEx}">
                                  <div class="validationBtn" (click)="startJourneyWithSelectedExercises()">
                                    <p class="validationBtn" i18n>Commencer</p>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <ng-container *ngIf="oseJourneyService.currentJourney?.evaluations.length > 0">
                              <div class="card-container-footer-item quizzContainer" #quizzContainer
                                *ngIf="activity && activity.quizz && activity.quizz.length > 0">
                                <ion-list lines="none" class="categoryItem" [ngClass]="{'opened':oseJourneyService.currentJourney?.selectedCategory === Details.quizz}">
                                  <ion-item  (click)="openDetails(Details.quizz)" class="cursor-pointer">
                                    <ion-label class="categoryTitle">Je me teste</ion-label>
                                    <ion-icon class="detailStatus" name="caret-forward-outline" ></ion-icon>
                                  </ion-item>
                                </ion-list>
                                <ng-container
                                  *ngIf="oseJourneyService.currentJourney?.selectedCategory ===  Details.quizz">
                                  <div id="containerEvaluation"
                                    [hidden]="oseJourneyService.currentJourney?.selectedCategory !== Details.quizz"
                                    *ngIf="oseJourneyService?.currentJourney?.exercises">
                                    <ng-container
                                      *ngFor="let ex of oseJourneyService.currentJourney.evaluations;let i = index">
                                      <div class="exerciseDetails" title="{{ex.id}}"
                                        *ngIf="!ex.isEtape && !(ex.type === OseExerciceType.fiche && ex.titre_sommaire === oseJourneyService.currentJourney.evaluations[i - 1]?.titre_sommaire)"
                                        [ngClass]="{'isSelected':ex.selected}">
                                        <ng-container [ngTemplateOutlet]="reUseCatDetails"
                                          [ngTemplateOutletContext]="{ ex: ex,i:i}">
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                    <div class="card-container-footer-item all-quiz-done">
                                      <p *ngIf="isJourneyDisplayed && allQuizCompleted" i18n>Bravo tu as terminé tous
                                        les
                                        quiz!</p>
                                    </div>
                                    <div class="journeyActionBtnContainer"
                                      [ngClass]="{'disabled':disableValidateSelectionEx}">
                                      <div class="validationBtn" (click)="startJourneyWithSelectedExercises()">
                                        <p class="validationBtn" i18n>Commencer
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div
                          *ngIf="(!isJourneyDisplayed || (isJourneyDisplayed && competenciesOpen)) && activity && activity.competencies && activity.competencies.length > 0"
                          id="modal-competencies" class="card-container-footer-item competenciesContainer"
                          (click)="grimoire($event)">
                       
                          <ng-container *ngIf="isJourneyDisplayed">
                              <h4 i18n>Compétences</h4>
                          </ng-container>
                          <ng-container *ngIf="!isJourneyDisplayed">
                            <p *ngIf="activity.competencies.length > 1" i18n>Thématiques</p>
                            <p *ngIf="activity.competencies.length <= 1" i18n>Thématique</p>
                          </ng-container>
                          <ng-container *ngFor="let competency of activity.competencies">
                            <div class=" card-container-eachCompetencies">
                              <ion-icon name="checkmark-circle-outline"></ion-icon>
                              <span><i>{{competency.name}}</i></span>
                            </div>
                          </ng-container>
                        </div>

                  
                      </div>

                      <div *ngIf="!isJourneyDisplayed" class="card-wrapper-global card-wrapper-play">
                        <img src="/assets/icon/play-ose.svg" />
                      </div>
                    </ng-template>
                    <ng-container *ngIf="isJourneyDisplayed">
                    <div id="competencies-wrapper" (click)="grimoire($event)"
                      *ngIf="accountService.team.length > 0 && accountService.team[0].preview && activity && activity.competencies && activity.competencies.length > 0">
                      <img id="open-competencies" src="/assets/icon/grimoire.png" />
                      <h2 i18n>Compétences</h2>
                    </div>

                    <div id="progressBar" *ngIf="accountService.team.length > 0 && !accountService.team[0].preview">
                      <app-custom-progressbar
                        [number]="totalCompletedExercises?.length || 0"
                        [total]="totalProgressionExercisesToDo?.length || 0" [mascotVisible]="true">
                      </app-custom-progressbar>
                    </div>
                  </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-template>
          <ng-container *ngIf="cabriService.territoires && displayTerritoire">
            <ng-container *ngFor="let territoire of cabriService.territoires">
              <ng-template swiperSlide>
                <div class="territoire-wrapper">
                  <pinch-zoom #pinch>
                    <img [src]="territoire.carte" />
                  </pinch-zoom>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </swiper>

      </div>
      <div class="footer">
        <div class="arrow-wrapper arrow-left" (click)="prevSlide()"></div>
        <h1 class="territoireName">{{currentTerritoire}}</h1>
        <div class="arrow-wrapper arrow-right" (click)="nextSlide()"></div>
      </div>
    </div>
  </div>

</ion-content>