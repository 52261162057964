import { CastelCell } from "./castel-cell";

export class CastelGrid {
	public _mode = "addition";
	// public _mode = "multiplication";
	// public _mode = "soustraction";

	public cells;
	public undisplayedNumbers = [];

	public _nbRows;
	public _nbCols;

	public priorityToGivenNumbers = 0.3;
	numbersToHideInPriority: number[] = [17, 27, 37, 47, 57, 67, 77, 87, 97];

	constructor() {
		this._nbRows = 10; // set default value
		this._nbCols = 10; // set default value
		this.generateNewGrid();
	}

	generateUndisplayedNumbers(hiddenLevel: number = 1) {
		// console.error("Je suis dans generateUndisplayedNumbers");
		this.undisplayedNumbers = [];
		let appendNumber: boolean;
		for (let i = 0; i < this.nbRows * this._nbCols; i++) {
			appendNumber = false;
			if (hiddenLevel === 2 && i > this._nbCols && i % this._nbCols > 0) {
				appendNumber = true;
			} else if (hiddenLevel === 3 && i >= this._nbCols) {
				appendNumber = true;
			}
			if (appendNumber) {
				this.undisplayedNumbers.push(i);
			}
		}
		return this.undisplayedNumbers;
	}

	generateHiddenNumbers(nbHiddenNumbers: number, difficultyLevel: number = 1) {
		// console.error("Je suis dans generateHiddenNumbers");
		const hiddenNumbers = [];
		let newNb: number;
		let iteratorCount = 0;
		const iterLimit = 300;
		// Push given numbers in hiddenNumbers array
		// Prioritize them with a certain chance (set with 'priorityToGivenNumbers')
		if (difficultyLevel === 2 && this.numbersToHideInPriority && this.mode === "addition") {
			this.numbersToHideInPriority.forEach(element => {
				// If array not full, element correct and random draw is positive, then append the number
				if (
					hiddenNumbers.length < nbHiddenNumbers &&
					element < this._nbRows * this._nbCols &&
					Math.random() < this.priorityToGivenNumbers
				) {
					hiddenNumbers.push(element);
				}
			});
		}

		// Finish to fill the hiddenNumbers array
		// tslint:disable-next-line: align
		let tooEasyToGuess: boolean;
		// tslint:disable-next-line: align
		let forbiddenNumber: boolean;

		const restToFillLength = nbHiddenNumbers - hiddenNumbers.length;
		for (let i = 0; i < restToFillLength; i++) {
			do {
				newNb = Math.floor(Math.random() * this.nbRows * this._nbCols);
				iteratorCount++;
				const castelCell = this.getCellById(newNb);
				tooEasyToGuess = difficultyLevel >= 2 && (castelCell.value % this._nbCols === 0 || newNb < this._nbCols);

				forbiddenNumber = castelCell.col === 0 && castelCell.row === 0;
				if (!forbiddenNumber) {
					forbiddenNumber =
						(this.mode === "multiplication" && (castelCell.col === 0 || castelCell.row === 0)) ||
						(this.mode === "soustraction" &&
							(castelCell.row >= castelCell.col || castelCell.col === 0 || castelCell.row === 0));
				}
				if (!forbiddenNumber) {
					forbiddenNumber = this.undisplayedNumbers.indexOf(newNb) === -1;
				}
			} while (iteratorCount < iterLimit && (tooEasyToGuess || hiddenNumbers.includes(newNb) || forbiddenNumber));
			hiddenNumbers.push(newNb);
		}
		return hiddenNumbers;
	}

	generateNewGrid() {
		this.cells = [];
		console.log("this._nbRows", this._nbRows);
		console.log("this._nbCols", this._nbCols);
		for (let i = 0; i < this._nbRows; i++) {
			const row = new Array();
			for (let j = 0; j < this._nbCols; j++) {
				const cellValue = this.calcultateCellValue(i, j);
				const cellId = i * this._nbCols + j;
				row.push(new CastelCell(cellId, cellValue, this));
			}
			this.cells.push(row);
		}
	}

	getCell(hiddenNumber: string): CastelCell {
		hiddenNumber = hiddenNumber.replace(/ /g, "").trim();
		this.cells.forEach(row => {
			row.forEach((cell: CastelCell) => {
				console.log(Number(cell.value), "==", Number(hiddenNumber));
				if (Number(cell.value) === Number(hiddenNumber)) {
					return cell;
				}
			});
		});
		// tslint:disable-next-line: align
		throw new Error("Cell not found. number : " + hiddenNumber);
	}

	getCellById(id: number): CastelCell {
		if ("" + id === "NaN") {
			return this.cells[0][0];
		}
		const row = Math.floor(id / this._nbCols); // dixaine
		const col = id - row * this._nbCols; // unité

		if (this.cells[row]) {
			return this.cells[row][col];
		}
	}

	getCellIdByRowCol(operation1: number, operation2: number, mode: string): CastelCell {
		let column;
		if (mode === "addition") {
			this.cells.some((cell, index) => {
				return cell.some(item => {
					if (item.id === operation2) {
						column = index;
						return index;
					}
				});
			});
		} else {
			column = operation2;
		}

		const currentCell = this.cells[column]?.[operation1];
		return currentCell;
	}

	calcultateCellValue(i, j) {
		let cellValue;
		if (this.mode === "addition") {
			if (i === 0 && j === 0) {
				cellValue = "+"; // cell [0, 0]
			} else {
				cellValue = i * this._nbCols + j; // operation
			}
		} else if (this.mode === "addition-classic") {
			if (i === 0 && j === 0) {
				cellValue = "+"; // cell [0, 0]
			} else if (i === 0) {
				cellValue = j; // 1st row
			} else if (j === 0) {
				cellValue = i; // 1st column
			} else {
				cellValue = i + j; // operation
			}
		} else if (this.mode === "multiplication") {
			if (i === 0 && j === 0) {
				cellValue = "×"; // cell [0, 0]
			} else if (i === 0) {
				cellValue = j; // 1st row
			} else if (j === 0) {
				cellValue = i; // 1st column
			} else {
				cellValue = i * j; // operation
			}
		} else if (this.mode === "soustraction") {
			if (i === 0 && j === 0) {
				cellValue = "-"; // cell [0, 0]
			} else if (i === 0) {
				cellValue = j; // 1st row
			} else if (j === 0) {
				cellValue = i; // 1st column
			} else {
				cellValue = j - i; // operation
			}
		}

		return cellValue;
	}

	get nbRows() {
		return this._nbRows;
	}
	set nbRows(value: number) {
		this._nbRows = value;
		this.generateNewGrid();
	}

	get nbCols() {
		return this._nbCols;
	}
	set nbCols(value: number) {
		this._nbCols = value;
		this.generateNewGrid();
	}

	get mode() {
		return this._mode;
	}
	set mode(value: string) {
		this._mode = value;
		this.generateNewGrid();
	}
}
