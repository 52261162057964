import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Exercices, Grade } from "src/app/models/exercices";
import { Status } from "src/app/models/proposed-activity";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { LmsService } from "src/app/services/lms.service";
import { AccountService } from "src/app/services/account.service";
import { Journey, JourneysByStatus } from "src/app/models/journey";
import { AudioService } from "src/app/services/audio.service";

export class ExerciseJourneyExtractor {
	[key: string]: { title?: string; id?: string };
}
@Component({
	selector: "app-journey",
	templateUrl: "./journey.component.html",
	styleUrls: ["./journey.component.scss"]
})
export class JourneyComponent {
	@Input() assignation: boolean;
	@Input() journeyByStatus: JourneysByStatus;
	@Output() checkJourneyState = new EventEmitter<void>(true);
	@Output() setHUDJourneyHeight = new EventEmitter<{ srcElement: HTMLEmbedElement; opened: boolean }>();

	journeyTitleExtract: ExerciseJourneyExtractor = {};
	journeyExerciseExtract: ExerciseJourneyExtractor = {};

	journeyTitles = {};
	journeyIds = {};

	constructor(
		public cd: ChangeDetectorRef,
		public accountService: AccountService,
		public lmsService: LmsService,
		public cabriService: CabriDataService,
		public globalService: GlobalService,
		public audioService: AudioService
	) {}

	openJourneyCategory($event, journey: { key: string; value: { opened: boolean; items: Journey } }) {
		this.audioService.playCancelSound();
		for (const j in this.journeyByStatus) {
			if (j && this.journeyByStatus[j]) {
				if (journey.key !== j) {
					this.journeyByStatus[j].opened = false;
				}
			}
		}

		journey.value.opened = !journey.value.opened;
		this.setHUDJourneyHeight && journey.value.opened
			? this.setHUDJourneyHeight.next({ opened: true, srcElement: $event.currentTarget })
			: this.setHUDJourneyHeight.next({ opened: false, srcElement: $event.currentTarget });
	}

	get containAtLeastJourneyInProgress() {
		let contain = false;
		for (const j in this.journeyByStatus) {
			if (j == "1" && this.journeyByStatus[j] && this.journeyByStatus[j].items?.length > 0) {
				contain = true;
			}
		}

		return contain;
	}

	updateValue($event, journey: Journey) {
		this.audioService.playSelectSound();
		this.lmsService.userJourneyFromParams = journey;
		// console.log("this.lmsService.userJourneyFromParams",this.lmsService.userJourneyFromParams)
		if (this.checkJourneyState) {
			this.checkJourneyState.next();
		}
		if ($event && $event.currentTarget && !$event.currentTarget.classList.contains("selectedItem")) {
			document.querySelectorAll(".elementList").forEach(data => {
				data.classList.remove("selectedItem");
			});
			$event.currentTarget.classList.add("selectedItem");
		}
	}

	get Grade() {
		return Grade;
	}

	get Status() {
		return Status;
	}

	displayThumbnail(journeyActivityId) {
		if (journeyActivityId) {
			const result = this.cabriService.activities.find(activity => Number(activity.id) === Number(journeyActivityId));
			if (result) {
				return result.customThumbnail || result.thumbnail;
			}
		}
	}

	openJourneyDetails(valueSelected, $event, indexContainer) {
		const parentWrapperContainer = document.querySelector(".currentStudentJourney" + indexContainer) as HTMLEmbedElement;
		valueSelected.selected = !valueSelected.selected;
		this.setHUDJourneyHeight && valueSelected.selected
			? this.setHUDJourneyHeight.next({ srcElement: parentWrapperContainer, opened: true })
			: this.setHUDJourneyHeight.next({ srcElement: parentWrapperContainer, opened: false });
		$event.stopPropagation();
	}
}
