<div class="modalHeadercloseButtonContainer" (click)="dismiss()">
  <ion-icon class="modalHeadercloseButton" name="close-outline">
  </ion-icon>
</div>
<ng-container *ngIf="online">
  <!-- Video fullscreen-->
  <div *ngIf="videoF" class="playerContainer">
    <div #vimeoContainer class="vimeoContainer fullVideo" [ngClass]="{'animated': !animationsDisabled}">
      <div class="wrapperVimeo">
        <iframe #vimeo (after-if)="videoPlayerInitialyze()"
          [src]="(holo ? videoF.videoUrlHolo : videoF.videoUrl)+'?dnt=true&autoplay=1' | safe" width="100%"
          height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="true"
          autoplay></iframe>
        <ng-content select="[slot=start]"></ng-content>
      </div>
    </div>
  </div>
  <!-- Description fullscreen-->
  <div *ngIf="selected &&  descriptionFull && ! videoF " class="descriptionFullWrapper">
    <ng-content select="[slot=end]"></ng-content>
    <div class="descriptionFull">
      <div class="descriptionFullText">
        <h1>{{selected.name}}</h1>
        <div class="descriptionTextSelected scrollbar-primary">
          <p>{{selected.description}}</p>
          <ul>
            <li *ngFor="let competency of selected.competencies">{{competency.name}}</li>
          </ul>
        </div>
        <div class="positionBotLeft">À partir de {{selected.age}} ans.</div>
        <div class="positionBotRight">⌛ {{selected.duration}}</div>
      </div>
      <div class="descriptionFullRight">
        <!-- img or video player-->
        <div *ngIf="!video" class="videoImgDescriptionFull play"
          (click)="audioService.playSelectSound();checkHolo();videoF=selected;videoSeen(selected);" [ngClass]="{'play': !buttonHolo}">
          <ion-img  [src]="(selected.videoUrl | vimeoThumbUrl |async) || selected.cover"></ion-img>
        </div>
        <!-- video Player -->
        <div *ngIf="video" class="playerContainer">
          <div #vimeoContainer class="vimeoContainer">
            <iframe #vimeo [src]="(holo ? video.videoUrlHolo : video.videoUrl)+'?dnt=true' | safe" width="100%"
              height="250vh" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="true"
              autoplay></iframe>
          </div>
        </div>
        <div *ngIf="buttonHolo" class="buttonsGroupOse">
          <div class="buttonPlayOse marginRight" (click)="holo=false;videoF=selected;videoSeen(selected);">
            <div>
              <p class="textButton " i18n>Sans Holographie</p>
            </div>
            <img src="./assets/icon/play-video.svg" alt="">

          </div>
          <div class="buttonPlayOse marginLeft" (click)="holo=true;videoF=selected;videoSeen(selected);">
            <div>
              <p class="textButton " i18n>Holographie</p>
            </div>
            <img src="./assets/icon/play-video.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!videoF">
    <ng-content select="[slot=list]"></ng-content>
    <ion-row class="mainlist">
      <!-- left block video list -->
      <ion-col *ngIf="!descriptionFull || !selected" [attr.size]="selected? 9:12">
        <ion-row class="filterVideo">
          <ion-col size="3" class="headerSelectionCol">
            <div class="buttonContainerMenu squareButtonActive pointer" [class.selectedMenuItem]="!unseen"
              (click)="allVideo()">
              <div class="insideButtonBody"></div>
              <p class="textButton " i18n>Toutes</p>
            </div>
          </ion-col>
          <ion-col size="3" class="headerSelectionCol">
            <div class="buttonContainerMenu squareButtonActive pointer" [class.selectedMenuItem]="unseen"
              (click)="unSeenVideo()">
              <div class="insideButtonBody"></div>
              <p class="textButton " i18n>Non lues</p>
            </div>
          </ion-col>
          <ion-col size="3" class="headerSelectionCol">
            <div class="buttonContainerMenu squareButtonActive " [class.selectedMenuItem]="age">
              <div class="insideButtonBody"></div>
              <div class="selectInline">
                <p class="textButton " i18n>Âge : </p>
                <select class="buttonSelect pointer" [(ngModel)]="age" (change)="filterVideo()">
                  <option [ngValue]="undefined">Tous</option>
                  <option [ngValue]="5" i18n>5 ans</option>
                  <option [ngValue]="6" i18n>6 ans</option>
                  <option [ngValue]="7" i18n>7 ans</option>
                  <option [ngValue]="8" i18n>8 ans</option>
                  <option [ngValue]="9" i18n>9 ans</option>
                  <option [ngValue]="10" i18n>10 ans</option>
                </select>
              </div>
            </div>
          </ion-col>
          <ion-col [attr.size]="selected || (descriptionFull && !modalController)? 3:2.65" class="headerSelectionCol">
            <div class="buttonContainerMenu squareButtonActive " [class.selectedMenuItem]="videoGenre">
              <div class="insideButtonBody"></div>
              <div class="selectInline">
                <select class="buttonSelect pointer" [(ngModel)]="videoGenre" (change)="filterVideo()">
                  <option [ngValue]="undefined" [selected]="true" i18n>Thèmes (Tous)</option>
                  <option *ngFor="let genre of videoGenres" [selected]="genre === videoGenre" [ngValue]="genre">
                    {{genre}}
                  </option>
                </select>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <div class="videoListContainer scrollbar-primary">
          <ion-row class="videoListRow" [@.disabled]="animationsDisabled" [@listAnimation]="videoList?.length">
            <ion-col *ngFor="let lvideo of videoList" [attr.size]="selected? 4:3" class="videoCol">
              <ion-card class="videoCard" (click)="audioService.playSelectSound();selected=lvideo;video=null">
                <div class="videoCardImg">
                  <img [src]="lvideo.cover" />
                </div>
                <ion-card-content class="descriptionTextVideo">
                  <p i18n>À partir de {{lvideo.age}} ans.</p>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
      <!-- right block video player | Description when not fullscreen-->
      <ion-col *ngIf="selected && !descriptionFull" [attr.size]="3" class="descriptionCol">
        <!-- video Description selected video -->
        <div class="descriptionCol">
          <ion-card class="selectedVideoCard" [@.disabled]="animationsDisabled" [@listAnimation]="selected">
            <img *ngIf="!video" [src]="selected.cover" />
            <!-- video Player -->
            <div *ngIf="video" class="playerContainer">
              <div #vimeoContainer class="vimeoContainer">
                <iframe #vimeo [src]="(holo ? video.videoUrlHolo : video.videoUrl)+'?dnt=true' | safe" width="100%"
                  height="250vh" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                  autoplay></iframe>
              </div>
            </div>
            <ion-card-header>
              <ion-card-title>{{selected.name}}</ion-card-title>
            </ion-card-header>
            <ion-card-content class="descriptionTextSelected scrollbar-primary">
              <div>À partir de {{selected.age}} ans.</div>
              <div>⌛ {{selected.duration}}</div>
              <p>{{selected.description}}</p>
              <li *ngFor="let competency of selected.competencies">{{competency.name}}</li>
            </ion-card-content>
            <div class="cardButton">
              <ion-row class="cardButtonRow">
                <ion-col size="6" class="headerSelectionCol">
                  <div class="buttonContainerMenu squareButtonActive "
                    (click)="holo=false;videoF=selected;videoSeen(selected);">
                    <div class="insideButtonBody"><img src="./assets/toolbar/modal/sans-mode-icone-holo.svg" alt="">
                    </div>
                    <p class="textButton ">▶</p>
                    <p class="textButton " i18n>Sans Holo</p>
                  </div>
                </ion-col>
                <ion-col size="6" class="headerSelectionCol">
                  <div class="buttonContainerMenu squareButtonActive "
                    (click)="holo=true;videoF=selected;videoSeen(selected);">
                    <div class="insideButtonBody"><img class="imgHolo"
                        src="./assets/toolbar/modal/une-face-icone-holo.svg" alt=""></div>
                    <p class="textButton ">▶</p>
                    <p class="textButton " i18n>Avec Holo</p>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ng-container>
</ng-container>
<ng-container *ngIf="!online">
  <span class="offlineText" i18n>Pas de connexion à internet : reconnectez-vous pour accéder aux videos.</span>
</ng-container>