import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tracking-iosinfo',
  templateUrl: './tracking-iosinfo.component.html',
  styleUrls: ['./tracking-iosinfo.component.scss'],
})
export class TrackingIOSInfoComponent implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  dismiss(){
    this.modalController.dismiss();
  }

}
