import { Component, OnInit } from "@angular/core";
import { svgTags } from "../../galaxy-map/galaxy-map.component";

@Component({
	selector: "app-etape3",
	templateUrl: "./etape3.component.html",
	styleUrls: ["./etape3.component.scss"]
})
export class Etape3Component implements OnInit {
	constructor() {}

	ngOnInit() {}

	get svgTags() {
		return svgTags;
	}
}
