<div class="studentsContainer scrollbar-primary"
  [ngClass]="!globalService.isBrowser && globalService.isLandscape ? 'scrollbarMobileLandscape' : 'scrollbar'">

  <ion-grid fixed class="ion-no-padding ion-no-margin">
    <ion-row style="width: 100%;">

      <!-- Host -->
      <ion-col size-md="4" size-xs="12" *ngFor="let item of remoteService.listRemoteHost">
        <ion-card *ngIf="item.hostActivityInfo" (click)="selectHost(item)">
          <ion-img [src]="cabriDataService.getActivityById(item.hostActivityInfo.activity).customThumbnail"></ion-img>
          <app-avatar-bot class="robot" [seed]="item.id" ></app-avatar-bot>
          <div class="gr_bubbles">
            <div class='gr_gradientBubbles'>
              <div class="gr_Container">
                <div class="gr_IconsContainer" [ngClass]="item.hostActivityInfo.participants.length > 3 ? 'twoRows' : ''">
                  <ng-container *ngFor="let student of item.hostActivityInfo.participants">
                    <ion-icon name="person"></ion-icon>
                  </ng-container>
                </div>
                <div class="gr_StudentNames">
                  <div class="gr_studentNamesContainer">
                    <ng-container *ngFor="let student of item.hostActivityInfo.participants;let last = last">
                      <p *ngIf="student">{{student}}</p>
                      <div *ngIf="!last" class="gr_Divider"></div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ion-card>
        <ion-card *ngIf="!item.hostActivityInfo" (click)="selectHost(item)">
          <app-avatar-bot  [seed]="item.id" ></app-avatar-bot>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ion-footer [ngClass]="!globalService.isBrowser && globalService.isLandscape ? 'footerMobileLandscape' : 'footerNormal'"
  class="gradientFooter">
  <button class="footerBtn btnCancel btnNoDecoration" (click)="modalCtrl.dismiss()">Annuler</button>
</ion-footer>