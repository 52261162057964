<div class="dividedContainer" *ngIf="this.generalParams?.length > 0">
  <ng-container *ngFor="let param of this.generalParams;let last=last">
    <div class="journeyParamsContainer" [style.height]="this.generalParams.length === 1 ? '60%' : 100 / this.generalParams.length +'%'"
      *ngIf="displayParams && param"
      [ngClass]="{'mathiaJourneyParamsContainerMobile' : !globalService.isKidaia && this.globalService.isMobile, 'divider': !last}">
      <app-param #paramContainer [param]="param" [quickstartPage]="true">
      </app-param>
    </div>
  </ng-container>
  <ng-container *ngIf="firstTimeParamSelection">
    <div class="validateBtn"
    [ngClass]="{'mathiaValidateBtnMobile' : !globalService.isKidaia && this.globalService.isMobile}">
        <button class="footerBtn" [ngClass]="{'footerBtnMathiaMobile' : !globalService.isKidaia}" (click)="validateChoice()"
        i18n>
        Valider
        </button>
    </div>
  </ng-container>
</div>