import { HttpClient } from "@angular/common/http";
import { ThisReceiver } from "@angular/compiler";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { Subscription } from "rxjs";
import { AppUtils } from "src/app/app-utils";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { OseActivity } from "src/app/models/ose-activities";
import { OseExerciceType } from "src/app/models/ose2-journey";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { Statement } from "src/app/models/statement";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { LrsService } from "src/app/services/lrs.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { environment } from "src/environments/environment";


export enum MainSvgId{
	intruduer="intrus",
	award="award",
	waste="waste",
	trap="trap"
}
@Component({
	selector: "app-seven-errors",
	templateUrl: "./seven-errors.component.html",
	styleUrls: ["./seven-errors.component.scss"]
})
export class SevenErrorsComponent{
	svgContainer:HTMLBaseElement
	@ViewChild("svgDisplayedContainer") svgDisplayedContainer;
	@ViewChild("pinch") pinchZoom: PinchZoomComponent;
	@Input() scenarioBubble: ScenarioOseBubble;
	public AWARD_TEXT = [$localize`Oui, bravo.`,$localize`Bien joué.`,$localize`Bravo, tu as trouvé.`];
	public TRAP_TEXT = [$localize`Tu as cliqué sur le mauvais élèment.`,$localize`Non,ce n'était pas le bon élément à sélectionner`];
	public currentSVGIndex = 0;
	public counterFindIntruder = 0;
	public totalIntruder: number;
	public currentSpotGame: any;
	public won: boolean;
	pZProperties;
	svg: SVGElement;
	windowResizedSubscription: Subscription;
	public endStatement: Statement[];
	environment;
	public defaultConsigne = $localize`Trouve les erreurs présentes dans cette image.`;
	public consigne;

	stopAnimation = false;

	public awardsStars = [];

	displayTutoHorizontal = false;
	displayTutoVertical = false;
	deltaDistance = 20;
	public needGoRight = true;
	public needGoLeft = true;
	public needGoTop = true;
	public needGoBottom = true;
	public isZoomedIn = false;
	imageMoved = false;
	movementX = 0;
	movementY = 0;
	startX = 0;
	startY = 0;
	validateAnswer = false;

	public scrollValue = 150;

	public scrollImgHorizontally = false;
	public scrollImgVertically = false;

	public intruders:HTMLBaseElement[] = new Array();
	public awards:HTMLBaseElement[] = new Array();
	public wastes:HTMLBaseElement[] = new Array();
	public traps:HTMLBaseElement[] = new Array();

	observer: ResizeObserver;
	activityReady = false;
	constructor(
		public http: HttpClient,
		public globalService: GlobalService,
		public cabriService: CabriDataService,
		public accountService: AccountService,
		public lrs: LrsService,
		public oseJourneyService: OseJourneyService,
		public router: Router
	) {
		this.environment = environment;
		this.pZProperties = {
			zoomControlScale: 1,
			// limitPan: true,
			wheelZoomFactor: 0.2,
			backgroundColor: "transparent",
			disableZoomControl:"never",
			"double-tap":false,
				// minPanScale:1
		};
		this.oseJourneyService.launchExercise.subscribe({
			next: (data: OseActivity) => {
				if (data.type === OseExerciceType.spotDifference) {
					this.loadSVG(data.id);
				}
			}
		});
	}

	async loadGame(){
		await this.cabriService.getSpotDifference();
		this.loadSVG(this.oseJourneyService.getCurrentExerciseId());
	}



	public initializeGame() {
		this.won = false;
		this.counterFindIntruder = 0;
		this.scrollImgVertically = 
		this.scrollImgHorizontally = 
		this.displayTutoVertical =  
		this.displayTutoHorizontal = false;
		this.intruders = new Array();
		this.awards = new Array();
		this.wastes = new Array();
		this.awardsStars = new Array();
		this.consigne = null;
		this.stopAnimation = false;
	}

	async loadSVG(id) {
		this.globalService.setGlobalLoading(true);
		if (id) {
			this.initializeGame();
			this.currentSpotGame = this.cabriService.spotDifference.find(c => Number(c.id) === Number(id));
			// const url = "/assets/territoire/map-agriculture.svg"
			// this.currentSpotGame.image = url;
			this.cabriService.currentOseActivity = { id: this.currentSpotGame.id, title:
			this.currentSpotGame.title, type: OseExerciceType.spotDifference };
			this.http.get(this.currentSpotGame.image as any as any, { responseType: "text" }).subscribe({
				next: async data => {
					this.activityReady = false;
					if (this.pinchZoom?.isZoomedIn) {
						this.pinchZoom.toggleZoom();
					}

					this.svgContainer = this.svgDisplayedContainer.nativeElement.querySelector("#svg-wrapper");
					this.svgContainer.innerHTML = data;
					this.svg = this.svgContainer.querySelector("svg");
					// capture coords when mouse down
					await this.svgReady();
					
					this.svgContainer.addEventListener("mousedown", this.mouseDown.bind(this));
					this.svgContainer.addEventListener("touchstart",this.mouseDown.bind(this));
					this.svgContainer.addEventListener("mouseup", this.mouseUp.bind(this));
					this.svgContainer.addEventListener("touchend", this.mouseUp.bind(this));
					if(this.globalService.isDesktop){
						this.svgContainer.addEventListener("wheel", this.onWheel.bind(this));
					}
					// handle autorized svg objects to be selected
					const {intruders,wastes,awards,traps} = this.getSvgMainElements();
					this.intruders = intruders;
					this.wastes = wastes;
					this.awards = awards;
					this.traps = traps;
					await AppUtils.timeOut(2000);
					this.svgContainer.querySelector("title")?.remove();
					this.manageSVGSize();

					this.pinchZoom.toggleZoom();
					await AppUtils.timeOut(this.pinchZoom.getDefaultComponentProperties()?.transitionDuration);
					this.checkNeedDisplayHorizontalButtons();
					this.totalIntruder = this.wastes.length;
					new Array(this.totalIntruder).fill(false).forEach((notWon) => {
						this.awardsStars.push(notWon);
					})

					this.intruders.forEach((element) => {
						element.addEventListener("click", async (e:any) => {
							if(this.validateAnswer && !this.won){
								let scored = false;
								// erreur trouvé
								const wasteGroup = e.target.closest(`[id^='${MainSvgId.waste}']`);
								let awardsAssociated;
								if(wasteGroup){
									scored = true;
									wasteGroup.style.display = "none";
									awardsAssociated = Array.from(wasteGroup.parentElement.children).find((item:any) => {
										if(item?.id){
											return item.id.toLowerCase().includes(MainSvgId.award);
										}
									}) as HTMLBaseElement
								}else{
									awardsAssociated = e.target;
									scored = false;
								}

								if(awardsAssociated){
									// award
									awardsAssociated.style.display = "block";
									const description = awardsAssociated.querySelector("desc")
									
									if(scored){
										this.counterFindIntruder++;
										const notAwardIndex = this.awardsStars.findIndex((award) => {
											return award === false;
										});
										if(notAwardIndex > -1){
											this.awardsStars[notAwardIndex] = true;
										}
									}

									if(scored || description?.innerHTML){
										const text = description?.innerHTML || this.AWARD_TEXT[Math.floor(Math.random()*this.AWARD_TEXT.length)];;
										await this.scenarioBubble.readCustomText(text,true);
									}
								}
								this.checkEnd();
							}
						})
					})
					this.globalService.setGlobalLoading(false);
					const statement = this.startActivityStatement();
					if (statement) {
						this.lrs.send(statement);
					}
					this.checkSvgIsScrollable();
					this.consigne = this.currentSpotGame.consigne?.trim() || this.defaultConsigne?.trim();
					if(this.scrollImgVertically || this.scrollImgHorizontally){
						const lastChar = this.consigne.charAt(this.consigne.length - 1);
						const point = lastChar !== "." && lastChar !== "!" ? "." : "";
						await this.scenarioBubble.readText(this.consigne + $localize`${point} Défile pour voir le reste de l'image`,false,true);
							if(this.scrollImgHorizontally){
								this.displayTutoHorizontal = true;
								await AppUtils.timeOut(2000);
								this.displayTutoHorizontal = false;
							}
							await AppUtils.timeOut(800);
							if(this.scrollImgVertically){
								this.displayTutoVertical = true;
								await AppUtils.timeOut(2000);
								this.displayTutoVertical = false;
							}
					}else{
						this.scenarioBubble.readText(this.consigne,false,true);
					}
					this.activityReady = true;
				}
			});
		} else {
			if (this.environment.production) {
				this.router.navigateByUrl("/territoire");
			} else {
				this.globalService.setGlobalLoading(false);
			}
		}
	}

	/**
	 * SVG and it's container size
	 */
	manageSVGSize(){
		this.manageSVGResize();
		if(this.observer){
			this.observer.disconnect();
		}
		this.observer = new ResizeObserver(() => {
			this.manageSVGResize()
		});
		this.observer.observe(this.svg);
	}

	manageSVGResize(){
		if (this.activityReady && this.pinchZoom?.isZoomedIn) {
			this.pinchZoom.toggleZoom();
		}
		if(this.pinchZoom?.pinchZoom?.parentElement){
			this.pinchZoom.pinchZoom.parentElement.style.height = "initial";
		}
		const root = document.documentElement;
		root.style.setProperty('--svgWrapperWidth', '100%');
		root.style.setProperty('--svgWidth', '100%');
		
		if(this.svgDisplayedContainer.nativeElement.offsetHeight >= this.pinchZoom?.pinchZoom?.parentElement?.offsetHeight){
			root.style.setProperty('--svgContainerWidth', '100%');
			root.style.setProperty('--svgContainerHeight', 'auto');
			root.style.setProperty('--svgWrapperHeight', 'auto');
		}else{
			root.style.setProperty('--svgContainerWidth', 'auto');
			root.style.setProperty('--svgContainerHeight', '100%');
			root.style.setProperty('--svgWrapperHeight', '100%');
			this.pinchZoom.pinchZoom.element.style.height = "100%";
		
		}
		this.pinchZoom.pinchZoom.parentElement.style.height = "100%";

		const svgWrapper = document.querySelector("#svg-wrapper") as any;

		const svgHeight = svgWrapper.offsetHeight;
		if (svgHeight > this.svgDisplayedContainer.nativeElement.offsetHeight) {
			// height 100% of parent container on safari need to define in pixel
			root.style.setProperty('--svgWrapperHeight',this.svgDisplayedContainer.nativeElement.offsetHeight + "px");
			root.style.setProperty('--svgWrapperWidth', 'auto')
			root.style.setProperty('--svgWidth', 'auto');
		}
	}

	/**
	 * Display top/bottom control buttons
	 */
	async onWheel(){
		await AppUtils.timeOut(this.pinchZoom.getDefaultComponentProperties()?.transitionDuration);
		this.checkNeedDisplayHorizontalButtons();
	}

	public checkNeedDisplayHorizontalButtons(){
		const widthContainer = this.svgContainer?.offsetWidth * this.pinchZoom?.pinchZoom?.scale
		// display right and left buttons only if img width is greater than parent width
		if(this.svgDisplayedContainer?.nativeElement){
			this.needGoRight = this.needGoLeft = widthContainer >= this.svgDisplayedContainer.nativeElement.offsetWidth ? true : false;
		}
	} 


	mouseDown(event){
		this.scrollImgHorizontally = false;
		this.scrollImgVertically = false;
		this.displayTutoVertical = false;
		this.displayTutoHorizontal = false;
		if(event.changedTouches?.length > 0){
			this.movementX = this.startX = event.changedTouches[event.changedTouches.length-1].pageX;
			this.movementY = this.startY = event.changedTouches[event.changedTouches.length-1].pageY;
		}else{
			this.movementX = this.startX = event.pageX;
			this.movementY = this.startY = event.pageY;
		}
		this.validateAnswer = false;
	}

	/**
	 * Scroll horizontally image on click 
	 * @param direction string left/right
	 */
	goHorizontal(direction:string){
		if(this.pinchZoom?.pinchZoom?.element){
			const value = this.pinchZoom.pinchZoom.element.style.transform.match(/matrix\(([^\)]+)\)/)[1].split(', ');
			let goDirection = direction === "left" ? 150 : -150;
			this.pinchZoom.pinchZoom.moveX = parseInt(value[4]) + goDirection;
			this.pinchZoom.pinchZoom.transformElement(100);
			this.pinchZoom.pinchZoom.limitPanX();
			this.pinchZoom.pinchZoom.transformElement(100);
			this.pinchZoom.pinchZoom.updateInitialValues();
		}
	}

	/**
	 * Scroll vertically image on click 
	 * @param direction string top/bottom
	 */
		goVertical(direction:string){
			if(this.pinchZoom?.pinchZoom?.element){
				const value = this.pinchZoom.pinchZoom.element.style.transform.match(/matrix\(([^\)]+)\)/)[1].split(', ');
				let goDirection = direction === "top" ? 150 : -150;
				this.pinchZoom.pinchZoom.moveY = parseInt(value[5]) + goDirection;
				this.pinchZoom.pinchZoom.transformElement(100);
				this.pinchZoom.pinchZoom.limitPanY();
				this.pinchZoom.pinchZoom.transformElement(100);
				this.pinchZoom.pinchZoom.updateInitialValues();
			}
		}

/**
 * Checks if the difference in both the X and Y axis is less than a certain value in order to validate the answer
 * @param event 
 */
	mouseUp(event){
		let pageX;
		let pageY;

		if(event.changedTouches?.length > 0){
				pageX = event.changedTouches[event.changedTouches.length-1].pageX;
				pageY = event.changedTouches[event.changedTouches.length-1].pageY;
		}else{
			pageX = event.pageX;
			pageY = event.pageY;
		}

		const diffX = Math.abs(pageX - this.startX);
		const diffY = Math.abs(pageY - this.startY);
		this.validateAnswer = diffX < this.deltaDistance && diffY < this.deltaDistance;
	}


	/**
	 * verify zoomed image is scrollable horizontally and/or vertically based on its transformed size.
	 */
	public checkSvgIsScrollable(){
		if(this.pinchZoom?.pinchZoom?.element?.style?.transform){
			if(this.pinchZoom?.pinchZoom?.parentElement){
				const transformedWidth = this.pinchZoom.pinchZoom.parentElement.offsetWidth * parseFloat(this.pinchZoom.pinchZoom.element.style.transform.match(/matrix\(([^\)]+)\)/)[1].split(', ')[0]);
				const transformedHeight = this.pinchZoom.pinchZoom.parentElement.offsetHeight * parseFloat(this.pinchZoom.pinchZoom.element.style.transform.match(/matrix\(([^\)]+)\)/)[1].split(', ')[3]);
	
				if (transformedWidth > this.pinchZoom.pinchZoom.parentElement.offsetWidth) {
					this.scrollImgHorizontally = true;
				  }
	
				  if(transformedHeight > this.pinchZoom.pinchZoom.parentElement.offsetHeight){
					this.scrollImgVertically = true;
				  }
			}
		}
	
	}


	/**
	 * Get SVG main activity elements
	 * @returns wastes,awards,intruders
	 */
	public getSvgMainElements(){
		const intruders = [];
		const wastes = [];
		const awards = [];
		const traps = [];
		const groupSvg = this.svgContainer.querySelectorAll("g");
		Array.from(groupSvg).forEach((element) => {
			const currentElementId = element.id?.toLowerCase();
			if(currentElementId){
				if(currentElementId.includes(MainSvgId.intruduer)){
					intruders.push(element);
					Array.from(element.children).forEach((intruderChilds:HTMLBaseElement) => {
						const currIntruderChildId = intruderChilds.id?.toLowerCase();
						if(currIntruderChildId){
							if(currIntruderChildId.includes(MainSvgId.waste)){
								wastes.push(intruderChilds);
							}else if(currIntruderChildId.includes(MainSvgId.award)){
								const wasteSiblingElemExist = Array.from(intruderChilds.parentElement.children).some((item:any) => {
									return item.id?.toLowerCase()?.includes(MainSvgId.waste);
								}) 
								if(wasteSiblingElemExist){
									intruderChilds.style.display = wasteSiblingElemExist ? "none" : "block";
								}
								awards.push(intruderChilds);
							}else if(currIntruderChildId.includes(MainSvgId.trap)){
								traps.push(intruderChilds);
							}
						}
					})
				}
			}
		})

		return {awards,wastes,traps,intruders}
	}

	public removeElementListener(element,eventName){
		if(element){
			element.eventListeners(eventName).forEach((currentEvent) => {
				if(currentEvent){
					element.removeEventListener(eventName, currentEvent);
				}
			})
		}
	}

	async checkEnd() {
		if (this.counterFindIntruder === this.totalIntruder) {
			if (this.pinchZoom?.isZoomedIn) {
				this.pinchZoom.toggleZoom();
			}
			this.endStatement = this.endActivityStatement();
			if (this.pinchZoom?.isZoomedIn) {
				this.pinchZoom.toggleZoom();
			}
			await this.displayFeedBackMessage();
		}
	}

	public async displayFeedBackMessage() {
		this.won = true;
		this.globalService.confettiCanvasComponent.launchConfetti();
		if (this.scenarioBubble) {
			await this.scenarioBubble.launchFeedbackWithEnd(this.currentSpotGame.feedback,true);
		}
	}

	svgReady() {
		return new Promise<void>(resolve => {
			AppUtils.timeOut(50).then(async () => {
				let counter = 0;
				while (this.svgContainer.offsetWidth === 0 && counter < 50) {
					await AppUtils.timeOut(10);
					counter++;
				}
				resolve();
			});
		});
	}
	public async nextActivity() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
		this.endStatement = this.endActivityStatement();
		this.removeElementListener(this.svgContainer,"mousedown");
		this.removeElementListener(this.svgContainer,"touchstart");
		this.removeElementListener(this.svgContainer,"mouseup");
		this.removeElementListener(this.svgContainer,"touchend");
		this.removeElementListener(this.svgContainer,"mousemove");
		this.removeElementListener(this.svgContainer,"touchmove");
		this.removeElementListener(this.svgContainer,"mouseleave");
		this.removeElementListener(this.svgContainer,"wheel");
		if (this.oseJourneyService.currentJourney) {
			this.lrs
				.send(this.endStatement)
				.then(() => {
					this.oseJourneyService.exerciseEnd.next();
				})
				.catch(error => {
					console.error("error last statement not send", error);
					this.oseJourneyService.exerciseEnd.next(true);
				});
		} else {
			this.prevNextPage(true)
		}
	}

	public prevNextPage(next:boolean){
		this.currentSVGIndex = this.cabriService.spotDifference.findIndex((ex) => {
			return ex.id === this.currentSpotGame.id;
		})
		let direction = next ? 1 : -1;
		if (this.currentSVGIndex > -1 && this.cabriService.spotDifference[this.currentSVGIndex + direction]) {
			this.loadSVG(this.cabriService.spotDifference[this.currentSVGIndex + direction].id);
		} else {
			let reStartIndex = next ? 0 : this.cabriService.spotDifference.length - 1;
			this.loadSVG(this.cabriService.spotDifference[reStartIndex].id);
		}
	}

	async prevPage(){
		if(!this.oseJourneyService.currentJourney){
			if(this.cabriService.spotDifference){
				this.prevNextPage(false);
			}
		} else {
			await this.scenarioBubble?.skipMathiaSpeechSequence(true);
			this.oseJourneyService.exercisePrev.next();
		}
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.currentSpotGame.id)}`,
				this.currentSpotGame.title,
				this.currentSpotGame.title,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */

	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();

			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.currentSpotGame.id)}`,
				this.currentSpotGame.title,
				this.currentSpotGame.title,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}

			statements.push(exerciseStatement);
			return statements;
		}
	}

	async ngOnDestroy() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
	}
}
