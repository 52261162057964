import { Component, OnInit } from "@angular/core";
import { RemoteService } from "src/app/services/remote.service";
import { Router, NavigationExtras } from "@angular/router";
import { ModalRemoteHostComponent } from "../modal-remote-host/modal-remote-host.component";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-remote-connect",
	templateUrl: "./remote-connect.component.html",
	styleUrls: ["./remote-connect.component.scss"]
})
export class RemoteConnectComponent implements OnInit {
	constructor(public remoteService: RemoteService, public router: Router, public modalController: ModalController) {}

	ngOnInit() {}

	onClick() {
		if (this.remoteService.listRemoteHost && this.remoteService.listRemoteHost.length > 1) {
			this.openListHosts();
		} else if (this.remoteService.listRemoteHost.length === 1) {
			this.goRemotePage(this.remoteService.listRemoteHost[0]);
		}
	}

	goRemotePage(host) {
		this.router.navigateByUrl("/remote-control", {
			replaceUrl: true,
			state: {
				host
			}
		});
	}

	async openListHosts() {
		const modal = await this.modalController.create({
			component: ModalRemoteHostComponent,
			cssClass: "student-list-modal gradientBubbleModal"
		});

		modal.onDidDismiss().then((callback: any) => {
			if (callback.data && callback.data.host) {
				this.goRemotePage(callback.data.host);
			}
		});

		return await modal.present();
	}
}
