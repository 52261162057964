import { HttpClient } from "@angular/common/http";
import { Pipe, PipeTransform } from "@angular/core";
import { lastValueFrom, map } from "rxjs";
import { AccountService } from "../services/account.service";

@Pipe({
	name: "filterCompetency",
	pure: true
})
export class VideosPipe implements PipeTransform {
	constructor(private accoutService: AccountService) {}
	transform(value: { [exerciseId: string]: string }) {
		if (this.accoutService.user.videos && value) {
			let competencyArray = this.accoutService.user.videos.getVideoByCompetenciesById(Object.keys(value));
			return competencyArray.length > 0 ? competencyArray : null;
		} else {
			return null;
		}
	}
}

@Pipe({
	name: "videosQualityPipe",
	pure: true
})
export class VideosQualityPipe implements PipeTransform {
	constructor() {}
	transform(value: string) {
		const regex = /-\d+x\d+/gm;
		return value.replace(regex, "");
	}
}

@Pipe({
	name: "vimeoThumbUrl",
	pure: true
})
export class VideosThumbUrl implements PipeTransform {
	constructor(public http: HttpClient) {}
	transform(value: string) {
		return this.http.jsonp<any>("https://vimeo.com/api/oembed.json?url=" + value, "callback").pipe(
			map(res => {
				const regex = /-d_.*/gm;
				return res.thumbnail_url.replace(regex, "-d_");
			})
		);
	}
}
