import { AudioService } from "./../../services/audio.service";
import { animate, query, stagger, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Video, VideoGenre } from "src/app/models/video";
import { Videos } from "src/app/models/videos";
import { AccountService } from "src/app/services/account.service";
import { ConnectionStatus, NetworkService } from "src/app/services/network.service";
import { VideosService } from "src/app/services/videos.service";
import * as Player from "@vimeo/player/dist/player.js";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { Statement } from "src/app/models/statement";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { LrsService } from "src/app/services/lrs.service";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { GlobalService } from "src/app/services/global.service";
import { AppUtils } from "src/app/app-utils";
import { EventsBasePage } from "src/app/page/base/eventsbasepage.page";
const listAnimation = trigger("listAnimation", [
	transition("* <=> *", [
		query(":enter", [style({ opacity: 0 }), stagger("60ms", animate("600ms ease-out", style({ opacity: 1 })))], { optional: true })
	])
]);

@Component({
	selector: "app-video",
	templateUrl: "./video.component.html",
	styleUrls: ["./video.component.scss"],
	animations: [listAnimation]
})
export class VideoComponent extends EventsBasePage implements OnInit, OnDestroy {
	@Input() videoF: Video;
	@Input() buttonHolo = false;
	@Input() holo = false;
	@Input() descriptionFull = false;
	@Input() context = {};
	@Output() fullscreen: EventEmitter<boolean>;

	vimeoPlayer: Player;
	video: Video;

	selected: Video = null;
	videosLib: Videos;
	videoList: Video[];
	unseen: boolean;
	videoGenres: (string | VideoGenre)[];
	age: number;
	videoGenre: string | VideoGenre;
	online = true;
	obsNetworkStatus: Subscription;
	public animationsDisabled = false;
	volumeChangeSub: Subscription;
	public appIdleSubscription: Subscription;
	public appIdleTimeOut: NodeJS.Timeout;

	public statement:Statement
	public sendInitStatement:boolean
	// @ViewChild("vimeo", { static: false }) vimeoIframe: ElementRef<HTMLIFrameElement>;
	@ViewChildren("vimeo") vimeoIframe: QueryList<any>;
	constructor(
		public videosService: VideosService,
		public accountService: AccountService,
		public modalController: ModalController,
		public networkService: NetworkService,
		public lrsService: LrsService,
		public globalService: GlobalService,
		public audioService: AudioService
	) {
		super(globalService);
		this.checkHolo();
		this.animationsDisabled = globalService.isIos ? true : false;
		this.videoGenres = Object.values(VideoGenre).filter(genre => genre !== "Toutes");
		this.obsNetworkStatus = networkService.onNetworkChange().subscribe(value => {
			this.online = value === ConnectionStatus.Online;
			if (this.online && !this.videosLib) {
				this.ngOnInit();
			}
		});
		this.volumeChangeSub = this.globalService.volumeChangeEvent.subscribe(volume => {
			this.setVimeoVolumeFromSettingsValue();
		});
	}
	async ngOnInit() {
		if (!this.videoF) {
			if (this.accountService.user.videos) {
				this.videosLib = this.accountService.user.videos;
				this.videoList = this.videosLib.getAllVideos();
			} else {
				this.videosLib = await this.videosService.getVideos(this.accountService.user?.studentId);
				this.videoList = this.videosLib.getAllVideos();
			}
			this.getGenreFilterFromVideosList();
			// subscribe to appIdle events:
			super.initAppIdleSubscription();
		}
	}

	getGenreFilterFromVideosList() {
		let bigArrayGenre = new Array();
		this.videoList.forEach(video => {
			bigArrayGenre = bigArrayGenre.concat(video.genres);
		});
		bigArrayGenre = bigArrayGenre.filter(genre => genre !== "Toutes");
		this.videoGenres = [...new Set(bigArrayGenre)];
	}

	allVideo() {
		this.unseen = false;
		this.filterVideo();
	}
	unSeenVideo() {
		this.unseen = true;
		this.filterVideo();
	}

	filterVideo() {
		this.audioService.playSelectSound();
		this.videoList = this.videosLib.getVideoByParam(this.unseen, this.age, this.videoGenre);
		console.log("this.videoList", this.videoList);
	}

	createVideoStatement() {
		let objExtension;
		if (this.videoF.competencies) {
			objExtension = { [XapiExtensions.competences]: JSON.stringify(this.videoF.competencies) };
		}
		const object = new XapiObject(this.videoF.videoUrl, this.videoF.name, this.videoF.description, XObjectType.video, objExtension);
		let contextXapi = {
			[XapiExtensions.codeclasse]: this.accountService.user.codeClasse,
			[XapiExtensions.kidaia]: this.globalService.isKidaia,
			[XapiExtensions.holo]: this.holo
		};
		contextXapi = { ...contextXapi, ...this.context };
		const context = new XapiContext(this.accountService.team, contextXapi);

		return new Statement(this.accountService.team[0]?.id, null, object, context);
	}
	videoSeen(video: Video) {
		this.videosService.seeVideo(video, this.accountService.user.studentId, this.holo);
	}

	dismiss() {
		// close video when fullscreen
		if (this.videoList && this.selected && this.videoF) {
			this.videoF = null;
		} else if (this.videoList && !this.videoF && this.selected && this.descriptionFull) {
			this.selected = null;
		} else {
			// close the modal
			this.modalController?.dismiss();
		}
	}

	checkHolo() {
		if (this.globalService.storySettingChoiceMade && this.globalService.generalParamsValues?.holo) {
			this.holo = this.globalService.generalParamsValues.holo.value === "2";
		} else {
			this.holo = false;
		}
	}

	setVimeoVolumeFromSettingsValue() {
		const volume = this.globalService.muteSounds
			? 1
			: this.globalService.volume.sounds + 0.3 > 1
			? 1
			: this.globalService.volume.sounds + 0.3;
		this.vimeoPlayer?.setVolume(volume);
	}

	videoPlayerInitialyze() {
		this.vimeoPlayer = new Player(this.vimeoIframe.last.nativeElement);
		this.vimeoPlayer.on("fullscreenchange", event => {
			if (this.fullscreen) {
				this.fullscreen.emit(event.fullscreen);
			}
		});
		this.statement = this.createVideoStatement();
		this.sendInitStatement = true;
		this.setVimeoVolumeFromSettingsValue();
		this.vimeoPlayer.on("playing", () => {
			//this.globalService.toggleMuteMusic(true);
			// TODO EVENT Video start
			if (this.sendInitStatement) {
				this.statement.verb = LrsVerbs.initialized;
				// console.error("verbStartSended")
				this.lrsService.send(this.statement);
				this.sendInitStatement = false;
			}
		});
		this.vimeoPlayer.on("ended", () => {
			this.sendVideoEndStatement();
		});
		this.vimeoPlayer.on("error", error => {
			console.log("vimeo error", error);
		});
		if (this.videoList) {
			this.vimeoPlayer.on("ended", () => {
				// to avoid next video when direct play video in parameter (no item on selected)
				if (this.selected) {
					this.loadNextVideo();
				} else {
					this.modalController?.dismiss();
				}
			});
		}
	}

	sendVideoEndStatement(){
		this.statement.verb = LrsVerbs.completed;
		this.lrsService.send(this.statement);
		// console.error("verbEndSended")
		this.sendInitStatement = true;
	}

	toggleVimeoPlayback(value) {
		if (value) {
			this.vimeoPlayer?.play();
		} else {
			this.vimeoPlayer?.pause();
		}
	}

	loadNextVideo(lastVideo?: string) {
		let videoUrl: string;
		if (lastVideo) {
			videoUrl = lastVideo;
		} else {
			videoUrl = this.holo ? this.videoF.videoUrlHolo : this.videoF.videoUrl;
		}
		const index = this.videoList.findIndex(video => {
			return video.videoUrl === videoUrl || video.videoUrlHolo === videoUrl;
		});
		const nextVideo = this.videoList[index + 1] ? this.videoList[index + 1] : this.videoList[0];
		this.vimeoPlayer
			.loadVideo(
				this.holo
					? nextVideo.videoUrlHolo + "?dnt=true&autoplay=1&title=0&portrait=0&byline=0"
					: nextVideo.videoUrl + "?dnt=true&autoplay=1&title=0&portrait=0&byline=0"
			)
			.then(() => {
				this.vimeoPlayer.title = false;
				this.vimeoPlayer.play();
				this.vimeoPlayer.off("ended");
				this.vimeoPlayer.on("ended", () => {
					this.loadNextVideo(this.holo ? nextVideo.videoUrlHolo : nextVideo.videoUrl);
					this.sendVideoEndStatement();
				});
			});
	}

	killVimeoPlayerIfAny(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (this.vimeoPlayer) {
				this.vimeoPlayer
					.destroy()
					.then(() => {
						this.vimeoPlayer = null;
						AppUtils.debug("VIMEO PLAYER DESTROYED SUCCESSFULLY");
						// this.audioService.toggleMusicPlayback(true);
						resolve();
					})
					.catch(error => {
						this.vimeoPlayer = null;
						AppUtils.debug("VIMEO PLAYER DESTROYED ERROR = ", error);
						reject();
					});
			} else {
				resolve();
			}
		});
	}

	// overload eventsBasePage's setAppIdle()
	async setAppIdle(idle: boolean, loaderStop = true, afterResize?: boolean): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (!this.globalService.toolbarMenuOpened.status) {
				this.toggleVimeoPlayback(!idle);
				resolve();
			}
		});
	}

	ngOnDestroy() {
		this.killVimeoPlayerIfAny();
		this.obsNetworkStatus?.unsubscribe();
		this.volumeChangeSub?.unsubscribe();
		// unsubscribe to appIdle events:
		super.unsubscribeinitAppIdleSubscription();
	}
}
