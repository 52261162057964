import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Videos } from "../models/videos";
import { Competency, Video, VideoGenre } from "../models/video";
import { map } from "rxjs/operators";
import { lastValueFrom } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class VideosService extends BaseService {
	public environment: { production: boolean; activityVersion: number; storyVersion: number; kidaia: boolean; bearerToken: string };
	modal: HTMLIonModalElement;

	constructor(private http: HttpClient) {
		super();
		this.environment = environment;
	}

	getVideos(idUser: string): Promise<Videos> {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("action", "getVideos");
			formData.append("id_user", idUser);
			if (!this.environment.production) {
				formData.append("dev", "true");
			}
			const remapVideos: Video[] = [];
			const videosObs = this.http.post<Video[]>(this.postUrl, formData).pipe(
				map(videos => {
					videos.forEach(video => {
						video.see = video.see ? true : false;
						video.age = Number(video.age);
						video.dateCreation = new Date(video.dateCreation);
						video.dateModified = new Date(video.dateModified);
						if (video.genres) {
							video.genres = (video.genres as unknown as String).split(",") as unknown as VideoGenre[];
						}
						if (video.competencies) {
							const comptencies: Competency[] = [];
							const competenciesString = (video.competencies as unknown as String).split("§") as unknown as string[];
							competenciesString.forEach(competencyString => {
								const competencyItem = competencyString.split("#");
								comptencies.push(new Competency(competencyItem[0], competencyItem[1]));
							});
							video.competencies = comptencies;
						}
						remapVideos.push(video);
					});
					return remapVideos;
				})
			);
			lastValueFrom(videosObs).then(videos => {
				resolve(new Videos(videos));
			}).catch(error => reject(error));
		});
	}

	public seeVideo(video: Video, studentId: string, holo: boolean) {
		video.see = true;
		this.saveVideoSee(video, studentId, holo).then(id => {
			console.log("Video See :" + video.name + " id save :" + id);
		});
	}

	private saveVideoSee(video: Video, studentId: string, holo: boolean): Promise<string> {
		const formData = new FormData();
		formData.append("action", "saveVideoSee");
		formData.append("id_user", studentId);
		formData.append("id_video", video.id);
		formData.append("holo", holo ? "1" : "0");
		formData.append("see", "1");
		const saveVideoObs = this.http.post<string>(this.postUrl, formData);
		return lastValueFrom(saveVideoObs);
	}
}
