<div class="speech-bubble-container" [ngClass]="{'displayBubble' : displayBubble && !globalService.leftMenuStatus && !globalService.waitingLandscapeMode && !globalService.studentsMenuStatus}">
  <div class="speech-bubble" (click)="skip()">
    <div class="speech-bubble__mascotte-container">
      <div class="mascotte">
        <div class="mascotte__img"></div>
      </div>
    </div>
    <div class="speech-bubble__text-container">
      <h1 #bubbleText id="speech-bubble__text">{{bubbleTextContent}}</h1>
    </div>
    <div class="buttonSkip" (click)="skip()">Passer</div>
  </div>
</div>
