export class Tutorial {
	phrase: any;
	phraseTTS: any;
	callback: any;
	next: Tutorial;
    randomSpeechMode: boolean;
    textBubble: boolean;
    buttons: boolean;
    buttonText: string;
    disableSkip: boolean;
    shootingStar: boolean;
    normalStar: boolean;
    moon: boolean;
    onlySpeech: boolean;
    keepBubble: boolean;
}
