import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-circle-percent",
	templateUrl: "./circle-percent.component.html",
	styleUrls: ["./circle-percent.component.scss"]
})
export class CirclePercentComponent implements OnInit {
	@Input() percent: number;
	@Input() width: number;
	@Input() height: number;
	@Input() textColor: string;
	public svgTextColor: string;
	color: string;
	random: string;

	constructor() {}

	ngOnInit() {
		this.definePercentColor();
		this.textColor ? (this.svgTextColor = this.textColor) : (this.svgTextColor = "white");
	}

	definePercentColor() {
		if (this.percent >= 0) {
			if (this.percent < 75) {
				// Orange
				this.color = "#FFA500";
			} else if (this.percent < 100) {
				// Green
				this.color = "#CFEB1B";
			} else if (this.percent >= 100) {
				// Green
				this.color = "#76EE00";
			}

			this.random = Math.random().toString(36).substring(4);
		}
	}
}
