import { Directive, Input, ElementRef, HostListener, Renderer2, OnDestroy } from "@angular/core";

@Directive({
	selector: "[tooltip]"
})
export class TooltipDirective implements OnDestroy {
	@Input("tooltip") tooltipTitle: string;
	@Input() placement: string;
	@Input() delay: string;
	@Input() click: boolean;
	@Input() persistant: boolean;
	@Input() cssClasses: string;

	tooltip: HTMLElement;

	tooltip2: HTMLElement;
	offset = 5;

	showed: boolean;

	constructor(private el: ElementRef, private renderer: Renderer2) {
		if (!this.placement) {
			this.placement = "top";
		}
		if (!this.delay) {
			this.delay = "0";
		}
		if (!this.click) {
			this.click = false;
		}
	}

	@HostListener("mouseenter") onMouseEnter() {
		if (this.tooltipTitle && !this.click) {
			this.show();
		}
	}

	@HostListener("mouseleave") onMouseLeave() {
		if (this.tooltipTitle && !this.click) {
			this.hide();
		}
	}

	@HostListener("click") onClick() {
		if (this.tooltipTitle && this.click && !this.showed) {
			this.show();
		} else if (this.tooltipTitle && this.click && this.showed) {
			this.hide();
		}
	}

	/**
	 * Create and show a tooltip for 2sec
	 */
	show() {
		this.showed = true;
		this.create();
		this.setPosition();
		this.renderer.addClass(this.tooltip, "ng-tooltip-show");

		if(!this.persistant){
			setTimeout(() => {
				if (this.showed) {
					this.hide();
				}
			}, 2000);
		}
	}

	/**
	 * Hide and delete a tooltip
	 */
	hide() {
		this.showed = false;
		this.renderer.removeClass(this.tooltip, "ng-tooltip-show");
		window.setTimeout(() => {
			this.renderer.removeChild(document.body, this.tooltip);
			this.tooltip = null;
		}, +this.delay);
	}

	/**
	 * Create span tooltip element with renderer
	 */
	create() {
		this.tooltip = this.renderer.createElement("span");
		this.el.nativeElement.test = this.tooltip;
		this.renderer.appendChild(
			this.tooltip,
			this.renderer.createText(this.tooltipTitle) // textNode
		);

		this.renderer.appendChild(document.body, this.tooltip);
		// this.renderer.appendChild(this.el.nativeElement, this.tooltip);

		this.renderer.addClass(this.tooltip, "ng-tooltip");
		this.renderer.addClass(this.tooltip, `ng-tooltip-${this.placement}`);
		const cssClasses = this.cssClasses.split(" ")
		cssClasses.forEach(cssClass => {
			this.renderer.addClass(this.tooltip, cssClass);
		});

		// delay
		this.renderer.setStyle(this.tooltip, "-webkit-transition", `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, "-moz-transition", `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, "-o-transition", `opacity ${this.delay}ms`);
		this.renderer.setStyle(this.tooltip, "transition", `opacity ${this.delay}ms`);

		this.renderer.listen(this.tooltip, "click", evt => {
			if (this.tooltip) {
				this.showed = false;
				this.renderer.removeChild(document.body, this.tooltip);
				this.tooltip = null;
			}
		});
	}

	/**
	 * Set relative position of tootip by css
	 */
	setPosition() {
		const hostPos = this.el.nativeElement.getBoundingClientRect();

		const tooltipPos = this.tooltip.getBoundingClientRect();

		const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		let top, left;

		if (this.placement === "top") {
			top = hostPos.top - tooltipPos.height - this.offset;
			left = hostPos.left + (hostPos.width - tooltipPos.width) / 2 + 2;
		}

		if (this.placement === "bottom") {
			top = hostPos.bottom + this.offset;
			left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
		}

		if (this.placement === "left") {
			top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
			left = hostPos.left - tooltipPos.width - this.offset;
		}

		if (this.placement === "right") {
			top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
			left = hostPos.right + this.offset;
		}

		this.renderer.setStyle(this.tooltip, "top", `${top + scrollPos}px`);
		this.renderer.setStyle(this.tooltip, "left", `${left}px`);
	}

	/**
	 * Destroy tooltip when angular cycle destroy parent element
	 */
	ngOnDestroy() {
		if (this.tooltip) {
			this.showed = false;
			this.renderer.removeChild(document.body, this.tooltip);
			this.tooltip = null;
		}
	}
}
