export class Video {
	id: string;
	name: string;
	dateCreation: Date;
	dateModified: Date;
	description: string;
	descriptionTeacher: string;
	genres: VideoGenre[];
	competencies: Competency[];
	duration: string;
	age: number;
	videoUrl: string;
	videoUrlHolo: string;
	cover: string;
	see: boolean;
}

export enum VideoGenre {
	Mathématiques = "Mathématiques",
	SVT = "SVT",
	Toutes = "Toutes",
	Astronomie = "Astronomie",
	Physique = "Physique",
	Histoire = "Histoire",
	Environnement = "Environnement"
}
export class Competency {
	id: string;
	name: string;

	constructor(id: string, name: string) {
        this.id=id;
        this.name = name;
    }
}
